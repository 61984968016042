import {
  pick, get, isEmpty, cloneDeep,
} from 'lodash';
import { BaseOrder } from './BaseOrder';
import qcForm from './Form';
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async get(params) {
    try {
      const { data } = await req.get(`ordersManager/get/${params.orderId}`, {
        params: { projectId: params.projectId, showAllCompanyOrders: params.showAllCompanyOrders, isCutOrder: params.isCutOrder || false },
      });

      if( params.isCutOrder  && !_.isEmpty(data)){
        const response = await this.getCutOrderItems({
          projectId: params.projectId,
          cardId: params.orderId,
          stage: params.stage,
          page: params.page,
          search: params.search,
          showCompleted: params.showCompleted || 0,
          hideCompleted: params.hideCompleted || 0,
        });
        data.items = response.data;
        data.page = params.page || 1;
        data.search = params.search;
        data.totalCutItems = response.total;
        data.showCompletedItems = params.hideCompleted || 0;
      }
      return new BaseOrder(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async create(card) {
    try {
      const { data: pm } = await req.post('/move/moveTOPM', card, {
        params: { projectId: card.project._id },
      });
      return pm;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      return {};
    }
  },
  getTemplateRuns(params) {
    const { newCard, templateProdOrder, companyRuns } = params;
    newCard.manager.templateRuns = [];
    if (isEmpty(templateProdOrder)) { return newCard; }
    if (templateProdOrder.manager.runs && templateProdOrder.manager.runs.length) {
      newCard.manager.templateRuns = cloneDeep(templateProdOrder.manager.runs);
      newCard.selectedCard = cloneDeep(templateProdOrder);
    } else if (!isEmpty(newCard.defaultRun)) {
      newCard.manager.templateRuns = [newCard.defaultRun];
    } else if (companyRuns && companyRuns.length) {
      newCard.manager.templateRuns = [companyRuns[0]];
    }
    return newCard;
  },
  async createFromPO(params) {
    const { card, companyRuns } = params;
    let newCard = pick(card, ['purpose', '_id', 'pTrackEnabled', 'templateOrder',
      'defaultRun', 'location', 'owner', 'project', 'notifyUsers', 'currentNote', 'manager']);
    newCard.poDetailBy = card.getDate('poDetailBy');
    newCard.deliverDate = card.getDate('deliver');
    if (!isEmpty(card.simpleDates.poManufactureBy)) {
      newCard.poManufactureBy = card.simpleDates.poManufactureBy.value;
    }
    if (!isEmpty(card.simpleDates.poQaBy)) {
      newCard.poQaBy = card.simpleDates.poQaBy.value;
    }
    newCard.projectId = card.project._id;
    this.getTemplateRuns({
      newCard: card, templateProdOrder: card.selectedCard, companyRuns,
    });
    let runs = card.manager.templateRuns;
    if (card.selectedCard && card.selectedCard.manager
      && card.selectedCard.manager.runs && card.selectedCard.manager.runs.length) {
      runs = card.selectedCard.manager.runs;
    }
    // dont send templateRuns to backend as sending it leads to circular dependency
    delete card.manager.templateRuns;
    const { selectedCard } = card;
    if (!isEmpty(newCard.templateOrder)) {
      newCard = new BaseOrder(await this.create(newCard));
      if (runs && runs.length) {
        newCard._customStage = 'detailing';
        newCard.manager.runs[0].archived.value = true;
        for (const run of runs) {
          if (run.form && run.form._id) {
            const newForm = await qcForm.attachForm({
              formId: run.form._id,
              projectId: newCard.project._id,
              orderId: newCard._id,
              setTemplateOwner: true,
            });
            run.form = { _id: newForm._id };
            run.hasNewForm = true;
            run.formStatus = newForm.status;
          }
        }
        newCard.addTemplateRuns(runs, selectedCard);
      }
      return newCard.save();
    }
    return this.create(newCard);
  },
  async update(card) {
    try {
      const { data: pm } = await req.post(`OrdersManager/update/${card._id}`, card, {
        params: { projectId: card.project._id },
      });
      return pm;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async bulkUpdateField(params) {
    try {
      const res = await req.post('ordersManager/bulkUpdateField/', params);
      return res;
    } catch (e) {
      // TODO: Add an alert here?
      throw e;
    }
  },
  async moveTo(stage, card) {
    try {
      const { data: pm } = await req.post(`OrdersManager/moveTo/${stage}/${card._id}`, card, {
        params: { projectId: card.project._id },
      });
      return pm;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async moveBackToManu(params) {
    try {
      await req.post('OrdersManager/moveBackToManu', params);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async cloneTemplate(card) {
    try {
      const { data: pm } = await req.post(`prefabs/cloneTemplate/${card._id}`, card, {
        params: { projectId: card.project._id },
      });
      return pm;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async startTimer(params) {
    try {
      const { data } = await req.post('productivity/timerStart', params);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async stopTimer(params) {
    try {
      const { data } = await req.post('productivity/timerStop', params);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async archive(card, type = 'return', bomArchive = false) {
    try {
      const { data: pm } = await req.post(`OrdersManager/archive/${card._id}/${type}/${bomArchive}`, card, {
        params: { projectId: card.project._id },
      });
      return pm;
    } catch (e) {
      throw e;
    }
  },
  async split(card) {
    try {
      const { data: order } = await req.post(`OrdersManager/split/${card._id}`, card, {
        params: { projectId: card.project._id },
      });
      return order;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async combine(card) {
    try {
      const { data: order } = await req.post('OrdersManager/merge', card);
      return order;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  addManagerLocationToRun(card, from) {
    card.manager.runs.forEach((run) => {
      if (!run._id) {
        run.location = { ...get(card, from, {}) };
      }
    });
  },
  async createOrderFromTemplate(card) {
    try {
      const { data } = await req.post('prodTemplates/createOrderFromTemplate', card);
      return data;
    } catch (e) {
      console.log('Error', e);
      throw e;
    }
  },
  async createOrderValidations(card) {
    try {
      const { data } = await req.post('order/validate', card);
      return data;
    } catch (e) {
      console.log('Error', e);
      throw e;
    }
  },

  async getUniqueOrderIds(params) {
    try {
      const { uniqueOrderIds, projectId } = params;
      const { data } = await req.post('ordersManager/get/getUniqueOrderIds', { skipProjectCheck: true, uniqueOrderIds, projectId });
      return data.data;
    } catch (e) {
      console.log('Error', e);
      throw e;
    }
  },
  async getCutOrderItems(params) {
    /*
      This GET API Endpoint takes the following params:
      {
        limit,
        sort,
        page,
        search,
        stage,
        cardId,
        projectId,
        hideCompleted, send 0 instead of false and 1 instead of true
        showCompleted, send 0 instead of false and 1 instead of true
      }
    */
    try {
      let { limit, sort, page, search, stage, cardId, projectId, hideCompleted, showCompleted } = params;
      const { data } = await req.get(`items/getCutOrderItems`, {
        params: {
          limit,
          sort: sort || { name: 1 },
          page: page || 1,
          search: search || "",
          stage: stage || "detailing",
          cardId,
          projectId,
          hideCompleted,
          showCompleted
        }
      });
      return data;
    } catch (error) {
      console.error("Error: ", error);
      throw error;
    }

  },
  async putCutOrderItems(params) {
    /*
      This POST API endpoint takes the following params:
      {
        items: {
          _id,
          customId i.e, Item Id,
          stage,
          isCompleted i.e, card.manager.runs.$.runItemsCount.$.riCompleted of the run for that item,
          quantity,
          costCode,
          measure,
          measureUnits,
          simpleFiles i.e, card.files.$.sources.$._id for that item,
          simpleMemos i.e, card.memos.$.sources.$._id for that item,
        }
      }
    */
    try {
      let { items, cardId } = params;
      let { data } = await req.post('items/putItems', { items, cardId });
      return data;
    } catch (error) {
      console.error("Error: ", error);
      throw error;
    }
  }, 
  async moveCutItemsToInv(params) {
    /*
      This POST API endpoint takes the following params: 
      {
        cardId,
        items: [
          {
            _id,
            catId,
            quantity
          }
        ],
        projectId,
        locationId
      }
    */
    try {
      let { data } = await req.post('/items/moveCutItemsToInv', { ...params });
      return data;
    } catch (error) {
      console.error("Error: ", error);
      throw error;
    }
  },
  async returnMaterialsToInv({ cardId }) {
    /*
      This POST API endpoint takes the following params: 
      {
        cardId
      }
    */
   try {
     let { data } = await req.post('/ordersManager/returnMaterials', { cardId });
     return data;
   } catch (error) {
     console.error("Error: ", error);
     throw error;
   }
  }
};
