import _ from 'lodash';
import { orderBy as naturalOrderBy } from 'natural-orderby';

export default {
  filter(data = [], sortParams = [], isNatural = false) {
    sortParams = _.castArray(sortParams);
    const sortField = _.map(sortParams, 'sortField');
    const sortDirection = _.map(sortParams, 'direction');
    if (isNatural) return naturalOrderBy(data, _.map(sortField, (f) => (v) => v[f]), sortDirection);
    return _.orderBy(data, sortField, sortDirection);
  },
};
