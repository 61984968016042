<template>
  <div class="modal-card">
    <o-loading :full-page="true" :active="isLoading"></o-loading>
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        Reorganize Items for {{ selectedProjectDetails.name }}
      </h4>
    </header>
    <div class="modal-card-body is-visible has-background-white p-0">
      <div class="line-height required-label is-size-5 is-italic">
        * required
      </div>
      <div class="column">
        <div class="columns">
          <div class="column pt-0">
            <h3 class="is-italic has-text-weight-bold mb-3">
              Reorganize Items By
            </h3>
            <!-- mf multi select c1a-->
            <div class="columns is-flex is-align-items-center">
              <div class="column is-6">
                <mf-multi-select
                v-model="selectedOrganizeOption"
                track-by="id"
                label="name"
                :multiple="false"
                :options="reOrganizeOpts"
                :allow-empty="false"
                @update:modelValue="fireEvent($event)">
                </mf-multi-select>
              </div>
              <div class="column is-narrow pl-0">
                <span class="is-size-3 ligter-color">
                  (Creates {{ reOrganizedOrdersPossible }} Orders)
                </span>
              </div>
            </div>
          </div>
          <div class="column pt-0 pl-0">
            <h3 class="is-italic has-text-weight-bold">
              New Sourcing Order Names
            </h3>
            <div class="column  pl-0 is-flex is-align-items-center">
              <div class="is-flex">
                <div class="prefix-text"
                  @input="toggleText">
                  <input type="text" placeholder="Prefix"
                    class="input" v-model="prefixState.label">
                </div>
                <div class="is-flex">
                  <div class="has-text-black-bis m-auto p-1">-</div>
                  <div class="is-flex is-align-items-center ligter-color">
                    [
                      <span class="organize-label has-text-centered is-italic"
                        :key="selectedOrganizeOption.id">
                        {{ selectedOrganizeOption.orderLabelOption }}
                      </span>
                    ]
                  </div>
                  <div class="has-text-black-bis m-auto p-1">-</div>
                </div>
                <div class="suffix-text"
                  @input="toggleText">
                  <input type="text" placeholder="Suffix"
                  class="input" v-model="suffixState.label">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- search bar and mf table -->
      <div class="column">
        <search-bar
        :shouldEmit="true"
        @search="getSearchValue($event)"
        placeholder="Search"
        >
        </search-bar>
        <div>
          <mf-table
          :tableProps="tableProps"
          :loadData="loadData"
          :hideGutter="true"
          @row-select="getSelectedRows($event)"
          @checkbox-toggled="getSelectedRows"
          ref="reorganizeTable"
          :checkedRows="selectedItems"
          :key="loadTable"
          :selectedRow="selectedRow"
          :selectedOption="selectedOrganizeOption.event"
          v-if="!hideTable"
          >
            <template v-slot:orderName="{ rowData }">
              {{ getOrganizeName(rowData) }}
            </template>
            <template v-slot:location-select="{ rowData }">
              <mf-multi-select
                v-model="rowData.commonLoc"
                :options="allLocations"
                :closeOnSelect="true"
                label="name"
                track-by="_id"
                :multiple="false"
                :hideSelected="false"
                :allow-empty="false"
                class="pendo-reorganize-location-select">
              </mf-multi-select>
            </template>
          </mf-table>
        </div>
      </div>
    </div>
    <footer>
      <div class="has-text-white is-size-5 footer-note is-primary">
          <span class="has-text-weight-bold">Note:</span>
          Item will be reorganized into these new orders. Empty orders will be removed.
    </div>
    <div class="modal-card-foot is-justify-content-flex-end is-align-items-center">
       <button class="button is-outlined"
      @click="cancel()">
        Cancel
      </button>
      <button class="button has-background-black-bis"
      :disabled="selectDisable"
      @click="reorganize()">
        Reorganize
      </button>
    </div>
    </footer>
    <o-modal :active="isProgressBar" v-if="isProgressBar" :canCancel="false" class="modal-xs">
      <progress-bar
      :totalCount="selectedItems.length"
      :progressCount="progressCount"
      headerLabel="Reorganizing Items"
      @turn-off-progress="turnOffProgress"
      progressType="progressCount"
      progressLabel="orders created"
      />
    </o-modal>
  </div>
</template>
<script>

import {
  reactive, toRefs, ref, computed, onBeforeMount, defineAsyncComponent,
} from 'vue';
import tableDefinition from '@/components/table-cols/reorganizeListCols';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import SearchBar from '@/components/SearchBar.vue';
import {
  isEmpty, find, some, debounce, get,
} from 'lodash';
import MaterialManager from '@/models/MaterialManager';
import { useStore } from 'vuex';
import Locations from '@/models/Locations';
import ProgressBar from '@/components/modals/ProgressBar';
import { useToast } from 'vue-toastification';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default {
  name: 'ReorganizeItems',
  components: {
    'mf-table': MfTable,
    MfMultiSelect,
    SearchBar,
    ProgressBar,
  },
  props: {
    selectedProject: {
      type: Object,
      default: () => {},
    },
    isActive: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const reorganizeTable = ref(null);
    const toast = useToast();
    const reOrganizeOpts = [
      {
        id: 1, name: 'Default Vendor', event: 'activeSupplier', label: 'vendor', orderLabelOption: 'Default Vendor',
      },
      {
        id: 2, name: 'Category', event: 'category', label: 'category', orderLabelOption: 'Category',
      },
      {
        id: 3, name: 'Category & Sub-category', event: 'subCategory', label: 'subcategory', orderLabelOption: 'Category & Sub',
      },
      {
        id: 4, name: 'Cost Code', event: 'costCode', label: 'costCode', orderLabelOption: 'Cost Code',
      },
    ];

    const state = reactive({
      tableProps: tableDefinition,
      selectedItems: [],
      selectedRow: {},
      isLoading: false,
      toggleColor: false,
      tableData: [],
      loadTable: 0,
      reOrganizedOrdersPossible: 0,
      selectedProjectDetails: {},
      selectedOrganizeOption: {},
      searchKey: '',
      isProgressBar: false,
      projectId: '',
      reOrganizeBy: '',
      prefixState: {
        label: '',
        value: '',
      },
      suffixState: {
        label: '',
        value: '',
      },
      allLocations: [],
      hideTable: false,
      progressCount: 0,
    });
    const fireEvent = async (event) => {
      state.projectId = props.selectedProject.id;
      state.selectedOrganizeOption = event;
      state.reOrganizeBy = event.event;
      state.selectedItems = [];
      state.loadTable++;
    };
    const cancel = () => {
      emit('cancel');
      emit('close', false);
    };

    const toggleText = async () => {
      debounce(() => {
        state.prefixState.value = state.prefixState.label;
        state.suffixState.value = state.suffixState.label;
      }, 500, {
        leading: false,
        trailing: true,
      })();
    };

    const setSelectedLocDate = (locData, selectedItem) => {
      locData.commonLoc = get(selectedItem, 'commonLoc', null);
      locData.earliestOnsite = get(selectedItem, 'earliestOnsite', locData.earliestOnsite);
    };

    const loadData = async (commonParam) => {
      const titleField = find(state.tableProps.fields, (field) => field.id === 'name');
      const params = {
        projectId: state.projectId,
        reOrganizeBy: state.reOrganizeBy,
        ...commonParam,
        search: state.searchKey,
      };
      titleField.title = `New Orders by ${state.selectedOrganizeOption.name}`;
      const { data, total } = await MaterialManager.reOrganize(params);
      data.forEach((locData) => {
        locData.reOrganizeBy.forEach((loc) => {
          const selectedItem = find(state.selectedItems, (checkedItem) => checkedItem.name === locData.name);
          if (!locData.commonLoc) {
            const { _id, _name } = loc.location;
            if (_id && _name) {
              locData.commonLoc = {
                _id,
                name: _name,
              };
            } else if (selectedItem) {
              setSelectedLocDate(locData, selectedItem);
            }
          } else if (locData.commonLoc._id !== loc._id) {
            if (selectedItem) {
              setSelectedLocDate(locData, selectedItem);
            } else {
              locData.commonLoc = null;
            }
          }
        });
      });
      state.tableData = data;
      state.reOrganizedOrdersPossible = total;
      // state.selectedItems = []; // Clearing the selected items when options are changed
      return { data: state.tableData, total };
    };

    const selectDisable = computed(() => {
      if (!isEmpty(state.selectedItems)) {
        const result = some(Object.values(state.selectedItems), (item) => !item.commonLoc?._id
          || !item.earliestOnsite || item.count > 50);
        return result;
      }
      return true;
    });

    const getSelectedRows = (items) => {
      state.selectedItems = items;
    };

    const getSearchValue = async (searchText) => {
      state.searchKey = searchText;
      await reorganizeTable.value.refreshTable();
    };

    const reorganize = async () => {
      try {
        state.isProgressBar = true;
        // state.isLoading = true;
        for (const ord of state.selectedItems) {
          const par = {
            projectId: state.projectId,
            reOrganizeBy: state.reOrganizeBy,
            prefix: state.prefixState.value,
            suffix: state.suffixState.value,
            orders: [ord],
          };
          await MaterialManager.createReorganizeOrder(par);
          state.progressCount += 1;
        }
        // state.isLoading = false;
      } catch (e) {
        console.log('Error:', e);
        toast.error('Error while Reorganizing items');
        state.isProgressBar = false;
        emit('close');
        emit('refresh-table');
      }
    };
    onBeforeMount(async () => {
      fireEvent(reOrganizeOpts[0]);
      [state.selectedOrganizeOption] = reOrganizeOpts;
      state.selectedProjectDetails = props.selectedProject;
      // const allProjs = store.state.queryParams._projects;
      // const projectIds = allProjs.map((project) => project._id);
      const projectIds = [];
      projectIds.push(props.selectedProject._id);
      if (!isEmpty(projectIds)) {
        state.allLocations = await Locations.allLocationForUser({
          projectId: projectIds,
        }, true);
      }
    });

    const getOrganizeName = (row) => {
      if (state.prefixState.value && state.suffixState.value) {
        return `${state.prefixState.value} - ${row.name} - ${state.suffixState.value}`;
      }
      if (state.prefixState.value) {
        return `${state.prefixState.value} - ${row.name}`;
      }
      if (state.suffixState.value) {
        return `${row.name} - ${state.suffixState.value}`;
      }
      return row.name;
    };
    const turnOffProgress = () => {
      setTimeout(() => {
        state.isProgressBar = false;
        emit('close');
        emit('refresh-table');
      }, 2000);
    };

    return {
      ...toRefs(state),
      getSelectedRows,
      loadData,
      getSearchValue,
      reorganizeTable,
      cancel,
      selectDisable,
      fireEvent,
      reOrganizeOpts,
      reorganize,
      toggleText,
      getOrganizeName,
      turnOffProgress,
    };
  },
};
</script>
<style scoped>
::v-deep(div.o-table__wrapper--sticky-header) {
  overflow-x: unset !important;
}
.organize-label {
  width: 120px;
}
</style>
