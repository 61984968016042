export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  stickeyHeaders: true,
  isCardView: true,
  fields: [{
    name: 'select',
    prop: 'selected',
    width: '5%',
    title: '',
    id: 'select',
  }, {
    name: 'field-generic-input',
    prop: 'name',
    width: '25%',
    title: 'Order Name',
    id: 'name',
  }, {
    name: 'field-generic-input',
    prop: 'financialId',
    width: '25%',
    title: 'Description',
    id: 'financialId',
  }, {
    name: 'field-generic-input',
    prop: 'owner.user.name',
    width: '30%',
    title: 'Owner',
    id: 'owner',
  }, {
    name: 'field-date',
    width: '15%',
    title: 'Onsite',
    id: 'onSite',
    prop: 'deliver',
    kind: 'dates',
    inputProps: {
      kind: 'deliver',
      // disableEdit: false,
    },
  }],
};
