<template>
  <div class="is-relative">
    <o-loading :active="isLoading" :full-page="false" :can-cancel="false"></o-loading>
    <div class="columns">
        <div class="column">
        <span class="is-size-4 has-text-black line-height">
          {{ selectedKind.desc }}
        </span>
      </div>
    </div>
    <div class="columns">
      <div class="column" v-if="selectedKind.shortHand !== 'shiftDays'">
          <div v-for="date in filteredDates" :key="date.value" class="columns">
            <div class="column is-4">
              <h3 class="has-text-black-bis has-text-weight-semibold is-italic">
                {{ date.label }}
              </h3>
            </div>
            <div class="column is-narrow">
              <div class="control">
                <mf-date
                :item="inputFields"
                :is-edit="true"
                :input-props="dateMinMax(date.value)"
                @setDate="setDate"
                >
                </mf-date>
              </div>
            </div>
          </div>
      </div>
      <div v-else class="is-flex is-justify-content-center column">
        <button class="button px-0 has-background-black-bis">
          <i class="icon-removedelete" @click="updateShiftVal('dec')"></i>
        </button>

          <input
            class="input mx-5"
            type="number"
            v-model.number="shiftBy"
            @input="onChange()"
          />
        <button class="button pr-0 pl-0 has-background-black-bis">
          <i class="icon-addnew"  @click="updateShiftVal('inc')"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive, toRefs, computed, watch,
} from 'vue';
import { useToast } from 'vue-toastification';
import moment from 'moment';
import MfDate from '@/components/abstract/MfDate.vue';
import _ from 'lodash';

export default {
  name: 'DatesUpdate',
  props: ['selectedKind', 'tab', 'cards', 'isLoading'],
  components: {
    'mf-date': MfDate,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const state = reactive({
      shiftBy: 0,
      datesMap: [
        {
          value: 'due',
          label: 'Due Date',
          screenToShow: ['clMassUpdate'],
        },
        {
          kind: 'coord',
          value: 'coord',
          label: 'Coordinate',
          isManager: false,
          max: 'deliver',
          lockedStages: ['detailing', 'manufacturing', 'qa', 'delivery'],
          screenToShow: ['psMassUpdate'],
        },
        {
          kind: 'detailBy',
          value: 'detailBy',
          label: 'Detail',
          isManager: true,
          lockedStages: ['manufacturing', 'qa', 'delivery'],
          screenToShow: ['psMassUpdate'],
        },
        {
          kind: 'poDetailBy',
          value: 'poDetailBy',
          label: 'Detail',
          isManager: false,
          lockedStages: [],
          screenToShow: ['psMassUpdate'],
        },
        {
          value: 'partsManufactureBy',
          label: 'Assembly Manufacture',
          isManager: false,
          lockedStages: ['qa', 'delivery'],
          screenToShow: ['psMassUpdate'],
        },
        {
          kind: 'manufactureBy',
          value: 'manufactureBy',
          label: 'Manufacture',
          isManager: true,
          lockedStages: ['qa', 'delivery'],
          screenToShow: ['psMassUpdate'],
        },
        {
          kind: 'poManufactureBy',
          value: 'poManufactureBy',
          label: 'Manufacture',
          isManager: false,
          lockedStages: [],
          screenToShow: ['psMassUpdate'],
        },
        {
          value: 'qaBy',
          kind: 'qaBy',
          label: 'QA',
          isManager: true,
          lockedStages: ['delivery'],
          screenToShow: ['psMassUpdate'],
        },
        {
          value: 'poQaBy',
          kind: 'poQaBy',
          label: 'QA',
          isManager: false,
          lockedStages: [],
          screenToShow: ['psMassUpdate'],
        },
        {
          value: 'deliver',
          kind: 'deliver',
          label: 'Onsite',
          min: 'coord',
          isManager: false,
          lockedStages: [],
          screenToShow: ['psMassUpdate', 'msMassUpdate'],
        },
      ],
      inputFields: {
        due: null,
        coord: null,
        detailBy: null,
        manufactureBy: null,
        qaBy: null,
        deliver: null,
      },
      filteredDates: [],
      updatedFields: {},
      errorLabels: {
        orderBy: 'On Site By',
        shipBy: 'Ship By',
      },
    });

    const dateMinMax = (date) => {
      const datesOrder = _.map(state.datesMap, 'value');
      const idx = _.indexOf(datesOrder, date);
      const obj = { kind: date };
      if (idx > 0) obj.min = datesOrder.slice(0, idx);
      if (idx < datesOrder.length - 1) obj.max = datesOrder.slice(idx + 1);
      return obj;
    };

    const updateShiftVal = ((val) => {
      if (val === 'dec') {
        state.shiftBy -= 1;
      } else if (val === 'inc') {
        state.shiftBy += 1;
      }
      if (state.shiftBy === 0) {
        emit('toggleBtn', false);
      } else {
        emit('toggleBtn', true);
      }
    });

    const onChange = (() => {
      state.shiftBy = _.round(state.shiftBy);
      if (state.shiftBy === 0) {
        emit('toggleBtn', false);
      } else {
        emit('toggleBtn', true);
      }
      return null;
    });

    const offsetKind = computed(() => {
      if (props.selectedKind.shortHand === 'offset') {
        for (const date of state.datesMap) {
          if (state.inputFields[date.value]) return date.value;
        }
      }
      return 'deliver';
    });

    const getDatesOrder = (card) => {
      let order = card.isPM() ? [
        'coord',
        'detailBy',
        'manufactureBy',
        'qaBy',
        'deliver',
      ] : [
        'coord',
        'poDetailBy',
        'poManufactureBy',
        'poQaBy',
        'deliver',
      ];
      if (props.tab === 'msMassUpdate') {
        order = [
          'deliver',
        ];
      }
      if (card.isKit()) order.splice(2, 0, 'partsManufactureBy');
      return order;
    };

    const getDateOffset = (oldVal, newVal) => {
      if (!oldVal) return false;
      const diff = moment(oldVal).startOf('day').diff(moment(newVal).startOf('day'), 'd');
      return diff;
    };

    const handlePODates = (kind) => {
      const poDateMap = {
        poDetailBy: 'detailBy',
        poManufactureBy: 'manufactureBy',
        poQaBy: 'qaBy',
      };
      if (poDateMap[kind]) {
        state.updatedFields[kind] = _.get(state.updatedFields, poDateMap[kind], null);
      }
    };

    const calculateIndividualOffsetDates = (card, place, kind, offset) => {
      const originalDate = _.get(card, place, null);
      state.updatedFields[kind] = originalDate ? card.getDateDiff(originalDate, offset) : null;
    };

    const isDateLocked = (date, stage) => !!date.lockedStages.includes(stage);
    state.datesMap = _.filter(state.datesMap,
      (mtd) => mtd.screenToShow && mtd.screenToShow.includes(props.tab));
    state.filteredDates = state.datesMap.filter((date) => !date.value.startsWith('po') && !date.value.startsWith('parts')
        && (!['shiftDays'].includes(props.selectedKind.shortHand)));

    const validateOrders = (orders) => {
      if (state.shiftBy && state.shiftBy > 730) {
        toast.warning('Set number of days in range 0 - 730.');
      }

      state.updatedFields = _.cloneDeep(state.inputFields);

      const addedDates = _(state.updatedFields).values().compact().value();
      if (_.isEmpty(addedDates) && !state.shiftBy) {
        toast.warning('Set atleast 1 date before updating.');
      }

      const updatedData = {
        valid: [],
        invalid: [],
      };

      for (const card of orders) {
        card._beforeEdit = _.cloneDeep(card);
        let place = `simpleDates.${offsetKind.value}['value']`;
        if (['detailBy', 'manufactureBy', 'qaBy'].includes(offsetKind.value)) {
          place = card.isPM() ? `manager.simpleDates.${offsetKind.value}['value']`
            : `simpleDates.po${_.upperFirst(offsetKind.value)}['value']`;
        }
        let offset;
        if (props.selectedKind.shortHand === 'offset') {
          // don't allow offset update if choosen date itself is locked
          if (_.find(state.datesMap, (date) => date.value === offsetKind.value)
            .lockedStages.includes(card.stage)) {
            card._isValid = false;
            card._errorMsg = `Selected offset date is locked for orders in ${card.stage} stage`;
            updatedData.invalid.push(card);
            continue;
          }
          offset = getDateOffset(
            _.get(card, place, null),
            state.inputFields[offsetKind.value],
          );
        } else {
          offset = -state.shiftBy;
        }

        if (offset === false) {
          card._isValid = false;
          card._errorMsg = 'Missing Fields';
          updatedData.invalid.push(card);
          continue;
        }
        card.lockedDateAttempts = [];
        const datesOrder = getDatesOrder(card);
        for (const date of state.datesMap) {
          if (!datesOrder.includes(date.value)) continue;
          place = card.isPM() && date.isManager ? `manager.simpleDates.${date.value}['value']` : `simpleDates.${date.value}['value']`;

          if (_.isUndefined(_.get(card, place))) {
            if (card.isPM() && date.isManager) {
              card.manager.newDate(date.value);
            } else {
              card.newDate(date.value);
            }
          }
          handlePODates(date.value);
          if (['offset', 'shiftDays'].includes(props.selectedKind.shortHand)) {
            // set updatedField to newly calculated date
            calculateIndividualOffsetDates(card, place, date.value, offset);
          }
          if (!isDateLocked(date, card.stage) && state.updatedFields[date.value]) {
            // don't set date if locked or value undefined
            _.set(card, place, state.updatedFields[date.value]);
          } else if (state.updatedFields[date.value]) {
            card.lockedDateAttempts.push(date.label);
          }
        }

        const { isValid, failedDateKinds } = card.checkDateValidation('massUpdate', datesOrder);

        if (isValid) {
          if (addedDates.length > card.lockedDateAttempts.length
           || ['offset', 'shiftDays'].includes(props.selectedKind.shortHand)) {
            // if there's value in shiftBy, that means onsite too will change. Hence always valid.
            updatedData.valid.push(card);
          }
          if (card.lockedDateAttempts.length) {
            const errMsg = `Past stage dates - ${card.lockedDateAttempts} - cannot be updated. All other dates will be updated.`;
            card._isValid = false;
            card._errorMsg = errMsg;
            updatedData.invalid.push(card);
          }
        } else {
          const datesMapForMsg = _.reduce(state.datesMap,
            (acc, { value, label }) => ({ ...acc, [value]: label }), {});
          const errMsg = `'${datesMapForMsg[failedDateKinds[1]]}' date must be after 
            '${datesMapForMsg[failedDateKinds[0]] || state.errorLabels[failedDateKinds[0]]}' date.`;
          card._isValid = false;
          card._errorMsg = errMsg;
          updatedData.invalid.push(card);
        }
        emit('updated-lists', updatedData);
        emit('close');
      }
    };

    const setDate = (dateObj) => {
      if (props?.selectedKind?.shortHand !== 'offset') return;
      const val = _.get(dateObj, 'val', null);
      const kind = _.get(dateObj, 'kind.value', '');
      _.forEach(state.inputFields, (val, key) => {
        _.set(state.inputFields, key, null);
      });
      _.set(state.inputFields, kind, val);
      emit('toggleBtn', true);
    };

    watch(state.inputFields, (val) => {
      if (Object.values(val).some((dt) => dt !== null)) {
        emit('toggleBtn', true);
      }
    }, { deep: true });

    return {
      ...toRefs(state),
      validateOrders,
      updateShiftVal,
      onChange,
      dateMinMax,
      setDate,
    };
  },
};

</script>
<style scoped>
  .input {
    width: 48px;
  }
  ::v-deep(.o-drop.o-dpck__dropdown) {
    justify-content: flex-end !important;
  }
</style>
