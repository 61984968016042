<template>
    <div>
     <VDropdown placement="right" :shown="isOpen">
      <template #popper>
        <div class="select-order-modal">
        <div class="card">
          <header class="card-header  has-background-grey-lighter">
              <h4
                class="card-header-title is-size-3 has-text-black-bis pl-0
                    is-capitalized has-text-weight-bold"
              >
                Selected Orders ({{ordersCount}})
              </h4>
              <button @click="close()" class="button px-0">
                 <i class="icon-close"></i>
              </button>
            </header>
          <div class="field mb-0">
          <h5 class="card-header-title is-size-5 has-text-black-bis
                  is-capitalized has-text-weight-bold is-italic">  Work Step: {{run.name}} </h5>
          </div>
          <div class="searchbar pl-4 pr-4 mb-2">
            <div class="field">
              <p class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="searchText"
                />
                <span class="icon is-left">
                  <i class="icon-search"></i>
                </span>
                <span class="icon is-small is-right" @click="clearSearch">
                  <i class="icon-close is-clickable"></i>
                </span>
              </p>
            </div>
          </div>
          <section class="card-content is-marginless has-background-white">
              <div
                id="tag-field"
                class="field is-grouped is-grouped-multiline"
              ></div>
              <div class="tags-body">
                <div v-if="cards ">
                    <button v-for="(card, idx) in filteredOrders" :key="card._id"
                    class="button has-text-black-bis is-fullwidth is-flex is-justify-content-space-between"
                    >
                      <span class="is-size-3" v-if="!card.isRemoved">{{ card.name }}</span>
                      <button class="icon is-small has-background-black-bis  has-text-white">
                   <i class="icon-removedelete" v-if="!card.isRemoved" @click="removeCard(card._id)"></i>
                      </button>
                    </button>
                </div>
                <span
                  v-if="!cards"
                  class="has-text-black-bis has-text-weight-bold"
                >
                  {{ $_.startCase('no orders selected') }}
                </span>
              </div>
          </section>
          <footer
            class="card-footer is-justify-content-flex-end has-background-grey-lighter"
          >
            <div>
              <button
                class="button has-background-black-bis has-text-white is-pulled-right"
                @click="close"
              >
                Done
              </button>
            </div>
          </footer>
        </div>
        </div>
      </template>
    </VDropdown>
    </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, computed, onMounted,
} from 'vue';
import { set, find } from 'lodash';

const MyComponent = defineComponent({
  props: {
    isOpen: Boolean,
    cards: Array,
    run: Object,

  },
  setup(props, { emit }) {
    const data = reactive({
      searchText: '',
      orders: [],
      ordersCount: 0,
    });
    const fetchOrders = () => {
      data.orders = props.cards.filter((card) => !card.isRemoved);
    };

    const removeCard = (idx) => {
      const card = find(props.cards, { _id: idx });
      set(card, 'isRemoved', true);
      // fetch the new list
      fetchOrders();
      emit('removeOrder', props.cards);
    };

    const close = () => {
      emit('closePopover', props.cards.reduce((acc, intial) => {
        if (intial.isRemoved) return null;
        return acc;
      }), []);
    };

    onMounted(() => {
      fetchOrders();
    });

    const filteredOrders = computed(() => {
      if (data.searchText.length) {
        return data.orders.filter((order) => order.name.toLowerCase().includes(data.searchText.toLowerCase()));
      }
      return data.orders;
    });

    const ordersCount = computed(() => data.orders.length);

    const clearSearch = () => {
      data.searchText = '';
    };

    return {
      ...toRefs(data),
      close,
      removeCard,
      filteredOrders,
      clearSearch,
      ordersCount,
    };
  },
});

export default MyComponent;
</script>

<style scoped>
.card {
  width: 432px !important;
}

</style>
