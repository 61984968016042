export default {
  PROJECT: {
    field: 'project',
  },
  DESCRIPTION: {
    field: 'financialId',
    multiple: false,
  },
  NAME: {
    field: 'name',
    multiple: false,
    required: true,
  },
  COORDINATION: {
    field: 'coord',
    multiple: false,
  },
  ONSITE: {
    field: 'deliver',
    multiple: false,
  },
  DETAILBY: {
    field: 'poDetailBy',
    multiple: false,
  },
  OWNER: {
    field: 'prefabOwner',
    multiple: false,
  },
  'DOCUMENT NAMES': {
    field: 'docNames',
    multiple: false,
  },
  DOCUMENTS: {
    field: 'documents',
    multiple: false,
  },
  NOTES: {
    field: 'note',
    multiple: false,
  },
};
