<template>
  <div class="login-tag">
    <div class="header is-vertical-center">
      <figure class="level-item has-text-centered is-centered">
        <img src="./../assets/logo-login.png" />
      </figure>
    </div>
    <div class="login-main is-flex is-justify-content-center is-align-items-center">
      <div class="card">
        <header class="card-header has-background-white-ter is-size-5">
          <p
            class="card-header-title is-uppercase has-text-dark is-justify-content-center"
          >
            {{ $t('reset password') }}
          </p>
        </header>
        <div class="card-content">
          <div class="field">
            <p class="control has-icons-right">
              <input
                class="input"
                v-model="user.newPassword"
                :placeholder="$t('Choose Password')"
                minlength="8"
                :type="password ? 'text' : 'password'"
              >
              <span
                class="icon is-small is-right is-clickable"
                @click="password = !password"
                @mouseover="
                  password
                    ? (eyeIcon = 'icon-hide')
                    : (eyeIcon = 'icon-show')
                "
                @mouseleave="
                  password
                    ? (eyeIcon = 'icon-show')
                    : (eyeIcon = 'icon-hide')
                "
              >
                <i :class="eyeIcon"></i>
              </span>
            </p>
          </div>
          <div class="field">
            <p class="control has-icons-right">
              <input
                class="input"
                :type="verifyPassword ? 'text' : 'password'"
                v-model="user.confirmPassword"
                :placeholder="$t('Verify Password')"
              >
              <span
                class="icon is-small is-right is-clickable"
                @click="verifyPassword = !verifyPassword"
                @mouseover="
                  verifyPassword
                    ? (verifyEyeIcon = 'icon-hide')
                    : (verifyEyeIcon = 'icon-show')
                "
                @mouseleave="
                  verifyPassword
                    ? (verifyEyeIcon = 'icon-show')
                    : (verifyEyeIcon = 'icon-hide')
                "
              >
                <i :class="verifyEyeIcon"></i>
              </span>
            </p>
          </div>
          <div class="is-offset-one-quarter">
          </div>
        </div>
        <footer
          class="card-footer has-background-white-bis is-justify-content-flex-end -small"
        >
        <a @click="resetPassword()" class="button login-button is-uppercase has-background-black-bis has-text-white">Reset</a>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import {
  reactive, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Company from '@/models/Companies';
import { useToast } from 'vue-toastification';

export default {
  name: 'resetPassword',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const state = reactive({
      password: false,
      verifyPassword: false,
      email: '',
      key: '',
      user: {
        newPassword: '',
        confirmPassword: '',
      },
      icons: ['times-circle', 'exclamation-circle'],
      eyeIcon: 'icon-hide',
      verifyEyeIcon: 'icon-hide',

    });
    const resetPassword = async () => {
      if (state.user.newPassword !== state.user.confirmPassword || state.user.newPassword === '' || state.user.confirmPassword === '') {
        toast.error('Please match the passwords');
      } else {
        try {
          state.email = route.query.email;
          state.key = route.query.key;
          await Company.resetPassword({
            passwords: {
              email: state.email,
              key: state.key,
              newPassword: state.user.newPassword,
              repeatPassword: state.user.confirmPassword,
            },
          });
          toast.success('Password reset is done');
          router.push({ path: '/login' });
        } catch (e) {
          // alertMessage(e);
          if (e.data?.message) {
            toast.error(e.data.message);
          }
          console.log('error', e);
        }
      }
    };
    return {
      ...toRefs(state),
      resetPassword,
    };
  },
};
</script>

<style scoped>
.card {
  width: 432px;
  border-radius: 8px;
}
::v-deep(.eye-button) {
  position: absolute;
  right: 7px;
  top: 7px;
}
.control.has-icons-left .icon.is-left,
.control.has-icons-right .icon.is-right {
  height: 100%;
}

</style>
