import { find, filter, get } from 'lodash';

const optsObj = {
  editDelivery: {
    icon: '',
    label: 'Edit Delivery',
    event: 'manage',
  },
  cancelDelivery: {
    icon: '',
    label: 'Cancel',
    event: 'cancelDelivery',
  },
  markFinal: {
    icon: '',
    label: 'Mark Final',
    event: 'markFinal',
  },
  reactivate: {
    icon: '',
    label: 'Reactivate',
    event: 'reactivate',
  },
  printQr: {
    icon: 'fa fa-qrcode',
    label: 'Print QR',
    event: 'printQr',
  },
  releaseToInv: {
    icon: '',
    label: 'Release to Inventory',
    event: 'release',
  },
  receive: {
    icon: '',
    label: 'Receive',
    event: 'receive',
    isDisabled(card) {
      if (card.partialsCount > 1) return true;
      return false
    }
  },
  removeLabel: {
    icon: 'icon-removedelete',
    label: 'Delete',
    event: 'remove',
  },
  scheduleDelivery: {
    icon: '',
    label: 'Schedule A New Delivery',
    event: 'scheduleDelivery',
  },
};

export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  stickeyHeaders: true,
  isDetailed: true,
  rearrangeCols: false,
  detailRowComponent: 'mf-detail-row',
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      isLine: true,
      tooltip: 'Customise Columns',
    }],
  },
  toolBar: {
    // name: for drop down label, label: for action button label
    bulkActions: () => {
      const opts = [];
      opts.push({
        name: 'Print QR Labels', event: 'bulkQrPrint', label: 'Print QR', refreshOnCancel: true,
      });

      return opts;
    },
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: [{
      event: 'excelExport',
      icon: 'icon-exportexcel',
      tooltip: 'Export to Excel',
    }],
  },
  fields: [
    {
      title: 'Shipping Order',
      name: 'field-generic-input',
      sortField: 'name',
      prop: 'name',
      width: '400px',
      sticky: true,
      id: 'name',
      isClickable: true,
      eventType: 'openCard',
      defaultVisible: true,
      alwaysVisible: true,
      searchField: false, // to give a search bar for the field
      searchable: true, // to place any custom field in the header of the column like for below
      bulkActionButtonField: true,
      inputProps: {
        type: 'text',
      },
    },
    {
      title: 'ID',
      name: 'field-generic-input',
      sortField: 'delivery.trackingId',
      prop: 'delivery.trackingId',
      id: 'id',
      width: '152px',
      defaultVisible: false,
      isDisabled: false,
      inputProps: {
        type: 'text',
        iconForShipping: 'fa-truck',
      },
    }, {
      title: 'Project',
      name: 'field-generic-input',
      prop: 'project.name',
      // sortField: 'project',
      searchable: true,
      filterText: 'Projects',
      width: '296px',
      id: 'project',
      isDisabled: true,
      defaultVisible: true,
      alwaysVisible: false,
    }, {
      title: 'Status',
      name: 'field-stage',
      prop: 'statusToShow',
      sortField: 'status',
      width: '196px',
      id: 'status',
      searchable: true,
      filterText: 'Status',
      defaultVisible: true,
      alwaysVisible: false,
      isStatus: true,
      props: {
        isStatus: true,
      },
    }, {
      title: 'Delivs',
      name: 'table-field-icon',
      width: '64px',
      id: 'shipDeliveries',
      defaultVisible: true,
      alwaysVisible: true,
    },
    {
      name: 'field-user-select',
      width: '296px',
      id: 'owner',
      title: 'Owner',
      defaultVisible: true,
      searchable: true,
      filterText: 'Owners',
      prop: 'delivery.owner',
      isShippingListView: true,
      cardProps: {
        projectId: '_delivery.currentProject._id',
      },
      inputProps: {
        isDisabled: (data) => data.shipType === 's',
      },
    }, {
      name: 'field-user-select',
      width: '296px',
      id: 'recipient',
      searchable: true,
      filterText: 'Recipients',
      // sortField: 'delivery.recipeint',
      defaultVisible: true,
      title: 'Recipient',
      prop: 'delivery.recipient',
      isShippingListView: true,
      cardProps: {
        projectId: '_delivery.currentProject._id',
      },
      inputProps: {
        isDisabled: (data) => data.shipType === 's',
      },
    },
    {
      title: 'Current Loc',
      name: 'field-location-select',
      sortField: 'delivery.currentLocation',
      width: '296px',
      id: 'nowAt',
      defaultVisible: true,
      searchable: true,
      filterText: 'Current Loc',
      prop: 'delivery.currentLocation',
      isForcedEditing: false,
      cardProps: {
        companyId: 'created.by.company._id',
        projectId: 'project._id',
        currentProjectId: 'delivery.currentProject._id',
        deliveryProjectId: 'delivery.deliveryProject._id',
      },
    },
    {
      title: 'Delivery Location',
      name: 'field-location-select',
      prop: 'delivery.deliveryLocation',
      sortField: 'delivery.deliveryLocation',
      width: '296px',
      id: 'deliveryLocation',
      defaultVisible: true,
      alwaysVisible: false,
      searchable: true,
      filterText: 'Delivery Loc',
      cardProps: {
        companyId: 'created.by.company._id',
        projectId: 'project._id',
        isEditing: 'isEditing',
        currentProjectId: 'delivery.currentProject._id',
        deliveryProjectId: 'delivery.deliveryProject._id',
      },
      inputProps: {
        isDisabled: (data) => data.shipType === 's',
      },
    },
    {
      title: 'Ship By',
      name: 'field-date',
      defaultVisible: true,
      alwaysVisible: false,
      prop: 'minShipDate',
      sortField: 'delivery.deliveryStart',
      kind: 'dates',
      width: '152px',
      id: 'shipDate',
      searchable: true,
      dateFilter: 'minShip',
      inputProps: {
        kind: 'minShipDate',
        max: 'maxdeliverDate',
        disableEdit: (data) => {
          if (['in-transit', 'in-storage', 'complete', 'zombie', 'fulfilled','mixed','not-started'].includes(data.status)) {
            return true;
          }
          return false;
        },
      },
    }, {
      title: 'Deliver By',
      name: 'field-date',
      defaultVisible: true,
      alwaysVisible: false,
      sortField: 'delivery.deliverBy',
      prop: 'maxdeliverDate',
      kind: 'dates',
      width: '152px',
      id: 'deliveryDate',
      searchable: true,
      dateFilter: 'maxDeliver',
      inputProps: {
        kind: 'maxdeliverDate',
        min: 'maxdeliverDate',
        disableEdit: (data) => data.shipType === 's',
      },
    }, {
      title: 'Earliest Onsite',
      name: 'field-date',
      defaultVisible: true,
      alwaysVisible: false,
      prop: 'minItemDelivery',
      sortField: 'minItemDelivery',
      kind: 'dates',
      width: '152px',
      id: 'onSiteFinal',
      searchable: true,
      dateFilter: 'deliver',
      inputProps: {
        kind: 'minItemDelivery',
        disableEdit: true,
      },
    }, {
      name: 'field-notes-icon',
      title: 'Notes',
      prop: 'delivery.notes',
      defaultVisible: true,
      width: '64px',
      id: 'notes',
      inputProps: {
        isItem: false,
        isShipping: true,
      },
    },
    {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      hideFromChooser: true,
      alwaysVisible: true,
      width: '112px',
      sticky: true,
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      searchable: true,
      clearBtn: true,
      permissionModule: 'shipping',
      inputProps: {
        options(card, store) {
          let opts = [];
          if (card.items.length) {
            let userBelogsToProject = card.haveAccess ? card.haveAccess : find(
              store.state.queryParams._projectsAndGI,
              { _id: card.project._id },
            );
            // For child projects check as _projectsAndGI doesn't include Child projects.
            if (!userBelogsToProject) {
              for (const project of store.state.queryParams._projectsAndGI) {
                if (project.isMaster) {
                  if (find(project.childProjects, { _id: card.project._id })) {
                    userBelogsToProject = true;
                  }
                }
              }
              // every user belongs to company's common stock project.
              if (card.project._id === store.state.queryParams.commonStockProject._id) {
                userBelogsToProject = true;
              }
            }
            if (card.status === 'not-started') {
              opts = [];
              if (userBelogsToProject) {
                if (card.kind === 'forward' && card.shipType !== 'm' && card.itemKind !== 'Sourcing') opts.push(optsObj.removeLabel);
                if (card.shipType === 's-m' && card.delivery.deliveryStart) opts.push(optsObj.cancelDelivery);
              }
            } else if (card.status === 'in-transit') {
              if (userBelogsToProject) {
                opts = [optsObj.receive, optsObj.cancelDelivery];
                if (card.kind === 'forward' && card.shipType !== 'm' && card.itemKind !== 'Sourcing') opts.push(optsObj.removeLabel);
              }
            } else if (card.status === 'in-storage') {
              if (userBelogsToProject) {
                opts = [optsObj.releaseToInv, optsObj.markFinal, optsObj.scheduleDelivery];
              }
            }
            const status = ['fulfilled', 'zombie', 'consumed', 'released-to-inventory', 'mixed'];
            if (status.includes(card.status) && card.shipType === 'm') {
              if (!userBelogsToProject) {
                optsObj.managePartials.label = optsObj.managePartials.viewLabel;
              }
              // opts = [optsObj.managePartials];
            }
            if ((card.status === 'fulfilled' || card.status === 'zombie') && userBelogsToProject) opts.push(optsObj.removeLabel);
            if (card.shipType !== 'm' && userBelogsToProject) opts.push(optsObj.printQr);
            if (userBelogsToProject) {
              opts.push({
                icon: 'fa fa-history',
                label: 'History',
                event: 'activity',
              });
            }
          }
          return opts;
        },
        showEdit: (rowData, user) => {
          const cardCompany = get(rowData, 'delivery.recipient.userDetail.company');
          const userCompany = get(user,'company._id', user.company);
          if (cardCompany?.toString() !== userCompany?.toString()) {
            return false;
          }
          if (['in-storage', 'zombie', 'fulfilled', 'consumed'].includes(rowData.status) && user._id) {
            return false;
          }
          return true;
        },
        disableEdit: (rowData, store) => {
          if (find(store.state.queryParams._projectsAndGI, { _id: rowData.project._id })
          || store.state.queryParams.commonStockProject._id === rowData.project._id) {
            return false;
          }
          return true;
        },
        reviveEnabled: (rowData, user) => {
          if (rowData.status === 'in-storage' && rowData.shipType !== 'm' && user._id) {
            return true;
          }
          return false;
        },
        shipPermissionDisabled: (rowData) => {
          if (!['in-storage', 'not-started'].includes(rowData.status)) {
            return true;
          }
          if ('haveAccess' in rowData && !rowData.haveAccess) {
            return true;
          }
          if (!rowData.itemElementsCount || rowData.shipType === 'm') {
            // Disables inline edit when shipment has no items
            return true;
          }
          return false;
        },
      },
    },
  ],
};
