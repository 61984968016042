<template>
    <div class="modal-card">
      <header class="modal-card-head is-borderless">
        <p class="card-header-title is-justify-content-left has-text-black-bis">
          {{ title }}
        </p>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="closeModal"></i>
      </header>
      <section class="modal-card-body is-visible has-text-black-bis">
          <p class="line-height mb-2" v-html="message"></p>
          <o-checkbox
            v-model="isNotPurchased"
          >
            Mark Items as Not Purchased
          </o-checkbox>
          <p class="has-text-weight-semibold is-italic mb-1">Explanation</p>
          <textarea class="textarea" rows="2"
            v-model="explanation" placeholder="Optional"></textarea>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
          <button class="button is-outlined" @click="closeModal">
            Cancel
          </button>
          <button class="button has-background-black-bis" @click="archiveSourcingOrder">
            Reject
          </button>
      </footer>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { map } from 'lodash';
import { useToast } from 'vue-toastification';
import MaterialManager from '@/models/MaterialManager';
import { useStore } from 'vuex';

export default {
  props: {
    orderId: String,
    items: {
      type: Array,
      default: () => [],
    },
    itemReject: {
      type: Boolean,
      default: false,
    },
    isActive: Boolean,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const data = reactive({
      explanation: '',
      isNotPurchased: false,
    });
    const store = useStore();

    const title = computed(() => {
      if (props.itemReject) {
        return props.items.length > 1 ? 'Reject Items?' : 'Reject Item';
      }
      return 'Reject Sourcing Order';
    });

    const message = computed(() => {
      if (props.itemReject) {
        const errMsg = props.items.length > 1
          ? 'This will reject the items and return them to Requesting. Continue?'
          : 'This will reject the item and return it to Requesting. Continue?';
        return errMsg;
      }
      return 'The Sourcing Order will be rejected and returned to Requesting.';
    });

    const methods = {
      closeModal() {
        emit('close');
      },
      async archiveSourcingOrder() {
        const params = {};
        params.orderId = props.orderId;
        params.rejectNotes = data.explanation;
        params.isNotPurchased = data.isNotPurchased;
        if (props.itemReject) {
          params.itemReject = true;
          params.itemIds = map(props.items, '_id');
          params.isNotPurchased = data.isNotPurchased;
        }
        const updatedOrder = await MaterialManager.reject(params);
        if (!props.itemReject) toast.warning('Order Rejected');
        else { toast.success(`Items rejected, marked as${(data.isNotPurchased ? ' Not' : '')} Purchased`); }
        if (props.itemReject && updatedOrder.items.length === 0) {
          if (store.state.activeScreen === 'schedule') {
            emit('refresh-card', { type: 'refresh-card' });
          } else if (route.query.fromSchedule) {
            router.push({ path: '/prefab/schedule' });
          } else {
            router.push({ path: '/materials/sourcing' });
          }
        } else {
          emit('refresh-card');
        }
        methods.closeModal();
      },
    };

    return {
      ...toRefs(data),
      ...methods,
      title,
      message,
    };
  },
};
</script>
