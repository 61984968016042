<template>
  <div class="is-flex is-justify-content-center">
    <VDropdown placement="right" :autoHide="false">
      <div class="is-relative items-count is-clickable">
        <span>{{ selected.length }}</span>
        <i class="icon-attacheditemsOL is-size-3"></i>
      </div>
      <template #popper>
        <div class="card">
          <header class="card-header  has-background-grey-lighter">
            <h4
              class="card-header-title is-size-3 has-text-black-bis pl-0
                is-capitalized has-text-weight-bold"
            >
              Items ({{ selected.length }})
            </h4>
          </header>
          <section class="card-content is-marginless has-background-white">
           <div v-if="itemView" class="has-text-black-bis line-height mb-3
            has-text-weight-semibold">
              order: {{ rowData.name }}
            </div>
            <div v-if="itemView" class="tags-body kit-item">
              <div class="mb-2" v-for="item in rowData.items" v-bind:key="item._id">
                <button class="button taghover is-fullwidth
                  is-flex is-justify-content-space-between has-background-black-bis has-text-white"
                >
                  <span class="is-size-3">{{ item.name }}</span>
                </button>
              </div>
            </div>
            <div v-else>
               <div class="has-text-black-bis line-height mb-3 has-text-weight-semibold">
                  Work Step: {{ rowData.name }}
               </div>
            <div class="card-action is-justify-content-space-between" v-if="isEditing">
              <div>
                <button class="button is-danger has-text-danger has-text-weight-bold is-outlined"
                  @click="clearAll()">
                  <span>Clear All Items</span>
                </button>
                <button class="button has-background-black-bis has-text-white has-text-weight-bold"
                    @click="selectAll()"
                  >
                  <span>Add All Items ({{allItems.length}})</span>
                </button>
              </div>
              <div>
                <button @click="cancelSelected()" class="button has-text-black-bis p-0">
                  <i class="icon-close"></i>
                </button>
                <button @click="saveAll()" class="has-text-success p-0 button">
                  <i class="icon-checkmark"></i>
                </button>
              </div>
            </div>
            <div v-else-if="!disableEditButton()" class="card-action is-justify-content-flex-end">
              <button
                @click="onEdit()"
                :disabled="isDisabled"
                class="button has-text-black-bis p-0"
              >
                <i class="icon-edit"></i>
              </button>
            </div>
            <div class="tags-body" :class="disableEditButton() ? 'kit-item' : ''">
              <div class="mb-2"
                v-for="item in allItems" v-bind:key="item._id">
                <button class="button taghover is-fullwidth
                  is-flex is-justify-content-space-between"
                  v-if="(!isEditing && isItemSelected(item)) || isEditing"
                  :class="isItemSelected(item) ? 'has-background-black-bis has-text-white'
                    : 'is-outlined'"
                  @click="addOrRemoveItem(item)"
                  :disabled="['detailing', 'manufacturing', 'qa', 'manager-edit-qa',
                    'production-status-order-view'].includes($store.state.activeScreen)"
                >
                  <span class="is-size-3">{{ item.name }}</span>
                  <span class="icon is-small">
                    <i v-if="isItemSelected(item)"
                      class="icon-removedelete"></i>
                    <i v-else class='icon-addnew'></i>
                  </span>
                </button>
              </div>
            </div>
            </div>
          </section>
          <footer class="card-footer is-justify-content-flex-end has-background-grey-lighter">
            <div class="buttons">
              <button v-if="!itemView" v-close-popper="true"
                class="button is-outlined has-text-weight-bold"
                @click="cancelSelected"
              >
                Cancel
              </button>
              <button v-close-popper="true"
                class="button has-background-black-bis has-text-white is-pulled-right"
                @click="onDone"
              >
                Done
              </button>
            </div>
          </footer>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  toRefs,
} from 'vue';
import {
  uniqBy, filter, map, set, cloneDeep, find, pick, isUndefined,
} from 'lodash';
import { useStore } from 'vuex';
import { RunItemsCount } from '@/models/BaseOrder';
import { useToast } from 'vue-toastification';
import { CardDirtyBus } from '@/utils/CardDirtyBus';

export default defineComponent({
  name: 'ItemSelect',
  props: {
    rowData: Object,
    itemView: Boolean,
  },
  setup(props) {
    const { rowData, itemView } = props;
    const store = useStore();
    const toast = useToast();
    const state = reactive({
      initialRiCopy: [],
      isEditing: false,
    });
    const disableEditButton = () => ['detailing', 'manufacturing', 'qa'].includes(store.state.activeScreen);
    const selected = computed(() => uniqBy(itemView ? rowData.items : rowData.runItemsCount));
    const allItems = computed(() => {
      const actualRuns = [];
      if (itemView) {
        return filter(rowData.items, (i) => !i.item.archived.value);
      }

      for (let i = 0; i <= rowData.runItemsCount.length - 1; i++) {
        actualRuns.push(rowData.runItemsCount[i]._item);
      }
      return actualRuns;

      return null;
    });
    const runItemsIndexMap = computed(() => rowData.order.manager._runItemsIndexMap);
    const isItemSelected = (item) => {
      const allSelectedItems = map(selected.value, '_item');
      return (map(allSelectedItems, '_id')).includes(item._id);
    };
    const isDisabled = computed(() => ['delivery', 'qa', 'complete'].includes(rowData.order?._customStage)
      || store.state.activeScreen === 'production-status-order-view');
    const cancelSelected = () => {
      state.isEditing = false;
      if (!isUndefined(rowData.runItemsCount?._beforeEdit)) {
        set(rowData, 'runItemsCount', rowData.runItemsCount._beforeEdit);
      }
    };
    const onEdit = () => {
      state.isEditing = true;
      set(rowData, 'runItemsCount._beforeEdit', cloneDeep(rowData.runItemsCount));
    };
    const saveAll = () => {
      state.isEditing = false;
      set(rowData, 'runItemsCount._beforeEdit', cloneDeep(rowData.runItemsCount));
      CardDirtyBus.emit('setCustomDirty');
    };
    const defaultRi = (item) => {
      const run = rowData;
      return rowData.order.manager.getDefaultRi({ item, run });
    };
    const setSlimRi = () => {
      if (!props.rowData) return;
      const slimRI = props.rowData.runItemsCount.map((x) => pick(x, RunItemsCount.fieldsToClone));
      state.initialRiCopy = cloneDeep(slimRI);
      set(rowData, 'runItemsCount._beforeEdit', cloneDeep(rowData.runItemsCount));
    };

    const onSelect = (op) => {
      if (state.initialRiCopy.length === 0) setSlimRi();
      const oldRi = find(state.initialRiCopy, { uuid: op.uuid });
      if (oldRi) oldRi._item = op;
      const len = rowData.runItemsCount.length;
      // len is last index + 1, so can be used directly
      set(rowData.runItemsCount, len, oldRi || defaultRi(op));
      rowData.order.manager._runItemsIndexMap[rowData.uuid][op.uuid] = len;
    };
    const riIndex = (itemId) => runItemsIndexMap.value[rowData.uuid][itemId];
    const onRemove = (op) => {
      if (op.$isDisabled || (op._item && op._item.$isDisabled)) {
        toast.error('Only items in this stage can be added/removed');
        return;
      }
      const idx = riIndex(op.uuid);
      if (idx > -1) rowData.runItemsCount.splice(idx, 1);
      rowData.order.manager.runItemsIndexMap();
    };
    const clearAll = () => {
      let riLen = rowData.runItemsCount.length;
      const ris = rowData.runItemsCount;
      while (--riLen >= 0) {
        if (ris[riLen]._item.$isDisabled) continue;
        ris.splice(riLen, 1);
      }
      rowData.order.manager.runItemsIndexMap();
    };
    const selectAll = () => {
      rowData.order.items.forEach((item) => {
        const idx = riIndex(item.uuid);
        if (idx > -1 || item.$isDisabled) return;
        onSelect(item);
      });
    };
    const addOrRemoveItem = (item) => {
      if (isDisabled.value) return;
      if (isItemSelected(item)) {
        onRemove(item);
      } else {
        onSelect(item);
      }
    };
    const onDone = () => {
      state.isEditing = false;
      CardDirtyBus.emit('setCustomDirty');
    };
    return {
      selected,
      allItems,
      isItemSelected,
      onSelect,
      onRemove,
      clearAll,
      selectAll,
      ...toRefs(state),
      cancelSelected,
      addOrRemoveItem,
      onEdit,
      saveAll,
      isDisabled,
      disableEditButton,
      onDone,
    };
  },
});
</script>
<style scoped>
div.v-popper--theme-dropdown .v-popper__inner .card {
  width: 432px;
  height: 504px;
}
div.v-popper--theme-dropdown .v-popper__inner .card .card-content  .kit-item {
  height: 358px;
}
</style>
