<template>
  <o-loading :full-page="true" :active="isLoading"></o-loading>
  <div class='modal-card'>
    <header class='modal-card-head'>
      <h4 class='modal-card-title'>Export Order QA/QC Forms</h4>
      <div class='is-divider-vertical'></div>
    </header>
    <section class='modal-card-body is-size-2 is-marginless'>
      <div class='column mb-0 p-0'>
        <div class='columns '>
          <div class='column is-flex is-justify-content-space-between pb-0 is-align-items-center'>
            <span class='is-size-3 has-text-black-bis'
              >Generate a Separate PDF</span
            >
            <div class='field'>
              <div class='field-label'>
                <label class='label'>
                  <o-switch v-model='isSeparatePdf'/>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='table-container qaqc-modal'>
        <mf-table
          ref='qaQcPdfTable'
          :tableProps='tableProps'
          :loadData='loadData'
          :apiMode='false'
          :hideGutter='true'
          @cellClicked='onTableCellClicked'
          @checkbox-toggled='getSelectedRows'
          @row-select='getSelectedRows($event)'
          :checkedRows='selectedItems'
          :key='refreshKey'
          :tab='tab'
          :selectedRow='selectedRow'
        >
          <template #workStep='props'>
            <div v-if='props.isHeader'>
              {{ props.rowField.title }} ({{ selectedItems.length }}/{{
                runsWithForm.length
              }})
            </div>
            <field-generic-input
              v-else
              :rowData='props.rowData'
              :rowField='props.rowField'
              :value='$_.get(props.rowData, props.rowField.prop)'
            />
          </template>
          <template #qa-qc='{ rowData }'>
            <div class='is-flex is-justify-content-center'>
              <i :class="[getStatusType(rowData), 'qaqc2x']"></i>
            </div>
          </template>
          <template v-slot:percCompleted='{ rowData }'>
            <div class='is-flex is-justify-content-center'>
              <div>
                {{ rowData.stats?.percComplete || 0 }}
              </div>
              %
            </div>
          </template>
          <template v-slot:isDone='{ rowData }'>
            <div
              class='is-flex is-justify-content-center'
              v-if='rowData.isCompleted'
            >
              <i class='icon-checkmark'></i>
            </div>
          </template>
        </mf-table>
      </div>
    </section>
    <footer class='modal-card-foot is-justify-content-flex-end'>
      <button class='button is-outlined' @click="cancel()">Cancel</button>
      <button
        class='button has-background-black-bis'
        :disabled='selectedItems.length <= 0'
        @click.once='exportPDF()'
      >
        Export Forms
      </button>
    </footer>
  </div>
</template>
<script>
import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  toRefs,
  ref,
} from 'vue';
import _ from 'lodash';
import { useToast } from 'vue-toastification';
import tableDefinition from '@/components/table-cols/qaQcPdfExportCols';
import LocalSort from '@/components/utils/LocalSort';
import ExcelExport from '@/models/ExcelExport';

const MfTable = defineAsyncComponent(() =>
  import('@/components/table-fields/MfTable.vue')
);

export default defineComponent({
  name: 'QaQcFormExport',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    'mf-table': MfTable,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const qaQcPdfTable = ref(null);
    const state = reactive({
      isSeparatePdf: false,
      selectedItems: [],
      tableProps: tableDefinition,
      runsWithForm: [],
      isLoading: false,
    });

    const loadData = () => {
      let data;
      data = LocalSort.filter(
        _.get(props.card, 'manager.runs'),
        qaQcPdfTable?.value?.sort
      );
      state.runsWithForm = _.filter(data, 'form._id');
      return state.runsWithForm;
    };

    const getSelectedRows = (items) => {
      state.selectedItems = items;
      const formIds = _.filter(_.map(state.selectedItems, (r) => r.form._id));
    };

    const getStatusType = (rowData) => {
      const status = {
        notStarted: { icon: 'icon-qcnotstarted', color: 'has-text-grey-dark' },
        inspect: { icon: 'icon-qcinspect', color: 'has-text-info' },
        inProgress: { icon: 'icon-qcinprogress', color: 'has-text-link' },
        pass: { icon: 'icon-qcsignature', color: 'has-text-success' },
        fail: { icon: 'icon-qcfail', color: 'has-text-danger' },
        complete: { icon: 'icon-qcpass', color: 'has-text-success' },
      };
      const currentStatus = status[rowData.formStatus];
      if (!_.isEmpty(currentStatus)) {
        return `${currentStatus.icon} ${currentStatus.color}`;
      }
      return '';
    };

    const exportPDF = async () => {
      state.isLoading = true;
      try {
        const formIds = _.filter(_.map(state.selectedItems, (r) => r.form._id));
        await ExcelExport.export({
          name: 'qaqcForm',
          type: 'pdf',
          projectId: props.card.project._id,
          formId: formIds,
          orderId: props.card._id,
          separatePdf: state.isSeparatePdf,
        });
        toast.success('PDF export will be emailed to you');
      } catch (error) {
        console.log(error)
        toast.error('Order QA/QC Form Export Failed');
      }
      emit('close');
      state.isLoading = false;
    };

    const cancel = () => {
      emit('cancel');
      emit('close');
    };

    return {
      ...toRefs(state),
      loadData,
      getSelectedRows,
      exportPDF,
      getStatusType,
      cancel,
    };
  },
});
</script>
<style lang='scss' scoped>
.o-table__th,
.o-table th:first-child {
  width: 20px;
}
::v-deep(.o-table tbody) {
  min-height: 378px !important;
  max-height: 378px !important;
}
</style>
