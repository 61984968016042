<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <h4 class="modal-card-title">
          Move Completed Items To Inventory
        </h4>
      </header>

      <section class="modal-card-body is-size-2 is-marginless">
        <div class="column pt-0">
          <h4 class="is-size-3">
            Details
          </h4>
          <div class="columns">

            <div class="column pt-0">

              <div class="field">
                <div class="field-label">
                  <label class="label is-size-3">Source Order</label>
                </div>
                <div class="field-body">
                  <span class="has-text-black-bis">{{ card.name }}</span>
                </div>
              </div>

              <div class="field">
                <div class="field-label">
                  <label class="label is-size-3">Source Project</label>
                </div>
                <div class="field-body">
                  <span class="has-text-black-bis">{{ card.project.name }}</span>
                </div>
              </div>

            </div>
            
            <div class="column pt-0">

              <div class="field">
                <div class="field-label">
                  <label class="label is-size-3">Source Production Location</label>
                </div>
                <div class="field-body">
                  <span class="has-text-black-bis">{{ card.manager.location.name }}</span>
                </div>
              </div>

              <div class="field">
                <div class="field-label">
                  <label class="label is-size-3">Destination Inventory Location*</label>
                </div>
                <div class="field-body">
                  <div class="field is-expanded">
                    <div class="control">
                      <mf-multi-select v-model="selectedLoc"
                              track-by="_id" label="name"
                              openDirection="below"
                              :disabled="allLocations.length === 0"
                              placeholder="Select Location"
                              :sort="false"
                              :options="allLocations"
                              :searchable="true"
                              :allow-empty="false">
                      </mf-multi-select>
                    </div>
                </div>
                </div>
              </div>

              </div>
          </div>
        </div>

        <div class="column">
          <div class="columns">
            <div class="column is-12">
              <h4 class="is-size-3">
                Items*
              </h4>
              <search-bar
                placeholder="Search"
                ref="searchComp"
                :shouldEmit="true"
              ></search-bar>
              <div class="table-container modal-table">
                <mf-table
                  v-if="!isLoading"
                  :apiMode="false"
                  :hideGutter="true"
                  :tableProps="tableProps"
                  :loadData="loadData"
                  :key="refreshKey"
                  :checkedRows="selectedItems"
                  @checkbox-toggled="selectedRows"
                  ref="completedItemsTable"
                ></mf-table>
              </div>
            </div>

          </div>

        </div>

      </section>

      <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
        <div class="column is-narrow ">
            <div>
              <button class="button is-outlined" @click="$emit('close')">
                Cancel
              </button>
               <button class="button has-background-black-bis" @click="moveCompletedItems" :disabled="disableMove">
                Move Items
              </button>
            </div>
          </div>
      </footer>

    </div>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>

<script>

import {
  defineComponent, defineAsyncComponent, toRefs, ref, reactive, computed, onBeforeMount,
onMounted,
} from 'vue';
import { useStore } from 'vuex';
import SearchBar from '@/components/SearchBar.vue';
import tableDefinition from '@/components/table-cols/moveCompletedItemsCols';
import UtilityMixin from '@/components/mixins/UtilityMixin';
import ProductionManager from '@/models/ProductionManager';
import _ from 'lodash';
import { useToast } from 'vue-toastification';
import ManagerRemainderMixin from '@/components/mixins/ManageRemainderMixin'

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default defineComponent({
  name: 'MoveCompletedItems',
  props: {
    card: Object,
  },
  components: {
    'mf-table': MfTable,
    'search-bar': SearchBar,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const store = useStore();
    const tableProps = tableDefinition;
    const { getAllLocations } = UtilityMixin();
    const { consumeAndUnreserve } = ManagerRemainderMixin();
    const state = reactive({
      isLoading: false,
      allLocations: [],
      refreshKey: 0,
      selectedLoc: null,
      selectedItems: [],
    });
    const completedItemsTable = ref(null);

    const loadData = () => state.selectedItems;

    onBeforeMount(async () =>{
      state.isLoading = true;
      try {
        const fetchedData = await ProductionManager.getCutOrderItems({
          cardId: props.card._id,
          projectId: props.card.project._id,
          showCompleted: 1,
          stage: props.card.stage,
        })
        state.selectedItems = fetchedData.data;
      } catch (e) {
        console.log('Error: ', e);
      } finally {
        state.isLoading = false;
      }
    })

    const disableMove = computed(() => {
      if (state.selectedItems < 1  || _.isEmpty(state.selectedLoc)) return true;
      return false;
    });

    const moveCompletedItems = async () => {
      const itemParams = {
        cardId: props.card._id,
        items: state.selectedItems,
        location: state.selectedLoc,
        project: props.card.project,
      }
      const nonCatItems = _.some(state.selectedItems, ({ catId }) => _.isEmpty(catId));
      let cardProject = store.getters.findProject({ _id: props.card.project._id });
      const isNestedLoc = cardProject.hasNesetedProjectInvLoc(store.state.userData);
      if (nonCatItems && isNestedLoc) {
        toast.error(`Cannot ship items without catalog id to nested location`);
        return;
      }
      try {
        state.isLoading = true;
        if(props.card.catQtyMaps.some((itm) => itm.QtyToReturn )){
          await ProductionManager.returnMaterialsToInv({cardId: props.card._id})
        }
        await consumeAndUnreserve({order: {_id: props.card._id}})
        await ProductionManager.moveCutItemsToInv(itemParams);
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        state.isLoading = false;
        emit('close',true);
      }
     
      toast.success('Moved Completed Items to Inventory');
    }

    const selectedRows = (rows) => {
      state.selectedItems = rows;
    };

    onMounted(async () => {
      state.isLoading = true;
      state.allLocations = await getAllLocations(props.card.project._id, true);
    });

    return {
      ...toRefs(state),
      tableProps,
      loadData,
      selectedRows,
      moveCompletedItems,
      disableMove,
    };
  },
});

</script>

<style scoped>
</style>