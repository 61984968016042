/* eslint-disable import/no-unresolved */
import axios from 'axios';
import _ from 'lodash';
// eslint-disable-next-line import/extensions
import appVersion from '@/appVersion.json';
import urls from '../urls';

const logAllRequests = false;

function baseConfig() {
  const url = localStorage.getItem('activeUrl') || urls[urls.active];
  return {
    baseURL: `${url}api/v4`,
    timeout: 60000,
  };
}

const Resource = function Resource(options) {
  const baseInstance = axios.create(_.extend(baseConfig(), options));
  baseInstance.interceptors.request.use((config) => {
    if (logAllRequests) {
      console.log('request use normal');
      console.log(config);
    }

    if (config.params && config.params.sort) {
      if (_.isArray(config.params.sort)) {
        config.params.sort = _.map(
          config.params.sort,
          s => _.pick(s, ['sortField', 'direction']),
        );
      } else if (config.params.sort.sortField) {
        config.params.sort = _.pick(config.params.sort, ['sortField', 'direction']);
      }
    }
    const token = localStorage.getItem('auth-token');
    const deviceInfo = JSON.parse(localStorage.getItem('deviceInfo'));
    const versionNum = _.get(deviceInfo, 'appVersion', appVersion.appVersion);
    const buildNum = _.get(deviceInfo, 'appBuild', appVersion.appBuild);
    config.headers.platform = _.get(deviceInfo, 'platform', 'rio-web');
    config.headers.version = config.headers.platform !== 'rio-web' ? `${versionNum}:${buildNum}` : `${versionNum}`;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  }, (error) => {
    if (logAllRequests) {
      console.log('request error');
      console.log(error);
    }

    return Promise.reject(error);
  });

  baseInstance.interceptors.response.use(
    (response) => {
      if (logAllRequests) {
        console.log('response normal');
        console.log(response);
      }
      return response;
    },
    (error) => {
      if (logAllRequests) {
        console.log('response error');
        console.log(error);
      }

      // the below line is to view the procore public view data for users who haven't logged in
      // because we will trigger getUser function to toggle to private submittal view
      // which will return the error so to skip it the below condition is required.
      if (window.location.href.includes('mfPublic')
        || window.location.href.includes('resetPassword')
        || window.location.href.includes('signup')
        || window.location.href.includes('thankyou')
        || window.location.href.includes('account/activate')) {
        return Promise.reject(error.response || error);
      }
      if (error.response && error.response.status === 401
        && !window.location.href.includes('login')) {
        window.location.hash = 'login';
        window.location.reload();
      }
      return Promise.reject(error.response || error);
    },
  );
  if (this instanceof Resource) {
    this.baseInstance = baseInstance;
    return this;
  }
  return baseInstance;
};

Resource.prototype.makeRequest = async function makeRequest(config) {
  try {
    const { data: projects } = await this.baseInstance(config);
    return projects;
  } catch (e) {
    // TODO: Add an alert here?
    console.log(e);
    throw e;
  }
};
Resource.prototype.generateCancelToken = function generateCancelToken() {
  const { CancelToken } = axios;
  return CancelToken.source();
};
Resource.prototype.isCancel = function isCancel() {
  return axios.isCancel;
};

export default Resource;
