import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async getUnSubscribedProjects(params = {}) {
    try {
      const projects = await req.get('subscriptions/getUnsubscribedProjects', {
        params,
      });
      return projects.data.projects;
    } catch (e) {
      throw e.response;
    }
  },
  async getSubscribedProjects(params = {}) {
    try {
      const projects = await req.get('subscriptions/getSubscribedProjects', {
        params,
      });
      return projects.data.projects;
    } catch (e) {
      throw e.response;
    }
  },
  async unsubscribe(params) {
    try {
      const projects = await req.post('subscriptions/unsubscribe', params);
      return projects.data.projects;
    } catch (e) {
      throw e.response;
    }
  },
  async subscribeToProjects(params) {
    try {
      const projects = await req.get('subscriptions/subscribeToProject', {
        params,
      });
      return projects.data.projects;
    } catch (e) {
      throw e.response;
    }
  },
  async cardSubscriptions(params) {
    try {
      const card = await req.post('subscriptions/createCardSubs', params);
      return card;
    } catch (e) {
      throw e.response;
    }
  },
  async getSubscriptionValue(params) {
    try {
      const card = await req.post('subscriptions/getSubscriptionValue', params);
      return card.data;
    } catch (e) {
      throw e.response;
    }
  },
  async unSubscribeFromCard(params) {
    try {
      const projects = await req.post('subscriptions/unSubscribeFromCard', params);
      return projects.data.projects;
    } catch (e) {
      throw e.response;
    }
  },
};
