import {
  reactive, toRefs,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { useToast } from 'vue-toastification';
import Projects from '@/models/Projects';
import Users from '@/models/Users';
import Project from '@/models/ProjectClass';

export default function () {
  const store = useStore();
  const toast = useToast();
  const user = store.state.userData;

  const state = reactive({
    cUsers: [],
    result: {},
    users: [],
    userToAdd: [],
  });
  const getUserToAdd = ((userPermision, template, userChanged = {}) => {
    state.userToAdd = [];
    const mmUser = {
      _id: user._id,
      name: user.name,
      id: user._id,
    };
    const cardUser = {
      _id: user._id,
      name: user.name,
    };
    const company = {
      _id: user.company,
      name: user.companyName,
    };
    if (!_.some(state.users, (usr) => usr._id === template.owner.user._id)) {
      userChanged = template.owner.user;
    }
    if (template.todos.length > 0) {
      for (const todo of template.todos) {
        if (todo.orderCreator) {
          todo.assignedTo.user = cardUser;
          todo.assignedTo.company = company;
        } else if (todo.runCreator && todo.sources.length > 1) {
          todo.assignedTo.user = cardUser;
          todo.assignedTo.company = company;
        }
        if (!_.some(state.users, (usr) => usr._id === todo.assignedTo.user._id)) {
          state.userToAdd.push(todo.assignedTo.user);
          if (!userPermision) {
            todo.assignedTo.user = cardUser;
            todo.assignedTo.company = company;
          }
        }
      }
    }
    if (template.__t === 'ProdTemplates') {
      if (template.manager.runs.length > 0) {
        for (const run of template.manager.runs) {
          if (run.orderCreator) {
            run.owner.user = cardUser;
            run.owner.company = company;
          }
          if (!_.some(state.users, (usr) => usr._id === run.owner.user._id)) {
            state.userToAdd.push(run.owner.user);
            if (!userPermision) {
              run.owner.user = cardUser;
              run.owner.company = company;
            }
          }
        }
      }
    } else {
      if (template.baseDelivery.supplier) {
        if (template.baseDelivery.orderCreator) {
          template.baseDelivery.supplier = mmUser;
        }
        if (!_.some(state.users, (usr) => usr._id === template.baseDelivery.supplier._id)) {
          if (!userPermision) {
            template.baseDelivery.supplier = mmUser;
          }
          state.userToAdd.push(template.baseDelivery.supplier);
        }
      }
      if (template.items) {
        for (const item of template.items) {
          if (item.qa.assignedTo && item.qa.orderCreator) {
            item.qa.assignedTo = mmUser;
          } else if (item.qa.assignedTo
              && !_.some(state.users, (usr) => usr._id === item.qa.assignedTo._id)) {
            if (!userPermision) {
              item.qa.assignedTo = mmUser;
            }
            state.userToAdd.push(item.qa.assignedTo);
          }
        }
      }
    }
    return userChanged;
  });

  const userAddToProject = (async function (card, userChanged) {
    if (!_.isEmpty(userChanged)) state.userToAdd.push(userChanged);
    if (state.userToAdd.length > 1) {
      state.userToAdd = _.uniqBy(state.userToAdd, '_id');
    }
    const getEmails = _.map(state.cUsers, (user) => {
      const uUser = _.find(state.userToAdd, (ur) => ur._id === user._id);
      if (uUser) {
        return user.email;
      }
      return false;
    });
    const params = {
      projectId: card.project._id,
      emails: [],
      title: 'User',
    };
    params.emails = _.compact(getEmails);
    await Projects.addUserToProject(params);
  });

  const setCardOwner = (async function (project, card, template, projectUsersList = [],
    allUsers = [], userChanged = {}, onePerItem = false, locations = [], newStage = card.stage) {
    let msg;
    if (_.isEmpty(projectUsersList)) {
      const projectId = !_.isEmpty(project) ? project._id : card.project._id;
      projectUsersList = await Projects.listProjectUsers({
        project_id: projectId,
        limit: 1000,
        sort: 'name',
        listActivatedUsers: true,
      });
    }
    state.users = projectUsersList.data || projectUsersList;
    allUsers = _.cloneDeep(state.users);
    let cardUser = {};
    if (!_.isEmpty(template) && project) {
      const projData = { project: { _id: project._id } };
      const isProjectEditable = store.getters.getPermissionVal({
        permissionModule: 'app&projects',
        rowData: projData,
      }, 'projects');
      state.cUsers = (await Users.allUsers({ limit: 1000 })).data;
      state.cUsers = _.filter(state.cUsers, (user) => user.activated && !user.archived);
      if (isProjectEditable) {
        if (template.orderCreator) {
          cardUser = user;
        } else {
          cardUser = _.find(state.cUsers, (usr) => usr._id === template.owner.user._id);
        }
        userChanged = getUserToAdd(true, template, userChanged);
        // eslint-disable-next-line
      } else {
        if (_.some(state.users, (usr) => usr._id === template.owner.user._id)
          && !template.orderCreator) {
          cardUser = _.find(state.users, (usr) => usr._id === template.owner.user._id);
        } else {
          cardUser = user;
        }
        userChanged = getUserToAdd(false, template, userChanged);
      }
      if (state.userToAdd.length > 0 || !_.isEmpty(userChanged)) {
        if (isProjectEditable) {
          msg = `This Orders template has the data owners that are not in this project.
                    Those people will be added to this project.`;
          await userAddToProject(card, userChanged);
        } else {
          msg = `This Orders template has the data owners that are not in this project.
            You will be assigned as The owner.`;
        }
      }
      state.userToAdd = [];
      userChanged = {};
    } else {
      cardUser = card.owner.user ? card.owner.user : user;
    }
    if (!_.isEmpty(cardUser)) {
      if (card.stage === 'coordination') { card.owner.user = cardUser; } else {
        card.manager.owner = {};
        card.manager.owner.user = cardUser;
        card.manager.owner.company = {
          _id: cardUser.company,
          name: cardUser.companyName,
        };
        card.owner.user = cardUser;
      }
    }
    const newProject = new Project(store.getters.findProject({ _id: card.project._id }));
    const defaults = newProject.getDefaults(card, newStage);
    if (defaults) {
      if (!onePerItem
        && !_.isEmpty(_.get(defaults, 'newOwner', '')) && _.isEmpty(template)) {
        card.owner.user = _.find(state.users, (usr) => usr._id === defaults.newOwner._id);
      }
      if (!_.isEmpty(defaults.newLocation)) {
        const location = _.find(locations, (loc) => loc._id === defaults.newLocation._id);
        if (card.__t === 'Materials' && (_.isEmpty(template)
        || _.isEmpty(template.baseDelivery.location))) {
          card.baseDelivery.location = location;
        } else if (card.__t === 'Prefabs' && (_.isEmpty(template)
        || _.isEmpty(template.manager.location))) {
          card.location = location;
        }
      }
      if (!_.isEmpty(defaults.notify)) {
        card.notifyUsers = _.map(defaults.notify, '_id');
      }
    }
    return { msg, card, users: state.users };
  });
  const setOwner = async (project, card, template, projectUsersList = [], allUsers = [],
    userChanged = {}, onePerItem = false, locations = [], newStage) => {
    const result = await setCardOwner(project, card, template, projectUsersList,
      allUsers, userChanged, onePerItem, locations, newStage);
    const { msg } = result;
    if (msg) {
      toast.error(msg);
    }
    return { card: result.card, users: result.users };
  };
  return {
    ...toRefs(state),
    getUserToAdd,
    setCardOwner,
    setOwner,
    userAddToProject,
  };
}
