<template>
  <div class="field">
    <mf-multi-select
      v-if="isEditing"
      :modelValue="inputValue"
      :options="userOpts"
      :titleLabel="titleLabel"
      :labelPosition="labelPosition"
      :placeholder="placeholder"
      labelClass="has-text-black-bis is-italic is-size-3"
      label="name"
      track-by="_id"
      group-label="key"
      group-values="val"
      :multiple="rowField?.isMulti"
      :allow-empty="false"
      :disabled="disableUser"
      :filterText="rowField?.id === 'notifyUser' ? 'Notify Users' :''"
      @update:modelValue="updateUser"
    >
    </mf-multi-select>
      <span
      v-else
    >
      {{ inputValue.name }}
    </span>
  </div>
</template>

<script>
import {
  onMounted,
  reactive,
  toRefs,
  computed,
  watch,
} from 'vue';
import {
  isEmpty,
  castArray,
  set,
  isFunction,
  get,
  isString,
  filter,
  isArray,
} from 'lodash';
import { useStore } from 'vuex';
import MfMultiSelect from '@/components/abstract/MfMultiSelect';
import Users from '@/models/Users';

export default {
  name: 'UserSelect',
  props: {
    titleLabel: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: 'top',
    },
    value: Object,
    companyId: String,
    projectId: String,
    isShippingListView: {
      type: Boolean,
      default: false,
    },
    options: Array,
    isEditing: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    rowData: Object,
    rowField: Object,
    placeholder: {
      type: String,
      default: '',
    },
  },
  components: {
    MfMultiSelect,
  },
  setup(props, { emit }) {
    const {
      companyId,
      projectId,
      options,
      rowField,
    } = props;
    const store = useStore();
    // data
    const data = reactive({
      userOpts: [],
    });
    const inputValue = computed({
      get: () => {
        if (isFunction(get(rowField, 'inputProps.getUserName', false))) {
          const prop = rowField.inputProps.getUserName(props.rowData);
          return get(props.rowData, prop, {});
        }
        return (isEmpty(props.value) ? [] : props.value);
      },
      set: (val) => {
        if (props.rowField?.inputProps?.kind === 'baseDelivery.recipient') {
          // The server expects the data to be in recipientId and not recipient for MM
          set(props.rowData, 'baseDelivery.recipientId', val);
        }
        if (isFunction(get(rowField, 'inputProps.getUserName', false))) {
          set(props.rowData, rowField.inputProps.getUserName(props.rowData), val);
        }
        if (props.titleLabel === 'Notify') {
          val = filter(val, (opt) => !isString(opt));
        }
        emit('update:value', val);
      },
    });

    // methods
    const fetchUsers = async () => {
      const companyIds = [];
      if (projectId && rowField?.isShippingListView || props.isShippingListView) {
        const selectedProj = store.getters.findProject({
          _id: projectId,
        });
        if (selectedProj) {
          const companies = selectedProj.projectCompanies;
          companies.push(selectedProj.createdCompany);
          companies.forEach((element) => {
            if (element.status === 'active' || !element.status) {
              companyIds.push(element._id);
            }
          });
        }
      }
      const linkedUsers = await Users.getCompanyProjectAllLinkedUsers({
        params: {
          companyIds: (rowField?.isShippingListView || props.isShippingListView) ? companyIds : castArray(companyId),
          projectId,
          ignoreCompany: true,
        },
      });
      return Users.groupUsers(linkedUsers);
    };

    const populateOpts = async () => {
      if (!props.isEditing) return;
      if (isEmpty(options)) {
        const opts = await fetchUsers();
        data.userOpts = opts;
      } else {
        data.userOpts = options;
      }
      if (rowField?.id === 'notifyUser' && !props.isShippingListView) {
        data.userOpts.unshift({
          key: 'Groups',
          val: [{
            name: 'Order Owner',
            _id: 'orderOwner',
          }, {
            name: 'Project Members (My Company)',
            _id: 'projectMembers',
          }],
        });
      }
    };
    const disableUser = computed(() => {
      if (isFunction(rowField?.inputProps?.isDisabled)) {
        return rowField?.inputProps?.isDisabled(props.rowData);
      }
      return props.disabled;
    });
    onMounted(populateOpts);

    watch(() => props.isEditing, populateOpts);

    const updateUser = (val) => {
      if (isArray(val)){
        inputValue.value = val;
      }
      else if (val.name) inputValue.value = val;
    }

    return {
      ...toRefs(data),
      inputValue,
      disableUser,
      updateUser,
    };
  },
};
</script>
