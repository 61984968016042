import { chunk, uniqBy } from 'lodash';
import GenerateRequest from './resources';
import _ from 'lodash';

const req = GenerateRequest();

export default {
  async getVendors(params) {
    try {
      let completeData = [];
      if (params.category && params.category.length > 200) {
        const categoryChunks = chunk(params.category, 200);
        for (const categoryChunk of categoryChunks) {
          params.category = categoryChunk;
          const tempData = await req.get('vendors', { params });
          if (!_.isEmpty(tempData.data)) {
            completeData.push(...tempData.data.data);
          }
        }
      } else {
        const tempData = await req.get('vendors', { params });
        if (!_.isEmpty(tempData.data)) {
          completeData.push(...tempData.data.data);
        }
      }
      const sortedVendors = { data: uniqBy(completeData, '_id') };
      return sortedVendors;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async addVendor(params) {
    const { data: newVendor } = await req.post('vendors/add', params);
    return newVendor;
  },
  async updateVendor(params) {
    const updatedVendor = await req.post(`vendors/edit/${params._id}`, params);
    return updatedVendor;
  },
  async archive(params) {
    const { data: archivedvendors } = await req.post('vendors/archive', params);
    return archivedvendors;
  },
  async unarchive(params) {
    const { data: unarchivedVendor } = await req.post('vendors/unarchive', { params });
    return unarchivedVendor;
  },
  async getCategories(params) {
    const { data: categories } = await req.get('categories', { params });
    return categories;
  },
  async getSubCategories(params) {
    const { data: subCategories } = await req.get('categories/subCategories', { params });
    return subCategories;
  },
  async addCategories(params) {
    const { data: newCategory } = await req.post('categories/add', params);
    return newCategory;
  },
  async updateCategories(params) {
    const updatedCategory = await req.post(`categories/edit/${params._id}`, params);
    return updatedCategory;
  },
  async archiveCategory(params) {
    const { data: archivedCategory } = await req.post('categories/archive', params);
    return archivedCategory;
  },
};
