// Table cols for Assemblies and Parts Table
export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  isDetailed: true,
  detailRowComponent: 'mf-detail-row',
  isCheckable: false,
  stickeyHeaders: true,
  gutterOpts: {},
  isCardView: true,
  toolBar: {
    buttons: () => {
      const buttons = [{
        event: 'showArchive',
        icon: 'icon-addnew',
        tooltip: 'Add New',
        isLine: true,
        type: 'switch',
        label: 'Show Archived Only'
      }, {
        event: 'addNew',
        icon: 'icon-addnew',
        tooltip: 'Add New',
        isLine: true,
        permissionType: 'assembly_parts',
      }, {
        event: 'excelImport',
        icon: 'icon-importexcel',
        tooltip: 'Import From Excel',
        isLine: true,
        permissionType: 'assembly_parts',
      }];
      return buttons;
    },
    bulkActions: () => {
      const opts = [{
        name: 'Delete Assemblies',
        event: 'multiDelete',
        label: 'Delete Assemblies',
      }, {
        name: 'Archive Assemblies',
        event: 'multiArchive',
        label: 'Archive Assemblies',
      }];
      return opts;
    },
  },
  fields: [
    {
      title: 'Name',
      name: 'field-generic-input',
      width: '400px',
      id: 'name',
      sortField: 'name',
      bulkActionButtonField: true,
      searchable: true,
      prop: 'name',
      sticky: true,
      defaultVisible: true,
      inputProps: {
        type: 'text',
      },
    },
    {
      name: 'field-cat-id',
      width: '152px',
      title: 'Catalog ID',
      id: 'catId',
      prop: 'catId',
      sortField: 'catId',
      allowAutoComplete: true,
      verifyCatId: true,
      defaultVisible: true,
      inputProps: {
        type: 'text',
        disableEdit: true,
      },
    },
    {
      title: 'Assembly ID',
      name: 'field-generic-input',
      width: '152px',
      id: 'customId',
      prop: 'customId',
      sortField: 'customId',
      defaultVisible: true,
      inputProps: {
        type: 'text',
      },
    },
    {
      name: 'categorySelect',
      width: '192px',
      title: 'Category',
      id: 'category',
      prop: 'category.name',
      defaultVisible: true,
      searchable: true,
      filterText: 'Categories',
    },
    {
      name: 'subCategorySelect',
      width: '192px',
      title: 'Sub-Category',
      id: 'subCategory',
      prop: 'subCategory.name',
      defaultVisible: true,
      searchable: true,
      filterText: 'Sub-Categories',
    },
    {
      title: 'Description',
      name: 'field-generic-input',
      width: '356px',
      id: 'desc',
      prop: 'desc',
      defaultVisible: true,
      inputProps: {
        type: 'text',
      },
    },
    {
      name: 'field-generic-input',
      width: '112px',
      title: 'Purchase',
      id: 'purchase',
      defaultVisible: true,
      type: 'checkbox',
      prop: 'purchase',
    },
    {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      hideFromChooser: true,
      alwaysVisible: false,
      defaultVisible: true,
      width: '112px',
      sticky: true,
      clearBtn: true,
      searchable: true,
      hideAllFilters: false,
      permissionModule: 'app&projects',
      permissionKey: 'assembly_parts',
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      inputProps: {
        showEdit: true,
        options(catalog) {
          const opts = [{
            icon: 'fa fa-history',
            label: 'Delete',
            event: 'catalogDelete',
            isDisabled() {
              return catalog.partOf > 0;
            },
          }];
          if (catalog.isTemporaryDelete) {
            opts.push({
              icon: 'fa fa-history',
              label: 'Restore',
              event: 'catalogUnArchive',
            });
          } else {
            opts.unshift({
              icon: 'fa fa-history',
              label: 'Archive',
              event: 'catalogArchive',
            });
          }
          return opts;
        },
      },
    },
  ],
};
