<template>
  <div :class="rowField.inputProps?.isLeft ? '' : 'is-pulled-right has-text-right clock-width'">
    <div class="is-flex is-align-items-center is-justify-content-space-between" v-if="!actualHrsEdit && ['stats.actualHrs', 'actualHrs'].includes(rowField.prop) ">
      <input type="number" v-model="timeInHrs" class="input timer-width-hour"
            :class="rowField.inputProps?.isModal ? 'timeInput': ''"
            placeholder="hh"
           :min="rowField.inputProps.min" :max="rowField.inputProps.max"
           :step="rowField.inputProps.step"/>
    :
      <input type="number" v-model="timeInMinutes" class="input timer-width-min" placeholder="mm"
            :class="rowField.inputProps?.isModal ? 'timeInput': ''"
            :min="rowField.inputProps.min" max="59"
            :step="rowField.inputProps.step"/>
    :
      <input type="number" v-model="timeInSeconds" class="input timer-width-min" placeholder="ss"
            :class="rowField.inputProps?.isModal ? 'timeInput': ''"
            :min="rowField.inputProps.min" max="59"
            :step="rowField.inputProps.step"/>
    </div>
    <span v-else-if="actualHrsEdit && ['stats.actualHrs', 'actualHrs'].includes(rowField.prop)">
      {{timeInHrs}} :  {{timeInMinutes}} : {{ timeInSeconds }}
    </span>
    <div class="is-flex is-align-items-center is-justify-content-space-between" v-else-if="!disableEdit">
      <input type="number" v-model="timeInHrs" class="input timer-width-hour" placeholder="hh"
            :class="rowField.inputProps?.isModal ? 'timeInput': ''"
            :min="rowField.inputProps.min" :max="rowField.inputProps.max"
            :step="rowField.inputProps.step"/>
      :
      <input type="number" v-model="timeInMinutes" class="input timer-width-min" placeholder="mm"
            :class="rowField.inputProps?.isModal ? 'timeInput': ''"
            :min="rowField.inputProps.min" max="59"
            :step="rowField.inputProps.step"/>
      :
      <input type="number" v-model="timeInSeconds" class="input timer-width-min" placeholder="ss"
            :class="rowField.inputProps?.isModal ? 'timeInput': ''"
            :min="rowField.inputProps.min" max="59"
            :step="rowField.inputProps.step"/>
    </div>
    <div class="columns pt-1" v-else>
      <div class="column">{{timeInHrs}} <span class="is-pulled-right ml-2">:</span></div>
      <div class="column is-narrow">{{timeInMinutes}} <span class="is-pulled-right ml-2">:</span></div>
      <div class="column is-narrow">{{timeInSeconds}}</div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import {
  get, set, isNaN, isUndefined,
} from 'lodash';
import CardEditMixin from '../../components/mixins/CardEditMixin';

export default defineComponent({
  name: 'ClockDisplay',
  props: {
    rowData: Object,
    rowField: Object,
  },
  setup(props, { emit }) {
    const { rowData, rowField } = props;
    const { disableStatus } = CardEditMixin();
    const timeInHrs = computed({
      get: () => {
        if (!rowData) return null;
        const rowValue = get(rowData, rowField.prop, 0);
        if (rowValue === 0) {
          if (rowField.inputProps.showNum) {
            return '00';
          }
          return 'hh';
        }
        if (rowValue < 3600) return 0;
        const tempVal = parseInt((rowValue / 3600), 10);
        return tempVal;
      },
      set: (val) => {
        val = parseInt(val, 10);
        if ((val < 0
          || Number.isNaN(val))) { val = 0; }
        if (val >= (rowField?.inputProps?.max || 999)) {
          val = rowField?.inputProps?.max || 999;
        }
        const seconds = isNaN(parseInt(timeInSeconds.value, 10)) ? 0 : timeInSeconds.value;
        const minutes = isNaN(parseInt(timeInMinutes.value, 10)) ? 0 : timeInMinutes.value;
        const totalHrs = parseInt(seconds, 10)
          + (parseInt(minutes, 10) * 60) + (parseInt(val, 10) * 3600);
        set(rowData, rowField.prop, totalHrs);
        if (rowField.inputProps.fromWorkStep) {
          if (totalHrs > ((rowField?.inputProps?.max || 999) * 3600)) {
            set(rowData, rowField.prop, ((rowField?.inputProps?.max || 999) * 3600));
          }
        }
        if (rowField.inputProps.fieldDirtyRequired) {
          set(rowData, 'fieldsDirty', true);
        }
        emit('cellClicked', {
          event: 'data-changed',
          prop: rowField.prop,
          data: rowData,
          value: totalHrs,
        });
      },
    });
    const timeInMinutes = computed({
      get: () => {
        if (!rowData) return null;
        const rowValue = get(rowData, rowField.prop, 0);
        if (rowValue === 0) {
          if (rowField.inputProps.showNum) {
            return '00';
          }
          return 'mm';
        }
        if (rowValue < 60 && rowValue > 0) return 0;
        const tempVal = parseInt(((rowValue % 3600) / 60), 10);
        if (tempVal > 59) return 59;
        return tempVal < 10 ? `0${tempVal}` : tempVal;
      },
      set: (val) => {
        val = parseInt(val, 10);
        if ((val < 0
          || Number.isNaN(val))) { val = 0; }
        if (val >= 59) val = 59;
        const seconds = isNaN(parseInt(timeInSeconds.value, 10)) ? 0 : timeInSeconds.value;
        const hours = isNaN(parseInt(timeInHrs.value, 10)) ? 0 : timeInHrs.value;
        const totalHrs = parseInt(seconds, 10)
          + (parseInt(val, 10) * 60) + (parseInt(hours, 10) * 3600);
        set(rowData, rowField.prop, totalHrs);
        if (rowField.inputProps.fieldDirtyRequired) {
          set(rowData, 'fieldsDirty', true);
        }
        emit('cellClicked', {
          event: 'data-changed',
          prop: rowField.prop,
          data: rowData,
          value: totalHrs,
        });
      },
    });
    const timeInSeconds = computed({
      get: () => {
        if (!rowData) return null;
        const rowValue = get(rowData, rowField.prop, 0);
        if (rowValue === 0) {
          if (rowField.inputProps.showNum) {
            return '00';
          }
          return 'ss';
        }
        if (rowValue < 1) return 0;
        if (rowValue < 60) {
          return rowValue < 10 ? `0${rowValue}` : rowValue;
        }
        const tempVal = parseInt((rowValue % 60), 10);
        if (tempVal > 59) return 59;
        return tempVal < 10 ? `0${tempVal}` : tempVal;
      },
      set: (val) => {
        val = parseInt(val, 10);
        if ((val < 0
          || Number.isNaN(val))) { val = 0; }
        if (val >= 59) val = 59;
        const minutes = isNaN(parseInt(timeInMinutes.value, 10)) ? 0 : timeInMinutes.value;
        const hours = isNaN(parseInt(timeInHrs.value, 10)) ? 0 : timeInHrs.value;
        const totalHrs = parseInt(val, 10)
          + (parseInt(minutes, 10) * 60)
          + (parseInt(hours, 10) * 3600);
        set(rowData, rowField.prop, totalHrs);
        if (rowField.inputProps.fieldDirtyRequired) {
          set(rowData, 'fieldsDirty', true);
        }
        emit('cellClicked', {
          event: 'data-changed',
          prop: rowField.prop,
          data: rowData,
          value: totalHrs,
        });
      },
    });
    const disableEdit = computed(() => {
      if (!isUndefined(rowData.editProductivity)
      && !get(rowField, 'inputProps.entireRun', false)) {
        return !rowData.editProductivity;
      }
      if (props.rowField.inputProps.fromWorkStep) {
        return props.rowField.inputProps.editHours;
      }
      return rowField.inputProps.disableEdit || !rowData.isEditing || disableStatus(rowData.order.status);
    });
    const actualHrsEdit = computed(() => {
      if (rowData.editProductivity || rowData.isEditing) {
        return (rowData.isCompleted || disableStatus(rowData.order.status));
      }
      if (rowField.inputProps.fromWorkStep) {
        return false;
      }
      if (disableStatus(rowData.order.status)) {
        return true;
      }
      return true;
    });
    return {
      timeInHrs,
      timeInMinutes,
      timeInSeconds,
      disableEdit,
      actualHrsEdit,
    };
  },
});
</script>

<style scoped>
  .timeInput {
    width: 50px;
  }
  .clock-width {
    width: 140px;
  }
</style>
