<template>
  <div class="field" :class="{ 'is-horizontal': labelPosition === 'left' }">
    <label v-if="titleLabel.length" class="label" :class="labelClass">
      {{ titleLabel }}
    </label>
    <div
      v-if="!disabled"
      class="control"
    >
      <VueMultiselect
        :model-value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @select="emitDirty"
        :options="options"
        :show-labels="false"
        :max-height="150"
        v-bind="getAttributes"
        :disabled="disabled"
        :placeholder="getPlaceHolder"
        @open="$emit('dropdown:active', true)"
        @close="$emit('dropdown:active', false)"
      >
        <template v-slot:selection="{ values }">
          <span
            class="multiselect__single"
            v-if="values.length && getAttributes.multiple && !getAttributes.tags"
          >
            {{ getAttributes.filterText }} {{ displayType === 'stringedOptions' ? multipleSelectValues : `(${multipleSelectValues})` }}
          </span>
        </template>
      </VueMultiselect>
    </div>
    <span ref="selectedWorkstep"
      v-else-if="selectedWorkstep"
      class="is-size-3 has-text-weight-normal ml-1 has-text-black-bis"
    >
      {{ $_.isArray(modelValue) ? modelValue[0]?.name : modelValue?.name }} : {{ selectedWorkstep.name }}
    </span>
    <span
      v-else
      class="is-size-3 has-text-weight-normal ml-1 has-text-black-bis"
    >
      {{ $_.isArray(modelValue) ? modelValue[0]?.name : modelValue?.name }}
    </span>
  </div>
</template>

<script>
/*
repo: https://github.com/shentao/vue-multiselect/tree/next
documentation: https://vue-multiselect.js.org/#sub-getting-started
*/
import VueMultiselect from 'vue-multiselect';
import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import { cloneDeep, get, map } from 'lodash';
import { CardDirtyBus } from '@/utils/CardDirtyBus';

export default {
  name: 'MfMultiSelect',
  components: { VueMultiselect },
  props: {
    modelValue: [Number, String, Object, Array, Boolean],
    titleLabel: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: 'top',
    },
    disabled: [Boolean, Function, String],
    options: {
      type: [Array, Object, Function],
      required: false,
      default: () => [],
    },
    labelClass: String,
    displayType: {
      type: String,
      default: '',
    },
    displayProp: {
      type: String,
      default: '',
    },
    noDirtyEmit: {
      type: Boolean,
      default: false,
    },
    selectedWorkstep: {},
  },
  setup(props, { attrs }) {
    const getAttributes = computed(() => (attrs));
    const emitDirty = () => {
      if (!props.noDirtyEmit) CardDirtyBus.emit('setCustomDirty');
    };
    const multipleSelectValues = computed(() => {
      if (get(props, 'displayType', '') === 'stringedOptions') {
        if (get(props, 'displayProp', '')) {
          return map(props.modelValue, get(props, 'displayProp', '')).join(', ');
        }
        return map(props.modelValue).join(', ');
      }
      return props.modelValue?.length || 0;
    });
    const getPlaceHolder = computed(() => {
      if (getAttributes.value.placeholder) return getAttributes.value.placeholder;
      return getAttributes.value.filterText ? `All ${getAttributes.value.filterText}`
        : 'Make Selection';
    });
    return {
      getAttributes, emitDirty, multipleSelectValues, getPlaceHolder,
    };
  },
};
</script>
