export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: false,
  stickeyHeaders: true,
  rearrangeCols: false,
  detailRowComponent: 'mf-detail-row',
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      tooltip: 'Customize Columns',
      isLine: true,
    }],
  },
  toolBar: {
    // name: for drop down label, label: for action button label
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: [{
      event: 'excelExport',
      icon: 'icon-exportexcel',
      tooltip: 'Export to Excel',
    }],
  },
  fields: [
    {
      name: 'field-generic-input',
      width: '400px',
      title: 'Name',
      prop: 'name',
      id: 'name',
      sticky: true,
      alwaysVisible: true,
      defaultVisible: true,
      isClickable: false,
      inputProps: {
        type: 'text',
        stageIcons: true,
        view: 'item',
      },
    }, {
      name: 'field-generic-input',
      width: '296px',
      title: 'Source Order',
      prop: 'cardName',
      defaultVisible: true,
      alwaysVisible: true,
      isClickable: true,
      id: 'cardName',
      eventType: 'openCard',
      inputProps: {
        type: 'text',
        stageIcons: true,
        view: 'item',
      },
    },
    {
      name: 'financialId',
      default: false,
      width: '152px',
      title: 'Item ID',
      id: 'financialId',
      defaultVisible: false,
    }, {
      name: 'project',
      id: 'project',
      title: 'Project',
      width: '296px',
      searchable: true,
      filterText: 'Projects',
      show: true,
      defaultVisible: true,
    }, {
      title: 'Stage',
      name: 'field-stage',
      prop: 'stage',
      searchable: true,
      filterText: 'Stages',
      id: 'stage',
      width: '192px',
      defaultVisible: true,
      alwaysVisible: false,
      cardProps: {
        cardType: '__t',
      },
    }, {
      name: 'field-stage',
      prop: 'status',
      title: 'Status',
      width: '152px',
      searchable: true,
      filterText: 'Status',
      id: 'status',
      show: true,
      defaultVisible: true,
      alwaysVisible: false,
      isStaticColor: true,
      isStatus: true,
    }, {
      name: 'field-user-select',
      id: 'owner',
      title: 'Owner',
      prop: 'owner.user.name',
      defaultVisible: false,
      show: false,
      searchable: true,
      filterText: 'Owners',
      inputProps: {
        type: 'manager',
      },
      width: '296px',
    }, {
      name: 'field-generic-input',
      id: 'company',
      title: 'Company',
      prop: 'owner.company.name',
      width: '296px',
      defaultVisible: false,
      show: false,
      inputProps: {
        type: 'manager',
      },
    }, {
      name: 'locationName',
      id: 'location',
      width: '296px',
      title: 'Location',
      searchable: true,
      filterText: 'Locations',
      show: false,
      defaultVisible: true,
    }, {
      name: 'field-generic-input',
      width: '112px',
      title: 'QTY',
      id: 'quantity',
      prop: 'quantity',
      defaultVisible: true,
    },
    {
      name: 'measure',
      width: '112px',
      title: 'Measure',
      id: 'measure',
      classRight: 'is-pulled-right',
      defaultVisible: false,
    }, {
      name: 'measureUnits',
      width: '152px',
      title: 'Measure Units',
      id: 'measureUnits',
      classRight: 'is-pulled-right',
      defaultVisible: false,
    }, {
      name: 'field-date',
      width: '152px',
      title: 'Coordinate',
      prop: 'date',
      id: 'coordination',
      searchable: true,
      dateFilter: 'coord',
      defaultVisible: true,
      inputProps: {
        type: 'mf-date',
        kind: 'coord',
      },
    }, {
      name: 'field-date',
      width: '152px',
      title: 'Detail By',
      prop: 'date',
      id: 'detailBy',
      searchable: true,
      dateFilter: 'detailBy',
      defaultVisible: true,
      inputProps: {
        type: 'mf-date',
        kind: 'detailBy',
      },
    }, {
      name: 'field-date',
      width: '152px',
      title: 'Manufacture By',
      defaultVisible: true,
      prop: 'date',
      id: 'manufactureBy',
      searchable: true,
      dateFilter: 'manufactureBy',
      inputProps: {
        type: 'mf-date',
        kind: 'manufactureBy',
      },
    }, {
      name: 'field-date',
      width: '152px',
      title: 'QA',
      defaultVisible: true,
      prop: 'date',
      id: 'qaBy',
      searchable: true,
      dateFilter: 'qaBy',
      inputProps: {
        type: 'mf-date',
        kind: 'qaBy',
      },
    }, {
      name: 'field-date',
      width: '152px',
      title: 'Onsite',
      prop: 'date',
      id: 'onSiteBy',
      searchable: true,
      dateFilter: 'deliver',
      defaultVisible: true,
      inputProps: {
        type: 'mf-date',
        kind: 'deliver',
      },
    }, {
      name: 'lastModified',
      width: '152px',
      title: 'Modified',
      defaultVisible: true,
      prop: 'date',
      id: 'modified',
      searchable: true,
      dateFilter: 'lastModified',
      // formatter: value => (value === '' ? '' : moment(value).format('DD/MM/YYYY')),
    }, {
      title: 'Notes',
      name: 'field-notes-icon',
      prop: 'notes',
      id: 'notes',
      width: '64px',
      defaultVisible: true,
    },
    {
      title: 'Actions',
      name: 'action',
      id: 'action',
      clearBtn: true,
      searchable: true,
      sticky: true,
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      width: '112px',
      hideFromChooser: true,
      alwaysVisible: true,
    },
  ],
};
