<template>
  <div class=""
    :class="labelPosition === 'is-left' ? 'field is-horizontal' : 'field'">
    <label v-if="label?.length" class="is-size-3 label line-height has-text-black-bis is-italic">
      {{ label }}
    </label>
    <o-datepicker
      v-if="(loadPicker || isModal) && showPicker"
      v-model="displayValue"
      :placeholder="placeholder"
      :minDate="minDate"
      :maxDate="maxDate"
      :dateFormatter="formattedDate"
      :position="position"
      :focusedDate="minDate || maxDate || new Date()"
      @focus="onOpen"
      @activeChange="onOpen"
      :mobileNative="false"
      :disabled="isDateDisable"
      iconRight="icon-datepicker"
      iconPack=" "
      iconNext="fas fa-angle-right"
      iconPrev="fas fa-angle-left"
      :iconRightClickable="false"
      ref="datePicker"
      :rootClass="[datePicker && datePicker.showPrev ? 'hide-prev' : '',
       datePicker && datePicker.showNext ? 'hide-next' : '']"
      :openOnFocus="true"
      :trap-focus="true"
      :inline="showInline"
      :class="dateClass"
      datePopup
    >
    </o-datepicker>
    <div v-if="isInvalidDate">
      <VDropdown :triggers="['click', 'hover']">
        <div class="line-height is-size-3" v-show="!showPicker"
        :class="isInvalidDate ? 'has-text-danger': ''">
          <i v-if="isInvalidDate" class="fa fa-exclamation" />
          {{ formattedDate(displayValue) }}
        </div>
        <template #popper v-if="isInvalidDate === true">
          <div class="card">
            <header class="card-header has-background-grey-lighter">
               <h4
              class="card-header-title is-size-3 has-text-black-bis
                has-text-weight-bold is-paddingless"
            >Date Conflict</h4>
            </header>
             <section class="card-content is-marginless has-background-white has-text-black-bis">
               <h4> {{dateWarning.text}}</h4>
               <div class="column has-text-black-bis">
                  <div class="columns">
                     <div class="column has-text-weight-bold is-italic pl-0">
                       {{dateWarning.conflictTo}}
                     </div>
                     <div class="column  has-text-right pr-0">
                       {{ formattedDate(displayValue) }}
                     </div>
                  </div>
                  <div class="columns">
                     <div class="column has-text-weight-bold is-italic  pl-0">
                       {{ dateWarning.conflictWith }}
                     </div>
                     <div class="column has-text-right pr-0">
                       {{ formattedDate(dateWarning.conflictingDate) }}
                     </div>
                  </div>
               </div>
             </section>
          </div>
      </template>
      </VDropdown>
    </div>
    <div v-show="(!showPicker || (isDateDisable && loadPicker)) && !isInvalidDate"
    class="has-text-black-bis line-height is-size-3 edit-float">
      {{ formattedDate(displayValue) }}
    </div>
    <div class="has-text-black-bis line-height is-size-3"
    v-if="isTemplatedSelected && isDateDisable">
      {{ formattedDate(displayValue) ? formattedDate(displayValue) : '(Computed from lead times)' }}
    </div>
    <div class="secondary-date" v-if="showPicker && !loadPicker && !isDateDisable && !isModal">
      <div class="o-ctrl-input" @click="loadAndShowPicker()">
        <input
            class="o-input o-input-iconspace-right"
            :value="formattedDate(displayValue)"
            :placeholder="placeholder"
          />
        <span class="o-icon o-input__icon-right">
          <i class="icon-datepicker"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  defineComponent,
  getCurrentInstance,
  ref,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { BaseOrder } from '@/models/BaseOrder';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'MfDate',
  props: [
    'item',
    'inputProps',
    'isEdit',
    'isDisabled',
    'source',
    'isOffset',
    'label',
    'labelPosition',
    'rowData',
    'rowField',
    'isModal',
    'isTemplatedSelected',
    'showInline',
    'dateClass',
    'datePopup',
  ],

  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      loadPicker: false,
      placeholder: '',

    });
    const el = getCurrentInstance().ctx.$el;
    const datePicker = ref(null);
    const position = computed(() => props.inputProps.pos || 'bottom-left');
    let data = props.item || props.rowData;
    const isBo = computed(() => {
      if (!data) return false;
      if (!_.isUndefined(props.inputProps.isBO)) return props.inputProps.isBO;
      return (
        data instanceof BaseOrder
        || (data.order && data.order instanceof BaseOrder)
      );
    });
    const getRealOrderForBO = (kind) => {
      let place = '';
      const isManagerDate = ['detailBy', 'manufactureBy', 'qaBy'].includes(
        kind,
      );
      const itemIsManager = data._place === 'manager';
      const itemHasManager = !itemIsManager && data.manager;
      if (isManagerDate && !data.isManager && !itemIsManager) {
        // PO created from template
        kind = _.camelCase(`po ${kind}`);
      } else if (isManagerDate && itemHasManager) {
        place = 'manager.';
      } else if (itemIsManager && !isManagerDate && data.order) {
        place = 'order.';
      }
      if (!data.simpleDates || !data.simpleDates[kind]) data.newDate(kind);
      return {
        data: (isManagerDate && itemHasManager) ? data.manager :
          (itemIsManager && !isManagerDate && data.order) ? data.order : data,
        kind: `${place}simpleDates.${kind}.value`,
        value: (isManagerDate && itemHasManager &&  data.manager.simpleDates[kind]?.value) ? data.manager.simpleDates[kind]?.value : data.simpleDates[kind].value,
      };
    };
    const kind = computed(() => {
      const dateKind = props.inputProps.kind;
      if (isBo.value) {
        return getRealOrderForBO(dateKind).kind;
      }
      return dateKind;
    });
    const showPicker = computed(() => {
      if (props.inputProps && _.isBoolean(props.inputProps.disableEdit)) {
        return (
          (!props.inputProps.disableEdit && props.rowData?.isEditing) || !!props.isEdit
        );
      }
      if (props.inputProps && _.isFunction(props.inputProps.disableEdit)) {
        const { activeScreen } = store.state;
        return (
          !props.rowField.inputProps.disableEdit(props.rowData, route.params, data, activeScreen)
          && (props.rowData?.isEditing || !!props.isEdit || props.rowField?.isEdit)
        );
      }
      return !!props.isEdit;
    });
    const isDateDisable = computed(() => {
      if (props.rowField?.isDisabled) {
        if (_.isFunction(props.rowField?.isDisabled)) {
          return props.rowField?.isDisabled(data);
        }
        return props.rowField?.isDisabled;
      }
      return props.isDisabled || false;
    });
    const dateWarning = computed(() => {
      if (data && props.rowField?.prop && !data[props.rowField.prop]?.isValid) {
        const errorMsg = {
          ...data[props.rowField.prop],
        };
        if (props.rowField.prop !== 'deliver') {
          if (['onsite', 'deliver'].includes(errorMsg.conflictWith)) {
            errorMsg.conflictWith = 'On-Site';
          } else {
            errorMsg.conflictWith = _.startCase(errorMsg.conflictWith);
          }
        }
        if (props.rowField.prop === 'deliver') {
          errorMsg.conflictTo = 'Existing On-Site';
          errorMsg.conflictWith = 'Imported On-Site';
          errorMsg.text = `${errorMsg.conflictTo} conflict with imported`;
        } else {
          errorMsg.conflictTo = _.startCase(props.rowField.prop);
          errorMsg.text = `${errorMsg.conflictTo} conflicts with ${errorMsg.conflictWith}`;
        }
        return errorMsg;
      }
      return {};
    });
    const formattedDate = (val) => (val ? moment(val).format('MM/DD/YYYY') : '');
    const isInvalidDate = computed(() => {
      if (store.state.activeScreen !== 'schedule') { return false; }
      if (data && props.rowField?.prop) {
        return !_.get(data[props.rowField.prop], 'isValid', true);
      }
      return false;
    });
    const displayValue = computed({
      get: () => {
        if (props.rowField?.itemDate) {
          const res = _.find(data.dates, (d) => !d.archived.value && d.kind === props.rowField.prop
            && _.find(d.sources, { _id: data._id }));
          return (!_.isEmpty(res) && res.value) ? moment(res.value).toDate() : '';
        }
        let date = _.get(data, kind.value, null);
        if (props.inputProps.kind ==='pickReqOnSite' && _.isEmpty(date)) {
          date =  props.rowData ? props.rowData.getDate('manufactureBy') : props.item.getDate('manufactureBy');
        }
        if (date) return moment(date).toDate();
        if (_.get(props.inputProps, 'preFillDate', false)) return moment(_.get(props.inputProps, 'preFillDate')).toDate();
        return null;
      },
      set: (val) => {
        if (props.datePopup) {
          emit('setDate', { val, kind });
          return;
        }
        CardDirtyBus.emit('setCustomDirty');
        // setting to noon to prevent date change for all tz offsets
        const existingVal = _.get(data, kind.value);
        if(data.isCutOrder) {
          if(props.inputProps.kind === 'detailBy') {
            const coordDate = _.find(data.dates, (d) => d.kind === 'coord' && !d.archived.value);
            coordDate.value = moment(val).toISOString();
          }
          if(props.inputProps.kind === 'manufactureBy') {
            for(const date of ['qaBy', 'deliver']) {
              const newDate = _.find(data.dates, (d) => d.kind === date && !d.archived.value);
              newDate.value = moment(val).toISOString();
            }
          }

        }
        if (moment(existingVal).toISOString() === moment(val).toISOString()) return;
        /* ^ This check prevents resetting of date when its the same date but set
        from a different timezone (https://github.com/manufacton/Rio/issues/7598) */
        val.setHours(12);
        if (props.isOffset) for (const date of Object.keys(data)) data[date] = null;
        _.set(data, kind.value, moment(val).toISOString());
        if (
          ['preparation', 'sourcing'].includes(data?.stage)
          && props.inputProps.kind === 'orderBy'
        ) {
          const deliverDate = data.order.simpleDates.deliver.value;
          data.leadTime = moment(deliverDate).diff(val, 'days');
          emit('cell-clicked', { type: 'leadTime' });
        }
        emit('setDate', { val, kind });
      },
    });
    const validDate = (date) => {
      if (!date) return undefined;
      date = moment(date);
      if (date.isValid()) return date.toDate();
      return undefined;
    };
    // eslint-disable-next-line consistent-return
    const getMinMaxDate = (key) => {
      if (!_.isUndefined(props.inputProps)) {
        const minMax = props.inputProps[key];
        if (!minMax) return undefined;
        if (!isBo.value && !props.source) {
          const date = _.get(data, minMax, undefined);
          if (date) return moment(date).startOf('day');
          return date;
        }
        const dates = _(minMax)
          .castArray()
          .map((key1) => (props.source === 'massUpdate'
            ? data[key1]
            : getRealOrderForBO(key1).value))
          .compact()
          .map((d) => moment(d).startOf('day'))
          .value();
        if (dates.length === 0) return undefined;
        return moment[key === 'max' ? 'min' : 'max'](dates);
      }
    };
    const minDate = computed(() => {
      const date = getMinMaxDate('min');
      return validDate(date);
    });
    const maxDate = computed(() => {
      const date = getMinMaxDate('max');
      return validDate(date);
    });
    const getScrollParent = (node) => {
      const isElement = node instanceof HTMLElement;
      const overflowY = isElement && window.getComputedStyle(node).overflowY;
      const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

      if (!node) {
        return null;
      }
      if (isScrollable && node.scrollHeight >= node.clientHeight) {
        return node;
      }

      return getScrollParent(node.parentNode) || document.body;
    };
    const onOpen = () => {
      const menu = el?.getElementsByClassName('dropdown-menu')[0];
      const expandEl = () => {
        if (menu.style.display === 'none') return setTimeout(expandEl, 20);
        if (menu.scrollIntoViewIfNeeded) return menu.scrollIntoViewIfNeeded();
        const parent = getScrollParent(menu);
        if (
          parent.getBoundingClientRect().bottom
          < menu.getBoundingClientRect().bottom
        ) {
          return menu.scrollIntoView(false);
        }
        return true;
      };
      if (menu) expandEl();
    };

    const loadAndShowPicker = () => {
      state.loadPicker = true;
      setTimeout(() => {
        datePicker.value.focus();
      }, 0);
    };
    onMounted(() => {
      if (props.inputProps.fromWorkStep === true) {
        state.placeholder = 'keep current';
        return;
      }
      state.placeholder = 'mm/dd/yyyy';
    });

    return {
      el,
      position,
      isBo,
      kind,
      showPicker,
      displayValue,
      minDate,
      maxDate,
      getScrollParent,
      onOpen,
      validDate,
      getMinMaxDate,
      formattedDate,
      datePicker,
      isInvalidDate,
      dateWarning,
      isDateDisable,
      ...toRefs(state),
      loadAndShowPicker,
    };
  },
});
</script>

<style scoped>
::v-deep(input) {
  padding-left: 0 !important;
  padding-right: 0;
}
</style>
