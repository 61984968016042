<template>
    <div>
        <div v-if="!edit">
            <div class="mfact-active-color" :style="{ background: color }"></div>
        </div>
        <Dropdown v-else :shown="displayPicker">
            <div
            @click="toggle"
            class="mfact-active-color"
            :style="{ background: color }"
            ></div>
            <template #popper>
             <ColorPicker
                theme="light"
                :color="color"
                :sucker-hide="false"
                @changeColor="changeColor"
                />
            <a v-close-popper>Close</a>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import { defineComponent, toRefs, reactive } from 'vue';
import { Dropdown } from 'v-tooltip';
import { ColorPicker } from 'vue-color-kit';
import 'vue-color-kit/dist/vue-color-kit.css';

export default defineComponent({
  name: 'MfactColorPicker',
  components: {
    Dropdown,
    ColorPicker,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
  setup(props, { emit }) {
    const colorInitials = reactive({
      displayPicker: false,
    });
    const changeColor = (color) => {
      emit('update:color', color.hex);
    };
    const toggle = () => {
      colorInitials.displayPicker = true;
    };
    return {
      ...toRefs(colorInitials),
      toggle,
      changeColor,
    };
  },
});
</script>
