<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <div class="stick-detail-row-header">
    <div v-if= "activeScreen === 'schedule'"
      class="columns">
      <div class="column is-narrow pr-0">
        <span class="is-size-5 has-text-weight-bold ml-2">Material Order </span>
      </div>
      <div class="column is-2">
        <input class="input pl-1" v-if="isEdit" type="text"
          v-model.trim="mm.name"
          placeholder="Order Name">
        <span v-else class="is-capitalized is-size-5">{{mm.name || '(none)'}} </span>
      </div>
      <div class="column is-narrow is-9 is-9-fullhd is-5-desktop is-flex is-justify-content-flex-end">
        <div class="buttons" :class="!isEdit ? 'detail-button' : 'detail-cancel-button'">
          <button class="button px-0 has-text-black-bis"
          v-if="!isEdit" @click="editOrder">
            <i class="icon-edit"></i>
          </button>
          <button v-if = "isEdit" @click="handleAction('cancel')"
            class="button is-outlined is-danger px-0">
            <i class="icon-close"></i>
          </button>
          <button v-if = "isEdit" @click="handleAction('save')"
            :disabled='!mm.items.length'
            class="button is-success has-text-white px-0">
            <i class="icon-checkmark"></i>
          </button>
        </div>
      </div>
    </div>
   </div>
  <MfactTabs
    :tabs="detailRowTabs"
    :activeIndex="detailRowOptions.activeIndex"
    :key="rowData.__v"
    :tabLabel="tabLabel"
    @saveCard ="saveCard"
    @tabName="(val) => $emit('tabName', val)"
    :rootClass="activeScreen === 'inventory' ?
    'inventory-flyout' : ''"
  >
  <!-- note: calls mf-detail-row-tab -->
    <template v-slot="{ activeComponent, activeIndex }">
      <component
        v-bind:is="activeComponent"
        :class="detailRowTabs[activeIndex].classes"
        :edit="isEdit"
        :rowData="rowData"
        :tab="detailRowTabs[activeIndex]"
        :stage="stage"
        :refreshCard="getRefreshCard"
        :mm="mm"
        :key="refreshKey"
        @cell-clicked="detailActionTriggered"
        :catalogCollection="catalogCollection"
        :deliveryDetails="deliveryDetails"
        @updateItemQty="updateItemQty"
        @refreshCardViewOnKey="(val) => $emit('refreshCardViewOnKey', val)"
        @updateMatItemQty="updateMaterialQty">
      </component>
    </template>
  </MfactTabs>
</template>

<script>
import {
  defineComponent, computed, reactive, onBeforeMount, toRefs, ref, onBeforeUnmount,
} from 'vue';
import {
  get, some, isEmpty, cloneDeep, set, pick, findIndex, find, has, filter,
} from 'lodash';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import MfactTabs from '@/components/table-fields/MfactTabs.vue';
import SupplyChain from '@/models/SupplyChain';
import { BaseOrder } from '@/models/BaseOrder';
import MaterialManager from '@/models/MaterialManager';
import prefabColsForStatus from '@/components/table-cols/planningRowDetails';
import CardEditMixin from '@/components/mixins/CardEditMixin';

export default defineComponent({
  name: 'MfDetailRow',
	emits:['tabName', 'saveCard', 'cellClicked', 'updateItemQty', 'itemQtyUpdate'],
  components: {
    MfactTabs,
  },
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    rowIndex: Number,
    stage: String,
    refreshCard: Function,
    refreshTable: Function,
    tabLabel: {
      type: String,
      default: '',
    },
    catalogCollection: {
      type: Array,
      default: () => [],
    },
    deliveryDetails: {
      type: [Array, Object],
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const deliveriesTable = ref(null);
    const state = reactive({
      mm: {},
      isEdit: false,
      isLoading: false,
      refreshKey: props.rowData,
      itemQtyUpdate: false,
      matItemQtyUpdate: false,
    });
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const { getConfigDetails } = CardEditMixin();
    const { activeScreen } = store.state;
    const configDetails = getConfigDetails(activeScreen,
     props.rowData.isCutOrder ? 'CutOrder' : props.rowData.__t, props.rowData._customStage || props.rowData.stage);

    const changeTabIndex = (tab) => {
      const tabsInsideCard = ['runs', 'items', 'materials', 'Checklist'];
      if (!tabsInsideCard.includes(tab)) return 0;
      return tabsInsideCard.indexOf(tab);
    };

    const changeManagerTabIndex = (tab) => {
      const tabsInsideCard = ['workSteps', 'managerItem', 'materials', 'tasks'];
      if (!tabsInsideCard.includes(tab)) return 0;
      return tabsInsideCard.indexOf(tab);
    };

    const detailRowOptions = computed(() => {
      const tableOpts = get(configDetails, 'tableTabs', {});
      if (props.tabLabel) {
        const { activeIndex, ...opts } = tableOpts;
        return { activeIndex: findIndex(tableOpts.tabs, { id: props.tabLabel }), ...opts };
      }
      if (['manager-edit-detailing', 'manager-edit-qa',
        'manager-edit-manufacturing'].includes(activeScreen)) {
        const project = find(store.state.queryParams._projects,
          (p) => p._id === route.params.projectId);
        const projectSetting = project?.projectSettings[0];
        if (projectSetting) tableOpts.activeIndex = changeTabIndex(projectSetting.editSettingTab);
      }

      if (['manager-edit-detailing', 'manager-edit-manufacturing', 'manager-edit-qa'].includes(activeScreen) && route.query.tab) {
        // set items tab when scanning qr
        const mappedTabs = {
          items: 'managerItem',
          materials: 'materials',
          tasks: 'tasks',
          worksteps: 'workSteps',
        };
        if (has(mappedTabs, route.query.tab)) {
          tableOpts.activeIndex = changeManagerTabIndex(mappedTabs[route.query.tab]);
        } else {
          tableOpts.activeIndex = 0;
        }
      }
      return tableOpts;
    });

    if (props.rowData && props.rowData.shipType && ['shipping-order-view'].includes(activeScreen)) {
      localStorage.setItem('shipmentId', props.rowData._id);
    }
    const detailRowTabs = computed(() => {
      const companyId = get(props.rowData.owner, 'company._id', null);
      let hideSomeTabs = false
      if (companyId && store.state.userData && companyId !== store.state.userData.company) {
        hideSomeTabs = true;
      }
      if (['production-status-order-view'].includes(activeScreen)) {
        const { stage } = props.rowData;
        if (!['planning', 'mixed', 'coordination'].includes(stage)) {
          if (hideSomeTabs) {
            const psPmTabs = filter(cloneDeep(detailRowOptions.value.pmTabs), (pmtab)=> !['workSteps', 'tasks'].includes(pmtab.id))
            return psPmTabs
          }
          return detailRowOptions.value.pmTabs;
        }
        if (stage === 'coordination') {
          if (hideSomeTabs) {
            const psPoTabs = filter(cloneDeep(detailRowOptions.value.poTabs), (potab)=> !['materials'].includes(potab.id))
            return psPoTabs;
          }
          return detailRowOptions.value.poTabs;
        }
        if (stage === 'planning') {
          if (hideSomeTabs) {
            const psPoTabs = filter(cloneDeep(detailRowOptions.value.tabs), (planningTab)=> ['items'].includes(planningTab.id))
            return psPoTabs;
          }
          return detailRowOptions.value.poTabs;
        }
        if (stage === 'mixed') return detailRowOptions.value.mixedStageTabs;
      }
      if (activeScreen === 'shipping-edit' && route.params.cardId === 'add') {
        const { tabs } = cloneDeep(detailRowOptions.value);
        const fields = get(tabs[0].tableProps, 'fields', []);
        if (fields.length) {
          const qtyField = find(fields, { id: 'quantity' });
          if (qtyField) {
            qtyField.prop = 'selectedQty';
            qtyField.name = 'available-qty';
          }
        }
        return tabs;
      }
      return detailRowOptions.value.tabs || [];
    });
    const getRefreshCard = computed(() => props.refreshCard);
    const saveCard = (() => {
      if (some(props.rowData.items, (i) => !i.archived.value && !i._id)
        || some(props.rowData.manager.runs, (rn) => isEmpty(rn._id))) {
        emit('saveCard');
      }
    });

    const selectedTab = (val) => {
      emit('tabName', val);
    };

    const editOrder = () => {
      state.mm._beforeSave = cloneDeep(state.mm);
      state.isEdit = true;
      state.refreshKey = true;
    };

    const handleAction = async (action) => {
      if (action === 'save') {
        try {
          if (!state.mm.name.trim() || state.mm.name.length < 3) {
            toast.error(
              'Order Validation failed. Please entera valid order name!',
            );
            return;
          }
          if (!state.mm.items.length) {
            toast.error(
              'Item Validation failed. Please add an Item to save the Order!',
            );
            return;
          }
          state.isLoading = true;
          for (const item of state.mm.items) {
            if (!isEmpty(item.simpleMemos)) {
              const memo = item.newFTD('memos');
              memo.text = item.simpleMemos[0].text;
            }
          }
          state.mm = await state.mm.save();
          if (state.mm.stage === 'preparation' && activeScreen === 'schedule') {
            await MaterialManager.pullToSourcing([state.mm._id], state.mm);
            props.refreshTable();
          }
          emit('cell-clicked', { type: 'update-schedule', data: state.mm });
          state.isLoading = false;
        } catch (e) {
          state.isLoading = false;
          console.log('err', e);
          toast.error(`Error occured while saving the order.
            Please contact Manufacton Support`);
        }
      } else if (action === 'cancel') {
        state.mm = state.mm._beforeSave;
      }
      state.isEdit = false;
      state.refreshKey = false;
    };

    const triggerSheduleDetailRow = async () => {
      const row = cloneDeep(props.rowData);
      if (row.order) {
        state.isLoading = true;
        const params = {
          projectId: row.project._id,
          orderId: row.order._id,
        };
        [state.mm] = (await SupplyChain.supplyChain(params)).data;
        state.isLoading = false;
      } else {
        const order = pick(row, ['project', 'submittal']);
        state.mm = new BaseOrder(Object.assign(order, {
          _id: '',
          __t: 'Materials',
          stage: 'preparation',
          fromSchedule: true,
          name: '',
          scheduleOrder: { _id: row._id, name: row.activityName },
        }));
        if (!isEmpty(get(row, 'submittal.name', ''))) {
          state.mm.name = `${row.activityId} - ${get(row, 'submittal.name', '')}`;
        } else {
          state.mm.name = row.activityId;
        }
        for (const dateType of ['deliver', 'orderBy', 'approveBy', 'submitBy']) {
          if (get(row[dateType], 'value', false)) {
            state.mm.addOrUpdateDate(dateType, get(row[dateType], 'value'));
          }
        }
        if (row.vendor) set(state.mm, 'baseDelivery.vendor', row.vendor);
        const project = store.getters.findProject({ _id: state.mm.project._id });
        const defaults = project.getDefaults(state.mm, 'sourcing');
        if (!isEmpty(defaults.newOwner)) {
          state.mm.owner.user = defaults.newOwner;
        } else {
          const user = store.state.userData;
          state.mm.owner.user = {
            _id: user._id,
            name: user.name,
          };
        }
        if (isEmpty(state.mm.baseDelivery)) {
          state.mm.baseDelivery = {
            location: {},
          };
        }
        if (!isEmpty(defaults.newLocation)) {
          state.mm.baseDelivery.location = {
            _id: defaults.newLocation._id,
            name: defaults.newLocation.name,
          };
        } else {
          const location = project.projectSettings[0].projectInventoryLocation;
          state.mm.baseDelivery.location = {
            _id: location._id,
            name: location.name || location._name,
          };
        }
        state.mm.baseDelivery.receipent = state.mm.owner.user;
        state.mm.baseDelivery.recipientId = state.mm.owner.user._id;
        state.mm.submittalStatus = row.submittalResponse;
        state.mm.activity = row.activityName;
      }
      state.refreshKey = state.mm;
    };

    onBeforeMount(async () => {
      if (['production-status-order-view'].includes(activeScreen)) {
        const itemCols = [...(prefabColsForStatus['item-info'].fields)];
        if (props.rowData.stage !== 'planning') {
          detailRowOptions.value.tabs[0].tableProps.fields = itemCols.filter(
            (f) => f.id !== 'action',
          );
        } else {
          set(detailRowOptions.value.tabs[0].tableProps, 'fields', itemCols);
        }
      } else if (activeScreen === 'schedule') {
        triggerSheduleDetailRow();
      }
      if (_.isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
    });
    const detailActionTriggered = (params) => {
      if (params?.type === 'refresh-details') {
        if (activeScreen === 'schedule') {
          if (params?.data?.isNew) {
            const newItemIndex = findIndex(state.mm.items, { catId: params.data.catId });
            if (newItemIndex > -1) {
              state.mm.items.splice(newItemIndex, 1);
              toast.success('Item removed');
            }
          } else {
            triggerSheduleDetailRow();
          }
        }
      } else if (['shipping-edit', 'kit-view-edit'].includes(activeScreen)) {
        emit('cell-clicked', params);
      } else if (params.type === 'leadTime') {
        emit('update-dates');
      } else {
        props.refreshTable();
      }
    };
    const updateItemQty = (val) => {
      state.itemQtyUpdate = val;
      emit('itemQtyUpdate', state.itemQtyUpdate);
    };
    const updateMaterialQty = (val) => {
      state.matItemQtyUpdate = val;
      emit('itemQtyUpdate', state.matItemQtyUpdate);
    };

    onBeforeUnmount(() => {
      localStorage.removeItem('shipmentId');
    });

    return {
      ...toRefs(state),
      detailRowOptions,
      detailRowTabs,
      saveCard,
      activeScreen,
      editOrder,
      handleAction,
      getRefreshCard,
      detailActionTriggered,
      selectedTab,
      deliveriesTable,
      updateItemQty,
      updateMaterialQty,
    };
  },
});
</script>
