const commonFields = [
  {
    name: 'name',
    prop: 'name',
    width: '90%',
    title: 'Item Locations',
    id: 'name',
  },
];

export default {
  filter: {
    fields: [
      ...commonFields,
      {
        name: 'table-field-checkbox',
        customHeader: true,
        prop: 'selected',
        width: '10%',
        title: 'Action',
        id: 'select',
      },
    ],
  },
  transferRequest: {
    fields: [
      ...commonFields,
      {
        title: 'Select',
        name: 'table-field-radio',
        width: '20%',
        prop: 'selected',
        id: 'selected',
      },
    ],
  },
};
