import ShippingLabel from '@/models/ShippingLabel';
import {
  reactive, toRefs,
} from 'vue';
import { useToast } from 'vue-toastification';
import _ from 'lodash';
import { useRouter, useRoute } from 'vue-router';
import Helper from '@/models/Helper';
import { useStore } from 'vuex';
import moment from 'moment';
import Projects from '@/models/Projects';
import Locations from '@/models/Locations';
import Shipping from '@/models/Shipping';
import SupplyChain from '@/models/SupplyChain';
import ProductionManager from '@/models/ProductionManager';
import Tasks from '@/models/Task';

import { DialogProgrammatic } from '@/components/Dialog';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import CreateShipping from '@/components/modals/CreateShipping.vue';

export default function () {
  const toast = useToast();
  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const state = reactive({
    shipmentOrder: {},
  });
  /*
  * DELETE partial shipment/delivery
  */
  const removePartialShipment = async (masterShipment, partialShipment, moveCardBack = true) => {
    try {
      if (['in-transit', 'not-started'].includes(partialShipment.status)) {
        const type = partialShipment.shipType === 's-m' ? 'fromSelf' : 'fromPartial';
        partialShipment.items.forEach((item) => {
          partialShipment.removeItem(item, type, 0);
        });
        const backToInv = [];
        let cardIds;
        for (const item of partialShipment._rmItems) {
          if (!(partialShipment.itemKind === 'Sourcing' && partialShipment.shipType === 's-m')
          && _.get(item, 'source.from', '') !== 'card') {
            backToInv.push(item);
          }
        }
        if (backToInv.length) {
          cardIds = _.map(backToInv, 'cardId');
        } else {
          cardIds = _.map(_.get(partialShipment, 'items', []), 'source._id');
        }
        await partialShipment.save();
        if (moveCardBack) {
          const params = {
            projectId: partialShipment.project._id,
            cardIds,
          };
          await ProductionManager.moveBackToManu(params);
        }
      }
      await masterShipment.archive(partialShipment._id).then((resp) => {
        if (moveCardBack) {
          toast.success('Delivery deleted');
        }
        return resp;
      }).catch((err) => {
        throw err;
      });
    } catch (e) {
      console.log('error archiving', e);
      toast.error('Failed to archive shipping order!', e);
    }
  };
  const deleteOrder = (async (order, refresh, data = {}) => {
    let shippingOrder = order || new ShippingLabel();
    if ((['fulfilled', 'zombie', 'in-transit', 'not-started'].includes(shippingOrder.status))) {
      let msgToDisp = '';
      if (['fulfilled', 'zombie'].includes(shippingOrder.status)) {
        if (shippingOrder.shipType === 'm') {
          msgToDisp = 'The shipping order (and all its Partial Shipments) will be permanently removed. Continue?';
        } else {
          msgToDisp = 'The Shipping Order will be permanently deleted';
        }
      } else if (shippingOrder.shipType === 's') {
        msgToDisp = 'All items will be returned to the status \'Not Scheduled\'. The Shipping Order  will be removed. Continue?';
      } else if (shippingOrder.itemKind !== 'Sourcing') {
        msgToDisp = 'Order will be moved to manufacturing and the delivery will be deleted';
        if (!_.isUndefined(shippingOrder.revivedShipmentParent)) {
          msgToDisp = 'Items will be moved to Inventory and the delivery will be deleted';
        }
      } else {
        msgToDisp = 'All items will be moved to Unscheduled, and the Delivery will be deleted.';
      }
      if (
        shippingOrder.itemKind === 'Sourcing'
        && ['m', 's-m'].includes(shippingOrder.shipType)
        && _.every(shippingOrder.items, (item) => item.source.from === 'card'
        && !['fulfilled', 'zombie'].includes(shippingOrder.status))
      ) {
        msgToDisp = 'The Shipping Order will be permanently deleted';
      }
      if (shippingOrder.shipType === 'm') {
        const allowDelete = _.every(shippingOrder.partialShipments, (ps) => ['scheduled', 'not-started', 'in-transit'].includes(ps.status));
        if (!allowDelete) {
          toast.error('Not All Deliveries in Scheduled or In Transit!');
          return;
        }
      }
      const confirmParam = {
        title: 'DELETE SHIPPING ORDER',
        message: msgToDisp,
        okButton: 'Delete',
        cancelButton: 'Cancel',
        type: 'danger',
        onConfirm: async () => {
          try {
            data.isLoading = true;
            if (shippingOrder.itemKind === 'Sourcing') {
              toast.error('Material Shipping Order Can not be Deleted!');
              if (refresh) {
                await refresh();
              } else {
                await router.push({ path: '/logistics/shipping/order-view' });
              }
              return;
            }
            if (['in-transit', 'not-started'].includes(shippingOrder.status)) {
              let masterShipment;
              if (shippingOrder.shipType === 'm') {
                masterShipment = await Shipping.getPartialShipments({
                  shippingLabelId: shippingOrder._id,
                });
                for await (const partialShipment of masterShipment.partialShipments) {
                  await removePartialShipment(masterShipment, partialShipment, false);
                }
                shippingOrder = await Shipping.getPartialShipments({
                  shippingLabelId: shippingOrder._id,
                });
              }
              if (['s-m', 'm'].includes(shippingOrder.shipType)) {
                _.forEach(shippingOrder.items, (item) => {
                  shippingOrder.removeItem(item, 'fromSelf', 0);
                });
              } else {
                _.forEach(shippingOrder.items, (item) => {
                  shippingOrder.removeItem(item, 'fromPartial', 0);
                });
              }
              const backToInv = [];
              let cardIds;
              for (const item of shippingOrder._rmItems) {
                if (
                  !(shippingOrder.itemKind === 'Sourcing'
                    && ['m', 's-m'].includes(shippingOrder.shipType)
                  )
                  && _.get(item, 'source.from', '') !== 'card'
                ) {
                  backToInv.push(item);
                }
              }
              if (backToInv.length) {
                cardIds = _.map(backToInv, 'cardId');
              } else {
                cardIds = _.map(
                  _.get(shippingOrder, 'items', []),
                  'source._id',
                );
              }
              cardIds = _.filter(cardIds, (id) => id);
              await shippingOrder.save();
              const params = {
                projectId: shippingOrder.project._id,
                cardIds,
              };
              await ProductionManager.moveBackToManu(params);
              if (cardIds.length) {
                await Shipping.reserveCatalog({ cardId: cardIds[0], reserveFromOrderLoc: true });
              }
            }
            if (shippingOrder.shipType !== 'm') {
              await shippingOrder.archive(shippingOrder._id);
            }
            toast.success('Delivery deleted');
            if (!refresh) {
              await router.push({ path: '/logistics/shipping/order-view' });
            }
          } catch (error) {
            console.log(error);
            toast.error('Error marking the Shipping Order archived: please contact ManufactOn support');
          } finally {
            data.isLoading = false;
            if(refresh) await refresh();
          }
        },
      };
      DialogProgrammatic.confirm(confirmParam);
    }
  });
  const cancelShipment = (async (order, refresh) => {
    const confirmParams = {
      title: 'CANCEL SHIPMENT',
      message: 'Cancelling will clear the Ship Date, but will not remove the Shipping Order. You can enter a new Ship Date later.',
      okButton: 'Cancel Shipment',
      cancelButton: 'Do Not Cancel',
      type: 'danger',
      onConfirm: async () => {
        try {
          await order.cancelDelivery();
          if (route.path != '/logistics/shipping/order-view') {
            await router.push({ path: '/logistics/shipping/order-view' });
          }
          toast.success('Shipping Cancelled');
          if (refresh) refresh();
        } catch (err) {
          console.log(err);
        }
      },
    };
    DialogProgrammatic.confirm(confirmParams);
  });
  const markOrderFinal = (async (shippingLabel, refresh, stateValue = {}) => {
    const confirmParams = {
      title: 'Mark Shipment Final',
      message: 'Are you sure this is the final delivery location',
      okButton: 'Mark Final',
      cancelButton: 'Cancel',
      onConfirm: async () => {
        try {
          if (_.isBoolean(stateValue?.isLoading)) {
            stateValue.isLoading = true;
          }
          await Shipping.receiveAll({ shippingLabelId: shippingLabel._id, isFinal: true });
          toast.success('The Shipping Order is now marked Final');
          if (_.isBoolean(stateValue?.isLoading)) {
            stateValue.isLoading = false;
          }
          // router.go(0);
          return;
        } catch (error) {
          console.log(error);
          toast.error('Error marking the Shipping Order Final: please contact ManufactOn support');
        } finally {
          await refresh();
          if (_.isBoolean(stateValue?.isLoading)) {
            stateValue.isLoading = false;
          }
        }
      },
    };
    DialogProgrammatic.confirm(confirmParams);
  });

  const generateShipPdf = (async (newPS = false) => {
    if (!newPS) {
      toast.error('Missing Params');
    } else {
      let params = {
        shippingId: newPS.id,
        projectId: newPS.project._id,
        tzinfo: new Date().getTimezoneOffset(),
      };
      let qrLabel = await Shipping.getShippingLabel(params);
      await store.getters.userPromise;
      const user = store.state.userData.fullName;
      const compLogo = qrLabel[0].companyLogo;
      qrLabel = qrLabel[0].imageData;
      params = {
        emails: _.get(newPS, 'externalEmails', []),
        qrLabel,
        compLogo,
        userInfo: user,
        type: 'pdf',
        name: 'shippingList',
        ...params,
      };
      Shipping.generatePdf(params);
    }
  });

  const releaseToInv = async (currentLoc, shipments, refresh, stateValue, refreshCardOnKey) => {
    const confirmParams = {
      title: 'Confirm',
      message: 'Are you sure you want to release to inventory?',
      okButton: 'Yes',
      cancelButton: 'Cancel',
      onConfirm: async () => {
        if (_.isBoolean(stateValue?.isLoading)) {
          stateValue.isLoading = true;
        }
        const allShipments = _.castArray(shipments);
        const invLabels = [];
        try {
          for (const shipment of allShipments) {
            const result = await shipment.relaunchAndRelease(currentLoc);
            invLabels.push(result);
            await generateShipPdf(shipment);
          }
          toast.success(`Items Released To Inventory at Location ${currentLoc.name}`);
          refresh();
          const [invLabel] = invLabels;
          // if release to inv is done to nested loc, the label will have transferRequestId
          // TODO: redirect to transferReq
          if (_.isBoolean(stateValue?.isLoading)) {
            stateValue.isLoading = false;
          }
          // router.go(0);
        } catch (e) {
          console.log('Error', e);
          toast.error(_.get(e, 'data.message', 'Error while releasing to inventory!'));
        } finally {
          if (_.isFunction(refreshCardOnKey)) {
            await refreshCardOnKey();
          }
        }
        if (_.isBoolean(stateValue?.isLoading)) {
          stateValue.isLoading = false;
        }
      },
    };
    DialogProgrammatic.confirm(confirmParams);
  };

  const scheduleDelivery = (async (order) => {
    try {
      const validate = order.validateLabel();
      if (validate.error) {
        toast.error(validate.errText);
        return;
      }
      await order.relaunch();
      await toast.success('New delivery scheduled');
      await router.push({ path: '/logistics/shipping/order-view' });
    } catch (e) {
      toast.error(e.data.message);
      console.log(e);
    }
  });
  const updateShipment = (async (order, generatePdf = true) => {
    try {
      const validate = order.validateLabel();
      if (validate.error) {
        toast.error(validate.errText);
        return;
      }
      if (['released-to-inventory', 'zombie', 'in-storage', 'fulfilled', 'zombie',].includes(order.status)) {
        order = await order.updateShippingDetails();
      } else {
        order = await order.save();
      }
      toast.success('Changes saved.');
      if (generatePdf) {
        await generateShipPdf(order);
      }
    } catch (err) {
      toast.error(err.message || err || 'Something went wrong, please contact Manufacton Support');
      console.log(err);
    }
  });

  const isPartialPMShip = (order, itemsReady) => {
    if (!_.isEmpty(order.catQtyMaps)) {
      if (itemsReady.length < order.items.length) return true;
      return !_.every(
        order.items,
        ({ _id, quantity }) => _.find(itemsReady, { _id, available: quantity }),
      );
    }
    return false;
  };

  const setDeliveryFromCard = async (card, shipment) => {
    const cardLoc = card.baseDelivery.location;
    const { deliveryLocation } = card.delivery;
    const cardOwner = card.owner.user;
    const cardRecipient = card.baseDelivery.recipient;
    const deliverBy = card.simpleDates.deliver.value;
    const deliveryStart = card.simpleDates.shipBy.value;
    const cardNotify = card.alsoNotify;

    shipment.purpose = card.purpose;
    shipment.customId = card.customId;
    shipment.name = card.financialId ? `${card.financialId}-` : '';
    shipment.name += `${card.name}`;
    if (!shipment.minItemDelivery) {
      shipment.minItemDelivery = card.simpleDates.deliver.value;
    }
    const { _locations } = store.state.queryParams;
    const selectedDeliveyLoc = !_.isEmpty(_locations) ? _locations.find(
      (loc) => loc._id.toString() === cardLoc._id.toString(),
    ) : await Locations.getOne({ id: cardLoc._id });

    let { commonStockProject } = store.state.queryParams;
    // eslint-disable-next-line max-len
    commonStockProject = !_.isEmpty(commonStockProject) ? commonStockProject : await Projects.getCommonStockProject();
    Object.assign(shipment.delivery, {
      currentProject: card.project,
      deliveryProject: _.get(selectedDeliveyLoc, 'nestedLocation', false) ? commonStockProject : card.delivery.deliveryProject,
      currentLocation: cardLoc,
      deliveryLocation: (!_.isEmpty(deliveryLocation) ? deliveryLocation : cardLoc),
      recipient: cardRecipient,
      deliveryStart,
      deliverBy,
      owner: cardOwner,
      notify: cardNotify,
    });
    return shipment;
  };

  const getInventoryItems = async (card) => {
    const allInventoryItems = [];
    const { data } = await Helper.getMaterialItems(card);
    for (const item of data) {
      item.source = {
        _id: {
          _id: card._id,
          name: card.name,
        },
        project: card.project,
      };
    }
    allInventoryItems.push(...data);
    return _.filter(allInventoryItems, (dt) => dt.qtyToShip > 0);
  };

  // prepare shippinglabel for creating shipment (PO orders)
  const prepareToShip = async (card) => {
    const isMaterialsReadyToShip = await card.materialsReadyToShip();
    if (!isMaterialsReadyToShip && ['manufacturing', 'qa'].includes(card._customStage)) {
      throw new Error('Some of the materials are not reserved. Please reserve before shipping!');
    }

    const items = await Shipping.getCompletedItems(card);
    const inventoryItems = await getInventoryItems(card);
    items.push(...inventoryItems);
    items.filter((item) => {
      if (item.qtyToShip === 0) { return false; }
      item.available = item.qtyToShip || item.available;
      _.set(item, 'selectedQty', item.available);
      return item;
    });
    if (!items.length) {
      throw new Error('No items available to ship!');
    }
    if (isPartialPMShip(card, items)) {
      throw new Error('Items not ready to ship !');
    }
    if (card.status === 'pause') {
      throw new Error('The order is Paused. Cannot be moved to Delivery!');
    }
    if (card.isPM() && _.some(card.items, ({ catId }) => _.isEmpty(catId))) {
      const [locDetails] = await Locations.getOne({ id: card.manager.location._id });
      if (locDetails.nestedLocation) {
        throw new Error(`Cannot ship items without catalog id to nested location '${locDetails.name}`);
      }
    }
    // create shipping label
    let shippingLabel = new ShippingLabel({
      name: card.name,
      purpose: 'general',
      project: card.project,
      reserveFor: null,
      items: [],
      externalEmails: [],
      shipType: 's-m',
      delivery: {},
    });
    // set all the required properties in the shipping label
    shippingLabel = setDeliveryFromCard(card, shippingLabel);
    const materialItems = [];
    const itemsToIncrement = [];

    // prevent item processing when label is in-transit since its unnecessary
    for (const item of items) {
      if (item.isKitMaterial) {
        shippingLabel.addItemFromShipment(item, item.sl, item.quantity);
      } else if (item.isMaterialItem) {
        item.qtyToAdd = item.selectedQty;
        materialItems.push(item);
      } else if (item.selectedQty > (item.initialAvailable || item.available || item.quantity)) {
        item.qtyToAdd = item.selectedQty - (item.available || item.quantity);
        itemsToIncrement.push(item);
      } else if (item.isInventoryItem) {
        shippingLabel.addItemFromInventory(item, item.selectedQty);
      } else {
        item.selectedQty = item.quantity || item.available || item.total;
        await shippingLabel.addItemFromCard(item, (card._id) ? card : item.source._id);
      }
    }
    return shippingLabel;
  };

  // takes in a baseorder card
  const moveToShipping = async (order) => {
    try {
      if (order.isKit() && order.isLocked) {
        toast.error('Cannot create shipping, one or more Orders assembly missing');
        return;
      }
      if ((order.isMM() && order.readyForShipment()) || order.isPM()) {
        const shippingLabel = await prepareToShip(order);
        router.push({
          name: 'shipping-edit',
          params: {
            projectId: order.project._id,
            cardId: 'add',
            stage: order.stage,
            orderJSON: JSON.stringify(shippingLabel),
            orderCard: JSON.stringify(order),
          },
        });
      } else {
        toast.error('order not ready for shipment');
      }
    } catch (e) {
      toast.error(e.message || e || 'Something went wrong, please contact Manufacton Support');
    }
  };

  const moveToKitLoc = (async (card, saveCard) => {
    try {
      await saveCard(card._id, card.stage, card);
      const shipMaterials = await card.materialsReadyToShip();
      if (!shipMaterials) {
        throw new Error('Some of the materials are not reserved. Please reserve before moving to kit location!');
      }
      if (!_.every(card.manager.runs, (run) => run.isCompleted)) {
        throw new Error('Cannot move to Kit location, since one more runs are not completed');
      }
      if (card.status === 'pause') {
        throw new Error('Order is paused, cannot move to Kit Location');
      }
      await selectKitLoc(card);
      await consumeAndUnreserve({ order: card });
    } catch (e) {
      console.log(e);
      const errMsg = e.message || 'Error Moving assemblies to kit location.Please contact Manufacton Support';
      toast.error(errMsg);
    }
  });

  const selectKitLoc = (async (card) => {
    let kitCard = await SupplyChain.supplyChain({
      projectId: card.project._id,
      module: ['ProductionOrder'],
      purpose: 'kit',
      customId: card.customId,
    });
    if (kitCard.data.length === 0) {
      toast.error('Unable to find associated Kit. Please contact Manufacton Support');
      throw new Error('Unable to find associated Kit. Please contact Manufacton Support');
    }
    [kitCard] = kitCard.data;
    if (kitCard.stage === 'coordination') {
      throw new Error('Move to Kit is only allowed when associated Kit is past Coordination stage.');
    }
    await shipToKit(kitCard.manager.location, kitCard, card);
  });

  const shipToKit = (async (shipmentLocation, kitCard, card) => {
    let intermediateShip = new ShippingLabel({ name: `${card.name} Reserved for ${kitCard.name}`, isInternal: true });
    intermediateShip.delivery = {
      currentLocation: card.manager.location,
      currentProject: card.project,
      deliveryLocation: shipmentLocation,
      deliveryProject: card.project,
      deliveryStart: moment().hours(12),
      deliverBy: moment().hours(12),
      owner: _.get(card, 'manager.owner.user', ''),
      recipient: _.get(card, 'manager.owner.user', ''),
      notifyUsers: [],
    };
    const completedItems = await Shipping.getCompletedItems(card);
    if (_.isEmpty(completedItems)) {
      toast.error('No completed items found. Please make sure items are manufactured.');
      return;
    }
    const cardNotes = card.manager.notes;
    for (const item of completedItems) {
      item.selectedQty = item.available;
      if (!_.isEmpty(cardNotes)) {
        item.notes = cardNotes;
      }
      intermediateShip.addItemFromCard(item, item.source._id);
    }
    intermediateShip.reserveFor = { _id: kitCard._id };
    await intermediateShip.save();
    intermediateShip = await intermediateShip.receive();
    return intermediateShip;
  });

  const consumeAndUnreserve = (async (params) => {
    try {
      await Shipping.consumeAndUnreserve(params);
      if (params.order.materials.length > 0) toast.success('Reservations updated');
    } catch (e) {
      console.log('Error', e);
      throw e;
    }
    return true;
  });
  const unreserveAll = (async(order) => {
    try {
      /* 1) If Pick is created for order and status is in 'not-started'
        i.e no items are reserved for card, then complete TR.
       2) If Pick req is created and some/all of items are reserved,
       release all items to project inventory location and create stock
       req for reserved items */
      const params = {
        createdFor: order._id,
        type: 'pick',
      };
      const assosiatedTRs = await Tasks.getTask(params);
      if (!_.isEmpty(assosiatedTRs)) {
        const updatePromises = [];
        for (const task of assosiatedTRs) {
          if (task.status !== 'complete') {
            updatePromises.push(Tasks.update(task._id, { status: 'complete' }));
          }
        }
        await Promise.all(updatePromises);
      }
      const { data: labels } = await Shipping.get({
        cardIds: [order._id],
        projectId: order.project._id,
        status: ['not-started', 'in-storage', 'fulfilled', 'completed', 'in-transit', 'mixed'],
        limit: 100,
        page: 1,
      });
      if (!labels.length) {
        return true;
      }
      const project = await Projects.getOne(order.project._id);
      const invLocation = _.get(project.projectSettings[0], 'projectInventoryLocation', {});
      for (const sl of labels) {
        if (sl.purpose !== 'assembly' || order.stage !== 'detailing') {
          const user = store.state.userData
          if (project.hasNesetedProjectInvLoc(user)) {
            /* While moving back from manufacturing to ordering,
            if new shipping labels are directly created from 'order.manager.location' to
            project inventory (which is nested), inter project shipment will be created which
            results in -ve qauntity in 'order.manager.location'.
            As a fix, first we release all qty to 'order.manager.location'
            and then create a new inter project shipment. */
            sl._delivery.deliveryLocation = order.manager.location;
            /* Setting fromInventory = true  because if both 'order.manager.location'
            and project inventory location are nested then on first release, ie while
            releasing to order location stock req creation must be skipped. */
            const createStockReq = _.get(order.manager, 'location._id', '').toString() === _.get(invLocation, '_id', '').toString();
            await sl.releaseToInventory(false, createStockReq);
            const newLbl = await sl.createFromLabel({ project });
            const deliveryLoc = _.get(newLbl._delivery, 'deliveryLocation', {});
            await newLbl.relaunchAndRelease(deliveryLoc);
          } else {
            await sl.unreserve();
            await sl.relaunchAndRelease(invLocation);
          }
        }
      }
      toast.success('Reservations updated');
      return true;
    } catch (e) {
      console.log(e);
      throw e;
    }
  });

  const addItemFromInv = (async (shipment, itemsToAdd) => {
    const multiShipments = [];
    const nonCatItems = _.some(itemsToAdd, (data) => data.value && _.isEmpty(data.item.catId));
    let deliveryLoc = _.get(shipment, '_delivery.deliveryLocation', false);
    if (nonCatItems && deliveryLoc) {
      if (_.isUndefined(deliveryLoc.nestedLocation)) {
        [deliveryLoc] = await Locations.getOne({ id: deliveryLoc._id });
      }
      if (_.get(deliveryLoc, 'nestedLocation', false)) {
        toast.error(`Cannot ship items without catalog id to nested location '${deliveryLoc.name}'`);
        return;
      }
    }
    const itemsData = [...shipment.items, ...itemsToAdd];
    let allItems = [];
    if (itemsData.length > 50) {
      allItems = _.chunk(itemsData, 50);
    } else {
      allItems = [itemsData];
    }
    for (const idx of _.times(allItems.length)) {
      let shipmentToConsider = shipment;
      if (idx !== 0) {
        // eslint-disable-next-line prefer-const
        let { _newItems, ...newShipment } = _.cloneDeep(shipment);
        newShipment = new ShippingLabel(newShipment);
        newShipment.name = `${shipment.name} ${idx}`;
        shipmentToConsider = newShipment;
        newShipment.createNewLabel = true;
      }
      for (const dataItem of allItems[idx]) {
        if (dataItem.value) {
          const { item } = dataItem;
          shipmentToConsider.addItemFromInventory(item, item.qtyNeeded);
        }
      }
      multiShipments.push(shipmentToConsider);
    }
    const newShipments = [];
    for (const shipment of multiShipments) {
      let newPS = {};
      if (['released-to-inventory', 'zombie'].includes(shipment.status)) {
        newPS = await shipment.updateShippingDetails();
      } else {
        try {
          newPS = await shipment.save();
        } catch (e) {
          console.log(e);
          throw e;
        }
      }
      newShipments.push(newPS);
    }
    return newShipments;
  });

  const isKitLocked = (orders) => {
    const lockedOrders = _.some(orders, { isLocked: true });
    return lockedOrders;
  };

  const pausedOrderCheck = (orders) => {
    const pausedOrders = _.some(orders, { status: 'pause' });
    return !pausedOrders;
  };

  const canShipOrders = async (orders) => {
    // get invLocation for sendToInventory
    let invLoc;
    let nestedInventoryLoc = false;
    const invLocId = _.get(store.state.queryParams.multiMoveInvLoc, '_id', '');
    if (invLocId) {
      [invLoc] = await Locations.getOne({ id: invLocId });
      nestedInventoryLoc = _.get(invLoc, 'nestedLocation', false);
    }
    for (const order of _.castArray(orders)) {
      if (order.isPM() && _.some(order.items, ({ catId }) => _.isEmpty(catId))) {
        if (nestedInventoryLoc) {
          toast.error(`Cannot ship items without catalog id to nested location '${invLoc.name}'`);
          return false;
        }
        const [locDetails] = await Locations.getOne({ id: order.manager.location._id });
        if (locDetails.nestedLocation) {
          toast.error(`Cannot ship items without catalog id to nested location '${locDetails.name}'`);
          return false;
        }
      }
    }
    return true;
  };

  const setDeliveryFromMultiOrders = async (orders, shipment, fromInventory = {}) => {
    const onsiteDatesVals = [];
    const sItems = [];
    let deliveryStart;
    let inventoryLocation;
    let itemsData = [];

    await store.getters.userPromise;
    let user = store.state.userData;
    if (!_.isEmpty(fromInventory)) user = _.pick(store.state.userData, ['_id', 'fullName']);
    if (orders.length > 0) {
      const projectId = orders[0].project;
      const locationId = orders[0].manager.location;
      for (const eachOrder of orders) {
        onsiteDatesVals.push(eachOrder.getDate('deliver'));
      }
      const deliverBy = moment(_.min(onsiteDatesVals)).hours(12).format();
      deliveryStart = moment().hours(12).format();
      if (moment().isAfter(deliverBy)) deliveryStart = deliverBy;
      if (!_.isEmpty(fromInventory)) {
        deliveryStart = moment().hours(12);
        inventoryLocation = fromInventory.dest;
      }
      for (const item of orders) {
        sItems.push(Shipping.getCompletedItems(item));
      }
      const allItems = await Promise.all(sItems);
      itemsData = _.flatten(allItems);
      shipment.items = itemsData;
      const today = new Date();
      // for now multi move of order defaults
      shipment.name = `Shipment ${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;
      shipment.minItemDelivery = deliverBy;
      shipment.project = projectId;
      const [cardLocDetails] = await Locations.getOne({ id: locationId._id });
      let { commonStockProject } = store.state.queryParams;
      commonStockProject = !_.isEmpty(commonStockProject) ? commonStockProject : await Projects.getCommonStockProject();
      Object.assign(shipment.delivery, {
        currentProject: projectId,
        deliveryProject: cardLocDetails.nestedLocation ? commonStockProject._id : projectId,
        currentLocation: locationId,
        deliveryLocation: inventoryLocation || locationId,
        recipient: user,
        deliveryStart,
        deliverBy,
        owner: user,
      });
      Object.assign(shipment, {
        owner: {
          user,
        },
      });
      return shipment;
    }
  };

  const moveOrdersToInventory = async (orders, params) => {
    let shipment = new ShippingLabel({
      name: '',
      reserveFor: null,
      delivery: {},
      items: [],
      externalEmails: [],
      shipType: 's-m',
    });

    shipment = await setDeliveryFromMultiOrders(orders, shipment, params);

    /* eslint-disable no-await-in-loop */

    for (const item of shipment.items) {
      item.selectedQty = item.available;
      // ^ *IMPORTANT* W/o this assignment, move to Inventory will fail
      await shipment.addItemFromCard(item, item.source._id);
    }

    try {
      await shipment.save();
      shipment = await shipment.relaunchAndRelease(shipment.delivery.deliveryLocation);
      // if release to inv is done to nested loc, the label will have transferRequestId
      const trCreatedMsg = shipment.transferRequestId ? ' Tranfer Request Created' : '';
      toast.success(`Shipping Orders moved to Inventory.${trCreatedMsg}`);
      if (shipment.transferRequestId) {
        router.push({
          path: `/transfer-request/${shipment.transferRequestId}`,
          params: { id: shipment.transferRequestId },
        });
      }
      // refreshTable();
    } catch (e) {
      console.log(e);
    }
  };

  const sendToInventory = async (orders) => {
    if (isKitLocked(orders)) {
      this.errorDialog('Cannot move to inventory, one or more Orders assembly missing....!');
      this.checkedList = [];
      return;
    }
    if (!await canShipOrders(orders)) return;
    await moveOrdersToInventory(
      orders,
      { dest: _.get(store.state.queryParams.multiMoveInvLoc, '_id', '') },
    );
  };

  const sendOrdersToInventory = async (orders) => {
    let errMsg = '';
    if (!pausedOrderCheck(orders)) {
      errMsg = 'Cannot move to Inventory, one or more Order is paused....!';
      toast.error(errMsg);
      throw new Error(errMsg);
    }
    const totalItemsLength = _.sumBy(orders, 'items.length');
    if (totalItemsLength > 50) {
      errMsg = 'Max. 50 Items per shipping order. Please select another Production Order';
      toast.error(errMsg);
      throw new Error(errMsg);
    }
    await sendToInventory(orders);
  };
  const reservationCheck = async (orders, isShipping = false, refreshTable, reset) => {
    if (isShipping) {
      ModalProgrammatic.open({
        component: CreateShipping,
        props: {
          orders,
          isMultiShipment: true,
          aggregateFlag: true,
        },
        events: {
          close: async (moved) => {
            if (moved) {
              reset();
              await refreshTable();
            }
          },
        },
        canCancel: false,
        rootClass: 'modal-sm',
      });
    }
  };
  const fetchItemsToShip = async (orders, refreshTable, reset, isShipping = true) => {
    if (isKitLocked(orders)) {
      toast.error('Cannot move to shipping, one or more Orders assembly missing....!');
      return;
    }
    pausedOrderCheck(orders);
    if (!await canShipOrders(orders)) return;
    let partialMatMove = false;
    const sItems = _.map(orders, async (order) => {
      const itemsReady = await Shipping.getCompletedItems(order);
      partialMatMove = partialMatMove || isPartialPMShip(order, itemsReady);
      return itemsReady;
    });
    let selectedOrderItems = await Promise.all(sItems);
    if (partialMatMove) {
      const msg = 'Items not ready to ship!';
      toast.error(`<strong>${msg}</strong>`);
      return;
    }
    selectedOrderItems = _.flatMap(selectedOrderItems);
    if (!selectedOrderItems.length) {
      const msg = 'No items available to ship!';
      toast.error(`<strong>${msg}</strong>`);
      return;
    }
    await reservationCheck(orders, isShipping, refreshTable, reset);
  };

  const isMaterialsReadyToShip = async (card) => {
    if (card._customStage !== 'manufacturing') return true;
    // filter out material items
    const materialItems = card.items.filter((item) => item.qtyToShip && (item.qtyToShip > 0));
    return !_.some(
      materialItems,
      (dt) => (parseFloat(dt.qtyToConsume) + parseFloat(dt.qtyToShip)) > parseFloat(_.get(dt, 'reserved', 0)),
    );
  };

  const multiMoveToShip = async (selectedCards, refreshTable, reset) => {
    if (!pausedOrderCheck(selectedCards)) {
      toast.error('Cannot move to shipping, one or more Order is paused....!');
      return false;
    }
    const movableOrders = [];
    const nonMovableOrders = [];
    for await (const selectedCard of selectedCards) {
      const isReady = await selectedCard.materialsReadyToShip();
      if (isReady) {
        movableOrders.push(selectedCard._id);
      } else { nonMovableOrders.push(selectedCard._id); }
      for (const cardId of nonMovableOrders) {
        _.remove(selectedCards, { _id: cardId });
      }
      if (nonMovableOrders.length && movableOrders.length) {
        toast.error('Some of the materials are not reserved, Those Orders will Not be Shipped. Please reserve before shipping!');
      } else if (nonMovableOrders.length && !movableOrders.length) {
        const errMsg = 'Some of the materials are not reserved. Please reserve before shipping!';
        toast.error(`${errMsg}`);
        throw new Error(errMsg);
      }
    }
    const totalItemsLength = _.sumBy(selectedCards, 'items.length');
    if (totalItemsLength > 50) {
      const errMsg = 'Max. 50 Items per shipping order. Please select another Production Order';
      toast.error(errMsg);
      throw new Error(errMsg);
    }
    const refresh = refreshTable;
    await fetchItemsToShip(selectedCards, refresh, reset);
  };

  const getMasterShipment = async (shipmentId) => {
    const mS = await Shipping.getPartialShipments({
      shippingLabelId: shipmentId,
    });
    const result = {
      mLabel: mS,
      partials: mS.partialShipments,
    };
    return result;
  };

  const getStaticColor = (val) => {
    if (val) {
      const color = val.replace(/ /g, '').replace(/-/g, '').toLowerCase();
      const statusColors = {
        notstarted: 'has-background-primary',
        scheduled: 'mf-lm-background',
        inprogress: 'has-background-primary',
        complete: 'has-background-success',
        fulfilled: 'has-background-success',
        zombie: 'has-background-success',
        paused: 'has-background-orange',
        noworkflow: 'has-background-grey-light',
        unscheduled: 'has-background-orange',
        intransit: 'has-background-primary',
        instorage: 'has-background-primary',
      };
      if (statusColors[color]) return statusColors[color];
    }
    return '';
  };

  const getDeliveriesOfItems = async (itemId, shipmentId) => {
    // get shipment details
    const masterShipment = await Shipping.getPartialShipments({
      shippingLabelId: shipmentId,
    });
    // fetch which partial shipment has used the item
    const partials = masterShipment.partialShipments;
    const summaryArr = [];
    if (partials?.length) {
      for (let i = 0; i < partials.length; i++) {
        const partialItems = partials[i].items;
        const filteredItem = partialItems.filter((item) => item._id === itemId);
        if (filteredItem.length) {
          const itemSummary = {
            deliveryName: partials[i].name || '',
            deliveryItemQty: filteredItem[0].quantity || 0,
          };
          summaryArr.push(itemSummary);
        }
      }
    }
    return summaryArr;
  };
  const fetchUnderDelivered = (mShippingLabel) => {
    const itemMap = {};
    for (const pl of mShippingLabel.partialShipments) {
      for (const item of pl.items) {
        const key = item.catId !== '' ? 'catId' : '_id';
        if (item.underDeliver) {
          const itemKey = item[key];
          if (itemMap[itemKey]) {
            const sameItem = itemMap[itemKey];
            sameItem.underDeliver += item.underDeliver;
            if (sameItem.issueNote === '' && item.sameItem !== '') {
              sameItem.issueNote = item.issueNote;
            } else if (sameItem.issueNote !== '' && sameItem.sameItem !== item.issueNote) {
              sameItem.issueNote = 'Mixed';
            }
          } else {
            itemMap[itemKey] = item;
          }
        }
      }
    }
    const unscheduledPartialItems = _.values(itemMap) || [];
    // check if unscheduled row already exists in tableData
    if (unscheduledPartialItems.length) {
      // this is required since not to modify previous dates of card
      const rowDataClone = _.cloneDeep(mShippingLabel);
      const deliveryObj = Object.assign(rowDataClone._delivery, {
        deliveryStart: '',
        deliverBy: '',
        trackingId: '',
      });
      const unscheduledLabel = new ShippingLabel({
        name: '[Under Delivered Items]',
        purpose: 'general',
        customId: '',
        reserveFor: null,
        delivery: deliveryObj,
        id: 'underDelivered',
        _id: 'underDelivered',
        status: 'underDelivered',
        items: unscheduledPartialItems,
        externalEmails: [],
        shipType: 's',
      });
      return unscheduledLabel;
    }
    return {};
  };
  /*
  * Unscheduled Row for Shipping Deliveries
  */
  const fetchUnscheduledRow = (mShippingLabel, tableData) => {
    // check if unscheduled items exists in deliveries
    const unscheduledPartialItems = mShippingLabel.items.filter(
      (item) => (item && item.masterSummary?.notScheduled > 0),
    );

    // check if unscheduled row already exists in tableData
    const isUnscheduledExists = tableData.filter((obj) => obj.id === 'unscheduled');

    if (!isUnscheduledExists.length && unscheduledPartialItems.length) {
      // this is required since not to modify previous dates of card
      const rowDataClone = _.cloneDeep(mShippingLabel);
      const deliveryObj = Object.assign(rowDataClone._delivery, {
        deliveryStart: '',
        deliverBy: '',
        trackingId: '',
      });
      const unscheduledLabel = new ShippingLabel({
        name: '[Unscheduled Items]',
        purpose: 'general',
        customId: '',
        reserveFor: null,
        delivery: deliveryObj,
        id: 'unscheduled',
        _id: 'unscheduled',
        status: 'unscheduled',
        items: unscheduledPartialItems,
        externalEmails: [],
        shipType: 's',
      });
      return unscheduledLabel;
    }
    return {};
  };



  const validateShippingLabel = async (shippingLabel) => {
    try {
      const validate = shippingLabel.validateLabel();
      if (validate.error) {
        throw new Error(validate.errText);
      }
      const nonCatItems = _.some(shippingLabel.items, ({ catId }) => _.isEmpty(catId));
      let deliveryLoc = _.get(shippingLabel, 'delivery.deliveryLocation', false);
      if (nonCatItems && deliveryLoc) {
        if (_.isUndefined(deliveryLoc.nestedLocation)) {
          [deliveryLoc] = await Locations.getOne({ id: deliveryLoc._id });
        }
        if (_.get(deliveryLoc, 'nestedLocation', false)) {
          throw new Error(`Cannot ship items without catalog id to nested location '${deliveryLoc.name}'`);
        }
      }
      return true;
    } catch (err) {
      toast.error(err.message);
      return false;
    }
  };

  const areItemsFromCard = (sL) => _.some(sL.items, (item) => item.source.from === 'card');

  /*
  * This takes in cards(optional) and a shipping label
  * used while creating shipment from list view (from Inventory)
  */
  const createNewShipment = async (cards = [], sL) => {
    let card;
    if (cards && cards.length > 1) {
      [card] = cards;
    }
    try {
      const itemsData = sL.items.filter((item) => item.selectedQty > 0);

      // check if items are available to ship
      if (!itemsData.length) {
        throw new Error('No items available to ship!');
      }

      itemsData.forEach((item) => {
        item.quantity = item.selectedQty;
      });

      // validate shipping label
      const validate = sL.validateLabel();
      if (validate.error) {
        throw new Error(validate.errText);
      }

      const nonCatItems = _.some(itemsData, ({ catId }) => _.isEmpty(catId));

      let deliveryLoc = _.get(sL, 'delivery.deliveryLocation', false);
      if (nonCatItems && deliveryLoc) {
        if (_.isUndefined(deliveryLoc.nestedLocation)) {
          [deliveryLoc] = await Locations.getOne({ id: deliveryLoc._id });
        }
        if (_.get(deliveryLoc, 'nestedLocation', false)) {
          throw new Error(`Cannot ship items without catalog id to nested location '${deliveryLoc.name}'`);
        }
      }

      // check if inventory items
      if (['in-storage', 'not-started', 'mixed', 'in-transit'].includes(sL.status)
        && !(sL.shipType === 'm' && sL.itemKind === 'Sourcing' && areItemsFromCard(sL))) {
        // getting supply chain data
        const { data } = await SupplyChain.inventory({
          params: {
            locs: sL.delivery.currentLocation._id,
            projectId: sL.delivery.currentProject._id,
            limit: 1000,
            page: 1,
            // show only items that are present at the root location
            // if nested lcoation is selected as src loc.
            getOnlyRoot: true,
          },
        });

        const fetchedInventoryItems = data;

        const inventoryData = itemsData.filter((item) => {
          if (fetchedInventoryItems.some((invItem) => invItem.uid === item.uid)) {
            return item;
          }
        });

        if (inventoryData.length) {
          inventoryData.forEach((invItem) => {
            invItem.availableInInv = invItem.available || 0;
            const indx = itemsData.findIndex((item) => item.uid === invItem.uid);
            if (indx !== -1) {
              itemsData.splice(indx, 1);
              itemsData.push(invItem);
            }
          });
        } // end of inv
      }

      // to create multiple shipments incase selected items are more than 50
      const multiShipments = [];
      try {
        let allItems = [itemsData];
        if (itemsData.length > 50) { allItems = _.chunk(itemsData, 50); }

        for (const idx of _.times(allItems.length)) {
          let shipmentToConsider = sL;
          if (idx !== 0) {
            // eslint-disable-next-line prefer-const
            let { _newItems, ...newShipment } = _.cloneDeep(sL);
            newShipment = new ShippingLabel(newShipment);
            newShipment.name = `${sL.name} ${idx}`;
            newShipment.createNewLabel = true;
            shipmentToConsider = newShipment;
          }

          for (const dataItem of allItems[idx]) {
            if (card && !_.isEmpty(card)) {
              if (dataItem.isKitMaterial) {
                shipmentToConsider.addItemFromShipment(
                  dataItem,
                  dataItem.sl,
                  dataItem.quantity,
                );
              } else {
                dataItem.selectedQty = dataItem.quantity || dataItem.available || dataItem.total;
                shipmentToConsider.addItemFromCard(
                  dataItem,
                  (card._id) ? card : dataItem.source._id,
                );
              }
            } else if (dataItem.isInventoryItem) {
              dataItem.selectedQty = dataItem.quantity || dataItem.available || dataItem.total;
              shipmentToConsider.addItemFromInventory(dataItem, dataItem.selectedQty);
            }
          }
          multiShipments.push(shipmentToConsider);
        }
        if (multiShipments.length > 1) {
          for (const sl of multiShipments) {
            if (cards && cards.length > 1) {
              sl.contributedCards = _.map(cards, '_id');
            } else if (card && !_.isEmpty(card)) {
              sl.contributedCards = [card._id];
            }
          }
        }
        const newShipments = [];
        for (const sl of multiShipments) {
          let createdShipment = {};
          try {
            if (card && !_.isEmpty(_.get(card, '_id', '')) && route.params.cardId === 'add') {
              await consumeAndUnreserve({ order: card });
            }
            createdShipment = await ShippingLabel.save(sl);
            await generateShipPdf(createdShipment);
          } catch (err) {
            throw new Error(err.message || err.data.message || 'Error while processing shipment. Please try again later');
          }
          newShipments.push(createdShipment);
        }
        if (newShipments && newShipments.length > 1) {
          await router.push({
            path: '/logistics/shipping/order-view',
          });
        } else {
          const [singleShipment] = newShipments;
          router.push({
            name: 'shipping-edit',
            params: {
              cardId: singleShipment._id,
              projectId: singleShipment.project._id,
            },
          });
        }
        toast.success('Shipment created');
        toast.warning('Shipping list being generated and will be attached in a moment.');
      } catch (e) {
        throw new Error(e.message || e.data.message || 'Error while processing shipment. Please try again later');
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  const checkIfKitComponentsMoved = (cards) => {
    if (_.some(cards, (card) => card._customStage === 'manufacturing' && _.get(card, 'purpose') === 'kit'
    && card.isLocked)) {
      toast.error('Some Kit components are still in Manufacturing. Please advance them out of manufacturing and Try again');
      return false;
    }
    return true;
  };
  const addItemsFromMaster = (params) => {
    const { selectedItems, masterShipment } = params;
    const itemsToAdd = [];
    for (const index in selectedItems) {
      // get the masterShipmentItem
      const selectedItem = _.find(masterShipment.items, (item) => {
        if (item._id === '000000000000000000000000') {
          return item.catId === selectedItems[index].catId;
        }
        return item._id === selectedItems[index]._id;
      });
      if (_.isEmpty(selectedItem) || selectedItem.selectedQty < 1) {
        continue;
      }
      const newSItem = _.cloneDeep(selectedItem);
      newSItem.quantity = selectedItems[index].selectedQty;
      newSItem.isUsed = true;
      Object.assign(newSItem, {
        id: newSItem._id + masterShipment._id,
        isMasterItem: true,
        selected: true,
        showInput: true,
        selectedQty: newSItem.quantity,
        isEditing: true,
        isTemporaryAddition: true,
      });
      itemsToAdd.push(newSItem);
    }
    return itemsToAdd;
  };

  return {
    ...toRefs(state),
    deleteOrder,
    markOrderFinal,
    releaseToInv,
    generateShipPdf,
    scheduleDelivery,
    createNewShipment,
    cancelShipment,
    updateShipment,
    isPartialPMShip,
    prepareToShip,
    moveToShipping,
    addItemFromInv,
    moveToKitLoc,
    selectKitLoc,
    shipToKit,
    sendOrdersToInventory,
    // createShipment,
    multiMoveToShip,
    getDeliveriesOfItems,
    getMasterShipment,
    getStaticColor,
    consumeAndUnreserve,
    fetchUnscheduledRow,
    fetchUnderDelivered,
    removePartialShipment,
    validateShippingLabel,
    getInventoryItems,
    setDeliveryFromCard,
    checkIfKitComponentsMoved,
    isMaterialsReadyToShip,
    setDeliveryFromMultiOrders,
    addItemsFromMaster,
    unreserveAll,
  };
}
