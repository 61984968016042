<template>
    <div class="lastrefreshed is-inline-block is-float-right">Dashboards last updated:
        <div id="refreshstamp" class="is-inline-block"></div>
    </div>
</template>

<script>
import {
  defineComponent, toRefs, onMounted, reactive,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PowerBIToken from '@/models/PowerBI';
import * as pbi from 'powerbi-client';
import _ from 'lodash';

export default defineComponent({
  name: 'LastDashboardRefresh',
  setup() {
    const router = useRouter();
    function setupReport(report, drill) {
      // Clear any other loaded handler events
      report.off('loaded');

      // Triggers when a report schema is successfully loaded
      report.on('loaded', () => {
        // report.updateFilters(pbi.models.FiltersOperations.Add, [myFilter]);
      });

      // Clear any other rendered handler events
      report.off('rendered');

      // Triggers when a report is successfully embedded in UI
      report.on('rendered', () => {
        console.log('Report render successful');
      });

      // Clear any other error handler events
      report.off('error');

      // Handle embed errors
      report.on('error', (event) => {
        const errorMsg = event.detail;
        console.error(errorMsg);
      });

      report.off('dataHyperlinkClicked');

      report.on('dataHyperlinkClicked', (event) => {
        const url = _.get(event, 'detail.url').replaceAll('&amp;', '&');

        //   drill(routePath, params);
        // }
      });

      report.off('buttonClicked');

      report.on('buttonClicked', (event) => {
        const buttonTitle = _.get(event, 'detail.title');
        console.log(`button clicked: ${buttonTitle}`);
        const routePath = '/prefab/schedule';
        const params = { fromDashBoard: true };
        const approvalStatusMap = {
          'Pending Approval': 'pending',
          'For Record Only': 'forRecordOnly',
          Approved: 'approved',
          'Approved As Noted': 'approvedAsNoted',
          'Revise And Resubmit': 'reviseAndResubmit',
          Rejected: 'rejected',
          Total: 'all',
        };

        params.approvalStatus = approvalStatusMap[buttonTitle];

        drill(routePath, params);
      });

      report.off('dataSelected');

      report.on('dataSelected', (event) => {
        console.log(`data selected: ${JSON.stringify(event.detail)}`);
      });
    }

    function dr(path, query) {
      router.push({ path, query });
    }

    const store = useStore();
    const data = reactive({
      isActive: false,
      allCategories: [],
    });
    const clickActivity = () => {
      data.isActive = true;
    };

    onMounted(async () => {
      await PowerBIToken.getEmbedToken().then((response) => {
        const embedData = response;

        // set up the request object
        const metrics = {
          type: 'report',
          tokenType: pbi.models.TokenType.Embed,
          accessToken: embedData.accessToken,

          // Use other embed report config based on the requirement.
          // We have used the first one for demo purpose
          embedUrl: embedData.embedUrl[0].embedUrl,
          // ALWAYS specify page name to future-proof
          pageName: '',
          reportSection: '',

          // Enable this setting to remove gray shoulders from embedded report
          // settings: {
          //     background: models.BackgroundType.Transparent
          // }
          settings: {
            layoutType: pbi.models.LayoutType.Custom,
            customLayout: {
              displayOption: pbi.models.DisplayOption.FitToPage,
            },
            panes: {
              pageNavigation: {
                visible: false,
              },
              filters:
              {
                visible: false,
              },
            },
            hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent,
          },
          filters: [],
        };

        // Use the token expiry to regenerate Embed token for seamless end user experience
        // Refer https://aka.ms/RefreshEmbedToken
        // const tokenExpiry = embedData.expiry;

        // Embed Power BI report when Access token and Embed URL are available

        const powerbi = new pbi.service.Service(pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        console.log(`categories: ${JSON.stringify(data.allCategories)}`);

        metrics.pageName = 'ReportSection29b29c23c4a788fb5c49';
        metrics.filters = [];
        const reportholder = document.getElementById('refreshstamp');
        const report = powerbi.embed(reportholder, metrics);
        setupReport(report, dr);
      });
    });
    return {
      ...toRefs(data),
      clickActivity,
    };
  },
});
</script>
