<template>
  <span v-if="isHeader" class="is-clickable" @click="triggerCarousel"
    :class="rowField.id === 'next' ? isNextDisabled() : isPrevDisabled()">
    <i :class="getIcon"></i>
  </span>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import { cloneDeep, findIndex, findLastIndex } from 'lodash';
import { useStore } from 'vuex';

export default {
  name: 'LmvCarousel',
  props: {
    isHeader: {
      type: Boolean,
      default: () => false,
    },
    rowField: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      currentCols: {},
    });

    const store = useStore();

    const isPrev = computed(() => props.rowField.type === 'previous');

    const isNext = computed(() => props.rowField.type === 'next');

    const getIcon = computed(() => props.rowField?.headerIcon);

    const getColsDetail = () => {
      const cols = cloneDeep(store.getters.activeScreenCols).filter((c) => !!c.id);
      const startingVisibleColumnIdx = findIndex(cols, (col) => (col.visible === true
        && col.id !== 'previous'));
      const lastVisibleColumnIdx = findLastIndex(cols, (col) => (col.visible === true
        && col.id !== 'next'));
      return { cols, startingVisibleColumnIdx, lastVisibleColumnIdx };
    };

    const triggerCarousel = () => {
      const { cols, startingVisibleColumnIdx, lastVisibleColumnIdx } = getColsDetail();
      if (isNext.value && (lastVisibleColumnIdx < (cols.length - 2))) {
        cols[startingVisibleColumnIdx].visible = false;
        cols[lastVisibleColumnIdx + 1].visible = true;
        store.commit('setColumns', cols);
        emit('cell-clicked', { type: 'lmvCarousel' });
      }
      if (isPrev.value && startingVisibleColumnIdx > 1) {
        cols[lastVisibleColumnIdx].visible = false;
        cols[startingVisibleColumnIdx - 1].visible = true;
        store.commit('setColumns', cols);
        emit('cell-clicked', { type: 'lmvCarousel' });
      }
      data.currentCols = cols;
    };

    const isPrevDisabled = () => {
      const { startingVisibleColumnIdx } = getColsDetail();
      return startingVisibleColumnIdx === 1 ? 'disabledPer has-text-grey-light' : '';
    };

    const isNextDisabled = () => {
      const { lastVisibleColumnIdx, cols } = getColsDetail();
      return lastVisibleColumnIdx === (cols.length - 2) ? 'disabledPer has-text-grey-light' : '';
    };

    return {
      getIcon,
      triggerCarousel,
      ...toRefs(data),
      isPrevDisabled,
      isNextDisabled,
    };
  },
};
</script>
