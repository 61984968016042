import { useStore } from 'vuex';
import _ from 'lodash';
import Locations from '@/models/Locations';
import Projects from '../../models/Projects';

export default function () {
  const store = useStore();

  function getChildProjects(projects) {
    return _.get(projects, 'childProjects', []);
  }

  async function getAllLocations(projectId, apiMode = false) {
    const { allLocations } = store.state.queryParams;
    if (!allLocations || apiMode) {
      let allProjects = [];
      const allCompanies = [];
      const projects = store.state.queryParams._projects;
      const { commonStockProject } = store.state.queryParams;
      const childprojects = getChildProjects(projects);
      projects.push(...childprojects);
      projects.forEach((pro) => {
        const projComps = pro.projectCompanies || [];
        allCompanies.push(pro.createdCompany, ...projComps);
      });
      allProjects.push(commonStockProject, ...projects);
      allProjects = _.map(allProjects, '_id');
      let params = {
        companyIds: _.compact(_.map(allCompanies, '_id')),
      };
      params = await getNegativeList(params, { projectId: allProjects });
      params.companyIds = _.uniq(params.companyIds);
      if (_.isEmpty(projects)) params.showAllCompanyOrders = true;
      const locations = await Locations.allLocationForUser(params, projects.length > 100);
      const allLocationsParams = [];
      locations.forEach((loc) => {
        const locationParams = _.pick(loc, ['_id', 'name', 'type', 'company', 'project']);
        if (loc.nestedLocation) {
          _.set(locationParams, 'children', loc.children);
          _.set(locationParams, 'nestedLocation', loc.nestedLocation);
        }
        allLocationsParams.push(locationParams);
      });
      store.commit('setQueryParams', { allLocations: allLocationsParams });
      return _.filter(allLocationsParams, (location) => _.find(location.project,
        (proj) => proj._id === projectId)
        || location?.type === 'company_location');
    }

    return _.filter(allLocations, (location) => _.find(location.project,
      (proj) => proj._id === projectId)
    || location?.type === 'company_location');
  }
  async function getNegativeListProjectIds(filteredProjects) {
    let _projects = _.get(store.state.queryParams, '_projects');
    if (!_projects?.length) {
      _projects = await Projects.allProjects();
    }
    const allProjects = (filteredProjects?.length === _.get(store.state.queryParams, 'projects')?.length) ? _projects : _.get(store.state.queryParams, 'projects', '_projects');
    const projectIds = _.map(allProjects, '_id');
    const negativeListProjectIds = _.difference(projectIds, filteredProjects);
    return negativeListProjectIds;
  }

  async function getNegativeListOwnerIds(owners) {
    const allOwners = _.get(store.state.queryParams, 'allUsers', '_owners');
    const ownerIds = _.map(allOwners, '_id');
    const negativeListOwnerIds = _.difference(ownerIds, owners);
    return negativeListOwnerIds;
  }

  async function getNegativeLocationIds(locations) {
    let allLocations = _.get(store.state.queryParams, 'allLocations', '_locations');
    let allProjects = [];
    const allCompanies = [];
    const allLocationsParams = allLocations || [];
    if (!allLocations) {
      let params = {};
      params.companyIds = store.state.companyData._id;
      params.projectId = store.getters.selectedIdsForKey('filteredProjects');
      const locations = await Locations.allLocationForUser(params, params.projectId.length > 100);
      locations.forEach((loc) => {
        const locationParams = _.pick(loc, ['_id', 'name', 'type', 'company', 'project']);
        if (loc.nestedLocation) {
          _.set(locationParams, 'children', loc.children);
          _.set(locationParams, 'nestedLocation', loc.nestedLocation);
        }
        allLocationsParams.push(locationParams);
      });
      store.commit('setQueryParams', { allLocations: allLocationsParams });
    }
    const negativeLocationIds = _.difference(_.map(allLocationsParams, '_id'), locations);
    return negativeLocationIds;
  }

  async function getNegativeList(params, filter) {
    const ownerParams = {
      user: {
        _id: [],
      },
    };
    const storeMap = {
      projectId: 'filteredProjects',
      owner: 'owners',
      recipient: 'recipients',
      currentLocation: 'currentLocations',
      deliveryLocation: 'deliveryLocation',
    };
    const result = {};
    if (!_.isEmpty(filter)) {
      // eslint-disable-next-line guard-for-in
      for (const key in filter) {
        if (!_.isEmpty(filter[key])) {
          result[key] = filter[key];
        } else {
          result[key] = store.getters.selectedIdsForKey(storeMap[key]);
        }
        if (result[key]?.length < 100) {
          if (key === 'owner' && store.state.activeScreen !== 'shipping-order-view') {
            params[key] = _.set(ownerParams, 'user._id', result[key]);
          } else {
            params[key] = result[key];
          }
        } else if (key === 'projectId') {
          const negativeListProjectIds = await getNegativeListProjectIds(result[key]);
          if (negativeListProjectIds?.length <= result[key]?.length) {
            params.negativeListProjectIds = negativeListProjectIds;
          } else {
            params[key] = result[key];
          }
          if (!negativeListProjectIds?.length) params.showAllCompanyOrders = true;
          else params.showAllCompanyOrders = false;
        } else if (['owner', 'recipient'].includes(key)) {
          const negativeListOwnerIds = await getNegativeListOwnerIds(result[key]);
          if (negativeListOwnerIds?.length <= result[key]?.length) {
            params[`negativeList${key}Ids`] = negativeListOwnerIds;
          } else if (key === 'owner' && store.state.activeScreen !== 'shipping-order-view') {
            _.set(ownerParams, 'user._id', result[key]);
            params[key] = ownerParams;
          } else {
            params[key] = result[key];
          }
        } else if (['currentLocation', 'deliveryLocation'].includes(key)) {
          const negativeLocIds = await getNegativeLocationIds(result[key]);
          if (negativeLocIds?.length <= result[key]?.length) {
            params[`negativeList${key}Ids`] = negativeLocIds;
            delete params[key];
          } else {
            params[key] = result[key];
          }
        }
      }
    }
    return params;
  }
  function openPreference() {
    const routeData = {
      newTab: '_blank',
      sameTab: '_self',
    };
    return routeData[store.state.userData?.openPreference] || '';
  }
  function getHoursMinutesSeconds(clockHrs) {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (clockHrs < 60) {
      seconds = clockHrs;
      minutes = 0;
      hours = 0;
    } else {
      hours = parseInt((clockHrs / 3600), 10);
      minutes = parseInt(((clockHrs % 3600) / 60), 10);
      seconds = clockHrs - (minutes * 60) - (hours * 3600);
    }
    return { hours, minutes, seconds };
  };
  function getHoursMinutesSecondsString(clockSeconds = 0) {
    const { hours, minutes, seconds } = getHoursMinutesSeconds(clockSeconds);
    return `${hours === 0 ? '00' : hours}h:${minutes === 0 ? '00' : minutes}m:${seconds === 0 ? '00': seconds}s`;
  };

  function conflictHtml(msg) {
    const conflictMsg = `<h3 class="is-size-3 is-italic has-text-weight-bold mb-3 has-text-danger" >Duplicate Order ID:</h3>
      <h3 class="is-size-3  mb-3 has-text-danger" > ${msg.OrderId}</h3>
      <h5 class="is-size-5 has-text-black-ter" >(Order ID's must be unique and cannot be duplicated within a single project.) </h5>
      <div class="is-divider my-3"></div>
      <h3 class="is-size-3 mb-3 has-text-weight-bold is-italic has-text-black-bis" >Order Name:</h3>
      <h3 class="is-size-3 mb-3 has-text-black-bis" >${msg.OrderName} </h3>
        <h3 class="is-size-3 mb-3 has-text-weight-bold is-italic has-text-black-bis" >Project: </h3>
        <h3 class="is-size-3 has-text-black-bis" >${msg.Project} </h3>`;
    return conflictMsg;
  };
  return {
    getAllLocations,
    getChildProjects,
    getNegativeListProjectIds,
    getNegativeList,
    openPreference,
    getHoursMinutesSeconds,
    getHoursMinutesSecondsString,
    conflictHtml,
  };
}
