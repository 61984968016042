import { BaseOrder } from './BaseOrder';
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async getActivity(obj) {
    const data = await req.get('activity', {
      params: obj,
    });
    return data;
  },
  async getrecentEvents(httpOptions = {}) {
    httpOptions.params = { source: 'Landing', ...httpOptions.params || {} };
    const activityData = await req.get('activity', httpOptions);
    activityData.data.data.forEach((act) => {
      if (!act.shippingLabel) {
        const module = act.module.charAt(0).toLowerCase() + act.module.slice(1);
        if (act.module === 'MaterialManager' && act.sourcingOrder) {
          act.sourcingOrder.project = act.project;
          act.order = new BaseOrder(act.sourcingOrder);
        } else {
          if (act[module]) act[module].project = act.project;
          act.order = new BaseOrder(act[module]);
        }
      } else {
        act.order = act.shippingLabel;
      }
    });
    return {
      data: activityData.data.data,
      total: activityData.data.totalCount,
      page: activityData.data.page,
    };
  },
  async getWeeksActivity(obj) {
    const data = await req.get('weeksActivity', {
      params: obj,
    });
    return data;
  },
};
