<template>
  <o-modal
      :active="isActive"
      :canCancel="false"
      class="modal-sm"
      :class="[isHeadless ? 'hide-tab' : 'modal-fixed']"
  >
  <div class="modal-card">
    <header>
      <div class="modal-card-head">
        <h4 class="modal-card-title">
          {{templateForm.name}}
        </h4>
        <button class="button px-0" v-if="!hideFormPrint">
          <i class="icon-print" @click="printFormView"></i>
        </button>
      </div>
    </header>
    <section class="modal-card-body has-background-white" id="printJS-form">
      <form-builder
        :templateForm="templateForm"
        :editForm="editForm"
        :showForm="!isHeadless"
        ref="formBuilder"
        @loaded="formLoaded"
      ></form-builder>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined" true type="button" @click="cancel">
        Cancel
      </button>
      <span v-tooltip="getTooltipText">
        <button
          class="button has-background-black-bis"
          @click="saveForm"
          :disabled="isCreateOrderModal"
        >
          Save
        </button>
      </span>
    </footer>
  </div>
  <o-loading
  :full-page="false"
  :active="isLoading"
  :can-cancel="true"
  ></o-loading>
</o-modal>
</template>

<script>

import {
  defineComponent, reactive, ref, toRefs, onMounted, inject, computed,
} from 'vue';
import FormBuilder from '@/components/FormBuilder';
import { useStore } from 'vuex';
import { isArray, isFunction } from 'lodash';
import { useToast } from 'vue-toastification';
import FormPrintMixin from '@/components/mixins/FormPrintMixin';

export default defineComponent({
  name: 'FormPreview',
  props: {
    viewForm: Boolean,
    editForm: Boolean,
    templateForm: Object,
    formCheckList: {
      type: Boolean,
      default: true,
    },
    checklistView: {
      type: Boolean,
      default: true,
    },
    formOrder: Object,
    order: {
      type: Object,
      default: () => {},
    },
    isHeadless: {
      type: Boolean,
      default: false,
    },
    card: Object,
    hideFormPrint: {
      type: Boolean,
      default: false,
    },
    isCreateOrderModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    'form-builder': FormBuilder,
  },
  setup({
    viewForm,
    templateForm,
    formCheckList,
    checklistView,
    formOrder,
    order, isHeadless, card, isCreateOrderModal,
  }, { emit }) {
    const toast = useToast();
    const emitter = inject('emitter');
    const formBuilder = ref(null);
    const { printPdf } = FormPrintMixin();
    const store = useStore();
    const state = reactive({
      isActive: viewForm,
      formOrder: null,
      isLoading: false,
      hideModal: true,
    });
    const { activeScreen } = store.state;
    const checkFormComplete = (formData) => {
      const data = formData;
      let hasRequired = false;
      for (const entry of data) {
        if (typeof entry.required !== 'undefined' && entry.required) {
          hasRequired = true;
          if (!isArray(entry.userData)
            || (isArray(entry.userData)
              && (entry.userData.length === 0
                || (!(entry.type === 'file') && entry.userData[0].length === 0))
            )) {
            return false;
          }
        }
      }
      if (!hasRequired) {
        for (const entry of data) {
          if (!(['paragraph', 'header'].includes(entry.type))
            && (!isArray(entry.userData)
              || (isArray(entry.userData)
                && (entry.userData.length === 0
                  || (!(entry.type === 'file') && entry.userData[0].length === 0))
              ))) {
            return false;
          }
        }
      }
      return true;
    };

    const saveForm = () => {
      const formData = formBuilder.value.update();
      // eslint-disable-next-line vue/no-mutating-props
      templateForm.completed = checkFormComplete(formData);
      // eslint-disable-next-line vue/no-mutating-props
      templateForm.formData = formData;
      if (formCheckList) {
        if (!checklistView) {
          if (isFunction(order.updateChecklistStatus)) {
            order.updateChecklistStatus(templateForm);
          } else if (formOrder) {
            formOrder.updateChecklistStatus(templateForm);
            formOrder.save();
          }
        }
      }
      emit('close');
    };

    onMounted(() => {
      state.isLoading = true;
    });

    const cancel = () => {
      emit('close');
    };

    const printFormView = () => {
      // print form from modal
      printPdf({
        getForm: true,
        templateForm,
        order,
        fromList: false,
        card,
      });
    };

    const formLoaded = () => {
      if (isHeadless) {
        printFormView();
        state.isActive = false;
        emit('close');
      }
      state.isLoading = false;
    };

    const getTooltipText = computed(() => (isCreateOrderModal ? 'Edit forms after creating order'
      : ''));

    return {
      ...toRefs(state),
      cancel,
      formBuilder,
      saveForm,
      formLoaded,
      printFormView,
      getTooltipText,
    };
  },
});

</script>
<style scoped>
::v-deep(.print-display) {
  display: none !important;
}
.hide-tab {
  display: none !important;
}
.modal-fixed {
  position: fixed !important;
}
.form-builder-container {
  position: relative !important;
  line-height: initial !important;
}
::v-deep(input[type=number]) {
  text-align: left !important;
}
::v-deep(.form-builder-container .rendered-form .filebox){
  display: block;
  height: 38px;
}
::v-deep(.form-builder-container .rendered-form .filebox>*){
  float: left;
  margin-right: 8px;
}
::v-deep(.form-image) {
  margin-bottom: 24px;
}
::v-deep(.form-builder-container .rendered-form .filebox a) {
 margin-right: 0;
}
</style>
