<template>
  <o-modal :active="isActive" :canCancel="false" class="modal-xs dialog-modal">
      <div class="modal-card">
        <header class="modal-card-head is-borderless">
          <p class="card-header-title has-text-centered p-0 is-justify-content-center"
            :class="type ? `has-text-${type}` : ' has-text-black-bis'">
              {{ title }}
            </p>
        </header>
        <section class="modal-card-body is-visible has-text-black-bis">
            <div v-if="conflictNote" v-html="conflictNote">
            </div>
            <p v-if="beforeMessage" class="line-height mb-2" v-html="beforeMessage"
            :class="type ? `has-text-${type} has-text-weight-bold` : ' has-text-black-bis'"></p>
            <p v-if="message" class="line-height" v-html="message"></p>
            <div v-if="isCheckboxAvailable" class="is-flex mb-1">
              <o-checkbox v-model="cboxValue" class="is-paddingless mr-0"></o-checkbox>
              <!-- <input type="checkbox" class="mr-2 checkbox" > -->
              <span v-html="cbMessage" class="line-height has-text-weight"></span>
            </div>
            <p v-if="note" class="line-height mb-2" v-html="note"
              :class="noteType ? `has-text-${noteType} has-text-weight-bold` : ' has-text-black-bis'"></p>
        </section>
        <footer class="modal-card-foot is-justify-content-space-between">
            <button class="button is-pulled-left" :class="cancelBtnIsOutlined ? `is-outlined`: 'has-background-black-bis'" v-if="cancelButton" @click="cancel">
              {{ cancelButton }}
            </button>
            <div class="is-pulled-right">
              <button class="button" v-if="additionalButton" @click="confirm('additional')"
                :class="getAddBtnClasses">
                {{ additionalButton }}
              </button>
              <button class="button" @click="confirm('ok')"
                :class="buttonGetClass">
                {{ okButton }}
              </button>
            </div>
        </footer>
      </div>
  </o-modal>
</template>
<script>
import { computed } from '@vue/runtime-core';
import { isEmpty } from 'lodash';

function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove();
  } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
    el.parentNode.removeChild(el);
  }
}

export default {
  name: 'MDialog',
  props: {
    title: String,
    programmatic: Boolean,
    message: String,
    okButton: String,
    cancelButton: String,
    additionalButton: String,
    onConfirm: Function,
    additionalMethod: Function,
    isCheckboxAvailable: Boolean,
    cbMessage: String,
    type: String,
    note: String,
    beforeMessage: String,
    noteType: String,
    conflictNote: Object,
    cancelBtnIsOutlined: {
      type: Boolean,
      default: false,
    },
    confirmButtonStyle: {
      type: String,
      default: 'outlined'
    },
    additionalBtnStyles: {
      type: Object,
      required: false,
    }
  },
  configField: 'dialog',
  data() {
    return {
      isActive: this.programmatic,
      cboxValue: false,
    };
  },
  methods: {
    close() {
      this.isActive = false;
      // Timeout for the animation complete before destroying
      setTimeout(() => {
        removeElement(this.$el);
      }, 150);
    },
    cancel() {
      this.$emit('close');
      this.close();
    },
    confirm(type) {
      if (type === 'ok' && this.onConfirm) {
        this.onConfirm(this.cboxValue);
      } else if (type === 'additional' && this.additionalMethod) {
        this.additionalMethod(this.cboxValue);
      }
      this.close();
    },
  },
  mounted() {
    // Insert the Dialog component in body tag
    // only if it's programmatic
    if (this.programmatic) {
      document.body.appendChild(this.$el);
      this.isActive = true;
    }
  },
  computed: {
    buttonGetClass () {
      if(this.confirmButtonStyle === 'isSolid'){
        return this.type ? `is-${this.type}` : 'has-background-black-bis';
      }
      return this.type ? `is-outlined is-${this.type}` : 'is-outlined';
    },
    getAddBtnClasses () {
      if (!isEmpty(this.additionalBtnStyles)) {
        const cls = [];
        if (this.additionalBtnStyles.btnType) cls.push(`is-${this.additionalBtnStyles.btnType}`);
        if (this.additionalBtnStyles.isOutlined) cls.push('is-outlined');
        return cls.join(' ');
      }
      if (this.type) return `is-outlined is-${this.type}`;
      return 'has-background-black-bis';
    }
  },
};
</script>

<style scoped>
::v-deep(.modal-card) {
  width: 360px;
}
.modal-card-body {
  min-height: 48px;
}
.modal-card-head {
  min-height: 32px;
  max-height: 48px;
  height: auto !important;
}
</style>
