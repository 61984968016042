import { isEmpty } from "lodash";

export function getMaterialColsForCut(screen) {
	const measFields = [{
			title: 'Measure',
			name: 'field-generic-input',
			id: 'measure',
			prop: 'measure',
			width: '152px',
			sortField: 'measure',
		}, {
			title: 'Measure Units',
			name: 'field-measure-unit-select',
			id: 'measureUnits',
			prop: 'measureUnits',
			width: '152px',
			sortField: 'measureUnits',
		}
	];
	const fields = [
		{
			name: 'field-generic-input',
			width: '400px',
			title: ['cut-edit-manufacturing', 'cut-edit-detailing'].includes(screen) ? 'Material Name' : 'Name',
			prop: 'name',
			sticky: true,
			id: 'name',
			searchable: true,
			sortField: 'name',
		}
	];
	if (['cut-edit-manufacturing', 'cut-edit-detailing'].includes(screen)) {
		fields.push({
				name: 'field-cat-id',
				width: '152px',
				title: 'Catalog ID',
				id: 'catId',
				prop: 'catId',
				allowAutoComplete: true,
				verifyCatId: true,
				sortField: 'catId',
			},
			{
				name: 'field-generic-input',
				width: '112px',
				title: 'Material Id',
				prop: 'customId',
				id: 'customId',
				sortField: 'customId',
			}, {
				name: 'field-generic-input',
				id: 'qtyCalculated',
				prop: 'qtyCalculated',
				title: 'Calculated Qty Needed',
				width: '140px',
				sortField: 'qtyCalculated',
			}, {
				name: ['cut-edit-detailing'].includes(screen) ? 'field-generic-input' : 'qty-consumed',
				width: '140px',
				title: 'Actual Qty Needed',
				id: 'qtyToConsume' ,
				prop: 'qtyToConsume',
				sortField: 'qtyToConsume',
			}, {
				name: '',
				sortField: 'available',
				width: '112px',
				title: 'Available Inventory',
				classRight: 'is-pulled-right',
				id: 'available',
				inputProps: {
					disableEdit: true,
				},
				formatter(item) {
					if (!isEmpty(item.available) || item.available) return item.available;
					return 0;
				},
			});

			if (screen === 'cut-edit-manufacturing') {
				fields.push({
					name: '',
					width: '112px',
					title: 'On Order Inventory',
					classRight: 'is-pulled-right',
					id: 'inDelivery',
					inputProps: {
						disableEdit: true,
					},
					formatter(item) {
						if (item.delivery) return item.delivery;
						return 0;
					},
				}, {
					name: '',
					sortField: 'reserved',
					width: '112px',
					title: 'Reserved Inventory',
					classRight: 'is-pulled-right',
					id: 'reserved',
					inputProps: {
						disableEdit: true,
					},
					formatter(item) {
						if (!isEmpty(item.qtyReserved) || item.qtyReserved) return item.qtyReserved;
						return 0;
					},
				}, {
					name: 'field-generic-input',
					id: 'qtyToReturn',
					title: 'Return To Invenory',
					width: '140px',
					sortField: 'qtyToReturn',
					prop: 'qtyToReturn',
				});
			}

			fields.push(...measFields);
	} else {
		fields.push({
			name: 'field-generic-input',
			width: '112px',
			title: 'Id',
			prop: 'customId',
			id: 'customId',
			sortField: 'customId',
		}, {
			name: 'field-cat-id',
			width: '152px',
			title: 'Catalog ID',
			id: 'catId',
			prop: 'catId',
			allowAutoComplete: true,
			verifyCatId: true,
			sortField: 'catId',
		}, ...measFields, {
			name: 'qty-consumed',
			width: '112px',
			title: 'Qty Consumed',
			id: 'qtyConsumed',
			prop: 'qtyConsumed',
			sortField: 'qtyToConsume',
		}, {
			name: 'qty-ship',
			id: 'qtyToShip',
			title: 'Qty To Ship',
			width: '112px',
			sortField: 'qtyToShip',
		}, {
			name: 'field-generic-input',
			prop: 'quantity',
			sortField: 'quantity',
			width: '112px',
			title: 'Qty Needed',
			id: 'quantity',
		}, {
			name: '',
			sortField: 'available',
			width: '112px',
			title: 'Qty In Inventory',
			classRight: 'is-pulled-right',
			id: 'available',
			inputProps: {
				disableEdit: true,
			},
			formatter(item) {
				if (!isEmpty(item.available) || item.available) return item.available;
				return 0;
			},
		}
	);
		if (screen === 'manager-edit-manufacturing') {
			fields.push({
				name: '',
				sortField: 'reserved',
				width: '112px',
				title: 'Qty Reserved',
				classRight: 'is-pulled-right',
				id: 'reserved',
				inputProps: {
					disableEdit: true,
				},
				formatter(item) {
					if (!isEmpty(item.reserved) || item.reserved) return item.reserved;
					return 0;
				},
			});
		}

		fields.push({
			title: '',
			name: 'field-card-edit-action',
			alwaysVisible: true,
			id: 'action',
			width: '112px',
			noButtons: true,
			isDisabled(rowData) {
				if (rowData.stage !== 'preparation' || rowData.combinedQuantity) {
					return true;
				}
				return false;
			},
		})
	}

	return fields;
}