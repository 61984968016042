<template>
  <div>
    <o-loading :active="isLoading"></o-loading>
    <o-tabs
      v-model="activeTab"
      size="small"
      type="boxed"
      class="tab-header"
      :animated="false"
      @update:modelValue="changeRoute"
    >
      <o-tab-item
        v-for="(tab, idx) in getTabs"
        :key="idx"
        :label="tab.title"
        :value="idx"
      >
        <div class="" v-if="activeTab === idx">
        </div>
      </o-tab-item>
     <last-dashboard-refresh  v-if="isBiDashBoard">
     </last-dashboard-refresh>
      <router-view :key="$route.fullPath"></router-view>
    </o-tabs>
  </div>
</template>
<script>
import {
  computed, defineComponent, reactive, toRefs,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { findIndex } from 'lodash';
import { useStore } from 'vuex';
import LastDashboardRefresh from '@/components/LastDashboardRefresh.vue';

export default defineComponent({
  components: {
    LastDashboardRefresh,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const tabs = computed(() => router.currentRoute.value.matched[0].children);
    const state = reactive({
      activeTab: null,
      isLoading: false,
    });
    const isHeaderVisible = (tab) => {
      const pKey = tab.meta?.permissionKey;
      const key = pKey?.split(':')[0];
      if (['company_profile', 'companies', 'members', 'production-status', 'material-status',
        'manufacton_settings', 'shipping', 'projects', 'overview'].includes(key)) {
        return store.getters.hasCompanyPermissionForModule(pKey);
      }
      return store.getters.hasPermissionForModule(pKey);
    };

    const getTabs = computed(() => {
      const mfTabs = [];
      tabs.value.forEach((t) => {
        const res = isHeaderVisible(t);
        if (res) mfTabs.push(t);
      });
      return mfTabs;
    });
    state.activeTab = findIndex(getTabs.value, (i) => i.name === router.currentRoute.value.name);
    const changeRoute = async () => {
      state.isLoading = true;
      if (tabs.value[state.activeTab] && ['material-status', 'production-status'].includes(tabs.value[state.activeTab].name)) {
        await router.push({ path: `/scm/${tabs.value[state.activeTab].name}/order-view` });
      } else if (tabs.value[state.activeTab] && ['shipping'].includes(tabs.value[state.activeTab].name)) {
        await router.push({ path: `/logistics/${tabs.value[state.activeTab].name}/order-view` });
      } else if (tabs.value[state.activeTab] && ['recentEvents'].includes(tabs.value[state.activeTab].name)) {
        await router.push({ path: `/resources/${tabs.value[state.activeTab].name}/stage-view` });
      } else {
        await router.push({ path: tabs.value[state.activeTab].path });
      }
      state.isLoading = false;
    };

    const isBiDashBoard = computed(() => route.meta && route.meta.isBiDashBoard);
    return {
      ...toRefs(state),
      tabs,
      changeRoute,
      getTabs,
      isBiDashBoard,
    };
  },
});
</script>
<style scoped>

</style>
