export default {
  ID: {
    field: 'customId',
    multiple: false,
  },
  NAME: {
    field: 'name',
    multiple: false,
    required: true,
  },
  LEVEL: {
    field: 'level',
    multiple: false,
  },
  ZONE: {
    field: 'zone',
    multiple: false,
  },
  QUANTITY: {
    field: 'quantity',
    multiple: false,
  },
  MEASURE: {
    field: 'measure',
    multiple: false,
  },
  'MEASURE UNITS': {
    field: 'measureUnits',
    multiple: false,
  },
  COORDINATION: {
    field: 'coordDate',
    multiple: false,
  },
  ONSITE: {
    field: 'deliverDate',
    multiple: false,
  },
  'DOCUMENT NAMES': {
    field: 'docNames',
    multiple: false,
  },
  DOCUMENTS: {
    field: 'documents',
    multiple: false,
  },
  NOTES: {
    field: 'note',
    multiple: false,
  },
};
