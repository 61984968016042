<template>
  <div>
    <div class="column pl-0">
      <h3
        class="is-size-3 is-italic has-text-black-bis is-capitalized has-text-weight-semibold mb-4"
      >
      Add Files and Images (Limit of 10 files per upload. 20MB file size limit per file.)
      </h3>
              <!-- <button
          class="button pl-0 pr-0"
          v-tooltip="'Add New'"
        >
        <o-upload ref="pickerRef" v-model="files" @update:modelValue="addFiles()"
        :multiple="multiple" :accept="accept">               
          <i  class="icon-addnew has-text-black-bis"></i>
        </o-upload>
      </button>  -->
      <div class="columns">
        <div class="column py-0 pr-0">
          <o-field class="has-text-centered drag-drop">
            <o-upload ref="pickerRef" v-model="files" @update:modelValue="addFiles()"
              :multiple="multiple" :accept="accept" :dragDrop="isDragAndDrop" :disabled="isUploadDisabled"> 
              <div v-if="isDragAndDrop" class="drag-button" >
                <p v-if="filesToProcess.length === 10"  class="is-italic is-size-3 has-text-weight-normal">Maximum of 10 files per upload has been reached.</p> 
                <div  v-if="filesToProcess.length !== 10">
                  <span class="is-italic is-size-3 has-text-grey-darker mb-3 pulled-left line-height mr-2 has-text-weight-normal">Drag &amp; Drop Files Here Or</span>
                <div class="button has-background-black-bis">
                  <!-- <o-upload ref="pickerRef" v-model="files" @update:modelValue="addFiles()"
                  :multiple="multiple" :accept="accept">Browse Files</o-upload> -->Browse Files
                </div>
                </div>
              </div>
              <div v-else>
                <a class="button is-primary is-medium" :class="{'is-loading': isLoading}"
                  v-tooltip="tooltipText">
                  <o-icon icon="upload" size="is-large"></o-icon>
                  <span><slot>Add Files</slot></span>
                </a>
              </div>
            </o-upload>
           </o-field>
        </div>
      </div>
    </div>
    <div class="column p-0 drag-documents mt-5" v-if="isDragAndDrop">
        
      <div class="is-align-items-center is-flex">
        <o-checkbox  v-model="markAsPrivate" v-if="!isQaQc" @update:modelValue="updateFileCheckboxes" class=""
        ></o-checkbox>
        <h3 class="is-size-3 has-text-black-bis has-text-weight-semibold  is-italic" >   {{ !isQaQc ? 'Mark As Private Document' : 'Document Name' }}</h3>
      </div>
       <div class="url-link mb-3">
        
        <div v-for="(file, index) in filesToProcess" :key="index" class="columns mx-1 my-2">
          <div class="column pb-0">
            <div class="columns  is-align-items-center is-flex">
              <div class="column">
                <div class="columns is-align-items-center is-flex">
                  <div class="column is-narrow p-0 ">
                     <o-checkbox v-model="file.isPrivate" v-if="!isQaQc" class="line-height mr-0"  @update:modelValue="updateMarkAsPrivate(file, index)">
                  </o-checkbox>
                  </div>
                  <div class="column has-text-black-bis line-height p-0 has-text-weight-normal mr-2  is-size-3">
                    <div v-if="file.edit" class="is-flex is-justify-content-space-between">
                      <input  type="text"
                        v-model="file.newName"
                        class="input mr-2" 
                        @input="onInputChange"
                      >
                      <label>{{ getExtention(file) }}</label>
                    </div>
                    <p v-else class="text-overflow text-clamp"> {{file.fileName}}</p>
                </div>
                </div>
              </div>
              <div class="column is-narrow">
                <button class="button px-0" v-if="!file.edit" @click="editFile(index)" >
                  <i class="icon-edit" ></i>
                </button>
                <div v-if="file.edit">
                <button @click="discardChanges(index)" class="button px-0 is-outlined mr-2">
                  <i class="icon-close"  ></i>
                </button>
                <button @click="saveFile(index)" class="button px-0  is-success"  :disabled="!isInputChanged" >
                  <i class="icon-checkmark"></i>
                </button>
              </div>
                <button class="button px-0 ml-2" v-if="!file.edit">
                <i class="icon-removedelete is-clickable has-text-black-bis"  @click="deleteDropFile(index)"></i>
                </button>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, computed
} from 'vue';
import { useToast } from 'vue-toastification';
import { isEmpty } from 'lodash';
import GenerateRequest from '@/models/resources';
import { DialogProgrammatic } from '@/components/Dialog';

const req = GenerateRequest();

export default defineComponent({
  name: 'MfFilePicker',
  props: {
    param: Object,
    multiple: {
      type: Boolean,
      default: true,
    },
    errorMsg: {
      type: String,
      default: 'Error on File Upload',
    },
    errorFileTypeMsg: {
      type: String,
      default: 'Only following file formats are accepted',
    },
    filePath: {
      type: String,
      default: 'files',
    },
    path: {
      type: String,
      default: 'commons/uploadFile',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    isDragAndDrop: {
      type: Boolean,
      default: false,
    },
    allPrivate: {
      type: Boolean,
      default: false,
    },
    isQaQc: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      files: [],  // Array to store selected files
      maxFileSize: 20 * 1000 * 1000, // 20 MB
      isLoading: false,
      pickerRef: null,
      filesToProcess: [], // Array to store files ready for processing/uploading
      isAllSelected: false,
      markAsPrivate: props.allPrivate,
      editing:false,
      order: {},
      validatedFiles: [],
      newNames: [],
      orderFiles: [],
    });
    const {
      errorFileTypeMsg, param, multiple, errorMsg, filePath, path, isDragAndDrop,
    } = props;

     if (param && param.order) {
      data.order = param.order;
    } 
    const toast = useToast();
    const isInputChanged = ref(false);

    // methods
    const uploadFile = async (selectedFiles, validFiles = []) => {
      const uploadDone = [];
      const uploadFailed = [];
      if (!Array.isArray(selectedFiles)) {
        selectedFiles = [selectedFiles];
      }
      for (const selectedFile of selectedFiles) {
        if (validFiles.length > 0 && validFiles.indexOf(selectedFile.split('.').pop() > -1)) {
          // this.$dialog.alert({
          //   title: 'Error',
          //   message: this.errorFileTypeMsg + validFiles.join(','),
          //   type: 'is-danger',
          //   hasIcon: true,
          //   icon: 'times-circle',
          //   iconPack: 'fa',
          // });
          toast.error('error', errorFileTypeMsg + validFiles.join(','));
          return;
        }

        data.isLoading = true;
        const formData = new FormData();
        const header = { headers: { 'Content-Type': 'multipart/form-data' } };
        let queryStr = '';
        if (param) {
          Object.keys(param).forEach((key) => {
            if (!isEmpty(param[key])) {
              queryStr += `${key}=${param[key]}&`;
            }
          });
        }
        let fileToUpload = selectedFile;

        let fileNewName = selectedFile.name;
        const extension = fileNewName.includes('.') ? fileNewName.split('.').pop() : '';

        // check if the name has extention
        if (selectedFile && !selectedFile.edit) {
          fileNewName = !isEmpty(selectedFile.newName) ? (extension ? `${selectedFile.newName}.${extension}`: selectedFile.newName) : selectedFile.name;
        } else {
          fileNewName = selectedFile.name;
        }
  
        if (!isEmpty(fileNewName)) {
          const newName = fileNewName;
        
          fileToUpload = new File([selectedFile], newName, { type: selectedFile.type });
        }

        formData.append('file', fileToUpload);


        try {
          /* eslint no-await-in-loop: "off" */
          const response = await req.post(`${path}?${queryStr}`, formData, header);
          uploadDone.push({
            name: selectedFile.newName || selectedFile.name,
            isPrivate: selectedFile.isPrivate,
            data: response.data,
          });
        } catch (err) {
          if (multiple === false) {
            toast.error(errorMsg);
          }
          uploadFailed.push({ name: selectedFile.newName || selectedFile.name, error: err });
        } finally {
          data.isLoading = false;
        }
      }
      for (const fileObj of uploadDone) {
        emit('onInput', fileObj.data[filePath], fileObj.isPrivate);
      }
      if (uploadDone.length) {
        toast.success(`${uploadDone.length} files uploaded`);
      }
      if (uploadFailed.length) {
        toast.warning(`${uploadFailed.length} files could not be uploaded: please contact ManufactOn support`);
      }
      data.filesToProcess = [];
    };
    const processFiles = () => {
      // Check if it's a drag and drop scenario
      if (isDragAndDrop) {
          // Emit an event to notify the parent component about the dragged and dropped files
         updateMarkAsPrivate();
      } else {
         // Upload the selected files
        uploadFile(data.filesToProcess);
      }
      // Clear the files array after processing
      data.files = [];
    };

    const getExtention = (file) => {
      if (file.fileName && file.fileName.includes('.')) {
        return '.' + file.fileName.split('.').pop();
      }
      return '';

    }


    const checkIfDuplicateFileNameExists = () => {
     // check name exists in the list while editing the names of the files
      let duplicate = false;
    
      const existingOrderFileNames = fetchExistingOrderFileNames();
      const filesInListNew = filesAddedInList();
      const finalList = [...filesInListNew, ...existingOrderFileNames];
      filesInListNew.forEach((fileName) => {
        if (finalList.includes(fileName)) {
          const filteredName = finalList.filter((key) => key == fileName);
          if (filteredName.length > 1) {
            duplicate = true;
          }
        }
      });
      return duplicate;
    } 

    const addFilesToList = async () => {
      // assign fileName
      data.validatedFiles = data.validatedFiles.map(file => {
        file.fileName = file.name;
        file.isPrivate = true;
        return file;
      });
      const finalCount = data.filesToProcess.length + data.validatedFiles.length;
      if (finalCount > 10) {
        const confirmFileCountExceed = {
          title: 'File Count Limit reached',
          message: 'Please upload 10 or less files at a time',
          beforeMessage: "<span class='has-text-danger'>Maximum 10 files upload reached</span>",
          okButton: 'OK',
          confirmButtonStyle: 'isSolid',
          onConfirm: () => {            
            data.validatedFiles = [];
          },
        }
        await DialogProgrammatic.confirm(confirmFileCountExceed);
      } else {
        data.filesToProcess = [...data.filesToProcess, ...data.validatedFiles]
      }
      processFiles();
      return;
    }

    const validateFileInOrder = async () => {
      if (data.validatedFiles.length > 10) {
        const confirmFileCountExceedParam = {
            title: 'File Count Limit reached',
            message: 'Please upload 10 or less files at a time',
            beforeMessage: "<span class='has-text-danger'>Maximum 10 files upload reached</span>",
            okButton: 'OK',
            confirmButtonStyle: 'isSolid',
            onConfirm: () => {            
             data.validatedFiles = [];

              // add to list
              addFilesToList();
            },
        }
        await DialogProgrammatic.confirm(confirmFileCountExceedParam);
      } else {
        // check for duplicates
        addFilesToList();
      }
    }

    const filesAddedInList = () => {
      const filesInListNew = data.filesToProcess.map((file) => file.fileName);
      return filesInListNew;
    }
 

    const fetchExistingOrderFileNames  = () => {
      let filteredFileNames = [];
      let todofilteredFileNames = [];
      let filteredSimpleFileNames = [];

       // get all the order files
       if (!isEmpty(data.order.files)) {
        const filterFiles = data.order.files.filter((file)=> !file.archived?.value)
        filteredFileNames = _.uniq(filterFiles.map((file) => file.name))
      }
      // get all the order todos
      if (data.order.todos && !isEmpty(data.order.todos)) {

        const filteredTodos = [];
        data.order.todos.forEach((todo) =>{
          if (todo.files && !isEmpty(todo.files)) {
            todo.files.forEach((file) => {
              if (!file.archived.value) {
                filteredTodos.push(file);
              }
            })
          }
        })
     
        todofilteredFileNames = _.uniq(filteredTodos.map((file) => file.name))
      }

      // get all the simple files
      if (data.order.simpleFiles && !isEmpty(data.order.simpleFiles)) {
        const filteredSimpleFiles = data.order.simpleFiles.filter((file) => !file.archived.value)
        filteredSimpleFileNames = _.uniq(filteredSimpleFiles.map((file) => file.name));
      }

      // existing uniq file names
      const existingOrderFileNames = _.uniq([...filteredFileNames, ...filteredSimpleFileNames, ...todofilteredFileNames]);
      return existingOrderFileNames;
    }
 
    const validateFiles = async (filesToCheck) => {
      const maxSize = 20000000; // 20MB in bytes
      
      const fileExceedingSizeLimit = [];  
      let validFiles = [];

      let filesToCheckNames =  data.files.map((file) => file.name);
      const existingOrderFileNames = fetchExistingOrderFileNames()
      const filesInListNew = filesAddedInList();
      const finalList = [...existingOrderFileNames, ...filesToCheckNames, ...filesInListNew];
      let duplicate = false;

      filesToCheckNames.forEach((name) => {
        if(finalList.includes(name)) {
          const filteredName = finalList.filter((key) => key == name);
          if (filteredName.length > 1) {
            duplicate = true;
          }
        }
      });

      if (duplicate) {
        toast.warning('Some of files could not be added. Duplicate File Already Exists.');
        duplicate = false;
        data.files = []
        return;
      }
      if (!isEmpty(data.files)) {
        data.files.forEach((file) => {
          if (file.size > maxSize) {
            fileExceedingSizeLimit.push(file);
          } else {
            validFiles.push(file);
          }
        })
        data.files = [];
      }

      if (!isEmpty(fileExceedingSizeLimit)) {

        // if file size exceeds max limit (20mb)
        const confirmFileExceedingSizeLimitParam = {
            title: 'File Size Limit reached',
            message: `<span class="has-text-danger">${fileExceedingSizeLimit.length} of ${filesToCheck.length} file(s) exceed the 20MB size limit.</span>`,
            additionalButton: `Skip ${fileExceedingSizeLimit.length} OverSize Files Only`,
            okButton: 'Skip All Files',
            additionalMethod: async () => {
              data.validatedFiles = validFiles;
              await validateFileInOrder();
            },
            onConfirm: async () => {
              data.validatedFiles = [];
              await validateFileInOrder();
            },
          }
          await DialogProgrammatic.confirm(confirmFileExceedingSizeLimitParam);
      } else {
        data.validatedFiles = validFiles;
        await validateFileInOrder();
      }
    };

    const addFiles = async () => {
      const files = _.cloneDeep(data.files);
      await validateFiles(files);
      if (data.pickerRef?.$el) {
        const [fileInput] = data.pickerRef?.$el?.getElementsByTagName('input');
        if (fileInput) fileInput.value = '';
      }
    };

    
    const deleteDropFile = (index) => {
      data.filesToProcess.splice(index, 1);
      if (isEmpty(data.filesToProcess)) {
        data.markAsPrivate = false;
        data.files = []
      } else {
        updateMarkAsPrivate();
      }
    };
    const discardChanges = (fileIndex) => {
      data.filesToProcess[fileIndex].newName = ''
      data.filesToProcess[fileIndex].fileName = data.filesToProcess[fileIndex].name;
      data.filesToProcess[fileIndex].edit = false;
      data.filesToProcess = [...data.filesToProcess]
    };

    const resetFiles = () => {
      data.filesToProcess = []
      data.markAsPrivate = false
    }

    const updateFileCheckboxes = (checkboxState) => {
        data.markAsPrivate = checkboxState; // Update the "Mark as Private" checkbox state

        // Set the "isPrivate" value for all files
        data.filesToProcess.forEach(file => {
          file.isPrivate = checkboxState;
        });

        // Update the filesToProcess array
        data.filesToProcess = [...data.filesToProcess];

        // Emit the updated files array to the parent component
        emit('dragAndDropFiles', data.filesToProcess);
      };

    const updateMarkAsPrivate = (file, index) => {
      // Uncheck "Mark as Private" checkbox if any file checkbox is unchecked
      data.markAsPrivate = !data.filesToProcess.some(file => !file.isPrivate);

      if (!_.isEmpty(file)) {
        data.filesToProcess[index].isPrivate = file.isPrivate;
        data.filesToProcess = [...data.filesToProcess]
      }

      // update drag and drop files
      emit('dragAndDropFiles', data.filesToProcess);
    }
    const editFile = (fileIndex) => {
      const fileToEdit = data.filesToProcess[fileIndex];
      fileToEdit.edit = true;
      
      const fileNameParts = fileToEdit.fileName.split('.');
      const fileExtension = fileNameParts.length > 1 ? `.${fileNameParts.pop()}` : '';
      
      fileToEdit.newName = fileToEdit.newName || fileNameParts.join('');
      
      data.filesToProcess = [...data.filesToProcess];
      
      isInputChanged.value = false; // Reset the input change flag
    };



    const saveFile = (fileIndex) => {
      const newName = data.filesToProcess[fileIndex].newName;
      if (newName.length < 3) {
        toast.error('Document name must contain at least 3 characters!');
        return;
      }
   
      const fileName = data.filesToProcess[fileIndex].name;
      const fileExtension = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.')) : '';
      data.filesToProcess[fileIndex].fileName = newName + fileExtension;
      
      // check based on fileName if the file already exists in the order
      const files = [data.filesToProcess[fileIndex]];

      if (checkIfDuplicateFileNameExists(files)) {
        toast.error('Duplicate File Name Already Exists');
        data.files = [];
        return false;
      }
      data.filesToProcess[fileIndex].edit = false;
      data.filesToProcess = [...data.filesToProcess];
      isInputChanged.value = false; // Reset the input change flag after saving
    };
    const onInputChange = () => {
      isInputChanged.value = true; // Set the input change flag when the input value changes
    };
    
    const isUploadDisabled = computed(() => {
      return data.filesToProcess.length >= 10;
    });

    return {
      ...toRefs(data),
      uploadFile,
      addFiles,
      deleteDropFile,
      resetFiles,
      updateFileCheckboxes,
      updateMarkAsPrivate,
      editFile,
      saveFile,
      discardChanges,
      getExtention,
      onInputChange,
      isInputChanged,
      isUploadDisabled
    };
  },
});
</script>
<style scoped>
a {
  z-index: 2;
}
.ex-center {
  text-align: center;
}
</style>
