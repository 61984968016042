export default {
  PROJECT: {
    field: 'project',
  },
  'KIT ID': {
    field: 'customId',
    required: true,
  },
  'KIT NAME': {
    field: 'name',
    required: true,
  },
  COORDINATE: {
    field: 'coord',
    required: true,
  },
  DETAIL: {
    field: 'poDetailBy',
    required: true,
  },
  MANUFACTURE: {
    field: 'poManufactureBy',
    required: true,
  },
  QA: {
    field: 'poQaBy',
  },
  ONSITE: {
    field: 'deliver',
    required: true,
  },
  'ASSEMBLY MANUFACTURE': {
    field: 'partsManufactureBy',
    required: true,
  },
  OWNER: {
    field: 'prefabOwner',
  },
  'DOCUMENT NAMES': {
    field: 'docNames',
  },
  DOCUMENTS: {
    field: 'documents',
  },
  NOTES: {
    field: 'note',
  },
};
