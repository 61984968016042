<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        UPDATE QUANTITIES {{headerTitle}}
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="$emit('close')"></i>
    </header>
    <section class="modal-card-body is-size-4 has-text-black-bis line-height">
    <p  v-if="matCard"> Linked BOM quantities will be updated here and also in Production Order's Materials tab. </p>
      <p v-else > One or more items are connected to Revit.
        Changing their quantities will disconnect them from Revit.This cannot be undone </p>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined" @click="cancel()">Cancel</button>
      <button class="button has-background-black-bis"
              @click="updateQty"
              :disabled="errName">Update</button>
    </footer>
  </div>
</template>
<script>
import {
  defineComponent, computed,
} from 'vue';
import { useStore } from 'vuex';

// import Todo from '@/models/Todo';

export default defineComponent({
  name: 'UpdateBomQty',
  props: {
    card: {
      type: Object,
    },
    headerTitle: {
      type: String,
    },
    packageName: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const updateQty = () => {
      emit('save-card');
      if (store.state.activeScreen === 'material-edit-preparation') {
        emit('save-matCard');
      }
      emit('close');
    };
    const cancel = () => {
      emit('refresh-cancel');
      emit('close');
    };
    const matCard = computed(() => {
      if (['material-edit-preparation', 'material-edit-sourcing'].includes(store.state.activeScreen)) {
        return true;
      }
      return false;
    });
    return {
      cancel,
      matCard,
      updateQty,
    };
  },
});
</script>
