<template>
  <div class="column has-background-white qc-form" v-on="dirtyListeners">
    <div class="columns qc-form-header-title">
      <div class="column is-flex is-align-items-center">
        <i class="icon-manufactonform"></i>
        <span class="has-text-weight-bold line-height">Create QA/QC Form</span>
      </div>
    </div>
    <div class="columns qc-form-header-content">
      <div class="column is-3">
        <div class="field">
          <label class="is-italic has-text-weight-bold">
            QA/QC Form Name
          </label>
          <input type="text" class="input p-1" placeholder="(required)" v-model="formData.name">
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <label class="is-italic has-text-weight-bold">
            QA/QC Form ID
          </label>
          <input type="text" class="input p-1" v-model="formData.formId">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-flex is-justify-content-center qc-form-body is-offset-1 p-0">
        <div class="qc-form-content">
          <div>
            <div class="column">
              <div class="columns">
                <div class="column is-flex is-align-items-center">
                  <i class="icon-attacheddocumentsOL mr-2"></i>
                  <span class="has-text-weight-bold is-size-5">QA/QC Support Documentation:</span>
                </div>
                <div class="column is-narrow is-flex is-align-items-center">
                  <div class="is-flex is-align-items-center form-docs-count">
                    <i class="icon-attacheddocumentsOL"></i>
                    <span class="is-size-5">{{getAllFiles.length}}</span>
                  </div>
                  <div class="is-divider-vertical"></div>
                  <div class="buttons">
                    <button class="button p-0 has-text-black-bis"
                      @click="isDocModalActive = !isDocModalActive">
                      <i class="icon-edit"></i>
                    </button>
                    <button class="button p-0 has-text-black-bis"
                      @click="removeAllFiles()" :disabled="formData.isUsed">
                      <i class="icon-removedelete"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="qc-form-notes" v-if="formData.note.showComponent">
            <div class="column">
              <div class="columns qc-form-notes-header">
                <div class="column is-flex is-align-items-center">
                  <i class="icon-attachednotesOL mr-2"></i>
                  <span class="has-text-weight-bold is-size-5">QA/QC Notes/Overview:</span>
                </div>
                <div class="column is-narrow">
                  <div class="buttons">
                    <button class="button p-0" v-if="formData.note.isEdit"
                      :disabled="formData.isUsed"
                      @click="formData.note.isEdit = false">
                      <i class="icon-checkmark has-text-success"></i>
                    </button>
                    <button class="button p-0 has-text-black-bis"
                      @click="formData.note.isEdit = true"
                      v-else :disabled="formData.isUsed">
                      <i class="icon-edit"></i>
                    </button>
                    <button class="button p-0 has-text-black-bis" @click="removeNotes"
                      :disabled="formData.isUsed">
                      <i class="icon-removedelete"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="column p-0"
                :class="!formData.note.isEdit ? 'desc-height' : ''">
                <textarea class="textarea has-fixed-size" rows="3" v-model="formData.note.desc"
              placeholder='QA/QC Notes/Description(required to add "Overview/Notes" to QA/QC Form)'
                v-if="formData.note.isEdit"
                ></textarea>
                <span class="is-size-3 line-height"
                  v-else>{{formData.note.desc}}</span>
              </div>
            </div>
          </div>
          <div class="qc-form-checks">
            <div class="qc-form-checks-header is-flex is-align-items-center">
              <i class="icon-qcinspect mr-2"></i>
              <span class="has-text-weight-bold is-size-5">
                QA/QC CHECKS ({{ formData.qaQcChecks.length }})
              </span>
            </div>
            <div class="qc-form-checks-body" v-if="formData.qaQcChecks.length"
              :class="formData.signature.showComponent ? 'signature-added' : ''">
              <draggable
                :list="formData.qaQcChecks"
                item-key="uuid"
                @end="isDirty = true"
                :disabled="formData.isUsed">
                <template #item="{ element, index }">
                  <div class="column"
                    :class="element.isCriteria ? 'qc-form-check-criteria' : ''">
                    <div class="columns qc-form-check-header">
                      <div class="column px-3">
                        <span class="has-text-weight-bold is-size-5">
                          Check {{ (index+1).toString().padStart(3, '0') }}
                        </span>
                      </div>
                      <div class="column is-narrow is-flex">
                        <div class="handle">
                          <button class="button p-0 has-text-black-bis"
                            :disabled="formData.isUsed">
                            <i class="icon-reorder"></i>
                          </button>
                        </div>
                        <div class="is-divider-vertical"></div>
                        <div class="buttons">
                          <button class="button p-0" @click="element.isEdit = false; element.isNew = false"
                            :disabled="isDisabledCheck(element)"
                            v-if="element.isEdit">
                            <i class="icon-checkmark has-text-success"></i>
                          </button>
                          <button class="button p-0" @click="cancelEdit(element)"
                            v-if="element.isEdit && !element.isNew">
                            <i class="icon-close has-text-danger"></i>
                          </button>
                          <button class="button p-0 has-text-black-bis"
                            @click="setEdit(element)" :disabled="formData.isUsed"
                            v-if="!element.isEdit">
                            <i class="icon-edit"></i>
                          </button>
                          <button class="button p-0 has-text-black-bis"
                            v-if="!element.isEdit || element.isNew"
                            @click="removeCheck(element)" :disabled="formData.isUsed">
                            <i class="icon-removedelete"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="column p-0"
                      :class="!element.isEdit ? 'desc-height' : ''">
                      <textarea class="textarea has-fixed-size" rows="3" v-model="element.desc"
                        v-if="element.isEdit"
                      placeholder='QA/QC Check Description(required)'
                      ></textarea>
                      <span class="is-size-3 line-height"
                        v-else>{{element.desc}}</span>
                    </div>
                    <div class="column" v-if="element.componentType === 'qc'">
                      <div class="columns">
                        <div class="column is-6 pb-0">
                          <div class="field">
                            <label class="is-italic has-text-weight-bold is-size-5">
                              Criteria
                            </label>
                            <input type="text" class="input p-1" placeholder="(required)"
                              v-model="element.criteria" v-if="element.isEdit">
                              <div class="is-size-3 line-height"
                                v-else>{{element.criteria}}</div>
                          </div>
                        </div>
                        <div class="column is-3 pb-0">
                          <div class="field">
                            <label class="is-italic has-text-weight-bold is-size-5">
                              Target Value/Range
                            </label>
                            <input type="text" class="input p-1" placeholder="(required)"
                              v-model="element.targetVal" v-if="element.isEdit">
                            <div class="is-size-3 line-height" v-else>{{element.targetVal}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
            <div class="empty-checks is-flex is-justify-content-center is-align-items-center"
              v-if="!formData.qaQcChecks.length">
              <em>QA/QC Form Requires a minimum of (1) QA/QC Check</em>
            </div>
          </div>
          <div class="qc-form-signature" v-if="formData.signature.showComponent">
            <div class="column p-1 is-flex is-justify-content-space-between">
              <div class="is-flex is-align-items-center">
                <i class="icon-signature mr-2"></i>
                <span class="is-size-5">
                  <span class=" has-text-weight-bold">Signature </span>
                  <em class="has-text-grey-light">
                    (Enabled after ALL ACTIVE QA/QC Checks are set to "PASS.")
                  </em>
                </span>
              </div>
              <button class="button p-0 is-pulled-right has-text-black-bis"
                @click="removeSignature" :disabled="formData.isUsed">
                <i class="icon-removedelete"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3 is-flex-direction-column is-flex qc-form-elements is-offset-1">
        <em class="line-height is-size-5">Click to Add  Form Elements</em>
        <button class="is-outlined button qc-form-elements-button is-flex
          is-justify-content-space-between"
          v-for="element in formElements"
          :key="element.key"
          @click="onElementClick(element.componentEvent)"
          :disabled="isButtonElemDisabled(element.key)"
          >
          <span>
            <i :class="element.icon"></i>
            <h5 class="is-size-5 has-text-weight-bold">{{ element.label }}</h5>
          </span>
          <i class="icon-addnew"></i>
        </button>
        <div class="is-flex is-justify-content-space-between">
          <button class="button has-background-black-bis has-text-white has-text-weight-bold"
            @click="previewForm"
            :disabled="isFormUpdateDisabled">
            Preview Form
          </button>
          <button class="button is-outlined has-text-weight-bold"
            :disabled="isClearFormDisabled"
            @click="resetForm">Clear Form</button>
        </div>
      </div>
    </div>
    <div class="qc-form-footer column"
      :class="formData.isUsed ? 'is-flex is-justify-content-space-between' : ''">
      <div class="line-height" v-if="formData.isUsed">
        <span class="has-text-black-bis has-text-weight-bold is-size-3">
          This Form is in use, and cannot be edited.
        </span>
      </div>
      <div class="buttons" :class="!formData.isUsed ? 'is-pulled-right' : ''">
        <button class="is-outlined button has-text-weight-bold"
          @click="redirectToList">Cancel</button>
        <button class="is-success button has-text-weight-bold"
          :disabled="isFormUpdateDisabled || !isDirty"
          @click="updateForm">{{ formId === 'add' ? 'Create' : 'Update' }} Form</button>
      </div>
    </div>
  </div>
  <o-modal v-if="isDocModalActive" :active="isDocModalActive" :canCancel="false" class="modal-xs">
       <div class="modal-card">
          <header class="modal-card-head has-text-black-bis has-text-weight-bold">
            Documents ({{getAllFiles.length}})
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column py-0">
                <search-bar
                  :shouldEmit="true"
                  @search="getSearchValue($event)"
                  placeholder="Search"
                >
                </search-bar>
              </div>
              <div class="is-divider-vertical" v-if="!formData.isUsed"></div>
             <div class="column is-narrow py-0">
               <mf-add-file v-if="!formData.isUsed"
                  @docs-added="docsAdded"
                  :showForm="false"
                  :defaultPrivate="isDefPrivate"
                  :isQaQc="true"
                >
                </mf-add-file>
             </div>
            </div>
            <div class="is-divider m-0 mb-2"></div>
            <file-list
              :order="formData"
              :files="getAllFiles"
              :projectId="getProjectId"
              :closable="!formData.isUsed"
              @close="deleteFile"
              :key="refreshFiles"
              :isQaQc="true"
            ></file-list>
          </section>
          <footer class="modal-card-foot is-justify-content-flex-end">
            <div class="buttons">
              <button class="button is-outlined" @click="isDocModalActive = !isDocModalActive">
                Cancel
              </button>
              <button class="button has-background-black-bis"
                @click="isDocModalActive = false">Done</button>
            </div>
          </footer>
       </div>
    </o-modal>
    <o-loading :active="isLoading"></o-loading>
</template>

<script>
import draggable from 'vuedraggable';
import {
  computed, onBeforeMount, reactive, toRefs,
} from 'vue';
import uuid from 'uuid/v4';
import {
  findIndex, set, isEmpty, cloneDeep,
} from 'lodash';
import SearchBar from '@/components/SearchBar.vue';
import MfAddFile from '@/components/MfAddFile.vue';
import FileList from '@/components/card-edit/FileList.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import Form from '@/models/Form';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import QaQcmodal from '@/components/modals/QaQcModal.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';

export default {
  name: 'FormBuilderComponent',
  components: {
    draggable,
    SearchBar,
    MfAddFile,
    FileList,
  },
  props: {
    formId: {
      type: String,
    },
  },
  setup(props) {
    const state = reactive({
      forms: [],
      animation: 200,
      hoveredId: '',
      formElements: [
        {
          label: 'QA/QC Notes/Overview',
          icon: 'icon-attachednotesOL',
          componentEvent: 'addNotes',
          key: 'note',
        }, {
          label: 'QA/QC Check (Simple)',
          icon: 'icon-qcinspect',
          componentEvent: 'addSimpleCheck',
          key: 'simpleCheck',
        }, {
          label: 'QA/QC Check (w/Criteria)',
          icon: 'icon-qcinspect',
          componentEvent: 'addCriteriaCheck',
          key: 'criteriaCheck',
        },
        {
          label: 'Signature (required)',
          icon: 'icon-signature',
          componentEvent: 'addSignature',
          key: 'signature',
        },
      ],
      formData: {
        note: {
          desc: '',
          showComponent: false,
          isEdit: false,
        },
        qaQcChecks: [],
        signature: {
          showComponent: false,
        },
        docs: [],
        formType: 'template',
        name: '',
        formId: '',
      },
      isDocModalActive: false,
      refreshFiles: 0,
      isLoading: false,
    });
    const toast = useToast();
    const router = useRouter();
    const store = useStore();
    const { onInitDirty } = CardEditMixin();
    onInitDirty(state);

    const onElementClick = (opt) => {
      if (opt === 'addNotes') {
        state.formData.note.showComponent = true;
        state.formData.note.isEdit = true;
      } else if (opt === 'addSignature') state.formData.signature = { showComponent: true };
      else if (opt === 'addSimpleCheck') {
        state.formData.qaQcChecks.push({
          desc: '',
          uuid: uuid(),
          componentType: 'qa',
          tasks: [{ status: 'notStarted' }],
          isEdit: true,
          isNew: true,
        });
      } else if (opt === 'addCriteriaCheck') {
        state.formData.qaQcChecks.push({
          desc: '',
          isCriteria: true,
          criteria: '',
          targetVal: '',
          uuid: uuid(),
          componentType: 'qc',
          tasks: [{ status: 'notStarted' }],
          isEdit: true,
          isNew: true,
        });
      }
    };

    const removeNotes = () => {
      state.formData.note.showComponent = false;
      state.formData.note.description = '';
    };

    const removeSignature = () => {
      state.formData.signature.showComponent = false;
    };

    const isButtonElemDisabled = (key) => {
      if (state.formData.isUsed) return true;
      if (key === 'note') return state.formData.note.showComponent;
      if (key === 'signature') return state.formData.signature?.showComponent;
      return false;
    };

    const removeCheck = (checkToRemove) => {
      const checkIndex = findIndex(state.formData.qaQcChecks, (check) => {
        if (checkToRemove.uuid) return check.uuid === checkToRemove.uuid;
        if (checkToRemove._id) return check._id === checkToRemove._id;
      });
      if (checkIndex > -1) state.formData.qaQcChecks.splice(checkIndex, 1);
    };

    const docsAdded = (docs) => {
      for (const doc of docs) {
        doc.docType = doc.type;
        set(doc, 'archived.value', false);
        state.formData.docs.push(doc);
      }
    };

    const getAllFiles = computed(() => state.formData
      .docs.filter((file) => file.archived.value === false));

    const deleteFile = async (fileToDelete) => {
      const confirmParam = {
        title: 'DELETE [THIS DATA]',
        message: 'Are you sure you want to delete [this item/ data/ document]? This can not be recovered',
        okButton: 'Delete',
        cancelButton: 'Cancel',
        type: 'danger',
        onConfirm: () => {
          const removingFile = findIndex(state.formData.docs, (file) => {
            if (!fileToDelete.uuid) return file._id === fileToDelete._id;
            return file.uuid === fileToDelete.uuid;
          });
          state.formData.docs[removingFile].archived.value = true;
          state.isDirty = true;
          state.refreshFiles++;
        },
      };
      DialogProgrammatic.confirm(confirmParam);
    };

    const updateForm = async () => {
      try {
        state.isLoading = true;
        const { data: form } = await Form.updateFormTemplate(state.formData);
        Object.assign(state.formData, form);
        state.isDirty = false;
        onInitDirty(state);
        if (props.formId === 'add') {
          toast.success('Form Created');
        } else {
          toast.success('Form Updated');
        }
        router.push(`/qcFormTemplate/${store.state.userData.company}/${form._id}`);
      } catch (e) {
        console.log(e);
      } finally {
        state.isLoading = false;
      }
    };

    onBeforeMount(async () => {
      if (props.formId !== 'add') {
        try {
          state.isLoading = true;
          const { data: formObj } = await Form.get({ formId: props.formId, formType: 'template' });
          Object.assign(state.formData, formObj[0]);
        } catch (e) {
          console.log(e);
        } finally {
          state.isLoading = false;
        }
      }
    });

    const previewForm = () => {
      ModalProgrammatic.open({
        component: QaQcmodal,
        props: {
          form: state.formData,
          preview: true,
        },
        events: {
          close: () => {},
        },
        canCancel: false,
        rootClass: 'modal-sm qa-modal',
      });
    };

    const isFormUpdateDisabled = computed(() => {
      let checkValidationFailed = false;
      if (isEmpty(state.formData.name) && state.formData.name.length < 3) return true;
      if (!state.formData.qaQcChecks.length) return true;
      if (state.formData.note.showComponent && isEmpty(state.formData.note.desc)) return true;
      state.formData.qaQcChecks.forEach((check) => {
        if (isEmpty(check.desc)) checkValidationFailed = true;
        if (check.componentType === 'qc'
          && (isEmpty(check.criteria) || isEmpty(check.targetVal))) checkValidationFailed = true;
      });
      return checkValidationFailed;
    });

    const isDisabledCheck = (check) => {
      if (isEmpty(check.desc)) return true;
      if (check.componentType === 'qc'
          && (isEmpty(check.criteria) || isEmpty(check.targetVal))) return true;
      return false;
    };

    const isClearFormDisabled = computed(() => {
      if (state.formData.isUsed) return true;
      if ((!state.formData.note.showComponent
        && !state.formData.signature.showComponent)
        && !state.formData.qaQcChecks.length) return true;
      return false;
    });

    const resetForm = () => {
      Object.assign(state.formData, {
        note: {
          desc: '',
          showComponent: false,
          isEdit: false,
        },
        qaQcChecks: [],
        signature: {
          showComponent: false,
        },
      });
    };

    const removeAllFiles = () => {
      state.formData.docs.forEach((file) => {
        if (!file.archived.value) file.archived.value = true;
      });
    };

    const redirectToList = () => {
      // need to provide the toast for asking the user to refresh the tab to get latest data
      window.close();
    };

    const setEdit = (element) => {
      set(element, '_beforeEdit', cloneDeep(element));
      set(element, 'isEdit', true);
    };

    const cancelEdit = (element) => {
      const checkIndex = findIndex(state.formData.qaQcChecks, (check) => {
        if (element.uuid) return element.uuid === check.uuid;
        return element._id === check._id;
      });
      if (checkIndex > -1) {
        set(state.formData, `qaQcChecks[${checkIndex}]`, element._beforeEdit);
      }
      set(element, 'isEdit', false);
    };

    return {
      ...toRefs(state),
      onElementClick,
      removeNotes,
      isButtonElemDisabled,
      removeSignature,
      removeCheck,
      docsAdded,
      getAllFiles,
      deleteFile,
      updateForm,
      previewForm,
      isFormUpdateDisabled,
      isDisabledCheck,
      isClearFormDisabled,
      resetForm,
      removeAllFiles,
      redirectToList,
      setEdit,
      cancelEdit,
    };
  },
};
</script>

<style scoped>
  .empty-checks {
    height: calc(100% - 32px);
  }
  .desc-height {
    height: 82px;
    padding: 6px !important;
  }
</style>
