<template>
  <div>
    <mf-multi-select
      v-if="isEditing && !rowField?.isDisabled"
      :modelValue="inputValue"
      :options="selectOpts"
      :titleLabel="titleLabel"
      :labelPosition="labelPosition"
      :placeholder="placeholder"
      labelClass="has-text-black-bis is-italic is-size-3"
      :multiple="false"
      :searchable="true"
      label="name"
      track-by="_id"
      :allow-empty="false"
      @update:modelValue="updateVendor"
    >
    </mf-multi-select>
    <span class="text-overflow line-height"
      v-else
    >
      {{ inputValue.name }}
    </span>
  </div>
</template>

<script>
import {
  onMounted,
  reactive,
  toRefs,
  computed,
  watch,
} from 'vue';
import {
  isEmpty,
} from 'lodash';
import MfMultiSelect from '@/components/abstract/MfMultiSelect';
import Vendors from '@/models/Vendors';

export default {
  name: 'VendorSelect',
  props: {
    titleLabel: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: 'top',
    },
    value: Object,
    options: Array,
    rowData: Object,
    isEditing: Boolean,
    placeholder: {
      type: String,
      default: '',
    },
    rowField: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    MfMultiSelect,
  },
  setup(props, { emit }) {
    const {
      options,
    } = props;

    // data
    const data = reactive({
      selectOpts: [],
    });

    // computed
    const inputValue = computed({
      get: () => (isEmpty(props.value) ? '' : props.value),
      set: (val) => (emit('update:value', val)),
    });

    // methods
    const loadData = async () => {
      const result = await Vendors.getVendors({ limit: 1000 });
      return result.data;
    };

    const populateOpts = async () => {
      if (!props.isEditing) return;
      if (isEmpty(options)) {
        const opts = await loadData();
        data.selectOpts = opts;
      } else {
        data.selectOpts = options;
      }
    };

    onMounted(populateOpts);

    watch(() => props.isEditing, populateOpts);

    const updateVendor = (val) => {
      if (val.name) inputValue.value = val;
    }

    return {
      ...toRefs(data),
      inputValue,
      updateVendor,
    };
  },
};
</script>
