<template>
  <div class="circle-wrapper">
    <div class="circle-container" :style="{background: getProgressBg}">
      <div class="circle-inner has-text-weight-bold" :class="`has-text-${getColor()}`">
        {{getVal}}%
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';

export default {
  name: 'progress-circle',
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    rowField: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const progressColors = {
      success: '#499b64',
      grey: '#666',
      'grey-dark': '#999',
      'white-ter': '#ccc',
    };
    const getVal = computed(() => get(props.rowData, props.rowField.prop, 0));

    const getColor = () => {
      if (getVal.value === 0) return 'white-ter';
      if (getVal.value <= 25) return 'grey-dark';
      if (getVal.value <= 50) return 'grey';
      return 'success';
    };

    const getProgressBg = computed(() => {
      const progressColor = progressColors[getColor()];
      return `conic-gradient(${progressColor} ${getVal.value * 3.6}deg,
      ${progressColors['white-ter']} ${getVal.value * 3.6}deg)`;
    });

    return {
      getVal,
      getProgressBg,
      getColor,
    };
  },
};
</script>

<style>

</style>
