function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    isDetailed: false,
    rearrangeCols: false,
  };
}

const itemsInfo = [
  {
    name: 'field-generic-input',
    width: '400px',
    title: 'Item Name',
    prop: 'name',
    id: 'name',
  },
  {
    name: 'field-generic-input',
    width: '152px',
    title: 'Item ID',
    prop: 'customId',
    id: 'customId',
  },
  {
    name: 'field-cat-id',
    width: '152px',
    title: 'Catalog ID',
    prop: 'catId',
    sortField: 'catId',
    id: 'catId',
  },
  {
    name: 'field-generic-input',
    width: '112px',
    title: 'QTY',
    prop: 'quantity',
    id: 'quantity',
    isDisabled: (rowData) => !((rowData._isEditing && rowData.stage === 'sourcing') || rowData.isNew),
  },
  {
    name: 'field-generic-input',
    prop: 'desc',
    width: '296px',
    title: 'Description',
    id: 'desc',
  },
  {
    name: 'field-generic-input',
    prop: 'leadTime',
    width: '112px',
    title: 'Lead Time (Days)',
    id: 'leadTime',
    isDisabled: (rowData) => !((rowData._isEditing && rowData.stage === 'sourcing') || rowData.isNew),
  },
  {
    title: 'Notes',
    name: 'field-notes-icon',
    prop: 'simpleMemos[0].text',
    defaultVisible: true,
    width: '64px',
    id: 'notes',
    inputProps: {
      isItem: true,
    },
  },
  {
    title: '',
    name: 'table-field-actions',
    id: 'action',
    width: '112px',
    from: 'scheduleFlyout',
    inputProps: {
      showEdit: true,
    },
  },
];
export default {
  'item-info': {
    ...extraTableParams(),
    fields: itemsInfo,
  },
};
