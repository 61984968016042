<template>
<o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
<div class="list-view">
    <div class="backingpanel">
          <div class="column is-inline-block dash-panel">
            <div class="dash-panel-title">SUBMITTAL APPROVALS BY DATE</div>
            <div class="dash-holder-300" id="approval"></div>
          </div>
          <div class="column is-inline-block dash-panel">
            <div class="dash-panel-title">SUBMITTAL AND DELIVERY DATE</div>
            <div class="dash-holder-300" id="delivery"></div>
          </div>
          <div class="column is-inline-block dash-panel">
            <div class="dash-panel-title">SUBMITTAL APPROVALS BY VENDOR</div>
            <div class="dash-holder-med" id="vendor"></div>
          </div>
          <div class="column is-inline-block dash-panel">
            <div class="dash-panel-title">VENDOR DELIVERY PERFORMANCE</div>
            <div class="dash-holder-med" id="vendormat"></div>
          </div>
    </div>

</div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, onMounted, computed, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PowerBIToken from '@/models/PowerBI';
import * as pbi from 'powerbi-client';
import Vendors from '@/models/Vendors';
import _ from 'lodash';
import moment from 'moment';

import Version from '@/appVersion.json';

export default defineComponent({
  name: 'Home',
  setup() {
    function filterDash() {
      this.reports.forEach((report) => {
        report?.updateFilters();
      });
    }
    function setupReport(report, drill) {
      // Clear any other loaded handler events
      report.off('loaded');

      // Triggers when a report schema is successfully loaded
      report.on('loaded', () => {
        // report.updateFilters(pbi.models.FiltersOperations.Add, [myFilter]);
      });

      // Clear any other rendered handler events
      report.off('rendered');

      // Triggers when a report is successfully embedded in UI
      report.on('rendered', () => {
        console.log('Report render successful');
      });

      // Clear any other error handler events
      report.off('error');

      // Handle embed errors
      report.on('error', (event) => {
        const errorMsg = event.detail;
        console.error(errorMsg);
      });

      report.off('dataHyperlinkClicked');

      report.on('dataHyperlinkClicked', (event) => {
        const url = _.get(event, 'detail.url').replaceAll('&amp;', '&');
        const split = url.split('?');
        if (split.length == 2) {
          const urlParams = new URLSearchParams(`?${split[1]}`);
          // TODO parse out the route as well as params!
          let routePath = '/prefab/schedule';
          const params = {};

          if (split[0].includes('scm/material-status')) {
            routePath = '/scm/material-status/order-view';
          }

          if (urlParams.get('fromDashBoard')) {
            params.fromDashBoard = urlParams.get('fromDashBoard');
          }
          if (urlParams.get('approvalStatus')) {
            params.approvalStatus = urlParams.get('approvalStatus');
          }
          if (urlParams.get('approvalMin')) {
            params.approvalMin = urlParams.get('approvalMin');
          }
          if (urlParams.get('approvalMax')) {
            params.approvalMax = urlParams.get('approvalMax');
          }
          if (urlParams.get('submittalState')) {
            params.submittalState = urlParams.get('submittalState');
          }
          if (urlParams.get('vendor') && urlParams.get('vendor') !== 'all') {
            params.vendor = urlParams.get('vendor');
          }
          if (urlParams.get('isDelivered')) {
            params.isDelivered = urlParams.get('isDelivered');
          }
          if (urlParams.get('isLate')) {
            params.isLate = urlParams.get('isLate');
          }
          if (urlParams.get('stages')) {
            params.stages = urlParams.get('stages');
          }

          drill(routePath, params);
        }
      });

      report.off('buttonClicked');

      report.on('buttonClicked', (event) => {
        const buttonTitle = _.get(event, 'detail.title');
        const routePath = '/prefab/schedule';
        const params = { fromDashBoard: true };
        const approvalStatusMap = {
          'Pending Approval': 'pending',
          'For Record Only': 'forRecordOnly',
          Approved: 'approved',
          'Approved As Noted': 'approvedAsNoted',
          'Revise And Resubmit': 'reviseAndResubmit',
          Rejected: 'rejected',
          Total: 'all',
        };

        params.approvalStatus = approvalStatusMap[buttonTitle];

        drill(routePath, params);
      });
    }
    const router = useRouter();

    let showDash = true; // Dont commit this change, update this to true for UI instance and local
    if (_.get(Version, 'env', '') == 'dev') {
      showDash = true;
    }

    function drill(path, query) {
      router.push({ path, query });
    }
    const getDynamicClasses = computed(() => {
      const routeMeta = router.currentRoute.value.meta;
      let classes = '';
      if (routeMeta.activeViewColor) {
        classes = `${routeMeta.activeViewColor} `;
      }
      if (routeMeta.cardEditIcon) {
        classes += `icon-${routeMeta.cardEditIcon}`;
      }
      return classes;
    });
    const store = useStore();
    const data = reactive({
      isActive: false,
      allCategories: [],
    });
    const clickActivity = () => {
      data.isActive = true;
    };

    const showSecond = ref(false);
    const showThird = ref(false);
    const biRef = ref('<div></div>');
    const showMobile = false;

    const reports = [];

    const projectIds = computed(() => store.getters.selectedIdsForKey('filteredProjects'));

    const projectFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'projects',
        column: '_id',
      },
      operator: 'In',
      values: projectIds.value,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const projectFilterM = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'MaterialOrders',
        column: 'project._id',
      },
      operator: 'In',
      values: projectIds.value,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const projectFilterS = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'schedules',
        column: 'project._id',
      },
      operator: 'In',
      values: projectIds.value,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    watch(() => store.state.queryParams.projects, () => {
      projectFilter.values = projectIds.value;
      projectFilterS.values = projectIds.value;
      projectFilterM.values = projectIds.value;
      // for some reason reports.each did not work here...
      reports[0].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilterS]);
      reports[1].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilterS]);
      reports[2].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilterS]);
      reports[3].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilterM]);
    });

    onMounted(async () => {
      data.allCategories = (await Vendors.getCategories({ limit: 500 })).data;
      await PowerBIToken.getEmbedToken().then((response) => {
        const embedData = response;

        // set up the request object
        const metrics = {
          type: 'report',
          tokenType: pbi.models.TokenType.Embed,
          accessToken: embedData.accessToken,

          // Use other embed report config based on the requirement.
          // We have used the first one for demo purpose
          embedUrl: embedData.embedUrl[0].embedUrl,
          // ALWAYS specify page name to future-proof
          pageName: '',
          reportSection: '',

          // Enable this setting to remove gray shoulders from embedded report
          // settings: {
          //     background: models.BackgroundType.Transparent
          // }
          settings: {
            layoutType: (showMobile ? pbi.models.LayoutType.MobilePortrait
              : pbi.models.LayoutType.Master),
            panes: {
              pageNavigation: {
                visible: false,
              },
              filters:
              {
                visible: false,
              },
            },
            hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent,
          },
          filters: [],
        };

        // Use the token expiry to regenerate Embed token for seamless end user experience
        // Refer https://aka.ms/RefreshEmbedToken
        // const tokenExpiry = embedData.expiry;

        // Embed Power BI report when Access token and Embed URL are available

        const powerbi = new pbi.service.Service(pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        // console.log(`categories: ${JSON.stringify(data.allCategories)}`);

        // console.log(`projects: ${projectIds.value}`);

        metrics.pageName = 'ReportSectiond5df7126a73db3fc6413';
        metrics.filters = [projectFilterS];
        let reportholder = document.getElementById('vendor');
        let report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill);
        reports.push(report);

        metrics.pageName = 'ReportSection02079e6910550cea2e8a';
        metrics.filters = [projectFilterS];
        reportholder = document.getElementById('delivery');
        report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill);
        reports.push(report);

        metrics.pageName = 'ReportSectiond522f5e10e8b51a7c0dd';
        metrics.filters = [projectFilterS];
        reportholder = document.getElementById('approval');
        report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill);
        reports.push(report);

        metrics.pageName = 'ReportSection13173f08d01765f3878d';
        metrics.filters = [projectFilterM];
        reportholder = document.getElementById('vendormat');
        report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill);
        reports.push(report);
      });
    });

    return {
      ...toRefs(data),
      clickActivity,
      showSecond,
      showThird,
      biRef,
      showMobile,
      reports,
      getDynamicClasses,
      showDash,
    };
  },
});
</script>
<style scoped>

</style>
