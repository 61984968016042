export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  isCheckable: true,
  hasCheckBox: true,
  isDetailed: false,
  customRowKey: 'uid',
  fields: [{
    name: 'field-generic-input',
    prop: 'name',
    width: '500px',
    title: 'Item Name',
    id: 'name',
  },
  {
    name: 'field-generic-input',
    prop: 'source._id.name',
    sortField: 'source._id.name',
    width: '300px',
    title: 'Source Order',
    id: 'source._id.name',
    inputProps: {
      type: 'text',
      openCard: false,
      disableEdit: true,
    },
  },
  {
    name: 'qty-needed',
    width: '112px',
    title: 'Qty',
    id: 'selectedQty',
  }],
};
