<template>
  <div class="is-flex is-justify-content-center">
    <VDropdown placement="right" :autoHide="false">
      <div class="is-relative runs-count is-clickable">
        <span>{{ selected.length }}</span>
        <i class="icon-attachedworkstepsOL is-size-3"></i>
      </div>
      <template #popper>
        <div class="card" :disabled="disableInput(rowData)">
          <header class="card-header  has-background-grey-lighter">
            <h4
              class="card-header-title is-size-3 has-text-black-bis pl-0
                is-capitalized has-text-weight-bold"
            >
              Work Steps ({{ selected.length }})
            </h4>
          </header>
          <section class="card-content mt-2 is-marginless has-background-white">
            <div class="has-text-black-bis mb-3 has-text-weight-semibold">
              Item: {{ rowData.name }}
            </div>
            <div class="card-action is-justify-content-space-between" v-if="isEditing">
              <div>
                <button class="button is-danger has-text-danger has-text-weight-bold is-outlined"
                  @click="clearAll()">
                    <span>Clear All Steps</span>
                  </button>
                <button class="button has-background-black-bis has-text-white has-text-weight-bold"
                    @click="selectAll()"
                  >
                    <span>Add All Steps ({{runs.length}})</span>
                  </button>
              </div>
              <div>
                <button @click="cancelSelected()" class="button has-text-black-bis p-0">
                  <i class="icon-close"></i>
                </button>
                <button @click="saveAll()" class="has-text-success p-0 button">
                  <i class="icon-checkmark"></i>
                </button>
              </div>
            </div>
            <div v-else-if="!disableEditButton()"
              class="card-action is-flex is-justify-content-flex-end">
              <o-checkbox
                v-model="isCompleted"
                @update:modelValue="setAlRiComplete($event)"
                :disabled="rowData.markCompleteDisabled || isFormCompleted || rowData.isItemCompleted"
              >
                <span class="is-size-3 has-text-black-bis">Mark Complete</span>
              </o-checkbox>
              <button
                @click="onEdit()"
                :disabled="isDisabled"
                class="button has-text-black-bis p-0"
              >
                <i class="icon-edit"></i>
              </button>
            </div>
            <div class="tags-body" :key="refreshKey" :class="disableEditButton() ? 'kit-item' : ''">
              <div class="mb-2 is-flex" v-for="run in runs" v-bind:key="run._id"
                :class="isRunSelected(run) ? '' : 'is-hidden'">
                <o-checkbox
                  v-if="(!isEditing && isRunSelected(run))"
                  :modelValue="getRiValue($event, run)"
                  :disabled="rowData.markCompleteDisabled
                    || ['detailing', 'manufacturing', 'qa',
                      'manager-edit-qa'].includes($store.state.activeScreen) || isFormCompleted || rowData.isItemCompleted"
                  @update:modelValue="setRiComplete($event, run)">
                </o-checkbox>
                <button class="button taghover is-fullwidth
                  is-flex is-justify-content-space-between"
                  v-if="(!isEditing && isRunSelected(run)) || isEditing"
                  :class="isRunSelected(run) ? 'has-background-black-bis has-text-white'
                    : 'is-outlined'"
                  @click="addOrRemoveRun(run)"
                  :disabled="['detailing', 'manufacturing', 'qa',
                    'manager-edit-qa'].includes($store.state.activeScreen)"
                >
                  <span class="is-size-3">{{ run.name }}</span>
                  <span class="icon is-small">
                    <i v-if="isRunSelected(run)"
                      class="icon-removedelete"></i>
                    <i v-else class='icon-addnew'></i>
                  </span>
                </button>
              </div>
            </div>
          </section>
          <footer class="card-footer is-justify-content-flex-end has-background-grey-lighter">
            <div class="buttons">
              <button v-close-popper="true"
                class="button is-outlined has-text-weight-bold"
                @click="cancelSelected"
              >
                Cancel
              </button>
              <button v-close-popper="true"
                class="button has-background-black-bis has-text-white is-pulled-right"
                @click="() => {
                  isEditing = false;
                  delete rowData._beforeEdit;
                  CardDirtyBus.emit('setCustomDirty');
                }"
              >
                Done
              </button>
            </div>
          </footer>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import {
  defineComponent, onMounted, reactive, computed, nextTick, toRefs,
} from 'vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import { BaseOrder, RunItemsCount } from '@/models/BaseOrder';
import { useToast } from 'vue-toastification';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';

export default defineComponent({
  name: 'RunSelect',
  props: {
    rowData: Object,
  },
  setup(props) {
    const { rowData } = props;
    const store = useStore();
    const toast = useToast();
    const { disableInput } = ProductionManagerMixin();
    const state = reactive({
      initialRiCopy: {},
      isEditing: false,
      refreshKey: 0,
      allChecked: false,
    });
    const disableEditButton = () => ['detailing', 'manufacturing', 'qa'].includes(store.state.activeScreen);
    const runItemsIndexMap = computed(() => rowData.order.manager._runItemsIndexMap);
    const runs = computed(() => _.filter(rowData.order.manager.runs, (r) => !r.archived.value));

    const isFormCompleted = computed(() => {
      const runsWithForms = _.filter(runs.value, (r) => r.form?.id !== null && r.form);
      const isComplete = _.every(runsWithForms, (run) => !_.isEmpty(run.form) && run.formStatus === 'complete' && run.form._id);
      return !isComplete;
    });

    const selected = computed({
      get: () => runs.value.filter((run) => {
        const index = runItemsIndexMap.value[run.uuid][rowData.uuid];
        if (index >= 0) {
          const slimRun = _.pick(run.runItemsCount[index], RunItemsCount.fieldsToClone);
          state.initialRiCopy[run.uuid] = _.clone(slimRun);
          return true;
        }
        return false;
      }),
      set: (val) => {
        rowData.order.manager.runs = val;
      },
    });
    const isCompleted = computed({
      get: () => rowData.isCompleted,
      set: (val) => {
        rowData.isCompleted = val;
      },
    });
    const isRunSelected = (run) => (_.map(selected.value, 'uuid')).includes(run.uuid);
    const isDisabled = computed(() => rowData.$isDisabled || rowData.isItemCompleted);
    function defaultRi(run) {
      const item = rowData;
      return rowData.order.manager.getDefaultRi({ item, run });
    }
    const cancelSelected = () => {
      state.isEditing = false;
      if (rowData._beforeEdit) {
        _.set(rowData, 'order', rowData._beforeEdit);
        delete rowData._beforeEdit;
      }
    };
    const onEdit = () => {
      state.isEditing = true;
      _.set(rowData, '_beforeEdit', _.cloneDeep(rowData.order));
    };
    const saveAll = () => {
      state.isEditing = false;
      _.set(rowData, '_beforeEdit', _.cloneDeep(rowData.order));
      CardDirtyBus.emit('setCustomDirty');
    };
    function getRi(run) {
      const oldRi = state.initialRiCopy[run.uuid];
      if (oldRi) oldRi._item = rowData;
      return oldRi || defaultRi(run);
    }
    const onSelect = async (run) => {
      if (run.runItemsCount.length > BaseOrder.runItemLimit) {
        toast.error(`Run already contains the maximum number(${BaseOrder.runItemLimit}) of items`);
        await nextTick();
        const index = _.findIndex(selected.value, { uuid: run.uuid });
        if (index > -1) selected.vaue.splice(index, 1);
        return;
      }
      const idx = _.findIndex(rowData.order.manager.runs, { _id: run._id });
      if (idx > -1) {
        const runIdx = rowData.order.manager.runs[idx].runItemsCount.push(getRi(run));
        rowData.order.manager._runItemsIndexMap[run.uuid][rowData.uuid] = runIdx - 1;
      }
      // on need to change selected, auto done by v-model
      // const runIdx = $_.findIndex(selected, { uuid: run.uuid });
      // $delete(selected, runIdx);
    };
    const onRemove = (run) => {
      const riIndex = runItemsIndexMap.value[run.uuid][rowData.uuid];
      const idx = _.findIndex(rowData.order.manager.runs, (mrun) => {
        if (run._id) return mrun._id === run._id;
        return mrun.uuid === run.uuid;
      });
      if (riIndex > -1 && idx > -1) {
        rowData.order.manager.runs[idx].runItemsCount.splice(riIndex, 1);
      }
      rowData.order.manager.runItemsIndexMap();
    };
    const clearAll = () => {
      selected.value.forEach((run) => {
        const riIndex = runItemsIndexMap.value[run.uuid][rowData.uuid];
        if (riIndex > -1) run.runItemsCount.splice(riIndex, 1);
      });
      rowData.order.manager.runItemsIndexMap();
      state.refreshKey += 1;
    };
    const selectAll = () => {
      runs.value.forEach((run) => {
        if (run.runItemsCount.length > BaseOrder.runItemLimit) {
          toast.error(`Cannot add more items to the Run - maximum reached (${BaseOrder.runItemLimit})`);
          return;
        }
        const riIndex = runItemsIndexMap.value[run.uuid][rowData.uuid];
        // if already part of run, then return
        if (riIndex > -1) return;
        const ri = getRi(run);
        run.runItemsCount.push(ri);
      });
      rowData.order.manager.runItemsIndexMap();
      state.refreshKey += 1;
    };
    const addOrRemoveRun = (run) => {
      if (selected.value.length === 1) {
        toast.warning('Atleast one run should be present in each item');
        return;
      }
      if (isDisabled.value) return;
      if (!state.isEditing && _.isUndefined(rowData._beforeEdit)) {
        _.set(rowData, '_beforeEdit', _.cloneDeep(rowData.order));
      }
      if (isRunSelected(run)) {
        onRemove(run);
      } else {
        onSelect(run);
      }
    };
    const getRiValue = (event, run) => {
      const riIdx = runItemsIndexMap.value[run.uuid][rowData.uuid];
      const ri = run.runItemsCount[riIdx];
      return ri.riCompleted || false;
    };
    const setRiComplete = (isChecked, run) => {
      if (!state.isEditing && _.isUndefined(rowData._beforeEdit)) {
        _.set(rowData, '_beforeEdit', _.cloneDeep(rowData.order));
      }
      const riIdx = runItemsIndexMap.value[run.uuid][rowData.uuid];
      const ri = run.runItemsCount[riIdx];
      ri.riCompleted = isChecked;
    };
    const setAlRiComplete = (isChecked) => {
      runs.value.forEach((run) => {
        run.isSelected = isChecked;
        setRiComplete(isChecked, run);
      });
    };
    onMounted(() => {
      if (!rowData) return;
      state.initialRiCopy = {};
    });
    return {
      ...toRefs(state),
      selectAll,
      clearAll,
      onRemove,
      onSelect,
      runs,
      isRunSelected,
      onEdit,
      cancelSelected,
      saveAll,
      addOrRemoveRun,
      selected,
      setRiComplete,
      setAlRiComplete,
      getRiValue,
      isCompleted,
      isDisabled,
      CardDirtyBus,
      disableEditButton,
      disableInput,
      isFormCompleted,
    };
  },
});
</script>
<style scoped>
div.v-popper--theme-dropdown .v-popper__inner .card {
  width: 432px;
  height: 504px;
}
div.v-popper--theme-dropdown .v-popper__inner .card .card-content  .kit-item {
  height: 358px;
}
::v-deep(.o-chk--disabled) {
  display: none;
}
</style>
