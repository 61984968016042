export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  isCheckable: true,
  hasCheckBox: true,
  isDetailed: false,
  customRowKey: 'uid',
  fields: [{
    name: 'field-generic-input',
    prop: 'name',
    width: '400px',
    title: 'Item Name',
    id: 'name',
  }, {
    name: 'field-generic-input',
    prop: 'description',
    width: '15%',
    title: 'Description',
    id: 'description',
  },
  {
    name: 'field-cat-id',
    prop: 'catId',
    width: '15%',
    title: 'Catalog ID',
    id: 'catId',
  }, {
    name: 'qty-available',
    prop: 'quantity',
    width: '15%',
    title: 'Available',
    id: 'quantity',
  },
  {
    name: 'qty-needed',
    prop: 'selectedQty',
    width: '15%',
    title: 'Qty To Ship',
    id: 'selectedQty',
  }],
};
