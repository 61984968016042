export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  hasCheckBox: false,
  isDetailed: false,
  rearrangeCols: false,
  toolBar: false,
  hideTableGutter: true,
  stickeyHeaders: true,
  isCardView: true,
  fields: [
    {
      title: '',
      name: 'table-field-radio',
      prop: 'selected',
      width: '40px',
      id: 'selected',
    },
    {
      title: 'Template Order Name',
      name: 'field-generic-input',
      prop: 'name',
      width: '356px',
      id: 'name',
    },
    {
      title: 'Location',
      name: 'location',
      width: '256px',
      id: 'location',
    },
    {
      title: 'Owner',
      name: 'field-generic-input',
      prop: 'owner.user.name',
      width: '256px',
      id: 'owner',
    },
  ],
};
