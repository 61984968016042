export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  fields: [
    {
      title: '',
      name: 'table-field-radio',
      width: '5%',
      prop: 'selected',
      id: 'selected',
    },
    {
      name: 'name',
      width: '35%',
      title: 'Item Name',
      id: 'name',
      sortField: 'name',
      defaultVisible: true,
    },
    {
      name: 'desc',
      width: '35%',
      title: 'Description',
      id: 'desc',
      defaultVisible: true,
    },
    {
      name: 'catId',
      width: '30%',
      title: 'Catalog ID',
      id: 'catId',
      sortField: 'catId',
      defaultVisible: true,
    },
  ],
};
