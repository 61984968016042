import { createVNode, render } from 'vue';
import { defaults } from 'lodash';
import Dialog from '@/components/modals/Dialog.vue';

let localVueInstance;

function open(propsData) {
  const app = localVueInstance;
  const vnode = createVNode(Dialog, propsData);
  vnode.appContext = app._context;
  return render(vnode, document.createElement('div'));
}
/* <o-dialog title="test" message="Hello" okButton="ok" cancelButton="close" programmatic="true"></o-dialog> */

const DialogProgrammatic = {
  confirm(params) {
    const defaultParam = {
      programmatic: true,
      title: 'ADD TEMPLATE\'S ITEMS?',
      message: '',
      okButton: 'Ok',
      cancelButton: '',
    };
    const props = defaults(params, defaultParam);
    return open(props);
  },
};

export default {
  install(app) {
    localVueInstance = app;
    app.component('m-dialog', Dialog);
    app.config.globalProperties.$oruga.dialog = DialogProgrammatic;
  },
};

export {
  DialogProgrammatic,
  Dialog as MDialog,
};
