<template>
    <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
    <div class="list-view">
        <mf-table
                ref="stageChangeTable"
                :tableProps="tableProps"
                :hideGutter="true"
                :loadData="loadData"
                :screenTable="true"
                tableName="stageChangeTable"
                @cell-clicked="$emit('cell-clicked', $event)"
        >
            <template v-slot:dateOfChange="date">
              <span class="is-pulled-right">
                {{$filters.usaDate(date.rowData.timeStamp)}}
              </span>
            </template>
            <template v-slot:module="data">
               {{getModuleName(data.rowData)}}
            </template>
        </mf-table>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import Activity from '@/models/Activity';
import {
  isEmpty, extend,
} from 'lodash';
import stageViewCols from '@/components/table-cols/stageChangeViewCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import {
  ref, reactive, toRefs, onMounted,
} from 'vue';
import uuid from 'uuid/v4';

export default ({
  props: {
    showEmpty: Boolean,
    moduleName: Object,
  },
  components: {
    MfTable,
  },
  setup(props) {
    const store = useStore();
    const stageChangeTable = ref(null);
    const state = reactive({
      tableProps: stageViewCols,
      isLoading: false,
    });

    const refreshTable = async () => {
      await stageChangeTable.value?.refreshTable();
    };

    const loadData = async (commonParam) => {
      const result = await Activity.getrecentEvents({
        params: {
          projectId: store.getters.selectedIdsForKey('filteredProjects'),
          createdCompany: store.getters.selectedIdsForKey('companies', false),
          ...store.getters.selectedDatesForKeys([
            'DateStartDate',
            'DateEndDate',
          ]),
          fieldChange: ['Stage'],
          search: store.state.queryParams.searchText,
          module: store.state.queryParams.module.map((mod) => mod.value),
          oldStage: store.state.queryParams.oldStage.map((s) => s.value),
          newStage: store.state.queryParams.newStage.map((st) => st.value),
          ...commonParam,
        },
      });
      result.data = result.data.filter((dt) => {
        const moduleName = dt.module.charAt(0).toLowerCase() + dt.module.slice(1);
        if (moduleName === 'materialManager' && !isEmpty(dt.sourcingOrder)) {
          return dt.sourcingOrder;
        }
        return dt[moduleName];
      }).map((stageAct) => {
        extend(stageAct, stageAct.order);
        if (stageAct.module === 'MaterialManager' && !isEmpty(stageAct.sourcingOrder)) {
          stageAct.cardId = stageAct.sourcingOrder._id;
        } else {
          stageAct.cardId = stageAct[props.moduleName[stageAct.module].module]._id;
        }
        if (isEmpty(stageAct.stageChange)) {
          stageAct.stageChange = stageAct.statusChange;
        }
        const changeStageMap = {
          'not-started': 'scheduled',
        };
        if (stageAct.stageChange) {
          ['newValue', 'oldValue'].forEach((key) => {
            if (changeStageMap[stageAct.stageChange[key]]) {
              stageAct.stageChange[key] = changeStageMap[stageAct.stageChange[key]];
            }
          });
        }
        stageAct.uid = uuid();
        return stageAct;
      });
      return result;
    };
    const getModuleName = (rowData) => {
      if(rowData.module === 'ProductionOrder' && rowData.isCutOrder) return 'Cut Order';
      return _.startCase(rowData.module)
    }
    onMounted(async () => {
      store.commit('setQueryParams', { oldStage: [] });
      store.commit('setQueryParams', { newStage: [] });
    });
    return {
      ...toRefs(state),
      stageChangeTable,
      loadData,
      refreshTable,
      getModuleName,
    };
  },
});
</script>
