import { get } from 'lodash';
// import installLocFieldCol from '@/components/table-cols/installLocFieldCol';
function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    isDetailed: false,
    rearrangeCols: false,
    toolBar: false,
  };
}

export default {
  'item-info': {
    ...extraTableParams(),
    fields: [
      {
        name: 'field-generic-input',
        width: '25%',
        title: 'Items',
        prop: 'name',
        id: 'name',
      },
      {
        name: 'table-field-icon',
        width: '5%',
        title: '',
        id: 'lmvIcon',
        customHeader: true,
        inputProps: {
          icon: 'icon-linked-model',
        },
      },
      {
        name: 'field-generic-input',
        width: '15%',
        title: 'Item ID',
        id: 'customId',
        prop: 'customId',
      },
      {
        name: 'field-generic-input',
        width: '10%',
        title: 'Catalog ID',
        id: 'catId',
        prop: 'catId',
      },
      {
        name: 'field-generic-input',
        width: '10%',
        title: 'QTY',
        id: 'quantity',
        prop: 'quantity',
      },
      {
        name: 'field-install-locs-icon',
        title: 'Install Location',
        width: '15%',
        id: 'installLocation',
        sortField: 'installLocation',
      },
      {
        name: 'field-item-docs-icon',
        width: '10%',
        title: 'Docs',
        id: 'simpleFiles',
        for: 'Item',
      },
      {
        name: 'field-notes-icon',
        title: 'Notes',
        prop: 'simpleMemos[0].text',
        defaultVisible: true,
        width: '10%',
        id: 'notes',
        inputProps: {
          isItem: true,
        },
      },
    ],
  },
  'item-details': {
    ...extraTableParams(),
    fields: [
      {
        name: 'name',
        width: '20%',
        title: 'Items',
        prop: 'name',
        id: 'name',
      },
      {
        name: 'customId',
        width: '10%',
        title: 'Item ID',
        id: 'customId',
      },
      {
        name: 'field-generic-input',
        width: '10%',
        title: 'Meas',
        id: 'measure',
        prop: 'measure',
      },
      {
        name: 'field-measure-unit-select',
        width: '10%',
        title: 'Meas units',
        id: 'measureUnits',
        prop: 'measureUnits',
      },
      {
        name: 'field-generic-input',
        prop: 'category.name',
        width: '15%',
        title: 'Category',
        id: 'category',
      },
      {
        name: 'vendors',
        prop: 'vendor',
        width: '15%',
        title: 'Vendor',
        id: 'vendor',
      }, {
        name: 'field-generic-input',
        width: '10%',
        title: 'QTY',
        id: 'quantity',
        prop: 'quantity',
      }, {
        name: 'costCode',
        width: '10%',
        title: 'Cost Code',
        prop: 'costCode',
        id: 'costCode',
      },
    ],
  },
  'item-dates': {
    ...extraTableParams(),
    stickeyHeaders: true,
    isCardView: true,
    toolBar: { hideSearchBar: true },
    fields: [
      {
        name: 'field-generic-input',
        width: '400px',
        title: 'Item',
        prop: 'name',
        id: 'name',
        searchable: true,
        sticky: true,
        cardProps: {
          isEditing: false,
        },
      },
      {
        name: 'field-generic-input',
        width: '296px',
        title: 'Item Id',
        id: 'customId',
        prop: 'customId',
        cardProps: {
          isEditing: false,
        },
      }, {
        name: 'field-qty-input',
        width: '152px',
        title: 'Lead Time (Days)',
        prop: 'leadTime',
        fieldClass: 'has-text-right',
        id: 'leadTime',
        max: 9999,
        min: 0,
        roundTo: 2,
        isDisabled: (rowData, routeParams) => ['ordering'].includes(get(routeParams, 'stage', '')) && routeParams.cardId !== 'add',
      }, {
        name: 'field-date',
        width: '152px',
        title: 'Order By',
        prop: 'orderBy',
        id: 'orderBy',
        inputProps: {
          kind: 'orderBy',
          disableEdit: (data, route) => {
            if (['sourcing', 'preparation'].includes(data.stage) || route.cardId === 'add') return false;
            return true;
          },
          hideCalendarIcon: true,
          max: ['shipBy', 'deliver'],
        },
      }, {
        name: 'field-date',
        width: '152px',
        title: 'Ship By',
        id: 'shipBy',
        inputProps: {
          kind: 'shipBy',
          disableEdit: false,
          hideCalendarIcon: true,
          min: 'orderBy',
          max: 'deliver',
        },
        isDisabled: (item) => ['delivery', 'complete'].includes(get(item, 'order.stage', '')),
      }, {
        name: 'field-date',
        width: '152px',
        title: 'Onsite',
        id: 'onSiteBy',
        inputProps: {
          kind: 'deliver',
          disableEdit: true,
        },
      },
    ],
  },
};
