<template>
  <o-modal :active="isListSubsActive" :canCancel="false" class="modal-sm">
    <div class="modal-card">
     <!-- Modal Header -->
      <header class="modal-card-head">
        <div class="modal-card-title" >
          Select Procore Submittal
        </div>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="cancel()"></i>
      </header>
      <!-- Modal Body -->
      <section class="modal-card-body is-visible has-background-white" >
        <div class="columns is-marginless">
          <div class="column p-0">
            <search-bar
              :shouldEmit="true"
              @search="getSearchValue"
              placeholder="Search"
            >
            </search-bar>
          </div>
        </div>
        <div class="table-container">
          <mf-table
            ref="Procore"
            :tableProps="tableProps"
            :loadData="loadData"
            :apiMode="true"
            @row-select="getSelectedRows($event)"
          >
          </mf-table>
        </div>
      </section>

      <!-- Modal Footer -->
      <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
        <button class="button is-outlined" @click="cancel()">Cancel</button>
        <button
          class="button has-background-black-bis"
          :disabled="!selectDisable"
          @click="selectSubmittal"
        >Select</button>
      </footer>
    </div>
    </o-modal>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, computed, defineAsyncComponent,
} from 'vue';
import tableDefinition from '@/components/table-cols/submittalsListCols';
import _ from 'lodash';
import SearchBar from '@/components/SearchBar.vue';
import ProcoreModel from '@/models/Procore';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default defineComponent({
  name: 'ListSubmittals',
  components: {
    MfTable,
    SearchBar,
  },
  props: {
    projectId: String,
    isListSubsActive: [String, Boolean],
  },
  setup(props, { emit }) {
    const Procore = ref(null);
    const state = reactive({
      tableProps: tableDefinition,
      searchText: '',
      tableData: [],
      loadTable: 0,
      selectedRow: {},
    });
    const selectDisable = computed(() => !_.isEmpty(state.selectedRow));
    const getSelectedRows = (items) => {
      state.selectedRow = items;
    };
    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      Procore.value.refreshTable();
    };
    const loadData = async () => {
      let data = [];
      if (state.tableData && state.searchText) {
        data = _.filter(state.tableData, (submittal) => submittal.title.toLowerCase().includes(state.searchText.toLowerCase()));
        return { data };
      }
      state.tableData = await ProcoreModel.getListOfSubmittals({ projectId: props.projectId });
      return { data: state.tableData };
    };
    const cancel = () => {
      state.searchText = null;
      state.selectedRow = null;
      emit('close', false);
    };
    const selectSubmittal = () => {
      const selectedSubmittal = _.find(state.tableData, { id: state.selectedRow.id });
      emit('selectedSubmittal', selectedSubmittal);
      emit('close', false);
    };
    return {
      ...toRefs(state),
      loadData,
      getSelectedRows,
      getSearchValue,
      cancel,
      Procore,
      selectDisable,
      selectSubmittal,
    };
  },
});
</script>
<style scoped>
::v-deep(.o-table tbody) {
  min-height: 385px !important;
  max-height: 385px !important;
}
</style>
