export default {
  'prefab-edit-forward': {
    title: 'Create Production Order',
    actionBtn: 'Move',
    __t: 'Prefabs',
    currentStage: 'planning',
    nextStages: ['coordination', 'detailing', 'manufacturing'],
    pickName: true,
    pickLocation: false,
    cardTitle: 'Order Details *',
    addFromTemplate: true,
    moveBasics: {
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner *',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                textValue: card.project.name,
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          // title: 'Production Details',
          fields: () => {
            const fields = [
              {
                name: 'field-location-select',
                prop: 'location',
                titleLabel: 'Production Location *',
                isEditing: true,
                id: 'location',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                isMulti: true,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
      ],
    },
    showTable: true,
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickeyHeaders: true,
      isCardView: true,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '400px',
          prop: 'name',
          id: 'name',
          sticky: true,
        },
        {
          title: 'Description',
          name: 'field-generic-input',
          width: '152px',
          id: 'customId',
          prop: 'financialId',
        },
        {
          name: 'fieldSelectDateSlot',
          kind: 'dates',
          prop: 'coord',
          title: 'Coordinate By',
          isEdit: true,
          width: '152px',
          id: 'coord-date',
          customHeader: true,
          customSlotHeader: true,
          inputProps: {
            kind: 'coord',
            max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          prop: 'detailBy',
          isEdit: true,
          width: '152px',
          title: 'Detail By',
          customHeader: true,
          customSlotHeader: true,
          id: 'detailBy-date',
          inputProps: {
            kind: 'detailBy',
            max: ['manufactureBy', 'qaBy', 'deliver'],
            min: 'coord',
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          width: '152px',
          prop: 'manufactureBy',
          title: 'Manufacture By',
          isEdit: true,
          customHeader: true,
          customSlotHeader: true,
          id: 'manufactureBy-date',
          inputProps: {
            kind: 'manufactureBy',
            min: ['coord', 'detailBy'],
            max: ['qaBy', 'deliver'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          prop: 'qaBy',
          title: 'QA/QC By',
          isEdit: true,
          id: 'qaBy-date',
          // item: card,
          width: '152px',
          isModal: true,
          inputProps: {
            kind: 'qaBy',
            max: ['deliver'],
            min: ['coord', 'detailBy', 'manufactureBy'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          width: '152px',
          prop: 'deliver',
          isEdit: false,
          title: 'On-site',
          id: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: ['coord', 'detailBy', 'manufactureBy', 'qaBy'],
          },
        },
        {
          title: 'Order Template',
          name: 'fieldSelectTemplateSlot',
          placeholder: 'make selection',
          width: '296px',
          prop: 'dataFromTemplate',
          stage: 'planning',
          purpose: 'general',
          inputProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: true,
          },
        },
      ],
    },
  },
  'po-edit-forward': {
    title: 'Move Orders Forward',
    actionBtn: 'Move',
    __t: 'ProductionOrder',
    currentStage: 'coordination',
    trackingRuns: true,
    pickLocation: true,
    pickStage: true,
    nextStages: ['detailing', 'manufacturing'],
    pickName: false,
    cardTitle:'Order Details *',
    moveBasics: {
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner *',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                textValue: card.project.name,
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          // title: 'Production Details',
          fields: () => {
            const fields = [
              {
                name: 'field-location-select',
                prop: 'location',
                titleLabel: 'Production Location *',
                isEditing: true,
                id: 'location',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                isMulti: true,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
      ],
    },
    showTable: true,
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickeyHeaders: true,
      isCardView: true,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '400px',
          prop: 'name',
          id: 'name',
          sticky: true,
        },
        {
          title: 'Description',
          name: 'field-generic-input',
          width: '152px',
          id: 'customId',
          prop: 'financialId',
        },
        {
          name: 'fieldSelectDateSlot',
          kind: 'dates',
          prop: 'coord',
          title: 'Coordinate By',
          isEdit: true,
          width: '152px',
          id: 'coord-date',
          inputProps: {
            kind: 'coord',
            max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          prop: 'detailBy',
          isEdit: true,
          width: '152px',
          title: 'Detail By',
          id: 'detailBy-date',
          customHeader: true,
          customSlotHeader: true,
          inputProps: {
            kind: 'detailBy',
            max: ['manufactureBy', 'qaBy', 'deliver'],
            min: 'coord',
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          width: '152px',
          prop: 'manufactureBy',
          title: 'Manufacture By',
          isEdit: true,
          id: 'manufactureBy-date',
          customHeader: true,
          customSlotHeader: true,
          inputProps: {
            kind: 'manufactureBy',
            min: ['coord', 'detailBy'],
            max: ['qaBy', 'deliver'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          prop: 'qaBy',
          title: 'QA/QC By',
          isEdit: true,
          id: 'qaBy-date',
          // item: card,
          isModal: true,
          width: '152px',
          inputProps: {
            kind: 'qaBy',
            max: ['deliver'],
            min: ['coord', 'detailBy', 'manufactureBy'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          width: '152px',
          prop: 'deliver',
          isEdit: false,
          title: 'On-site',
          id: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: ['coord', 'detailBy', 'manufactureBy', 'qaBy'],
          },
        },
      ],
    },
  },
  'manager-edit-detailing-forward': {
    title: 'Move Order To Manufacturing',
    actionBtn: 'Move',
    __t: 'ProductionOrder',
    currentStage: 'detailing',
    trackingRuns: false,
    pickLocation: true,
    pickStage: true,
    nextStages: ['manufacturing'],
    pickName: false,
    cardTitle: 'Order Details *',
    moveBasics: {
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner *',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          // title: 'Production Details',
          fields: () => {
            const fields = [
              {
                name: 'field-location-select',
                prop: 'location',
                titleLabel: 'Production Location *',
                isEditing: true,
                id: 'location',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                isMulti: true,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
      ],
    },
    showTable: true,
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickeyHeaders: true,
      isCardView: true,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '400px',
          prop: 'name',
          id: 'name',
          sticky: true,
        },
        {
          title: 'Description',
          name: 'field-generic-input',
          width: '152px',
          id: 'customId',
          prop: 'financialId',
        },
        {
          name: 'fieldSelectDateSlot',
          kind: 'dates',
          prop: 'coord',
          title: 'Coordinate By',
          isEdit: true,
          width: '152px',
          id: 'coord-date',
          inputProps: {
            kind: 'coord',
            max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          prop: 'detailBy',
          isEdit: true,
          width: '152px',
          title: 'Detail By',
          id: 'detailBy-date',
          inputProps: {
            kind: 'detailBy',
            max: ['manufactureBy', 'qaBy', 'deliver'],
            min: 'coord',
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          width: '152px',
          prop: 'manufactureBy',
          title: 'Manufacture By *',
          isEdit: true,
          id: 'manufactureBy-date',
          inputProps: {
            kind: 'manufactureBy',
            min: ['coord', 'detailBy'],
            max: ['qaBy', 'deliver'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          prop: 'qaBy',
          title: 'QA/QC By',
          isEdit: true,
          id: 'qaBy-date',
          // item: card,
          width: '152px',
          isModal: true,
          inputProps: {
            kind: 'qaBy',
            max: ['deliver'],
            min: ['coord', 'detailBy', 'manufactureBy'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          width: '152px',
          prop: 'deliver',
          isEdit: false,
          title: 'Onsite',
          id: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: ['coord', 'detailBy', 'manufactureBy', 'qaBy'],
          },
        },
      ],
    },
  },
  'manager-edit-manufacturing-forward': {
    title: 'Move Order To QA/QC',
    actionBtn: 'Move',
    __t: 'ProductionOrder',
    currentStage: 'manufacturing',
    trackingRuns: false,
    pickLocation: true,
    pickStage: true,
    nextStages: ['qa'],
    pickName: false,
    cardTitle: 'Order Details *',
    moveBasics: {
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner *',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          // title: 'Production Details',
          fields: () => {
            const fields = [
              {
                name: 'field-location-select',
                prop: 'location',
                titleLabel: 'Production Location *',
                isEditing: true,
                id: 'location',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                isMulti: true,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
      ],
    },
    showTable: true,
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: false,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickeyHeaders: true,
      isCardView: true,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '400px',
          prop: 'name',
          id: 'name',
          sticky: true,
        },
        {
          title: 'Description',
          name: 'field-generic-input',
          width: '152px',
          id: 'customId',
          prop: 'financialId',
        },
        {
          name: 'fieldSelectDateSlot',
          kind: 'dates',
          prop: 'coord',
          title: 'Coordinate By',
          isEdit: true,
          width: '152px',
          id: 'coord-date',
          inputProps: {
            kind: 'coord',
            max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          prop: 'detailBy',
          isEdit: true,
          width: '152px',
          title: 'Detail By',
          id: 'detailBy-date',
          inputProps: {
            kind: 'detailBy',
            max: ['manufactureBy', 'qaBy', 'deliver'],
            min: 'coord',
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          width: '152px',
          prop: 'manufactureBy',
          title: 'Manufacture By',
          isEdit: true,
          id: 'manufactureBy-date',
          inputProps: {
            kind: 'manufactureBy',
            min: ['coord', 'detailBy'],
            max: ['qaBy', 'deliver'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          prop: 'qaBy',
          title: 'QA/QC By *',
          isEdit: true,
          id: 'qaBy-date',
          width: '152px',
          isModal: true,
          inputProps: {
            kind: 'qaBy',
            max: ['deliver'],
            min: ['coord', 'detailBy', 'manufactureBy'],
          },
        },
        {
          name: 'fieldSelectDateSlot',
          defaultVisible: false,
          kind: 'dates',
          width: '152px',
          prop: 'deliver',
          isEdit: false,
          title: 'Onsite',
          id: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: ['coord', 'detailBy', 'manufactureBy', 'qaBy'],
          },
        },
      ],
    },
  },
};
