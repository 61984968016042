<template>
  <o-modal :active="isActive" :canCancel="false" class="modal-xs">
    <div class="modal-card column-chooser">
      <header class="modal-card-head">
        <h4
          class="modal-card-title is-capitalized"
        >
          Customize Columns
        </h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="$emit('close')"></i>
      </header>
      <section class="modal-card-body is-marginless is-paddingless">
        <div>
          <button
            class="button has-background-black-bis mb-0 is-size-3"
            :disabled="restoreDisabled"
            @click="setDefaultCols"
          >
            Restore Defaults
          </button>
        </div>
        <div class="column">
          <div class="chooser-body">
            <draggable
              v-model="cols"
              item-key="id"
              handle=".handle"
              :move="onMove"
            >
            <template #item="{ element }">
              <div class="column-chooser-item is-flex is-align-items-center">
                <div class="is-flex"
                  :class="element.visible && !element.alwaysVisible ?
                    'column-chooser-item-elem' : 'is-full-width'">
                  <o-checkbox
                    class="checkbox-and-pin column"
                    v-show="!element.hideFromChooser"
                    v-model="element.visible"
                    :key="element.id"
                    :disabled="element.alwaysVisible"
                    :value="element.id"
                  >
                    <span class="is-capitalized has-text-black-bis ml-2">
                      {{element.title ? element.title : getTitle(element.id)}}
                    </span>
                  </o-checkbox>
                  <div class="handle is-pulled-right"
                    :class="element.visible && !element.alwaysVisible ?
                      'is-visible' : 'is-hidden'">
                    <i class="icon-reorder"></i>
                  </div>
                </div>
              </div>
            </template>
          </draggable>
          </div>
        </div>
      </section>
      <footer>
        <p class="has-text-white is-size-6 footer-note">
          <span class="has-text-weight-bold">Hint:</span>
          Click-Hold &amp; Drag items to reorder columns.
        </p>
        <div class="modal-card-foot is-justify-content-flex-end">
          <button
            class="button is-outlined"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="button has-background-black-bis"
            :disabled="!enableApply"
            @click="setColumns"
          >
            Apply Changes
          </button>
        </div>
      </footer>
    </div>
  </o-modal>
</template>

<script>

import {
  reactive,
  toRefs,
  computed,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import screens from '@/constants';
import {
  cloneDeep, find, isEmpty,
} from 'lodash';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
    // draggable: () => import('vuedraggable'),
  },
  name: 'ColumnChooser',
  props: {
    isActive: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const activeCols = cloneDeep(store.getters.activeScreenCols).filter((c) => !!c.id);

    // check if lmvIcon field exists
    activeCols.map((cols) => {
      if (cols.id === 'lmvIcon') cols.title = 'Linked Model';
      return cols;
    });

    // data
    const data = reactive({
      cols: activeCols,
      enableApply: false,
      reArrangeCols: false,
    });

    const getTitle = (id) => {
      const titles = {
        lmvIcon: 'Linked Model',
        'todos.private': 'Private',
      };
      return titles[id];
    };
    // computed
    // Checks the number of Always visible columns and the Checked columns and determines
    // whether the retore default should be disabled or not
    const restoreDisabled = computed(() => {
      if (data.reArrangeCols) return false;
      const findNonDefaultCol = find(data.cols, { defaultVisible: false, visible: true });
      const findDefaultCol = find(data.cols, { defaultVisible: true, visible: false });
      return isEmpty(findNonDefaultCol) && isEmpty(findDefaultCol);
    });

    // methods
    const setColumns = () => {
      store.commit('setColumns', data.cols);
      if (data.reArrangeCols) emit('re-render-table');
      emit('close');
    };
    const setDefaultCols = () => {
      data.cols = activeCols;
      data.cols = screens[store.state.activeScreen].cols;
      data.reArrangeCols = true;
      data.cols.forEach((c) => { c.visible = !!(c.alwaysVisible || c.defaultVisible); });
    };
    const onMove = ({ relatedContext, draggedContext }) => {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      data.reArrangeCols = true;
      return (
        (!relatedElement || !relatedElement.alwaysVisible) && !draggedElement.alwaysVisible
      );
    };

    watch(
      () => data.cols,
      () => { data.enableApply = true; },
      { deep: true },
    );

    return {
      ...toRefs(data),
      restoreDisabled,
      setDefaultCols,
      setColumns,
      onMove,
      getTitle,
    };
  },
};
</script>

<style scoped>
 .modal-xs .modal-card {
   width: 360px !important;
 }
 .modal-card-body {
   overflow: hidden;
 }
</style>
