export default {
  PROJECT: {
    field: 'project',
  },
  DESCRIPTION: {
    field: 'financialId',
    multiple: false,
  },
  NAME: {
    field: 'name',
    multiple: false,
    required: true,
  },
  ONSITEBY: {
    field: 'onSiteBy',
    multiple: false,
    required: true,
  },
  OWNER: {
    field: 'materialOwner',
    multiple: false,
  },
  'DOCUMENT NAMES': {
    field: 'docNames',
    multiple: false,
  },
  DOCUMENTS: {
    field: 'documents',
    multiple: false,
  },
  NOTES: {
    field: 'note',
    multiple: false,
  },
};
