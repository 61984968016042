<template>
  <div class="is-flex is-align-items-center">
     <button class="button px-0 is-danger mr-2" v-show="getFileName" @click="clearFile">
        <i class="icon-removedelete"></i>
      </button>
      <o-upload
        ref="pickerRef"
        v-model="rawFile"
        @update:modelValue="handleFileChange"
        :accept="accept"
        :dragDrop="false"
      >
        <a
          v-if="!getFileName"
          class="button px-0 is-success has-text-white mr-2"
          :class="{ 'is-loading': isLoading }"
          v-tooltip="tooltipText"
        >
          <slot><i class="icon-addnew has-text-white"></i></slot>
        </a>
        <span class="mr-2">{{ getFileName }}</span>
      </o-upload>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import {
  defineComponent, reactive, computed, toRefs, ref,
} from 'vue';
import { uniq, set } from 'lodash';
import moment from 'moment';

export default defineComponent({
  name: 'excelExport',
  props: {
    accept: {
      type: String,
      default: '.xlsx, .xls',
    },
    className: {
      type: String,
      default: 'xlsx-button',
    },
    bulkImport: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const excelFileInput = ref(null);
    const state = reactive({
      rawFile: null,
      workbook: null,
      rABS: null,
      tableData: {
        header: [],
        body: [],
      },
      pickerRef: null,
    });
    const rABS = computed(
      () => window.xlsxEventBus.XLSX_EVENTS_DATA.options.rABS,
    );
    const getFileName = computed(() => state.rawFile?.name);
    const method = {
      clearUpload: () => {
        if (state.pickerRef?.$el) {
          const [fileInput] = state.pickerRef?.$el?.getElementsByTagName(
            'input',
          );
          if (fileInput) fileInput.value = '';
        }
      },

      fileConvertToWorkbook: (file) => {
        const reader = new FileReader();
        const fixdata = (data) => {
          let o = '';
          let l;
          const w = 10240;
          for (l = 0; l < data.byteLength / w; ++l) {
            o += String.fromCharCode.apply(
              null,
              new Uint8Array(data.slice(l * w, l * w + w)),
            );
          }
          o += String.fromCharCode.apply(
            null,
            new Uint8Array(data.slice(l * w)),
          );
          return o;
        };
        return new Promise((resolve, reject) => {
          try {
            reader.onload = (renderEvent) => {
              const data = renderEvent.target.result;
              if (state.rABS) {
                /* if binary string, read with type 'binary' */
                resolve(XLSX.read(data, { type: 'binary', cellDates: true }));
              } else {
                /* if array buffer, convert to base64 */
                const arr = fixdata(data);
                resolve(
                  XLSX.read(btoa(arr), { type: 'base64', cellDates: true }),
                );
              }
            };
            reader.onerror = (error) => {
              reject(error);
            };
            if (state.rABS) {
              reader.readAsBinaryString(file);
            } else {
              reader.readAsArrayBuffer(file);
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      handleFileChange: async () => {
        try {
          emit('upload-raw-file', state.rawFile);
          const workbook = await method.fileConvertToWorkbook(state.rawFile);
          const sheetData = workbook.Sheets[workbook.SheetNames[0]];
          for (const key of Object.keys(sheetData)) {
            const row = sheetData[key];
            const value = row.v;
            if (row.t === 'd' && value) {
              const formatData = new Date(moment(value).add(30, 'm').hours(12).format());
              set(row, 'v', formatData);
            }
          }
          const xlsxArr = XLSX.utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]],
          );
          state.workbook = workbook;
          method.initTable(method.xlsxArrToTableArr(xlsxArr));
          method.clearUpload();
        } catch (error) {
          emit('on-select-file', false);
          console.error(error);
          method.clearUpload();
        }
      },
      xlsxArrToTableArr: (xlsxArr) => {
        const tableArr = [];
        let maxLength = 0;
        let tableHeader = [];
        xlsxArr.forEach((item) => {
          const keys = Object.keys(item);
          tableHeader.push(...keys);
        });
        tableHeader = uniq(tableHeader);
        maxLength = tableHeader.length;
        let rowItem = {};
        xlsxArr.forEach((item) => {
          rowItem = {};
          for (let i = 0; i < maxLength; i++) {
            rowItem[tableHeader[i]] = item[tableHeader[i]] || '';
          }
          tableArr.push(rowItem);
        });
        return {
          header: tableHeader,
          data: tableArr,
          fileName: getFileName.value,
        };
      },
      tableArrToXlsxArr: ({ data, header }) => {
        const xlsxArr = [];
        let tempObj = {};
        data.forEach((rowItem) => {
          tempObj = {};
          rowItem.forEach((item, index) => {
            tempObj[header[index]] = item;
          });
          xlsxArr.push(tempObj);
        });
        return xlsxArr;
      },
      initTable: ({ data, header }) => {
        state.tableData.header = header;
        state.tableData.body = data;
        const excelDataObject = {
          tableData: state.tableData,
          workbook: state.workbook,
          fileName: getFileName.value,
        };
        emit('on-select-file', excelDataObject);
      },
      clearFile(needEmit = true) {
        state.rawFile = null;
        if (needEmit) emit('clear-file-selection');
      },
    };
    return {
      ...method,
      rABS,
      ...toRefs(state),
      excelFileInput,
      getFileName,
    };
  },
});
</script>
<style scoped>
 .is-flex .button.is-success i,
 .is-flex .is-danger i {
   color: white !important;
 }
</style>
