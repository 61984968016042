class VolatileCache {
  timeInSeconds;

  cacheMap;

  resetOn;

  constructor(timeInSeconds = 60) {
    this.timeInSeconds = timeInSeconds;
  }

  checkTime() {
    const timeNow = Math.floor(Date.now() / (this.timeInSeconds * 1000));
    if (this.resetOn !== timeNow) {
      this.resetOn = timeNow;
      delete this.cacheMap;
      this.cacheMap = {};
    }
  }

  set(key, val) {
    this.checkTime();
    this.cacheMap[key] = val;
  }

  get(key) {
    this.checkTime();
    return this.cacheMap[key];
  }

  has(key) {
    this.checkTime();
    return !!this.cacheMap[key];
  }
}

export default VolatileCache;
