import _ from 'lodash';
import { BaseOrder } from './BaseOrder';
import GenerateRequest from './resources';

const req = GenerateRequest();
export default {
  async supplyChain(params = {}) {
    if (_.isEmpty(params.negativeListProjectIds)
      && _.isEmpty(params.projectId)
      && !params.showAllCompanyOrders) {
      return {
        data: [],
        total: 0,
      };
    }
    if (params.stage && params.stage.length) {
      params.stage = BaseOrder.appendShippingStages(params.stage);
    }
    params = {
      limit: 25,
      page: 1,
      sort: 'name',
      ...params,
    };
    try {
      const textEncode = new TextEncoder();
      const stringifiedParams = textEncode.encode(JSON.stringify(params));
      let data = {};
      if (stringifiedParams && (stringifiedParams.length > 3000)) {
        ({ data } = await req.post('supplyChains', params));
      } else {
        ({ data } = await req.get('supplyChains', { params }));
      }
      return {
        data: data.data.map(order => new BaseOrder(order)),
        total: data.total,
      };
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
  async itemUpdate(item) {
    try {
      const data = await req.post(`inventory/itemUpdate/${item.uid}`, item);
      return {
        item: data.data.item,
      };
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async deleteInventoryItem(items) {
    try {
      const projectIds = _(items).map('projectIds').flatten().value();
      return req.post('inventory/deleteInventoryItem/', { items, projectIds });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async itemView(params = {}) {
    params = {
      limit: 25,
      page: 1,
      sort: 'name',
      ...params,
    };
    try {
      const { data } = await req.get('supplyChains/getItemsView', { params });
      return {
        data: data.data.map((order) => {
          order.items.forEach((i) => { i.archived.value = false; });
          order.quantity = order.items[0].quantity;
          order.catId = order.items[0].catId;
          order.measure = order.items[0].measure;
          order.measureUnits = order.items[0].measureUnits;
          return new BaseOrder(order);
        }),
        total: data.total,
      };
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async inventory(httpOptions = {}, removeEmptyItems, fromImScreen = false) {
    httpOptions.params = {
      baseCardType: ['Part', 'Assembly'],
      limit: 10,
      page: 1,
      sort: 'name',
      ...httpOptions.params || {},
    };
    let data = {};
    if (fromImScreen) {
      ({ data } = await req.post('inventory/allInventoryItems', httpOptions));
    } else {
      ({ data } = await req.get('inventory/inventoryItems', httpOptions));
    }
    return {
      total: data.total,
      data: _.compact(data.data.map((d) => {
        d.itemIds = _.uniq(d.itemIds);
        d.id = _.get(d, 'uid', 'catId') + _.get(d, 'rootLoc._id', '_id.locId');
        // below assignment required for icons, case-insensitive (string case does not matter)
        d.__t = d.baseCardType === 'Prefabs' ? 'ProductionOrder' : d.baseCardType;
        if (removeEmptyItems && !d.available) return false;
        return d;
      })),
    };
  },
  async getBaseOrderQr(params) {
    try {
      const { data } = await req.get(`qr/baseorder/${params.orderId}`, { params });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async returnCodes() {
    try {
      const { data } = await req.get('shipping/getReturnCodes');
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async returnItems(params) {
    try {
      const { data } = await req.post('shipping/return/item', params);
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async showItemDetails(params) {
    try {
      const data = await req.get('inventory/showItemDetails', { params });
      return data.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async importInventoryItems(params) {
    try {
      params = {
        items: params,
        projectIds: _(params).map('projectId').uniq().value(),
      };
      const { data } = await req.post('inventory/importInventoryItems', params);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async adjustItemQty(item) {
    try {
      const data = await req.post(`inventory/adjustItemQty/${item.uid}`, item);
      return {
        item: data.data.item,
      };
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async inventoryQr(params) {
    try {
      const { data } = await req.get('qr/inventory/itemqr', { params });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
