import moment from 'moment';
import * as _ from 'lodash';
import uuid from 'uuid/v4';
import store from '../store';
import Shipping from './Shipping';
import Todo from './Todo';
import Projects from './Projects';
import Locations from './Locations';
import SupplyChain from './SupplyChain';

const zeroId = '000000000000000000000000';
class TransitSchema {
  trackingId;

  currentLocation;

  deliveryLocation;

  currentProject;

  deliveryProject;

  owner;

  recipient;

  deliverBy;

  deliveryStart;

  deliveredOn;

  finalDeliveredBy;

  delivered;

  notes;

  status;

  notify;

  created;

  lastModified;

  constructor(props) {
    Object.assign(this, {
      trackingId: '',
      currentProject: { _id: '' },
      currentLocation: { _id: '' },
      deliveryProject: { _id: '' },
      deliveryLocation: { _id: '' },
      recipient: { _id: '' },
      owner: { _id: '' },
    }, props);
  }

  toServerObj() {
    const toSend = _.clone(this);
    ['currentLocation', 'deliveryLocation', 'currentProject', 'deliveryProject'].forEach((key) => {
      toSend[key] = toSend[key]._id || toSend[key];
    });
    return toSend;
  }
}

class Item {
  constructor(itemObj) {
    if (itemObj && itemObj.name === '' && itemObj.catName) {
      itemObj.name = itemObj.catName;
      itemObj.orderName = '';
    }
    Object.assign(this, itemObj);
  }

  get quantityNeeded() {
    const val = (this.qtyToConsume + this.qtyToShip) || this.quantity;
    return _.round(val, 2);
  }

  set quantityNeeded(val) {
    this.quantity = _.round(val, 2);
  }
}
class ShippingLabel {
  newViewIndexes = [];

  _id;

  name = '';

  items = [];

  itemElements = [];

  project = {};

  files = [];

  isInternal = false;

  kind = 'forward';

  status = 'not-started';

  _indexedItems = {};

  _newItems = {
    fromCard: [],
    fromShipments: [],
    fromInventory: [],
  };

  externalEmails = [];

  _rmItems = [];

  _rmItemsFromPartial = [];

  contributedCards = [];

  _delivery;

  get delivery() {
    return this._delivery;
  }

  set delivery(val) {
    this._delivery = new TransitSchema(val || {});
  }

  get statusToShow() {
    const statusMap = {
      zombie: 'complete', // Doing this since its a requirement from US team
      'released-to-inventory': 'inventory',
      'not-started': 'scheduled',
      fulfilled: 'complete',
    };
    if (this.shipType === 'm' && this.status === 'mixed') {
      // eslint-disable-next-line consistent-return
      if (_.every(this.items, (item) => {
        if (item.masterSummary) {
          return item.masterSummary.delivered === item.masterSummary.total;
        }
      })) {
        return 'complete';
      }
    }
    return statusMap[this.status] ? statusMap[this.status] : this.status;
  }

  constructor(props) {
    Object.assign(this, {
      status: 'not-started',
    }, props);
    this.populateItemDetails();
    this.appendDefaultForms();
    this.items = _.map(this.items, item => new Item(item));
  }

  static async createShipment(card, commonStockProject) {
    const params = {
      name: card.name,
      purpose: 'general',
      project: card.project,
      reserveFor: null,
      items: [],
      externalEmails: [],
      shipType: 's-m',
      delivery: {
        currentProject: card.project._id,
        deliveryProject: card.project._id,
        currentLocation: card.baseDelivery.location,
        deliveryLocation: card.delivery ? card.delivery.deliveryLocation : card.baseDelivery.location,
        owner: card.owner.user,
        recipient: card.baseDelivery.recipient,
      },
    };
    const [cardLocDetails] = await Locations.getOne({ id: card.baseDelivery.location._id });
    if (cardLocDetails.nestedLocation) {
      params.delivery.deliveryProject = _.get(commonStockProject, '_id', card.project._id);
    }
    card.dates.forEach((date) => {
      if (date.kind === 'deliver' && !_.isEmpty(date.sources)) {
        if (date.sources.length === 1 && ['order', 'sourcing'].includes(date.sources[0].type)) {
          params.delivery.deliverBy = date.value;
        }
      }
      if (date.kind === 'shipBy') {
        params.delivery.deliveryStart = date.value;
      }
    });
    const shippingOrder = new ShippingLabel(params);
    const items = await Shipping.getCompletedItems(card);
    items.forEach((item) => {
      let itemObj;
      const key = item._id === zeroId ? 'catId' : '_id';
      if (card.items && card.items.length) {
        itemObj = _.find(card.items, i => i[key] === item[key]);
      } else {
        itemObj = item;
      }
      _.set(item, 'selectedQty', (itemObj.selectedQty || item.quantity || item.available));
      item.cardId = card._id;
      shippingOrder.addItemFromCard(item, card);
    });
    try {
      const newShipment = await shippingOrder.save();
      return newShipment;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  static async saveShipment(sL) {
    try {
      const newShipment = await sL.save();
      return newShipment;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  get groupedEles() {
    return _.groupBy(this.itemElements, '_id');
  }

  isKit() {
    return this.purpose === 'kit';
  }

  isAssembly() {
    return this.purpose === 'assembly';
  }

  validateLabel() {
    let errText = '';
    if (!this.name) {
      errText = 'Please enter a name';
      return { error: true, errText };
    }
    if (this.name.trim().length < 3) {
      errText = 'Shipment name must contain atleast 3 characters';
      return { error: true, errText };
    }
    const regex = '^[A-Za-z0-9][A-Za-z0-9-._]*?[A-Za-z0-9]$';
    _.forEach(this.items, (item) => {
      if (!_.isEmpty(item.catId)) {
        item.catId = item.catId.trim();
        item.catId = item.catId.replace(/ /g, '.');
        if ((!_.inRange(item.catId.length, 4, 33) || !RegExp(regex).test(item.catId))) {
          errText = 'Catalog ID should be 4 to 32 character long and cannot start with special characters';
        }
      }
      return { error: true, errText };
    });
    const deliveryPropMap = {
      currentProject: 'Source Project',
      deliveryProject: 'Destination Project',
      currentLocation: 'Current Location',
      deliveryLocation: 'Destination Location',
      recipient: 'Recipient',
      owner: 'Owner',
    };
    Object.keys(deliveryPropMap).every((deliveryProp) => {
      if (!_.get(this, `delivery.${deliveryProp}._id`, false)) {
        errText = `Please select a ${deliveryPropMap[deliveryProp]}`;
        return false;
      } return true;
    });
    if (errText) return { error: true, errText };
    if (!this.delivery.status === 'waiting' && !this.delivery.deliveryStart) {
      errText = 'Please enter Ship date';
      return { error: true, errText };
    }
    if (!this.delivery.deliverBy) {
      errText = 'Please enter Delivery date';
      return { error: true, errText };
    }
    if (this.delivery.deliveryStart && moment(this.delivery.deliveryStart).isAfter(this.delivery.deliverBy, 'd')) {
      errText = 'Ship Date must not exceed Deliver Date!';
      return { error: true, errText };
    }
    return { error: false };
  }

  populateItemDetails() {
    if (this.itemSources) {
      for (const sItem of this.items) {
        if (sItem.source && sItem.source.id && sItem._id !== zeroId) {
          const order = this.itemSources[sItem.source.id];
          if (order) {
            const orderItem = _.find(order.items, { _id: sItem._id });
            Object.assign(sItem, {
              orderName: order.name || '',
              orderFinId: order.financialId || '',
              level: _.get(orderItem, 'level', order.level),
              zone: _.get(orderItem, 'zone', order.zone),
              catId: _.get(orderItem, 'catId', ''),
              installLocs: _.get(orderItem, 'installLocs', []),
            });
          }
        }
      }
    }
  }

  removeItem(item, key, newQty = 0) {
    let idx = _.findIndex(this._newItems.fromCard, { _id: item._id });
    if (idx > -1 && key === 'fromCard') {
      if (newQty === 0) this._newItems.fromCard.splice(idx, 1);
      else this._newItems.fromCard[idx].quantity = newQty;
      return;
    }

    idx = _.findIndex(this._newItems.fromShipments, { _id: item._id });
    if (idx > -1 && key === 'fromShipments') {
      if (newQty === 0) this._newItems.fromShipments.splice(idx, 1);
      else {
        const old = this._newItems.fromShipments[idx];
        old.quantity = newQty;
        old.serials.splice(0, old.quantity - newQty);
      }
      return;
    }
    if (item._id.toString() === zeroId && !_.isEmpty(item.catId)) {
      idx = _.findIndex(this.items, { catId: item.catId });
    } else idx = _.findIndex(this.items, { _id: item._id });
    if (idx > -1 && key === 'fromSelf') {
      const old = this.items[idx];
      const newInd = this._rmItems.push(_.cloneDeep(old)) - 1;
      if (newQty === 0) {
        // Split API expects quantity 'to be removed' instead of 'new updated' qty
        this._rmItems[newInd].quantity = old.quantity;
        old.quantity = 0;
      } else {
        // Split API expects quantity 'to be removed' instead of 'new updated' qty
        this._rmItems[newInd].quantity = old.quantity - newQty;
        old.quantity = newQty;
      }
    } else if (key === 'fromPartial') {
      const old = this.items[idx];
      const newInd = this._rmItemsFromPartial.push(_.cloneDeep(old)) - 1;
      if (newQty === 0) {
        // removeItemsFromPartial API expects the 'updated' qty and NOT the qty 'to be removed'
        this._rmItemsFromPartial[newInd].quantity = 0;
        old.quantity = 0;
      } else {
        old.quantity = newQty;
        // removeItemsFromPartial API expects the 'updated' qty and NOT the qty 'to be removed'
        this._rmItemsFromPartial[newInd].quantity = newQty;
      }
    }
  }

  /* item:
    { '_id' },
    { 'name' },
    { 'source._id.financialId', title: 'Order Id' },
    { 'source._id.name', title: 'Order Name' },
    { 'catId', title: 'Catalog' },
    { 'level' },
    { 'zone' },
    { 'measure', title: 'Total MEAS' },
    { 'measureUnits', title: 'MEAS Units' },
    { 'available', title: 'Qty' },
    { 'selectedQty', title: 'Qty' },
   */
  addItemFromCard(item, order) {
    item.orderId = order._id;
    if (!item.selectedQty) return;
    this._newItems.fromCard.push(item);
  }

  addItemFromInventory(item, qty = 0) {
    this._newItems.fromInventory.push({
      catId: item.catId,
      uid: item.uid,
      itemIds: item.itemIds,
      quantity: qty || item.quantity,
      locId: _.get(item, 'rootLoc._id', false) || _.get(item, '_id.locId', false),
      project: item.project,
      name: item.name,
    });
  }

  addItemFromShipment(item, ship, qty) {
    return this._newItems.fromShipments.push({
      // quantity: qty,
      moveQuantity: qty,
      name: item.name,
      cardId: _.get(this, 'reserveFor._id', undefined),
      fromShippingId: ship._id,
      uid: item.uid,
      _id: item._id,
      catId: item.catId,
      viewIndex: item.viewIndex,
      locationId: item.locId,
      customId: item.customId,
    }) - 1;
  }

  getItem(id, forceRefresh = false) {
    if (_.isEmpty(this._indexedItems) || forceRefresh) {
      this._indexedItems = _.keyBy(this.items, '_id');
    }
    return this._indexedItems[id];
  }

  peelSerials(id, qty) {
    const serials = [];
    let lastIndex = this.groupedEles[id].length;
    while (qty > 0 && lastIndex >= 1) {
      lastIndex--;
      const ele = this.groupedEles[id][lastIndex];
      if (ele.returned || ele.toReturn) continue;
      qty--;
      serials.push(ele.serial);
      this.groupedEles[id].splice(lastIndex, 1);
    }
    return serials;
  }

  isLabelEmpty() {
    let addable = 0;
    for (const arr of _.values(this._newItems)) { addable += arr.length; }
    const removable = this._rmItems.length || this._rmItemsFromPartial.length || 0;
    return (addable + removable + this.items.length) === 0;
  }

  generateViewIndices() {
    const shipItems = [
      this._newItems.fromCard,
      this._newItems.fromShipments,
      this._newItems.fromInventory,
      this.items,
    ];
    for (const itemsArr of shipItems) {
      this.newViewIndexes.push(...this.updateIndices(itemsArr));
    }
  }

  async save(props = {}) {
    if (!this._id && this.isLabelEmpty()) throw new Error('Cannot create empty label!');
    this.generateViewIndices();
    try {
      let newPS;
      const newFiles = _.filter(this.files, f => !f.master || f.edited);
      const masterFiles = _.filter(this.files, f => f.master);
      this.files = newFiles;
      const backToMaster = [];
      if (this._rmItems.length > 0) {
        const backToBO = [];
        const backToInv = [];
        for (const item of this._rmItems) {
          if (this.itemKind === 'Sourcing' && this.shipType === 's-m' && _.get(props, 'isLocked', true) === true) {
            item.quantity = item.actualQty - item.quantity;
            backToMaster.push(item);
          } else if (_.get(item, 'source.from', '') === 'card') backToBO.push(item);
          else backToInv.push(item);
        }
        if (backToInv.length) {
          const deleteSplit = new this.constructor({
            name: `Split Of ${this.name}`,
            delivery: Object.assign(_.cloneDeep(this.delivery), {
              currentLocation: this.delivery.deliveryLocation,
              currentProject: this.delivery.deliveryProject,
              deliveryLocation: this.delivery.currentLocation,
              deliveryProject: this.delivery.currentProject,
              deliveryStart: moment().startOf('day'),
              deliverBy: moment().startOf('day'),
            }),
          });
          backToInv.forEach(item => deleteSplit.addItemFromShipment(item, this, item.quantity));
          await deleteSplit.save({ splitType: 's-m' });
          if (this.purpose === 'kit') {
            await deleteSplit.receive();
          } else if (this.shipType !== 's') {
            await deleteSplit.releaseToInventory();
          }
        }
        if (backToBO.length) {
          await Shipping.removeItems({
            shippingLabelId: this._id,
            items: backToBO,
          });
        }
      }
      if (this._rmItemsFromPartial.length > 0) {
        await Shipping.removeItemsFromPartial({
          shippingLabelId: this._id,
          items: this._rmItemsFromPartial,
        });
      }

      if (this._newItems.fromInventory.length > 0) {
        const invItems = this._newItems.fromInventory;
        // get all the shipping orders where these items may be present.
        // one item here is one CatID - which may be spread across multiple SLs
        // and over multiple ItemIds
        const catIds = _.flatMap(
          invItems,
          item => (item.catId),
        );
        // itemId is used to get the required items while creating/saving shipment
        const itemId = _.flatMap(
          invItems,
          item => (item.uid),
        );
        const projectId = _.map(_.flatMap(invItems, 'project.0'), '_id');
        const reqParams = {
          params: {
            catIds,
            projectId,
            itemId,
            limit: 50,
            locs: [_.get(this.delivery, 'currentLocation._id', false) || _.get(this.delivery, 'currentLocation', false)],
          },
          catIds,
          limit: !_.isEmpty(catIds) ? catIds.length : 100,
          page: 1,
          baseCardType: ['Part', 'Assembly'],
        };

        const { data: slitems } = await SupplyChain.inventory(reqParams, true, false);
        if (slitems.length) {
          // for each item - unwrap the itemIds
          // for each itemId - find the SLs
          // from each SL - peel off until desired quantity reached

          for (const item of invItems) {
            const itemInfo = {
              catId: item.catId,
              uid: item.uid,
              name: item.name,
              fromShippingId: zeroId,
              _id: zeroId,
              locId: item.locId,
            };
            this.addItemFromShipment(itemInfo, { _id: zeroId }, item.quantity);
          }
        } else throw new Error('No Shipments found!');
      }
      if (this._newItems.fromCard.length > 0) {
        if (!this._id) {
          const notifyUsers = this._delivery.notify || this.alsoNotify || [];
          if (!_.some(notifyUsers, { _id: this._delivery.recipient._id })) notifyUsers.push(this._delivery.recipient);
          // create a card like return shiping;
          let items = _.flatMap(this._newItems.fromCard, (item) => {
            // todo _.isUndefined(item) ||
            if (_.isUndefined(item.runs)) return _.noop();
            return item.runs.map(run => ({
              itemId: item._id,
              name: item.name,
              runId: run.runId,
              installLocs: item.installLocs || [],
              cardId: _.get(this, 'reserveFor._id', undefined),
              orderId: item.orderId || item.source._id,
              quantity: item.selectedQty,
              activeSupplier: item.activeSupplier,
              notes: item.notes || '',
              customId: item.customId,
            }));
          });
          items = _.compact(items);
          // eslint-disable-next-line max-len
          const inventoryItems = _.filter(this._newItems.fromCard, item => _.isUndefined(item.runs));
          if (!_.isEmpty(items)) {
            newPS = await Shipping.create({
              name: this.name,
              externalEmails: this.externalEmails,
              items,
              notifyUsers: notifyUsers || [],
              isInternal: this.isInternal || false,
              contributedCards: this.contributedCards,
              fromSchedule: this.fromSchedule,
              scheduleOrder: this.scheduleOrder,
            });
          }
          if (inventoryItems.length > 0) {
            const catIds = _.map(inventoryItems, 'detail.catId');
            const inventoryShips = await this.getInventoryShip({
              catIds,
              cardIds: _.map(inventoryItems, 'cardId'),
              project: this.delivery.currentProject,
            });
            const itemMap = {};
            for (const inventoryItem of inventoryItems) {
              // eslint-disable-next-line max-len
              const mapId = !_.isEmpty(inventoryItem.catId) ? inventoryItem.catId : inventoryItem._id;
              if (inventoryItem.qtyToShip > 0) {
                inventoryItem.selectedQty = inventoryItem.qtyToShip;
              }
              if (!itemMap[mapId]) {
                itemMap[mapId] = {
                  qtyToShip: inventoryItem.selectedQty,
                };
              } else {
                itemMap[mapId].qtyToShip += inventoryItem.selectedQty;
              }
            }
            for (const shipLabel of inventoryShips) {
              for (const item of shipLabel.items) {
                let catId = item.catId || item.detail.catId;
                catId = _.isEmpty(catId) ? item._id : catId;
                if (itemMap[catId]) {
                  const { qtyToShip } = itemMap[catId];
                  if (qtyToShip > 0) {
                    const qty = item.quantity < qtyToShip ? item.quantity : qtyToShip;
                    /* Below condition is required as if multiple shipments are created,
                    according to above process, shipment will be created only for those
                    items having run..While managing materials, if items.len > 50,
                    items are split to different shipments and will not be part of runs
                    and hence newPS will not be created. */
                    if (newPS) {
                      newPS.addItemFromShipment(item, shipLabel, qty);
                    } else {
                      this.addItemFromShipment(item, shipLabel, qty);
                    }
                    itemMap[catId].qtyToShip -= qty;
                  }
                }
              }
            }
            if (newPS) this._newItems.fromShipments.push(...newPS._newItems.fromShipments);
            if (_.get(this, 'createNewLabel', false)) {
              this._newItems.createNewShipping = true;
            } else {
              this._newItems.createNewShipping = false;
            }
          }
        } else {
          await Shipping.addFromPO({
            toShippingId: this._id,
            items: _.flatMap(this._newItems.fromCard, item => item.runs.map(run => ({
              itemId: item._id,
              name: item.name,
              runId: run.runId,
              installLocs: item.installLocs || [],
              orderId: item.orderId || item.source._id,
              quantity: item.selectedQty,
              notes: item.notes || '',
              customId: item.customId || '',
            }))),
          });
        }
      }

      if (this._newItems.fromShipments.length > 0) {
        if (!this._id && _.get(newPS, 'purpose') !== 'kit' && _.get(this._newItems, 'createNewShipping', true)) {
          const invToShip = _.find(
            this._newItems.fromShipments,
            item => item && item.fromShippingId === zeroId,
          );
          newPS = await Shipping.split({
            project: { name: this.project.name, _id: this.project._id },
            name: this.name,
            items: this._newItems.fromShipments,
            externalEmails: this.externalEmails,
            toShippingType: props.splitType || undefined,
            fromInv: props.fromInv,
            fromInvToShip: !_.isEmpty(invToShip),
            isInternal: this.isInternal || false,
            contributedCards: this.contributedCards,
            forBOM: _.get(this, 'forBOM', false),
          });
        } else {
          const toShippingId = (_.get(newPS, 'purpose') === 'kit' || !_.get(this._newItems, 'createNewShipping', true)) ? newPS._id : this._id;
          newPS = await Shipping.splitFromLabels({
            projectId: _.get(newPS, 'project._id') || this.project._id,
            toShippingId,
            items: this._newItems.fromShipments,
            contributedCards: this.contributedCards,
          });
        }
      }

      if (newPS) {
        newPS.delivery = Object.assign(newPS.delivery || {}, this.delivery);
        if (newPS.status !== 'in-transit') delete newPS.delivery._id;
        Object.assign(this, newPS, { newViewIndexes: this.newViewIndexes });
        this.files = newFiles;
      }
      if (this.status === 'in-storage') {
        delete this.delivery._id;
      }
      newPS = await (!this.delivery._id ? this.addTransit() : this.updateTransit());
      newPS.files = _.uniqBy(_.concat(masterFiles, newPS.files), '_id');
      if (this.status === 'in-transit') {
        /* this is required since the 'receive' call relies on selectedQty inside items
          and since this.items is overwritten by Object.assign, making sure we have the
          older copy of this.items */
        Object.assign(this, newPS, { items: this.items });
      } else {
        Object.assign(this, newPS);
      }
      /* Moved this function last as shipment must be updated with new items
      first before creating partial shipments in case of ordering */
      if (backToMaster.length) {
        const masterShipment = await Shipping.breakShipmentIntoPartials({
          shippingLabelId: this._id,
        });
        await Shipping.removeItemsFromPartial({
          shippingLabelId: masterShipment.partialShipments[0]._id,
          items: backToMaster,
        });
      }
      return this;
    } catch (e) {
      console.log('Error ', e);
      throw e;
    }
  }

  async getInventoryShip(params) {
    const {
      project, catIds, cardIds,
    } = params;
    const query = {
      cardIds,
      projectId: project._id,
      status: ['not-started', 'in-storage', 'fulfilled', 'completed', 'in-transit', 'mixed'],
      limit: 100,
      page: 1,
      catIds,
    };
    const { data: labels } = await Shipping.get(query);
    return labels;
  }

  addTransit() {
    const notifyUsers = this._delivery.notify || this.alsoNotify || [];
    if (!_.some(notifyUsers, { _id: this._delivery.recipient._id })) notifyUsers.push(this._delivery.recipient);
    return Shipping.addTransit({
      projectId: this.project._id,
      shippingLabelId: this._id,
      name: this.name,
      files: this.files, // TODO: All files
      notifyUsers: notifyUsers || [], // TODO: All notify
      viewIndexes: this.newViewIndexes,
      ...this.delivery.toServerObj(),
    });
  }

  updateTransit() {
    const notifyUsers = this._delivery.notify || _.cloneDeep(this.alsoNotify) || [];
    //notifyUsers.push(this._delivery.recipient);
    return Shipping.updateTransit({
      projectId: this.project._id,
      shippingLabelId: this._id,
      name: this.name,
      files: this.files, // TODO: All files
      notifyUsers: notifyUsers || [], // TODO: All notify
      viewIndexes: this.newViewIndexes,
      transitId: this.delivery._id,
      ...this.delivery.toServerObj(),
      externalEmails: this.externalEmails,
    });
  }

  updateIndices(items = []) {
    let itemArray = [];
    const updatedViewIndex = [];
    itemArray = items.length ? items : this.items;
    itemArray.forEach((item) => {
      updatedViewIndex.push({
        _id: item._id,
        index: item.viewIndex,
        name: item.name,
      });
    });
    return updatedViewIndex;
  }

  async receive(isFinal) {
    const notifyUsers = this._delivery.notify || _.cloneDeep(this.alsoNotify) || [];
    notifyUsers.push(this._delivery.recipient);
    const params = {
      shippingLabelId: this._id,
      message: this.delivery.notes,
      externalEmails: this.externalEmails,
      viewIndexes: this.updateIndices(),
      excelFileName: this.excelFileName || '',
      files: this.files ? this.files : [],
      notifyUsers: notifyUsers || [],
      items: _.map(this.items, item => ({
        _id: item._id,
        catId: item.catId,
        isUnderDeliver: item.isUnderDeliver,
        underDeliver: item.underDeliver,
        issueNote: _.get(item, 'issueNote.key', item.issueNote),
        quantity: _.isNumber(item.selectedQty) ? item.selectedQty : item.quantity,
        customId: item.customId,
      })),
      projectId: _.get(this, 'project._id', ''),
    };
    params.isFinal = isFinal
    || (_.get(this, 'delivery.currentProject._id', null)
      !== _.get(this, 'delivery.deliveryProject._id', null));
    const newPS = await Shipping.receiveAll(params);
    if (newPS.parentShipment) {
      localStorage.setItem('shipmentId', newPS.parentShipment);
    }
    return newPS;
  }

  async updateShippingDetails(arg) {
    // arg = {purpose, toConsume}
    const params = {
      shippingLabelId: this._id,
      transitId: this.delivery._id,
      externalEmails: this.externalEmails,
      notes: this.delivery.notes,
      viewIndexes: this.updateIndices(),
      files: this.files ? this.files : [],
      notify: this._delivery && this._delivery.notify ? this._delivery.notify : [],
    };
    if (_.get(arg, 'purpose') === 'kit') {
      params.purpose = arg.purpose;
      params.toConsume = arg.toConsume;
    }
    const newPS = await Shipping.updateShippingDetails(params);
    return newPS;
  }

  async releaseToInventory(incInv = false) {
    let label = this;
    const isReserved = _.some(this.items, 'cardId');
    if (isReserved) {
      await this.unreserve();
    }
    if (this.status === 'in-transit') label = await this.receive();
    if (label.status === 'in-storage') {
      label = await Shipping.releaseToInventory({
        shippingLabelId: this._id,
        fromInventory: incInv,
      });
    }
    return label;
  }

  async markFulfilled() {
    const label = await this.receive(true);
    return label;
  }

  async archive(id) {
    const data = await Shipping.archive(id);
    return data;
  }

  split() {

  }

  async managePartials() {
    if (this.shipType === 's-m' && ['not-started', 'in-transit'].includes(this.status)) {
      const masterShipment = await Shipping.breakShipmentIntoPartials({
        shippingLabelId: this._id,
      });
      if (this.isPdfUpdate) await this.generateShipPdf(masterShipment, this.externalEmails);
      return masterShipment;
    } if (this.shipType === 'm') {
      return Shipping.getPartialShipments({ shippingLabelId: this._id });
    } if (this.shipType === 's') {
      return Shipping.getPartialShipments({ shippingLabelId: this.parentShipment });
    }
    return false;
  }

  async cancelDelivery() {
    const notifyUsers = this._delivery.notify || _.cloneDeep(this.alsoNotify) || [];
    notifyUsers.push(this._delivery.recipient);
    const params = {
      shippingLabelId: this._id,
      transitId: this.delivery._id,
      notes: this.delivery.notes,
      files: this.files ? this.files : [],
      notifyUsers: notifyUsers || [],
      externalEmails: this.externalEmails,
    };
    return Shipping.cancelShipping(params);
  }

  relaunch() {
    const transit = {
      deliverBy: _.get(this.delivery, 'deliverBy', ''),
      deliveryStart: _.get(this.delivery, 'deliveryStart', ''),
      recipient: _.get(this.delivery, 'recipient._id', ''),
      owner: _.get(this.delivery, 'owner._id', ''),
      deliveryLocation: _.get(this.delivery, 'deliveryLocation._id', ''),
      currentLocation: _.get(this.delivery, 'currentLocation._id', ''),
      currentProject: _.get(this.delivery, 'currentProject._id', ''),
      deliveryProject: _.get(this.delivery, 'deliveryProject._id', ''),
      trackingId: _.get(this.delivery, 'trackingId', ''),
      notes: _.get(this.delivery, 'notes', ''),
      notify: _.get(this.delivery, 'notify', ''),
    };
    return Shipping.relaunch({
      name: this.name,
      shippingLabelId: this._id,
      viewIndexes: this.updateIndices(),
      delivery: transit,
      externalEmails: this.externalEmails,
      files: _.get(this, 'files', []),
    });
  }

  unreserve() {
    if (!_.get(this, 'items.length', false)) return true;
    return Shipping.unreserveItems({
      labelIds: [this._id],
      cardId: this.items[0].cardId,
      items: this.items,
      externalEmails: this.externalEmails,
      loc: this.delivery.currentLocation,
    });
  }

  consume() {
    if (!this._id) return true;
    return Shipping.markConsumed({
      labelIds: [this._id],
      cardIds: [],
    });
  }

  async generateShipPdf(masterShipment = false, emails) {
    const shipment = this;
    let params = {
      shippingId: masterShipment ? masterShipment._id : shipment._id,
      projectId: masterShipment ? masterShipment.project._id : shipment.project._id,
      tzinfo: new Date().getTimezoneOffset(),
      type: 'shipping',
    };
    let qrLabel = await Shipping.getShippingLabel(params);
    await store.getters.userPromise;
    const user = store.state.userData.fullName;
    const compLogo = qrLabel[0].companyLogo;
    qrLabel = qrLabel[0].imageData;
    params = {
      emails,
      qrLabel,
      compLogo,
      userInfo: user,
      type: 'pdf',
      name: 'shippingList',
      ...params,
    };
    Shipping.generatePdf(params);
  }

  async createFromLabel(params) {
    let newShipment = {};
    try {
      const commonStockProject = await Projects.getCommonStockProject();
      const projectInvLoc = _.get(params.project.projectSettings[0], 'projectInventoryLocation', {});
      newShipment = new ShippingLabel({
        name: params.name || this.name,
        purpose: 'general',
        isInternal: true,
        project: this.project,
        items: [],
        externalEmails: [],
        shipType: 's-m',
        delivery: {},
      });
      Object.assign(newShipment.delivery, this._delivery);
      Object.assign(newShipment.delivery, {
        deliveryLocation: projectInvLoc,
        deliveryProject: commonStockProject,
      });
      for (const item of this.items) {
        newShipment.addItemFromShipment(item, this, item.quantity);
      }
      newShipment = await newShipment.save();
    } catch (e) {
      console.log('Error creating from labels', e);
    }
    return newShipment;
  }

  async relaunchAndRelease(inventoryLocation) {
    /* This function is called after user selects location from pop-up when
    release to inventory is done.
    It creates a lazarus shipment and relases that shipment to invnetory
    */
    let label = this;
    const [invLocDetails] = await Locations.getOne({ id: inventoryLocation._id });
    if (invLocDetails.nestedLocation) {
      const commonStockProject = await Projects.getCommonStockProject();
      label.delivery.deliveryProject = commonStockProject;
    }

    if (!this._id || invLocDetails.nestedLocation) {
      // Do save for new shippig label
      label = await label.save();
    }
    if (this.status === 'in-transit') {
      // Do a receive first if shipment is in-transit
      label = await label.receive();
    }
    if (label.delivery.currentProject._id !== label.delivery.deliveryProject._id) {
      const newShipment = await Shipping
        .get({
          shippingId: label.relayedToShipment,
          projectId: label.delivery.deliveryProject._id,
        });
      [label] = newShipment.data;
    }
    label._delivery.deliveryLocation = inventoryLocation;
    try {
      // Relaunch and Receive label
      const shipData = await label.relaunch();
      const result = await shipData.releaseToInventory();
      return result;
    } catch (error) {
      console.log('error', error);
      throw new Error('Error while releasing to inventory!');
    }
  }

  async appendDefaultForms(force) {
    if (this._id && !force) return;
    const params = { useInShipping: true };
    let defaultForms = (await Todo.getAllTemplateForms(params)).data;
    defaultForms = defaultForms.filter(f => f.useInShipping);
    if (_.isEmpty(defaultForms)) return;
    const newForms = defaultForms.map((form) => {
      const newForm = {
        url: uuid(),
        uuid: uuid(),
        copiedFrom: form._id,
        name: form.templateName,
        type: 'form',
        formData: form.formData,
        sources: [{
          type: 'shipping',
        }],
        visible: 'to-all',
        archived: { value: false },
      };
      return newForm;
    });
    this.files.push(...newForms);
  }
}
export default ShippingLabel;
