// store module for scan qr

const qrScan = {
  namespaced: true,
  state: {
    prevUrl: { url: '' },
    orderLevel: false,
    separatePdf: false,
  },
  getters: {
    showPrevUrl: (state) => state.prevUrl.url,
  },
  mutations: {
    setUrl: (state, val) => {
      state.prevUrl.url = val;
    },
    setOrderLevelQr: (state, val) => {
      state.orderLevel = val;
    },
    setSeparatePdf: (state, val) => {
      state.separatePdf = val;
    },

  },
  actions: {
    setPrevUrl: ({ commit }, url) => {
      commit('setUrl', url);
    },
    setOrderLvlQr: ({ commit }, val) => {
      commit('setOrderLevelQr', val);
    },
    setSeperateQr: ({ commit }, val) => {
      commit('setSeparatePdf', val);
    },
    qrsBasedOnUserPref: ({ commit }, val) => {
      commit('setOrderLevelQr', val);
      commit('setSeparatePdf', val);
    },

  },
};

export default qrScan;
