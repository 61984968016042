export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: false,
  rearrangeCols: false,
  stickeyHeaders: true,
  noDataText: '',
  fields: [
    {
      name: 'field-LmvCarousel',
      id: 'previous',
      width: '40px',
      title: '',
      defaultVisible: true,
      show: true,
      customHeader: true,
      headerIcon: 'icon-disclosearrowleft',
      type: 'previous',
    },
    {
      name: 'project',
      id: 'filteredProjects',
      title: 'Project',
      width: '296px',
      show: true,
      sortField: 'project',
      defaultVisible: true,
      searchable: true,
      filterText: 'Projects',
    },
    {
      name: 'Company',
      title: 'Company',
      width: '296px',
      show: true,
      sortField: 'name',
      defaultVisible: true,
      searchable: true,
      id: 'companies',
      filterText: 'Companies',
    }, {
      title: 'All Locations',
      name: 'locations',
      searchable: true,
      filterText: 'locations',
      id: 'locations',
      width: '296px',
      defaultVisible: true,
    },
    {
      title: 'Stage',
      name: 'stage',
      searchable: true,
      filterText: 'Stages',
      id: 'lmvStage',
      width: '152px',
      defaultVisible: true,
    },
    {
      name: 'status',
      id: 'orderStatus',
      title: 'Status',
      width: '196px',
      sortField: 'status',
      searchable: true,
      filterText: 'Status',
      defaultVisible: true,
    }, {
      title: 'Coordinate By',
      name: 'field-date',
      searchable: true,
      dateFilter: 'coord',
      kind: 'dates',
      width: '152px',
      id: 'coord',
      inputProps: {
        kind: 'coord',
        disableEdit: false,
      },
    }, {
      title: 'Detail By',
      name: 'field-date',
      searchable: true,
      dateFilter: 'detailBy',
      prop: 'detailBy',
      kind: 'dates',
      width: '156px',
      id: 'detailBy',
      inputProps: {
        kind: 'detailBy',
        disableEdit: false,
      },
    }, {
      title: 'Manufacture By',
      name: 'field-date',
      searchable: true,
      dateFilter: 'manufactreBy',
      prop: 'manufactreBy',
      kind: 'dates',
      width: '152px',
      id: 'manufactreBy',
      inputProps: {
        kind: 'manufactreBy',
        disableEdit: false,
      },
    }, {
      title: 'QA By',
      name: 'field-date',
      searchable: true,
      dateFilter: 'qaBy',
      prop: 'qaBy',
      kind: 'dates',
      width: '152px',
      id: 'qaBy',
      inputProps: {
        kind: 'qaBy',
        disableEdit: false,
      },
    }, {
      title: 'Onsite',
      name: 'field-date',
      searchable: true,
      dateFilter: 'deliver',
      prop: 'deliver',
      kind: 'dates',
      width: '152px',
      id: 'deliver',
      inputProps: {
        kind: 'deliver',
        disableEdit: false,
      },
    }, {
      title: 'Vendor',
      name: 'field-vendor-select',
      searchable: true,
      filterText: 'suppliers',
      id: 'suppliers',
      width: '296px',
    }, {
      title: 'Submittal',
      name: 'submittals',
      searchable: true,
      filterText: 'submittals',
      id: 'submittals',
      width: '296px',
    }, {
      title: 'Owner',
      name: 'field-user-select',
      searchable: true,
      filterText: 'owners',
      id: 'owners',
      width: '296px',
    }, {
      title: 'Recipient',
      name: 'field-user-select',
      searchable: true,
      filterText: 'recipients',
      id: 'recipients',
      width: '296px',
    },
    {
      name: 'field-LmvCarousel',
      id: 'next',
      width: '40px',
      title: '',
      defaultVisible: true,
      show: true,
      customHeader: true,
      headerIcon: 'icon-disclosearrowright',
      type: 'next',
      alwaysVisible: true,
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      searchable: true,
      clearBtn: true,
      hideAllFilters: false,
    },
  ],
};
