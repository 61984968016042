<template>
  <o-modal :active="isActive" :canCancel="false" class="modal-xs">
    <div class="modal-card">
      <header class="modal-card-head is-borderless">
        <p class="card-header-title is-justify-content-left has-text-black-bis">
          Select On-Site Date
        </p>
      </header>
      <section class="modal-card-body is-visible has-text-black-bis schedule-date">
         <field-generic-input
            label="Date: "
            :value="formatedDate"
            placeholder="MM/DD/YYYY"
            class="has-text-weight-bold is-flex is-justify-content-center"
            :disabled="true"
            :isEditing="true"
          ></field-generic-input>
          <div class="is-flex is-justify-content-center">
            <o-datepicker
              v-model="deliver"
              class=""
              inline
              :placeholder="dateFormat"
            >
            </o-datepicker>
          </div>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
          <button class="button is-outlined" @click="closeModal">
            Cancel
          </button>
          <button class="button has-background-black-bis"
            :disabled="!deliver"
            @click="updateSchedule">
              OK
          </button>
      </footer>
    </div>
  </o-modal>
</template>
<script>
import { computed, reactive, toRefs } from 'vue';
import moment from 'moment';

export default {
  props: {
    isActive: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      option: '',
      deliver: null,
      fromDateFormatted: null,
    });

    const message = computed(() => "An Activity's new On-Site date should be:");
    const formatedDate = computed(() => {
      if (state.deliver) {
        return moment(state.deliver).format('MM/DD/YYYY');
      }
      return null;
    });

    const methods = {
      closeModal() {
        state.option = false;
        emit('close-modal');
        emit('close');
      },
      updateSchedule() {
        emit('update-schedule', { value: state.deliver });
        emit('close');
      },
    };

    return {
      ...toRefs(state),
      ...methods,
      message,
      formatedDate,
    };
  },
};
</script>
<style scoped>
.modal-xs .modal-card {
  width: 360px !important;
}
</style>
