export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  isCheckable: true,
  hideDefaultSortIcon: true,
  noDataText: 'Please select a Prefab Package',
  fields: [
    {
      name: 'name',
      width: '20%',
      title: 'Item Name',
      id: 'name',
      sortField: 'name',
      defaultVisible: true,
    },
    {
      name: 'customId',
      width: '20%',
      title: 'Item ID',
      id: 'customId',
      prop: 'customId',
    },
    {
      name: 'costCode',
      width: '20%',
      title: 'Cost Code',
      prop: 'costCode',
      id: 'costCode',
    },
    {
      name: 'field-install-locs-icon',
      title: 'Install Location',
      width: '20%',
      id: 'installLocation',
      sortField: 'installLocation',
    },
    {
      name: 'quantity',
      width: '20%',
      title: 'QTY',
      id: 'quantity',
      prop: 'quantity',
    },
  ],
};
