<template>
  <o-modal :active="showModal" :canCancel="false"
    class="modal-sm qa-modal has-text-black-bis">
    <qa-qc-modal
      :form="form"
      :preview="true"
      @close="onClose">
    </qa-qc-modal>
  </o-modal>
</template>

<script>
import { onBeforeMount, reactive, toRefs } from 'vue';
import QaQcModal from '@/components/modals/QaQcModal';
import Form from '@/models/Form';
import { useRouter } from 'vue-router';

export default {
  components: {
    QaQcModal,
  },
  props: {
    formId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      showModal: false,
      form: null,
    });
    onBeforeMount(async () => {
      [state.form] = (await Form.get({
        formId: props.formId,
      })).data;
      state.showModal = true;
    });
    const onClose = () => {
      window.close();
    };
    return {
      ...toRefs(state),
      onClose,
    };
  },
};
</script>

<style>

</style>
