export default {
  stickeyHeaders: true,
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  stickeyHeaders: true,
  rearrangeCols: false,
  isCheckable: true,
  customRowKey(selectedBulkAction, option) {
    const keysToMap = {
      activeSupplier: 'name',
      costCode: 'name',
    };
    return keysToMap[option] || '_id';
  },
  isCardView: true,
  fields: [
    {
      name: 'orderName',
      title: 'New Orders by Vendor',
      width: '400px',
      id: 'name',
      prop: 'name',
      sticky: true,
      sortField: 'name',
      defaultVisible: true,
    },
    {
      name: 'location-select',
      width: '256px',
      prop: 'commonLoc._name',
      title: 'Location *',
      cardProps: {
        companyId: 'created.by.company._id',
        projectId: 'project._id',
      },
    },
    {
      name: 'field-generic-input',
      width: '100px',
      title: '# Items',
      prop: 'count',
      id: 'items',
      defaultVisible: true,
    },
    {
      name: 'field-date',
      width: '152px',
      title: 'New On-site',
      prop: 'earliestOnsite',
      id: 'on-site',
      isEdit: true,
      inputProps: {
        pos: 'is-bottom-right',
        kind: 'earliestOnsite',
      },
      tdClass: 'td-date-field',
    },
  ],
};
