<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <TableGutter
    :selectedAction="isBulkActionEnabled"
    :tools="tableProps.gutterOpts"
    @cellClicked="eventHandler"
    @cc-modal-closed="triggerSetHeaderHeight"
    @re-render-table="rerenderMfTable += 1"
  >
    <template v-slot:viewModel>
      <div class="column is-narrow is-flex pr-0">
        <button class="button has-background-black-bis
         has-text-white is-size-5 has-text-weight-bold pl-0"
          :disabled="isBulkActionEnabled !== null"
          @click="viewModelOpen = true">
          <i class="icon-linked-model"></i>
          View Model</button>
        <div class="is-divider-vertical"></div>
      </div>
    </template>
    <template v-slot:selectView>
      <div class="column is-2 is-3-desktop pl-0 pr-0">
        <div class="columns is-align-items-center">
          <div class="column is-narrow">
            <label class="label has-text-black-bis is-size-3 is-italic has-text-weight-bold">
              View As:
            </label>
          </div>
          <div class="column">
            <mf-multi-select
              :disabled="isBulkActionEnabled !== null"
              :modelValue="getSelectedVal()"
              track-by="type"
              label="label"
              :multiple="false"
              :options="viewOptions"
              :allow-empty="false"
              @update:modelValue="setView($event)"
            ></mf-multi-select>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:selectStock>
      <div class="column is-narrow is-flex" v-if="viewAs.type === 'order-view'">
        <div class="is-divider-vertical"></div>
        <o-switch v-model="showEmpty">
          <span class="has-text-black-bis is-size-3 is-italic has-text-weight-bold">
            Show Empty Orders
          </span>
        </o-switch>
      </div>
    </template>
  </TableGutter>
  <order-view ref="orderView" v-if="view === 'order-view'" :showEmpty="showEmpty"
    :key="rerenderMfTable"></order-view>
  <item-view ref="itemView" v-if="view === 'item-view'" :key="rerenderMfTable"></item-view>
  <lmv-project-modal
    v-if="viewModelOpen"
    :isActive="viewModelOpen"
    @close="viewModelOpen = false">
  </lmv-project-modal>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref,
  computed,
  onMounted,
  inject,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import orderViewCols from '@/components/table-cols/shippingOrderViewCols';
import LmvProjectModal from '@/components/modals/LmvProjectModal.vue';
import ShippingListOrderView from '@/views/ShippingListOrderView.vue';
import ShippingListItemView from '@/views/ShippingListItemView.vue';
import TableGutter from '@/components/TableGutter.vue';
import { findIndex } from 'lodash';
import ExcelExportMixin from '@/components/mixins/ExcelExportMixin';

export default defineComponent({
  name: 'shipping-list',
  components: {
    LmvProjectModal,
    'order-view': ShippingListOrderView,
    'item-view': ShippingListItemView,
    TableGutter,
  },
  props: {
    view: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { excelExport } = ExcelExportMixin();
    const orderView = ref(null);
    const itemView = ref(null);
    const isBulkActionEnabled = computed(() => {
      if (props.view === 'order-view') {
        return orderView?.value?.$refs.shippingTable?.selectedBulkAction;
      }
      return itemView?.value?.$refs.shippingTable?.selectedBulkAction;
    });
    const viewOptions = [{
      label: 'Orders',
      type: 'order-view',
    }, {
      label: 'Items',
      type: 'item-view',
    }];

    const state = reactive({
      isLoading: false,
      showEmpty: false,
      tableProps: orderViewCols,
      viewAs: viewOptions[findIndex(viewOptions, ['type', props.view])],
      viewModelOpen: false,
      rerenderMfTable: 0,
      deliveryNotes: {
        isSlideNotesActive: false,
        isFileListVisible: false,
        selectedItem: {},
        isDisabled: true,
      },
    });

    const setView = async (view) => {
      state.viewAs = view;
      const key = 'selectedList';
      store.commit('setQueryParams', { [key]: view.type });
    };

    const eventHandler = async (eventType) => {
      if (eventType === 'excelExport') await excelExport();
    };

    const getSelectedVal = () => ({
      label: store.state.queryParams.selectedList === 'order-view' ? 'Orders' : 'Items',
      type: store.state.queryParams.selectedList,
    });

    watch(
      () => store.state.queryParams.selectedList,
      (newVal, oldVal) => {
        store.commit('setQueryParams', {
          oldselectedList: oldVal,
        });
        if (newVal !== '' && newVal !== props.view) {
          router.push({ path: `/logistics/shipping/${newVal}` });
        }
      },
    );
    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      if (props.view === 'order-view') {
        // eslint-disable-next-line no-unused-expressions
        orderView?.value?.$refs.shippingTable?.closeColumnChooserModal();
         // eslint-disable-next-line no-unused-expressions
         orderView?.value?.$refs.shippingTable?.refreshFilterOpts();
      } else {
        // eslint-disable-next-line no-unused-expressions
        itemView?.value?.$refs.shippingTable?.closeColumnChooserModal();
      }
    };

    onMounted(async () => {
      await store.getters.userPromise;
      if (store.state.queryParams.selectedList !== props.view) {
        store.commit('setQueryParams', {
          selectedList: props.view,
        });
      }
    });
    return {
      ...toRefs(state),
      viewOptions,
      setView,
      eventHandler,
      orderView,
      itemView,
      isBulkActionEnabled,
      triggerSetHeaderHeight,
      getSelectedVal,
    };
  },
});
</script>

<style scoped lang="sass"></style>
