import * as _ from 'lodash';

const itemsImport = [
  {
    name: 'field-generic-input',
    title: 'Name',
    prop: 'name',
    width: '400px',
    sticky: true,
    inputProps: {
      type: 'text',
    },
  },
  {
    name: 'field-generic-input',
    title: 'Quantity',
    prop: 'quantity',
    width: '112px',
    inputProps: {
      type: 'text',
    },
  },
  {
    name: 'field-generic-input',
    title: 'Measure',
    prop: 'measure',
    width: '112px',
    inputProps: {
      type: 'text',
    },
  },
  {
    name: 'field-generic-input',
    title: 'Measure Units',
    prop: 'measureUnits',
    width: '152px',
    inputProps: {
      type: 'text',
    },
  },
];

const runsImport = [
  {
    name: 'field-generic-input',
    title: 'Name',
    prop: 'name',
    sticky: true,
    width: '400px',
    inputProps: {
      type: 'text',
    },
  },
  {
    name: 'field-generic-input',
    title: 'Owner',
    prop: 'owner',
    width: '296px',
    inputProps: {
      type: 'text',
    },
  },
  {
    name: 'field-generic-input',
    title: 'Hours',
    prop: 'hours',
    width: '112px',
    inputProps: {
      type: 'text',
    },
  },
  {
    name: 'field-generic-input',
    title: 'Location',
    prop: 'location',
    width: '296px',
    inputProps: {
      type: 'text',
    },
  },
];

const checklistsImport = [
  {
    name: 'field-generic-input',
    title: 'Description',
    prop: 'text',
    width: '400px',
    inputProps: {
      type: 'text',
    },
  },
  {
    name: 'field-generic-input',
    title: 'Assignee',
    prop: 'assignee',
    width: '296px',
    inputProps: {
      type: 'text',
    },
  },
];

export default Object.freeze({
  kitdetails: {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'ID',
        prop: 'financialId',
        disableEdit: true,
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'name',
        prop: 'name',
        disableEdit: true,
        sticky: true,
        width: '400px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Type',
        prop: 'purpose',
        width: '152px',
        disableEdit: true,
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-date',
        title: 'Coordinate',
        width: '152px',
        inputProps: {
          kind: 'coord',
          disableEdit: true,
        },
      },
      {
        name: 'field-date',
        title: 'Detail By',
        width: '152px',
        inputProps: {
          kind: 'detailBy',
          disableEdit: true,
        },
      },
      {
        name: 'field-date',
        title: 'Manufacture',
        width: '152px',
        inputProps: {
          kind: 'manufactureBy',
          disableEdit: true,
        },
      },
      {
        name: 'field-date',
        title: 'QA',
        width: '152px',
        inputProps: {
          kind: 'qaBy',
          disableEdit: true,
        },
      },
      {
        name: 'field-date',
        title: 'Onsite',
        width: '152px',
        inputProps: {
          kind: 'deliver',
          disableEdit: true,
        },
      },
      {
        name: 'field-stage',
        id: 'stage',
        title: 'Stage',
        width: '152px',
        show: true,
        sortField: 'stage',
        defaultVisible: true,
        iPadVisible: true,
        inputProps: {
          stageColor: true,
        },
      },
    ],
  },
  'mass-update-dates': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'order-name',
        title: 'Order Name',
        width: '400px',
        id: 'name',
        sticky: true,
      },
      {
        name: 'field-date',
        width: '152px',
        title: 'Coordinate',
        prop: 'date',
        id: 'coord-date',
        defaultVisible: true,
        iPadVisible: true,
        inputProps: {
          type: 'mf-date',
          kind: 'coord',
        },
      },
      {
        name: 'field-date',
        width: '152px',
        title: 'Detail By',
        prop: 'date',
        id: 'detailBy',
        iPadVisible: true,
        defaultVisible: true,
        inputProps: {
          type: 'mf-date',
          kind: 'detailBy',
          isManager: true,
        },
      },
      {
        name: 'field-date',
        width: '152px',
        title: 'Manufacture',
        prop: 'date',
        id: 'manufactureBy',
        inputProps: {
          type: 'mf-date',
          kind: 'manufactureBy',
          isManager: true,
        },
      },
      {
        name: 'field-date',
        width: '152px',
        title: 'QA',
        prop: 'date',
        id: 'qaBy',
        iPadVisible: true,
        defaultVisible: true,
        inputProps: {
          type: 'mf-date',
          kind: 'qaBy',
          isManager: true,
        },
      },
      {
        name: 'field-date',
        title: 'Onsite',
        width: '152px',
        prop: 'date',
        id: 'deliver-date',
        inputProps: {
          type: 'mf-date',
          kind: 'deliver',
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Message',
      },
    ],
  },

  'kits-excel-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Kit ID',
        prop: 'customId',
        disableEdit: true,
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Name',
        prop: 'name',
        sticky: true,
        width: '400px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-date',
        title: 'Coordinate',
        width: '152px',
        inputProps: {
          kind: 'coord',
          disableEdit: true,
        },
      },
      {
        name: 'detailBy',
        title: 'Detail By',
        width: '152px',
      },
      {
        name: 'field-date',
        title: 'Assembly Manuf',
        width: '152px',
        inputProps: {
          kind: 'partsManufactureBy',
          disableEdit: true,
        },
      },
      {
        name: 'manufactureBy',
        title: 'Manufacture',
        width: '152px',
      },
      {
        name: 'field-date',
        title: 'Onsite By',
        width: '152px',
        inputProps: {
          kind: 'deliver',
          disableEdit: true,
        },
      },
      {
        name: 'field-generic-input',
        title: 'Owner',
        prop: 'prefabOwner',
        width: '296px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },

  'multi-assembly-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Kit ID',
        prop: 'customId',
        disableEdit: true,
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Assembly Catalog Id',
        prop: 'catId',
        disableEdit: true,
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Quantity',
        prop: 'quantity',
        disableEdit: true,
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },

  'prefabs-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Name',
        prop: 'name',
        sticky: true,
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-date',
        title: 'Coordinate',
        width: '152px',
        inputProps: {
          kind: 'coord',
          disableEdit: true,
        },
      },
      {
        name: 'field-date',
        title: 'Detail By',
        width: '152px',
        inputProps: {
          kind: 'poDetailBy',
          disableEdit: true,
        },
      },
      {
        name: 'field-date',
        title: 'Onsite By',
        width: '152px',
        inputProps: {
          kind: 'deliver',
          disableEdit: true,
        },
      },
      {
        name: 'field-generic-input',
        title: 'Owner',
        prop: 'prefabOwner',
        width: '196px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
        isDisabled: true,
      },
    ],
  },

  'projects-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Project ID',
        prop: 'proId',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Name',
        prop: 'name',
        sticky: true,
        width: '400px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Member',
        prop: 'email',
        width: '196px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Location',
        prop: 'jobsiteName',
        width: '296px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Street',
        prop: 'street',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'City',
        prop: 'city',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'State',
        prop: 'state',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Zip Code',
        prop: 'zipcode',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Project Inventory Location',
        prop: 'projInvLoc',
        width: '296px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },
  'materials-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Name',
        prop: 'name',
        sticky: true,
        width: '400px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Owner',
        prop: 'materialOwner',
        width: '296px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-date',
        title: 'Onsite By',
        width: '152px',
        inputProps: {
          kind: 'onSiteBy',
          disableEdit: true,
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },

  'pm-runs-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      ...runsImport,
      ...[
        {
          name: 'field-date',
          title: 'Start Date',
          width: '152px',
          inputProps: {
            kind: 'startDate',
            disableEdit: true,
          },
        },
        {
          name: 'field-date',
          title: 'Finish Date',
          width: '152px',
          inputProps: {
            kind: 'finishDate',
            disableEdit: true,
          },
        },
        {
          name: 'field-generic-input',
          width: '196px',
          title: 'Status',
          prop: '_errorMsg',
        },
      ],
    ],
  },

  'tpm-runs-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      ...runsImport,
      ...[
        {
          name: 'field-generic-input',
          width: '196px',
          title: 'Status',
          prop: '_errorMsg',
        },
      ],
    ],
  },

  'prefab-items-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      ...itemsImport,
      ...[
        {
          name: 'field-date',
          title: 'Coordination',
          width: '152px',
          inputProps: {
            kind: 'coordDate',
            disableEdit: true,
          },
        },
        {
          name: 'field-date',
          title: 'Onsite By',
          width: '152px',
          inputProps: {
            kind: 'deliverDate',
            disableEdit: true,
          },
        },
        {
          name: 'field-generic-input',
          width: '196px',
          title: 'Status',
          prop: '_errorMsg',
        },
      ],
    ],
  },

  'mm-items-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      ...itemsImport,
      ...[
        {
          name: 'field-generic-input',
          title: 'Catalog ID',
          prop: 'catId',
          width: '152px',
          inputProps: {
            type: 'text',
          },
        },
        {
          name: 'field-date',
          title: 'Order By',
          width: '152px',
          inputProps: {
            kind: 'orderBy',
            disableEdit: true,
          },
        },
        {
          name: 'field-date',
          title: 'Ship By',
          width: '152px',
          inputProps: {
            kind: 'shipBy',
            disableEdit: true,
          },
        },
        {
          name: 'field-generic-input',
          title: 'Status',
          width: '196px',
          prop: '_errorMsg',
        },
      ],
    ],
  },

  'tmm-items-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      ...itemsImport,
      ...[
        {
          name: 'field-generic-input',
          title: 'Catalog ID',
          prop: 'catId',
          width: '152px',
          inputProps: {
            type: 'text',
          },
        },
        {
          name: 'field-generic-input',
          width: '196px',
          title: 'Status',
          prop: '_errorMsg',
        },
      ],
    ],
  },

  'order-cl-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      ...checklistsImport,
      ...[
        {
          name: 'field-date',
          title: 'Due',
          width: '152px',
          inputProps: {
            kind: 'dueDate',
            disableEdit: true,
          },
        },
        {
          name: 'field-generic-input',
          width: '196px',
          title: 'Status',
          prop: '_errorMsg',
        },
      ],
    ],
  },

  'template-cl-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      ...checklistsImport,
      ...[
        {
          name: 'field-generic-input',
          width: '196px',
          title: 'Status',
          prop: '_errorMsg',
        },
      ],
    ],
  },

  'inventory-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Location',
        prop: 'location',
        width: '296px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Quantity',
        prop: 'quantity',
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Min Stock',
        prop: 'minQuantity',
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Max Stock',
        prop: 'maxQuantity',
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Catalog ID',
        prop: 'catId',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Measure',
        prop: 'measure',
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Measure Units',
        prop: 'measureUnits',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },

  'vendor-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Name',
        prop: 'name',
        sticky: true,
        width: '400px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'contactName',
        prop: 'contactName',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Email',
        prop: 'contactEmail',
        width: '152px',
        inputProps: {
          disableEdit: true,
        },
      },
      {
        name: 'field-generic-input',
        title: 'Phone',
        prop: 'phone',
        width: '152px',
        inputProps: {
          disableEdit: true,
        },
      },
      {
        name: 'field-generic-input',
        title: 'Category',
        formatter(row) {
          if (!_.isEmpty(row)) {
            return row.toString();
          }
          return '';
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },

  'members-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Name',
        prop: 'firstName',
        sticky: true,
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Email',
        prop: 'email',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Phone',
        prop: 'mobilePhone',
        width: '196px',
        inputProps: {
          disableEdit: true,
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },

  'category-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Name',
        prop: 'name',
        sticky: true,
        width: '400px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },

  'tag-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Name',
        prop: 'name',
        sticky: true,
        width: '400px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },

  'assemblyParts-import': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'field-generic-input',
        title: 'Id',
        prop: 'customId',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Name',
        prop: 'name',
        sticky: true,
        width: '400px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'CatId',
        prop: 'catId',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Status',
        prop: '_errorMsg',
      },
    ],
  },
  'ms-mass-update-dates': {
    stickeyHeaders: true,
    isCardView: true,
    fields: [
      {
        name: 'order-name',
        title: 'Order Name',
        width: '400px',
        sticky: true,
        id: 'name',
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Fab/Order',
        prop: 'date',
        id: 'orderBy',
        defaultVisible: true,
        iPadVisible: true,
        inputProps: {
          type: 'mf-date',
          kind: 'orderBy',
        },
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Available',
        prop: 'date',
        id: 'availableBy',
        iPadVisible: true,
        defaultVisible: true,
        inputProps: {
          type: 'mf-date',
          kind: 'available',
        },
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Ship',
        prop: 'date',
        id: 'shipBy',
        inputProps: {
          type: 'mf-date',
          kind: 'shipBy',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Onsite',
        width: '152px',
        prop: 'date',
        id: 'deliver-date',
        inputProps: {
          type: 'mf-date',
          kind: 'deliver',
        },
      },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Message',
        prop: '_errorMsg',
      },
    ],
  },
  'schedule-excel-import': {
    fields: [
      {
        name: 'field-generic-input',
        title: 'Activity Name',
        prop: 'activityName',
        width: '400px',
        disableEdit: true,
        inputProps: {
          type: 'text',
        },
      }, {
        name: 'field-generic-input',
        title: 'Activity Id',
        prop: 'activityId',
        width: '112px',
        disableEdit: true,
        inputProps: {
          type: 'text',
        },
      }, {
        name: 'field-generic-input',
        title: 'Onsite',
        width: '152px',
        prop: 'date',
        id: 'deliver-date',
        inputProps: {
          type: 'mf-date',
          kind: 'deliver',
        },
      },
      {
        name: 'field-generic-input',
        width: '112px',
        title: 'Message',
        prop: '_errorMsg',
      },
    ],
  },
  'prodorders-excel-import': {
    fields: [
      {
        name: 'field-generic-input',
        title: 'Template',
        prop: 'templateId',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Order Name',
        prop: 'name',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Order ID',
        prop: 'uniqueOrderId',
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Message',
        prop: '_errorMsg',
      },
    ],
  },
  'prodOrderItems-excel-import': {
    fields: [
      {
        name: 'field-generic-input',
        title: 'Item Name',
        prop: 'name',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Order ID',
        prop: 'uniqueOrderId',
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Qunatity',
        prop: 'quantity',
        width: '75px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Catalog Id',
        prop: 'catId',
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Message',
        prop: '_errorMsg',
      },
    ],
  },
  'cut-item-import': {
     fields: [
      {
        name: 'field-generic-input',
        title: 'Item Name',
        prop: 'name',
        width: '152px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Order ID',
        prop: 'uniqueOrderId',
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Qunatity',
        prop: 'quantity',
        width: '75px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        title: 'Catalog Id',
        prop: 'catId',
        width: '112px',
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Message',
        prop: '_errorMsg',
      },
    ],
  }
});
