import {
  flatMap, concat, filter, get, isEmpty,
} from 'lodash';
import ShippingLabel from './ShippingLabel';
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async update(card) {
    try {
      const params = {
        inLineEdit: true,
        projectId: card.project._id,
        recipient: card.delivery.recipient._id,
        owner: card.delivery.owner._id,
        trackingId: card.delivery.trackingId,
        externalEmails: card.delivery.externalEmails,
        shippingLabelId: card._id,
        transitId: card.delivery._id,
        name: card.name,
        files: card.files.length ? card.files : [],
        notify: flatMap(card.delivery.notify, '_id'),
        notes: card.delivery.notes,
        deliveryStart: card.delivery.deliveryStart,
        deliverBy: card.delivery.deliverBy,
        currentLocation: card.delivery.currentLocation._id,
        deliveryLocation: card.delivery.deliveryLocation._id,
      };
      if (!isEmpty(card.minShipDate)) {
        params.deliveryStart = card.minShipDate;
      }
      if (!isEmpty(card.maxdeliverDate)) {
        params.deliverBy = card.maxdeliverDate;
      }
      const { data: shipping } = await req.post('shipping/transit/update', params);
      return new ShippingLabel(shipping);
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      return {};
    }
  },
  async get(params = {}) {
    params = {
      limit: 25,
      page: 1,
      status: ['not-started', 'in-transit', 'in-storage', 'fulfilled'],
      ...params,
    };
    try {
      const { data: shipping } = await req.get('shipping/get', { params });
      shipping.data = shipping.data.map((s) => {
        s.files = s.files.filter(f => !f.archived.value);
        return new ShippingLabel(s);
      });
      return shipping;
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
  async getItemView(params) {
    try {
      const { data: shipping } = await req.get('shipping/getItemView', { params });
      shipping.data = shipping.data.map(s => new ShippingLabel(s));
      return shipping;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async createDeliveries(params) {
    const { data } = await req.post('shipping/partial/createDeliveries', params);
    return data;
  },
  async addTransit(params = {}) {
    try {
      params.files = filter(params.files, f => !f.master);
      const { data: shipping } = await req.post('shipping/transit/add', params);
      return new ShippingLabel(shipping);
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
  async updateTransit(params = {}) {
    try {
      const { data: shipping } = await req.post('shipping/transit/update', params);
      return new ShippingLabel(shipping);
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
  async addFromPO(params = {}) {
    try {
      const { data: shipping } = await req.post('/shipping/add/fromPOs', params);
      return new ShippingLabel(shipping);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async receiveAll(params = {}) {
    try {
      const { data } = await req.post('/shipping/receive/all', params);
      return new ShippingLabel(data);
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
  async create(ship) {
    try {
      const { data: shipment } = await req.post('/shipping/new', ship);
      return new ShippingLabel(shipment);
    } catch (e) {
      console.log(e);
      throw (e);
    }
  },
  async split(params = {}) {
    try {
      const { data } = await req.post('/shipping/split', params);
      return new ShippingLabel(data);
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
  async createPurchaseReceipts(params) {
    try {
      const { data: shipments } = await req.post('/shipping/purchaseReceipts', params);
      shipments.purchaseLabel = shipments.purchaseLabel.map(s => new ShippingLabel(s));
      return shipments;
    } catch (e) {
      console.log(e);
      throw (e);
    }
  },
  async getPurchaseShippingOrders(params) {
    try {
      const { data: shipping } = await req.get('/shipping/getPurchaseShippingOrders', { params });
      shipping.data = shipping.data.map((s) => {
        s.files = s.files.filter(f => !f.archived.value);
        return new ShippingLabel(s);
      });
      return shipping;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getCompletedItems(params) {
    try {
      const { data } = await req.get(
        `/materials/getCompletedItems/${params._id}`,
        { params: { projectId: params.project._id || '' } },
      );
      return data;
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
  // async markFulfilled(params) {
  //   A shipment can now be marked fulfilled using the 'receiveAll' call itself by
  //   setting 'isFinal' param to true
  // },
  async markConsumed(params) {
    try {
      const { data } = await req.post('/shipping/markConsumed', params);
      return new ShippingLabel(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async splitFromLabels(params) {
    try {
      const { data } = await req.post('/shipping/add/fromOtherLabels', params);
      return new ShippingLabel(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async removeItems(params) {
    try {
      const { data } = await req.post('/shipping/remove/items', params);
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async updateShippingDetails(params) {
    try {
      const { data } = await req.post('/shipping/update/shippingDetails/', params);
      return new ShippingLabel(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async cancelShipping(params) {
    try {
      const { data } = await req.post('/shipping/transit/cancel', params);
      return new ShippingLabel(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getDetailedLabel(params) {
    try {
      const { data } = await req.get(`/shipping/details/${params.shippingId}`, { params });
      return new ShippingLabel(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async unreserveItems(params) {
    try {
      const { data } = await req.post('/shipping/unreserve', params);
      if (data.labels) {
        Object.assign(data, {
          labels: data.labels.map(label => new ShippingLabel(label)),
        });
      }
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async releaseToInventory(params) {
    try {
      const { data } = await req.post('/shipping/release', params);
      return new ShippingLabel(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getShippingLabel(params) {
    try {
      const { data } = await req.get(`/qr/shippinglabel/${params.shippingId}`, { params });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async breakShipmentIntoPartials(params) {
    try {
      const { data } = await req.post('/shipping/partial/save', params);
      const newShipment = new ShippingLabel(data.mLabel);
      Object.assign(newShipment, {
        partialShipments: data.partials.map((p) => {
          p.itemSources = newShipment.itemSources;
          p.files = concat(data.mLabel.files, p.files);
          return new ShippingLabel(p);
        }),
        summary: newShipment.summary,
      });
      return newShipment;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async relaunch(params) {
    try {
      const { data } = await req.post('/shipping/revive', params);
      return new ShippingLabel(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getPartialShipments(params) {
    try {
      if (params.shippingLabelId === 'undefined' || isEmpty(params.shippingLabelId)) {
        return {};
      }
      const { data } = await req.get('/shipping/partial/get', { params });
      if (data.partials.length === 0
        && data.mLabel
        && data.mLabel.shipType === 's-m') {
        data.partials.push(data.mLabel);
      }
      data.mLabel.files = data.mLabel.files.map((f) => {
        f.master = true;
        return f;
      });
      const newShipment = new ShippingLabel(data.mLabel);
      Object.assign(newShipment, {
        partialShipments: data.partials.map((p) => {
          p.items = filter(p.items, item => item.quantity);
          p.files = data.mLabel.files;
          p.itemSources = newShipment.itemSources;
          return new ShippingLabel(p);
        }),
      });
      return newShipment;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async removeItemsFromPartial(params) {
    try {
      const { data } = await req.post('/shipping/partial/removeItems', params);
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async generatePdf(params) {
    try {
      const { data } = await req.post('reportings/addPdfToQueue', { params });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async archive(id) {
    try {
      const { data } = await req.post('shipping/archiveLabel', { id });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async consumeAndUnreserve(params) {
    try {
      const orderId = get(params, 'order._id', '');
      const { data } = await req.post(`shipping/consumeAndUnreserve/${orderId}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async reserveCatalog(params) {
    try {
      const { data } = await req.post(`shipping/reserveMaterials/${params.cardId}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async getLinkedShippingOrders(params) {
    try {
      const { data } = await req.get(`shipping/linkedShippingOrders/${params.cardId}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
};
