/* eslint-disable prefer-promise-reject-errors */
import { isFunction } from 'lodash';
import GenerateRequest from './resources';

const req = GenerateRequest();
const reqFromClass = new GenerateRequest();
const shouldComplete = 50; /* accepted value of file upload percentage complete
to avoid offline upload */
// const acceptedSpeed = 50; // acceptable speed (in kbps) to allow upload

export default {

  async uploadFormFile(companyId, formData) {
    try {
      const { data } = await req.post(`companies/addFormBuilderFile?company=${companyId}&type=companies&`, formData);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async uploadFile(url, form, onUpload = '') {
    return new Promise(async (resolve, reject) => {
      const cancelTokenSrc = reqFromClass.generateCancelToken();
      let timerElapsed = false;
      let percentDone = 0;
      setTimeout(() => {
        timerElapsed = true;
      }, 15000);
      try {
        const fileData = await req.post(url, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem('auth-token'),
          },
          cancelToken: cancelTokenSrc.token,
          onUploadProgress(progress) {
            percentDone = Math.round(((progress.loaded / progress.total) * 100));
            if ((percentDone < shouldComplete) && timerElapsed) {
              cancelTokenSrc.cancel('Internet connection is weak');
            }
            if (isFunction(onUpload)) {
              onUpload({
                percentDone,
                loaded: progress.loaded,
                total: progress.total,
              });
            }
          },
        });
        resolve(fileData);
      } catch (e) {
        if (reqFromClass.isCancel(e)) {
          reject({
            type: 'cancel',
            message: "Speed's weak, files are heavy. There's vomit on his shirt, Mom's spaghetti.",
          });
        }
        if (e.code === 'ECONNABORTED') {
          reject({ type: 'timeout', message: 'Server timeout. Time limit exceeded' });
        }
        reject(e);
      }
    });
  },

  async FileUpload(selectedFile, queryStr, path) {
    const formData = new FormData();
    const header = { headers: { 'Content-Type': 'multipart/form-data' } };
    formData.append('file', selectedFile);
    const response = await req.post(`${path}?${queryStr}`, formData, header);
    return response;
  },
};
