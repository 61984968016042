<template>
    <div>
    <VDropdown placement="right"
      :shown="popOver"
      :key="rowData._id"
      :autoHide="false"
    >
      <div class="is-clickable has-text-link" @click="popOver =! popOver">
        {{ $_.isEmpty(rowData.templateName) ? 'Select Template' : rowData.templateName }}
      </div>
      <template #popper>
        <div class="card modal-card">
          <header class="card-header  has-background-grey-lighter">
            <h4
              class="card-header-title is-size-3 has-text-black-bis pl-0
                  is-capitalized has-text-weight-bold"
            >
              Select Template
            </h4>
          </header>
          <section class="has-text-black card-content template-content is-marginless has-background-white">
            <div v-for="template in templateOrders?.data" :key="template._id" class="column pl-0">
              <input name="TemplateName" type="radio"
                :id="template._id"
                :checked="rowData.dataFromTemplate?._id === template._id"
                @click="setSelectedTemplate(template)"
                :value="template._id">
              <label :for="template._id">{{template.name}}</label>
            </div>
          </section>
          <footer>
            <div class="has-text-black is-size-5 footer-note is-primary">
                <span class="has-text-weight-bold">Note:</span>
                Template Date will override entered dates
            </div>
            <div class="footer-check">
              <o-checkbox v-model="selectedTempDetails.applyToAll">
                Apply Selection to All
              </o-checkbox>
            </div>
            <div class="is-flex card-footer is-justify-content-flex-end
              has-background-grey-lighter">
              <div class="buttons">
                <button
                  @click="clear()"
                  class="button is-outlined has-text-weight-bold"
                >
                  Cancel
                </button>
                <button
                  class="button has-text-weight-bold has-background-black-bis has-text-white"
                  :disabled="applyDisabled()"
                  @click="apply()"
                >
                  Apply
                </button>
              </div>
            </div>
          </footer>
        </div>
      </template>
    </VDropdown>
  </div>
</template>
<script>

import { reactive, toRefs } from 'vue';
import { set, isEmpty } from 'lodash';

export default {
  name: 'SelectTemplatePopupField',
  props: {
    rowData: Object,
    rowField: Object,
    templateOrders: Array,
  },
  setup(props, { emit }) {
    const state = reactive({
      popOver: false,
      selectedTempDetails: {
        selectedTemplate: {},
        applyToAll: false,
      },
    });

    const setSelectedTemplate = (selectedTempDetails) => {
      set(props.rowData, 'dataFromTemplate', selectedTempDetails);
      state.selectedTempDetails.selectedTemplate = selectedTempDetails;
    };

    const clear = () => {
      state.popOver = false;
      state.selectedTempDetails.selectedTemplate = {};
      state.selectedTempDetails.applyToAll = false;
      set(props.rowData, 'dataFromTemplate', {});
      emit('template-details', state.selectedTempDetails, props.rowData._id);
    };

    const apply = () => {
      state.popOver = false;
      emit('template-details', state.selectedTempDetails, props.rowData._id);
    };

    const applyDisabled = () => isEmpty(state.selectedTempDetails.selectedTemplate);

    return {
      ...toRefs(state),
      setSelectedTemplate,
      apply,
      clear,
      applyDisabled,
    };
  },
};
</script>
<style scoped>
  .card-content {
    height: 320px;
  }
  .card-footer {
    height: 104px;
    display: block;
  }
  ::v-deep(.o-chk) {
    float: unset;
  }
</style>
