<template>
<ItemQRScan
  v-if="showItemQr"
  :isActive="showItemQr"
  :cardId="cardId"
  :itemId="itemId"
  :name="name"
  :itemName="item.name"
  :customId="customId || item.customId"
  :rowData="order"
  qrType="204" @close="closeBtn"></ItemQRScan>
 <div>
     <Web-Qr-Code
      :width='screenWidth'
      :height='screenHeight'
      ref="qrComp"
      @qrCodeContent="bringUpCard($event)">
    </Web-Qr-Code>
</div>
</template>

<script>
import {
  defineComponent, reactive, toRefs,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { DialogProgrammatic } from '@/components/Dialog';
import WebQrCode from '@/components/WebQrScan.vue';
import Qr from '@/models/Qr';
import { isEmpty, isString } from 'lodash';
import Shipping from '@/models/Shipping';
import ItemQRScan from '@/components/modals/ItemQRScan';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'LoadQr',
  components: {
    WebQrCode,
    ItemQRScan,
  },
  props: [],
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const state = reactive({
      screenHeight: 0,
      screenWidth: 0,
      content: '',
      showItemQr: false,
      itemId: '',
      cardId: '',
      Project: '',
      stage: '',
      name: '',
      order: '',
      customId: '',
      item: {},
    });

    state.screenHeight = window.screen.height * window.devicePixelRatio;
    state.screenWidth = window.screen.width * window.devicePixelRatio;

    const viewCard = async (card, stage, isItem = false, qr = '') => {
      const routeNameMap = {
        Sourcing: 'sourcing-edit',
        Materials: 'material-edit',
        ProductionOrder: 'manager-edit',
      };
      if (!isItem) {
        const moduleName = routeNameMap[card.__t];
        const projectId = card.project._id;
        const cardId = card._id;
        const cardStage = moduleName === 'sourcing-edit' && stage === 'delivery' ? 'ordering'
          : card.getCardStage();
        const routeData = {
          newTab: '_blank',
          sameTab: '_self',
        };
        const routeObj = {
          name: moduleName,
          params: {
            module: moduleName,
            projectId,
            cardId,
            stage: cardStage,
          },
        };
        routeData.link = router.resolve(routeObj);
        window.open(routeData.link.href, routeData.sameTab);
      }
      if (isItem) {
        let itemId = undefined;
        const itemQrId = qr.substring(10, 14);
        if(isItem && !card.shipType ) {
          try {
				    const item = await Qr.getItemForQr({ baseorderId: card._id, itemId: itemQrId});
            itemId = item._id;
            state.item = item;
          } catch (error) {
            DialogProgrammatic.confirm({
                title: 'Error',
                message: error?.data?.message,
                okButton: 'OK',
                type: 'black-bis',
            });
            return router.push({path: store.getters['qrScan/showPrevUrl']});
          }
        } else {
          itemId = _.find(card.items, (item) => item._id.substring(item._id.length - 4) === itemQrId)?._id;
        }
        const moduleName = routeNameMap[card.__t];
        const cardId = card._id;
        state.name = card.name;
        state.itemId = itemId;
        state.cardId = cardId;
        state.customId = card.items[0]?.customId;
        state.order = card;
        state.showItemQr = true;
        const cardStage = moduleName === 'sourcing-edit' && stage === 'delivery' ? 'ordering' : card.getCardStage();
        const params = {
          itemId,
          cardId,
          qrType: '204',
        };
      }
    };

    const errorDialog = (errorMsg = '') => {
      if (isEmpty(errorMsg)) errorMsg = 'Currently this QR code is not supported';
      const confirmParam = {
        title: 'Error',
        message: `${errorMsg}`,
        okButton: 'Ok',
        onConfirm: () => {
          router.push({
            path: store.getters['qrScan/showPrevUrl'],
          });
        },
      };
      DialogProgrammatic.confirm(confirmParam);
    };

    // BRING UP CARD IF QR DETECTS A SUCCESSFULL SCAN
    const bringUpCard = async (content) => {
      /*
      * 1 - order
      * 2 - item
      * 3 - shipping
      */
      try {
        if (content[2] === '2' || content[2] === '5') {
          // 2 is for order api/v2 version and 5 for api/v3 versions

          const result = await Qr.getCode({qrCode: content});
          // we get the baseorder of the result order
          const cardStage = ['mixed', 'delivery', 'complete', 'rejected'].includes(result.stage)
            ? result.stage : result.getCardStage();
          if (!['planning', 'preparation', 'coordination'].includes(cardStage)) {
            // opening qr order...
            const compId = store.state.userData.company;
            if (compId !== result.owner.company._id) {
              return toast.error("Invalid Qr");
            }
            viewCard(result, cardStage);
          }
        } else if (content[2] === '1' || content[2] === '6') {
          const result = await Qr.getCode({qrCode: content});
          const cardStage = ['mixed', 'delivery', 'complete', 'rejected'].includes(result.stage)
            ? result.stage : result.getCardStage();
          if (!['planning', 'preparation', 'coordination'].includes(cardStage)) {
            // opening qr order...
            const isItem = true;
            viewCard(result, cardStage, isItem, content);
          }
        } else if (content[2] === '3' || content[2] === '4') {
          // Shipping QR Scan
          const itemQrId = content.substring(10, 14);
          const params = {
            shippingId: content.substr(45 - 24 + 1, 24),
            qrscan: true,
            projectId: store.state.queryParams._projects.map((i) => i._id),
            itemQrId: itemQrId,
          };
          const chosenLabel = await Shipping.getDetailedLabel(params);
          if (chosenLabel.shipType === 's') params.shippingId = chosenLabel.parentShipment;
          const projectId = chosenLabel.project._id;
          if (content[2] === '4') {
            const isItem = true;
            const getItemCustomId =  _.find(chosenLabel.items, (item) => item._id.substring(item._id.length - 4) === itemQrId);
            const item = await Qr.getItemForQr({ baseorderId: getItemCustomId.source._id, itemId: itemQrId});
            chosenLabel.items[0].customId = item.customId;
            viewCard(chosenLabel, chosenLabel.stage, isItem, content);
          } else {
            router.push({
              name: 'shipping-edit',
              params: {
                projectId,
                cardId: params.shippingId,
              },
            });
          }
        } else {
          errorDialog();
        }
      } catch (e) {
        const { data } = e;
        if (data) {
          if (isString(data)) {
            errorDialog(data);
          } else if (data.msg) {
            errorDialog(data.msg);
          }
          else {
            toast.error(data.message);
            router.push({path: 'logistics/inventory'});
          }
        }
      }
    };

    const closeBtn = () => {
      state.showItemQr = false;
      router.go(-1);
    }

    return {
      ...toRefs(state),
      bringUpCard,
      closeBtn
    };
  },
});
</script>
<style scoped>

</style>
