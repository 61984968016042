import _ from 'lodash';
import GenerateRequest from './resources';
import { BaseOrder } from './BaseOrder';

const req = GenerateRequest();
export default {
  async getTemplates(params = {}) {
    try {
      const { data } = await req.get('prodTemplates', { params });
      return new BaseOrder(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getDefaultTemplate(params = {}) {
    try {
      params.getDefault = true;
      const { data } = await req.get('prodTemplates/getDefault', { params });
      if (_.isEmpty(data)) { return data; }
      return new BaseOrder(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async create(card) {
    try {
      const { data: template } = await req.post('prodTemplates/create', card, {
        params: { projectId: card.project._id },
      });
      return template;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async update(card) {
    try {
      const { data: template } = await req.post('prodTemplates/update', card, {
        params: { projectId: card.project._id },
      });
      return template;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async archive(card) {
    try {
      const { data: order } = await req.post('prodTemplates/archive', card);
      return order;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async clone(card) {
    try {
      const { data: prodTemplate } = await req.post('prodTemplates/clone', card, {
        params: { projectId: card.project._id },
      });
      return prodTemplate;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
};
