<template>
   <div class="is-relative note-count is-clickable"
    @click="triggerItemsModal()">
      <span> {{rowData.items.length }} </span>
      <i class="icon-attacheditemsOL"></i>
    </div>
</template>

<script>

import { defineComponent, inject } from 'vue';

export default defineComponent({
  name: 'ShippingItemsIconField',
  props: [
    'value',
    'rowField',
    'rowData',
  ],
  setup(props) {
    const emitter = inject('emitter');

    const triggerItemsModal = () => {
      // trigger delivery items modal
      const emitObj = {
        rowData: props.rowData,
        isUnscheduledRow: props.rowData.id === 'unscheduled',
        isActive: true,
      };
      emitter.emit('toggle:isDeliveryItems', emitObj);
    };

    return {
      triggerItemsModal,
    };
  },
});

</script>
