<template>
  <div v-if="isHeader" class="has-text-centered">
    <i
      :class="[
        $_.get(rowField, 'inputProps.customHeader')
          ? $_.get(rowField, 'inputProps.icon')
          : 'far fa-lock',
      ]"
    ></i>
    {{ $_.get(rowField, 'title', '') }}
  </div>
  <div v-else class="is-justify-content-center is-flex">
    <i :class="isPrivate ? 'fas fa-lock' : 'far fa-lock'">
    </i>
  </div>
</template>
<script>
import { computed } from 'vue';
import { get } from 'lodash';

export default {
  props: ['value', 'rowData', 'rowField', 'key', 'type', 'isHeader'],
  setup(props) {
    const isPrivate = computed(() => get(props.rowData, props.rowField.id));

    return {
      isPrivate,
    };
  },
};
</script>
