<template>
  <o-modal :active="isActive" :canCancel="false" class="modal-xs">
      <div class="modal-card">
        <header class="modal-card-head">
         <h4 class="modal-card-title" >
         View Model
         </h4>
        </header>
        <section class="modal-card-body is-visible has-background-white">
         <h3 class="has-text-black mb-3"> Select a Project to view linked model </h3>
          <div class="mb-3">
            <search-bar
              :shouldEmit="true"
              @search="getSearchValue($event)"
              placeholder="Search"
            >
            </search-bar>
          </div>
          <div class="sub-content">
            <div v-for="project in filterProjects" :key="project.id"
              class="mb-2 has-text-black-bis line-height">
                  <input type="radio"
                  :id="project.id"
                  :value="project.id"
                  @click="selectProject(project)"
                  v-model="ProjectId"
                  :checked="ProjectId === project.id"
                />
                <label :for="project.id" class="has-text-black-bis is-flex is-align-items-center">
                  {{project.name}}
                </label>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
            <button class="button is-outlined"
              @click="cancel">
              Cancel
            </button>
            <button class="button has-background-black-bis"
            :disabled="$_.isEmpty(selectedProject)"
             @click="viewModel">
              View Model
            </button>
        </footer>
      </div>
  </o-modal>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import {
  reactive, toRefs, onMounted, defineComponent,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LmvProjectModal',
  props: {
    isActive: Boolean,
  },
  components: {
    SearchBar,
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      allProjects: [],
      fetchedProjects: [],
      ProjectId: null,
      selectedProject: [],
      searchText: '',
      filterProjects: [],
    });
    const { userData } = store.state;

    onMounted(async () => {
      state.allProjects = store.state.queryParams._projects;
      state.fetchedProjects = _.filter(state.allProjects, (project) => {
        const projectSetting = _.find(project.projectSettings, {
          companyId: userData.company,
        });
        return (
          !_.isEmpty(projectSetting)
            && !_.isEmpty(projectSetting.forgeSync)
            && !_.isEmpty(projectSetting.forgeSync.urn)
        );
      });
      state.filterProjects = state.fetchedProjects;
    });
    const selectProject = (data) => {
      state.selectedProject = data;
    };
    const viewModel = () => {
      const projectSettings = _.filter(state.selectedProject.projectSettings,
        (data) => data.forgeSync);
      const Params = {
        module: 'viewer',
        projectId: state.selectedProject._id,
        urn: projectSettings[0].forgeSync.urn,
      };
      store.commit('setQueryParams', { selectedLmvProject: [state.selectedProject] });
      router.push({ name: 'lmv-view', params: Params });
    };
    const cancel = () => {
      state.searchText = '';
      state.filterProjects = state.fetchedProjects;
      state.ProjectId = null;
      state.selectedProject = [];
      store.commit('setQueryParams', { selectedLmvProject: [] });
      emit('close');
    };
    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      if (state.searchText) {
        // eslint-disable-next-line max-len
        state.filterProjects = _.filter(state.fetchedProjects, (project) => project.name.toLowerCase().includes(state.searchText.toLowerCase()));
      } else {
        state.filterProjects = state.fetchedProjects;
      }
    };
    return {
      ...toRefs(state),
      selectProject,
      viewModel,
      cancel,
      getSearchValue,
    };
  },
});
</script>
<style scoped>
[type="radio"] ~ label::after {
  top: unset !important;
}
.modal-xs {
  overflow: unset !important;
}
</style>
