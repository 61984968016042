import { map } from 'lodash';

function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    isDetailed: false,
    rearrangeCols: false,
  };
}

export default {
  'item-info': {
    ...extraTableParams(),
    fields: [
      {
        name: 'field-generic-input',
        width: '400px',
        title: 'Items',
        prop: 'name',
        id: 'name',
      },
      {
        name: 'table-field-icon',
        width: '64px',
        title: '',
        id: 'lmvIcon',
        customHeader: true,
        inputProps: {
          icon: 'icon-linked-model',
        },
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Item ID',
        id: 'customId',
        prop: 'customId',
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Catalog ID',
        id: 'catId',
        prop: 'catId',
      },
      {
        name: 'field-generic-input',
        width: '112px',
        title: 'QTY',
        id: 'quantity',
        prop: 'quantity',
      },
      {
        name: 'field-generic-input',
        prop: 'unitCost',
        sortField: 'unitCost',
        width: '112px',
        title: 'Item Cost',
        id: 'unitCost',
      },
      {
        name: 'field-generic-input',
        prop: 'totalCost',
        sortField: 'totalCost',
        width: '112px',
        title: 'Total Cost',
        id: 'totalCost',
      },
      {
        name: 'installLocation',
        title: 'Install Location',
        width: '296px',
        id: 'installLocation',
        sortField: 'installLocation',
      },
      {
        name: 'field-item-docs-icon',
        width: '64px',
        title: 'Docs',
        for: 'Item',
        id: 'simpleFiles',
      },
      {
        name: 'field-notes-icon',
        title: 'Notes',
        prop: 'simpleMemos[0].text',
        defaultVisible: true,
        width: '64px',
        id: 'notes',
        inputProps: {
          isItem: true,
        },
      },
    ],
  },
  'item-details': {
    ...extraTableParams(),
    fields: [
      {
        name: 'name',
        width: '400px',
        title: 'Items',
        prop: 'name',
        id: 'name',
      },
      {
        name: 'customId',
        width: '152px',
        title: 'Item ID',
        id: 'customId',
      },
      {
        name: 'measure',
        width: '112px',
        title: 'Meas',
        id: 'measure',
      },
      {
        name: 'measureUnits',
        width: '112px',
        title: 'Meas units',
        id: 'measureUnits',
      },
      {
        name: 'field-generic-input',
        prop: 'category.name',
        width: '152px',
        title: 'Category',
        id: 'category',
      },
      {
        name: 'vendors',
        prop: 'vendor',
        width: '296px',
        title: 'Vendor',
        id: 'vendor',
        formatter(vendors) {
          return map(vendors, 'name').join(', ');
        },
      }, {
        name: 'quantity',
        width: '112px',
        title: 'QTY',
        id: 'quantity',
      }, {
        name: 'costCode',
        width: '112px',
        title: 'Cost Code',
        prop: 'costCode',
        id: 'costCode',
      },
    ],
  },
};
