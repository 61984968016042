export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  hasCheckBox: true,
  isCheckable: true,
  isDetailed: false,
  rearrangeCols: false,
  toolBar: false,
  fields: [
    {
      title: 'Project Name',
      name: 'field-generic-input',
      width: '640px',
      sortField: 'name',
      prop: 'name',
      id: 'name',
    }, {
      title: 'Project ID',
      name: 'field-generic-input',
      width: '300px',
      prop: 'projectSettings[0].customId',
      id: 'projectId',
    },
  ],
};
