import { uniq, map, castArray } from 'lodash';
import { useToast } from 'vue-toastification';
import SupplyChain from '@/models/SupplyChain';
import { DialogProgrammatic } from '@/components/Dialog';

export default function () {
  const toast = useToast();

  const formatItemDeleteObj = (item, selectedValuesForKey, singleDelete = false) => {
    const projectIds = uniq(map(castArray(item.project), '_id'));
    const deleteItem = {
      itemIds: item.itemIds,
      projectIds,
      project: { name: uniq(map(castArray(item.project), 'name')) },
      locIds: [item.rootLoc._id],
      isTerminal: singleDelete,
      kind: selectedValuesForKey('source', true),
      uid: item.uid,
    };
    if (item.catId) {
      deleteItem.catId = item.catId;
    } else {
      deleteItem.name = item.name;
    }
    return deleteItem;
  };

  const archiveItems = async (selectedValuesForKey, item = null, emitRefresh, emitLoading) => {
    try {
      const itemsToDelete = [formatItemDeleteObj(item, selectedValuesForKey, true)];
      let res = null;
      let confirmParam = {
        title: 'Are you sure you want to Remove?',
        message: 'Are you sure you want to Remove the inventory items',
        okButton: 'OK',
        cancelButton: 'Cancel',
        onConfirm: async () => {
          try {
            emitLoading(true);
            res = await SupplyChain.deleteInventoryItem(itemsToDelete);
            emitRefresh();
            if (res.done && res.failed.length) {
              const failedItemNames = map(res.failed, 'name');
              confirmParam = {
                title: 'Error',
                message: `Item(s) deleted! But, following items could not be deleted
                due to one or more reasons: <b>${String(failedItemNames).replace(/,/g, ', ')}</b>`,
                okButton: 'OK',
                type: 'danger',
              };
              DialogProgrammatic.confirm(confirmParam);
            } else {
              toast.success('Item(s) deleted!', 'is-success');
            }
          } catch (e) {
            emitLoading(false);
            toast.error('Error while deleting');
          }
        },
      };
      DialogProgrammatic.confirm(confirmParam);
    } catch (e) {
      toast.error('Error while deleting');
    }
  };

  return {
    archiveItems,
  };
}
