<template>
  <div>
    <div v-if="rowFieldValue.allowAutoComplete && !disableEdit">
      <o-autocomplete
        :data="CatIdOptions"
        v-model="rowDataValue[getType]"
        :field="getType"
        ref="autocomplete"
        @typing="getAsyncData"
        :open-on-focus="false"
        @blur="getType === 'catId' ? catIdValidate(rowDataValue, $event): ''"
        @select="setCatId">
        <template v-slot:empty="{ props }" class="pointer">
          <span @click="setPartModalActive()" v-if="getType === 'catId'">
            <span v-if="$_.isEmpty(CatIdOptions)">
              <!-- Click here to create new. -->
              No Results Found.
            </span>
          </span>
          <span v-else>
            {{$_.isEmpty(rowDataValue.name) ? 'Enter item name' : 'Not Found.'}}
          </span>
        </template>
      </o-autocomplete>
    </div>
    <div v-else-if="!disableEdit" >
      <input v-if="isPartsOrAssmb" class="input is-size-6" v-model.trim="rowDataValue.catId"
        @input="searchCatId(rowDataValue, $event, rowFieldValue)"
        :disabled="disableCatId(rowDataValue)"
        @blur="catIdValidate(rowDataValue, $event)" />
      <input v-else class="input is-size-6" v-model.trim="rowDataValue.catId"
        @input="onInputChange(rowDataValue, rowFieldValue)"
        :disabled="disableCatId(rowDataValue)"
        @blur="catIdValidate(rowDataValue, $event)" />
    </div>
    <span v-else-if="rowFieldValue.allowAutoComplete" v-tooltip="rowDataValue[getType]">
      {{rowDataValue[getType]}}
    </span>
    <span v-else v-tooltip="rowDataValue.catId.length > 15 ? rowDataValue.catId : ''"
      class="text-overflow line-height text-clamp">
      {{rowDataValue.catId}}
    </span>
  </div>
</template>

<script>
import {
  get, debounce, isFunction, isEmpty, find, set, filter,
} from 'lodash';
import Catalogs from '@/models/Catalogs';
import Vendors from '@/models/Vendors';
import Helper from '@/models/Helper';
import Validation from '@/components/utils/Validations';
import {
  computed, reactive, toRefs, onMounted,
} from 'vue';
import { useToast } from 'vue-toastification';
import { DialogProgrammatic } from '@/components/Dialog';
import { useStore } from 'vuex';

export default {
  name: 'CatIdField',
  props: {
    rowData: Object,
    rowField: Object,
    catalogCollection: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const data = reactive({
      rowDataValue: props.rowData,
      rowFieldValue: props.rowField,
      isFetching: false,
      CatIdOptions: [],
    });

    const toast = useToast();

    const shipStages = ['in-transit', 'complete', 'delivery', 'fulfilled', 'not-started', 'in-storage', 'released-to-inventory', 'mixed-shipping'];

    const isPartsOrAssmb = computed(() => ['preparation', 'sourcing', 'ordering'].includes(get(data.rowDataValue, 'order._customStage')) || ['orderMoreInv'].includes(get(data.rowFieldValue, 'inputProps.for', '')));

    const disableEdit = computed(() => {
      const disableEditVal = get(data.rowFieldValue, 'inputProps.disableEdit', true);
      if (isFunction(disableEditVal)) {
        return disableEditVal(data.rowDataValue);
      }
      if (data.rowDataValue?.isNew) {
        return !!data.rowDataValue?.fromMasterCatalog;
      }
      return disableEditVal;
    });

    const getType = computed(() => data.rowFieldValue.id);

    const getCatalogType = computed(() => {
      if (store.state.activeScreen === 'assemblies') return 'assemblyAndItems';
      return (isPartsOrAssmb.value ? 'Parts' : 'Assembly');
    });

    const methods = {
      searchCatId() {
        debounce(async (rowDataValue, event, rowFieldValue) => {
          const searchVal = event.target.value;
          Helper.reSubmitStageChange({ rowDataValue, rowFieldValue });
          rowDataValue.category = '';
          rowDataValue.vendors = [];
          let indexedCategories = {};
          if (searchVal) {
            const { data: result } = await Catalogs.getAssemblyParts({
              catId: [rowDataValue.catId],
              type: 'UpcItems',
            });
            rowDataValue.category = result && !isEmpty(result[0].category) ? result[0].category : '';
            if (!isEmpty(rowDataValue.category)) {
              const { data: vendors } = await Vendors.getVendors({
                category: [rowDataValue.category._id],
              });
              if (vendors && vendors.length > 0) {
                indexedCategories = Helper.getCategoriesFromVendors(vendors);
              }
              rowDataValue.vendors = indexedCategories[rowDataValue.category._id];
            }
          }
        }, 500);
      },

      onInputChange() {
        debounce((rowDataValue, rowFieldValue) => {
          Helper.reSubmitStageChange({ rowDataValue, rowFieldValue });
        }, 500);
      },

      disableCatId(rowDataValue) {
        if ((rowDataValue.order && shipStages.includes(rowDataValue.order._customStage))
          || rowDataValue.fromMasterCatalog || rowDataValue.stage === 'planning') {
          return true;
        }
        return false;
      },

      catIdValidate(rowDataValue, event) {
        const catId = event.target.value;
        rowDataValue.catId = Validation.getValidCatId(catId);
        if (catId.toUpperCase() !== rowDataValue.catId) {
          toast.success('Updated Catalog Id by removing unsupported characters');
        }
        // below code is to delete the purpose if user selected the assembly from autoSelect
        // and removed it again
        if (isEmpty(data.rowDataValue.catId) && data.rowDataValue.purpose === 'assembly') {
          delete data.rowDataValue.purpose;
        }
        if (data.rowFieldValue.verifyCatId && !isEmpty(data.rowDataValue.catId)) {
          setTimeout(() => {
            // eslint-disable-next-line
            const res = find(data.CatIdOptions, { catId: data.rowDataValue.catId });
            // this.$refs.autocomplete.newValue = Validation.getValidCatId(this.rowDataValue.catId);
            if (!res) {
              const confirmParam = {
                title: 'Catalog ID Not Found',
                message: `The Catalog ID you entered does not exist.
                Please search the Catalog for a valid ID.
                <a href='/#/settings/company-profile/assembly-parts/parts' target='_blank'
                  class='is-capitalized'>
                  <span>open manufacton catalog</span>
                  <i class="far fa-external-link-alt"></i>
                </a>`,
                okButton: 'OK',
                onConfirm: () => {
                  data.rowDataValue.catId = '';
                  data.rowDataValue.measureUnits = '';
                },
              };
              DialogProgrammatic.confirm(confirmParam);
            } else {
              // below code is to initialize the assembly data to the item when
              // manually typed catId
              methods.setCatId(res);
            }
          }, 1000);
        }
      },

      async getAsyncData(name) {
        const params = {
          limit: 25,
          page: 1,
          search: name,
          type: getCatalogType.value,
          showAssmbsParts: store.state.activeScreen === 'assemblies',
          parentAssemblyId: data.rowDataValue?.parentAssemblyId,
          parentCatId: data.rowDataValue?.parentCatId,
        };
        if (props.catalogCollection?.length) {
          data.CatIdOptions = name?.length
            ? filter(props.catalogCollection,
              (c) => c.catId?.toLowerCase().includes(name?.toLowerCase()))
            : props.catalogCollection;
        } else {
          const catalogData = (await Catalogs.getAssemblyParts(params)).data;
          data.CatIdOptions = catalogData;
        }
      },

      setCatId(option) {
        if (option) {
          if (getType.value === 'catId') {
            data.rowDataValue.catId = option.catId;
            set(data.rowDataValue, 'name', option.name);
            set(data.rowDataValue, 'measureUnits', option.measureUnits);
            set(data.rowDataValue, 'purchase', option.purchase);
            if (store.state.activeScreen === 'assemblies') {
              set(data.rowDataValue, 'customId', option.customId);
              set(data.rowDataValue, 'category', option.category);
              set(data.rowDataValue, 'subCategory', option.subCategory);
              set(data.rowDataValue, 'desc', option.desc);
              set(data.rowDataValue, '__t', option.__t);
            }
            if (data.rowDataValue?._place === 'item'
              && data.rowDataValue?.order?.__t === 'Prefabs'
              && data.rowDataValue?.order?.purpose === 'kit') {
              set(data.rowDataValue, 'purpose', 'assembly');
            }
          } else if (getType.value === 'name') {
            data.rowDataValue.name = option.name;
            set(data.rowDataValue, 'catId', option.catId);
            set(data.rowDataValue, 'measureUnits', option.measureUnits);
          }
        }
      },
    };

    onMounted(async () => {
      if (!disableEdit.value) await methods.getAsyncData('');
    });

    return {
      ...toRefs(data),
      ...methods,
      shipStages,
      isPartsOrAssmb,
      disableEdit,
      getType,
      getCatalogType,
    };
  },
};
</script>
