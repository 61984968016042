<template>
  <div class="field">
    <label class="label has-text-black-bis is-italic is-size-3">
      {{ label }}
    </label>
    <div class="is-size-3 pl-1 has-text-weight-bold" v-if="value">
      <a class="text-overflow" @click="openPOrder()">
        {{ value.name }}
      </a>
    </div>
    <div class="column p-0" v-else>
      <span class="is-size-3 is-italic has-text-weight-normal label"
        >No Linked Orders</span
      >
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Order from '@/models/Orders';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LinkedProductionOrder',
  props: ['value', 'label', 'projectId'],
  setup(props) {
    const router = useRouter();

    const openPOrder = async () => {
      try {
        const order = await Order.get({
          orderId: props.value._id,
          projectId: props.projectId,
        });
        const name = order.isPM() ? 'manager-edit' : 'order-edit';
        const stage = order.isPM() ? order.stage : '';
        router.push({
          name,
          params: {
            module: 'ProductionOrder',
            projectId: props.projectId,
            cardId: order._id,
            stage,
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    return {
      openPOrder,
    };
  },
});
</script>
<style scoped>
.text-overflow {
  -webkit-line-clamp: 1;
  line-height: 24px;
}
</style>
