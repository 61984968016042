<template>
<div class="list-view home">
  <div class="backingpanel-home">
      <div class="column is-inline-block dash-panel">
        <div class="dash-panel-title">ORDER SUMMARY
        <select v-model="showHideEmpty" class="is-pulled-right">
            <option v-for="option in filterEmptyOptions" :key="option.value" :value="option.value">{{option.text}}</option>
          </select></div>
        <div class="dash-holder-med" id="orders"></div>
      </div>
      <div class="column is-inline-block dash-panel">
        <div class="dash-panel-title">VENDOR DELIVERY PERFORMANCE
          <select v-model="vendorTimeFrame" class="is-pulled-right">
            <option v-for="option in timeframeOptions" :key="option.value" :value="option.value">{{option.text}}</option>
          </select></div>
        <div class="dash-holder-med" id="vendormat"></div>
      </div>
      <div class="column is-inline-block dash-panel">
        <div class="dash-panel-title">SHOP HOURS
        <select v-model="shopResourceTimeFrame">
                  <option v-for="option in shopTimeOptions" :key="option.value" :value="option.value">{{option.text}}</option>
                </select>
        </div>
        <div class="dash-holder-480" id="resource"></div>
      </div>
      <div class="column is-inline-block dash-panel">
        <div class="dash-panel-title">PRODUCTIVITY BY PROJECT
        </div>
        <div class="dash-holder-480" id="productivity"></div>
      </div>
</div>
</div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, onMounted, computed, watchEffect, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PowerBIToken from '@/models/PowerBI';
import * as pbi from 'powerbi-client';
import Vendors from '@/models/Vendors';
import _ from 'lodash';
import moment from 'moment';

import Version from '@/appVersion.json';

export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter();

    function setupReport(report, drill) {
      // Clear any other loaded handler events
      report.off('loaded');

      // Triggers when a report schema is successfully loaded
      report.on('loaded', () => {
        // report.updateFilters(pbi.models.FiltersOperations.Add, [myFilter]);
      });

      // Clear any other rendered handler events
      report.off('rendered');

      // Triggers when a report is successfully embedded in UI
      report.on('rendered', () => {
        console.log('Report render successful');
      });

      // Clear any other error handler events
      report.off('error');

      // Handle embed errors
      report.on('error', (event) => {
        const errorMsg = event.detail;
        console.error(errorMsg);
      });

      report.off('dataHyperlinkClicked');

      report.on('dataHyperlinkClicked', (event) => {
        const url = _.get(event, 'detail.url').replaceAll('&amp;', '&');

        const split = url.split('?');
        if (split.length === 2) {
          const urlParams = new URLSearchParams(`?${split[1]}`);

          let routePath = '/scm/production-status/order-view';
          const params = {};

          console.log(url);

          if (url.search('material-status') >= 0) {
            routePath = '/scm/material-status/order-view';
          }

          if (url.search('shipping-status') >= 0) {
            routePath = '/logistics/shipping/order-view';
            params.dbShowEmpty = showHideEmpty.value === 'b'
          }

          params.fromDashBoard = true;

          if (urlParams.get('hasCompleteWorksteps')) {
            params.hasCompleteWorksteps = urlParams.get('hasCompleteWorksteps');
          }
          if (urlParams.get('hasActualHrs')) {
            params.hasActualHrs = urlParams.get('hasActualHrs');
          }
          if (urlParams.get('hasPlannedHrs')) {
            params.hasPlannedHrs = urlParams.get('hasPlannedHrs');
          }
          if (urlParams.get('hasProductivity')) {
            params.hasProductivity = urlParams.get('hasProductivity');
          }
          if (urlParams.get('productionStatus')) {
            params.productionStatus = urlParams.get('productionStatus');
          }
          if (urlParams.get('productivityRange')) {
            params.productivityRange = urlParams.get('productivityRange');
          }
          if (urlParams.get('project')) {
            params.project = urlParams.get('project');
          }
          if (urlParams.get('orderType')) {
            params.dbOrderType = urlParams.get('orderType');
          }
          if (urlParams.get('upcoming')) {
            params.dbUpcoming = urlParams.get('upcoming');
          }
          if (urlParams.get('isDelivered')) {
            params.isDelivered = urlParams.get('isDelivered');
          }
          if (urlParams.get('isLate')) {
            params.isLate = urlParams.get('isLate');
          }
          if (urlParams.get('vendor')) {
            params.vendor = urlParams.get('vendor');
          }
          if (urlParams.get('orderStage')) {
            params.orderStage = urlParams.get('orderStage');
          }
          if (urlParams.get('shopMonth')) {
            params.shopMonth = urlParams.get('shopMonth');
          }
          if (urlParams.get('shopWeek')) {
            params.shopWeek = urlParams.get('shopWeek');
          }

          if (urlParams.get('productivityStatus')) {
            params.productivityStatus = urlParams.get('productivityStatus');
          }
          if (urlParams.get('wsOrderId')) {
            params.wsOrderId = urlParams.get('wsOrderId');
          }

          

          drill(routePath, params);
        }
      });

      report.off('buttonClicked');

      report.on('buttonClicked', (event) => {
        const url = _.get(event, 'detail.title');
        console.log(JSON.stringify(url));

        const split = url.split('?');
        if (split.length === 2) {
          const urlParams = new URLSearchParams(`?${split[1]}`);

          let routePath = '/scm/production-status/order-view';
          const params = {};

          if (url.search('material-status') >= 0) {
            routePath = '/scm/material-status/order-view';
          }

          if (url.search('shipping-status') >= 0) {
            routePath = '/logistics/shipping/order-view';
            params.dbShowEmpty = showHideEmpty.value === 'b'
          }

          params.fromDashBoard = true;

          if (urlParams.get('hasCompleteWorksteps')) {
            params.hasCompleteWorksteps = urlParams.get('hasCompleteWorksteps');
          }
          if (urlParams.get('hasActualHrs')) {
            params.hasActualHrs = urlParams.get('hasActualHrs');
          }
          if (urlParams.get('hasPlannedHrs')) {
            params.hasPlannedHrs = urlParams.get('hasPlannedHrs');
          }
          if (urlParams.get('hasProductivity')) {
            params.hasProductivity = urlParams.get('hasProductivity');
          }
          if (urlParams.get('productionStatus')) {
            params.productionStatus = urlParams.get('productionStatus');
          }
          if (urlParams.get('productivityRange')) {
            params.productivityRange = urlParams.get('productivityRange');
          }
          if (urlParams.get('project')) {
            params.project = urlParams.get('project');
          }
          if (urlParams.get('orderType')) {
            params.dbOrderType = urlParams.get('orderType');
          }
          if (urlParams.get('upcoming')) {
            params.dbUpcoming = urlParams.get('upcoming');
          }
          if (urlParams.get('isDelivered')) {
            params.isDelivered = urlParams.get('isDelivered');
          }
          if (urlParams.get('isLate')) {
            params.isLate = urlParams.get('isLate');
          }
          if (urlParams.get('vendor')) {
            params.vendor = urlParams.get('vendor');
          }
          if (urlParams.get('orderStage')) {
            params.orderStage = urlParams.get('orderStage');
          }
          if (urlParams.get('shopMonth')) {
            params.shopMonth = urlParams.get('shopMonth');
          }
          if (urlParams.get('shopWeek')) {
            params.shopWeek = urlParams.get('shopWeek');
          }

          drill(routePath, params);
        }
      });

      report.off('dataSelected');

      report.on('dataSelected', (event) => {
        console.log(`data selected: ${JSON.stringify(event.detail)}`);
      });
    }

    let showDash = true; // Dont commit this change, update this to true for UI instance and local
    if (_.get(Version, 'env', '') == 'dev') {
      showDash = true;
    }

    function drill(path, query) {
      router.push({ path, query });
    }
    const getDynamicClasses = computed(() => {
      const routeMeta = router.currentRoute.value.meta;
      let classes = '';
      if (routeMeta.activeViewColor) {
        classes = `${routeMeta.activeViewColor} `;
      }
      if (routeMeta.cardEditIcon) {
        classes += `icon-${routeMeta.cardEditIcon}`;
      }
      return classes;
    });
    const store = useStore();
    const data = reactive({
      isActive: false,
      allCategories: [],
    });
    const clickActivity = () => {
      data.isActive = true;
    };

    const showSecond = ref(false);
    const showThird = ref(false);
    const biRef = ref('<div></div>');
    const showMobile = false;

    const reports = [];

    const projectIds = computed(() => store.getters.selectedIdsForKey('filteredProjects'));

    const projectFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'projects',
        column: '_id',
      },
      operator: 'In',
      values: projectIds.value,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const projectFilterM = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'MaterialOrders',
        column: 'project._id',
      },
      operator: 'In',
      values: projectIds.value,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const projectFilterP = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'productionOrders',
        column: 'project._id',
      },
      operator: 'In',
      values: projectIds.value,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const projectFilterS = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'shippinglabels',
        column: 'project._id',
      },
      operator: 'In',
      values: projectIds.value,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const timeframeOptions = [
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Years, qty: 100, value: 'a', text: 'All Time',
      },
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Weeks, qty: 1, value: 'b', text: 'Past Week',
      },
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Months, qty: 1, value: 'c', text: 'Past Month',
      },
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Months, qty: 3, value: 'd', text: 'Past 3 Months',
      },
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Years, qty: 1, value: 'e', text: 'Past Year',
      },
    ];

    const shopTimeOptions = [
      {
        value: 'b', text: 'Monthly',
      },
      {
        value: 'a', text: 'Weekly',
      },
    ];

    const filterEmptyClauses = [
      {
        value: 'a', text: 'Hide Empty Shipping Orders', filter: ['NotEmpty']
      },
      {
        value: 'b', text: 'Show Empty Shipping Orders', filter: ['NotEmpty', 'Empty']
      },
    ];

    const filterEmptyOptions = [
      {
        value: 'a', text: 'Hide Empty Shipping Orders'
      },
      {
        value: 'b', text: 'Show Empty Shipping Orders'
      }
    ]

    const shopResourceTimeFrame = ref('b');

    const vendorTimeFrame = ref('a');

    const showHideEmpty = ref('a');

    const emptyShippingOrderFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'shippinglabels',
        column: 'IsEmpty',
      },
      operator: 'In',
      values: ['NotEmpty'],
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const vendorDateFilter = {
      $schema: 'http://powerbi.com/product/schema#relativeDate',
      target: {
        table: 'MaterialOrders',
        column: 'OnsiteByDate',
      },
      operator: pbi.models.RelativeDateOperators.InLast,
      timeUnitsCount: 100,
      timeUnitType: pbi.models.RelativeDateFilterTimeUnit.Years,
      includeToday: true,
      filterType: pbi.models.FilterType.RelativeDate,
    };

    watchEffect(() => {
      const {filter} = filterEmptyClauses.find((element) => element.value === showHideEmpty.value);
      emptyShippingOrderFilter.values = filter;
      if(reports[1]) {
        reports[1].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilterM, projectFilterP, emptyShippingOrderFilter]);
      }
    });

    watchEffect(() => {
      const { qty, unit } = timeframeOptions.find((element) => element.value === vendorTimeFrame.value);
      vendorDateFilter.timeUnitsCount = qty;
      vendorDateFilter.timeUnitType = unit;
      if (reports[3]) {
        reports[3].updateFilters(pbi.models.FiltersOperations.Replace, [vendorDateFilter]);
      }
    });

    watchEffect(() => {
      const { text } = shopTimeOptions.find((element) => element.value === shopResourceTimeFrame.value);
      if (reports[2]) {
        if (text === 'Weekly') {
          reports[2].setPage('ReportSection4abb0c031108e398b5a7');
        } else {
          reports[2].setPage('ReportSection1dffa25ea60eea49566a');
        }
      }
    });

    watch(() => store.state.queryParams.projects, () => {
      console.log('project list changed');
      projectFilter.values = projectIds.value;
      projectFilterM.values = projectIds.value;
      projectFilterP.values = projectIds.value;
      projectFilterS.values = projectIds.value;
      // for some reason reports.each did not work here...
      console.log(JSON.stringify(emptyShippingOrderFilter));
      console.log(JSON.stringify(projectFilterS));
      /*
       * NOTE: this is the order of the dashboards in the reports array:
       * [0]: productivity
       * [1]: orders
       * [2]: resource
       * [3]: vendor
       * this is NOT the same as the order they appear on screen!
       */
      reports[0].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilterM, projectFilterP, emptyShippingOrderFilter]);
      reports[1].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilter, projectFilterM, projectFilterP, projectFilterS]);
      reports[2].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilter, projectFilterM, projectFilterP]);
      reports[3].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilterM]);
    });

    // removing logs for prod env
    // watchEffect(() => {
    //   console.log(`Project IDs: ${JSON.stringify(projectIds.value)}`);
    // });

    onMounted(async () => {
      data.allCategories = (await Vendors.getCategories({ limit: 500 })).data;
      await PowerBIToken.getEmbedToken().then((response) => {
        const embedData = response;

        // set up the request object
        const metrics = {
          type: 'report',
          tokenType: pbi.models.TokenType.Embed,
          accessToken: embedData.accessToken,

          // Use other embed report config based on the requirement.
          // We have used the first one for demo purpose
          embedUrl: embedData.embedUrl[0].embedUrl,
          // ALWAYS specify page name to future-proof
          pageName: '',
          reportSection: '',

          // Enable this setting to remove gray shoulders from embedded report
          // settings: {
          //     background: models.BackgroundType.Transparent
          // }
          settings: {
            layoutType: (showMobile ? pbi.models.LayoutType.MobilePortrait
              : pbi.models.LayoutType.Master),
            panes: {
              pageNavigation: {
                visible: false,
              },
              filters:
              {
                visible: false,
              },
            },
            hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent,
          },
          filters: [],
        };

        // Use the token expiry to regenerate Embed token for seamless end user experience
        // Refer https://aka.ms/RefreshEmbedToken
        // const tokenExpiry = embedData.expiry;

        // Embed Power BI report when Access token and Embed URL are available

        const powerbi = new pbi.service.Service(pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        
          // removing logs for prod environment
          // console.log(`categories: ${JSON.stringify(data.allCategories)}`);


        metrics.pageName = 'ReportSection904e47e1938f163b0e3e';
        metrics.filters = [projectFilter, projectFilterM, projectFilterP];
        let reportholder = document.getElementById('productivity');
        let report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill);
        reports.push(report);

        metrics.pageName = 'ReportSection0f890299733c4f3eabae';
        metrics.filters = [projectFilter, projectFilterM, projectFilterP, projectFilterS, emptyShippingOrderFilter];
        reportholder = document.getElementById('orders');
        report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill);
        reports.push(report);

        metrics.pageName = 'ReportSection1dffa25ea60eea49566a';
        metrics.filters = [projectFilter, projectFilterM, projectFilterP];
        reportholder = document.getElementById('resource');
        report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill);
        reports.push(report);

        metrics.pageName = 'ReportSection13173f08d01765f3878d';
        metrics.filters = [projectFilterM, vendorDateFilter];
        reportholder = document.getElementById('vendormat');
        report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill, { uncategorized: true });
        reports.push(report);
      });
    });

    return {
      ...toRefs(data),
      clickActivity,
      showSecond,
      showThird,
      biRef,
      showMobile,
      reports,
      getDynamicClasses,
      showDash,
      projectIds,
      timeframeOptions,
      vendorTimeFrame,
      shopTimeOptions,
      shopResourceTimeFrame,
      showHideEmpty,
      filterEmptyOptions
    };
  },
});
</script>
<style scoped>

</style>
