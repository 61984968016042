<template>
<div>
    <mf-multi-select
      v-model="inputValue"
      :options="orderList"
      :titleLabel="titleLabel"
      :labelPosition="labelPosition"
      :placeholder="placeholder"
      :disabled="disableSelection"
      :multiple="false"
      labelClass="has-text-black-bis is-italic is-size-3"
      label="templateName"
      openDirection="below"
      track-by="name"
      :allow-empty="false"
      :preselectFirst="false"
      @select="$emit('select', { all: false, template: $event, itemId: rowData._id.toString() })"
    >
    </mf-multi-select>
  </div>
</template>

<script>
import {
  reactive, toRefs, computed, onMounted,
} from 'vue';
import { isEmpty, filter, get } from 'lodash';
import MfMultiSelect from '@/components/abstract/MfMultiSelect';
import Projects from '@/models/Projects';
import SupplyChain from '@/models/SupplyChain';
import { useStore } from 'vuex';

export default {
  name: 'TemplateSelect',
  props: {
    titleLabel: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: 'top',
    },
    placeholder: {
      type: String,
      default: 'Make a Selection',
    },
    stage: String,
    purpose: String,
    value: Object,
    rowData: Object,
    isEditing: Boolean,
    isVarying: Boolean,
    isDisabled: Boolean,
    templateProject: {
      type: Object,
      default: () => {},
    },
    templateOrders: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    MfMultiSelect,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { rowData } = props;
    const state = reactive({
      templateProject: false,
      orderList: [],
    });

    const inputValue = computed({
      get: () => (isEmpty(props.value) ? [] : props.value),
      set: (val) => (emit('update:value', val)),
    });

    const stage = props.stage ? props.stage : 'production';

    const disableSelection = computed(() => (!isEmpty(props.rowData)
          && ((get(props.rowData, 'order.purpose', '') === 'kit' && props.rowData.purpose === 'general')
          || (!store.state.onePerItem && !['kit', 'assembly'].includes(props.rowData.purpose)))
    ));

    onMounted(async () => {
      if (isEmpty(props.templateProject)) {
        const templateProjects = (await Projects.haveTemplateProject()).templateProject;
        state.templateProject = templateProjects;
      } else {
        ({ templateProject: state.templateProject } = props);
      }
      if (state.templateProject && state.templateProject._id) {
        let fetchedTemplates = null;
        if (isEmpty(props.templateOrders)) {
          fetchedTemplates = await SupplyChain.supplyChain({
            projectId: state.templateProject._id,
            module: ['preparation', 'sourcing', 'ordering'].includes(stage)
              ? 'MatTemplates'
              : 'ProdTemplates',
            filterNoItemOrders: false,
            limit: 9999,
            page: 1,
          });
        } else {
          ({ templateOrders: fetchedTemplates } = props);
        }
        state.orderList = filter(fetchedTemplates.data, (list) => {
          if (!isEmpty(rowData) && list.purpose === rowData.purpose
          && ['all', 'coordination'].includes(list.stageUsed)) return true;
          return false;
        });
        if (props.isVarying) {
          state.orderList.push({
            name: '(Varies)',
            templateName: '(Varies)',
          });
        }
      }
    });

    return {
      ...toRefs(state),
      inputValue,
      disableSelection,
    };
  },
};

</script>
