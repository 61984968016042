<template>
<div class="columns">
  <div class="column pt-2" v-for="col of cols" :key="col">
     <div class="field" v-for="date in cardProps.dates[col]" :key="date.label">
          <div class="field-label">
            <label class="label"> {{date.label}} </label>
          </div>
          <div class="field-body">
            <mf-date :item="date.item" :input-props="{
                                  kind: date.inputProps.kind,
                                  min: date.inputProps.min,
                                  max: date.inputProps.max,
                                }"
                              :is-edit="date.isEdit" :isModal="true">
            </mf-date>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Render dates for multiple columns inside move modal (expects a dates
object with cols as keys in this case (col1 and col2) to render columns
*/
import MfDate from '@/components/abstract/MfDate.vue';

export default ({
  props: {
    cardProps: Object,
    cols: {
      type: Array,
      default: () => ['col1', 'col2'],
    },
  },
  components: {
    'mf-date': MfDate,
  },
});
</script>
