import GenerateRequest from './resources';

const req = GenerateRequest();
export default {
  async getUserNotifications(params = {}) {
    params = {
      limit: 25,
      page: 1,
      sort: 'name',
      ...params,
    };
    try {
      const result = (await req.get(`notifications/${params.id}`, {
        params,
      })).data;
      return {
        data: result.data,
        total: result.totalCount,
        page: result.page,
      };
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getNotifications(httpOptions = {}) {
    httpOptions.params = {
      limit: 25,
      page: 1,
      sort: 'name',
      ...httpOptions.params || {},
    };
    try {
      const result = (await req.get('notifications/getNotifications', httpOptions)).data;
      return {
        data: result.data,
        total: result.totalCount,
        page: result.page,
      };
    } catch (e) {
      throw e;
    }
  },
  async getNotifyUsers(httpOptions = {}) {
    httpOptions.params = { cache: true, ...httpOptions.params || {} };
    return (await req.get('notifications/listNotifiedUsers', httpOptions)).data;
  },
  async sendNotification(params) {
    return (await (req.post('notifications/sendNotification', params))).data;
  },
  async getLocalNotifications(params) {
    try {
      const result = (await req.get('getLocalNotifications', {
        params: { startDate: params.startDate },
      }));
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
