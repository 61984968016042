import {
  sum, find, findIndex, isEmpty, get,
} from 'lodash';
import InventoryIncrementMixin from '@/components/mixins/InventoryIncrementMixin';

const { archiveItems } = InventoryIncrementMixin();

function getWidth(cols) {
  const width = cols.map((col) => col.width);
  width.forEach((w, i) => {
    width[i] = Number(w.replace('px', ''));
  });
  return (sum(width));
}

function getNestedCols(alteredTableCols, actionFieldRequired) {
  const colIds = ['measure', 'measureUnits', 'available', 'minQuantity',
    'maxQuantity', 'delivery', 'reserved', 'consumed', 'notes'];
  const ids = [];
  const cols = [];
  const visibleCols = alteredTableCols.filter((col) => col.visible === true);
  visibleCols.forEach((col) => {
    const isVisible = colIds.includes(col.id);
    if (isVisible) ids.push(col.id);
  });
  ids.unshift('name');
  ids.forEach((id, index) => {
    if (id === 'name') {
      const res = visibleCols.slice(0, findIndex(visibleCols, { id: ids[index + 1] }));
      const width = getWidth(res);
      cols.push({
        width: `${width + 24}px`,
        title: '',
        name: 'name',
        id: 'name',
      });
    } else if (id === 'delivery') {
      const res = visibleCols.slice(
        findIndex(visibleCols, { id: ids[index] }),
        findIndex(visibleCols, { id: ids[index + 1] }),
      );
      let width = getWidth(res);
      if (ids.length === (index + 1) && !actionFieldRequired) {
        width += getWidth([visibleCols[visibleCols.length - 1]]);
      }
      const colDetails = find(visibleCols, { id });
      cols.push({
        width: `${width}px`,
        id: colDetails.id,
        name: '',
        inputProps: colDetails.inputProps,
        prop: colDetails.prop,
        restrict: colDetails.restrict,
        classRight: 'is-pulled-right',
        formatter(item) {
          return item.delivery < 0 ? 0 : item.delivery;
        },
      });
    } else {
      const res = visibleCols.slice(
        findIndex(visibleCols, { id: ids[index] }),
        findIndex(visibleCols, { id: ids[index + 1] }),
      );
      let width = getWidth(res);
      if (ids.length === (index + 1) && !actionFieldRequired) {
        width += getWidth([visibleCols[visibleCols.length - 1]]);
      }
      let colDetails = find(visibleCols, { id });
      cols.push({
        width: `${width}px`,
        id: colDetails.id,
        name: colDetails.name,
        inputProps: colDetails.inputProps,
        prop: colDetails.prop,
        restrict: colDetails.restrict,

      });
      if (ids.length === (index + 1) && actionFieldRequired) {
        width = getWidth([visibleCols[visibleCols.length - 1]]);
        colDetails = find(visibleCols, { id: 'action' });
        if (!isEmpty(colDetails)) {
          cols.push({
            width: '95px',
            id: colDetails.id,
            name: colDetails.name,
            inputProps: {
              locationTree: true,
              showEdit(rowData) {
                return isEmpty(rowData.rootLoc.children) && rowData.key !== 'showMore';
              },
              showDelete(rowData) {
                return isEmpty(rowData.rootLoc.children)
                  && rowData.key !== 'showMore';
              },
              disableDelete(rowData) {
                return get(rowData, 'reserved', 0) !== 0;
              },
              archiveItems,
            },
            permissionModule: 'inventory',
            permissionKey: 'save',
          });
        }
      }
    }
  });
  return cols;
}

export default getNestedCols;
