// Table cols for Assemblies and Parts Table
export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  isCheckable: true,
  stickeyHeaders: true,
  gutterOpts: {},
  toolBar: false,
  isCardView: true,
  fields: [
    {
      name: 'name',
      width: '400px',
      title: 'Name',
      id: 'name',
      sticky: true,
      sortField: 'name',
      prop: 'name',
    },
    {
      name: 'prefVendor',
      width: '152px',
      title: 'Pref Vendor',
      prop: 'prefVendor',
      id: 'prefVendor',
    },
    {
      name: 'code',
      width: '152px',
      title: 'Vendor SKU/Part #',
      prop: 'code',
      id: 'code',
    },
    {
      name: 'defVendor',
      width: '152px',
      title: 'Default Vendor',
      prop: 'defVendor',
      id: 'defVendor',
    },
    {
      name: 'desc',
      width: '296px',
      title: 'Description',
      prop: 'desc',
      id: 'desc',
    },
    {
      name: 'field-generic-input',
      width: '152px',
      title: 'Category',
      id: 'category',
      prop: 'category[0].name',
    },
    {
      name: 'field-generic-input',
      width: '152px',
      title: 'Sub Category',
      id: 'subCat-name',
      prop: 'subCategory[0].name',
    },
    {
      name: 'unitCost',
      width: '152px',
      title: 'Item Cost',
      prop: 'unitCost',
      id: 'unitCost',
    },
    {
      name: 'leadTime',
      width: '152px',
      title: 'Lead Time (Days)',
      prop: 'leadTime',
      id: 'quantity',
    },
  ],
};
