import _ from 'lodash';
import { useToast } from 'vue-toastification';
import Catalogs from '@/models/Catalogs';
import Vendors from '@/models/Vendors';
import Helper from '@/models/Helper';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import SupplyChain from '@/models/SupplyChain';
import { useStore } from 'vuex';
import ProductionManagerMixin from './ProductionManagerMixin';

export default function () {
  const store = useStore();
  const toast = useToast();
  const { addPMItem } = ProductionManagerMixin();
  const attachVendorsAndCategories = async (
    card,
    parts = [],
    fromExcel = false,
    route,
  ) => {
    /*
      this function is to add vendors, categories after importing from catalogs or excel
      Also to calculate UnitCost and TotalCost
        Unit cost is taken from part's vendors.
        total cost = unitCost * item.quantity
    */
    const { vendor: cardVendor } = card.baseDelivery;
    let catIds = _.map(card.items, (item) => {
      if (item.isNew || item.isSubstitute) return item.catId;
      return null;
    });
    catIds = _.uniq(_.compact(catIds));
    if (_.isEmpty(catIds)) return;
    let indexedCatIds = [];
    if (_.isEmpty(parts)) {
      indexedCatIds = (await Catalogs.getAssemblyParts({
        catId: catIds,
        type: 'UpcItems',
      })).data;
    } else {
      indexedCatIds = parts;
    }
    const categories = _.uniq(_.map(indexedCatIds, 'category._id'));
    indexedCatIds = _.keyBy(indexedCatIds, 'catId');
    let indexedCategories = {};
    if (!_.isEmpty(categories)) {
      const { data: vendors } = await Vendors.getVendors({
        category: categories,
      });
      if (vendors && vendors.length > 0) {
        indexedCategories = Helper.getCategoriesFromVendors(vendors);
      }
    }
    // set default vendor for the item (defVendor)
    for (const item of card.items) {
      if (!_.isEmpty(item.catId) && _.isEmpty(item.category)) {
        item.category = indexedCatIds[item.catId] ? indexedCatIds[item.catId].category : '';
      }
      item.vendors = item.vendors ? item.vendors : [];
      if (item.catId && !_.isEmpty(indexedCatIds[item.catId])) {
        const vendorsToPush = indexedCatIds[item.catId].vendor;
        vendorsToPush.forEach((ven) => {
          if (!(item.vendors.some((v) => v._id === ven._id))) {
            item.vendors.push(ven);
          }
        });
      }

      const defVendor = _.filter(item.vendors, { isDefault: true });
      if (defVendor) {
        if (defVendor.length > 1) {
          item.defVendor = _.find(defVendor, { name: item.activeSupplier, isDefault: true });
        } else {
          item.defVendor = _.find(defVendor, { isDefault: true });
        }
      }
    }

    // TODO: check this code for leadTime null in sourcing
    if (['preparation', 'sourcing', 'ordering'].includes(route.params.stage)) {
      await card.calcItemCostAndDate(parts, cardVendor, 'leadTime', fromExcel);
    }
    if (['sourcing', 'ordering'].includes(route.params.stage)) {
      await card.calcItemCostAndDate(parts, cardVendor);
    }
  };
  const materialItemsImport = async ({
    itemsToAdd,
    rowData: card,
    route,
    data,
    activeScreen,
  }) => {
    for (let i = 0; i < itemsToAdd.length; i++) {
      const existingSavedCardItem = card.items.filter((cardItem) => (cardItem.catId === itemsToAdd[i].catId));
      if (existingSavedCardItem.length) {
        toast.error(`Item with same catalog id ${itemsToAdd[i].catId} already exists`);
        return;
      }
    }
    itemsToAdd.forEach((item) => {
      const existingItem = _.find(card.items, (i) => (i.catId === item.catId));
      if (!existingItem) {
        const itemObj = _.pick(item, ['catId', 'customId', 'measureUnits', 'name', 'activeSupplier', 'leadTime', 'purchase', 'vendor', 'vendors']);
        itemObj.isEditing = true;
        itemObj.category = !_.isEmpty(itemObj.category) ? itemObj.category : '';
        if (['sourcing', 'ordering'].includes(route.params.stage)
        && route.params.cardId === 'add') itemObj.stage = 'preparation';
        if (['sourcing', 'ordering'].includes(route.params.stage)) {
          itemObj.purchase = true;
        }
        itemObj.fromMasterCatalog = true;
        if (activeScreen === 'schedule') {
          if (data?.vendor?._id) {
            const vendor = _.find(item.vendor, { _id: data.vendor._id });
            itemObj.leadTime = _.get(vendor, 'leadTime', 0);
          }
          if (!_.isEmpty(itemObj.measureUnits)) {
            itemObj.measure = 1;
          }
        }
        const index = card.addItem(itemObj);
        card.items[index].newFTD('memos');
      } else {
        toast.error('Item with same catalog id already exists');
      }
    });
    await attachVendorsAndCategories(card, itemsToAdd, false, route);
  };
  const formatArray = (val, mapKey = 'name') => _.map(val, mapKey).join(', ');

  const prefabsItemsImport = async ({
    itemsToAdd,
    rowData: card,
  }) => {
    const itemExists = [];
    if (itemsToAdd) {
      itemsToAdd.forEach((item) => {
        const existingItem = _.find(card.items, (i) => i.catId === item.catId);
        if (existingItem) {
          itemExists.push(item.catId);
        } else {
          item = _.pick(item, ['catId', 'customId', 'measureUnits', 'name']);
          item.isEditing = true;
          item.fromMasterCatalog = true;
          item.purpose = card.purpose === 'kit' ? 'assembly' : 'general';
          const index = card.addItem(item);
          card.items[index].newFTD('memos');
        }
      });
      if (!_.isEmpty(itemExists)) {
        const itemNames = formatArray(itemExists, _.toString);
        toast.error(`Item with ${itemNames} catalog id's already exists.`);
      }
    }
  };
  const POItemsImport = async (
    {
      itemsToAdd,
      rowData: card,
      selectedPrefab,
      prefabList,
    },
  ) => {
    const itemExists = [];
    if (itemsToAdd) {
      itemsToAdd.forEach((item) => {
        const existingItem = _.find(card.items, (i) => i.catId === item.catId)
        || _.find(
          (_.find(prefabList, { _id: selectedPrefab }).items),
          (i) => i.catId === item.catId,
        );
        if (existingItem) {
          itemExists.push(item.catId);
        } else {
          item = _.pick(item, ['catId', 'customId', 'measureUnits', 'name']);
          item.isEditing = true;
          item.fromMasterCatalog = true;
          item.purpose = card.purpose === 'kit' ? 'assembly' : 'general';
          item.cards = [selectedPrefab, card._id];
          item.prefab = { _id: selectedPrefab };
          let index;
          // Calling the pm-edit addItem because we want this item to be added to a run.
          if (card.isManager) {
            index = addPMItem(card, item);
          } else {
            index = card.addItem(item);
          }
          card.items[index].newFTD('memos');
        }
      });
      if (!_.isEmpty(itemExists)) {
        const itemNames = formatArray(itemExists, _.toString);
        toast.error(`Item with ${itemNames} catalog id's already exists.`);
      }
    }
  };
  const addFromCatalog = async (params) => {
    const { refreshTable } = params;
    if (['Materials', 'Sourcing'].includes(params.rowData.__t)) {
      await materialItemsImport(params);
    } else if (params.rowData.__t === 'Prefabs') {
      await prefabsItemsImport(params);
    } else if (params.rowData.__t === 'ProductionOrder') {
      await POItemsImport(params);
    }
    if (refreshTable) await refreshTable();
    CardDirtyBus.emit('setCustomDirty');
  };
  const mItemSubstitute = async (newItem, params, rowData, refreshTable, substitutedItem = {}) => {
    if (params.data.archived.value) {
      toast.error('Cannot substitute, item archived!');
    } else if (!_.isEmpty(newItem)) {
      const existingItem = _.find(rowData.items, (i) => i.catId === newItem.catId);
      if (existingItem) {
        toast.error('Item with same catalog id already exists');
      } else {
        let catIdInReq = false;
        if (rowData.isSourcing()) {
          const matCardIds = _.uniq(_.map(params.data.materialItems, 'rootId'));
          const SupplyChainParams = {
            module: 'Materials',
            projectId: rowData.project._id,
            orderId: matCardIds,
            filterNoItemOrders: false,
            showAllCompanyOrders: false,
          };
          const { data: matCards } = await SupplyChain.supplyChain(SupplyChainParams);
          const matItems = _.flatMap(matCards, 'items');
          const catIds = _.map(matItems, 'catId');
          catIdInReq = catIds.some((catId) => catId === newItem.catId);
        }
        if (catIdInReq) {
          toast.error('Item with same catalog id already exists in requesting order');
        } else {
          if (!newItem.customId) newItem.customId = '';
          _.assign(params.data, _.pick(newItem, ['customId', 'name', 'catId', 'category', 'measureUnits', 'purchase']));
          params.data.isSubstitute = true;
          params.data.getItemLeadTime({
            type: 'leadTime',
            fromExcel: false,
            item: params.data,
            existingCatalogs: [newItem],
            selectedVendor: params.data.order.baseDelivery.vendor,
          });
          params.data.costCode = '';
          if (params.data.stage === 'rejected' && rowData.isMM()) {
            params.data.stage = 'preparation';
            if (!_.isEmpty(params.data.simpleMemos)) {
              params.data.simpleMemos[0].text = '';
            }
            if (!_.isEmpty(params.data.simpleFiles)) {
              //params.data.simpleFiles = [];
              params.data.simpleFiles.forEach((file) => {
                const foundSource = _.find(file.sources, source => source._id === substitutedItem._id);
                if (foundSource) {
                    file.archived.value = true;
                }
              })
            }
          }
          params.data.calculateEarliestOrderByDate();
          toast.success('You successfully substituted an item!', 'is-success');
          CardDirtyBus.emit('setCustomDirty');
        }
      }
    }
    await refreshTable();
  };

  return {
    addFromCatalog,
    mItemSubstitute,
  };
}
