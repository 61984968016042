<template>
  <h4 class="is-size-3 has-text-black-bis mb-3">
    {{ title }}
    <mf-add-notify
      :options="options"
      :value="notifyUsers"
      :title="title"
      @update:value="updateValue"
    ></mf-add-notify>
  </h4>
  <div>
    <div
      class="button has-background-black-bis has-text-white taghover"
      v-for="user in notifyUsers"
      v-bind:key="user.id"
      @click="removeEmp(user)"
    >
      <span class="is-size-5">{{ user.name }}</span>
        <i class="icon-removedelete has-text-white" ></i>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs,
} from 'vue';
import MfAddNotify from '@/components/modals/MfAddNotify.vue';

export default defineComponent({
  name: 'also-notify',
	emits:['addNotifier'],
  components: { 'mf-add-notify': MfAddNotify },
  props: {
    value: {
      type: Array,
      default: [],
    },
    options: Array,
    title: String,
  },
  setup(props, { emit }) {
    const data = reactive({
      isEnabled: true,
      isOpen: false,
      notifyUsers: props.value,
    });

    const methods = {
      updateValue(notifyUsers) {
        data.notifyUsers = notifyUsers;
        emit('addNotifier', data.notifyUsers);
      },
      removeEmp(user) {
        data.notifyUsers = data.notifyUsers.filter((i) => i.id !== user.id);
        emit('addNotifier', data.notifyUsers);
      },
    };

    const cancel = () => {
      data.isEnabled = false;
    };
    return {
      ...toRefs(data),
      ...methods,
      cancel,
    };
  },
});
</script>
