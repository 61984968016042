<template>
  <o-modal :active="isActive" :canCancel="false">
    <div class="modal-card">
      <header class="modal-card-head is-borderless">
        <p class="card-header-title is-justify-content-left has-text-black-bis">
          Schedule Updates: On-Site Dates
        </p>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="closeModal"></i>
      </header>
      <section class="modal-card-body is-visible has-text-black-bis">
          <p class="line-height mb-2" v-html="message"></p>
          <div class="line-height has-text-black-bis">
            <input type="radio" @click="option = 'acceptUpdate'" v-model="option"
              :checked="option === 'acceptUpdate'" id='acceptUpdate'
              value="acceptUpdate" />
              <label for='acceptUpdate' class="has-text-black-bis">Accepted</label>
          </div>
          <div class="line-height has-text-black-bis">
            <input type="radio" @click="option ='rejectUpdate'" v-model="option"
              :checked="option === 'rejectUpdate'" id='rejectUpdate'
              value="rejectUpdate" />
              <label for="rejectUpdate" class="has-text-black-bis">Rejected</label>
          </div>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
          <button class="button is-outlined" @click="closeModal">
            Cancel
          </button>
          <button class="button has-background-black-bis"
            :disabled="!option"
            @click="updateSchedule">
              OK
          </button>
      </footer>
    </div>
  </o-modal>
</template>
<script>
import { computed, reactive, toRefs } from 'vue';

export default {
  props: {
    isActive: {
      type: Boolean,
    },
    cards: Array,
  },
  setup(props, { emit }) {
    const state = reactive({
      option: '',
    });

    const message = computed(() => "An Activity's new On-Site date should be:");

    const methods = {
      closeModal() {
        emit('close-modal');
        emit('close');
      },
      updateSchedule() {
        emit('update-schedule', { value: state.option });
        emit('close');
      },
    };

    return {
      ...toRefs(state),
      ...methods,
      message,
    };
  },
};
</script>
