<template>
  <div>
    <div class="modal-card">
       <header class="modal-card-head">
          <h4 class="modal-card-title is-size-4 has-text-black-bis
          is-capitalized has-text-weight-bold">
            {{cardHeader}}
           </h4>
          <div class="is-divider-vertical"></div>
          <i class="icon-close is-clickable" @click="$emit('close')"></i>
      </header>
      <section class="modal-card-body is-marginless is-visible">
        <div class="columns">
          <div class="column">
            <h3 v-if="!isUnscheduled" class=" has-text-black-bis has-text-weight-bold is-italic">
              Delivery : <span class="has-text-black-bis has-text-weight-light is-size-5 has-text-weight-semibold">{{deliveryName}} </span>
            </h3>
            <h3 v-if="isUnscheduled" class="has-text-black-bis my-4">
              These items are not scheduled for Delivery.
            </h3>
          </div>
          <div class="column has-text-black-bis is-flex is-justify-content-flex-end">
            <field-stage
              v-if="isShortReceive"
              :isEditing="true"
              :isStatus="true"
              :shipType="rowData.shipType"
              :value="rowData.status"
              :shippingItems="rowData.items"
            ></field-stage>
          </div>
        </div>
        <search-bar
          placeholder="Search"
          :shouldEmit="shouldEmit"
          @search="getSearchValue"
        ></search-bar>
          <div class="my-2">
            <mf-table
              :tableProps="tableProps"
              :apiMode="false"
              :loadData="loadData"
              :hideGutter="true"
              ref="itemsTable"
              :checkedRows="checkedRow"
              @checkbox-toggled="getSelectedRow"
            >
              <template v-slot:qty-needed="{ rowData : item }">
                <qty-input
                  v-model.number="item.selectedQty"
                  :value="item.quantity"
                  :roundTo="4" :max="maxQtyAllowed(item)"
                  :isDisabled="$store.state.activeScreen === 'shipping-order-view'"
                >
                </qty-input>
              </template>
               <template v-slot:actualy-qty="{ rowData : item }">
                <qty-input
                  v-model.number="item.selectedQty"
                  :value="(item.underDeliver ? (item.actualQty - item.underDeliver).toFixed(4) : item.actualQty.toFixed(4))"
                  :roundTo="4" :max="maxQtyAllowed(item)"
                  :isDisabled="true"
                >
                </qty-input>
              </template>
            </mf-table>
          </div>
        </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <div>
          <button
            class="button is-outlined"
            @click="cancel()"
            v-if="showButton('cancel')"
          >
            Cancel
          </button>
          <button
            class="button has-background-black-bis"
            @click="updateItems(false)"
            :disabled="isDisabled"
            v-if="showButton('done')"
          >
            Done
          </button>
          <button
            class="button has-background-black-bis"
            @click="updateItems"
            :disabled="isDisabled"
            v-if="showButton('update')"
          >
            Update
          </button>
          <button
            class="button has-background-black-bis"
            @click="createDelivery"
            v-if="showButton('createDelivery')"
            >
            Create Delivery
          </button>
          <button
            class="button has-background-black-bis"
            @click="cancel()"
            v-if="isUnscheduled"
          >
            Done
          </button>
        </div>
      </footer>
     </div>
    <o-loading
      :full-page="true"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>

<script>
import {
  computed,
  defineComponent, onMounted, reactive, toRefs, ref,
  inject,
} from 'vue';
// import Catalogs from '@/models/Catalogs';
import { filter, set, cloneDeep } from 'lodash';
import uuid from 'uuid/v4';
import { useToast } from 'vue-toastification';
// import Validation from '@/utils/Validations';
import deliveryItemsViewCols from '@/components/table-cols/deliveryItemsViewCols';
import unscheduledItemsViewCols from '@/components/table-cols/unscheduledItemsViewCols';
import SearchBar from '@/components/SearchBar.vue';
import QtyInput from '@/components/fields/QtyInput.vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import Shipping from '@/models/Shipping';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'DeliveryItems',
  props: [
    'value',
    'rowField',
    'rowData',
    'isUnscheduledRow',
  ],
  components: {
    MfTable,
    QtyInput,
    'search-bar': SearchBar,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const emitter = inject('emitter');
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const { fetchUnscheduledRow } = GeneralShippingMixin();
    const { activeScreen } = store.state;
    const state = reactive({
      shouldEmit: true,
      deliveryName: '',
      searchText: '',
      selectedItems: [],
      partialShipment: {},
      checkedRow: [],
      isLoading: false,
      card: props.rowData,
      isUnscheduled: props.isUnscheduledRow,
      isShortReceive: false,
      unscheduledItems: [],
      mS: {},
    });
    const tableProps = state.isUnscheduled ? unscheduledItemsViewCols : deliveryItemsViewCols;
    if (!state.isUnscheduled) {
      if (['in-storage', 'fulfilled', 'zombie'].includes(props.rowData.status)) {
        tableProps.isCheckable = false;
        tableProps.fields = tableProps.cols.delivered;
      } else if (props.rowData.status === 'underDelivered') {
        tableProps.isCheckable = false;
        tableProps.fields = cloneDeep(tableProps.cols.underDelivered);
      } else {
        tableProps.fields = tableProps.cols.nonDelivered;
      }
    }
    if (activeScreen === 'shipping-order-view') tableProps.isCheckable = false;
    const itemsTable = ref(null);

    if (props.rowData.shipType === 's' && props.rowData.status === 'in-storage') {
      const isUnderDelivered = props.rowData.items.filter((item) => item.underDeliver && item.underDeliver > 0);
      if (isUnderDelivered.length) {
        state.isShortReceive = true;
      }
    }

    const cancel = (doRefresh = false) => {
      emit('close', { doRefresh });
    };

    const getSelectedRow = (row) => {
      state.selectedItems = row;
    };
    const cardHeader = computed(() => {
      if (state.isUnscheduled) {
        return 'Unscheduled Items';
      }
      return "Delivery's Items";
    });
    const isDisabled = computed(() => {
      if (state.selectedItems.length > 0) {
        return false;
      }
      return true;
    });

    const fetchMasterShipment = async () => {
      const shippingLabelId = route.params.cardId;
      const masterShipment = await Shipping.getPartialShipments({
        shippingLabelId,
      });
      state.mS = masterShipment;
    };

    const fetchUnscheduledItems = async () => {
      // if !isUnscheduledRow then fetch unscheduled rowData
      const rowDetails = await fetchUnscheduledRow(state.mS, []);
      const unscheduledItems = rowDetails.items;
      return unscheduledItems;
    };

    const loadData = () => {
      state.isLoading = true;
      let fetchedData = props.rowData.items;
      state.deliveryName = props.rowData.name;

      // set a unique id for each item
      fetchedData.forEach((item) => {
        set(item, 'uid', uuid());
      });

      // search
      if (fetchedData.length > 0 && state.searchText) {
        fetchedData = filter(fetchedData,
          (item) => item.name.toLowerCase().includes(state.searchText.toLowerCase()));
      }

      // by default select all
      state.checkedRow = fetchedData;
      state.selectedItems = fetchedData;
      state.isLoading = false;
      return filter(fetchedData, (item) => item.quantity);
    };

    const maxQtyAllowed = (item) => {
      // max qty allowed for an item depends on the unscheduled item qty
      if (!state.isUnscheduled && state.unscheduledItems && state.unscheduledItems.length) {
        // find if item exists in unscheduled
        const unscheduledItem = state.unscheduledItems.filter((uItm) => {
          if ((uItm.id === item.id) && (uItm.catId === item.catId)) {
            return uItm;
          }
        });
        let maxQty = item.quantity;
        if (unscheduledItem.length) {
          maxQty = item.quantity + unscheduledItem[0].quantity;
          return maxQty;
        }
        return maxQty;
      }
      return item.quantity;
    };

    // get search value
    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      itemsTable.value.refreshTable();
    };

    const updateItems = async (val = true) => {
      if (!val) {
        cancel();
        return;
      }
      const removeFrom = state.card.shipType === 's-m' ? 'fromSelf' : 'fromPartial';
      state.isLoading = true;
      const selectedItemsMap = {};
      for (const selectedItem of state.selectedItems) {
        const key = selectedItem.catId !== '' ? selectedItem.catId : selectedItem._id;
        selectedItemsMap[key] = selectedItem;
      }
      for (const item of props.rowData.items) {
        const key = item.catId !== '' ? item.catId : item._id;
        const selectedItem = selectedItemsMap[key];
        if (selectedItem && selectedItem.quantity > selectedItem.selectedQty) {
          state.card.removeItem(item, removeFrom, selectedItem.selectedQty);
        } else if (selectedItem && selectedItem.quantity < selectedItem.selectedQty) {
          const qtyToRemove = selectedItem.selectedQty - selectedItem.quantity;
          state.card.addItemFromShipment(selectedItem, state.mS, qtyToRemove);
        } else if (!selectedItem) {
          state.card.removeItem(item, removeFrom, 0);
        }
      }
      await state.card.save();
      state.isLoading = false;
      toast.success('Items updated successfully');
      emit('close', { doRefresh: true });
      // new shipping Id generated if item removed.
      if(removeFrom === 'fromSelf') {
        await router.push({ path: '/logistics/shipping/order-view' });
      }
    };
    const showButton = (type) => {
      if (activeScreen !== 'shipping-order-view') {
        if (['in-storage', 'underDelivered', 'fulfilled', 'zombie'].includes(props.rowData.status) && ['done'].includes(type)) {
          return true;
        }
        if (['in-transit', 'not-started'].includes(props.rowData.status)
            && ['cancel', 'update'].includes(type)) {
          return true;
        }
        if (props.rowData.status === 'unscheduled' && ['cancel', 'createDelivery'].includes(type)) {
          return true;
        }
        if (props.rowData.status === 'underDeliver' && ['done'].includes(type)) {
          return true;
        }
      } else if (['done'].includes(type)) {
        return true;
      }
      return false;
    };
    const createDelivery = async () => {
      state.isLoading = true;
      await fetchMasterShipment();
      // trigger create del modal
      const emitObj = {
        card: props.rowData,
        masterShipment: state.mS,
        isActive: true,
      };
      state.isLoading = false;
      emit('close');
      emitter.emit('toggle:createDelModal', emitObj);
    };

    onMounted(async () => {
      await fetchMasterShipment();
      if (!state.isUnscheduled) {
        state.unscheduledItems = await fetchUnscheduledItems();
      }
      loadData();
    });

    return {
      ...toRefs(state),
      tableProps,
      loadData,
      isDisabled,
      itemsTable,
      getSearchValue,
      cancel,
      updateItems,
      maxQtyAllowed,
      getSelectedRow,
      createDelivery,
      showButton,
      cardHeader,
    };
  },
});
</script>
