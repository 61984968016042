<template>
  <o-tabs
    v-model="index"
    size="small" type="boxed"
    class="tab-header"
    :animated="false"
    @update:modelValue="selectTab"
  >
    <o-tab-item
      v-for="(tab, idx) in tabs"
      :key="idx"
      :label="tab.text"
      :value="idx"
    >
    <!-- note: loads mf-detail-row-tab comp not mf-table directly -->
     <div>
      <slot v-if="index === idx"
        :activeComponent="activeComponent"
        :activeIndex="index"
      ></slot>
    </div>
    </o-tab-item>
  </o-tabs>
</template>

<script>
import {
  defineComponent, onMounted, reactive, toRefs, shallowRef,
} from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'MfactTabs',
  props: {
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const activeComponent = shallowRef(props.tabs[props.activeIndex].component);
    const state = reactive({
      index: props.activeIndex,
    });
    function selectTab(index) {
      state.index = index;
      activeComponent.value = props.tabs[index].component;
      if (['manager-edit-detailing', 'po-edit',
        'manager-edit-manufacturing'].includes(store.state.activeScreen) && props.tabs[index].id === 'materials') {
        emit('saveCard');
      }
      emit('tabName', props.tabs[index].id);
    }
    onMounted(() => {
      selectTab(props.activeIndex);
    });
    return {
      ...toRefs(state),
      selectTab,
      activeComponent,
    };
  },
});
</script>
