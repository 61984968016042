// form print pdf mixin
import print from 'browser-print-js';
import {
  reactive, toRefs, computed, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import {
  get,
  has,
  find,
  startCase,
  capitalize,
  isArray,
  isEmpty,
} from 'lodash';
import urls from '@/urls';
// eslint-disable-next-line import/no-webpack-loader-syntax
import css from '!!raw-loader!@/styles/form-print.no-parse.css';

export default function () {
  const store = useStore();
  const state = reactive({
    isLoading: false,
    templateForm: {},
    order: {},
    getForm: false,
    fromList: false,
    isHeadless: false,
    orderData: {},
  });

  const companyData = computed(() => store.state.companyData);

  const companyImageUrl = () => {
    let url = '';
    const fileGuid = get(companyData, 'logo.fileGuid', '');
    if (companyData._id && fileGuid !== '') {
      url = `${urls.s3}companies/${companyData._id}/${fileGuid}`;
    }
    return url;
  };

  const populateFormSrc = (form) => {
    const todos = isArray(state.order.todos) ? state.order.todos : [state.order.todos];
    const associatedTodo = find(todos, (todo) => todo.archived.value === false
      && find(todo.files, (file) => (file._id && form._id ? file._id === form._id : false)
      || (file.uuid && form.uuid ? file.uuid === form.uuid : false)));

    let src = '';
    form.formType = 'order';
    if (associatedTodo && associatedTodo.sources.length > 1) {
      src = find(associatedTodo.sources, (source) => !['prefabs', 'order', 'material', 'sourcing'].includes(source.type));
      form.formType = src.type;
    }
    if (form.formType === 'run') {
      form[form.formType] = find(state.order.manager.runs, (run) => {
        if (run._id) return run._id.toString() === src._id.toString();
        return run.uuid.toString() === src._id.toString();
      }).name;
    } else if (form.formType === 'item') {
      form[form.formType] = find(state.order.items, (item) => {
        if (isEmpty(item._id)) {
          return item.uuid.toString() === src._id.toString();
        }
        return item._id.toString() === src._id.toString();
      }).name;
    }
  };

  // get the subhjeaders data
  const formSource = (fromList, currentForm) => {
    // Populate formType (run/item/order) inside a card level as that info is present in the todo
    if (!fromList && currentForm.formType !== 'shipping order') {
      populateFormSrc(currentForm);
    }
    // Order Name/ Shipping Order Name
    let subHeader = `${currentForm.formType === 'shipping order' ? startCase(currentForm.formType) : 'Order'}
                         Name : ${currentForm.orderName || state.order.name || state.orderData.name} <br/>`;
    // Form can belong to item or run too. Do not add another level of info if form is present
    // in order level `order` or `shipping order`
    // Optional: Only will be printed if form belongs to Run/ Items
    if (!currentForm.formType.includes('order')) {
      subHeader += `${capitalize(currentForm.formType)} Name: ${currentForm[currentForm.formType]}`;
    }
    return subHeader;
  };

  // Render headers required for printing form
  const getHeader = (fromList, currentForm = null, idx = 0) => {
    // CurrentForm only comes through from list view,
    // if not present treat this.templateForm(prop to formBuilder) as current form.
    if (!currentForm) {
      currentForm = {
        ...state.templateForm,
        formType: has(state.order, 'shipType') ? 'shipping order' : '',
      };
    }

    // If no logo is present show the company name.
    const logoOrName = companyImageUrl() ? `<img class="logo" src=${companyImageUrl()}> `
      : `<div class="logo">${companyData.value.name}</div>`;

    return `
         <!-- When in list view or inside order, need to display Company Logo or name only on the first page. -->
         ${idx === 0 ? logoOrName : ''}

        <span class="title">
        
          Manufacton Form Name: ${fromList ? currentForm.formData.name : state.templateForm.name};
          <br/>
          ${formSource(fromList, currentForm)}
        </span>
        
        <div class="divider"></div>
      `;
  };

  const execPrint = () => {
    const printParamsAndCallBack = {
      printable: 'printJS-form',
      type: 'html',
      documentTitle: '&nbsp;',
      header: `
        ${!state.fromList ? getHeader(state.fromList) : ''}
      `,
      headerStyle: 'font-weight: 700; font-size: 25px;',
      onLoadingStart: () => { state.isLoading = true; },
      onLoadingEnd: () => { state.isLoading = false; },
      style: css,
    };
    print(printParamsAndCallBack);
  };

  const printPdf = async (ev) => {
    state.fromList = ev.fromList;
    if (!state.fromList) {
      state.getForm = ev.getForm;
      state.templateForm = ev.templateForm;
      state.order = !ev.order.order ? ev.order : ev.order.order;
      state.orderData = ev.card;
    }
    await nextTick();
    execPrint();
  };

  return {
    ...toRefs(state),
    printPdf,
    getHeader,
  };
}
