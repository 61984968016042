/**
 * Created by saikrishna on 29/01/19.
 */
import { BaseOrder, Dialog } from './BaseOrder';
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async getCode(params) {
    try {
      const { data } = await req.get(`qr/get/latest/${params.qrCode}&${params.isItemQr || false}`);
      return new BaseOrder(data);
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line consistent-return
  async getLatestOrder(params) {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await req.get(`qr/get/latest/${params.qrCode}`, params, {
        params: { qrCode: params.qrCode },
      });
      return new BaseOrder(data);
    } catch (e) {
      Dialog.alert({
        message: e.data.msg,
        duration: 4500,
        color: 'danger',
      });
    }
  },
  async getQrInfo(params) {
    try {
      const { data } = await req.get(`qr/get/${params.qrCode}`, params, {
        params: { qrCode: params.qrCode },
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async generateBulkQrPdf(params) {
    try {
      const { data } = await req.post('reportings/addQrPdfToQueue', { params });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async getItemQRHistory(params) {
    try {
      const { data } = await req.get(`qr/itemHistory/?itemId=${params.itemId}&cardId=${params.cardId}&qrType=${params.qrType}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async getItemForQr(params) {
    try {
      const { data } = await req.get(`qr/get/itemForQr/${params.baseorderId}&${params.itemId}`);
      return data;
    } catch (error) {
      throw error;    
    }
  }
};
