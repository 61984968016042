<template>
  <div>
    <div v-if="isHeader">
      {{isColumnRequired ? `${rowField.title} *`: rowField.title}}
    </div>
    <div v-else>
      <div v-if="isDateDisabled">
        {{defaultDate}}
      </div>
      <div v-else>
        <VDropdown :shown="isOpen" placement="top">
          <div class="is-clickable has-text-link" @click="isOpen =! isOpen">
            {{ !selectedDate ? 'Set Date': selectedDate }}
          </div>
          <template #popper>
            <div class="card modal-card">
              <header class="card-header has-background-grey-lighter">
                <h4
                  class="card-header-title is-size-3 has-text-black-bis pl-0
                      is-capitalized has-text-weight-bold"
                >
                  Set {{rowField.title}} date
                </h4>
              </header>
              <section class="has-text-black card-content
                template-content is-marginless has-background-white">
                  <h4
                  class="is-size-3 has-text-black-bis pl-0
                      is-capitalized has-text-weight-bold"
                  >
                    {{rowData.name}}
                  </h4>
                  <div class="column">
                    <div class="columns">
                      <div class="column pl-0">
                        <field-generic-input
                            label="Date "
                            labelPosition="is-left"
                            :value="mfPickedDate"
                            placeHolder="mm/dd/yyyy"
                            class="has-text-weight-bold"
                            :disabled="false"
                            :isEditing="true"
                          >
                          </field-generic-input>
                      </div>
                      <div class="column pl-0">
                        <o-checkbox v-model="applyToAll">
                            Apply to All
                        </o-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="is-flex is-justify-content-center column">
                      <field-date
                        :rowData="rowData"
                        :rowField="rowField"
                        :inputProps="rowField.inputProps"
                        :isEdit="true"
                        :showInline="true"
                        dateClass="is-inline"
                        :datePopup="true"
                        @setDate='pickedDate'
                        :isModal="true"
                      >
                      </field-date>
                  </div>
              </section>
              <footer>
                <div class="is-flex card-footer is-justify-content-flex-end
                  has-background-grey-lighter">
                  <div class="buttons">
                    <button
                      @click="clear()"
                      class="button is-outlined has-text-weight-bold"
                    >
                      Cancel
                    </button>
                    <button
                      class="button has-text-weight-bold has-background-black-bis has-text-white"
                      :disabled="setDateDisable()"
                      @click="setDate()"
                    >
                      Set Date
                    </button>
                  </div>
                </div>
              </footer>
            </div>
          </template>
        </VDropdown>
      </div>
    </div>
  </div>
</template>
<script>
import {
  computed, onBeforeMount, reactive, toRefs,
} from 'vue';
import moment from 'moment';
import {
  get, isEmpty, isUndefined, camelCase, cloneDeep, set,
} from 'lodash';
import { BaseOrder } from '@/models/BaseOrder';

export default ({
  name: 'SelectDatePopupField',
  props: {
    rowData: Object,
    rowField: Object,
    isDateDisabled: Boolean,
    isHeader: Boolean,
    selectedDestStage: Object,
  },
  setup(props, { emit }) {
    const state = reactive({
      isOpen: false,
      defaultDate: null,
      selectedDate: null,
      applyToAll: false,
      data: props.rowData,
      inputProps: props.rowField.inputProps,
      mfPickedDate: '',
      val: '',
    });
    const isBo = computed(() => {
      if (!state.data) return false;
      if (!isUndefined(state.inputProps.isBO)) return state.inputProps.isBO;
      return (
        state.data instanceof BaseOrder
        || (state.data.order && state.data.order instanceof BaseOrder)
      );
    });

    const getRealOrderForBO = (kind) => {
      let place = '';
      const isManagerDate = ['detailBy', 'manufactureBy', 'qaBy'].includes(
        kind,
      );
      const itemIsManager = state.data._place === 'manager';
      const itemHasManager = !itemIsManager && state.data.manager;
      if (isManagerDate && !state.data.isManager && !itemIsManager) {
        // PO created from template
        kind = camelCase(`po ${kind}`);
      } else if (isManagerDate && itemHasManager) {
        place = 'manager.';
        state.data = state.data.manager;
      } else if (itemIsManager && !isManagerDate && state.data.order) {
        place = 'order.';
        state.data = state.data.order;
      }
      if (!state.data.simpleDates || !state.data.simpleDates[kind]) state.data.newDate(kind);
      return {
        data: state.data,
        kind: `${place}simpleDates.${kind}.value`,
        value: state.data.simpleDates[kind].value,
      };
    };

    const kind = computed(() => {
      const dateKind = state.inputProps.kind;
      if (isBo.value) {
        return getRealOrderForBO(dateKind).kind;
      }
      return dateKind;
    });

    const displayValue = computed(
      () => {
        let date = get(state.data, kind.value, null);
        if (state.inputProps.kind === 'pickReqOnSite' && _.isEmpty(date)) {
          date = props.rowData.getDate('manufactureBy');
        }
        if (date) return moment(date).format('MM/DD/YYYY');
        if (get(state.inputProps, 'preFillDate', false)) return moment(get(state.inputProps, 'preFillDate')).toDate();
        return null;
      },
    );

    const pickedDate = (dateObj) => {
      if (dateObj.val) {
        state.val = dateObj.val;
        state.mfPickedDate = moment(dateObj.val).format('MM/DD/YYYY');
      }
    };

    const clear = () => {
      // state.selectedDate = state.defaultDate;
      state.isOpen = false;
      emit('selected-date', state.selectedDate, state.applyToAll, kind, state.inputProps, props.rowField.title);
    };

    const setDateDisable = () => isEmpty(state.mfPickedDate);

    const setDate = () => {
      state.selectedDate = state.mfPickedDate;
      state.isOpen = false;
      if (kind.value === 'manager.simpleDates.manufactureBy.value') {
        set(props.rowData, 'simpleDates.pickReqOnSite.value', moment(state.val).toISOString());
        emit('refreshMaterialBy')
      }
      set(props.rowData, kind.value, moment(state.val).toISOString());
      emit('selected-date', state.selectedDate, state.applyToAll, kind, state.inputProps, props.rowField.title);
    };

    onBeforeMount(() => {
      state.defaultDate = cloneDeep(displayValue.value);
      state.selectedDate = state.defaultDate;
    });

    const isColumnRequired = computed(() => {
      const requiredDates = {
        coordination: ['coord'],
        detailing: ['coord', 'detailBy'],
        manufacturing: ['coord', 'detailBy', 'manufactureBy'],
      };
      return requiredDates[props.selectedDestStage.id]?.includes(props.rowField.prop);
    });

    return {
      ...toRefs(state),
      displayValue,
      clear,
      setDateDisable,
      setDate,
      kind,
      isBo,
      getRealOrderForBO,
      pickedDate,
      isColumnRequired,
    };
  },
});
</script>
<style scoped>
.card-content {
  height: 370px;
}
div.v-popper--theme-dropdown .v-popper__inner .card {
  width: 350px;
}
.card-footer {
  height: 104px;
  display: block;
}
::v-deep(.o-chk) {
  float: unset;
}
</style>
