<template>
  <div
    class="has-background-grey-lighter card-basic column p-0"
  >
    <div class="is-flex hero-header is-size-3 columns is-marginless mb-3">
      <div class="column is-6 is-flex is-align-items-flex-end is-align-items-center">
        <i
          class="has-text-white image is-32x32"
          :class="getDynamicClasses"
        >
        </i>
        <input class="input has-text-weight-bold generic-input" v-model="card.name" />
      </div>
      <div class="column is-3 is-flex is-align-items-flex-end is-align-items-center">
        <label class="is-italic">Project </label>
        {{ card.project.name }}
      </div>
      <div class="column  is-3 is-flex is-offset-1 is-align-items-flex-end is-align-items-center">
        <label class="is-italic ml-3">Type </label>
        {{ card.isCutOrder ? 'Cut' : 'Production' }}
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="columns">
          <div
            class="column pause-restart-btn"
            v-for="col of config.basicDetails"
            :key="col.label"
          >
            <base-column :fields="uniqueOrderIdFieldCheck(col.fields)" :card="card" @update-dates="$emit('updateDates')" @cellClicked="onCellClicked" :disablePause="disablePause"> </base-column>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8" v-if="!$_.isEmpty(config.deliveryDetails)">
            <h4 class="title is-capitalized">delivery details</h4>
            <div class="columns">
              <div
                class="column"
                v-for="col of config.deliveryDetails"
                :key="col.label"
              >
                <base-column :fields="col.fields" :card="card"></base-column>
              </div>
            </div>
          </div>
          <div
            class="column is-4"
            v-if="!$_.isEmpty(config.linkedShippingOrders)"
          >
            <div v-for="col of config.linkedShippingOrders" :key="col.label">
              <base-column :fields="col.fields" :card="card"></base-column>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2 pt-0 is-2-fullhd is-3-desktop">
        <h4 class="title is-capitalized">
          dates
          <a
            v-if="showMoreDates"
            @click="showAllDates(config.dates)"
            class="is-pulled-right is-size-5 has-text-weight-normal"
            >all dates</a
          >
        </h4>
        <div v-for="col of config.dates" :key="col.label">
          <base-column
            :fields="col.fields.filter((field) => !field.hideDate)"
            :card="card"
            kind="dates"
          ></base-column>
        </div>
      </div>
    </div>
    <div class="columns" :class="!getAccordionStatus ? 'hide-column' : 'show-column'">
      <div class="column">
        <div class="columns">
          <div class="column" :class="card.isCutOrder ? 'is-4' : ''" v-if="!$_.isEmpty(config.productionLocation)">
            <h4 class="title is-capitalized" v-if="config.productionLocation[0].fields.length">
              production location
            </h4>
            <div
              v-for="col of config.productionLocation"
              :key="col.label"
            >
              <base-column :fields="col.fields" :card="card"></base-column>
            </div>
          </div>
          <div class="column" v-if="!$_.isEmpty(config.destinationLocation)">
            <h4 class="title is-capitalized">destination details</h4>
            <div
              v-for="col of config.destinationLocation"
              :key="col.label"
            >
              <base-column :fields="col.fields" :card="card"></base-column>
            </div>
          </div>
          <div class="column" v-if="!$_.isEmpty(config.linkedBomOrders)">
            <div
              v-for="col of config.linkedBomOrders"
              :key="col.label"
            >
              <base-column :fields="col.fields" :card="card" @event="triggerEvent"></base-column>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column" v-for="col of config.subDetails" :key="col.label">
            <base-column :fields="col.fields" :card="card"></base-column>
          </div>
        </div>
      </div>
      <div class="column is-2 is-2-fullhd is-3-desktop" v-if="!$_.isEmpty(config.financials)">
        <h4 class="is-size-3 is-capitalized">financials</h4>
        <div v-for="col of config.financials" :key="col.label" class="base-financial">
          <base-column
            :fields="col.fields"
            :card="card"
            kind="constant"
          ></base-column>
        </div>
      </div>
      <div v-if="!$_.isEmpty(config.productivity)"
        class="column is-2 productivity-basics is-2-fullhd is-3-desktop">
        <h4 class="is-size-3 is-capitalized">productivity</h4>
        <div v-for="col of config.productivity" :key="col.label">
          <base-column
            :fields="col.fields"
            :card="card"
            kind="constant"
          ></base-column>
        </div>
        <div v-if="hasSpecification">
          <h4 class="is-size-3 is-capitalized">calculated</h4>
          <div v-for="col of config.calculated" :key="col.label">
            <base-column
              :fields="col.fields"
              :card="card"
              kind="constant"
            ></base-column>
          </div>
        </div>
      </div>
      <div class="column is-2 is-2-fullhd is-3-desktop" v-if="$_.isEmpty(config.financials) &&
        $_.isEmpty(config.productivity)">
        <div v-if="hasSpecification">
          <h4 class="is-size-3 is-capitalized">calculated</h4>
          <div v-for="col of config.calculated" :key="col.label">
            <base-column
              :fields="col.fields"
              :card="card"
              kind="constant"
            ></base-column>
          </div>
        </div>
      </div>
    </div>
    <div
      class="column card-collapse is-clickable"
      @click="$emit('accordianChanged', !getAccordionStatus)"
    >
      <div class="is-size-5 has-text-weight-normal is-capitalized">
        <i
          class="icon-disclosearrow"
          :class="!getAccordionStatus ? '' : 'rotate-180'"
        ></i>
        <span class="is-italic" v-if="!getAccordionStatus">show details</span>
        <span class="is-italic" v-else>hide details</span>
      </div>
    </div>
    <date-component
      v-if="loadMoreDates"
      :is-Active="loadMoreDates"
      @update:isActive="loadMoreDates = false"
      :data="datesData"
      :card="card"
    ></date-component>
    <pause-restart-modal
      v-if="isPauseRestartActive"
      :isActive="isPauseRestartActive"
      :rowData="selectedOrder"
      @close="closePauseRestartModal">
    </pause-restart-modal>
  </div>
</template>

<script>
import {
  reactive, toRefs, computed, onMounted,
} from 'vue';
import BaseCardColumn from '@/components/card-edit/BaseCardColumn.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import DateComponent from '@/components/card-edit/DateComponent.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import SupplyChain from '@/models/SupplyChain';
import PauseRestartmodal from '@/components/modals/PauseRestartmodal.vue';
import { useToast } from 'vue-toastification';

import {
  isEmpty, map, find, some, filter,
} from 'lodash';

export default {
  name: 'CardBasics',
  emits: ['updateDates', 'pause', 'accordianChanged'],
  props: {
    type: String,
    card: Object,
    accordionStatus: {
      type: Boolean,
      default: false,
    },
    refreshCard: {
      type: Function,
      default: () => {},
    },
    disablePause: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    'base-column': BaseCardColumn,
    DateComponent,
    'pause-restart-modal': PauseRestartmodal,
  },
  setup(props, {emit}) {
    const store = useStore();
    const toast = useToast();
    const { getConfigDetails, getRestartPermission } = CardEditMixin();
    const state = reactive({
      config: {},
      user: {},
      loadMoreDates: false,
      datesData: {},
      materialsStage: {},
      isPauseRestartActive: false,
      selectedOrder: {},
    });
    state.user = store.state.userData;
    // eslint-disable-next-line max-len
    const configDetails = getConfigDetails(props.type, props.card.isCutOrder ? 'CutOrder':props.card.__t, props.card._customStage || props.card.stage);
    const cardBasicsCols = configDetails.cardBasics;

    const uniqueOrderIdFieldCheck = (cols) => {
      if (props.card?.kitIds?.length) {
        return filter(cols, (col) => col.prop !== 'uniqueOrderId.value');
      }
      return cols;
    };

    if (props.card?.fromSchedule) {
      if (!some(cardBasicsCols.basicDetails[2].fields, { prop: 'submittalStatus' })) {
        cardBasicsCols.basicDetails[2].fields.splice(1, 0, {
          name: 'field-stage',
          prop: 'submittalStatus',
          label: 'Submittal Response',
          inputProps: {
            isDisabled: true,
          },
          isStatus: true,
        });
      }
      if (!some(cardBasicsCols.dates[0].fields, { prop: 'submitBy' })) {
        cardBasicsCols.dates[0].fields.unshift({
          name: 'field-date',
          prop: 'release',
          label: 'Release',
          id: 'release',
          hideDate: true,
          inputProps: {
            disableEdit: false,
          },
        });
        cardBasicsCols.dates[0].fields.unshift({
          name: 'field-date',
          prop: 'approveBy',
          label: 'Approve By',
          id: 'approveBy',
          hideDate: true,
          inputProps: {
            disableEdit: true,
          },
        });
        cardBasicsCols.dates[0].fields.unshift({
          name: 'field-date',
          prop: 'submitBy',
          label: 'Submit By',
          id: 'submitBy',
          hideDate: true,
          inputProps: {
            disableEdit: true,
          },
        });
      }
    }
    state.config = cardBasicsCols;
    const router = useRouter();
    const getDynamicClasses = computed(() => {
      const routeMeta = router.currentRoute.value.meta;
      let classes = '';
      if (routeMeta.activeViewColor) {
        classes = `${routeMeta.activeViewColor} `;
      }
      if (routeMeta.cardEditIcon) {
        classes += `icon-${routeMeta.cardEditIcon}`;
      }
      return classes;
    });
    const showAllDates = (col) => {
      state.loadMoreDates = true;
      [state.datesData] = col;
    };
    const showMoreDates = computed(() => props.card.isPO()
    || props.card.isPM()
    || props.card.isKit()
    || props.card.fromSchedule);

    const methods = {
      async getSubmitDisabeled() {
        if (!isEmpty(props.card.materials)) {
          state.materialsStage = {};
          const params = {
            projectId: props.card.project._id,
            orderId: map(props.card.materials, '_id'),
            filterNoItemOrders: false,
            showAllCompanyOrders: false,
          };
          const data = await SupplyChain.supplyChain(params);
          for (const i of data.data) {
            const item = find(props.card.materials, ['_id', i._id]);
            item.card = i;
            state.materialsStage[i._id] = i.stage;
          }
        }
      },
    };
    const getAccordionStatus = computed(() => props.accordionStatus);
    onMounted(async () => {
      await methods.getSubmitDisabeled();
    });

    const triggerEvent = (e) => {
      if (e.type === 'refresh-card') {
        props.refreshCard();
      }
    };

    const hasSpecification = computed(() => {
      if (props.card.specification && props.card.specification?.itemValue) {
        return true;
      }
      return false;
    })

    const closePauseRestartModal = ((val) => {
      state.isPauseRestartActive = false;
      state.selectedOrder = {};
      emit('pause', {s: state.isPauseRestartActive, type:'close', val: val});
    })

    const onCellClicked = async (e) => {
      const { type, data } = e;
       if (type === 'pauseRestart') {
        state.selectedOrder = data;
        const cannotRestart = await getRestartPermission(data)
        if (data.status === 'pause' && cannotRestart) {
          return toast.error('Only Project admins can restart the order');
        }
        state.isPauseRestartActive = true;
        emit('pause', {s: state.isPauseRestartActive, type:'pause'});
       }
      
    }; 

    return {
      ...toRefs(state),
      getDynamicClasses,
      showAllDates,
      showMoreDates,
      ...methods,
      getAccordionStatus,
      triggerEvent,
      uniqueOrderIdFieldCheck,
      hasSpecification,
      closePauseRestartModal,
      onCellClicked,
    };
  },
};
</script>

<style scoped>
.hide-column {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
.show-column {
  max-height: 650px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}
.productivity-basics ::v-deep(.field .text-overflow.line-height){
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
}
.base-financial ::v-deep(.field span) {
  font-size: 16px;
  float: right;
}
</style>
