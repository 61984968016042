<template>
    <div v-if="isHeader">
        <o-checkbox v-model="selectedAll">
        </o-checkbox>
    </div>
    <div v-else-if="!isHeader">
        <o-checkbox v-model="inputValue">
        </o-checkbox>
    </div>

</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  toRefs,
} from 'vue';
import { get, eq } from 'lodash';

export default defineComponent({
  props: {
    value: Boolean,
    isHeader: Boolean,
    rowData: {
      type: Object,
      default: () => {},
    },
    key: String,
    rowIndex: Number,
    rowField: {
      type: Object,
      default: () => {},
    },
    totalRows: Number,
    totalRowsSelected: Number,
  },
  setup(props, context) {
    const { rowData } = props;
    const selectedAll = computed({
      get: () => eq(props.totalRows, props.totalRowsSelected),
      set: (val) => context.emit('cell-clicked', { type: 'selectAll', value: val }),
    });
    const inputValue = computed({
      get: () => {
        const rowValue = get(props, 'value');
        if (rowValue && Array.isArray(rowValue)) return rowValue.toString();
        return rowValue;
      },
      set: (val) => {
        context.emit('update:value', val);
        context.emit('cell-clicked', { type: 'selectRow' });
      },
    });
    return {
      inputValue,
      selectedAll,
    };
  },
});
</script>
<style scoped>
</style>
