import { some } from 'lodash';
import translator from '@/languages/translator';

const { t } = translator;

function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    isDetailed: false,
    rearrangeCols: false,
  };
}

export default {
  'item-info': {
    ...extraTableParams(),
    fields: [
      {
        name: 'field-generic-input',
        width: '20%',
        title: 'Items',
        prop: 'name',
        id: 'name',
        searchable: true,
        isDisabled: true,
      },
      {
        name: 'field-generic-input',
        width: '10%',
        title: 'Item ID',
        prop: 'customId',
        isDisabled: true,
        id: 'customId',
      }, {
        name: 'table-field-icon',
        width: '5%',
        title: '',
        id: 'lmvIcon',
        customHeader: true,
        inputProps: {
          icon: 'icon-linked-model',
        },
      }, {
        name: 'field-run-select',
        width: '5%',
        title: 'Work Steps',
        id: 'add-run-in-items',
        dataClass: 'overflow-visible',
      }, {
        name: 'item-check',
        width: '5%',
        title: 'Done',
        prop: 'name',
        id: 'name',
        inputProps: {
          type: 'text',
          id: 'itemName',
          disableEdit: true,
          textArea: true,
        },
      }, {
        name: 'field-generic-input',
        width: '5%',
        title: 'QTY',
        prop: 'autoHoursonQty',
        id: 'qty',
        isDisabled: true,
      }, {
        name: 'field-generic-input',
        width: '10%',
        title: 'Cost Code',
        prop: 'costCode',
        id: 'costCode',
        inputProps: {
          type: 'text',
          disableEdit: true,
        },
      },
      {
        name: 'field-generic-input',
        width: '90px',
        title: 'Measure',
        id: 'measure',
        prop: 'measure',
        isDisabled: true,
      },
      {
        name: 'field-measure-unit-select',
        width: '10%',
        title: 'Measure Units',
        id: 'measureUnits',
        prop: 'measureUnits',
        cardProps: {
          fromMasterCatalog: 'fromMasterCatalog',
          isEditing: 'isEditing',
          measure: 'measure',
        },
      },
      {
        name: 'installLocation',
        title: 'Install Location',
        width: '5%',
        id: 'installLocation',
        sortField: 'installLocation',
      },
      {
        name: 'field-generic-input',
        prop: 'level',
        width: '5%',
        title: 'Level',
        id: 'level',
        dataClass: 'overflow-visible',
        isDisabled: true,
      }, {
        name: 'field-generic-input',
        prop: 'zone',
        width: '5%',
        title: 'Zone',
        id: 'zone',
        dataClass: 'overflow-visible',
        isDisabled: true,
      },
      {
        name: 'field-item-docs-icon',
        width: '5%',
        title: 'Docs',
        id: 'simpleFiles',
        allowAdd: true,
        showForm: true,
        for: 'Item',
        allowDelete(file, order) {
          if (some(file.sources, { stage: 'planning' })) {
            return order._customStage === 'planning';
          }
          return true;
        },
      },
      {
        name: 'field-notes-icon',
        title: 'Notes',
        prop: 'simpleMemos[0].text',
        defaultVisible: true,
        width: '10%',
        id: 'notes',
        inputProps: {
          isItem: true,
        },
      },
    ],
  },
  'runs-info': {
    ...extraTableParams(),
    fields: [
      {
        name: 'field-generic-input',
        width: '20%',
        title: 'Work Step',
        prop: 'name',
        id: 'name',
        customSecondaryHeader: true,
        searchable: true,
        isDisabled: true,
        inputProps: {
          type: 'text',
          textArea: true,
        },
        dataClass: 'overflow-visible',
      }, {
        name: 'field-item-select',
        width: '5%',
        title: 'Prefab Items',
      }, {
        name: 'field-date',
        width: '10%',
        title: 'Production Start',
        id: 'start',
        isEdit: false,
        inputProps: {
          pos: 'is-bottom-right',
          kind: 'coord',
          max: 'deliver',
        },
      }, {
        name: 'field-date',
        width: '10%',
        title: 'Production End',
        id: 'end',
        isEdit: false,
        inputProps: {
          pos: 'is-bottom-right',
          kind: 'deliver',
          min: 'coord',
        },
      }, {
        name: 'field-clock-display',
        width: '15%',
        title: 'Planned Hours(Per Item)',
        prop: 'autoHours',
        id: 'hrs',
        inputProps: {
          type: 'number',
          min: '0',
          step: '1',
          max: '999',
        },
      }, {
        name: 'field-clock-display',
        width: '15%',
        title: 'Planned Hours(All Items)',
        prop: 'stats.plannedHrs',
        id: 'stats.plannedHrs',
        inputProps: {
          type: 'text',
          disableEdit: true,
        },
      }, {
        name: 'field-clock-display',
        width: '10%',
        title: 'Actual Hours',
        prop: 'stats.actualHrs',
        id: 'stats.actualHrs',
        inputProps: {
          type: 'number',
          min: '0',
          step: '1',
          max: '999',
        },
      }, {
        name: 'field-productivity-display',
        width: '10%',
        title: 'Productivity',
        inputProps: {
          type: 'text',
          disableEdit: true,
          kind: 'stats.pf',
        },
        dataClass: 'overflow-visible',
      },
      {
        name: 'field-generic-input',
        title: 'Owner',
        width: '10%',
        prop: 'owner.user.name',
        dataClass: 'overflow-visible',
        isDisabled: true,
      },
      {
        name: 'field-location-select',
        title: 'Location',
        width: '10%',
        id: 'location',
        prop: 'location',
        dataClass: 'overflow-visible',
        isDisabled: true,
      },
      {
        name: 'field-item-docs-icon',
        width: '5%',
        title: 'Docs',
        id: 'simpleFiles',
        for: 'Workstep',
        allowAdd: true,
        showForm: true,
      },
    ],
  },
  'task-info': {
    ...extraTableParams(),
    fields: [
      {
        name: 'field-generic-input',
        sortField: 'text',
        width: '35%',
        alwaysVisible: true,
        title: 'Task',
        prop: 'text',
        id: 'text',
        searchable: true,
        isDisabled: true,
      },
      {
        name: 'field-generic-input',
        sortField: 'assignedTo.user.name',
        width: '15%',
        title: 'Assignee',
        prop: 'assignedTo.user.name',
        id: 'assignedTo.user.name',
        defaultVisible: true,
        dataClass: 'overflow-visible',
        isDisabled: true,
      },
      {
        name: 'field-date', // todo: once GenericDate is implemented
        sortField: 'dueDate',
        prop: 'date',
        width: '10%',
        id: 'dueDate',
        type: 'generic-date',
        title: 'Due Date',
        defaultVisible: true,
        inputProps: {
          kind: 'dueDate',
          disableEdit: true,
        },
        dataClass: 'overflow-visible',
      },
      {
        name: 'private',
        width: '5%',
        title: 'Private',
        id: 'private',
        prop: 'private',
        isDisabled: true,
        showIcons: true,
      },
      {
        name: 'field-generic-input',
        sortField: 'status',
        width: '15%',
        title: 'Status',
        prop: 'status',
        dataClass: 'overflow-visible',
        id: 'status',
        defaultVisible: true,
        isDisabled: true,
      },
      {
        name: 'field-generic-input',
        sortField: 'type',
        width: '10%',
        title: 'Type',
        id: 'type',
        prop: 'type',
        isDisabled: true,
        defaultVisible: true,
        dataClass: 'overflow-visible',
      },
      {
        name: 'checklist-docs',
        width: '10%',
        title: 'Docs',
        id: 'files',
        for: 'Task',
      },
    ],
  },
};
