export default {
  defaultSortField: 'title',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  fields: [
    {
      title: '',
      name: 'table-field-radio',
      width: '5%',
      prop: 'selected',
      id: 'selected',
    },
    {
      name: 'field-generic-input',
      width: '30%',
      title: 'Title',
      id: 'title',
      prop: 'title',
    },
    {
      name: 'field-generic-input',
      width: '25%',
      title: 'Spec Section',
      id: 'id',
      prop: 'id',
    },
    {
      name: 'field-generic-input',
      width: '10%',
      title: '#',
      id: 'formatted_number',
      prop: 'formattedNumber',
    },
    {
      name: 'field-generic-input',
      width: '10%',
      title: 'Rev',
      id: 'revision',
      prop: 'revision',
    },
    {
      name: 'field-generic-input',
      width: '20%',
      title: 'Responsible Contractor',
      id: 'responsible_contractor.id',
      prop: 'vendor.name',
    },
  ],
};
