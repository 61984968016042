import { computed } from 'vue';
import {
  castArray, cloneDeep, forEach, get, isArray, isEmpty, isUndefined, filter,
  map, find, concat, uniqBy, compact, uniq,
} from 'lodash';
import defaultStatus from '@/components/DefaultFilterOpts';
import Vendors from '@/models/Vendors';
import { useRoute } from 'vue-router';
import Procore from '@/models/Procore';
import Users from '@/models/Users';
import Locations from '@/models/Locations';
import Projects from '@/models/Projects';
import LocalSort from '@/components/utils/LocalSort';
import UtilityMixin from '@/components/mixins/UtilityMixin';
import store from '../store';
import Companies from '../models/Companies';

export default function () {
  const activeScreen = computed(() => store.state.activeScreen);
  let qryArray = [];
  const route = useRoute();
  const { userData } = store.state;
  let allCompanies = [];
  const { getNegativeList } = UtilityMixin();
  const sortOpts = (data) => LocalSort.filter(data,
    {
      sortField: 'name',
      direction: 'asc',
    }, true);
  const getAllProjComps = async () => {
    const linkedProjects = map(store.state.queryParams.projects, (project) => {
      if (project?.isMaster || !isEmpty(project?.parentProject)) return project?._id;
      return null;
    });
    if (!isEmpty(compact(linkedProjects)) && allCompanies.length) {
      allCompanies = (await Projects.getSubCompany({
        showUser: 'all',
        sendLinkedCompanies: true,
        projectId: linkedProjects,
      }, true)).items;
      allCompanies = filter(allCompanies, (company) => company.status === 'active');
      allCompanies = uniqBy(allCompanies, '_id');
    }
    return allCompanies;
  };
  const getOptions = async (fields) => {
    const filterOpts = {};
    // the below code is added as tablegutter is not available in the lmv-view which loads the projects list on page reload
    if (_.isEmpty(store.state.queryParams._projects)
      && ['lmv-view', 'lmv-view-card'].includes(store.state.activeScreen)) {
      let companyId = store.state.userData.company;
      await store.dispatch('updateActiveProj', companyId);
    }
    // to get all the project locations count and storing in store
    const allProjs = store.state.queryParams._projects;
    const projectIds = allProjs.map((project) => project._id);
    const mapData = {};
    if (!isEmpty(projectIds)) {
      const allLocs = await Locations.allLocationForUser({
        projectId: projectIds,
      }, true);
      store.commit('setQueryParams', { allLocationsLength: allLocs.length });
    }
    if (['production-status-order-view', 'material-status-order-view', 'production-status-item-view', 'material-status-item-view'].includes(store.state.activeScreen)) await getAllProjComps();
    const projects = cloneDeep(store.state.queryParams.projects);
    for (const field of fields) {
      const { id } = field;
      if (id === 'status' && activeScreen.value === 'shipping-order-view') {
        filterOpts[id] = cloneDeep(defaultStatus[id][activeScreen.value]);
      } else if (id === 'stage') {
        if (activeScreen.value === 'material-status-order-view') {
          filterOpts[id] = defaultStatus.materialStatusStages;
        } else if (activeScreen.value === 'production-status-order-view') {
          filterOpts[id] = defaultStatus.productionStatusStages;
        } else {
          filterOpts[id] = defaultStatus.stage;
        }
      } else if (defaultStatus[id]) {
        if (isArray(defaultStatus[id])) {
          filterOpts[id] = defaultStatus[id];
        } else if (!isUndefined(defaultStatus[id][activeScreen.value])) {
          filterOpts[id] = cloneDeep(defaultStatus[id][activeScreen.value]);
        }
      } else if (['project', 'projects'].includes(id)) {
        const projOpts = sortOpts(projects);
        filterOpts[id] = cloneDeep(projOpts);
      } else if (id === 'level') {
        let levels = [];
        const levelsOpts = [];
        forEach(projects, (project) => { levels = concat(levels, project.levels); });
        const uniqLevels = uniq(levels);
        forEach(uniqLevels, (level) => { levelsOpts.push({ _id: level, name: level }); });
        filterOpts[id] = levelsOpts;
      } else if (id === 'zone') {
        let zones = [];
        const zonesOpts = [];
        forEach(projects, (project) => { zones = concat(zones, project.zones); });
        const uniqZones = uniq(zones);
        forEach(uniqZones, (zone) => { zonesOpts.push({ _id: zone, name: zone }); });
        filterOpts[id] = zonesOpts;
      } else if (id === 'companies') {
        let otherCompanies;
        let allProjects=_.cloneDeep(store.state.queryParams._projects);
        let allProjIds = _.map(allProjects, '_id')
        const allLinkedCompanies = await Projects.getSubCompany({
        showUser: 'all',
        sendLinkedCompanies: true,
        projectId: allProjIds,
           }, true);
        const comps = filter(store.state.userData.multiCompanies,
          ({ archived }) => !archived.value);
        filterOpts[id] = !isEmpty(allCompanies)
          ? allCompanies : allLinkedCompanies.items;
      } else if (['suppliers', 'vendor', 'vendors'].includes(id)) {
        if (!mapData.vendors) {
          const { data } = (await Vendors.getVendors({ limit: 1000 }));
          filterOpts[id] = data;
          mapData.vendors = data;
        } else {
          filterOpts[id] = mapData.vendors;
        }
      } else if (id === 'submittals') {
        const projectId = route.params?.projectId;
        const project = find(
          allProjs,
          { _id: projectId },
        );
        let subData = [];
        let submittals = [];
        if (projectId) {
          const procoreSetting = find(
            get(project, 'apis', []),
            { name: 'procore' },
          );
          if (get(procoreSetting, 'name', '') !== '') {
            const params = { projectId };
            subData = await Procore.getListOfSubmittals(params);
          }
          for (const dt of subData) {
            if (!isEmpty(dt)) {
              submittals = subData;
            }
          }
        }
        filterOpts[id] = submittals;
      } else if (['owner', 'owners', 'recipient', 'recipients', 'assignee'].includes(id)) {
        let companyId = userData.company;
        let users = mapData.users || [];
        if (id === 'assignee' && store.state.activeScreen === 'transfer-request' && !users.length) {
          users = (await Users.allUsers({ showUser: 'active' })).data;
        } else if (!users.length) {
          const filteredProjects = store.getters.selectedIdsForKey('filteredProjects');
          companyId = !isEmpty(allCompanies)
            ? map(allCompanies, '_id') : companyId;
          const otherCompIds = store.state.queryParams.otherCompanyIds;
          const actualCompanyIds = castArray(otherCompIds)
          actualCompanyIds.push(companyId)
          const params = await getNegativeList({
            companyIds: ['production-status-order-view', 'material-status-order-view', 'production-status-item-view', 'material-status-item-view'].includes(store.state.activeScreen)? actualCompanyIds : castArray(companyId),
            ignoreCompany: true,
          }, { projectId: route.params.projectId || filteredProjects });
          users = await Users.getCompanyProjectAllLinkedUsers({
            params,
          });
        }
        filterOpts[id] = cloneDeep(users);
        mapData.users = users;
        store.commit('setQueryParams', { allUsers: users });
      } else if (['location', 'locations', 'deliveryLocation', 'nowAt'].includes(id)) {
        const companyId = userData.company;
        const filteredProjects = store.getters.selectedIdsForKey('filteredProjects');
        const allProjectIds = route.params.projectId || filteredProjects;
        let { allLocations } = mapData;
        if (!isEmpty(allProjectIds) && !isEmpty(companyId) && !allLocations) {
          const otherCompIds = store.state.queryParams.otherCompanyIds;
          const actualCompanyIds = castArray(otherCompIds)
          allLocations = await Locations.allLocationForUser({
            companyIds: ['production-status-order-view', 'material-status-order-view', 'production-status-item-view', 'material-status-item-view'].includes(store.state.activeScreen)? actualCompanyIds : castArray(companyId),
            projectId: allProjectIds,
          }, true);
          mapData.allLocations = allLocations;
        }
        allLocations = sortOpts(allLocations);
        filterOpts[id] = cloneDeep(allLocations);
      } else if (id === 'filteredProjects') {
        const projOpts = sortOpts(allProjs);
        filterOpts[id] = cloneDeep(projOpts);
      } else if (id === 'stageChange.oldValue') {
        filterOpts[id] = defaultStatus.oldStage;
      } else if (id === 'stageChange.newValue') {
        filterOpts[id] = defaultStatus.newStage;
      } else if (id === 'category') {
        if (isEmpty(store.state.queryParams.categories)) {
          const allCategories = await Vendors.getCategories({ limit: 500 });
          store.commit('setQueryParams', { categories: get(allCategories, 'data', []) });
        }
        filterOpts[id] = store.state.queryParams.categories;
      } else if (id === 'subCategory') {
        if (isEmpty(store.state.queryParams.category)
          || store.state.queryParams.category.length > 1) {
          store.commit('setQueryParams', { subCategories: [], subCategory: [] });
        } else {
          const categoryId = get(store.state.queryParams.category, '[0]._id', null);
          const subCategories = await Vendors.getSubCategories({ categoryId });
          store.commit('setQueryParams', { subCategories });
        }
        filterOpts[id] = store.state.queryParams.subCategories;
      }
    }
    return filterOpts;
  };

  const applyQueryParams = () => {
    forEach(qryArray, async (qr) => {
      store.commit('setQueryParams', qr);
    });
    qryArray = [];
  };

  const applyFilters = (filters, options, clearAll = false) => {
    const filtersToSend = { ...filters };
    // eslint-disable-next-line prefer-const
    for (let [k, v] of Object.entries(filtersToSend)) {
      if (clearAll || isEmpty(v)) v = [];
      if (get(v, '0.value', '') === 'all') {
        const allSelected = cloneDeep(options[k]);
        allSelected.shift();
        v = allSelected;
      }
      // since by default All options are not selected, while selecting updating the selection
      if (['level', 'zone'].includes(k)) {
        v.forEach((obj) => {
          obj.selected = true;
        });
      }
      if (k === 'project') {
        qryArray.push({ filteredProjects: castArray(v) });
      } else if (k === 'allDates') {
        for (const d in v) {
          if (d.includes('End') && v[d]) {
                 v[d] = new Date(v[d].setHours(23, 59, 59));
          }
        }
        if (qryArray[k]?.length) {
          qryArray[k].push(...v);
        } else {
          qryArray.push({ [k]: v });
        }
      } else if (k === 'stageChange') {
        const stage = cloneDeep(v);
        if (stage.oldValue) {
          qryArray.push({ oldStage: castArray(stage.oldValue) });
        }
        if (stage.newValue) {
          qryArray.push({ newStage: castArray(stage.newValue) });
        }
      } else if (k === 'stage' && activeScreen.value === 'material-status-order-view') {
        qryArray.push({ materialStage: castArray(v) });
      } else if (k === 'nowAt') {
        qryArray.push({ currentLocations: castArray(v) });
      } else if (k === 'status' && activeScreen.value === 'shipping-order-view') {
        qryArray.push({ shippingStatus: castArray(v) });
      } else if (k === 'filteredProjects') {
        qryArray.push({ selectedLmvProject: castArray(v) });
      } else {
        qryArray.push({ [k]: castArray(v) });
      }
    }
    applyQueryParams();
  };

  const clearFilters = (filters, keepVal = []) => {
    const filtersToSend = { ...filters };
    for (const [k, v] of Object.entries(filtersToSend)) {
      if (k === 'project' && keepVal.includes(k)) {
        qryArray.push({ filteredProjects: store.state.queryParams.filteredProjects });
      } else if (k === 'category' && keepVal.includes(k)) {
        qryArray.push({ category: store.state.queryParams.category });
      } else if (k === 'project') {
        qryArray.push({ filteredProjects: store.state.queryParams.projects });
      } else if (k === 'allDates') {
        qryArray.push({ [k]: {} });
      } else if (k === 'filteredProjects') {
        qryArray.push({ selectedLmvProject: store.state.queryParams.selectedLmvProject });
      } else if (k === 'vendor') {
        qryArray.push({ vendor: [] });
      } else if (k === 'stageChange.oldValue') {
        qryArray.push({ oldStage: [] });
      } else if (k === 'stageChange.newValue') {
        qryArray.push({ newStage: [] });
      } else if (k === 'nowAt') {
        qryArray.push({ currentLocations: [] });
      } else if (k === 'status' && activeScreen.value === 'shipping-order-view') {
        qryArray.push({ shippingStatus: [] });
      } else if (k === 'stage' && activeScreen.value === 'material-status-order-view') {
        qryArray.push({ materialStage: [] });
      } else {
        qryArray.push({ [k]: [] });
      }
    }
    applyQueryParams();
  };

  return {
    getOptions,
    applyFilters,
    clearFilters,
  };
}
