<template>
  <div>
    <VDropdown placement="left" :shown="isModalVisible" :autoHide="false">
      <template #popper>
        <div class="card">
          <header class="card-header has-background-grey-lighter">
            <div
              class="card-header-title is-size-3 has-text-black-bis
                  is-capitalized has-text-weight-bold is-paddingless"
            >
              Send Shipping List
            </div>
            <div class="is-divider-vertical" />
            <i class="icon-close is-clickable" @click="cancel()" />
          </header>
          <section class="card-content pt-0 is-marginless has-background-white">
            <div class="has-text-black-bis has-text-weight-bold line-height mb-3">
              Send Shipping List email(s) to the following  recipients({{ emails.length }}/5):
              <h3 class="is-size-3 has-text-weight-bold has-text-black-bis line-height" />
            </div>
            <div class="is-divider m-0 mb-2" />
            <div class="control has-icons-left mb-2">
              <input
                v-model="emailInput"
                class="input pl-1 border-color"
                type="text"
                placeholder="Add Up to 5 Recipients (Press Enter to Add)"
                @keyup.enter="addExternalEmail()"
              />
              <p class="has-text-danger is-flex is-align-items-center"><i class="icon-alert mr-2" /> Press Enter to Add</p>
            </div>
            <div class="tag-list">
              <div
                class="button has-background-black-bis has-text-white taghover m-1 p-1"
                v-for="(emp, idx) in emails"
                :key="idx"
                @click="removeExternalEmail(emp)"
              >
                <span>{{ emp }}</span>
                <i class="icon-removedelete has-text-white" />
              </div>
            </div>
          </section>
          <footer
            class="card-footer is-justify-content-flex-end has-background-grey-lighter"
          >
            <button class="button is-outlined mr-2" @click="cancel">Cancel</button>
            <button class="button has-background-black-bis has-text-white" @click="confirm">
              Send Shipping List
            </button>
          </footer>
        </div>
      </template>
    </VDropdown>
  </div>
</template>
<script>
import {
  computed, reactive,
} from 'vue';
import { toRefs } from '@vue/reactivity';
import { useToast } from 'vue-toastification';
import Validate from '@/components/utils/Validations';

export default {
  name: 'SendShippingList',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isVisible: Boolean,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const data = reactive({
      emails: props.value,
      emailInput: '',
      isOpen: false,
    });

    const isModalVisible = computed({
      get: () => props.isVisible,
      set: (val) => { emit('update:modal', val); },
    });
    const methods = {
      confirm() {
        try {
          emit('sendShippingList');
        } catch (error) {
          throw error.message;
        }
        isModalVisible.value = false;
      },

      cancel() {
        isModalVisible.value = false;
      },

      checkAlreadyExists(email) {
        return data.emails
          .map((val) => val.toUpperCase())
          .includes(email.toUpperCase());
      },

      checkEmail() {
        const isEmailValid = Validate.validateEmail(data.emailInput);
        const isEmailExisting = methods.checkAlreadyExists(data.emailInput);
        if (!isEmailValid) {
          data.toastErrorMessage = 'Please Enter Valid Email';
        } else if (isEmailExisting) {
          data.toastErrorMessage = 'Email Already Present';
        }
        return isEmailValid && !isEmailExisting;
      },
      addExternalEmail() {
        if (data.emails.length < 5 && this.checkEmail()) {
          data.emails.push(data.emailInput);
          emit('update:value', data.emails);
          data.emailInput = '';
        } else {
          if (!data.emails.length < 5 && (!data.toastErrorMessage || data.toastErrorMessage === '')) {
            toast.error('Maximum of 5 emails allowed');
          } else {
            toast.error(data.toastErrorMessage);
            data.toastErrorMessage = '';
          }
          data.emailInput = '';
        }
      },

      removeExternalEmail(email) {
        data.emails = data.emails.filter(
          (val) => val !== email,
        );
        emit('update:value', data.emails);
      },
    };

    return {
      ...methods,
      ...toRefs(data),
      isModalVisible,
    };
  },
};
</script>
<style scoped>
.tag-list {
  height: 145px;
}
</style>
