import {
  reactive, toRefs,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { useToast } from 'vue-toastification';
import ShippingLabel from '@/models/ShippingLabel';
import Shipping from '@/models/Shipping';
import Materials from '@/models/MaterialManager';
import Projects from '@/models/Projects';
import Locations from '@/models/Locations';
import Users from '@/models/Users';
import UtilityMixin from './UtilityMixin';

export default function () {
  const store = useStore();
  const toast = useToast();
  const state = reactive({
    result: {},
    shippingOrders: [],
    projectLocations: {},
    projectUsers: {},
    showLoading: false,
  });
  const { getAllLocations } = UtilityMixin();

  // check if given array has falsy values - false, undefined, NaN, null, "" and 0.
  const hasEmptyValues = (ary) => {
    const l = ary.length;
    for (let i = 0; i < l; i += 1) {
      if (!ary[i]) {
        return true;
      }
    }
    return false;
  };

  const moveFromRequestingToSourcing = (async (cards) => {
    try {
      for (const card of cards) {
        await Materials.pullToSourcing([card._id], card);
      }
      return {
        success: true,
        message: (cards.length > 1) ? `${cards.length} orders moved to Sourcing successfully` : 'Order moved to Sourcing successfully',
      };
    } catch (e) {
      return {
        success: false,
        message: e.message,
      };
    }
  });

  const fetchLocations = async (projectId, isGIProject) => {
    const locs = await getAllLocations(projectId);
    if (isGIProject) {
      _.remove(locs, (loc) => loc.type !== 'gi_location');
    }
    return Locations.groupLocations(locs, store.state.companyData);
  };

  const fetchUsers = async (projectId) => {
    const allUsers = await Projects.linkedProjectUsers([projectId]);
    return Users.groupUsers(allUsers);
  };

  const moveFromSourcingToOrderingStage = (async (orders) => {
    state.shippingOrders = [];
    try {
      let i = 0;
      // create shipping orders
      try {
        orders.forEach((order) => {
          const shippingOrder = new ShippingLabel({
            name: order.name,
            purpose: 'general',
            project: order.project,
            reserveFor: null,
            items: [],
            externalEmails: order.externalEmails,
            generatePdf: true,
            shipType: 's-m',
            fromSchedule: order.fromSchedule,
            scheduleOrder: order.scheduleOrder,
            delivery: {
              vendor: order.baseDelivery.vendor,
              currentProject: order.project._id,
              deliveryProject: order.project._id,
              currentLocation: order.baseDelivery.location,
              deliveryLocation: order.baseDelivery.location,
              owner: order.owner.user,
              recipient: order.baseDelivery.recipient,
              shippingForRequesters: order.shippingForRequesters,
              deliverBy: order.getDate('deliver'),
              deliveryStart: order.getDate('shipBy'),
              notes: _.get(order, 'notes[0]', ''),
            },
          });
          state.shippingOrders.push(shippingOrder);
        });
        // get all details
        orders.forEach(async (order) => {
          if (_.isEmpty(state.projectLocations[order.project._id])) {
            state.projectLocations[order.project._id] = [];
            const locs = await fetchLocations(order.project._id, order.project.isIGI);
            if (locs.length) state.projectLocations[order.project._id] = locs;
          }
          if (_.isEmpty(state.projectUsers[state.projectUsers[order.project._id]])) {
            state.projectUsers[order.project._id] = [];
            const users = await fetchUsers(order.project._id);
            if (users.length) state.projectUsers[order.project._id] = users;
          }
        });

        for (const card of orders) {
          card.stage = 'ordering';
          card.location = card.baseDelivery.location;
          const project = store.getters.findProject({ _id: card.project._id });
          if (project) {
            const stage = project.getDefaults(card, 'ordering');
            if (!_.isEmpty(stage.newOwner)) card.owner.user = stage.newOwner;
            if (!_.isEmpty(stage.newLocation)) {
              card.location = stage.newLocation;
              card.baseDelivery.location = stage.newLocation;
            }
            if (!_.isEmpty(stage.notify)) card.notifyUsers = _.map(stage.notify, '_id');
          }
          try {
            await Materials.moveToNextStageFromCard(card);
          } catch (e) {
            console.log('error', e);
            throw e;
          }
        }
      } catch (e) {
        console.log('error creating shipping orders', e);
        toast.error(`Unexptected Error occured while creating shipping orders ${e.message}`);
        throw e;
      }

      // generate shipping labels
      for await (const slabel of state.shippingOrders) {
        try {
          const isPdfUpdate = slabel.generatePdf;
          let savedSlabels = [];
          let card;
          if (orders.length === 1) {
            [card] = orders;
          } else {
            card = orders[i];
            i += 1;
          }
          if (slabel.shippingForRequesters) {
            try {
              const items = await Shipping.getCompletedItems(card);
              items.forEach((item) => {
                _.set(item, 'selectedQty', item.available);
                item.cardId = card._id;
                slabel.addItemFromCard(item, card);
              });
              const params = {
                id: card._id,
                project: {
                  _id: slabel.project._id,
                },
                deliverBy: slabel.delivery.deliverBy,
                fromSchedule: card.fromSchedule,
                scheduleOrder: card.scheduleOrder,
                deliveryStart: slabel.delivery.deliveryStart,
                notes: slabel.delivery.notes,
                externalEmails: slabel.externalEmails,
              };
              const result = await Shipping.createPurchaseReceipts(params);
              savedSlabels = result.purchaseLabel;
            } catch (e) {
              console.log('Unexpected error occured while creating purchase receipts', e);
              throw e;
            }
          } else {
            try {
              const items = await Shipping.getCompletedItems(card);
              items.forEach((item) => {
                _.set(item, 'selectedQty', item.available);
                item.cardId = card._id;
                slabel.addItemFromCard(item, card);
              });

              const [locDetails] = await Locations.getOne({
                id: _.get(slabel.delivery, 'deliveryLocation._id', ''),
              });
              let { commonStockProject } = store.state.queryParams;
              if (_.get(locDetails, 'nestedLocation', false)) {
                commonStockProject = !_.isEmpty(commonStockProject) ? commonStockProject : await Projects.getCommonStockProject();
                slabel.delivery.deliveryProject = commonStockProject
                  || slabel.delivery.deliveryProject;
              }
              const result = await slabel.save();
              savedSlabels.push(result);
            } catch (e) {
              console.log('Unexpected error occured while creating shipping labels', e);
              throw e;
            }
          }
          // generate shipping pdfs
          if (isPdfUpdate && savedSlabels.length > 0) {
            for (const sl of savedSlabels) {
              await sl.generateShipPdf(false, _.get(sl, 'externalEmails', []));
            }
          }
        } catch (e) {
          console.log('error occured while creating shipping label :', e);
          //   toast.error('Unexpected Error occured while creating associated shipping label !');
          throw e;
        }
      }

      // if all done
      return {
        success: true,
        message: 'Order moved to Ordering successfully',
      };
    } catch (e) {
      return {
        success: false,
        message: `Error occured while moving order : ${e.message}`,
      };
    }
  });

  return {
    ...toRefs(state),
    hasEmptyValues,
    moveFromRequestingToSourcing,
    moveFromSourcingToOrderingStage,
  };
}
