export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  hasCheckBox: false,
  isCheckable: false,
  isDetailed: false,
  stickyHeaders: false,
  toolBar: false,
  fields: [
    {
      name: 'field-generic-input',
      width: '400px',
      title: 'Name',
      prop: 'name',
      id: 'name',
      sticky: false,
      searchable: false,
    }, {
      name: 'field-cat-id',
      prop: 'catId',
      width: '152px',
      title: 'Catalog ID',
      id: 'catId',
    }, {
      name: 'field-generic-input',
      width: '296px',
      prop: 'orderName',
      title: 'Source Order',
      id: 'desc',
    }, {
      name: 'field-generic-input',
      prop: 'quantity',
      width: '112px',
      title: 'QTY',
      id: 'quantity',
    },
  ],
};
