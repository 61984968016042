<template>
  <div :class="positionClass">
    <label class="has-text-black-bis is-italic is-size-3" v-if="label"
      :class="labelPosition === 'is-left' ? 'line-height' : 'label'">
      {{ label }}
    </label>
    <div class="control is-flex-grow-1" v-if="isEditable">
      <span v-if="$_.get(rowField, 'type') === 'checkbox'" class="is-flex is-justify-content-center">
        <o-checkbox v-model="inputValue" :disabled="disableCheckbox"
        :key="updateCheckBoxComp"
        @update:modelValue="onupdate($event)"
        >
        </o-checkbox>
      </span>
      <span v-else>
        <input
          class="input generic-input"
          :type="type"
          v-model.trim="inputValue"
          :class="rowField?.fieldClass"
          :placeholder="getPlaceHolder"
          @change="onChange"
          :disabled="disableInput(rowData)"
        />
      </span>
    </div>
    <div v-else-if="!isFieldClickable" class="has-text-black text-overflow line-height"
      :class="isTextPulledRight ? 'is-pulled-right' : '' ">
      <!-- <div  v-if="!value && !textValue" class="empty-height"></div> -->
      <span v-if="textValue">
        {{ textValue }}
      </span>
      <span v-else-if="$_.get(rowField, 'type') === 'checkbox'"
      class="is-flex is-justify-content-center">
        <o-checkbox v-model="inputValue" v-if="inputValue" :disabled="!isEditable">
        </o-checkbox>
        <i v-else-if="!inputValue && rowField?.id === 'purchase'"></i>
      </span>
      <span v-else :class="[rowField?.id === 'quantity' ? 'is-pulled-right' : '', (rowData?.isAlreadyPresent || rowData?.isAlreadySubAssembly) ? 'has-text-grey-light' : '']"
        v-tooltip="getToolTip">
        {{ inputValue }}
      </span>
      <span v-if="showPercentage">%</span>
    </div>
    <a
      v-else
      class="is-text-link text-overflow is-clickable"
      @click="$emit('cellClicked', { type: eventType })"
      :class="{disabledPer: isDisabledOnPermission}"
      v-permission="{permissionModule, type: getType, project: rowData.project,
        requiredCheck: getRequiredCheck}" v-tooltip="inputValue.length > 80 ? inputValue : ''"
      :href="getOrderUrl()"
      @click.prevent=""
    >
        {{ inputValue }}
    </a>
  </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue';
import {
  isUndefined, get, isFunction, clamp, isEmpty, has, round, some, set,
} from 'lodash';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import screens from '@/constants';
import Helper from '@/models/Helper';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';
import { DialogProgrammatic } from '@/components/Dialog';
import CardEditMixin from '../mixins/CardEditMixin';

export default {
  name: 'GenericField',
  props: [
    'label',
    'value',
    'textValue',
    'type',
    'isEditing',
    'labelPosition',
    'placeHolder',
    'isDisabled',
    'isClickable',
    'eventType',
    'rowField',
    'rowData',
    'isInput',
    'showPercentage',
    'max',
    'clampTo',
    'projectId',
    'roundTo'
  ],
  setup(props, context) {
    const { rowField, rowData } = props;
    const store = useStore();
    const route = useRoute();
    const { disableInput } = ProductionManagerMixin();
    const { disableStatus } = CardEditMixin();
    const state = reactive({
      positionClass: 'field',
      updateCheckBoxComp: 0,
    });
    const { companyData } = store.state;
    const project = computed(() => {
      const projectId = props.projectId || rowData?.project?._id;
      if (projectId) {
        return store.getters.findProject({ _id: projectId });
      }
      return {
        levels: [],
        zones: [],
      };
    });
    // below function is to  check isEditing property from rowField
    // By default, item's isEditing is set to true in onCardLoaded() in CardEditMixin.js
    const isEditable = computed(() => {
      let result = true;
      if (rowField?.isDisabled) {
        if (isFunction(rowField?.isDisabled)) {
          if (rowField.id === 'purchase') return !rowField.isDisabled(rowData, route.params);
          return !rowField?.isDisabled(rowData, store, project.value, companyData);
        }
        return !props.rowField?.isDisabled || false;
      }
      if (props.rowField?.cardProps) {
        result = get(props.rowField.cardProps, 'isEditing', true);
      }
      if (!isUndefined(rowField?.inputProps?.disableEdit)) {
        if (isFunction(get(rowField, 'inputProps.disableEdit', ''))) {
          return !rowField.inputProps.disableEdit(rowData);
        }
        return !(props.rowData?.isAlreadyPresent || props.rowData?.isAlreadySubAssembly || get(rowField, 'inputProps.disableEdit', false));
      }
      if (props.textValue) return false;
      if (!isUndefined(props.isInput)) return props.isInput;
      if (props.rowData?.fromMasterCatalog) return false;

      return props.rowData?.isNew ? props.isEditing : result && props.isEditing;
    });

    if (props.labelPosition) {
      switch (props.labelPosition) {
        case 'is-left':
          state.positionClass = 'field is-horizontal';
          break;
        case 'is-right':
          state.positionClass = 'field is-horizontal has-text-right is-pulled-right';
          break;
        case 'is-centered':
          state.positionClass = 'field is-horizontal has-text-centered';
          break;
        default:
          state.positionClass = 'field';
          break;
      }
    }
    const getPlaceHolder = computed(() => {
      if (!isUndefined(props.placeHolder)) return props.placeHolder;
      return '';
    });
    const inputValue = computed({
      get: () => {
        let rowValue = get(props, 'value');
        if (rowField?.id === 'ordertype') {
          switch (true) {
            case rowData?.isCutOrder:
              return 'Cut';
            case rowData?.purpose === 'general':
              return 'Production';
            case rowData?.purpose === 'kit':
              return 'Kit';
            default:
              return 'Assembly';
          }
        }
        if ((rowField?.type === 'number' || props?.type === 'number') && (rowField?.roundTo || props?.roundTo)) {
          rowValue = parseFloat(clamp(Math.abs(parseFloat(rowValue) || 0), 0, 9999).toFixed(4));
        } 
        if (rowField?.prop === 'extraCost') {
          return round(clamp(rowValue, rowField?.min, rowField?.max), 2).toFixed(rowField?.roundTo);
        }
        if (rowField?.has) return (!has(rowData, rowField.id)) || rowValue;
        if (rowValue && Array.isArray(rowValue)) return rowValue.toString();
        if (rowValue === 0 && (rowData?.isAlreadyPresent || rowData?.isAlreadySubAssembly)) return '';
        if (rowData?.kitIds?.length && rowField?.id === 'uniqueOrderId') return '';
        if (rowField?.id === 'uniqueOrderId' && get(rowData, 'uniqueOrderId.isSystemGenerated', false)) return '';
        return rowValue;
      },
      set: (val) => {
        if (rowField?.id === 'uniqueOrderId' && rowData.uniqueOrderId.isSystemGenerated === true) {
          set(rowData, 'uniqueOrderId.isSystemGenerated', false);
        }
        context.emit('update:value', val);
        Helper.reSubmitStageChange(rowData);
      },
    });
    const getToolTip = computed(() => {
      if (rowField?.id === 'name') {
        return inputValue?.value?.length > 80 ? inputValue.value : '';
      }
      return inputValue?.value?.length > 15 ? inputValue.value : '';
    });
    const getMaxValue = computed(() => (props.max ? Number(props.max) : null));
    const getClampToValue = computed(() => (props.clampTo ? Number(get(rowData, props.clampTo, getMaxValue.value)) : null));
    const onChange = computed(() => {
      if (getClampToValue.value !== null && getClampToValue.value !== 0) inputValue.value = clamp(inputValue.value, 0, getClampToValue.value);
      else if (getMaxValue.value !== null) inputValue.value = clamp(inputValue.value, 0, getMaxValue.value);
    });
    const isTextPulledRight = computed(() => ['measure', 'minQuantity', 'maxQuantity', 'available', 'qtyToReturn',
      'plannedHrs', 'modified', 'actualHrs', 'totalItems', 'totalItems', 'itemPerc', 'qtyCalculated', 'qtyToConsume',
      'runsPerc', 'items', 'total', 'incomplete', 'complete', 'leadTime', 'qtyToShip', 'qtyConsumed', 'unitCost', 'totalCost'].includes(rowField?.id));

    const isDisabledOnPermission = computed(() => {
      const { meta } = route;
      let permissionModule = meta.permissionKey ? meta.permissionKey.split(':')[0] : '';
      let canOpenOrder = true;
      if (['production-status-order-view', 'material-status-order-view'].includes(store.state.activeScreen)
        && !_.isEmpty(props.rowData)) {
        permissionModule = store.getters.getStageWiseModule(props.rowData);
        const disableOpenCard = (props.rowData.owner.company._id !== store.state.userData.company);
        return disableOpenCard;
      }
      if (!isEmpty(permissionModule)) {
        canOpenOrder = store.getters.getPermissionVal({
          permissionModule,
          rowData: props.rowData,
        }, 'openOrder');
      }
      if ((store.getters.isViewer() && !store.getters.viewerHasPermission)
        || !canOpenOrder) return true;
      return false;
    });

    const permissionModule = computed(() => {
      const res = get(screens[store.state.activeScreen], 'permissionModule', '');
      return res;
    });

    const getType = computed(() => {
      if (['scm', 'shipping'].includes(permissionModule.value)) {
        const type = get(screens[store.state.activeScreen], 'permissionKey', '');
        if (type === 'shippingStatus' && route.name === 'shipping'
       && props.rowData) {
          const res = store.getters.hasShippingAccess(props.rowData);
          if (res) return '';
        }
        return type;
      }
      return '';
    });

    const getRequiredCheck = computed(() => {
      if (getType.value === 'shippingStatus') {
        return [{ module: 'shipping', key: 'data' }];
      } if (['productionStatus', 'materialStatus'].includes(getType.value)
        && !_.isEmpty(props.rowData)) {
        return [{ module: store.getters.getStageWiseModule(props.rowData), key: 'data' }];
      }
      return '';
    });

    const isFieldClickable = computed(() => {
      if (isFunction(rowField?.isClickable)) {
        return !rowField?.isClickable(rowData);
      }
      return rowField?.isClickable || false;
    });
    // eslint-disable-next-line consistent-return
    const getOrderUrl = () => {
      if (['inventory', 'transfer-request'].includes(store.state.activeScreen)) {
        return false;
      }
      const order = rowData.order || rowData;
      let orderKind = 'prefabs';
      let projectId;
      let { orderStage } = order;
      if (store.state.activeScreen !== 'schedule-list' && store.state.activeScreen !== 'notifications' && store.state.activeScreen !== 'tasks') {
        if (!order.__t) return false;
        if (order.isPM()) orderKind = 'managers';
        if (order.isMM()) orderKind = 'material';
        if (order.isPO()) orderKind = 'orders';
        projectId = order.project._id;
        orderStage = order.isPO() || order.isPrefab()
          ? '' : order.getCardStage();
        if (['sourcing', 'ordering', 'complete', 'in-transit', 'delivery', 'not-started', 'in-storage', 'full-filled', 'zombie'].includes(order.status) && order.__t === 'Sourcing') orderKind = 'sourcing';
        return `#/${orderKind}/${projectId}/${order._id}/${orderStage}`;
      }
    };

    const isCheckboxDisabled = computed(() => {
      if (rowData && isEmpty(rowData.form?._id)) {
        return false;
      }
      if (rowData && rowField.id === 'isDone' && (rowData?.formStatus === 'complete')) {
        return false;
      }
      if (rowData && ['prefVendor', 'defVendor'].includes(rowField.id)) {
        return false;
      }
      return true;
    });
    const disableCheckbox = computed(() => {
      return disableStatus(rowData.order?.status) || isCheckboxDisabled.value;
    })

    const onupdate = (event) => {
      if (event && store.state.activeScreen === 'manager-edit-manufacturing'
        && some(rowData.runItemsCount, (ri) => (ri._item.stage === 'detailing'
          && ri._item.purpose === 'general'))) {
        // oruga checkbox has reactive issue even the v-model value changes the
        // component was not displaying latest value so manually re-rendering
        // the checkbox compoent
        state.updateCheckBoxComp += 1;
        DialogProgrammatic.confirm({
          title: 'Warning',
          message: `Unable to mark ${rowData.name} as complete because one of `
            + 'the items in this run is in detailing stage.',
          okButton: 'Ok',
          type: 'danger',
        });
      }
    };

    return {
      ...toRefs(state),
      getPlaceHolder,
      inputValue,
      isEditable,
      getMaxValue,
      onChange,
      getClampToValue,
      isTextPulledRight,
      isDisabledOnPermission,
      permissionModule,
      getType,
      getRequiredCheck,
      getToolTip,
      isFieldClickable,
      getOrderUrl,
      isCheckboxDisabled,
      disableInput,
      onupdate,
      disableCheckbox,
    };
  },
};
</script>
<style scoped>
.input {
  padding: 4px !important;
}
::v-deep(.o-chk__label) {
  display: none !important;
}
</style>
