const DARK = '000000';
const LIGHT = 'FFFFFF';

const getContrastYIQ = (hexcolor) => {
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? DARK : LIGHT;
};
const stripNumberSign = (color) => {
  if (color[0] === '#') {
    color = color.substring(1, color.length);
  }
  return color;
};

export default {
  beforeMount(el, binding) {
    if (binding.value) {
      const color = stripNumberSign(binding.value);
      el.style.backgroundColor = `#${color}`;
      el.style.color = `#${getContrastYIQ(color)}`;
      el.style.lineHeight = '24px';
    }
  },
};
