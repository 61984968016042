<template>
    <div class="column project-title is-flex is-align-items-center" v-if="hideProject">
      <button class="button has-background-black-bis" @click="toggleProjectModal"
      :disabled="fromDashBoard" v-if="isProjectVisible">
        <span class="has-text-warning is-flex is-align-items-center is-size-5 line-height">
          <span class="has-text-weight-bold">
            Projects ({{filteredProjectsCount}}/{{totalProjectsCount}}):
          </span>
          </span>
        </button>
        <div class="is-size-5 is-block has-text-white" v-if="isProjectVisible">
          <span v-if="filteredProjectsCount === totalProjectsCount">
            All Projects
          </span>
          <span v-else class="line-height">
            {{ getFilteredProjectsName}}
          </span>
        </div>
      </div>
    <select-projects v-if="isProjectVisible" :isActive="isProjectModal" @cancel="toggleProjectModal"
    type="all" @filter:count="setFiltersCount"/>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { map } from 'lodash';
import SelectProjects from '@/components/modals/SelectProjects.vue';
import { useRoute } from 'vue-router';

export default {
  name: 'ProjectTitleBar',
  components: {
    SelectProjects,
  },
  setup() {
    const store = useStore();
    const data = reactive({
      isProjectModal: false,
      filteredProjectsCount: 0,
      totalProjectsCount: 0,
    });
    const route = useRoute();

    const fromDashBoard = computed(() => route.query.fromDashBoard === 'true');

    const toggleProjectModal = () => {
      data.isProjectModal = !data.isProjectModal;
    };
    // function to set the projects counts for UI
    const setFiltersCount = (filteredCountData) => {
      ({
        filteredProjectsCount: data.filteredProjectsCount,
        totalProjectsCount: data.totalProjectsCount,
      } = filteredCountData);
    };

    // function to filter out projects name for UI
    const getFilteredProjectsName = computed(() => {
      const projectNames = map(store.state.queryParams.projects, 'name').join(', ');
      return projectNames;
    });

    const hideProject = computed(() => !['projects', 'user settings'].includes(route.name));

    // Used for Setting Visibility For Project select in Assemblies and Parts Screen.
    const isProjectVisible = computed(() => !['assemblies', 'parts', 'cutParts'].includes(store.state.activeScreen));

    return {
      ...toRefs(data),
      toggleProjectModal,
      setFiltersCount,
      getFilteredProjectsName,
      hideProject,
      fromDashBoard,
      isProjectVisible,
    };
  },
};
</script>
