<template>
  <div class="transfer-card is-flex is-flex-direction-column">
    <div class="column p-0">
      <div class="columns">
        <div class="column is-narrow pr-0"  v-if="showCheckMark">
            <i class="icon-checkmark"></i>
        </div>
        <div class="column" :class="showCheckMark ? 'pl-0' : ''">
          <span class="transfer-card-title is-size-3 has-text-black-bis  has-text-weight-semibold
        line-height text-overflow text-clamp">{{ item.name }}</span></div>
      </div>
    </div>

    <div class="is-flex">
      <div v-for="field in fields" :key="field" class="card-footer-item">
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <h3
            class="
              is-size-3 is-capitalized
              line-height
              is-italic
              has-text-weight-semibold
            "
          >
            {{ field }}
          </h3>
          <div class="transfer-card-qty has-text-weight-bold">
            {{ getItemQuantityCount(field) || "0" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue';
import { get, isString } from 'lodash';

export default {
  name: 'item-card',
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const fieldsMap = {
      stock: ['to stock', 'stocked'],
      pick: ['available', 'need', 'taken'],
    };

    const getToStockCount = computed(() => (get(props.item.quantityMap, 'toStock', 0) - get(props.item.quantityMap, 'stocked', 0)));

    const getAvailableCount = computed(() => props.item.totalQtyAvailable);
    const data = reactive({
      fields: fieldsMap[props.type] || [],
      mapAccessor: {
        available: getAvailableCount,
        need: 'needed',
        taken: 'taken',
        'to stock': getToStockCount,
        stocked: 'stocked',
      },
    });

    const getItemQuantityCount = (field) => {
      if (!isString(data.mapAccessor[field])) return data.mapAccessor[field];
      return props.item.quantityMap[data.mapAccessor[field]];
    };
    const showCheckMark = computed(() => {
      if (props.type === 'pick' && (props.item.quantityMap.needed > 0 && props.item.quantityMap.needed) === props.item.quantityMap.taken) {
        return true;
      }
      if (props.type === 'stock' && props.item.quantityMap.toStock === props.item.quantityMap.stocked) {
        return true;
      }
      return null;
    });

    return {
      ...toRefs(data),
      getItemQuantityCount,
      showCheckMark,
    };
  },
};
</script>

<style scoped></style>
