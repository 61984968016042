import _ from 'lodash';

class User {
  autoAdd = false;

  disableAutoAdd = false;

  wasAdmin = false;

  secondaryRoles = [];

  _statusForCompany = 'Active';

  constructor(user) {
    Object.assign(this, user);
    this.wasAdmin = this.isAdmin;
  }

  setStatusForCompany(companyId) {
    const comp = _.find(this.multiCompanies, ({ company: c }) => c._id === companyId);
    const archived = _.get(comp, 'archived.value', false);
    if (archived) this._statusForCompany = 'Archived';
    else {
      this._statusForCompany = this.activated ? 'Active' : 'Inactive';
    }
  }

  get autoAddUser() {
    if (!_.isEmpty(this.secondaryRoles)
      && this.secondaryRoles.includes('auto-add')) this.autoAdd = true;
    return this.autoAdd;
  }

  set autoAddUser(value) {
    this.autoAdd = value;
    if (value && !this.secondaryRoles.includes('auto-add')) {
      this.secondaryRoles.push('auto-add');
    } else if (!value && this.secondaryRoles.includes('auto-add')) {
      _.remove(this.secondaryRoles, role => role === 'auto-add');
    }
  }
}
export default User;
