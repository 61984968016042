<!-- ItemDocsIconField.vue -->
<template>
  <div v-if="!isDisabled" class="is-relative docs-count is-clickable"
    @click="openItemDocumentsSlider()">
    <span> {{ files.length }} </span>
     <i class="icon-attacheddocumentsOL is-size-3"></i>
  </div>
</template>

<script>

import {
  reactive, toRefs, computed, inject,
} from 'vue';
import { get } from 'lodash';

export default {
  name: 'ItemDocsIconField',
  props: [
    'value',
    'rowData',
    'rowField',
    'key',
    'type',
    'checkListOrder',
  ],
  setup(props) {
    const emitter = inject('emitter');
    const data = reactive({
      files: [],
    });

    const files = computed(() => (get(props.rowData, props.rowField.id, false)
      ? get(props.rowData, props.rowField.id, []).filter((file) => file.archived.value === false)
      : []));

    const openItemDocumentsSlider = () => {
      const emitObj = {
        data: props.rowData,
        rowField: props.rowField,
        isActive: true,
        isDisabled: props.rowField.isDisabled,
      };
      if (props.checkListOrder) emitObj['checkListOrder'] = props.checkListOrder;
      emitter.emit('toggle:itemDocsSlider', emitObj);
    };

    const isDisabled = computed(() => {
      if (typeof props.rowField.isDisabled === 'function') {
        return props.rowField.isDisabled(props.rowData);
      }
      return false;
    });

    return {
      ...toRefs(data),
      openItemDocumentsSlider,
      files,
      isDisabled,
    };
  },
};
</script>
