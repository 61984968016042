export default {
  'mass-update-dates': {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    hasCheckBox: false,
    isCheckable: false,
    isDetailed: false,
    rearrangecols: false,
    stickyHeaders: false,
    toolBar: false,
    fields: [
      {
        title: 'Order Name',
        name: 'field-generic-input',
        id: 'name',
        width: '25%',
        prop: 'name',
      },
      {
        title: 'Coordinate By',
        name: 'field-date',
        id: 'coord-date',
        kind: 'dates',
        isEdit: false,
        width: '12%',
        inputProps: {
          kind: 'coord',
          max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
          disableEdit: true,
        },
      },
      {
        title: 'Detail By',
        name: 'field-date',
        id: 'detailBy-date',
        kind: 'dates',
        isEdit: false,
        width: '12%',
        inputProps: {
          kind: 'detailBy',
          min: 'coord',
          max: ['manufactureBy', 'qaBy', 'deliver'],
          disableEdit: true,
        },
      },
      {
        title: 'Manufacture By',
        name: 'field-date',
        prop: 'manufactureBy',
        kind: 'dates',
        width: '12%',
        inputProps: {
          kind: 'manufactureBy',
          min: ['detailBy', 'coord'],
          max: ['qaBy', 'deliver'],
          disableEdit: true,
        },
      },
      {
        title: 'QA By',
        name: 'field-date',
        prop: 'qaBy',
        kind: 'dates',
        width: '12%',
        inputProps: {
          kind: 'qaBy',
          min: ['manufactureBy', 'detailBy', 'coord'],
          max: 'deliver',
          disableEdit: true,
        },
      },
      {
        title: 'Onsite',
        name: 'field-date',
        prop: 'deliver',
        kind: 'dates',
        width: '12%',
        inputProps: {
          kind: 'deliver',
          min: ['orderBy', 'available', 'shipBy'],
          disableEdit: true,
        },
      },
      {
        name: 'error-msg',
        width: '15%',
        title: 'Message',
      },
    ],
  },
  'ms-mass-update-dates': {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    hasCheckBox: false,
    isCheckable: false,
    isDetailed: false,
    rearrangecols: false,
    stickyHeaders: false,
    toolBar: false,
    fields: [
      {
        title: 'Order Name',
        name: 'field-generic-input',
        id: 'name',
        width: '25%',
        prop: 'name',
      },
      {
        title: 'Fab/Order',
        name: 'field-date',
        prop: 'orderBy',
        kind: 'dates',
        width: '25%',
        id: 'orderBy',
        inputProps: {
          kind: 'orderBy',
          max: ['available', 'shipBy', 'deliver'],
          disableEdit: true,
        },
      },
      {
        title: 'Available',
        name: 'field-date',
        prop: 'availableBy',
        kind: 'dates',
        width: '25%',
        id: 'availableBy',
        inputProps: {
          kind: 'available',
          disableEdit: true,
        },
      },
      {
        title: 'Ship',
        name: 'field-date',
        prop: 'shipBy',
        kind: 'dates',
        width: '25%',
        id: 'shipBy',
        inputProps: {
          kind: 'shipBy',
          min: ['orderBy', 'available', 'shipBy'],
          max: ['deliver'],
          disableEdit: true,
        },
      },
      {
        title: 'Onsite',
        name: 'field-date',
        prop: 'deliver',
        kind: 'dates',
        width: '15%',
        inputProps: {
          kind: 'deliver',
          min: ['orderBy', 'available', 'shipBy'],
          disableEdit: true,
        },
      },
      {
        name: 'error-msg',
        width: '25%',
        title: 'Message',
      },
    ],
  },
};
