<template>
   <div class="is-flex is-justify-content-center ">
    <div v-if="rowDataValue === 'N/A'"
      class="productivity-pill has-background-grey-dark has-text-white
        has-text-weight-bold is-size-5 has-text-centered">
      {{ rowDataValue }}
    </div>
    <div class="productivity-pill has-text-white
      has-text-weight-bold is-size-5 has-text-centered" v-else
      :class="getColor(rowDataValue)">
      {{ rowDataValue }}
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { get } from 'lodash';

export default defineComponent({
  name: 'ProductivityDisplay',
  props: {
    rowData: Object,
    rowField: Object,
  },
  setup(props) {
    const { rowData, rowField } = props;
    const rowDataValue = computed(() => {
      let pf = get(rowData, `${rowField.inputProps.kind}`, -1);
      pf = parseFloat(pf);
      if (pf && pf.toFixed(2) >= 0) return pf.toFixed(2);
      return 'N/A';
    });
    const getColor = (val) => {
      if (val <= 1) return 'has-background-success';
      if (val > 1 && val <= 1.25) return 'has-background-warning';
      return 'has-background-danger';
    };
    return {
      rowDataValue,
      getColor,
    };
  },
});
</script>
