import {
  computed, reactive,
} from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { useRoute } from 'vue-router';
import SortParams from '@/components/utils/SortParams';
import ExcelExport from '@/models/ExcelExport';
import ShippingItemViewCols from '@/components/table-cols/shippingItemViewCols';
import { isEmpty } from 'lodash';
import Projects from '@/models/Projects';

export default function () {
  const store = useStore();
  const toast = useToast();
  const route = useRoute();
  const state = reactive({
    selectedCols: [],
  });

  const qp = computed(() => store.state.queryParams);

  const requestFormater = (cols, check) => {
    if (store.state.analysisTab === 'prodOrders' || store.state.analysisTab === 'runs' || store.state.analysisTab === 'rawData') {
      cols.forEach((obj) => {
        if (check || obj.visible === true) {
          state.selectedCols.push({
            field: obj.exportId || obj.id,
            title: obj.title,
            show: true,
            sortable: obj.id,
            class: obj.class,
          });
        }
      });
    } else {
      cols.forEach((obj) => {
        if (check || obj.visible === true) {
          state.selectedCols.push({
            field: obj.exportId || obj.id,
            title: obj.title,
            show: true,
            disabled: false,
            sortAttr: obj.sortField,
          });
        }
      });
    }
  };

  const excelExport = async () => {
    let status;
    let stage;
    let sortParams;
    let module;
    let name = '';
    let screen;
    let location;
    state.selectedCols = [];
    let baseCardType;
    let inStock;
    let projectId;
    let customId;
    let owner = store.getters.selectedOwners;
    let recipient = store.getters.selectedIdsForKey('recipients');
    let vendor;
    const extraParams = {};
    if (store.state.activeScreen === 'shipping-order-view' || store.state.activeScreen === 'shipping-item-view') {
      projectId = store.getters.selectedIdsForKey('filteredProjects');
      let { commonStockProject } = store.state.queryParams;
      if (store.state.activeScreen === 'shipping-order-view') {
        commonStockProject = !isEmpty(commonStockProject) ? commonStockProject
          : await Projects.getCommonStockProject();
        projectId.push(commonStockProject._id);
      }
      status = store.getters.selectedValuesForKey('shippingStatus', false);
      name = 'shippingList';
    } else {
      projectId = store.getters.selectedIdsForKey('projects');
    }
    if (store.state.activeScreen === 'production-status-order-view' || store.state.activeScreen === 'view-member-production-status-order-view'
          || store.state.activeScreen === 'production-status-item-view' || store.state.activeScreen === 'production-status-cal-view') {
      module = ['Prefabs', 'ProductionOrder'];
      name = 'ProductionStatus';
      screen = 'productionStatus';
      stage = store.getters.selectedValuesForKey('stage', false);
      status = store.getters.selectedValuesForKey('status', false);
      location = store.getters.selectedIdsForKey('location', false);
      owner = store.getters.scmSelectedOwner;
      projectId = store.getters.selectedIdsForKey('filteredProjects');
      sortParams = store.state.sortOrder['production-status-order-view_psTable'];
    } else if (store.state.activeScreen === 'material-status-order-view' || store.state.activeScreen === 'view-member-material-status-order-view'
           || store.state.activeScreen === 'material-status-item-view') {
      module = ['Materials', 'Sourcing'];
      extraParams.notFromRequisition = false;
      name = 'MaterialStatus';
      stage = store.getters.selectedValuesForKey('stage', false);
      recipient = store.getters.selectedIdsForKey('recipient', false);
      owner = store.getters.scmSelectedOwner;
      vendor = store.getters.selectedIdsForKey('vendor', false);
      location = store.getters.selectedIdsForKey('location', false);
      projectId = store.getters.selectedIdsForKey('filteredProjects');
      sortParams = store.state.sortOrder['material-status-order-view_msTable'];
    } else if (store.state.activeScreen === 'inventory') {
      name = 'Inventory';
      screen = 'inventory';
      location = store.getters.selectedIdsForKey('selectedCompanyLocations');
      // sortParams = this.sortOrder['shipping-order-view_shipTable'];

      baseCardType = ['Part', 'Assembly'];
      projectId = store.getters.selectedIdsForKey('filteredProjects');
      // locs = locations;
      inStock = store.getters.selectedInStock;
      // locationView =  true;
    } else if (store.state.activeScreen === 'kit-view-edit') {
      customId = route.params.customId;
      module = ['ProductionOrder'];
      projectId = route.params.projectId;
      name = 'KitView';
    } else {
      name = 'DeliveryStatus';
      sortParams = store.state.sortOrder['shipping-order-view_shipTable'];
    }
    let columns = [];
    let itemView = null;
    if (!['order-view',
      'cal-view',
      'order-view-empty-orders'].includes(qp.value.selectedList)
        && !['Productivity',
          'inventory', 'kit-view-edit'].includes(store.state.activeScreen)) {
      itemView = true;
    }
    if (qp.value.selectedList === 'order-view' || qp.value.selectedList === 'order-view-empty-orders') {
      const ScreenCols = store.getters.activeScreenCols;
      requestFormater(ScreenCols, false);
    } else if (store.state.activeScreen === 'material-status-item-view' || store.state.activeScreen === 'production-status-item-view') {
      let itemcols = store.getters.activeScreenCols;
      itemcols = itemcols.filter((i) => i.visible === true);
      requestFormater(itemcols, true);
    } else if (store.state.activeScreen === 'kit-view-edit') {
      const kitcols = store.getters.activeScreenCols;
      requestFormater(kitcols, true);
    } else if (store.state.activeScreen === 'inventory') {
      const ScreenCols = store.getters.activeScreenCols;
      requestFormater(ScreenCols, false);
      // sortParams = this.sortOrder.inventory_imTable;
    } else {
      const itemcols = ShippingItemViewCols.fields;
      requestFormater(itemcols, true);
    }

    columns = state.selectedCols;

    // eslint-disable-next-line
    const targetId = event.target.id;
    let sort;
    if (sortParams !== undefined) {
      const [{ sortField }] = sortParams;
      sort = SortParams.setSortParams(sortParams);
      sort = (sort[sortField] > 0) ? sortField : `-${sortField}`;
    } else {
      sort = {};
    }
    const data = await ExcelExport.export({
      showAllCompanyOrders: !!qp.showAllCompanyOrders,
      // location: this.selectedIdsForKey('locations', false),
      currentLocation: store.getters.selectedIdsForKey('currentLocations'),
      deliveryLocation: store.getters.selectedIdsForKey('deliveryLocations'),
      company: store.getters.selectedIdsForKey('companies', false),
      owner,
      recipient,
      vendor,
      supplier: store.getters.selectedIdsForKey('suppliers'),
      stage,
      status,
      location,
      submittals: store.getters.selectedSubmittals,
      ...store.getters.selectedDatesForKeys([
        'deliverStartDate',
        'deliverEndDate',
        'coordStartDate',
        'coordEndDate',
        'detailByStartDate',
        'detailByEndDate',
        'manufactreByStartDate',
        'manufactureByEndDate',
        'qaByStartDate',
        'qaByEndDate',
        'availableStartDate',
        'availableEndDate',
        'orderByStartDate',
        'orderByEndDate',
        'shipByStartDate',
        'shipByEndDate',
        'shipStartDate',
        'shipEndDate',
      ]),
      search: qp.searchText ? qp.searchText : '',
      screen,
      limit: null,
      sort,
      pTrackEnabled: null,
      type: 'excel',
      module,
      name,
      excelHeaders: { columns },
      itemView,
      projectId,
      userCompanyId: store.state.activeScreen === 'Productivity' ? store.state.userData.company : null,
      showPrivate: true,
      baseCardType,
      inStock,
      customId,
      ...extraParams,
    });
    toast.success('Excel report emailed to you');
    return data;
  };

  return {
    excelExport,
  };
}
