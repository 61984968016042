export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  fields: [{
    name: 'select-catalog',
    prop: 'selected',
    width: '5%',
    title: '',
    id: 'selectCatalog',
  }, {
    name: 'field-generic-input',
    prop: 'name',
    width: '25%',
    title: 'Item Name',
    id: 'name',
    sortField: 'name',
  }, {
    name: 'field-generic-input',
    prop: 'desc',
    width: '20%',
    title: 'Description',
    id: 'desc',
  }, {
    name: 'field-cat-id',
    prop: 'catId',
    width: '25%',
    title: 'Catalog ID',
    id: 'catId',
    sortField: 'catId',
  }, {
    name: 'field-generic-input',
    prop: 'available',
    width: '10%',
    title: 'In Stock',
    id: 'available',
  }, {
    name: 'qty-needed',
    prop: 'qtyNeeded',
    width: '15%',
    title: 'Qty Needed',
    id: 'qtyNeeded',
  }],
};
