export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  stickeyHeaders: true,
  isCardView: true,
  permissionModule: '',

  fields: [
    {
      name: 'field-generic-input',
      sortField: 'text',
      width: '400px',
      alwaysVisible: true,
      title: 'Task',
      prop: 'text',
      id: 'text',
      searchable: true,
      sticky: true,
		  isDisabled: true,
    },
    {
      name: 'field-generic-input',
      sortField: 'assignedTo.user.name',
      width: '296px',
      title: 'Assignee',
      id: 'assignedTo.user.name',
      prop: 'assignedTo.user.name',
      defaultVisible: true,
      dataClass: 'overflow-visible',
      isDisabled: true,
    },
    {
      name: 'field-date', // todo: once GenericDate is implemented
      sortField: 'dueDate',
      prop: 'dueDate',
      width: '152px',
      id: 'dueDate',
      type: 'generic-date',
      title: 'Due Date',
      defaultVisible: true,
      inputProps: {
        kind: 'dueDate',
        disableEdit: true,
      },
      dataClass: 'overflow-visible',
    },
    {
      name: 'private',
      width: '64px',
      title: 'Private',
      id: 'private',
      prop: 'private',
      isDisabled: true,
      showIcons: true,
    },
    {
      name: 'field-generic-input',
      sortField: 'status',
      width: '196px',
      title: 'Status',
      dataClass: 'overflow-visible',
      id: 'status',
      prop: 'status',
      defaultVisible: true,
      isDisabled: true,
    },
    {
      name: 'field-generic-input',
      sortField: 'type',
      width: '168px',
      title: 'Type',
      id: 'type',
      prop: 'type',
      defaultVisible: true,
      dataClass: 'overflow-visible',
      isDisabled: true,
    },
    {
      name: 'checklist-docs', // todo: integrate item checklist component
      width: '64px',
      title: 'Docs',
      id: 'files',
      for: 'Task',
    },

  ],
};
