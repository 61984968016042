import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async addUpdateCatalog(params) {
    const updatedCatalog = await req.post('items/addCatalog', params);
    return updatedCatalog.data;
  },
  async archiveCatalog(params) {
    const { data: archivedCatalog } = await req.get('items/archiveCatId', { params });
    return archivedCatalog;
  },
  async unArchiveCatalog(params) {
    const { data: unArchivedCatalog } = await req.get('items/unArchiveCatId', { params });
    return unArchivedCatalog;
  },
  async getQtyCalData(params) {
    try {
      const data = await req.post('items/getCalData', params);
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getAssemblyParts(params) {
    try {
      const data = await req.post('catalogue', params);
      return data.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async addAssemblyParts(params, type) {
    const { data: updatedAssemblyParts } = await req.post(`catalogue/add/${type}`, params);
    return updatedAssemblyParts;
  },
  async updateAssemblyParts(params) {
    const updatedAssemblyParts = await req.post(`catalogue/edit/${params._id}`, params);
    return updatedAssemblyParts;
  },
  async archive(params, type) {
    const { data: archivedCatalog } = await req.post(`catalogue/archive/${type}`, { params });
    return archivedCatalog;
  },
  async unarchive(params, type) {
    const { data: unarchivedCatalog } = await req.post(`catalogue/unarchive/${type}`, { params });
    return unarchivedCatalog;
  },
  async checkCatIds(params) {
    try {
      const data = await req.post('catalogue/checkCatId', params);
      return data.data;
    } catch (e) {
      throw e.data.message;
    }
  },
  async getSuperAssemblies() {
    try {
      const data = await req.get('catalogue/getSuperAssemblies');
      return data;
    } catch (error) {
      throw error.data.message;
    }
  },
  async getAssemblyPartsData(params) {
    try {
      let data;
      if (params.catId && params.catId.length > 100) {
        data = await req.post('catalogue/assemblyPartsData', params);
      } else {
        data = await req.get('catalogue/assemblyPartsData', { params });
      }
      return data.data;
    } catch (error) {
      throw error.data.message;
    }
  },
  /* API to fetch all the existing CATALOG ID's */
  async getAllCatIds() {
    try {
      const data = await req.get('catalogue/allCatIds');
      return data;
    } catch (e) {
      throw e.data.message;
    }
  },
};
