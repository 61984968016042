<template>
  <div v-if="!isDisabled" class="is-relative note-count is-clickable"
  @click="openNotesSlider()">
      <span>{{ notesCount }}</span>
      <i class="icon-attachednotesOL"></i>
    </div>
</template>

<script>

import {
  reactive, toRefs, computed, inject,
} from 'vue';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';
import { trim } from 'lodash';

export default {
  name: 'NotesIconField',
  props: [
    'value',
    'rowField',
    'rowData',
    'isItem',
    'isShipping',
    'fromMove',
    'orderId',
    'fromFlyout',
    'isPurchaseModal'
  ],
  setup(props) {
    const emitter = inject('emitter');
    const { disableInput } = ProductionManagerMixin();
    const state = reactive({
      notes: [],
    });

    const notesCount = computed(() => {
      if ((!props.isItem && trim(props.rowData.currentNote?.text)?.length > 0)
        || (!props.isItem && props.rowData.manager?.notes.length)
        || (props.isShipping && props.rowData.delivery.notes?.length)
        || (props.isItem && props.rowData.simpleMemos.length
        && props.rowData.simpleMemos[0].text?.length)) {
        return 1;
      }
      if (props.isMove && props.rowData?.notes.length) {
        return 1;
      }
      return 0;
    });

    const openNotesSlider = () => {
      const emitObj = {
        data: props.rowData,
        isItem: props.isItem,
        isActive: true,
        isShipping: props.isShipping,
        isPurchaseModal: props.isPurchaseModal,
        fromMove: props.fromMove,
        orderId: props.orderId,
        isDisabled: props.rowField.isDisabled || disableInput(props.rowData),
      };
      if (props.fromFlyout) {
        emitter.emit('toggle:flyoutNoteSlider', emitObj);
      } else {
        emitter.emit('toggle:notesSlider', emitObj);
      }
    };

    const isDisabled = computed(() => {
      if (typeof props.rowField.isDisabled === 'function') {
        return props.rowField.isDisabled(props.rowData);
      }
      return false;
    });

    return {
      ...toRefs(state),
      openNotesSlider,
      notesCount,
      isDisabled,
      disableInput,
    };
  },
};

</script>
<style scoped>
button.is-absolute {
  position: absolute !important;
  right: 0;
}
</style>
