import { get } from 'lodash';

function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    isDetailed: false,
    rearrangeCols: false,
  };
}
const ItemCols = [
  {
    name: 'field-generic-input',
    width: '400px',
    title: 'Items',
    prop: 'name',
    sticky: true,
    id: 'name',
    searchable: true,
  },
  {
    name: 'field-cat-id',
    width: '152px',
    title: 'Catalog ID',
    id: 'catId',
    prop: 'catId',
    allowAutoComplete: true,
    inputProps: {
      type: 'text',
      disableEdit: true,
    },
  },
  {
    name: '',
    width: '112px',
    title: 'Inventory',
    formatter(item) {
      if (item.isShipmentItem && get(item, 'source.from', '') !== 'card') {
        return item.availableInInv;
      } if (item.isInventoryItem) {
        return item.available || item.actualQty;
      } if (get(item, 'source.from', '') !== 'inventory') {
        return 'N/A';
      }
      return item.actualQty ? item.actualQty : 0;
    },
  },
  {
    name: 'itemQty',
    title: 'Qty',
    width: '112px',
  },
  {
    title: ' ',
    name: 'table-field-icon',
    width: '64px',
    id: 'shipSummary',
    customHeader: true,
    defaultVisible: true,
    inputProps: {
      icon: 'icon-attachedshippinglistOL',
    },
  },
  {
    title: 'Delivs',
    name: 'delivsField',
    width: '64px',
    id: 'shipDelivs',
    defaultVisible: true,
    inputProps: {
      icon: 'icon-attacheddeliveriesOL',
    },
  },
  {
    name: '',
    width: '296px',
    title: 'Source Order',
    prop: 'order',
    classRight: 'text-clamp',
    id: 'order',
    formatter(item) {
      return item.orderName
        || get(item, 'source._id.name', '')
        || get(item, 'order.name', '');
    },
  },
  {
    name: '',
    title: 'Install Location',
    width: '296px',
    id: 'installLocation',
    formatter(item) {
      if (Array.isArray(item.installLocs)) {
        return item.installLocs.join(', ');
      }
      return '';
    },
  },
  {
    name: 'field-generic-input',
    width: '296px',
    title: 'Level',
    prop: 'itemLevel',
    id: 'level',
  },
  {
    name: 'field-generic-input',
    width: '296px',
    title: 'Zone',
    prop: 'itemZone',
    id: 'zone',
  },
  {
    title: '',
    name: 'field-card-edit-action',
    alwaysVisible: true,
    id: 'action',
    width: '112px',
    sticky: true,
    thAttrs: () => ({ class: 'action-sticky' }),
    tdClass: 'action-sticky',
  },
];

export default {
  'item-info': {
    ...extraTableParams(),
    stickeyHeaders: true,
    isCardView: true,
    ...{
      toolBar: {
        hideSearchBar: true, // by default let the search bar be available for the table
        buttons: [{
          event: 'addNew',
          icon: 'icon-addnew',
          isLine: true,
          tooltip: 'Add From Local Inventory',
          isDisabled: (route, props) => ['in-storage', 'fulfilled', 'zombie'].includes(props.orderStage)
            || route.cardId === 'add',
        }],
      },
      fields: ItemCols,
    },
  },
  'list-view-delivery-info': {
    ...extraTableParams(),
    fields: [
      {
        name: 'field-generic-input',
        width: '400px',
        title: 'Delivery',
        prop: 'name',
        id: 'name',
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Tracking #',
        prop: '_delivery.trackingId',
        id: 'trackingId',
      },
      {
        name: 'field-ship-items-icon',
        width: '64px',
        title: 'Items',
        id: 'items',
        inputProps: {},
      },
      {
        title: 'Status',
        name: 'field-stage',
        prop: 'statusToShow',
        width: '196px',
        id: 'shippingStatus',
        isStatus: true,
      }, {
        title: 'Delivery Location',
        name: 'field-generic-input',
        prop: '_delivery.deliveryLocation.name',
        width: '196px',
        id: 'deliveryLocation',
      }, {
        title: 'Ship By',
        name: 'field-date',
        prop: '_delivery.deliveryStart',
        kind: 'dates',
        width: '152px',
        id: 'shipBy',
        inputProps: {
          kind: '_delivery.deliveryStart',
          max: '_delivery.deliverBy',
          disableEdit: false,
        },
      }, {
        title: 'Deliver By',
        name: 'field-date',
        prop: '_delivery.deliverBy',
        kind: 'dates',
        width: '152px',
        id: 'deliverBy',
        inputProps: {
          kind: '_delivery.deliverBy',
          min: '_delivery.deliveryStart',
          disableEdit: false,
        },
      },
      {
        name: 'field-notes-icon',
        title: 'Notes',
        prop: '_delivery.notes',
        width: '64px',
        id: 'notes',
        fromFlyout: true,
        inputProps: {
          isItem: false,
        },
        isShipping: true,
        isDisabled(rowData) {
          if (rowData.id === 'unscheduled') {
            return true;
          }
          return false;
        },
      },
      {
        name: 'field-item-docs-icon',
        width: '64px',
        title: 'Docs',
        id: 'files',
        cardProps: {
          isEditing: false,
        },
        isDisabled(rowData) {
          if (rowData.id === 'unscheduled') {
            return true;
          }
          return false;
        },
      },
    ],
  },
  'delivery-info': {
    ...extraTableParams(),
    stickeyHeaders: true,
    isCardView: true,
    ...{
      toolBar: {
        hideSearchBar: true,
        buttons: [],
        bulkActions: () => {
          return [{
            name: 'Receive Deliveries',
            event: 'receiveMultiDeliveries',
            label: 'Receive Deliveries',
          }];
        },
      },
    },
    fields: [
      {
        name: 'field-generic-input',
        width: '400px',
        title: 'Delivery',
        prop: 'name',
        sticky: true,
        bulkActionButtonField: true,
        id: 'name',
        sortField: 'name',
        alwaysVisible: true,
        isClickable: true,
        searchField: false,
        searchable: true,
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Tracking #',
        prop: '_delivery.trackingId',
        id: 'trackingId',
      },
      {
        name: 'field-ship-items-icon',
        width: '64px',
        title: 'Items',
        defaultVisible: true,
        id: 'items',
        inputProps: {},
      },
      {
        name: 'shippingStatus',
        id: 'shippingStatus',
        title: 'Status',
        width: '196px',
        searchable: true,
        filterText: false,
        defaultVisible: true,
      },
      {
        title: 'Ship By',
        name: 'field-date',
        defaultVisible: true,
        alwaysVisible: false,
        prop: '_delivery.deliveryStart',
        kind: 'dates',
        width: '152px',
        id: 'shipBy',
        searchable: true,
        inputProps: {
          kind: '_delivery.deliveryStart',
          max: '_delivery.deliverBy',
          disableEdit(rowData) {
            return rowData.status !== 'not-started';
          },
        },
      }, {
        title: 'Deliver By',
        name: 'field-date',
        defaultVisible: true,
        alwaysVisible: false,
        prop: '_delivery.deliverBy',
        kind: 'dates',
        width: '152px',
        id: 'deliverBy',
        searchable: true,
        inputProps: {
          kind: '_delivery.deliverBy',
          min: '_delivery.deliveryStart',
          disableEdit: false,
        },
      },
      {
        name: 'field-notes-icon',
        title: 'Notes',
        prop: '_delivery.notes',
        defaultVisible: true,
        width: '64px',
        id: 'notes',
        isShipping: true,
        inputProps: {
          isItem: false,
        },
        isDisabled(rowData) {
          if (['unscheduled', 'underDelivered'].includes(rowData.id)) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'field-item-docs-icon',
        width: '64px',
        title: 'Docs',
        id: 'files',
        cardProps: {
          isEditing: false,
        },
        isDisabled(rowData) {
          if (['unscheduled', 'underDelivered'].includes(rowData.id)) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'notify',
        width: '64px',
        title: 'Notify',
        prop: '_delivery.notify',
        id: 'notify',
        isDisabled(rowData) {
          if (['unscheduled', 'underDelivered'].includes(rowData.id)) {
            return true;
          }
          return false;
        },
      },
      {
        title: 'Actions',
        name: 'table-field-actions',
        id: 'action',
        hideFromChooser: true,
        alwaysVisible: true,
        width: '112px',
        sticky: true,
        thAttrs: () => ({ class: 'action-sticky' }),
        tdClass: 'action-sticky',
        searchable: false,
        clearBtn: true,
        inputProps: {
          showEdit: (card) => {
            if (['unscheduled', 'underDelivered'].includes(card.id) || ['fulfilled', 'zombie'].includes(card.status)) {
              return false;
            }
            return true;
          },
          options: (card) => {
            const shipLabelOpts = [
              {
                label: 'Print QR',
                event: 'printQr',
              },
              {
                label: 'History',
                event: 'activity',
              },
            ];
            if (card.status === 'not-started') {
              shipLabelOpts.unshift({
                label: 'Split',
                event: 'split',
              });
            }
            if (card.status === 'in-storage') {
              shipLabelOpts.unshift(
                {
                  label: 'Schedule A New Delivery',
                  event: 'scheduleDelivery',
                },
                {
                  label: 'Mark Final',
                  event: 'markFinal',
                },
                {
                  label: 'Release To Inventory',
                  event: 'releaseToInv',
                },
              );
            }
            if (!['zombie', 'in-storage', 'fulfilled'].includes(card.status)) {
              shipLabelOpts.unshift(
                {
                  label: 'Delete',
                  event: 'deleteDelivery',
                },
              );
            }
            if (card.status === 'in-transit') {
              shipLabelOpts.unshift(
                {
                  label: 'Cancel',
                  event: 'cancelShip',
                },
              );
            }
            if (card.status === 'in-transit') {
              shipLabelOpts.unshift({
                label: 'Receive',
                event: 'receive',
              });
            }
            const unscheduledOpts = [
              {
                label: 'Create Delivery',
                event: 'createDelivery',
              },
            ];
            let actions;
            switch (card.id) {
              case 'unscheduled':
                actions = unscheduledOpts;
                break;
              case 'underDelivered':
                actions = [];
                break;
              default:
                actions = shipLabelOpts;
                break;
            }
            // const actions = card.id === 'unscheduled' ? unscheduledOpts : shipLabelOpts;
            return !['s', 's-m'].includes(card.shipType) ? [] : actions;
          },
        },
      },
    ],
  },
  'detail-row-cols': {
    ...extraTableParams(),
    fields: [
      {
        name: 'field-generic-input',
        width: '400px',
        title: 'Items',
        prop: 'name',
        id: 'name',
      },
      {
        name: 'field-cat-id',
        width: '152px',
        title: 'Catalog ID',
        id: 'catId',
        prop: 'catId',
        allowAutoComplete: true,
        inputProps: {
          type: 'text',
          disableEdit: true,
        },
      },
      {
        name: '',
        width: '112px',
        title: 'Inventory',
        formatter(item) {
          if (item.isShipmentItem && get(item, 'source.from', '') !== 'card') {
            return item.availableInInv;
          } if (item.isInventoryItem) {
            return item.available;
          } if (get(item, 'source.from', '') !== 'inventory') {
            return 'N/A';
          }
          return item.actualQty ? item.actualQty : 0;
        },
      },
      {
        name: 'field-generic-input',
        width: '112px',
        title: 'Qty',
        id: 'quantity',
        prop: 'quantity',
      },
      {
        title: ' ',
        name: 'table-field-icon',
        width: '64px',
        id: 'shipSummary',
        customHeader: true,
        defaultVisible: true,
        inputProps: {
          icon: 'icon-attachedshippinglistOL',
        },
      },
      {
        title: 'Delivs',
        name: 'table-field-icon',
        width: '64px',
        id: 'shipDelivs',
        defaultVisible: true,
        inputProps: {
          icon: 'icon-attacheddeliveriesOL',
        },
      },
      {
        name: '',
        width: '296px',
        title: 'Source',
        prop: 'order',
        classRight: 'text-clamp',
        id: 'order',
        formatter(item) {
          return item.orderName
            || get(item, 'source._id.name', '')
            || get(item, 'order.name', '');
        },
      },
      {
        name: '',
        title: 'Install Location',
        width: '296px',
        id: 'installLocation',
        formatter(item) {
          if (Array.isArray(item.installLocs)) {
            return item.installLocs.join(', ');
          }
          return '';
        },
      },
      {
        name: 'field-generic-input',
        width: '296px',
        title: 'Level',
        prop: 'level',
        id: 'level',
      },
      {
        name: 'field-generic-input',
        width: '296px',
        title: 'Zone',
        prop: 'zone',
        id: 'zone',
      },
    ],
  },
};
