<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
    <div  class="list-view">
      <TableGutter
        :tools="tableProps.gutterOpts"
        :selectedAction="isBulkActionEnabled"
        @cc-modal-closed="triggerSetHeaderHeight"
        @re-render-table="rerenderMfTable += 1"
      >
      </TableGutter>
      <mf-table
        ref="materialTable"
        :tableProps="tableProps"
        :loadData="loadData"
        :hideGutter="true"
        :tableName="stageFromUrl"
        :screenTable="true"
        @cell-clicked="onCellClicked"
        :key="rerenderMfTable"
      >
        <template v-slot:kit-id="{ rowData }">
          <span v-tooltip="cardKitId(rowData).length > 13 ? cardKitId(rowData) : ''"
            class="text-overflow line-height">
            {{cardKitId(rowData)}}
          </span>
        </template>
        <template #lmvIcon="{rowData, rowField, isHeader, rowIndex }">
          <IconField
            :rowData="rowData"
            :rowField="rowField"
            :isHeader="isHeader"
            :rowIndex="rowIndex"
            :projects="allProjects" />
        </template>
      </mf-table>
    <create-order
      v-if="createOrderModal"
      :isActive="createOrderModal"
      @onSave="onCreate"
      @close="createOrderModal = false"
      :stage="stageFromUrl"
      permissionModule="material-manager"
      :requiredCheck="getRequiredCheck"
    >
    </create-order>
    <transition name="slide">
      <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedItem"
        @close="closeNotesSlider"
        @update:note="updateNotes($event)"
      >
      </notes-icon-slider>
    </transition>
    <transition name="slide">
      <item-document-slider
        v-if="isFileListVisible"
        :isActive="isFileListVisible"
        :rowData="selectedItem"
        :rowField="rowField"
        @close="closeDocumentSlider"
        >
      </item-document-slider>
    </transition>
    <activity-log
          :isActive="isActive"
          :id="activityDetails.id"
          :projectId="activityDetails.projectId"
          :orderName="activityDetails.orderName"
          @update:isActive="isActive=false">
    </activity-log>
    <excel-import
      ref="excel"
      :card="loadData"
      :isExcelModalActive="excelImportModal"
      :kitsImport="false"
      :multiAssemblyImport="false"
      :dates="dates"
      :maxLimit="500"
      :validateExcelItem="validateExcelItem"
      :saveExcelItem="saveExcelItem"
      :selectedProjectId="selectedProject"
      modalHeaderTitle="import materials from excel"
      @close-modal="closeExcelModal"
      @refresh-table="refreshTable"
    >
      <template #project>
          <td width="40%">Project*</td>
          <td width="60%">
            <mf-multi-select
                :options="groupedProjects"
                :multiple="false"
                :preselect-first="true"
                :allow-empty="false"
                v-model="selectedProject"
                label="name"
                track-by="_id"
                placeholder="Select option (Required)"
                @select="checkToDisableNext"
            ></mf-multi-select>
          </td>
      </template>
    </excel-import>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  ref,
  inject,
  onBeforeUnmount,
  onMounted,
  onBeforeMount,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import { useToast } from 'vue-toastification';
import SupplyChain from '@/models/SupplyChain';
import Shipping from '@/models/Shipping';
import tableDefinition from '@/components/table-cols/materialCols';
// eslint-disable-next-line import/no-named-as-default
import BaseOrder from '@/models/BaseOrder';
import TableGutter from '@/components/TableGutter.vue';
import CreateOrder from '@/components/CreateOrder.vue';
import Validations from '@/models/Validations';
import { DialogProgrammatic } from '@/components/Dialog';
import MaterialManager from '@/models/MaterialManager';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import UtilityMixin from '@/components/mixins/UtilityMixin';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import RejectSourcing from '@/components/modals/RejectSourcing.vue';
import ExcelImport from '@/components/ExcelImport.vue';
import screens from '@/constants';
import Validation from '@/utils/Validations';
import ExcelMixin from '@/components/mixins/ExcelMixin';
import Projects from '@/models/Projects';
import moment from 'moment';
import ActivityLog from '../components/ActivityLog.vue';
import SplitCard from '../components/modals/SplitCard.vue';
import CloneCard from '../components/modals/CloneCard.vue';
import MfTable from '../components/table-fields/MfTable.vue';
import IconField from '../components/fields/IconField.vue';

export default defineComponent({
  name: 'material-manager',
  components: {
    MfTable,
    CreateOrder,
    ActivityLog,
    ExcelImport,
    TableGutter,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
    IconField,
  },
  setup() {
    const store = useStore();
    const emitter = inject('emitter');
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const materialTable = ref(null);
    const isBulkActionEnabled = computed(() => materialTable?.value?.selectedBulkAction);
    const excel = ref(null);
    const {
      exportMaterialItems, printQr, openLmvModel, openWithUserPref,
    } = CardEditMixin();
    const { getNegativeList } = UtilityMixin();
    const stageFromUrl = computed(() => route.path.split('/').pop());
    const { validateDates, addFiles } = ExcelMixin();
    const state = reactive({
      isLoading: false,
      isActive: false,
      tableProps: tableDefinition,
      createOrderModal: false,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      isRejectActive: false,
      orderId: '',
      dates: [
        {
          kind: 'shipBy',
          label: 'shipBy',
        },
        {
          kind: 'onSiteBy',
          label: 'onSiteBy',
        },
      ],
      excelImportModal: false,
      allUsers: [],
      user: {},
      selectedProject: '',
      allProjects: [],
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedItem: {},
      isItem: false,
      rowField: {},
      rerenderMfTable: 0,
      projOptions: [],
      allProjects: [],
    });
    const loadData = async (commonParam) => {
      let data = {};
      // eslint-disable-next-line max-len
      let categoryId; let date; let createdAfter; let rangeDiff; let fromDashBoard; let createdUser; let paramsToAppend; let isLate; let vendor; let stages; let poCreatedAtStart;
      if (route.query) {
        ({
          createdAfter,
          categoryId,
          date,
          fromDashBoard,
          rangeDiff,
          createdUser,
          isLate,
          vendor,
          poCreatedAtStart,
          stages,
        } = route.query);
        paramsToAppend = {
          filterNoItemOrders: fromDashBoard || false,
          createdUser,
        };
        if (createdAfter) paramsToAppend.createdAfter = moment(createdAfter)._d;
        if (isLate) paramsToAppend.isLate = isLate;
        if (poCreatedAtStart) paramsToAppend.poCreatedAtStart = poCreatedAtStart;
      }
      const dateToQuery = _.isEmpty(date)
        ? store.getters.selectedDatesForKeys([
          'deliverStartDate',
          'deliverEndDate',
          'availableStartDate',
          'availableEndDate',
          'orderByStartDate',
          'orderByEndDate',
          'shipByStartDate',
          'shipByEndDate',
        ])
        : JSON.parse(date);
      let vendors = [];
      if (vendor) {
        vendors = vendor;
      } else {
        vendors = _.isEmpty(store.state.queryParams.vendors)
          ? []
          : _.map(store.state.queryParams.vendors, '_id');
      }
      let stage = [];
      const stageName = stageFromUrl.value === 'ordering' ? ['ordering', 'delivery'] : stageFromUrl.value;
      if (stages) {
        stage = stages;
      } else stage = store.getters.selectedValuesForKey('materialStage');
      const companyId = store.getters.selectedIdsForKey('companies', false) || store.state.userData.company;
      let params = {
        showAllCompanyOrders: store.state.queryParams.showAllCompanyOrders,
        company: companyId,
        location: store.getters.selectedIdsForKey('locations', false),
        tag: store.getters.selectedIdsForKey('tags'),
        submittals: store.getters.selectedSubmittals,
        ...dateToQuery,
        // category: !_.isEmpty(categoryId)
        //   ? [categoryId]
        //   : store.getters.selectedIdsForKey('categories'),
        supplier: store.getters.selectedIdsForKey('suppliers'),
        vendor: vendors,
        module: stageFromUrl.value === 'preparation' ? 'Materials' : 'Sourcing',
        levels: store.getters.selectedIdsForKey('level'),
        zones: store.getters.selectedIdsForKey('zone'),
        filterNoItemOrders: false,
        stage,
        ...paramsToAppend,
        rangeDiff,
        fromDashBoard,
        ...commonParam,
      };
      if (!_.isEmpty(categoryId)) params.category = [categoryId];
      params = await getNegativeList(params, {
        projectId: [],
        owner: store.getters.selectedOwners?.user?._id,
        recipient: store.getters.selectedIdsForKey('recipients'),
      });
      if (stageFromUrl.value === 'preparation') params.notFromRequisition = false;
      if (stageFromUrl.value !== 'preparation') params.stage = stageName;
      data = await SupplyChain.supplyChain(params);
      data.data.forEach((o) => {
        o._customStage = stageFromUrl.value;
      });
      if (stageFromUrl.value === 'ordering') {
        data.data = _.filter(
          data.data,
          (order) => !_.every(order.items, (item) => ['fulfilled', 'released-to-inventory', 'in-storage'].includes(
            item.stage,
          )),
        );
      }
      return data;
    };

    // eslint-disable-next-line consistent-return
    const saveRow = async (rowData) => {
      let material = _.cloneDeep(rowData);
      try {
        store.commit('setLoading', true);
        material = await material.save();
        _.set(rowData, 'isEditing', !rowData.isEditing);
        Object.assign(rowData, material);
      } catch (e) {
        const msg = _.get(e.data, 'message', '')
          || _.get(e.data, 'msg', '') || e?.message
          || 'Error saving: please contact ManufactOn support';
        return toast.error(msg);
      } finally {
        store.commit('setLoading', false);
      }
    };

    const archiveCard = async (rowData) => {
      const order = rowData;
      let messageForDelete = 'The order and all its items will be permanently removed. Continue?';
      const isMoved = order.__t === 'Materials' && _.some(order.items, ({ stage }) => !['preparation', 'rejected'].includes(stage));
      if (order.stage === 'sourcing' && order.__t === 'Sourcing') {
        state.isRejectActive = true;
      } else if (isMoved) {
        toast.warning(
          `Unable to archive. One or more items from this order have been moved past
            'Requesting' stage and hence the order cannot be deleted.`,
        );
      } else {
        if (order.productionOrder) {
          messageForDelete = ` <b>${order.name}</b> is a linked Bill of Materials for <b>${order.productionOrder.name}</b> Deletion will break that link.`;
        }
        const confirmMessage = order._customStage === 'ordering'
          ? 'The Purchase Order and its linked Shipping Order will be deleted. The items will be returned to Sourcing.'
          : messageForDelete;
        const confirmParams = {
          title: _.upperCase(`Delete ${order._customStage === 'ordering' ? 'purchase' : 'requesting'} Order`),
          message: confirmMessage,
          okButton: 'Delete',
          cancelButton: 'Cancel',
          type: 'danger',
          onConfirm: async () => {
            state.isLoading = true;
            try {
              if (order._customStage === 'ordering') {
                const params = {};
                params.projectId = order.project._id;
                params.orderId = order._id;
                const { data: purchaseorders } = await Shipping.getPurchaseShippingOrders(params);
                if (!order.isLocked && purchaseorders.every((l) => l.shipType === 's-m')) {
                  const promises = [];
                  for (const label of purchaseorders) {
                    if (['in-transit', 'not-started'].includes(label.status)) {
                      if (label.shipType === 's-m') {
                        _.forEach(label.items, (item) => {
                          label.removeItem(item, 'fromSelf', 0);
                        });
                      } else {
                        _.forEach(label.items, (item) => {
                          label.removeItem(item, 'fromPartial', 0);
                        });
                      }
                    }
                    promises.push(label.save({ isLocked: _.get(order, 'isLocked', false) }));
                  }
                  await Promise.all(promises);
                } else {
                  toast.error(`The Purchase Orders linked Shipping order cannot be removed.
                    Please cancel the Shipping order and try again`);
                  return;
                }
              } else await MaterialManager.archive(order);
              toast.success('Card archived!');
            } catch (error) {
              toast.error(`Error archiving - ${error.data}.  Please contact ManufactOn support`);
            }
            state.isLoading = false;
            materialTable.value?.refreshTable();
          },
        };
        DialogProgrammatic.confirm(confirmParams);
      }
    };

    const onCreate = (card, toOpen) => {
      state.createOrderModal = false;
      if (toOpen) {
        router.push({
          name: ['sourcing', 'ordering'].includes(card.stage) ? 'sourcing-edit' : 'material-edit',
          params: {
            module: 'material',
            projectId: card.project._id,
            cardId: card.stage === 'preparation' ? card._id : 'add',
            stage: card.stage,
            orderJSON: JSON.stringify(card),
          },
        });
      } else {
        // eslint-disable-next-line no-unused-expressions
        materialTable.value?.refreshTable();
      }
    };

    function getModuleName(card) {
      let moduleName = '';
      if (card.isPrefab()) moduleName = 'prefab';
      else if (card.isPO()) moduleName = 'order';
      else if (card.isPM()) moduleName = 'manager';
      else if (card.isMM()) moduleName = 'material';
      else if (card.isSourcing()) moduleName = 'sourcing';
      else if (card.isMaterialTemplate()) moduleName = 'materialTemplate';
      else if (card.isProductionTemplate()) moduleName = 'productionTemplate';
      return moduleName;
    }

    function openRow(card) {
      const shipStages = [
        'in-transit',
        'complete',
        'delivery',
        'fulfilled',
        'not-started',
        'in-storage',
        'released-to-inventory',
        'mixed-shipping',
      ];
      let moduleName = '';
      let stage = '';
      let cardId = '';
      // if clicked on an item it will have order property set via BO.init
      const projectId = _.get(card.project, '_id', '');
      // if it is not a bo card. please handle in the controller itself.
      if (card instanceof BaseOrder) {
        if (
          card.__t !== 'ProdTemplates'
          && card.__t !== 'MatTemplates'
          && card.items.length === 0
          && !['planning', 'preparation'].includes(card.stage)
        ) {
          // this.$toast.open({
          //   message: 'The Order is archived, and cannot be opened',
          //   type: 'is-danger has-text-black',
          // });
          return;
        }
        moduleName = getModuleName(card);
        if (card.__t === 'Materials') {
          stage = card.stage === 'mixed' ? card.getCardStage() : card.stage;
        } else if (card.__t === 'Sourcing' && shipStages.includes(card.stage)) stage = 'ordering';
        else {
          stage = card.stage === 'mixed' ? card.getCardStage() : card.stage;
        }

        cardId = card._id;
      } else if (card.order instanceof BaseOrder) {
        const item = card; // it is actually an item.
        // adding prefabs condition coz in future if someone gives link in Prefab list view
        const cardRef = ['Materials', 'Sourcing'].includes(item.baseCardType)
          || card.order.__t === 'Prefabs'
          ? item.cards[0]
          : item.cards[1];
        cardId = cardRef;
        if (card.order.__t === 'Sourcing' && shipStages.includes(item.stage)) stage = 'ordering';
        else stage = shipStages.includes(item.stage) ? 'delivery' : item.stage;
        moduleName = getModuleName(card.order);
      }
      if (card.cardType === 'templateForm') {
        const params = {
          cardId: card._id,
          companyId: store.state.userData.company,
        };
        router.openWithUserPref({
          name: 'formTemplate-edit',
          params,
        });
      } else {
        router.push({
          name: `${moduleName}-edit`,
          params: { projectId, cardId, stage },
        });
      }
    }
    const getRequiredCheck = computed(() => {
      if (store.state.activeScreen === 'mm-ordering') {
        return [
          { module: 'shipping', key: 'data' },
          { module: 'material-manager', key: 'move' },
        ];
      }
      return [];
    });
    const permissionType = () => {
      const res = _.get(screens[store.state.activeScreen], 'permissionModule', '');
      if (res === 'shipping') return 'scm';
      return res;
    };

    const getAllProjects = computed(() => {
      const permModule = (permissionType() === 'scm')
        ? 'shipping' : permissionType();
      return store.getters.getProjectsOnPermission(permModule, store.state.queryParams.projects, 'data');
    });
    const groupedProjects = computed(() => {
      const projects = getAllProjects.value;
      const fetchFilteredProjects = store.state.queryParams.projects;
      const filteredProjectIds = fetchFilteredProjects.map((project) => project._id);
      const filteredProjects = _.filter(store.state.queryParams._projects,
        (project) => !project.isTemplateProject && filteredProjectIds.includes(project._id));
      if (store.state.queryParams.filteredProjects) {
        return filteredProjects;
      }
      return projects;
    });
    const onCellClicked = async (event) => {
      const order = event.data;
      if (event.type === 'reject') {
        state.orderId = order._id;
        state.isRejectActive = true;
        ModalProgrammatic.open({
          component: RejectSourcing,
          props: {
            orderId: state.orderId,
          },
          canCancel: false,
          rootClass: 'modal-xs',
          events: {
            'refresh-card': async () => {
              // eslint-disable-next-line no-unused-expressions
              await materialTable.value?.refreshTable();
            },
          },
        });
      } else if (event.type === 'save') {
        await saveRow(event.data);
      } else if (event.type === 'remove') {
        await archiveCard(event.data);
      } else if (event.type === 'clone') {
        ModalProgrammatic.open({
          component: CloneCard,
          props: {
            card: event.data,
            headerTitle: event.data.stage === 'sourcing' ? 'Sourcing Order' : 'Requesting Order',
            copyOpts: [
              {
                model: 'documents',
                label: 'Order Documents',
              },
            ],
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-xs',
          events: {
            'refresh-table': async () => {
              // eslint-disable-next-line no-unused-expressions
              await materialTable.value?.refreshTable();
            },
          },
        });
      } else if (event.type === 'split') {
        if (event.data.items.length < 2) {
          toast.warning(
            'Since the order has less than 2 items, so we cannot split this order',
          );
        } else {
          let order = event.data;
          state.isLoading = true;
          order = await order.attachVendors();
          state.isLoading = false;
          ModalProgrammatic.open({
            component: SplitCard,
            props: {
              card: order,
              isActive: true,
            },
            custom: false,
            trapFocus: true,
            canCancel: false,
            events: {
              'refresh-table': async () => {
                await materialTable.value?.refreshTable();
              },
            },
          });
        }
      } else if (event.type === 'openCard') {
        openWithUserPref(event.data);
      } else if (event.type === 'addNew') {
        state.createOrderModal = true;
      } else if (event.type === 'activity') {
        state.isActive = true;
        state.activityDetails.id = event.data._id;
        state.activityDetails.projectId = event.data.project._id;
        state.activityDetails.orderName = event.data.name;
      } else if (event.type === 'export') {
        exportMaterialItems(event);
      } else if (event.type === 'printQr') {
        printQr(event.data);
      } else if (event.type === 'excelImport') {
        // eslint-disable-next-line prefer-destructuring
        state.selectedProject = groupedProjects.value[0];
        state.excelImportModal = true;
      } else if (event.type === 'lmv') {
        openLmvModel(event.data);
      }
    };

    const closeModal = () => {
      state.isRejectActive = false;
    };

    const closeExcelModal = () => {
      state.excelImportModal = false;
      state.isLoading = false;
    };

    const validateExcelItem = (item) => {
      let errMsg = null;
      if (_.isEmpty(item.name) || item.name.length < 3) {
        errMsg = 'Name Field must contain three or more characters!';
      } else if (!item.onSiteBy) {
        errMsg = 'onSite field is mandatory!';
      } else if (item.materialOwner && !Validation.validateEmail(item.materialOwner)) {
        errMsg = 'Owner Field must have a valid email!';
      }
      else if (item.financialId && item.financialId !== '') {
        item.financialId = item.financialId.toString().trim().replace(/ /g, '.').toUpperCase();
        if (!Validations.validateOrderId(item.financialId)) {
          errMsg = 'Description length must be 4 to 24 characters long and cannot start or end with special characters';
        }
      }
      if (!errMsg && state.dates) {
        validateDates(item);
      }
      return errMsg;
    };

    const saveExcelItem = async (obj) => {
      if (!state.excelImportModal) {
        return materialTable.value.refreshTable();
      }
      obj.__t = 'Materials';
      obj.stage = 'preparation';
      obj._customStage = 'preparation';
      state.allUsers = await Projects.linkedProjectUsers([state.selectedProject._id]);
      let material = new BaseOrder(obj);
      let user;
      if (obj.materialOwner) {
        user = _.find(state.allUsers, (usr) => usr.email === obj.materialOwner);
      }
      const project = store.getters.findProject({ _id: state.selectedProject._id });
      const defaults = project.getDefaults(material, 'preparation');
      if (!user) {
        if (!_.isEmpty(defaults.newOwner)) {
          user = _.find(state.allUsers, (usr) => usr._id === defaults.newOwner._id);
        } else {
          // eslint-disable-next-line
          user = store.state.userData;
        }
      }
      material.owner.user._id = user._id;
      material.owner.company._id = user.company;
      if (!_.isEmpty(defaults.newLocation)) {
        material.baseDelivery.location = defaults.newLocation;
      }
      material.project._id = state.selectedProject._id;
      if (_.has(material, 'onSiteBy')) {
        material.newDate('deliver');
        // Add earliest orderby same as deliver whhile importing orders.
        material.newDate('orderBy');
        material.simpleDates.deliver.value = moment.utc(material.onSiteBy).hours(12).format();
        material.simpleDates.orderBy.value = moment.utc(material.onSiteBy).hours(12).format();
      }
      if (_.has(material, 'note')) {
        material.currentNote = {};
        material.currentNote.text = material.note;
      }
      if (store.state.defaultItem) {
        material.addItem({ name: material.name, customId: material.financialId });
      }
      material = addFiles(material);

      await material.save();
      return null;
    };

    const checkToDisableNext = (project) => {
      excel.value.checkToDisableNext(project);
    };
    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      // eslint-disable-next-line no-unused-expressions
      materialTable?.value?.closeColumnChooserModal();
      // eslint-disable-next-line no-unused-expressions
      materialTable?.value?.refreshFilterOpts();
    };

    const refreshTable = async () => {
      await materialTable.value?.refreshTable();
    };

    const cardKitId = (card) => {
      if (card.__t === 'Sourcing') {
        if (!_.isEmpty(card.kitIds)) return card.kitIds.join(', ');
        return '';
      }
      return card.customId;
    };

    const updateNotes = async (event) => {
      if (event.type === 'save') {
        let order = _.cloneDeep(event.data);
        _.set(event.data, 'isEditing', !event.data.isEditing);
        order = await saveRow(order);
        Object.assign(event.data, order);
      }
    };

    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      state.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      state.isFileListVisible = false;
    };

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      state.selectedItem = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive && _.isUndefined(payload.fromMove);
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      state.selectedItem = payload.data;
      state.rowField = payload.rowField;
      state.isFileListVisible = payload.isActive;
    });
    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });
    onBeforeMount(async () => {
      state.allProjects = await Projects.allProjects();
    });

    return {
      ...toRefs(state),
      loadData,
      onCellClicked,
      materialTable,
      onCreate,
      stageFromUrl,
      closeModal,
      groupedProjects,
      validateExcelItem,
      saveExcelItem,
      closeExcelModal,
      excel,
      checkToDisableNext,
      isBulkActionEnabled,
      triggerSetHeaderHeight,
      refreshTable,
      cardKitId,
      updateNotes,
      closeNotesSlider,
      getRequiredCheck,
      closeDocumentSlider,
    };
  },
});
</script>

<style scoped lang="sass"></style>
