const arrayKey = {
  'not-started': 'Not Started',
  'in-progress': 'In Progress',
  complete: 'Complete',
};

export default {
  defaultSortField: 'taskId',
  defaultSortOrder: 'asc',
  isDetailed: false,
  stickeyHeaders: true,
  rearrangeCols: false,
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      isLine: true,
      tooltip: 'Customize Columns',
    }],
  },
  toolBar: {
    // name: for drop down label, label: for action button label
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: [],
    bulkActions: () => {},
  },
  fields: [
    {
      name: 'field-generic-input',
      width: '400px',
      title: 'Request ID',
      prop: 'taskId',
      sortField: 'taskId',
      id: 'taskId',
      isClickable: true,
      searchable: true,
      sticky: true,
      defaultVisible: true,
      alwaysVisible: true,
      eventType: 'openCard',
      inputProps: {
        type: 'text',
        disableEdit: true,
      },
    },
    {
      name: 'requestTypes',
      width: '152px',
      title: 'Request Type',
      prop: 'type',
      id: 'requestTypes',
      sortField: 'type',
      searchable: true,
      filterText: 'Types',
      defaultVisible: true,
    },
    {
      name: 'owner',
      prop: 'owner',
      width: '296px',
      title: 'Owner',
      searchable: true,
      defaultVisible: true,
      filterText: 'Owners',
      id: 'assignee',
      sortField: 'assignee.user',
      dataClass: 'overflow-visible',
      inputProps: {
        type: 'text',
        openCard: false,
      },
    }, {
      name: 'field-stage',
      width: '196px',
      title: 'Status',
      prop: 'status',
      id: 'taskStatus',
      sortField: 'status',
      searchable: true,
      defaultVisible: true,
      filterText: 'Statuses',
      isStatus: true,
      isStaticColor: true,
      formatter(row) {
        return arrayKey[row];
      },
    }, {
      name: 'createdAt',
      prop: 'createdAt',
      sortField: 'created.at',
      defaultVisible: false,
      width: '112px',
      title: 'Created',
      id: 'createdAt',
      searchable: true,
      dateFilter: 'createdAt',
      inputProps: {
        kind: 'created',
      },
    }, {
      name: 'field-date',
      width: '112px',
      title: 'Due Date',
      id: 'dueDate',
      sortField: 'dueDate',
      prop: 'date',
      searchable: true,
      defaultVisible: true,
      dateFilter: 'dueDate',
      inputProps: {
        type: 'mf-date',
        kind: 'dueDate',
        isEditing: 'isEditing',
        disableEdit: false,
      },
    }, {
      name: 'sourceOrder',
      width: '296px',
      title: 'Source Order',
      id: 'sourceOrder',
      defaultVisible: false,
      isDisabled: true,
      inputProps: {
        type: 'text',
        disableEdit: true,
      },
    }, {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      width: '112px',
      sticky: true,
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      searchable: true,
      hideFromChooser: true,
      alwaysVisible: true,
      clearBtn: true,
      permissionModule: 'inventory',
      permissionKey: 'transfer_request_list',
      inputProps: {
        showExcelIcon: false,
        options() {
          const optionsToShow = [{
            label: 'Close Request',
            event: 'closeRequest',
            icon: 'icon-close',
          }, {
            label: 'History',
            event: 'activity',
            icon: 'icon-activityhistory',
          }];
          return optionsToShow;
        },
      },
    },
  ],
};
