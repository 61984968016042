<template>
    <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
    <div class="list-view">
        <mf-table
                ref="dateChangeTable"
                :tableProps="tableProps"
                :hideGutter="true"
                :loadData="loadData"
                :screenTable="true"
                tableName="dateChangeTable"
                @cell-clicked="$emit('cell-clicked', $event)"
        >
            <template v-slot:dateType="date">
              <span>
                {{'On-Site'}}
              </span>
            </template>
            <template v-slot:oldDate="{rowData}">
              <span class="is-pulled-right">
                 {{$filters.usaDate($_.get(rowData, 'onSiteChange.oldValue', ''))}}
              </span>
            </template>
            <template v-slot:newDate="{rowData}">
              <span class="is-pulled-right">
                 {{$filters.usaDate($_.get(rowData, 'onSiteChange.newValue', ''))}}
              </span>
            </template>
            <template v-slot:dateOfChange="date">
              <span class="is-pulled-right">
                {{$filters.usaDate(date.rowData.timeStamp)}}
              </span>
            </template>
             <template v-slot:module="data">
               {{$_.startCase(data.rowData.module)}}
            </template>
        </mf-table>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import Activity from '@/models/Activity';
import {
  extend, keys, some, find, isUndefined, isEmpty, filter,
} from 'lodash';
import dateViewCols from '@/components/table-cols/dateChangeViewCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import {
  ref, reactive, toRefs, onMounted,
} from 'vue';

export default ({
  props: {
    showEmpty: Boolean,
    moduleName: Object,
  },
  components: {
    MfTable,
  },
  setup(props) {
    const store = useStore();
    const dateChangeTable = ref(null);
    const state = reactive({
      tableProps: dateViewCols,
      isLoading: false,
    });

    const refreshTable = async () => {
      await dateChangeTable.value?.refreshTable();
    };
    const loadData = async (commonParam) => {
      const result = await Activity.getrecentEvents({
        params: {
          projectId: store.getters.selectedIdsForKey('filteredProjects'),
          createdCompany: store.getters.selectedIdsForKey('companies', false),
          ...store.getters.selectedDatesForKeys([
            'DateStartDate',
            'DateEndDate',
          ]),
          fieldChange: ['Date'],
          search: store.state.queryParams.searchText,
          module: store.state.queryParams.module.map((mod) => mod.value),
          ...commonParam,
        },
      });
      result.data = result.data
        .filter((date) => {
          const moduleName = date.module.charAt(0).toLowerCase() + date.module.slice(1);
          if ((some(date.dateChange, { name: 'On-Site' })
                      || some(date.dateChange, { name: 'onSiteBy' })) && date[moduleName]) {
            return true;
          }
          return false;
        })
        .map((dateAct) => {
          dateAct.onSiteChange = find(dateAct.dateChange, { name: 'onSiteBy' });
          if (isUndefined(dateAct.onSiteChange) || isEmpty(dateAct.onSiteChange)) {
            dateAct.onSiteChange = find(dateAct.dateChange, { name: 'On-Site' });
          }
          extend(dateAct, dateAct.order);
          if (keys(props.moduleName).indexOf(dateAct.module) > -1) {
            dateAct.cardId = dateAct[props.moduleName[dateAct.module].module]._id;
          }
          return dateAct;
        });
      const stage = store.state.queryParams.stage.map((s) => s.value);
      if (!isEmpty(stage)) {
        result.data = filter(result.data, (dt) => stage.includes(dt.stage));
      }

      return result;
    };
    onMounted(async () => {
      store.commit('setQueryParams', { module: [] });
      store.commit('setQueryParams', { stage: [] });
    });

    return {
      ...toRefs(state),
      dateChangeTable,
      loadData,
      refreshTable,
    };
  },
});
</script>
