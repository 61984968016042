export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  isCheckable: true,
  hasCheckBox: true,
  isDetailed: false,
  fields: [{
    name: 'field-generic-input',
    prop: 'name',
    width: '500px',
    title: 'Item Name',
    id: 'name',
    inputProps: {
      type: 'text',
      disableEdit: true,
    },
  },
  {
    name: 'field-generic-input',
    prop: 'catId',
    width: '300px',
    title: 'Catalog ID',
    id: 'catId',
    inputProps: {
      type: 'text',
      disableEdit: true,
    },
  },
  {
    name: 'field-generic-input',
    prop: 'quantity',
    width: '112px',
    title: 'Qty',
    id: 'quantity',
    inputProps: {
      type: 'text',
      disableEdit: true,
    },
  }],
};
