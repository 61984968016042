import { reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import _ from 'lodash';
import Prefabs from '@/models/Prefabs';
import ProductionManager from '@/models/ProductionManager';
import Users from '@/models/Users';
import Materials from '@/models/MaterialManager';
import Locations from '@/models/Locations';
import Order from '@/models/Orders';
import Company from '@/models/Companies';
import GeneralShippingMixin from '../mixins/GeneralShippingMixin';
import moment from 'moment';
import Projects from '@/models/Projects';
import Helper from '@/models/Helper';
import Catalogs from '@/models/Catalogs';
import UtilityMixin from './UtilityMixin';
import Validation from '@/utils/Validations';
import { DialogProgrammatic } from '@/components/Dialog';


export default function () {
  const store = useStore();
  const toast = useToast();
  const { getAllLocations, conflictHtml } = UtilityMixin();
  const { unreserveAll } = GeneralShippingMixin();
  const state = reactive({
    result: {},
    shippingOrders: [],
    projectUsers: {},
    showLoading: false,
    newCard: {
      __t: null,
      name: '',
      oldName: '',
      stage: '',
      _customStage: '',
      _alsoNotify: null,
      notifyUsers: [],
      location: null,
      defaultRun: null,
      pTrackEnabled: false,
    },
    project: {},
    companyRuns: [],
    projectLocations: [],
    listUsers: [],
    addDefaultRun: false,
    onePerItem: false,
  });

  const moveFromPlanningToCordination = async (card) => {
    try {
      return await Prefabs.moveToPO(card);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const moveFromCordinationToDetailing = async (cards) => {
    try {
      for (const card of cards) {
        // validations
        const poDetailByDate = card.getDate('poDetailBy');
        if (!poDetailByDate.length > 0) {
          throw new Error('Failed to Move : Detail By Date is required');
        }
        const poOnSiteDate = card.getDate('deliver');
        if (!poOnSiteDate.length > 0) {
          throw new Error('Failed to Move : On-Site Date is required');
        }
        const poLocation = card.location;
        if (!poLocation) {
          throw new Error('Failed to Move : Production Location is required');
        }
        await ProductionManager.createFromPO({ card });
      }
      return {
        success: true,
        message: 'Order moved to Detailing successfully',
      };
    } catch (e) {
      return {
        success: false,
        message: e.message,
      };
    }
  };

  const createBOMForOrders = async (card) => {
    let materials = [];
    for (const item of card.items) {
      const bomInfo = {};
      bomInfo.projectId = card.project._id;
      bomInfo.cardId = card._id;
      bomInfo.poManufactureBy = card.getDate('manufactureBy');
      bomInfo.itemId = _.get(item, '_id', null);
      bomInfo.bomManufactureBy = bomInfo.bomBefore = card.dateOffset;
      bomInfo.manufactureBy = _.isEmpty(bomInfo.poManufactureBy) ? bomInfo.poManufactureBy : moment(bomInfo.poManufactureBy).subtract(bomInfo.bomBefore, 'days').format('MM-DD-YYYY');
      const BOM = await Materials.createBOM(bomInfo);
      if (_.isEmpty(BOM)) continue;
      materials = _.concat(materials, BOM);
    }
    const isNestedLoc = isNestedLocation([card]);
    if (materials.length > 0) {
      const bom = materials.filter((m) => card.items.some((itm) => itm._id === m.linkedAssembly));
      if (!_.isEmpty(bom)) {
        // retain materials already linked to the order
        const matsToLink = _.compact(_.concat(bom, card.materials));
        const materialIds = _.castArray(matsToLink).map((b) => b._id);
        const order = await Order.addMaterials(card, _.uniqBy(materialIds));
        card.materials = order.materials;
        if (isNestedLoc) card._createTR = true;
      }
    }
    return card;
  };

  const attachFormsAndTodos = (order, selectedTemplate) => {
    // adding order level forms
    let orderTodos = [];
    for (const file of selectedTemplate.files) {
      if (!_.isEmpty(file) && file.type === 'form' && file.sources.length === 1) {
        const newFile = order.newFile();
        _.assign(newFile, _.pick(file, ['url', 'name', 'copiedFrom', 'visible', 'type']));
        orderTodos = _.filter(selectedTemplate.todos, (todo) => {
          const fileUrls = _.map(todo.files, 'url');
          return fileUrls.includes(file.url) && todo.sources.length === 1;
        });
        order.createTodos(orderTodos, order.owner, order);
      }
    }
    return order;
  };

  const moveFromDetailingToManufacturing = async (cards) => {
    try {
      const newCards = [];
      for (const card of cards) {
        const order = await ProductionManager.moveTo('manufacturing', card);
        newCards.push(order);
      }
      return {
        newCards,
        success: true,
        message: 'Order moved to Manufacturing successfully',
      };
    } catch (e) {
      return {
        success: false,
        message: e.message,
      };
    }
  };

  const isProjectPTEnabled = (card) => {
    const user = store.state.userData;
    const company = _.find(card.project.projectSettings, {
      companyId: user.company,
    });
    if (_.isEmpty(company)) return false;
    return company.isPTEnabled;
  };

  // create new prefab card
  const createNewPrefabCards = async (cards) => {
    const newCards = [];
    for (let i = 0; i < cards.length; i++) {
      const deepClone = _.cloneDeep(cards[i]);

      // grab all the properties
      state.newCard = Object.assign(deepClone, state.newCard);

      // __t
      state.newCard.__t = 'ProductionOrder';

      // set the name
      state.newCard.name = state.onePerItem
        ? 'Item name will become order name'
        : '';

      // set the oldName
      state.newCard.oldName = cards[i].name;

      // stage
      state.newCard.stage = 'coordination';

      // customStage
      state.newCard._customStage = 'planning';

      // project
      const currProject = await Projects.getOne(cards[i].project._id);
      const defaults = currProject.getDefaults(state.newCard, 'coordination');

      // companyIds
      const companyIds = [cards[i].owner.company._id];
      if (!['detailing', 'manufacturing', 'qa'].includes(state.newCard.stage)) {
        companyIds.push(..._.map(currProject.projectCompanies, '_id'));
      }

      // location
      const pls = await getAllLocations(currProject._id);

      let loc = cards[i].location;
      if (cards[i].isPM()) {
        loc = _.get(cards[i], 'manager.location');
      }
      state.newCard.location = defaults.newLocation
        || _.find(pls, {
          _id: loc ? loc._id : null,
        });

      // manager
      state.listUsers = await Users.getCompanyProjectAllLinkedUsers({
        params: {
          companyIds,
          projectId: cards[i].project._id,
          ignoreCompany: true,
        },
      });
      const oldManagerOwner = cards[i].isManager
        ? _.get(cards[i], 'manager.owner')
        : cards[i].owner;
      state.newCard.owner._oldUser = _.clone(cards[i].owner.user);

      // owner

      state.newCard.owner.user = defaults.newOwner
        || _.find(state.listUsers, { _id: oldManagerOwner.user._id });

      // alsoNotify
      state.newCard._alsoNotify = cards[i].baseDelivery.recipient || null;

      // notify users
      state.newCard.notifyUsers = _.map(state.newCard._alsoNotify || [], '_id');

      // defaultRun
      const user = store.state.userData;
      state.companyRuns = await Company.getCompanyRuns('companyRuns');

      const projectSetting = _.find(currProject.projectSettings, {
        companyId: user.company,
      });

      if (projectSetting) state.addDefaultRun = projectSetting.addDefaultRun;
      state.newCard.defaultRun = state.companyRuns;

      // pTrackEnabled
      state.newCard.pTrackEnabled = isProjectPTEnabled(cards[i]) && !cards[i].multiTrade.value;
      if (state.newCard.pTrackEnabled) [state.newCard.defaultRun] = state.companyRuns;

      newCards.push(state.newCard);
    }

    return newCards;
  };

  // create new PO card
  const createNewPOCards = async (cards) => {
    const newCards = [];
    for (let i = 0; i < cards.length; i++) {
      const deepClone = _.cloneDeep(cards[i]);

      // grab all the properties
      state.newCard = Object.assign(deepClone, state.newCard);

      // __t
      state.newCard.__t = 'ProductionOrder';

      // set the name
      state.newCard.name = cards[i].name;

      // stage
      state.newCard.stage = 'detailing';

      // customStage
      state.newCard._customStage = 'coordination';

      // project
      const currProject = await Projects.getOne(cards[i].project._id);
      const defaults = currProject.getDefaults(state.newCard, 'detailing');

      // companyIds
      const companyIds = [cards[i].owner.company._id];
      if (!['detailing', 'manufacturing', 'qa'].includes(state.newCard.stage)) {
        companyIds.push(..._.map(currProject.projectCompanies, '_id'));
      }

      // location
      const pls = await getAllLocations(currProject._id);

      let loc = cards[i].location;
      if (cards[i].isPM()) {
        loc = _.get(cards[i], 'manager.location');
      }
      state.newCard.location = defaults.newLocation
        || _.find(pls, {
          _id: loc ? loc._id : null,
        });

      state.projectLocations = Locations.groupLocations(
        pls,
        store.state.companyData,
      );

      // manager
      state.listUsers = await Users.getCompanyProjectAllLinkedUsers({
        params: {
          companyIds,
          projectId: cards[i].project._id,
          ignoreCompany: true,
        },
      });
      const oldManagerOwner = cards[i].isManager
        ? _.get(cards[i], 'manager.owner')
        : cards[i].owner;
      state.newCard.owner._oldUser = _.clone(cards[i].owner.user);

      // owner
      state.newCard.owner.user = defaults.newOwner
        || _.find(state.listUsers, { _id: oldManagerOwner.user._id });

      // alsoNotify
      state.newCard._alsoNotify = defaults.notify || null;

      // notify users
      state.newCard.notifyUsers = _.map(state.newCard._alsoNotify || [], '_id');

      // defaultRun
      const user = store.state.userData;
      state.companyRuns = await Company.getCompanyRuns('companyRuns');
      const projectSetting = _.find(currProject.projectSettings, {
        companyId: user.company,
      });
      if (projectSetting) state.addDefaultRun = projectSetting.addDefaultRun;
      [state.newCard.defaultRun] = state.companyRuns;

      // pTrackEnabled
      state.newCard.pTrackEnabled = true;

      newCards.push(state.newCard);
    }

    return newCards;
  };

  const isUniqueValueIsUpdated = (card) => {
    const beforeValue = _.get(card, '_beforeEdit.uniqueOrderId.value', '');
    const afterValue = _.get(card, 'uniqueOrderId.value', '');
    return (beforeValue !== afterValue);
  };

  const saveRow = async (card) => {
    try {
      store.commit('setLoading', true);
      if (isUniqueValueIsUpdated(card)) {
        _.set(card, 'uniqueOrderId.value', Validation.getValidUniqueorderId(_.get(card, 'uniqueOrderId.value', '')));
        const uniqueOrderId = _.get(card, 'uniqueOrderId.value', '');
        if (!_.isEmpty((uniqueOrderId)) && !Validation.validateUniqueOrderId(uniqueOrderId)) {
          throw new Error(`Invalid Order ID ${uniqueOrderId}`);
        }
      }
      return await card.save();
    } catch (e) {
      const msg = e?.data?.message || e?.message
        || e?.data?.msg
        || 'Error saving: please contact ManufactOn support';
      const conflictMsg = conflictHtml(msg);
      // const conflictMsg = `<h3 class="is-size-3 has-text-weight-bold mb-3 has-text-danger is-italic" >Duplicate Order ID:</h3>
      // <h3 class="is-size-3  mb-3 has-text-danger" > ${msg.OrderId}</h3>
      // <h6 class="is-size-6 has-text-weight-bold has-text-black-bis" >(Order ID's must be unique and cannot be duplicated within a single project.) </h6>
      // <div class="is-divider my-3"></div>
      // <h3 class="is-size-3 mb-3 has-text-weight-bold has-text-black-bis is-italic" >Order Name:</h3>
      // <h3 class="is-size-3 mb-3 has-text-black-bis" >${msg.OrderName} </h3>
      //  <h3 class="is-size-3 mb-3 has-text-weight-bold has-text-black-bis is-italic" >Project: </h3>
      //  <h3 class="is-size-3 has-text-black-bis" >${msg.Project} </h3>`;
        if (msg.OrderId || msg.OrderName || msg.Project) {
          const confirmParam = {
            title: 'CONFLICT: Order ID',
            okButton: 'Close',
            type: 'black-bis',
            conflictNote: conflictMsg,
            noteType: 'danger',
          };
          DialogProgrammatic.confirm(confirmParam);
        } else {
          toast.error(msg);
        }
    } finally {
      store.commit('setLoading', false);
    }
  };

  const pullBackwardToPlanning = async (cards) => {
    try {
      const archiveBom = true;
      const promises = [];
      for (const card of cards) {
        promises.push(Order.archive(card, 'return', archiveBom));
      }
      await Promise.all(promises);
      return {
        success: true,
        message: `${cards.length} Order's moved to Planning successfully`,
      };
    } catch (e) {
      console.log('error', e);
      return {
        success: false,
        message: e.message,
      };
    }
  };

  // move any prod ordder (eg. manu -> qa)
  const moveProdOrder = async (card) => {
    let newStageKey = `${card.stage.toLowerCase()}By`;

    if (newStageKey === 'manufacturingBy') newStageKey = 'manufactureBy';

    const mfBy = card.getDate(newStageKey) || card.simpleDates.poManufactureBy?.value;
    const qaQc = card.getDate('qaBy') || card.simpleDates.poQaBy?.value;
    let newCard = _.pick(card.toJSON(), ['_id', 'owner', 'status', 'items', 'location', 'project', 'manager', 'notifyUsers', 'isCutOrder']);

    if (mfBy) {
      newCard[newStageKey === 'manufactureBy' ? 'manufacturedBy' : newStageKey] = mfBy;
    }
    if (qaQc) {
      newCard.qaBy = qaQc;
    }
    newCard.projectId = card.project._id;
    if (['coordination', 'detailing'].includes(card.stage)) {
      //to unreserve/consume  reserved items
      await unreserveAll(card);
    }
    newCard = await ProductionManager.moveTo(card.stage, newCard);
    return newCard;
  };

  // check for nested locaton
  const isNestedLocation = (cards) => {
    const allCards = _.castArray(cards);
    const projectIds = _.map(allCards, 'project._id');
    const allProjects = [];
    for (const id of projectIds) {
      const projectFromStore = store.getters.findProject({ _id: id });
      allProjects.push(projectFromStore);
    }
    return _.some(allProjects, (project) => project.hasNesetedProjectInvLoc(store.state.userData));
  };

  // check if multi stages are selected
  const isMixedSelection = (cards) => {
    const selectedStages = cards.map((item) => item.stage);
    const selectedStagesSet = new Set(selectedStages);
    return selectedStagesSet.size > 1;
  };

  // create bom and Pick Request
  const createBomAndPR = async (card) => {
    let assemblyItems = _.filter(card.items, { fromMasterCatalog: true });
    //  if (assemblyItems.length) _.set(card, '_bomTasks', { create: false, submit: false });
    let bomOrders = [];

    if (_.get(card, 'materials.length', 0) > 0) {
      bomOrders = await card.getSubmitMaterials();
      const linkedinAssemblyIds = _.compact(_.map(bomOrders, 'linkedAssembly'));
      // filtering all assembly Items with no BOM
      assemblyItems = _.filter(assemblyItems,
        (item) => !linkedinAssemblyIds.includes(item._id));
    } else {
      // const catId = card.items ?  : [];
      const data = await Catalogs.getAssemblyParts({
        catId: _.map(card.items, 'catId') || [],
      });
      let partsData = [];
      _.forEach(data.data, (part) => {
        partsData = _.concat(partsData, part.partsData);
      });
      card._disableSubmit = !_.some(partsData, 'purchase');
    }
    const cardStage = _.get(card, 'stage');
    let createBom;
    let bomsToSubmit;
    if (['coordination', 'detailing'].includes(cardStage)) createBom = await Helper.hasBomCreated(assemblyItems);
  
    if (createBom && assemblyItems.length) {
      // create and submit bom
      card._bomCreated = false;
      card._bomSubmitted = false;
      card._createTR = false;
      card._disableSubmit = _.some(bomOrders, (ord) => ord.hasNotPurchasedItems) || card._disableSubmit;
    } else {
      const boms = await card.getSubmitMaterials();
      bomsToSubmit = boms.filter((bom) => bom.stage === 'preparation');
      card._bomCreated = true;
      card._bomSubmitted = !bomsToSubmit.length;
      card._disableSubmit = _.some(boms, (ord) => ord.hasNotPurchasedItems) || card._disableSubmit;
    }
    const cards = [card];
    const isNestedLoc = isNestedLocation(cards);
    const hasReserved = (type) => _.some(cards, (cD) => !_.isEmpty(cD[type]));
    if(card.isCutOrder && isNestedLoc) card._createTR =true; 
    if (!isNestedLoc && bomsToSubmit) {
      card._createTR = false;
    } else if (isNestedLoc && (hasReserved('materials')
      || hasReserved('fromInvOrderId'))) {
      card._createTR = true;
    }
    return card;
  };

  return {
    ...toRefs(state),
    moveFromPlanningToCordination,
    moveFromCordinationToDetailing,
    moveFromDetailingToManufacturing,
    createNewPrefabCards,
    createNewPOCards,
    createBOMForOrders,
    saveRow,
    pullBackwardToPlanning,
    moveProdOrder,
    isNestedLocation,
    isMixedSelection,
    createBomAndPR,
    attachFormsAndTodos,
  };
}
