<template>
  <div>
    <VDropdown :autoHide="false">
      <button v-if="edit" class="is-pulled-right button px-0 " v-tooltip="'Add New'">
        <i class="icon-addnew border-raidus" @click="fetchAllTags"
        ></i>
      </button>
      <template #popper>
        <div class="card">
          <header class="card-header  has-background-grey-lighter">
            <h4
                    class="card-header-title is-size-3 has-text-black-bis pl-0
                  is-capitalized has-text-weight-bold"
            >
              Manage Tags
            </h4>
          </header>
          <section class="card-content mt-2 is-marginless has-background-white">
            <div class="control has-icons-left mb-3">
                  <input
                          class="input"
                          type="text"
                          placeholder="Search or add custom tags"
                          v-model="searchTag"
                          @keyup.enter="addProjectTag()"
                  />
                  <span class="icon is-small is-left">
                    <i class="icon-search"></i>
                  </span>
            </div>
            <div>

              <div id="tag-field" class="field is-grouped is-grouped-multiline">
              </div>
                <div class="tags-body">
                  <div v-if="!createProjectTag">
                    <div class="mb-3">
                      <h3 class="is-size-3">Company Tags</h3>
                      <button class="button is-primary is-fullwidth
                        is-flex is-justify-content-space-between" v-for="tag in companyTags()"
                        v-bind:key="tag._id" @click="toggleTag(tag)"
                        :class="tag.isTagAdded ? '' : 'is-outlined' ">
                      <span class="is-size-3">{{tag.name}}</span>
                      <span class="icon is-small">
                        <i :class="[tag.isTagAdded ? 'icon-removedelete' : 'icon-addnew']"></i>
                      </span>
                    </button>
                    </div>
                      <!-- project select tags -->
                      <div>
                        <h3 class="is-size-3">Project Tags</h3>
                        <button class="button is-fullwidth is-flex is-justify-content-space-between"
                          v-for="tag in projectTags()"
                            v-bind:key="tag._id"
                            @click="toggleTag(tag)" :class="tag.isTagAdded ?
                              'has-background-black-bis has-text-white' : 'is-outlined' ">
                          <span class="is-size-3">{{tag.name}}</span>
                          <span class="icon is-small">
                            <i :class="[tag.isTagAdded ? 'icon-removedelete' : 'icon-addnew']"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  <span v-if="createProjectTag" class="has-text-black-bis has-text-weight-bold">
                    No tags found. Press 'enter' to create new project tag.
                  </span>
                </div>
            </div>
          </section>
          <footer class="card-footer is-justify-content-flex-end has-background-grey-lighter">
            <div>
              <button v-close-popper="true"
                class="button has-background-black-bis has-text-white is-pulled-right"
                @click="updateTagsList">
                Done
              </button>
            </div>
          </footer>
        </div>
      </template>
    </VDropdown>
  </div>
</template>
<script>
// import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import {
  defineComponent, reactive, toRefs, computed, onMounted,
} from 'vue';
import _ from 'lodash';
import Tags from '@/models/Companies';
import Validation from '@/utils/Validations';
import { useToast } from 'vue-toastification';
import { CardDirtyBus } from '@/utils/CardDirtyBus';

const MyComponent = defineComponent({
  name: 'mf-tags',
  props: {
    card: Object,
    edit: {
      type: Boolean,
      // keeping default true as card view is always editable
      default: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const { card } = props;
    const state = reactive({
      searchTag: '',
      tagsToUpdate: [],
      currentSearchText: '',
      existingTags: [],
      existingColors: [],
      tagList: [],
    });
    const tagTarget = computed({
      get: () => (card.isManager ? card.manager.tags : card.tags),
      set: (val) => {
        // this.reComputeTagLimit();
        if (card.isManager) card.manager.tags = val;
        else card.tags = val;
      },
    });
    const methods = {
      updateTagsList() {
        if (!_.isEmpty(state.tagsToUpdate)) {
          const tagsToRemove = _.filter(state.tagsToUpdate, (i) => i.isTagAdded === false);
          // eslint-disable-next-line max-len
          tagsToRemove.forEach((i) => card.tags.splice(props.card.tags.findIndex((e) => e._id.toString() === i._id.toString()), 1));
          const tagsToAdd = _.filter(state.tagsToUpdate, (i) => i.isTagAdded === true);
          tagsToAdd.forEach((i) => {
            if (card.isPM()) {
              card.manager.tags.push(i);
            } else {
              card.tags.push(i);
            }
          });
          state.tagsToUpdate = [];
          CardDirtyBus.emit('setCustomDirty');
        }
      },
      toggleTag(tag) {
        const resultTag = _.find(state.tagsToUpdate, { _id: tag._id });
        if (!_.isEmpty(resultTag)) {
          resultTag.isTagAdded = !resultTag.isTagAdded;
          state.tagsToUpdate.pop(tag);
        } else {
          tag.isTagAdded = !tag.isTagAdded;
          state.tagsToUpdate.push(tag);
        }
      },
      companyTags() {
        return state.existingTags.company?.filter((data) => data.name.includes(state.searchTag));
      },
      projectTags() {
        return state.existingTags.project?.filter((data) => data.name.includes(state.searchTag));
      },
      async fetchAllTags() {
        const { data } = await Tags.getTags({
          limit: 99999,
          page: 1,
          project: card.project._id,
          fromCard: true,
        });
        if (data && data.length) {
          state.existingColors = _.map(data, 'color');
          state.existingTags = data.map((x) => {
            const item = card.tags.find((Item) => Item._id === x._id);
            if (item) {
              item.isTagAdded = true;
              return item;
            }
            x.isTagAdded = false;
            return x;
          }).filter((item) => item !== undefined);
          state.existingTags = _.groupBy(state.existingTags, 'scope');
        }
      },
      async addProjectTag() {
        const newTag = {
          name: state.searchTag,
          project: card.project._id,
          scope: 'project',
          color: '#43AAC7', // Default color for now. Will change it later.
        };
        if (!Validation.validateTag({ tagName: newTag.name, showError: true })) {
          const { valids, duplicates, limitReached } = await Tags.addTags(newTag);
          if (limitReached) {
            toast.warning(
              'Can not add more tags, Company tag limit of 1024 reached!',
              'is-danger',
            );
          }
          if (!_.isEmpty(valids)) {
            await methods.fetchAllTags();
            const tagToAdd = _.find(state.existingTags.project, {
              name: _.cloneDeep(newTag.name),
            });
            if (card.isPM()) {
              card.manager.tags.push(tagToAdd);
            } else {
              card.tags.push(tagToAdd);
            }
            toast.success(`Tag with name ${_.map(valids, 'name')} added successfully to this project!`);
          } else if (!_.isEmpty(duplicates)) {
            toast.warning(`The name or the color provided for "${duplicates}" is already present!`);
          }
        }
      },
    };
    // eslint-disable-next-line max-len
    const createProjectTag = computed(() => state.searchTag.length && methods.projectTags() && methods.projectTags().length < 1);
    onMounted(async () => {
      await methods.fetchAllTags();
    });
    return {
      ...toRefs(state), createProjectTag, tagTarget, card, ...methods,
    };
  },
});

export default MyComponent;
</script>

<style scoped>
 .card {
   width: 432px !important;
 }
 .card-content {
  height: 350px !important;
 }
</style>
