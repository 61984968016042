import _ from 'lodash';
import { BaseOrder } from './BaseOrder';
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async get(params) {
    try {
      const { data: prefab } = await req.get(`prefabs/get/${params.prefabId}`, {
        params: { projectId: params.projectId },
      });
      return new BaseOrder(prefab);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async isMultiPrefab(params) {
    try {
      const { data: prefab } = await req.get(`prefabs/isMultiPrefab/${params.prefabId}`, {
        params: { projectId: params.projectId },
      });
      return prefab;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async create(card) {
    try {
      const { data: prefab } = await req.post('prefabs/create', card, {
        params: { projectId: card.project._id },
      });
      return prefab;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async update(card) {
    try {
      const { data: prefab } = await req.post(`prefabs/update/${card._id}`, card, {
        params: { projectId: card.project._id },
      });
      return prefab;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async clone(card) {
    try {
      const { data: prefab } = await req.post(`prefabs/clone/${card._id}`, card, {
        params: { projectId: card.project._id },
      });
      return prefab;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async archive(card) {
    try {
      const { data: prefab } = await req.post(`prefabs/archive/${card._id}`, {}, {
        params: { projectId: card.project._id },
      });
      return prefab;
    } catch (e) {
      throw e;
    }
  },
  async moveToPO(card) {
    let newCard = new BaseOrder(Object.assign(card.toJSON(), { _id: '', _customStage: '' }));
    if (card.purpose === 'assembly') {
      newCard.simpleDates = _.cloneDeep(_.get(card, 'simpleDates', {}));
    }
    const dateTypes = ['coord', 'poDetailBy', 'poManufactureBy', 'partsManufactureBy', 'poQaBy', 'deliver'];
    const dates = {};
    for (const type of dateTypes) {
      if (_.get(newCard.simpleDates[type], 'value')) {
        dates[type] = newCard.simpleDates[type].value;
        if (card.purpose === 'assembly' && type === 'poManufactureBy' && !(_.has(card, 'templateOrder') && card.makeDateDirty)) {
          dates.poManufactureBy = _.get(newCard.simpleDates, 'partsManufactureBy.value');
        }
      }
    }
    const {
      level,
      zone,
      multiTrade,
      submittal,
      financialId,
      makeDateDirty,
    } = newCard;
    newCard.dates = [];
    newCard.simpleDates = {};
    try {
      let files = _.filter(newCard.files, file => file.sources.length === 1);
      if (!_.isEmpty(newCard.templateOrder) && !_.isEmpty(files)) {
        files = files.map((file) => {
          delete file._id;
          return file;
        });
      }
      newCard.updateAfterCreation = true;
      newCard = new BaseOrder(await newCard.save());
      if (!_.isEmpty(newCard.templateOrder)) newCard.newFile(files);
      // eslint-disable-next-line
      for (const dateType in dates) {
        newCard.addOrUpdateDate(dateType, dates[dateType], makeDateDirty);
      }
      Object.assign(newCard, {
        multiTrade, level, zone, financialId, submittal,
      });
      newCard.updateAfterCreation = true;
      return await newCard.save();
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
