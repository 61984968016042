<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <div class="list-view">
    <mf-table
      ref="PSTable"
      :tableProps="tableProps"
      :hideGutter="true"
      :loadData="loadData"
      :screenTable="true"
      tableName="PSTable"
      @opened-row="getDetailRowOrder($event)"
      @cell-clicked="onCellClicked"
    >
    <template v-slot:level-select="{ rowData }">
      <div v-if="rowData.__t !== 'Prefabs'">
        <span v-if="rowData.isEditing && rowData.projectLvlsAndZns.levels.length !==0">
          <mf-multi-select v-model="rowData.level"
                        :options="rowData.projectLvlsAndZns.levels"
                        :closeOnSelect="true"
                        :multiple="false"
                        :taggable="false"
                        :hideSelected="false"
                        class="pendo-level-select"
                        type="arraySort"
          >
          </mf-multi-select>
        </span>
        <span v-else-if="rowData.isEditing">
          <input type="text" placeholder="level" class="input" v-model.trim="rowData.level">
        </span>
        <span v-else>
              {{rowData.level}}
        </span>
      </div>
    </template>
    <template v-slot:zone-select="{ rowData }">
      <div v-if="rowData.__t !== 'Prefabs'">
          <span v-if="rowData.isEditing && rowData.projectLvlsAndZns.zones.length !==0">
            <mf-multi-select v-model="rowData.zone"
                          :options="rowData.projectLvlsAndZns.zones"
                          :closeOnSelect="true"
                          :multiple="false"
                          :taggable="false"
                          :hideSelected="false"
                          class="pendo-zone-select"
                          type="arraySort"
            >
            </mf-multi-select>
          </span>
          <span v-else-if="rowData.isEditing">
              <input type="text" placeholder="zone" class="input" v-model.trim="rowData.zone">
          </span>
          <span v-else>
                {{rowData.zone}}
          </span>
        </div>
    </template>
    <template v-slot:lastModified="psData">
      {{$filters.usaDate(psData.rowData.lastModified.at)}}
    </template>
    <template v-slot:company="psData">
      {{psData.rowData.owner.company.name}}
    </template>
    <template v-slot:project="psData">
      {{psData.rowData.project.name}}
    </template>
    <template v-slot:plannedHrs="{ rowData }">
      <span class="is-size-4 is-pulled-right">
        {{$filters.clockDisplay($_.get(rowData, 'manager.stats.plannedHrs', 0))}}
       </span>
    </template>
    <template v-slot:actualHrs="{ rowData }">
      <span class="is-size-4 is-pulled-right">
        {{$filters.clockDisplay($_.get(rowData, 'manager.stats.actualHrs', 0))}}
      </span>
    </template>
    <template #lmvIcon="{rowData, rowField, isHeader, rowIndex }">
        <IconField
          :rowData="rowData"
          :rowField="rowField"
          :isHeader="isHeader"
          :rowIndex="rowIndex"
          :projects="allProjects" />
      </template>  
  </mf-table>
    <activity-log
      v-if="activityModalOpen"
      :isActive="activityModalOpen"
      :id="activityDetails.id"
      :projectId="activityDetails.projectId"
      :orderName="activityDetails.orderName"
      v-bind="({leastStage, stage} = activityDetails)"
      @update:isActive="activityModalOpen=false"
    ></activity-log>
    <pause-restart-modal
      v-if="isPauseRestartActive"
      :isActive="isPauseRestartActive"
      :rowData="selectedOrder"
      @close="closePauseRestartModal">
    </pause-restart-modal>
    <transition name="slide">
      <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedOrder"
        @close="closeNotesSlider"
        @update:note="updateNotes($event)"
      >
      </notes-icon-slider>
    </transition>
    <transition name="slide">
      <item-document-slider
        v-if="isFileListVisible"
        :isActive="isFileListVisible"
        :rowData="selectedItem"
        :rowField="rowField"
        @close="closeDocumentSlider"
        :card="detailRowOrder"
        >
      </item-document-slider>
    </transition>
  </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, inject, onBeforeUnmount, onBeforeMount
} from 'vue';
import ProductionStatusCols from '@/components/table-cols/ProductionStatusCols';
import _ from 'lodash';
import { useStore } from 'vuex';
import SupplyChain from '@/models/SupplyChain';
import MfTable from '@/components/table-fields/MfTable.vue';
import ActivityLog from '@/components/ActivityLog.vue';
import { useToast } from 'vue-toastification';
import { useRouter, useRoute } from 'vue-router';
import ExcelExportMixin from '@/components/mixins/ExcelExportMixin';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import UtilityMixin from '@/components/mixins/UtilityMixin';
import PauseRestartmodal from '@/components/modals/PauseRestartmodal.vue';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import moment from 'moment';
import IconField from '../../components/fields/IconField.vue';
import Projects from '../../models/Projects'
import Validation from '@/utils/Validations';
import { DialogProgrammatic } from '@/components/Dialog';

export default defineComponent({
  name: 'Production-Status-order-view',
  components: {
    MfTable,
    ActivityLog,
    'pause-restart-modal': PauseRestartmodal,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
    IconField,
  },
  setup() {
    const PSTable = ref(null);
    const emitter = inject('emitter');
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const { excelExport } = ExcelExportMixin();
    const {
      printQr, openLmvModel, openWithUserPref, archiveCard, getRestartPermission
    } = CardEditMixin();
    const { getNegativeList, conflictHtml } = UtilityMixin();
    const state = reactive({
      tableProps: ProductionStatusCols,
      activityModalOpen: false,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      isPauseRestartActive: false,
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedOrder: {},
      selectedItem: {},
      isItem: false,
      rowField: {},
      detailRowOrder: {},
      allProjects: [],
    });
    const loadData = async (commonParams) => {
      let { project } = route.query;
      let stages = store.getters.selectedValuesForKey('stage', false);
      const {
        fromDashBoard, dbUpcoming, dbOrderType, hasPlannedHrs, hasActualHrs, hasProductivity, productionStatus,
        hasCompleteWorksteps, upcoming, shopMonth, orderStage, wsOrderId, productivityStatus,
      } = route.query;
      if (fromDashBoard) {
        if (productionStatus) {
          const getStages = {
            'Not Started': ['detailing', 'coordination'],
            Complete: ['delivery', 'complete', 'qa'],
            'In Progress': ['manufacturing'],
            All: stages,
          };
          stages = getStages[productionStatus];
        }
        if (!_.isEmpty(project)) {
          project = project.split(',');
        } else project = _.map(store.state.queryParams.projects, '_id');
      }
      const startOffSetDate = upcoming === '<1Week' ? 0 : 7;
      const endOffSetDate = upcoming === '<1Week' ? 6 : 41;
      const dashBoardParams = {
        projectId: project,
        fromDashBoard,
        dbUpcoming,
        dbOrderType,
        hasActualHrs,
        hasPlannedHrs,
        hasProductivity,
        hasCompleteWorksteps,
        stage: stages,
        shopMonth,
        orderStage,
        productivityStatus,
        wsOrderId,
        module: ['Prefabs', 'ProductionOrder'],
        showAllCompanyOrders: store.state.queryParams.showAllCompanyOrders,
        showPrivate: true,
        ...commonParams,
      };
      if (upcoming) {
        dashBoardParams.manufactureByStartDate = (dbUpcoming === 'Overdue') ? ''
          : moment().add(startOffSetDate, 'days').toDate();
        dashBoardParams.manufactureByEndDate = (dbUpcoming === 'Overdue') ? moment().toDate()
          : moment().add(endOffSetDate, 'days').toDate();
      }
      const filterOwnerIds = store.getters.selectedIdsForKey('owner', false);
      let owner;
      if (filterOwnerIds?.length) {
        owner = {
          user: {
            _id: filterOwnerIds,
          },
        };
      } else {
        owner = null;
      }
      let params = {
        projectId: store.getters.selectedIdsForKey('filteredProjects'),
        showAllCompanyOrders: store.state.queryParams.showAllCompanyOrders,
        module: ['Prefabs', 'ProductionOrder'],
        screen: 'productionStatus',
        location: store.getters.selectedIdsForKey('locations', false),
        company: store.getters.selectedIdsForKey('companies', false),
        tag: store.getters.selectedIdsForKey('tags'),
        stage: store.getters.selectedValuesForKey('stage', false),
        status: store.getters.selectedValuesForKey('status', false),
        submittals: store.getters.selectedSubmittals,
        ...store.getters.selectedDatesForKeys([
          'deliverStartDate',
          'deliverEndDate',
          'coordStartDate',
          'coordEndDate',
          'detailByStartDate',
          'detailByEndDate',
          'manufactureByStartDate',
          'manufactureByEndDate',
          'qaByStartDate',
          'qaByEndDate',
          'modifiedStartDate',
          'modifiedEndDate',
        ]),
        search: store.state.queryParams.searchText,
        showPrivate: true,
        levels: store.getters.selectedIdsForKey('level'),
        zones: store.getters.selectedIdsForKey('zone'),
        ...commonParams,
      };

      params = await getNegativeList(params, {
        projectId: [],
        owner: store.getters.selectedOwners?.user?._id,
      });
      const otherCompIds = _.map(store.state.queryParams._otherCompanyProjects, 'createdCompany._id');
      store.commit('setOtherCompanyIds', otherCompIds);
      const data = await SupplyChain.supplyChain(fromDashBoard === 'true' ? dashBoardParams : params);
      if (PSTable.value?.selectedBulkAction?.event === 'massUpdateDates') {
        const user = await store.state.userData;
        const massUpdateData = {
          total: data.total,
          data: _.filter(data.data, (order) => {
            const projectSetting = store.getters.getProjectSettings(
              order.project._id,
              order.owner.company._id,
            );
            if (['planning', 'delivery', 'mixed', 'in-transit', 'complete'].includes(order.stage)
              || (order.isManager === false && (projectSetting.isPrivate
              && order.owner.company._id !== user.company))
              || (order.isManager === true && order.owner.company._id !== user.company)) {
              return false;
            }
            return true;
          }),
        };
        return massUpdateData;
      }
      return data;
    };

    const refreshTable = async () => {
      await PSTable.value.refreshTable();
    };
    // eslint-disable-next-line consistent-return
    const isUniqueValueIsUpdated = (card) => {
    const beforeValue = _.get(card, '_beforeEdit.uniqueOrderId.value', '');
    const afterValue = _.get(card, 'uniqueOrderId.value', '');
    return (beforeValue !== afterValue);
  };
    const saveRow = async (rowData) => {
      let order = _.cloneDeep(rowData);
      try {
        store.commit('setLoading', true);
        if (isUniqueValueIsUpdated(order)) {
        _.set(order, 'uniqueOrderId.value', Validation.getValidUniqueorderId(_.get(order, 'uniqueOrderId.value', '')));
        const uniqueOrderId = _.get(order, 'uniqueOrderId.value', '');
        if (!_.isEmpty((uniqueOrderId)) && !Validation.validateUniqueOrderId(uniqueOrderId)) {
          throw({data: {message: `Invalid Order ID ${uniqueOrderId}`}});
        }
      }
        order = await order.save();
        _.set(rowData, 'isEditing', !rowData.isEditing);
        Object.assign(rowData, order);
      } catch (e) {
        const msg = _.get(e.data, 'message', '')
          || _.get(e.data, 'msg', '') || _.get(e, 'message', '')
          || 'Error saving: please contact ManufactOn support';
        const conflictMsg = conflictHtml(msg);
        // const conflictMsg = `<h3 class="is-size-3 has-text-weight-bold mb-3 has-text-danger is-italic" >Duplicate Order ID:</h3>
        //   <h3 class="is-size-3  mb-3 has-text-danger" > ${msg.OrderId}</h3>
        //   <h6 class="is-size-6 has-text-weight-bold has-text-black-bis" >(Order ID's must be unique and cannot be duplicated within a single project.) </h6>
        //   <div class="is-divider my-3"></div>
        //   <h3 class="is-size-3 mb-3 has-text-weight-bold has-text-black-bis is-italic" >Order Name:</h3>
        //   <h3 class="is-size-3 mb-3 has-text-black-bis" >${msg.OrderName} </h3>
        //    <h3 class="is-size-3 mb-3 has-text-weight-bold has-text-black-bis is-italic" >Project: </h3>
        //    <h3 class="is-size-3 has-text-black-bis" >${msg.Project} </h3>`;
        if (msg.OrderId || msg.OrderName || msg.Project) {
          const confirmParam = {
            title: 'CONFLICT: Order ID',
            okButton: 'Close',
            type: 'black-bis',
            conflictNote: conflictMsg,
            noteType: 'danger',
          };
          DialogProgrammatic.confirm(confirmParam);
        } else {
          return toast.error(msg);
        }
      } finally {
        store.commit('setLoading', false);
      }
    };
    function openRow(card) {
      let key = 'prefab';
      if (card.isPM()) {
        key = 'manager';
      } else if (card.isPO()) {
        key = 'order';
      }
      router.push({
        name: `${key}-edit`,
        params: {
          projectId: card.project._id,
          cardId: card._id,
          stage: card.stage,
        },
      });
    }
    const onCellClicked = async (event) => {
      const { type, data } = event;
      if (type === 'save') {
        await saveRow(data);
      } else if (type === 'openCard') {
        openWithUserPref(data);
      } else if (type === 'activity') {
        state.activityModalOpen = true;
        state.activityDetails.id = data._id;
        state.activityDetails.projectId = data.project._id;
        state.activityDetails.orderName = data.name;
        state.activityDetails.leastStage = data.leastStage;
        state.activityDetails.stage = data.stage;
      } else if (type === 'excelExport') {
        excelExport();
      } else if (type === 'lmv') {
        openLmvModel(data);
      } else if (type === 'printQr') {
        printQr(data);
      } else if (type === 'pauseRestart') {
        state.selectedOrder = data;
        const cannotRestart = await getRestartPermission(data)
        if (data.status === 'pause' && cannotRestart) {
          return toast.error('Only Project admins can restart the order');
        }
        state.isPauseRestartActive = true;
      } else if (event.type === 'remove') {
        await archiveCard(event.data, refreshTable);
      }
    };

    const updateNotes = async (event) => {
      if (event.type === 'save' && !event.isItem) {
        let order = _.cloneDeep(event.data);
        _.set(event.data, 'isEditing', !event.data.isEditing);
        order = await saveRow(order);
        Object.assign(event.data, order);
      }
    };

    const closePauseRestartModal = (() => {
      state.isPauseRestartActive = false;
      state.selectedOrder = {};
    });

    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      state.selectedOrder = {};
    });

    const closeDocumentSlider = () => {
      state.isFileListVisible = false;
    };

    const getDetailRowOrder = (e) => {
      state.detailRowOrder = e;
    };

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      state.selectedOrder = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      state.selectedItem = payload.data;
      state.rowField = payload.rowField;
      state.isFileListVisible = payload.isActive;
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });
    onBeforeMount(async () => {
      state.allProjects = await Projects.allProjects();
    });

    return {
      ...toRefs(state),
      PSTable,
      loadData,
      onCellClicked,
      closePauseRestartModal,
      updateNotes,
      closeNotesSlider,
      closeDocumentSlider,
      getDetailRowOrder,
    };
  },
});
</script>
