/* eslint-disable no-useless-catch */
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async getEmbedToken() {
    try {
      const { data } = await req.get('dashboard/getBIToken');
      return data;
    } catch (e) {
      throw e;
    }
  },
};
