// txt: "Hi i am #{name} from #{city}""
// meta: {name: 'John Doe', city: 'Delaware'}
// selectedLanguage : 'fr'
// res: "Salut je suis John Doe de Delaware"

const definedLocales = ['es', 'fr'];
let targetLocale = {};

// Import the language only once.
// Make sure to re-trigger this function when user changes
// his prefered language settings.
function initLocale() {
  const state = JSON.parse(window.localStorage.getItem('vuex'));
  const browserPref = window.navigator.userLanguage || window.navigator.language;
  let prefLang = state?.userData && state?.userData.locale;
  if (!prefLang) prefLang = browserPref.split('-')[0];
  const selectedLanguage = prefLang;
  // import only the language that has been set.
  if (definedLocales.includes(selectedLanguage)) targetLocale = require(`./locales/${selectedLanguage}.json`);
}
initLocale();

function addMetaVars(txt, meta) {
  for (const [k, v] of Object.entries(meta)) {
    txt = txt.replaceAll(`#{${k}}`, v);
  }
  return txt;
}

export default {
  t: (txt = '', meta = {}) => addMetaVars(targetLocale[txt] || txt, meta),
};
