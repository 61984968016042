<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        Finish Combining Orders
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="cancel()"></i>
    </header>
    <div class="header-note">
      <div class="is-size-5">
        <span class="has-text-white">Note :</span>
        Only Kits that have Bills of Material are displayed
      </div>
    </div>
    <section class="modal-card-body is-size-2 is-marginless">
      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <div class="field-label">
              <label class="label">Combine BOMs into Material Order</label>
            </div>
            <div class="field-body">
              <input v-model="name" class="input" type="text"
                     placeholder="Enter split order name">
            </div>
          </div>
        </div>
        </div>
        <search-bar
          :shouldEmit="true"
          @search="getSearchValue"
          placeholder="Search"
        >
        </search-bar>
        <div class="table-container">
          <mf-table
            :tableProps="tableProps"
            :loadData="loadData"
            :hideGutter="true"
            @row-select="selectRow"
            ref="kitsTable"
          >
          </mf-table>
        </div>
      </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined"
        @click="cancel()">Cancel</button>
      <button class="button has-background-black-bis"
        @click.once="move()"
        :disabled="!selectedKit._id">Move</button>
    </footer>
  </div>
</template>
<script>
import {
  defineComponent, defineAsyncComponent, reactive, toRefs, ref,
} from 'vue';
import {
  isEmpty, remove, some, has,
} from 'lodash';
import tableDefinition from '@/components/table-cols/moveKitCols';
import { useToast } from 'vue-toastification';
import Material from '@/models/MaterialManager';
import SearchBar from '@/components/SearchBar.vue';
import { useStore } from 'vuex';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default defineComponent({
  name: 'MoveKits',
  components: {
    MfTable,
    SearchBar,
  },
  props: {
    card: Object,
    isActive: Boolean,
  },
  setup(props, { emit }) {
    const tableProps = tableDefinition;
    const toast = useToast();
    const store = useStore();
    const kitsTable = ref(null);
    const state = reactive({
      searchKey: '',
      name: '<Kit ID> - Combined BOMs',
      selectedKit: {},
    });
    const loadData = async (commonParam) => {
      let kitidList;
      try {
        const params = {
          projectId: store.getters.selectedIdsForKey('filteredProjects'),
          ...commonParam,
          search: state.searchKey, // need backend changes for search to work here
        };
        kitidList = await Material.getKitIds(params);
      } catch (e) {
        console.log(e);
        const message = e.message || e.data.message || 'Please Contact Manufacton Support';
        toast.error(`Error: ${message}`);
      }
      kitidList.data.forEach((kit) => {
        kit.kitId = kit._id.customId;
      });
      remove(kitidList.data, (kit) => some(kit.items, (item) => has(item, 'purchase') && !item.purchase));
      return { data: kitidList.data, total: kitidList.total };
    };
    const cancel = () => {
      emit('close');
      emit('cancel');
    };
    const selectRow = (row) => {
      state.selectedKit = row;
      state.name = `${state.selectedKit._id.customId} - Combined BOMs`;
    };
    const getSearchValue = async (searchText) => {
      state.searchKey = searchText;
      await kitsTable.value.refreshTable();
    };
    const move = async () => {
      const kit = state.selectedKit;
      if (isEmpty(kit)) return;
      const params = {
        kitId: kit.kitId,
        name: `${kit.name} - Combined`,
        projectId: kit.project._id,
        selectedKitId: kit.kitId,
      };
      try {
        await Material.kitBOMToSourcing(params);
        emit('close');
        emit('refresh-table');
      } catch (e) {
        if (e.data) {
          toast.error(e.data);
        }
      }
    };

    return {
      ...toRefs(state),
      tableProps,
      loadData,
      isEmpty,
      cancel,
      move,
      selectRow,
      getSearchValue,
      kitsTable,
    };
  },
});
</script>
<style lang="scss" scoped>
.o-table__th, .o-table th:first-child {
  width: 20px;
}
::v-deep(.o-table tbody) {
  min-height: 378px !important;
  max-height: 378px !important;
}
</style>
