<template>
  <div class="field">
    <mf-multi-select
      v-if="showDropDown"
      v-model="selectedUnit"
      :options="mUnits"
      :titleLabel="titleLabel"
      :labelPosition="labelPosition"
      labelClass="has-text-black-bis is-italic is-size-3"
      label="name"
      track-by="name"
      :allow-empty="false"
      :disabled="isMeasUnitsDisabled"
    >
    </mf-multi-select>
    <span
      v-else
    >
      {{ $_.get(selectedUnit, 'name', '') }}
    </span>
  </div>
</template>

<script>
import {
  onMounted,
  reactive,
  toRefs,
  computed,
} from 'vue';
import MfMultiSelect from '@/components/abstract/MfMultiSelect';
import { useStore } from 'vuex';
import {
  cloneDeep, isNaN, isEmpty, isFunction, get,
} from 'lodash';

export default {
  props: {
    titleLabel: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: 'top',
    },
    value: String,
    rowData: Object,
    isEditing: Boolean,
    rowField: Object,
  },
  components: {
    'mf-multi-select': MfMultiSelect,
  },
  setup(props, { emit }) {
    const { rowData: item, rowField } = props;
    const store = useStore();
    const state = reactive({
      mUnits: [],
    });
    onMounted(async () => {
      const mUnits = await store.state.companyData;
      ({ measureUnits: state.mUnits } = mUnits);
    });

    const selectedUnit = computed({
      get: () => (!isEmpty(props.value) ? { name: props.value.toUpperCase() } : {}),
      set: (option) => {
        let name = '';
        if (option) name = option.name;
        emit('update:value', name);
        emit('changeStage', { option });
      },
    });

    const showDropDown = computed(() => {
      if (get(rowField, 'inputProps.isEditing', false) && isFunction(rowField.inputProps.isEditing)) return rowField.inputProps.isEditing(item);
      return props.isEditing || item.isEditing;
    });
    const isMeasUnitsDisabled = computed(() => {
      if (props.rowData.isItemCompleted) return true
      if (rowField.disableMeasUnitsOptions
        && isFunction(rowField.disableMeasUnitsOptions)) {
        return rowField.disableMeasUnitsOptions(item);
      }
      if ((item.fromMasterCatalog || !isEmpty(item.catId))
        && ['ordering', 'sourcing', 'preparation'].includes(item.stage) && item.measureUnits) return true;
      if (item.measure === null
          || item.measure <= 0
          || isNaN(item.measure)) return true;
      return false;
    });

    return {
      ...toRefs(state),
      selectedUnit,
      showDropDown,
      isMeasUnitsDisabled,
    };
  },
};
</script>
