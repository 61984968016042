<template>
  <div class="is-flex is-justify-content-center" :key="getStatuslabel">
    <button
    :class="['button mr-0 has-background-white run-timer', 'has-border-'+ getStatusType.color]" v-if="rowData.form?._id || rowData.form?.id"
      v-tooltip="getStatuslabel"
      @mouseleave="hovered = false">
      <i :class="[getStatusType.icon,  'has-text-'+ getStatusType.color]"
        @click="triggerModal"></i>
    </button>
    <VDropdown  placement="left" :shown="isOpen" :autoHide="false" v-else>
      <!-- This will be the popover reference (for the events and position) -->
      <button v-if="showFormAddButton"
      class="is-clickable  button run-timer mr-0 has-border-black-bis" v-tooltip="'Attach QC Form'" >
          <i class="icon-addnew" @click="loadData()"></i>
        </button>

      <!-- This will be the content of the popover -->
      <template #popper>
        <div class="card has-text-black-bis is-visible">
          <header class="card-header has-background-grey-lighter">
            <h4
              class="card-header-title is-size-3 has-text-black-bis
                has-text-weight-bold is-paddingless"
            >
              Add QC Form To Work Step
            </h4>
          </header>
          <section class="card-content py-4 is-marginless has-background-white">
            <div class="mb-2">
              <em>Work Step</em> {{rowData.name}}
            </div>
            <div class="qc-form-select">
              <h4>
                Choose Manufacton QC Form
              </h4>
              <mf-multi-select
                :options="formList"
                label="name"
                track-by='_id'
                v-model="selectedData.form"
                :allow-empty="false"
              ></mf-multi-select>
            </div>
            <div class="qc-form-select">
              <h4>
                Set Assignee
              </h4>
              <mf-multi-select
                v-model="selectedData.owner"
                :options="listUsers"
                :closeOnSelect="true"
                label="name"
                track-by="_id"
                :multiple="false"
                :hideSelected="false"
                :allow-empty="false"
                group-values="val" group-label="key"
                class="pendo-run-owner-select">
              </mf-multi-select>
            </div>
          </section>
          <footer
            class="card-footer is-justify-content-flex-end has-background-grey-lighter"
          >
            <div class="buttons">
              <button class="button is-outlined"  @click="isOpen = !isOpen">
                Cancel
              </button>
              <button @click="linkForm()"
                class="button has-background-black-bis has-text-white"
                :disabled="isAddFormDisabled">
                Add QC Form
              </button>
            </div>
          </footer>
        </div>
        <o-loading
          :full-page="false"
          :active="isLoading"
          :can-cancel="false"
        ></o-loading>
      </template>
    </VDropdown>
    <o-loading
        :full-page="true"
        :active="isLoading"
        :can-cancel="true"
      ></o-loading>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import QaQcmodal from '@/components/modals/QaQcModal.vue';
import Form from '@/models/Form';
import Users from '@/models/Users';
import { isEmpty, set } from 'lodash';
import MfMultiSelect from '../abstract/MfMultiSelect.vue';

export default {
  components: { MfMultiSelect },
  name: 'QaQc-field',
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      isOpen: false,
      formList: [],
      selectedData: {
        form: {},
        owner: {},
      },
      listUsers: [],
      isLoading: false,
    });
    const triggerModal = () => {
      ModalProgrammatic.open({
        component: QaQcmodal,
        props: {
          rowData: props.rowData,
          card: props.card,
        },
        events: {
          close: (opt = {}) => {
            if (opt.isOrderPaused || opt.formRemoved || opt.refreshCard) {
              emit('save');
            }
            if (opt.statusChanged) {
              props.rowData.changeQaQcFormStatus(opt.status);
            }
          },
        },
        canCancel: false,
        rootClass: 'modal-sm qa-modal',
      });
    };
    const loadData = async () => {
      state.isOpen = !state.isOpen;
      state.isLoading = true;
      const comps = props.card.getInvolvedCompanies();
      if (!state.formList.length) {
        const formList = await Form.get({
          formType: 'template',
        });
        state.listUsers = await Users.getGroupedProjectLinkedUsers(comps, props.card.project._id);
        state.formList = formList.data;
      }
      state.isLoading = false;
    };
    const linkForm = async () => {
      if (isEmpty(state.selectedData?.form)) {
        return false;
      }
      state.isLoading = true;
      const reqData = {
        formId: state.selectedData?.form?._id,
        owner: state.selectedData?.owner?._id,
        projectId: props.card.project?._id,
        runId: props.rowData._id,
        orderId: props.card._id,
      };
      const newForm = await Form.attachForm(reqData);
      props.rowData.addQaQcForm(newForm);
      state.isOpen = false;
      state.isLoading = false;
      emit('addedForm', { form: newForm });
    };
    const getStatusType = computed(() => {
      const status = {
        notStarted: { icon: 'icon-qcnotstarted', color: 'grey-dark' },
        inspect: { icon: 'icon-qcinspect', color: 'info' },
        inProgress: { icon: 'icon-qcinprogress', color: 'link'},
        pass: { icon: 'icon-qcsignature', color: 'success' },
        fail: { icon: 'icon-qcfail', color: 'danger' },
        complete: { icon: 'icon-qcpass', color: 'success' },
      };
      const currentStatus = status[props.rowData.formStatus];
      if (!isEmpty(currentStatus)) {
        return currentStatus;
      }
      return '';
    });

    const getStatuslabel = computed(() => {
      const status = {
        notStarted: 'QC Not Started',
        inspect: 'QC Inspection',
        inProgress: 'QC In Progress',
        pass: 'QC Signature Required',
        fail: 'QC Fail',
        complete: 'QC Complete',
      };
      return status[props.rowData.formStatus] || '';
    });

    const showFormAddButton = computed(() => {
      if ((props.rowData.completed && !props.rowData.form?._id)) {
        return false;
      }
      return true;
    });
    const isAddFormDisabled = computed(() => isEmpty(state.selectedData.form) || isEmpty(state.selectedData.owner));
    return {
      triggerModal,
      ...toRefs(state),
      loadData,
      linkForm,
      getStatusType,
      getStatuslabel,
      showFormAddButton,
      isAddFormDisabled,
    };
  },
};
</script>

<style scoped>
.is-flex .button.mr-0 {
  margin-right: 0 !important;
}
</style>
