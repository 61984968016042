<template>
<div v-show="showCheck">
  <div class="columns form-header">
    <div class="has-text-weight-bold has-text-weight-medium line-height column is-1">
      {{(checkIndex+1).toString().padStart(3, '0')}}
    </div>
    <div class="line-height column">
      {{qcCheck.desc}}</div>
  </div>
  <div class="columns" v-for="(task, idx) in getQcCheck.tasks.slice().reverse()" :key="idx"
    v-show="showTasks(idx)">
    <div class="has-text-weight-bold has-text-weight-medium line-height column is-1">
    </div>
      <div class="line-height column pl-0" v-if="!(hideTask && !task.isRequired)">
        <div class="columns mb-0">
          <div class="column pr-0" :class="toggledMap[task._id] ? 'pb-0' : ''">
            <div class="is-flex is-justify-content-space-between"
              :class="toggledMap[task._id] ? 'pb-3' : ''">
              <div class="is-clickable is-flex is-align-items-center"
                @click="toggleTask(task)">
                <i :class="toggledMap[task._id] ? 'icon-disclosearrow' : 'icon-disclosearrowright'"
                class="mr-2"></i>
                <span class="is-flex is-align-items-center"
                  :class="getStatusColorIcon(task).color">
                  <i class="mr-2" :class="getStatusColorIcon(task).icon"></i>
                  <span class="has-text-weight-bold line-height">
                    {{(checkIndex+1).toString().padStart(3,'0')}}.{{(getQcCheck.tasks?.length - idx)
                      .toString().padStart(2, '0')}}:
                      <span v-if="!task.isRequired && !preview">Does Not Apply</span>
                      <span v-else>{{ taskStatus[task.status] }}</span>
                  </span>
                </span>
              </div>
              <div v-if="task.showAction">
                <div class="buttons">
                  <button class="button p-0"
                    :disabled="isSaveDisabled(task)"
                    @click="saveTask(task,
                      (getQcCheck.tasks?.length - idx).toString().padStart(2, '0'))">
                    <i class="icon-checkmark has-text-success"></i>
                  </button>
                  <button class="button p-0" @click="closeTask(task, true)">
                    <i class="icon-close has-text-danger"></i>
                  </button>
                </div>
              </div>
              <div v-else-if="!$_.isEmpty(task.lastModified)" class="pr-1">
                <em>
                  {{task.lastModified?.by?.user?.name}}
                  ({{$filters.fullDateTime(task.lastModified.at)}})
                </em>
              </div>
            </div>
            <div v-if="toggledMap[task._id]" class="column"
              :class="toggledMap[task._id] ? 'pt-0 is-border-top' : ''">
              <div class="columns">
                <div class="column is-flex is-align-items-center"
                :class="task.isInspection ? 'is-3' : ''">
                  <o-checkbox class="mr-1"
                    v-model="task.isRequired"
                    @update:modelValue="setAction(task, 'doesNotApply')"
                    :disabled="!['notStarted', 'reEnabled', 'inspect'].includes(task.status) ||
                      (!task.isEditable && task.status !== 'reEnabled') || preview || fromTask"
                    v-if="isCheckTypeVisible(task)">
                  </o-checkbox>
                  <div class="is-divider-vertical mr-3"  v-if="isCheckTypeVisible(task)"></div>
                  <span class="is-flex is-align-items-center mr-3"
                    :class="(task.isRequired) ? 'is-clickable' : 'has-text-grey-light'"
                    :disabled="!task.isEditable"
                    @click="initializeTask(task, 'pass')"
                    v-if="isCheckTypeVisible(task)">
                    <i class="icon-qcpass mr-2"
                      :class="task.status === 'pass' ? 'has-text-success':''">
                    </i>
                    <span :class="task.status === 'pass' ? 'has-text-weight-bold':''"
                      class="input-qty">Pass</span>
                  </span>
                  <span class="is-flex is-align-items-center mr-3"
                    v-if="isCheckTypeVisible(task)"
                    @click="initializeTask(task, 'fail')"
                    :class="(task.isRequired) ? 'is-clickable' : 'has-text-grey-light'">
                    <i class="icon-qcfail mr-2"
                      :class="task.status === 'fail' ? 'has-text-danger':''"></i>
                    <span :class="task.status === 'fail' ? 'has-text-weight-bold':''"
                      class="input-qty">Fail</span>
                  </span>
                  <div class="is-divider-vertical mr-3" v-if="isCheckTypeVisible(task)"></div>
                  <div v-if="(task.isRequired && task.isEditable) ||
                    (!task.isRequired && task.allowEdit) ||
                    (task.isEditable && task.status === 'inspect') ||
                    task.status === 'fail'">
                    <o-checkbox class="mr-3" v-model="task.isInspection"
                      @update:modelValue="setAction(task)"
                      :disabled="task.status !== 'fail' || !task.isRequired || task.isEditable
                        || (task.status === 'fail' && !task.showAction && task.isInspection)
                        || fromTask">
                      Ready For Inspection
                    </o-checkbox>
                  </div>
                  <o-checkbox class="mr-3" v-model="task.isReEnabled" v-else
                    @update:modelValue="setAction(task, 'reOpen')"
                    :disabled="((!task.isRequired || task.status === 'pass')
                      && task.isReEnabled && !task.showAction) || isSubmitted
                      || preview || fromTask || formData.submitForm || isWorkStepCompleted">
                    Re-enable Check
                  </o-checkbox>
                </div>
                <div class="column is-flex is-align-items-center"
                  v-if="task.isInspection">
                  <div class="is-divider-vertical mr-3"></div>
                  <i class="mr-2">Reassign task to: </i>
                  <UserSelect :isEditing="true" v-if="task.showAction"
                    class="is-flex-grow-1"
                    :options="assigneeList"
                    :value="task.reAssigneeTo"
                    @update:value="(val) => task.reAssigneeTo = val"/>
                    <div v-else class="text-height">
                      {{task.reAssigneeTo?.name}}
                    </div>
                </div>
                <div class="column is-narrow is-flex is-align-items-center pr-0">
                  <div class="is-divider-vertical mr-3"></div>
                  <div class="form-docs-count is-clickable is-flex is-align-items-center"
                    :class="!task.status ? 'disabledPer' : ''"
                    @click="setDocsModalActive(task)">
                    <i class="icon-attacheddocumentsOL"></i>
                    <span class="is-size-5">
                      {{task.docs?.filter((doc) => !doc.archived.value).length}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="columns is-multiline qa-forms-content pb-0"
              :class="getStatusColorIcon(task).backGround">
                <div class="column is-12" v-if="task.isRequired || task.isEditable || preview"
                  :class="task.status !== 'fail' &&
                    qcCheck.componentType !== 'qc' ? 'p-0' : ''">
                  <div class="columns" v-if="task.status === 'fail'">
                    <div class="column">
                      <mf-multi-select
                        v-if="!isDisabled(task)"
                        v-model="task.errorType"
                        :options="getIssueTypeArr"
                        titleLabel="Issue Type"
                        :allow-empty="false"
                        :disabled="isDisabled(task)"
                        placeholder="Make Selection (Required)"
                      ></mf-multi-select>
                      <div v-else>
                        <div class="is-italic has-text-weight-bold">
                          Issue Type
                        </div>
                        <span class="line-height">{{task.errorType}}</span>
                      </div>
                    </div>
                    <div class="column">
                      <span class="is-italic has-text-weight-bold">
                        Create/Assign Task To
                      </span>
                      <UserSelect :isEditing="true" v-if="!isDisabled(task)"
                        :options="assigneeList"
                        :value="task.owner"
                        @update:value="(val) => task.owner = val"/>
                        <div v-else class="text-height">
                          {{task.owner?.name}}
                        </div>
                    </div>
                  </div>
                  <div v-if="task.status === 'fail'">
                    <div class="is-divider"></div>
                  </div>
                  <div class="column px-0" v-if="qcCheck.componentType === 'qc'">
                    <div class="columns">
                      <div class="column">
                        <div class="field">
                          <label class="is-italic has-text-weight-bold">
                            Criteria
                          </label>
                          <div>{{qcCheck.criteria}}</div>
                        </div>
                      </div>
                      <div class="column">
                        <div class="field">
                          <label class="is-italic has-text-weight-bold">
                            Target Value/Range
                          </label>
                          <div>{{qcCheck.targetVal}}</div>
                        </div>
                      </div>
                      <div class="column">
                        <div class="field">
                          <label class="is-italic has-text-weight-bold">
                            Measured Value
                          </label>
                          <div v-if="!task.isRequired || preview || !task.isEditable || fromTask">
                            {{task.meauredValue}}
                          </div>
                          <input type="text" class="input p-1" placeholder="Measured Value (Required)"
                            v-model="task.meauredValue" v-else>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="preview && !fromTask">
                  <textarea class="textarea has-fixed-size disable-note"
                    v-model="task.note"
                    rows="3"
                    :disabled="true"
                    placeholder="Notes"></textarea>
                </div>
                <div class="column is-12" v-else
                  :class="(!task.isRequired && !task.isEditable) ||
                    ($_.isEmpty(task.note) && !task.isEditable)? 'p-0' : ''">
                  <div v-if="(!task.isEditable && task.note) || preview">
                    <span v-if="task.status === 'fail'">CHECK FAILED: </span>{{ task.note }}
                  </div>
                  <textarea class="textarea has-fixed-size" v-else-if="task.isEditable"
                    v-model="task.note"
                    placeholder="Notes"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="is-divider"></div>
      </div>
  </div>
  <o-modal v-if="isDocModalActive" :active="isDocModalActive" :canCancel="false" class="modal-xs">
    <div class="modal-card">
      <header class="modal-card-head has-text-black-bis has-text-weight-bold">
        Documents ({{selectedTaskDocs.docs?.filter((doc) => !doc.archived.value).length}})
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column py-0">
            <search-bar
              :shouldEmit="true"
              @search="getSearchValue($event)"
              placeholder="Search"
            >
            </search-bar>
          </div>
          <div class="is-divider-vertical" v-if="(selectedTaskDocs.isEditable ||
            canAddRemoveDocs) && !isSubmitted && !fromTask"></div>
          <div class="column is-narrow py-0" v-if="(selectedTaskDocs.isEditable ||
            canAddRemoveDocs) && !isSubmitted && !fromTask">
            <mf-add-file
              :order="order"
              @docs-added="(docs) => docsAdded(selectedTaskDocs, docs)"
              :showForm="false"
              :defaultPrivate="isDefPrivate"
              :hidePrivate="true"
              :projectId="projectId"
            >
            </mf-add-file>
          </div>
        </div>
        <div class="is-divider m-0 mb-2"></div>
        <file-list
          :order="formData"
          :files="loadFiles()"
          :projectId="projectId"
          :closable="(selectedTaskDocs.isEditable || canAddRemoveDocs) && !isSubmitted"
          @close="(fileToRemove) => deleteFile(selectedTaskDocs, fileToRemove)"
          :key="refreshFiles"
          :isQaQc="preview"
          :fromQcModal="true"
          @files-updated="(val) => update(selectedTaskDocs, val)"
        ></file-list>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <div class="buttons">
          <button class="button is-outlined" @click="closeDocsModal">
            Cancel
          </button>
          <button class="button has-background-black-bis"
            @click="closeDocsModal">Done</button>
        </div>
      </footer>
    </div>
  </o-modal>
</div>
</template>

<script>
import {
  computed, reactive, toRefs,
} from 'vue';
import UserSelect from '@/components/fields/UserSelect.vue';
import {
  filter, set, findIndex, find, cloneDeep, isEmpty, map,
} from 'lodash';
import MfAddFile from '@/components/MfAddFile.vue';
import FileList from '@/components/card-edit/FileList.vue';
import SearchBar from '@/components/SearchBar.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  props: {
    qcCheck: {
      type: Object,
      default: () => {},
    },
    checkIndex: {
      type: Number,
      default: () => 1,
    },
    assigneeList: {
      type: Object,
      default: () => {},
    },
    projectId: String,
    preview: {
      type: Boolean,
      default: false,
    },
    hideTask: {
      type: Boolean,
      default: false,
    },
    accordian: {
      type: Boolean,
      default: false,
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
    runAssignee: {
      type: Object,
      default: () => {},
    },
    fromTask: {
      type: Boolean,
      default: findIndex,
    },
    selectedFilter: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Boolean,
      default: true,
    },
    isWorkStepCompleted: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UserSelect,
    MfAddFile,
    FileList,
    SearchBar,
  },
  setup(props, { emit }) {
    const state = reactive({
      toggledMap: {},
      disabledMap: {
        isRequired: {},
        isFailed: {},
      },
      showAction: false,
      taskStatus: {
        notStarted: 'Not Started',
        inspect: 'Inspect',
        fail: 'Fail',
        pass: 'Pass',
        reEnabled: 'Re-enabled',
      },
      taskType: '',
      isDocModalActive: false,
      isOpen: false,
      selectedTaskDocs: {},
      searchText: '',
    });
    const store = useStore();
    const route = useRoute();

    const disable = (task) => {
      const { _id } = task;
      if (state.disabledMap.isRequired[_id] !== false) {
        state.disabledMap.isRequired[_id] = false;
      } else {
        state.disabledMap.isRequired[_id] = !state.disabledMap.isRequired[_id];
      }
    };
    const initializeTask = (task, status) => {
      if (!task.isEditable || !task.isRequired || props.preview || props.fromTask) { return; }
      const initialTaskCopy = cloneDeep(task);
      if (!task._beforeEdit) set(task, '_beforeEdit', initialTaskCopy);
      set(task, 'status', status);
      if (status === 'fail') {
        set(task, 'createCheckList', true);
        set(task, 'owner', props.runAssignee);
      } else {
        set(task, 'createCheckList', false);
        set(task, 'updateCheckList', true);
      }
      set(task, 'showAction', true);
    };
    const closeTask = (task, reset = false) => {
      state.toggledMap[task._id] = false;
      set(task, 'showAction', false);
      if (reset) {
        const taskIdx = findIndex(props.qcCheck.tasks, { _id: task._id });
        set(props.qcCheck, `tasks[${taskIdx}]`, task._beforeEdit);
      }
    };
    const saveTask = (task, idx) => {
      if ((task.isRequired && !task.isInspection && !task.isReEnabled)
        || (!task.isRequired && task.isEditable)) task.isModified = true;
      if (!['notStarted', 'inspect', 'reEnabled'].includes(task.status) ||
        (['notStarted', 'inspect', 'reEnabled'].includes(task.status) && !task.isRequired)) {
        task.isEditable = false;
      }
      emit('add-task', {
        task,
        taskIdx: idx,
        checkIdx: (props.checkIndex + 1).toString().padStart(3, '0'),
      });
      closeTask(task);
    };
    const getStatusColorIcon = (task) => {
      const status = {
        pass: { color: 'has-text-success', icon: 'icon-qcpass', backGround: 'is-success' },
        fail: { color: 'has-text-danger', icon: 'icon-qcfail', backGround: 'is-danger' },
        notStarted: { color: '', icon: 'icon-qcpass', backGround: 'is-grey' },
        inspect: { color: 'has-text-info', icon: 'icon-qcinspect', backGround: 'is-grey' },
        reEnabled: { color: '', icon: 'icon-qcpass', backGround: 'is-grey' },
      };
      if (!task.isRequired && !props.preview) {
        return { color: 'has-text-grey-light', backGround: 'is-grey', icon: 'icon-reject' };
      }
      return status[task.status] || { color: '', icon: '', backGround: '' };
    };

    const docsAdded = (task, docs) => {
      const taskIdx = findIndex(props.qcCheck.tasks, (t) => {
        if (task._id) return task._id === t._id;
        return task.uuid === t.uuid;
      });
      set(props.qcCheck.tasks[taskIdx], 'showAction', true);
      emit('docs-added', props.checkIndex, taskIdx, docs);
    };

    const isDisabled = (task) => task.status !== 'fail' || !task.isEditable || !task.isRequired;

    const getQcCheck = computed(() => props.qcCheck);

    const deleteFile = (task, fileToArchive) => {
      const taskIdx = findIndex(props.qcCheck.tasks, (t) => {
        if (task._id) return task._id === t._id;
        return task.uuid === t.uuid;
      });
      const fileIdx = findIndex(props.qcCheck.tasks[taskIdx].docs, (d) => {
        if (fileToArchive._id) return fileToArchive._id === d._id;
        return fileToArchive.uuid === d.uuid;
      });

      set(props.qcCheck, `tasks[${taskIdx}].docs[${fileIdx}].archived.value`, true);
      emit('delete-doc', { check: props.qcCheck });
      set(props.qcCheck.tasks[taskIdx], 'showAction', true);
    };

    const setAction = (task, type = '') => {
      const taskData = find(props.qcCheck.tasks, { _id: task._id });
      if (type === 'doesNotApply'
        && ['notStarted', 'reEnabled', 'inspect'].includes(task.status)
        && !props.preview) {
        taskData.showAction = true;
        taskData.allowEdit = true;
      } else if (!props.preview && task.status === 'fail') {
        if (task.isInspection) set(task, 'reAssigneeTo', props.runAssignee);
        taskData.showAction = true;
      } else if (type === 'reOpen') taskData.showAction = true;
    };

    const isSaveDisabled = (task) => {
      if (task.isEditable && task.status === 'fail') {
        if (isEmpty(task.errorType)) return true;
        if (isEmpty(task.owner?.name)) return true;
        if (props.qcCheck.componentType === 'qc' && isEmpty(task.meauredValue)) return true;
      } else if (task.isEditable && task.status === 'pass') {
        if (props.qcCheck.componentType === 'qc' && isEmpty(task.meauredValue)) return true;
      }
      return false;
    };

    const showTasks = (idx) => {
      if (props.accordian) return idx === 0;
      return true;
    };

    const isCheckTypeVisible = (task) => {
      if (!((task.isRequired && task.isEditable) || (!task.isRequired && task.allowEdit))
        && !props.preview) {
        return false;
      }
      return true;
    };

    const toggleTask = (task) => {
      const initialTask = cloneDeep(task);
      if (!task.befreEditAdded) set(task, '_beforeEdit', initialTask);
      set(task, 'befreEditAdded', true);
      state.toggledMap[task._id] = !state.toggledMap[task._id];
    };

    const setDocsModalActive = (task) => {
      state.isDocModalActive = !state.isDocModalActive;
      state.selectedTaskDocs = task;
    };

    const closeDocsModal = () => {
      state.isDocModalActive = false;
      state.selectedTaskDocs = {};
    };

    const canAddRemoveDocs = computed(() => {
      if (!isEmpty(state.selectedTaskDocs)) {
        const taskIdx = findIndex(props.qcCheck.tasks, (t) => {
          if (state.selectedTaskDocs._id) return state.selectedTaskDocs._id === t._id;
          return state.selectedTaskDocs.uuid === t.uuid;
        });
        if ((taskIdx === (props.qcCheck.tasks.length - 1)) && !props.preview && !props.fromTask) {
          return true;
        }
      }
      return false;
    });

    const getIssueTypeArr = computed(() => {
      const { companyData } = store.state;
      if (!isEmpty(companyData?.qaqcIssueTypes)) return map(companyData.qaqcIssueTypes, 'name');
      return [];
    });
    const showCheck = computed(() => {
      if (props.selectedFilter.key !== 'all'
        && props.selectedFilter.key !== props.qcCheck.status) {
        return false;
      }
      return true;
    });

    const getSearchValue = (searchText) => {
      state.searchText = searchText;
    };

    const loadFiles = () => {
      if (state.searchText.length) {
        return filter(state.selectedTaskDocs.docs, (doc) => doc.name.toLowerCase().includes(state.searchText.toLowerCase()) && !doc.archived.value);
      }
      return state.selectedTaskDocs.docs.filter((doc) => !doc.archived.value);
    };

    const update = (task, val) => {
      if (val) {
        const taskIdx = findIndex(props.qcCheck.tasks, (t) => {
          if (task._id) return task._id === t._id;
          return task.uuid === t.uuid;
        });
        if (props.qcCheck.tasks[taskIdx].isEditable) {
          set(props.qcCheck.tasks[taskIdx], 'showAction', true);
        }
      }
    };

    return {
      ...toRefs(state),
      disable,
      initializeTask,
      saveTask,
      getStatusColorIcon,
      isDisabled,
      closeTask,
      docsAdded,
      getQcCheck,
      deleteFile,
      setAction,
      isSaveDisabled,
      showTasks,
      isCheckTypeVisible,
      toggleTask,
      setDocsModalActive,
      closeDocsModal,
      canAddRemoveDocs,
      getIssueTypeArr,
      showCheck,
      getSearchValue,
      loadFiles,
      update,
    };
  },
};
</script>

<style scoped>
::v-deep(.o-modal.modal-xs .modal-card) {
  width: 432px !important
}
::v-deep(.modal-card .modal-card-body .is-divider-vertical) {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep(.o-chk--disabled) {
  pointer-events: none;
}
.text-height {
  height: 24px;
}
.disable-note {
  background: #f2f2f2 !important;
  color: grey;
}
.disable-note:hover {
  border-color: #d9d9d9 !important;
}
.disable-note::placeholder, ::placeholder {
  color: #808080 !important;
}
::placeholder {
  font-style: italic;
}
</style>
