/* eslint no-restricted-syntax: "off" */

import moment from 'moment';
import _ from 'lodash';

const catIdRegex = '^[-._]+|[^A-Za-z0-9-._]|[-._]+$';

export default {
  validateEmail(email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  },
  validateZip(zipCode, showError, Dialog) {
    // US team requirement https://github.com/manufacton/Rio/issues/10237
    zipCode = !_.isEmpty(zipCode) ? zipCode : '';
    if (!_.inRange(zipCode.length, 1, 25)) {
      if (showError) {
        Dialog.alert({
          title: 'Error',
          message: 'Valid range for Zip length is 1 - 24!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
        });
      }
      return false;
    }
    return true;
  },
  validateOrderId(newId) {
    const regex = '^[A-Za-z0-9][A-Za-z0-9-._]*?[A-Za-z0-9]$';
    if (newId.length < 4 || newId.length > 24 || !RegExp(regex).test(newId)) {
      return false;
    }
    return true;
  },
  validateSupplier(supplier, showError, Dialog) {
    const regex = '^([A-Za-z0-9 ]*)+$';
    const msg = 'Supplier name should have atleast 3 characters and cannot start with special characters';
    if (supplier && !(supplier.length >= 3 && RegExp(regex).test(supplier))) {
      if (showError) {
        Dialog.alert({
          title: 'Error',
          message: msg,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
        });
      }
      return false;
    }
    return true;
  },
  isDateValid(date) {
    const dateFormats = ['YYYY-MM-DD', 'YY-MM-DD', 'YYYY/MM/DD', 'YY/MM/DD', 'MM-DD-YYYY',
      'MM/DD/YYYY', 'MM-DD-YY', 'MM/DD/YY', 'M-D-YY', 'M/D/YY', 'M-DD-YY', 'M/DD/YY',
      'M-DD-YYYY', 'M-D-YYYY', 'M/D/YYYY', 'M/DD/YYYY', 'MM/DD/YYYY'];
    return _.some(dateFormats, type => moment(date, type, true).isValid());
  },
  validateCostCode(costCode) {
    costCode = costCode.trim();
    costCode = costCode.replace(/ /g, '.');
    const regex = '^[A-Za-z0-9][A-Za-z0-9-._]*?[A-Za-z0-9]$';
    if (_.isEmpty(costCode) || !_.inRange(costCode.length, 4, 25)
      || !RegExp(regex).test(costCode)) {
      return false;
    }
    return true;
  },
  getValidCatId(catId) {
    catId = catId.replace(/ /g, '.').toUpperCase();
    if (!_.isEmpty(catId) && RegExp(catIdRegex).test(catId)) {
      catId = catId.replace(/^[-._]+|[^A-Za-z0-9-._]|[-._]+$/g, '');
      // catId = catId.replace(/^\W+|[^A-Za-z0-9-._]|\W+$/g, '');
    }
    return catId;
  },
  validateCatalogId(catId, showError = false, Dialog) {
    catId = catId.replace(/ /g, '.').toUpperCase();
    if (_.isEmpty(catId) || !_.inRange(catId.length, 4, 33) || RegExp(catIdRegex).test(catId)) {
      if (showError) {
        Dialog.alert({
          title: 'Error',
          message: 'Catalog ID should be 4 to 32 character long',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
        });
      }
      return false;
    }
    return true;
  },
  validateTag({
    tagName, showError, Dialog, skipDuplicateCheck, existingNames = [],
  }) {
    tagName = tagName.trim();
    const regex = '^[^<>|%]+$';
    let errMsg = '';
    if (_.isEmpty(tagName)) {
      errMsg = 'Tag Name cannot be empty';
    } else if (!_.inRange(tagName.length, 3, 33)) {
      errMsg = 'Tag name should be betweeen 3 to 32 character in length!';
    } else if (!RegExp(regex).test(tagName)) {
      errMsg = 'Tag names cannot contain %, |, < and >';
    } else if (skipDuplicateCheck && existingNames.includes(tagName.toLowerCase())) {
      errMsg = `The Tag '${tagName}' is Already Present, Tag names have to be unique!`;
    }
    if (showError && errMsg) {
      Dialog.alert({
        title: 'Error',
        message: errMsg,
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
      });
    }
    return errMsg;
  },
  qtyEditPermission(row) {
    const allowedStages = ['planning', 'coordination', 'detailing', 'manufacturing'];
    let permission = false;
    if (row.purpose !== 'assembly') {
      permission = (row.stage !== 'planning');
    } else {
      permission = (allowedStages.indexOf(row.stage) === -1 && row.purpose === 'assembly');
    }
    return permission;
  },
  validatePhoneNumber(phone, showError = false, Dialog) {
    // US team requirement https://github.com/manufacton/Rio/issues/10237
    // eslint-disable-next-line no-useless-escape
    const regex = '^[+]?[0-9.\\-\\(\\)\\ ]+$';
    if (!(_.inRange(phone.length, 1, 25) && RegExp(regex).test(phone))) {
      if (showError) {
        Dialog.alert({
          title: 'Error',
          message: 'Valid range for phone number length is 1 - 24!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
        });
      }
      return false;
    }
    return true;
  },
  itemNameDisable: item => !item.isNew && item.stage !== 'preparation',
  measureUnitsCheck(item, companyData) {
    if (item.measureUnits
      && _.includes(_.map(companyData.measureUnits, 'name'), item.measureUnits.toUpperCase())) {
      return item.measureUnits;
    }
    return null;
  },
  validateTask(materialItems = []) {
    if (!_.isEmpty(materialItems) && _.every(materialItems, dt => !_.isEmpty(dt.catId))) {
      return true;
    }
    return false;
  },
};

