<template>
  <div v-if="legend">
    <h3 class="has-text-weight-bold mt-0">Prefab Packages </h3>
    <h4 v-stage-color="companies.colorScheme.prefab"
      :key="companies.colorScheme.prefab"
      class="has-text-weight-bold">All Prefab Packages</h4>
      <div  v-for="colorTab in colorSchema" :key="colorTab.label">
        <h3 class="has-text-weight-bold">{{colorTab.label}}</h3>
        <div v-for="color in colorTab.data" :key="color.label" >
          <h4 v-stage-color="companies.colorScheme[color.model]"
            class="has-text-weight-bold">
            {{color.label}}
          </h4>
        </div>
      </div>
  </div>
  <div v-else class="has-background-white">
    <div class="columns">
      <div>
        <h3 class="has-text-black has-text-weight-bold">Prefab Packages</h3>
        <div class="column">
          <div class="column has-background-gray">
            <h4 class="has-text-black has-text-weight-bold"
              v-stage-color="companies.colorScheme.prefab">
              All Prefab Packages</h4>
            <color-picker
              :edit="edit"
              :color="companies.colorScheme.prefab"
              @update:color="updateColor($event, 'prefab')" />
          </div>
        </div>
      </div>
      <div class="column" v-for="colorTab in colorSchema" :key="colorTab.label">
        <h3 class="has-text-black has-text-weight-bold">{{colorTab.label}}</h3>
        <div v-for="color in colorTab.data" :key="color.label"
          class="has-text-black has-text-weight-bold column">
          <div class="column">
            <span>{{color.label}}</span>
            <div>
              <color-picker
                :edit="edit"
                :color="companies.colorScheme[color.model]"
                @update:color="updateColor($event, color.model)"/>
              </div>
            </div>
          </div>
        </div>
        </div>
  </div>
</template>
<script>
import {
  reactive, toRefs, onMounted,
} from 'vue';
import MfColorPicker from '@/components/MfColorPicker.vue';
import Companies from '@/models/Companies';

export default {
  name: 'CompanyColors',
  components: {
    'color-picker': MfColorPicker,
  },
  props: {
    legend: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      mm: {
        label: 'Material Orders',
        data: [
          {
            model: 'preparation',
            label: 'Requesting',
          },
          {
            model: 'ordering',
            label: 'Ordering',
          },
          {
            model: 'sourcing',
            label: 'Sourcing',
          },
        ],
      },
      po: {
        label: 'Production Orders',
        data: [
          {
            model: 'coordination',
            label: 'Coordination',
          },
          {
            model: 'detailing',
            label: 'Detailing',
          },
          {
            model: 'manufacturing',
            label: 'Manufacturing',
          },
          {
            model: 'qa',
            label: 'Quality Assurance',
          },
        ],
      },
      so: {
        label: 'Shipping Orders',
        data: [
          {
            model: 'notStarted',
            label: 'Scheduled',
          },
          {
            model: 'inTransit',
            label: 'In Transit',
          },
          {
            model: 'inStorage',
            label: 'In Storage',
          },
          {
            model: 'fulfilled',
            label: 'Complete',
          },
          {
            model: 'releasedToInventory',
            label: 'Inventory',
          },
          {
            model: 'mixDelivery',
            label: 'Mixed Shipments',
          },
          {
            model: 'consumed',
            label: 'Consumed',
          },
        ],
      },
      colorSchema: [],
      stageColor: Companies.defaultColorSchemes(),
      companies: {
        colorScheme: {},
      },
    });
    onMounted(async () => {
      state.companies.colorScheme = await Companies.colorSchemes(state.stageColor.company);
      if (!props.legend) {
        state.mm.data.push({
          model: 'catalogue',
          label: 'Catalogue',
        });
        state.po.data.push({
          model: 'delivery',
          label: 'Delivery',
        }, {
          model: 'complete',
          label: 'Complete',
        }, {
          model: 'mixColor',
          label: 'Mixed',
        });
      }
      state.colorSchema.push(state.mm);
      state.colorSchema.push(state.po);
      state.colorSchema.push(state.so);
    });
    const updateColor = (value, data) => {
      if (data === 'prefab') {
        state.companies.colorScheme.prefab = value;
      } else {
        state.companies.colorScheme[data] = value;
      }
    };
    return {
      ...toRefs(state),
      updateColor,
    };
  },
};
</script>
