<template>
  <div class="is-narrow side-menu-container is-relative column" v-if="showSidebar">
    <button @click="toggleSidebar" class="button is-white is-rounded toggle-sidebar"
    :class="{'is-hidden': !hideAside}"
    >
      <o-icon class="is-size-2" pak="far" :icon="store.state.showSidebar ?
        'angle-left' : 'angle-right'">
      </o-icon>
    </button>
    <aside class="menu" :class="[store.state.showSidebar ? 'expand-menu' : 'collapse-menu',
      !hideAside ? 'no-width' : ' ']"
      v-if="!$_.isEmpty($store.state.userData)">
      <ul class="menu-list" v-for="(menuList, index) in getMenuLists" :key="index">
        <li v-for="menu in menuList" :key="menu.key" @click="openOrHide(menu)"
          v-show="!menu.visibilityKey || isMenuVisible(menu)"
          :class="[getActiveMenu(menu) ? `${menu.activeColor}-background is-active` :
            !menu.isBottomList ? 'has-background-black-ter' : '']">
          <component class="is-clearfix is-flex is-align-items-center"
            :is="['logo', 'version'].includes(menu.key) ? 'div' : 'a'"
            @mouseover="hoverMenu = menu"
            @mouseleave="hoverMenu = {}">
            <span v-if="menu.key === 'user'" v-tooltip="{content: user.fullName,
              placement:'left' }"
              class="mf-avatar is-flex is-justify-content-center is-align-items-center">
              <figure class="image is-32x32"  v-if="avatar" >
                <img :src="avatar" class="is-rounded" />
              </figure>
              <i v-else  :class="menu.icon"></i>
            </span>
            <div v-else-if="menu.key === 'logo'" class="mf-logo is-flex is-align-items-center
              is-justify-content-center">
              <img src="../assets/m-logo-icon.png" alt="mf-logo" >
            </div>
            <span v-else class="image is-flex is-justify-content-center
              is-align-items-center"
              :class="[getHoverColor(menu), !menu.isBottomList ? 'is-48x48' : 'mf-avatar']"
            >
              <i :class="menu.icon" class="is-size-1"></i>
            </span>
            <div class="is-size-5 has-text-weight-bold text-label"
              :class="[{'is-hidden': !showLabels}, ['home', 'user', 'app', 'dashboard'].includes(menu.key)
                && getActiveMenu(menu) ? 'is-selected-text' : '']">
              <h6 v-if="menu.subLabel" class="is-block is-uppercase is-size-6 has-text-weight-bold">
                {{menu.subLabel}}
              </h6>
              <span v-if="menu.key === 'user'"
                :class="hoverMenu.key === menu.key && !getActiveMenu(hoverMenu)
                  ? 'is-orange-text' : ''">
                {{user.fullName}}
              </span>
              <span v-else-if="menu.key === 'logo'" class="is-flex">
                <img src="../assets/m-logo-name.png" alt="mf-comp-name">
              </span>
              <div v-else-if="menu.key === 'version'"
                class="has-text-weight-normal has-text-right has-text-white mf-version">
                <span class="is-block">Version: {{loginConstant.appVersion}}</span>
                <span>Date: {{loginConstant.appBuildDate}}</span>
              </div>
              <span v-else
                :class="hoverMenu.key === menu.key && !getActiveMenu(hoverMenu)
                  ? 'is-orange-text' : ''">
                  {{menu.label}}
              </span>
            </div>
          </component>
        </li>
      </ul>
      <div :class="{'is-hidden': !showLabels}">
      </div>
    </aside>
  </div>
</template>

<script>
import urls from '@/urls';
import Version from '@/appVersion.json';
import User from '@/models/Users';
import { useStore } from 'vuex';
import {
  computed, onBeforeMount, reactive, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  isEmpty, get, filter, some,
} from 'lodash';

export default {
  name: 'side-menu',
  setup() {
    // setting up localState
    const localState = reactive({
      openedkey: [],
      loginConstant: {},
      menuOptions: [
        {
          label: 'User Name',
          icon: 'icon-genericuser',
          key: 'user',
          path: '/account/general-settings',
          stages: ['account'],
          isBottomList: true,
          activeColor: 'mf-home',
          subs: [
            {
              label: 'Edit Account',
              icon: 'fa fa-suitcase',
              path: '/account/general-settings',
              key: 'acc',
            },
            {
              label: 'Logout',
              icon: 'fa fa-eye-dropper',
              path: '/login',
              key: 'lo',
            },
          ],
        },
        {
          label: 'Logo',
          icon: '',
          key: 'logo',
          path: '',
          stages: [],
          activeColor: 'mf-home',
        },
        {
          label: 'Dashboard',
          icon: 'icon-dashboard',
          key: 'dashboard',
          path: '/home',
          stages: ['home'],
          visibilityKey: ['home:all_home_tabs'],
          activeColor: 'mf-home',
        },
        // {
        //   label: 'Checklist',
        //   icon: 'fas fa-list-ol',
        //   key: 'checklist',
        //   path: '/home/checklist',
        //   stages: ['checklist'],
        //   visibilityKey: ['home:checklist'],
        // },
        {
          label: 'Project Planner',
          subLabel: 'plan',
          icon: 'icon-projectplanner',
          key: 'pp-dashboard',
          path: '/prefab/planning',
          stages: ['prefab', 'planning', 'coordination', 'schedule'],
          visibilityKey: ['prefabs:data', 'production-order:data'],
          activeColor: 'mf-pl',
          subs: [
            {
              label: 'Prefab Package',
              icon: 'fa fa-book',
              path: '/prefab/planning',
              key: 'planning',
              visibilityKey: 'prefabs:data',
            },
            {
              label: 'Overview',
              icon: 'fa',
              path: '/prefab/dashboard',
              key: 'overview',
              // visibilityKey: 'material-manager:overview',
            },
            {
              label: 'Production Order',
              icon: 'icon icon-production-order',
              path: '/prefab/coordination',
              key: 'coordination',
              visibilityKey: 'production-order:data',
            },
          ],
        },
        {
          label: 'Material Manager',
          subLabel: 'buy',
          icon: 'icon-materialmanager',
          key: 'mm-dashboard',
          path: '/materials/dashboard',
          stages: ['materials', 'material', 'sourcing', 'ordering'],
          visibilityKey: ['material-manager:data'],
          activeColor: 'mf-mm',
          subs: [
            {
              label: 'Overview',
              icon: 'fa',
              path: '/materials/dashboard',
              key: 'overview',
              // visibilityKey: 'material-manager:overview',
            },
            {
              label: 'Preparation',
              icon: 'fa fa-suitcase',
              path: '/materials/preparation',
              key: 'prep',
            },
            {
              label: 'Measurement',
              icon: 'fa fa-eye-dropper',
              path: '/materials/sourcing',
              key: 'qa',
            },
            {
              label: 'Ordering',
              icon: 'fa fa-phone',
              path: '/materials/ordering',
              key: 'ordering',
            },
          ],
        },
        {
          label: 'Production Manager',
          subLabel: 'make',
          icon: 'icon-productionmanager',
          key: 'manager-dashboard',
          path: '/manager/dashboard',
          stages: ['manager', 'managers'],
          visibilityKey: ['production-manager:data'],
          activeColor: 'mf-pm',
          subs: [
            {
              label: 'Dashboard',
              icon: 'icon-edit',
              path: '/manager/dashboard',
              key: 'dashboard',
            },
            {
              label: 'Detailing',
              icon: 'icon-edit',
              path: '/manager/detailing',
              key: 'detailing',
            },
            {
              label: 'Manufacturing',
              icon: 'icon icon-contractor',
              path: '/manager/manufacturing',
              key: 'mf',
            },
            {
              label: 'Quality Assurance',
              icon: 'fa fa-check-square',
              path: '/manager/qa',
              key: 'qa',
            },
          ],
        },
        {
          label: 'Logistics Manager',
          subLabel: 'deliver',
          icon: 'icon-logisticsmanager',
          key: 'logistics',
          path: '/logistics/shipping',
          stages: ['shipping', 'inventory', 'transfer-request'],
          visibilityKey: ['inventory:data', 'scm:shipping'],
          activeColor: 'mf-lm',
          subs: [
            {
              label: 'inventory',
              icon: 'fa fa-phone',
              path: '/logistics/inventory',
              key: 'inventory',
              visibilityKey: 'inventory:data',
            },
            {
              label: 'shipping',
              icon: 'fa fa-phone',
              path: '/logistics/shipping/order-view',
              key: 'shipping',
              visibilityKey: 'scm:shipping',
            },
          ],
        },
        {
          label: 'Supply Chain Manager',
          subLabel: 'manage',
          icon: 'icon-supplychainmanager',
          key: 'scm',
          path: '/scm/production-status/order-view',
          stages: ['scm'],
          visibilityKey: ['scm:production-status', 'scm:material-status'],
          activeColor: 'mf-scm',
          subs: [
            {
              label: 'Production Status',
              icon: 'icon-edit',
              path: '/scm/production-status/order-view',
              key: 'ps',
              visibilityKey: 'scm:production-status',
            },
            {
              label: 'Material Status',
              icon: 'icon-materialmanager',
              path: '/scm/material-status/order-view',
              key: 'ms',
              visibilityKey: 'scm:material-status',
            },
          ],
        },
        {
          label: 'Resources',
          subLabel: 'Document',
          icon: 'icon-resources',
          key: 'home',
          path: '/resources/forms',
          stages: ['forms', 'notifications', 'recentEvents', 'tasks'],
          visibilityKey: ['home:all_home_tabs'],
          activeColor: 'mf-home',
          subs: [
            {
              label: 'forms',
              icon: 'icon-edit',
              path: '/resources/forms',
            },
            {
              label: 'notifications',
              icon: 'icon-materialmanager',
              path: '/resources/notifications',
            },
            {
              label: 'recentEvents',
              icon: 'icon-edit',
              path: '/resources/recentEvents',
            },
            {
              label: 'tasks',
              icon: 'icon-materialmanager',
              path: '/resources/tasks',
            },
          ],
        },
        {
          label: 'App & Project Settings',
          icon: 'icon-settings',
          key: 'app',
          isBottomList: true,
          path: '/settings',
          stages: ['settings', 'projects', 'productionTemplate', 'materialTemplate'],
          visibilityKey: ['app&projects:projects', 'app&projects:members',
            'app&projects:companies', 'app&projects:company_profile',
            'app&projects:manufacton_settings'],
          activeColor: 'mf-home',
        },
        {
          label: 'Log Out',
          icon: 'icon-loginlogout',
          key: 'lo',
          stages: [],
          isBottomList: true,
        },
        {
          label: '',
          icon: '',
          key: 'version',
          path: '',
          stages: [],
          isBottomList: true,
          activeColor: 'mf-home',
        },
      ],
      appSubs: [
        {
          type: 'projects',
          path: '/settings/projects',
          visibilityKey: 'app&projects:projects',
        },
        {
          type: 'members',
          path: '/settings/members',
          visibilityKey: 'app&projects:members',
        },
        {
          type: 'companies',
          path: '/settings/companies',
          visibilityKey: 'app&projects:companies',
        },
        {
          type: 'company_profile',
          path: '/settings/company-profile',
          visibilityKey: 'app&projects:company_profile',
          subTabs: ['CompanyInfo', 'CompanyLocations', 'GeneralInventory', 'MeasureUnits',
            'AssemblyParts'],
        },
        {
          type: 'manufacton_settings',
          path: '/settings/manufacton-settings',
          visibilityKey: 'app&projects:manufacton_settings',
          subTabs: ['CompanyTemplates', 'CompanyColors', 'QrLabel', 'LoginSecurity',
            'PermissionGroups'],
        },
      ],
      activeMenu: {},
      hoverMenu: {},
      showLabels: false,
    });
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    localState.showLabels = store.state.showSidebar;

    const loginScreen = () => Version;

    onBeforeMount(async () => {
      await store.getters.userPromise;
      localState.loginConstant = loginScreen();
    });

    const toggleSidebar = () => {
      store.commit('toggleSidebar');
      if (!localState.showLabels) {
        setTimeout(() => {
          localState.showLabels = !localState.showLabels;
        }, 300);
      } else {
        localState.showLabels = !localState.showLabels;
      }
    };

    const logout = async () => {
      if (store.state.showSidebar) toggleSidebar();
      // clear selected nested location in inventory global filter
      // store.dispatch('clearInvNestedLocations');
      // clear colsMap in store upon logout
      // store.commit('updateColsMap');
      await User.logout();
      router.push({ path: '/login' });
      localStorage.removeItem('auth-token');
      await store.dispatch('resetState');
    };

    const openOrHide = (menu) => {
      if (menu.key === 'lo') {
        logout();
        return;
      }
      localState.activeMenu = menu;
      /* added below code for sidemenu to redirect to correct page based on permission */
      let tabPath = '';
      let subTab = [];
      // added this route name as the router push doesnt work for the path
      // along with params.
      const routeNameMap = {
        company_profile: 'company-profile',
        manufacton_settings: 'manufacton-settings',
      };
      // start of permission code
      if (menu.visibilityKey && menu.visibilityKey.includes('app&projects:projects')) {
        menu.subs = localState.appSubs;
      }
      if (menu.subs && menu.visibilityKey) {
        menu.subs = menu.subs
          .filter((t) => {
            if (!t.visibilityKey) return true;
            if (['scm:production-status', 'scm:material-status',
              'scm:shipping', 'app&projects:projects',
              'material-manager:overview', 'home:all_home_tabs', 'inventory:data'].includes(t.visibilityKey)) {
              return store.getters.hasCompanyPermissionForModule(t.visibilityKey);
            }
            return store.getters.hasPermissionForModule(t.visibilityKey);
          });
        if (['company_profile', 'manufacton_settings'].includes(menu.subs[0].type)) {
          subTab = menu.subs[0].subTabs.filter((t) => {
            const key = store.getters.getSubtabsModule(this.$_.camelCase(t));
            return store.getters.hasCompanyPermissionForModule(key);
          });
        }
        tabPath = menu.subs[0].path;
      } else {
        tabPath = menu.path;
      }
      /* end of permission code */
      // context.emit('close-subHeader');
      if (isEmpty(subTab)) router.push({ path: tabPath });
      else { // added the below router push by name as it sends sub tab as params
        router.push({ name: routeNameMap[menu.subs[0].type], params: { tab: subTab[0] } });
      }
    };

    const getActiveMenu = (menu) => {
      let activeStage = false;
      for (const stage of menu.stages) {
        if (stage !== '' && !activeStage) {
          if (stage === 'checklist') {
            if (menu.path === route.path) activeStage = true;
          } else if (stage === 'home' && route.path.split('/').includes('checklist')) {
            break;
          } else {
            activeStage = route.path.split('/').includes(stage);
          }
        }
      }
      return activeStage;
    };

    const user = computed(() => store.state.userData);

    const usercompanyModules = computed(() => user.value.allowedModules.companyModules);

    const isMenuVisible = (menu) => {
      if (['scm', 'logistics', 'app'].includes(menu.key)) {
        return some(menu.visibilityKey, (key) => usercompanyModules.value.includes(key));
      }
      return usercompanyModules.value.includes(menu.visibilityKey[0]);
    };

    const avatar = computed(() => {
      let url = '';
      const fileGuid = get(user.value, 'imageUrl.fileGuid', '');
      if (user.value._id && fileGuid !== '') {
        url = `${urls.s3}users/${user.value._id}/${fileGuid}`;
      }
      return url;
    });

    const getHoverColor = (menu) => {
      const hoverClasses = [];
      if (!localState.hoverMenu.isBottomList && localState.hoverMenu.key === menu.key) {
        hoverClasses.push(`${menu.activeColor}-background`);
        if (!getActiveMenu(menu)) {
          hoverClasses.push(`${menu.activeColor}-border`);
        }
      }
      return hoverClasses;
    };

    const getMenuLists = computed(() => {
      const topMenuList = filter(localState.menuOptions, (m) => !m.isBottomList);
      const bottomMenuList = filter(localState.menuOptions, (m) => m.isBottomList);
      return [topMenuList, bottomMenuList];
    });

    const showSidebar = computed(() => get(route, 'meta.showSideBar', true));
    const hideAside = computed(() => isEmpty(route.query?.sheet));
    return {
      toggleSidebar,
      store,
      ...toRefs(localState),
      user,
      isMenuVisible,
      avatar,
      openOrHide,
      getActiveMenu,
      getHoverColor,
      getMenuLists,
      showSidebar,
      hideAside,
    };
  },
};
</script>

<style scoped>
.toggle-sidebar {
  padding: 0 !important;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 59px;
  right: -12.5px;
  z-index: 99;
}
.side-menu-container aside.menu.expand-menu,
.side-menu-container aside.menu.collapse-menu {
  transition: width 0.3s ease-in-out;
}
.side-menu-container aside.menu.expand-menu {
  width: 264px !important;
}
.side-menu-container aside.menu.collapse-menu {
  width: 64px;
}
::v-deep(.fa-angle-left) {
  margin-right: 1px;
}
::v-deep(.fa-angle-right) {
  margin-left: 1px;
}
.icon-loginlogout::before {
  font-size: 40px !important;
}
.no-width {
  width: 0 !important;
}
</style>
