<template>
  <div>
    <div class="column header-stripe-small">
      <figure class="level-item has-text-centered">
        <img src="./../assets/logo-login.png">
      </figure>
    </div>
    <div class="has-background-white signup-col">
      <div class="column content">
        <h2 class="is-size-3 title has-text-black-bis">Create Account</h2>
        <div class="columns">
          <div class="column">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model.trim="user.firstName"
                  placeholder="First Name"
                  required
                  pattern="^[a-zA-Z/\s]+$"
                >
              </div>
            </div>
            <!-- <b-input placeholder="Billing" class="m-b-15" v-model="company.billingName">
            </b-input>-->
          </div>
          <div class="column">
            <div class="field">
              <div class="control">
                <input class="input " type="text" v-model.trim="user.lastName"
                  placeholder="Last Name">
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <div class="control">
                <input
                  class="input "
                  type="text"
                  v-model.trim="user.company.title"
                  placeholder="Position Title"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <h3 class="subtitle is-size-3  has-text-black-bis">Address 1</h3>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <div class="control">
                    <input
                      class="input "
                      type="text"
                      v-model.trim="user.company.name"
                      placeholder="Company Name"
                    >
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model.trim="user.company.website"
                      placeholder="Website"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model.trim="user.company.addr1.street"
                  placeholder="Street And Number"
                >
              </div>
            </div>
            <div class="columns">
              <div class="column is-8">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model.trim="user.company.addr1.city"
                      placeholder="City"
                    >
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model.trim="user.company.addr1.state"
                      placeholder="State"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model.trim="user.company.addr1.country"
                  placeholder="Country"
                >
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="tel"
                  v-model.trim="user.company.addr1.phone"
                  placeholder="Phone Number"
                >
              </div>
            </div>
          </div>
          <div class="column">
            <h3 class="subtitle  is-size-3  has-text-black-bis">
              Address 2
              <span class="has-text-grey-light is-size-6">(Optional)</span>
            </h3>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model.trim="user.company.addr2.companyName"
                      placeholder="Company Name"
                    >
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model.trim="user.company.addr2.website"
                      placeholder="Website"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model.trim="user.company.addr2.street"
                  placeholder="Street And Number"
                >
              </div>
            </div>
            <div class="columns">
              <div class="column is-8">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model.trim="user.company.addr2.city"
                      placeholder="City"
                    >
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model.trim="user.company.addr2.state"
                      placeholder="State"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model.trim="user.company.addr2.country"
                  placeholder="Country"
                >
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="tel"
                  v-model.trim="user.company.addr2.phone"
                  placeholder="Phone Number"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <h3 class="subtitle  is-size-3  has-text-black-bis">Login & Password</h3>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="email"
                  v-model.trim="user.email"
                  placeholder="Login ID: Your email address"
                >
              </div>
            </div>
            <div class="field">
              <div class="control">
                <p class="control has-icons-right">
                  <input
                    class="input"
                    :type="password ? 'text' : 'password'"
                    v-model="user.password"
                    placeholder="Password: 8 to 14 characters"
                  >

                    <span
                    class="icon is-small is-right is-clickable"
                    @click="password = !password"
                    @mouseover="
                      password
                        ? (eyeIcon = 'icon-hide')
                        : (eyeIcon = 'icon-show')
                    "
                    @mouseleave="
                      password
                        ? (eyeIcon = 'icon-show')
                        : (eyeIcon = 'icon-hide')
                    "
                  >
                    <i :class="eyeIcon"></i>
                  </span>
                </p>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <p class="control has-icons-right">
                  <input
                    class="input"
                    :type="verifyPassword ? 'text' : 'password'"
                    v-model="user.repassword"
                    placeholder="Retype Password"
                  >
                   <span
                class="icon is-small is-right is-clickable"
                @click="verifyPassword = !verifyPassword"
                @mouseover="
                  verifyPassword
                    ? (verifyEyeIcon = 'icon-hide')
                    : (verifyEyeIcon = 'icon-show')
                "
                @mouseleave="
                  verifyPassword
                    ? (verifyEyeIcon = 'icon-show')
                    : (verifyEyeIcon = 'icon-hide')
                "
              >
                <i :class="verifyEyeIcon"></i>
              </span>
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <h3 class="subtitle  is-size-3  has-text-black-bis">Account Information</h3>
            <div class="field">
              <p class="control is-expanded has-text-black-bis">
                 <o-field label="Account License">
                    <o-select placeholder="Select a License" v-model="user.company.license">
                       <option
                    v-for="license in $_.sortBy(licenses, 'name')"
                    :value="license.value"
                    :key="license.value"
                  >{{ license.name }}</option>
                    </o-select>
                  </o-field>
              </p>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model.trim="user.company.billingName"
                  placeholder="Billing Contact Name"
                >
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="email"
                  v-model.trim="user.company.billingEmail"
                  placeholder="Billing Contact Email"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p class="has-text-weight-semibold is-flex has-text-black-bis
                 is-justify-content-center is-align-items-center">
              <o-checkbox v-model="user.acceptTerms" native-value="I agree to the" ></o-checkbox>
              <a class="mr-2"
                href="https://www.manufacton.com/terms-of-use/"
                target="_blank"
              >Terms and Conditions</a> and
              <a  class="ml-2"
                href="https://www.manufacton.com/manufacton-privacy-policy/"
                target="_blank"
              >Privacy Policy</a>
            </p>
          </div>
        </div>
        <button class="button has-text-white is-uppercase has-background-black-bis" @click="signUp()">SUBMIT</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive, toRefs,
} from 'vue';
import User from '@/models/Users';
import _ from 'lodash';
import Validation from '@/utils/Validations';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export default {
  name: 'signup',
  setup() {
    const toast = useToast();
    const router = useRouter();
    const state = reactive({
      licenses: [
        { name: 'Trial', value: 'trial' },
        { name: 'Project', value: 'project' },
        { name: 'Enterprise', value: 'enterprise' },
      ],
      user: {
        company: {
          addr1: {
            city: '',
            country: '',
            state: '',
            street: '',
          },
          addr2: {},
          name: '',
          billingName: '',
          billingEmail: '',
        },
        acceptTerms: false,
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        repassword: '',
      },
      password: false,
      verifyPassword: false,
      eyeIcon: 'icon-hide',
      verifyEyeIcon: 'icon-hide',
    });
    const validate = () => {
      if (state.user.firstName.length < 2) {
        toast.error('Please enter First Name of Atleast 2 character');
        return false;
      }

      if (state.user.lastName.length < 2) {
        toast.error('Please enter Last Name of Atleast 2 character');
        return false;
      }

      if (state.user.company.name.length < 3) {
        toast.error('Please enter Company Name of Atleast 2 character');
        return false;
      }

      if (
        !_.isEmpty(state.user.company.addr1.phone)
        && !Validation.validatePhoneNumber(state.user.company.addr1.phone, true)
      ) {
        return false;
      }

      if (
        !_.isEmpty(state.user.company.addr2.phone)
        && !Validation.validatePhoneNumber(state.user.company.addr2.phone, true)
      ) {
        return false;
      }

      if (!Validation.validateEmail(state.user.email)) {
        toast.error('Please enter your valid email');
        return false;
      }
      if (_.isEmpty(state.user.password)) {
        toast.error('Please enter password');
        return false;
      }

      if (state.user.password !== state.user.repassword) {
        toast.error('Passwords do not match');
        return false;
      }

      if (state.user.company.billingName.length < 3) {
        toast.error(
          'Please enter Billing Name of Atleast 2 character',
        );
        return false;
      }

      if (!Validation.validateEmail(state.user.company.billingEmail)) {
        toast.error('Please check the Billing email you have entered');
        return false;
      }
      if (state.user.acceptTerms === false) {
        toast.error('You must accept the ToS');
        return false;
      }
      if (!_.isEmpty(state.user.company.addr1.phone)) {
        state.user.officePhone = state.user.company.addr1.phone;
        state.user.mobilePhone = state.user.company.addr1.phone;
      }
      const rgex = new RegExp('^(http|https)://', 'i');
      if (
        !_.isEmpty(state.user.company.website)
        && !state.user.company.website.match(rgex)
      ) {
        state.user.company.website = `http://${state.user.company.website}`;
      }
      if (
        !_.isEmpty(state.user.company.addr2.website)
        && state.user.company.addr2.website.match(rgex)
      ) {
        state.user.company.website = `http://${state.user.company.addr2.website}`;
      }

      return true;
    };
    const signUp = async () => {
      if (validate()) {
        try {
          await User.signUp(state.user, state.user.company);
          router.push({ path: '/thankyou' });
        } catch (err) {
          if (err.status === 404) {
            toast.error('Error !', 'Looks like server is down !!!');
          } else if (err.data?.message) {
            toast.error(err.data.message);
          } else {
            toast.error('Error !', 'Server error', 'error');
          }
          console.log(err);
        }
      }
      return true;
    };
    return {
      ...toRefs(state),
      signUp,
      validate,
    };
  },
};
</script>
<style scoped>
</style>
