<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        {{title}}
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="cancel()"></i>
    </header>
    <section class="modal-card-body is-size-4">
        <p class="has-text-black-bis mb-5">
          {{description}}
        </p>
        <div class="stage-options">
          <div class="stage-option mb-3" v-for="opt of options" :key="opt.label">
            <input type="radio" v-model="selectedStage" :id="opt.value"
            :value="opt.value" :checked="selectedStage === opt.value">
            <label :for="opt.value" class="has-text-black-bis">
              {{opt.label}}
            </label>
          </div>
        </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined" @click="cancel()">Cancel</button>
      <button class="button has-background-black-bis"
              @click="select()"
              :disabled="$_.isEmpty(selectedStage)">Select</button>
    </footer>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue';

export default {
  name: 'stage-select-modal',
  props: {
    title: String,
    description: String,
  },
  setup(props, { emit }) {
    const state = reactive({
      selectedStage: null,
    });
    const select = () => {
      emit('selected-stage', state.selectedStage);
      emit('close');
    };
    const cancel = () => {
      emit('cancel');
      emit('close');
    };
    const options = [
      {
        label: 'Coordination',
        value: 'coord',
      }, {
        label: 'Detailing',
        value: 'detail',
      }, {
        label: 'Manufacturing',
        value: 'manuf',
      },
    ];
    return {
      ...toRefs(state),
      options,
      select,
      cancel,
    };
  },
};
</script>

<style scoped>
  .modal-card-body {
    overflow: visible;
  }
</style>
