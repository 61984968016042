export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  customRowKey: 'uid',
  hideDefaultSortIcon: true,
  fields: [
    {
      name: 'field-generic-input',
      sortField: 'name',
      prop: 'name',
      title: 'Item Name',
      width: '250px',
      id: 'name',
      dataClass: 'overflow-visible',
      allowAutoComplete: true,
      inputProps: {
        type: 'text',
        openCard: false,
        filterCatIds: true,
        disableEdit(item) {
          return !item.newRow;
        },
      },
    }, {
      name: 'field-generic-input',
      prop: 'customId',
      sortField: 'customId',
      width: '152px',
      title: 'Item ID',
      id: 'customId',
      inputProps: {
        type: 'text',
        openCard: false,
        disableEdit: true,
      },
    }, {
      name: 'field-cat-id',
      sortField: 'catId',
      prop: 'catId',
      width: '152px',
      title: 'Catalog ID',
      id: 'catId',
      dataClass: 'overflow-visible',
      allowAutoComplete: true,
      verifyCatId: true,
      inputProps: {
        type: 'text',
        openCard: false,
        disableEdit(item) {
          return !item.newRow;
        },
        filterCatIds: true,
        for: 'orderMoreInv',
      },
    }, {
      name: 'field-generic-input',
      prop: 'minQuantity',
      width: '64px',
      title: 'Min',
      id: 'minQuantity',
      inputProps: {
        disableEdit: true,
        type: 'number',
        min: 0,
        max: 9999,
        clampTo: 'maxQuantity',
      },
      alwaysVisible: false,
    }, {
      name: 'field-generic-input',
      prop: 'maxQuantity',
      width: '64px',
      title: 'Max',
      id: 'maxQuantity',
      inputProps: {
        disableEdit: true,
        type: 'number',
        min: 0,
        max: 9999,
      },
      defaultVisible: true,
      alwaysVisible: false,
    }, {
      name: 'field-generic-input',
      prop: 'available',
      width: '112px',
      title: 'Current',
      id: 'available',
      isDisabled: true,
      inputProps: {
        type: 'text',
        disableEdit: true,
      },
      defaultVisible: true,
      alwaysVisible: false,
    }, {
      name: 'qtyToOrder',
      width: '112px',
      title: 'Qty To Order',
      id: 'qtyToOrder',
      prop: 'qtyToOrder',
      defaultVisible: true,
      defaultValue: 1,
    },
  ],
};
