<template>
  <div class="field">
    <mf-multi-select
      v-model="inputValue"
      :options="cardStatuses"
      :titleLabel="titleLabel"
      labelClass="has-text-black-bis is-italic is-size-3"
      label="name"
      openDirection="below"
      track-by="_id"
      :allow-empty="false"
      :disabled="disable"
    >
    </mf-multi-select>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  onMounted,
} from 'vue';
import {
  isEmpty, find, set,
} from 'lodash';
import { useRoute } from 'vue-router';
import MfMultiSelect from '@/components/abstract/MfMultiSelect';
import CardEditMixin from '../mixins/CardEditMixin';

export default {
  name: 'SelectStatus',
  props: {
    titleLabel: {
      type: String,
      default: '',
    },
    value: [Object, String],
    resumeOrdersByAll: {
      type: Boolean,
      default: false,
    },
    stage: {
      type: String,
      default: '',
    }
  },
  components: {
    MfMultiSelect,
  },
  setup(props, { emit }) {
    const data = reactive({
      cardStatuses: [{
        name: 'Not Started',
        _id: 'not-started',
      }, {
        name: 'In Progress',
        _id: 'in-progress',
      }, {
        name: 'Paused',
        _id: 'pause',
        $isDisabled: (props.stage === 'qa')
      }, {
        name: 'Complete',
        _id: 'complete',
      }],
      disable: true,
    });
    const { getRestartPermission, disableStatus } = CardEditMixin();
    const route = useRoute();
    const selectedCardStatus = (status) => [find(data.cardStatuses, { _id: status })];
    const disableStatusDropDown = computed( async() => {
      const tempOrd = {};
      set(tempOrd, 'project._id', route.params.projectId)
      const checkPermission = await getRestartPermission(tempOrd);
      return checkPermission;
    })
    const inputValue = computed({
      get: () => (isEmpty(props.value) ? [] : selectedCardStatus(props.value)),
      set: (val) => emit('update:value', val._id),
    });
    onMounted(async() => {
      if (props.resumeOrdersByAll) {
        data.disable = false;
      } else {
        data.disable = (disableStatus(props.value) && await disableStatusDropDown.value);
      }
    })

    return {
      ...toRefs(data),
      inputValue,
      disableStatusDropDown,
    };
  },
};
</script>
