export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  isCheckable: false,
  stickeyHeaders: true,
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      tooltip: 'Customize Columns',
    }],
  },
  toolBar: {
    buttons: [],
  },
  fields: [
    {
      name: 'notif-toggle',
      width: '400px',
      title: 'Event',
      alwaysVisible: true,
      sticky: true,
      dataClass: 'break-all',
      id: 'absoluteNotification',
    },
    {
      name: 'field-generic-input',
      width: '400px',
      title: 'Source',
      prop: 'source.name',
      id: 'sourceOrder',
      defaultVisible: true,
      isClickable: true,
      eventType: 'openCard',
      alwaysVisible: true,
      searchable: false,
      isDisabled: true,
      inputProps: {
        type: 'text',
        disableEdit: true,
      },
    },
    {
      name: 'notificationTitle',
      width: '296px',
      title: 'Event Type',
      searchable: true,
      id: 'notificationTitle',
      defaultVisible: true,
    },
    {
      name: 'project.name',
      width: '296px',
      title: 'Project',
      id: 'project',
      searchable: true,
      filterText: 'Projects',
      defaultVisible: true,
      iPadVisible: true,
    },
    {
      name: 'notif-date',
      width: '112px',
      title: 'Date',
      dateFilter: 'notifyBy',
      id: 'notifyBy',
      searchable: true,
      defaultVisible: true,
      iPadVisible: true,
    },
    {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      width: '112px',
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      noRequiredCheck: true,
      sticky: true,
      hideFromChooser: true,
      alwaysVisible: true,
      searchable: true,
      clearBtn: true,
      inputProps: {
        showEdit: false,
      },
    },

  ],
};
