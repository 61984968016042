<template>
  <div class="list-view">
    <mf-table
      ref="shippingTable"
      :tableProps="tableProps"
      :hideGutter="true"
      :loadData="loadData"
      :screenTable="true"
      tableName="shipping"
      @cell-clicked="onCellClicked"
    ></mf-table>
    <transition name="slide">
      <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedOrder"
        @close="closeNotesSlider"
        :isShipping="true"
        :isDisabled="true"
      ></notes-icon-slider>
    </transition>
    <transition name="slide">
      <item-document-slider
        v-if="deliveryDocs.isFileListVisible"
        :isActive="deliveryDocs.isFileListVisible"
        :rowData="deliveryDocs.selectedItem"
        :rowField="deliveryDocs.docRowField"
        @close="closeDocumentSlider"
        :isDisabled="true"
      >
      </item-document-slider>
    </transition>
      <activity-log
      v-if="activityModalOpen"
      :isActive="activityModalOpen"
      :id="activityDetails.id"
      :projectId="activityDetails.projectId"
      :orderName="activityDetails.orderName"
      @update:isActive="activityModalOpen=false"
    ></activity-log>
    <o-modal
          v-if="isDeliveryItemsActive"
          :active="isDeliveryItemsActive"
          :canCancel="false"
          :trapFocus="true"
          class="modal-sm"
        >
        <delivery-items
          :rowData="isDeliveryItemsRow"
          :isUnscheduledRow="isUnscheduledRow"
          @close="toggleDeliveryItems"
        >
        </delivery-items>
    </o-modal>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import Shipping from '@/models/Shipping';
import Projects from '@/models/Projects';
import {
  isEmpty, get, find, set, assign, pick
} from 'lodash';
import orderViewCols from '@/components/table-cols/shippingOrderViewCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import {
  ref, watch, reactive, toRefs, inject, onBeforeUnmount,
} from 'vue';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';
import ShippingReceive from '@/components/modals/ShippingReceive.vue';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import Print from '@/components/utils/Print';
import ExcelExportMixin from '@/components/mixins/ExcelExportMixin';
import UtilityMixin from '@/components/mixins/UtilityMixin';
import ActivityLog from '@/components/ActivityLog.vue';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import DeliveryItems from '@/components/modals/DeliveryItems.vue';
import moment from 'moment';

export default ({
  props: {
    showEmpty: Boolean,
  },
  components: {
    MfTable,
    ActivityLog,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
    DeliveryItems,
  },
  setup(props) {
    const store = useStore();
    const emitter = inject('emitter');
    const router = useRouter();
    const route = useRoute();
    const shippingTable = ref(null);
    const toast = useToast();
    const { excelExport } = ExcelExportMixin();
    const {
      deleteOrder,
      markOrderFinal,
      releaseToInv,
      cancelShipment,
      scheduleDelivery,
      // createShipment,
    } = GeneralShippingMixin();
    const { getNegativeList } = UtilityMixin();
    const state = reactive({
      tableProps: orderViewCols,
      activityModalOpen: false,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      isLoading: false,
      isSlideNotesActive: false,
      selectedOrder: {},
      isItem: false,
      deliveryDocs: {
        isFileListVisible: false,
        selectedItem: {},
        isDisabled: true,
      },
      isDeliveryItemsActive: false,
      isDeliveryItemsRow: {},
      isUnscheduledRow: false,
    });

    const refreshTable = async () => {
      await shippingTable.value?.refreshTable();
    };

    const loadData = async (commonParam) => {
      const itemKind = store.getters.selectedValuesForKey('types', false);
      const { fromDashBoard, dbUpcoming, dbShowEmpty } = route.query;
      const startOffSetDate = dbUpcoming === '<1Week' ? 0 : 7;
      const endOffSetDate = dbUpcoming === '<1Week' ? 6 : 41;
      const dashBoardParams = {
        fromDashBoard: fromDashBoard === 'true',
        dbUpcoming: dbUpcoming,
        dbShowEmpty: dbShowEmpty,
        projectId: store.getters.selectedIdsForKey('filteredProjects'),
        shipType: ['m', 's-m'],
        showInternalOrders: false,
        ...commonParam,
      };
      if (dbUpcoming) {
        dashBoardParams.shipStartDate = (dbUpcoming === 'Overdue') ? ''
          : moment().add(startOffSetDate, 'days').toDate();
        dashBoardParams.shipEndDate = (dbUpcoming === 'Overdue') ? moment().toDate()
          : moment().add(endOffSetDate, 'days').toDate();
      }
      let params = {
        status: store.getters.selectedValuesForKey('shippingStatus', false),
        shipType: ['m', 's-m'],
        showAllCompanyOrders: store.state.queryParams.showAllCompanyOrders,
        currentLocation: store.getters.selectedIdsForKey('currentLocations'),
        deliveryLocation: store.getters.selectedIdsForKey('deliveryLocation'),
        itemKind: itemKind.length > 1 ? [] : itemKind[0],
        company: store.state.userData.company,
        ...store.getters.selectedDatesForKeys([
          'deliverStartDate',
          'deliverEndDate',
          'shipStartDate',
          'shipEndDate',
          'maxDeliverStartDate',
          'maxDeliverEndDate',
          'minShipStartDate',
          'minShipEndDate',
        ]),
        // eslint-disable-next-line no-use-before-define
        showEmpty: props.showEmpty,
        showInternalOrders: false,
        ...commonParam,
      };
      if (store.state.userData.viewOnlyMember) {
        params.company = store.getters.selectedIdsForKey('companies', false);
      }
      // To hide inventory orders by default
      if (params.status.includes('released-to-inventory')) {
        params.showInvOrders = true;
      } else params.showInvOrders = false;
      const user = store.state.userData;
      params = await getNegativeList(params, {
        projectId: store.getters.selectedIdsForKey('filteredProjects'),
        owner: store.getters.selectedIdsForKey('owner'),
        recipient: store.getters.selectedIdsForKey('recipient'),
        currentLocation: store.getters.selectedIdsForKey('currentLocations'),
        deliveryLocation: store.getters.selectedIdsForKey('deliveryLocation'),
      });
      const shipping = await Shipping.get(fromDashBoard === 'true' ? dashBoardParams : params);
      const selectedProjectIds = params.projectId;
      const selectedProjects = await Projects.allProjects({ projectId: selectedProjectIds });
      for (const so of shipping.data) {
        const shippingProject = find(selectedProjects, { _id: so.project._id });
        let projectSettings = [];
        if (shippingProject) {
          ({ projectSettings } = shippingProject);
        }
        if (isEmpty(projectSettings)) {
          so.allowReceiveShipment = false;
        }
        const companyProjectSetting = find(projectSettings, { companyId: user.company });
        so.allowReceiveShipment = (companyProjectSetting
            && companyProjectSetting.allowReceiveShipment
            && (get(so.delivery, 'recipient.userDetail.company', '')) === user.company);
      }
      return shipping;
    };

    const saveRow = async (shipment) => {
      set(shipment, 'isEditing', !shipment.isEditing);
      try {
        const validate = shipment.validateLabel();
        if (validate.error) {
          toast.error(validate.errText);
          console.error('error---------', validate.errText);
          return;
        }
        if (shipment.shipType === 'm') {
          const ms = await Shipping.getPartialShipments({
            shippingLabelId: shipment._id,
          });
          const partials = ms.partialShipments;
          const promises = [];
          for (const partial of partials) {
            assign(partial.delivery, pick(shipment.delivery, ['deliverBy', 'receipent', 'owner', 'deliveryProject', 'deliveryLocation']));
            promises.push(Shipping.update(partial));
          }
          await Promise.all(promises);
        }
        shipment = await Shipping.update(shipment);
        // eslint-disable-next-line no-unused-expressions
        await refreshTable();
      } catch (e) {
        console.log(e);
      }
    };

    function openRow(card) {
      router.push({
        name: 'shipping-edit',
        params: { projectId: card.project._id, cardId: card._id },
      });
    }

    const onCellClicked = async (event) => {
      const { type, data } = event;
      if (type === 'save') {
        state.isLoading = true;
        await saveRow(data);
        state.isLoading = false;
      } else if (type === 'openCard') {
        openRow(data);
      } else if (type === 'receive') {
        ModalProgrammatic.open({
          component: ShippingReceive,
          props: {
            shippingLabel: data,
            isActive: true,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          events: {
            close: () => {
              refreshTable();
            },
          },
        });
      } else if (type === 'activity') {
        state.activityModalOpen = true;
        state.activityDetails.id = data._id;
        state.activityDetails.projectId = data.project._id;
        state.activityDetails.orderName = data.name;
      } else if (type === 'remove') {
        state.isLoading = true;
        await deleteOrder(data, refreshTable, state);
        state.isLoading = false;
      } else if (type === 'cancelDelivery') {
        state.isLoading = true;
        await cancelShipment(data, refreshTable);
        state.isLoading = false;
      } else if (type === 'markFinal') {
        state.isLoading = true;
        await markOrderFinal(data, refreshTable);
        state.isLoading = false;
      } else if (type === 'release') {
        const deliveryLoc = get(data.delivery, 'currentLocation', {});
        await releaseToInv(deliveryLoc, data, refreshTable, state);
      } else if (type === 'scheduleDelivery') {
        data.name = `Revived of ${data.name}`;
        ModalProgrammatic.open({
          component: ShippingReceive,
          props: {
            shippingLabel: data,
            isSchedule: true,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          events: {
            close: (val) => {
              if (!val) refreshTable();
            },
          },
        });
        await refreshTable();
      } else if (type === 'printQr') {
        const itemData = data.items;
        const params = {
          shippingId: data._id,
          projectId: data.project._id,
          tzinfo: new Date().getTimezoneOffset(),
          printSerial: false,
          type: 'shipping',
        };
        const qrLabel = await Shipping.getShippingLabel(params);
        const itemArray = qrLabel.filter((qr) => qr.type !== 'shipping');
        const compArray = qrLabel[0];
        set(compArray, 'viewIndex', 0);
        const sortedArr = itemArray;
        sortedArr.unshift(compArray);
        for (const item of itemData) {
          const obj = find(itemArray, (dt) => dt._id === item._id);
          set(obj, 'viewIndex', item.viewIndex);
        }
        const qrData = sortedArr;
        const user = store.state.userData;
        await Print.printQrLabel(qrData, user);
      } else if (type === 'excelExport') excelExport();
      // else if (type === 'addNew') {
      // Uncomment if required - Trigger Create shipment modal
      //   createShipment(refreshTable);
      // }
    };

    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      state.selectedOrder = {};
    });

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      state.selectedOrder = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive;
    });

    emitter.on('toggle:flyoutNoteSlider', (payload) => {
      state.selectedOrder = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      state.deliveryDocs.selectedItem = payload.data;
      state.deliveryDocs.docRowField = payload.rowField;
      state.deliveryDocs.isFileListVisible = payload.isActive;
      state.deliveryDocs.isShipping = payload.isShipping;
    });

    const closeDocumentSlider = () => {
      state.deliveryDocs.isFileListVisible = false;
    };
    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
    });

    watch(
      () => props.showEmpty,
      async () => { await refreshTable(); },
    );

    const toggleDeliveryItems = async () => {
      state.isDeliveryItemsActive = false;
    };

    emitter.on('toggle:isDeliveryItems', (payload) => {
      state.isDeliveryItemsRow = payload.rowData;
      state.isUnscheduledRow = payload.isUnscheduledRow;
      state.isDeliveryItemsActive = true;
    });

    return {
      ...toRefs(state),
      shippingTable,
      loadData,
      refreshTable,
      onCellClicked,
      closeNotesSlider,
      closeDocumentSlider,
      toggleDeliveryItems,
    };
  },
});
</script>
