import _ from 'lodash';

export default {
  createEmptyRow(cols) {
    const emptyRow = {};
    _.each(cols, (col) => {
      emptyRow[col.id] = col.defaultValue || '';
    });
    emptyRow.isEditing = true;
    emptyRow.newRow = true;
    return emptyRow;
  },
  createFilledRow(cols, row) {
    const emptyRow = {};
    _.each(cols, (col) => {
      emptyRow[col.id] = row[col.prop] || col.defaultValue || '';
    });
    emptyRow.isEditing = false;
    emptyRow.newRow = false;
    return emptyRow;
  },
};
