<template>
    <video id="video-src" :height="height" :width="width"></video>
</template>

<script>
import { BrowserCodeReader, BrowserQRCodeReader } from '@zxing/browser';
import { reactive, onBeforeUnmount, onMounted } from 'vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'WebQrScan',
  props: {
    height: {
      type: Number,
      default: 600,
    },
    width: {
      type: Number,
      default: 800,
    },
  },
  setup(props, { emit }) {
    const toast = useToast();

    const state = reactive({
      isDestroying: false,
      cameraIsOn: true,
      stopCamera: {},
    });

    const onDecode = (content, controls) => {
      const code = content;
      if (code) {
        emit('qrCodeContent', code);
        controls.stop();
      }
    };

    const onError = (error) => {
      const knownErrors = {
        NotAllowedError: 'Camera permission denied', // user denied camera access permission
        NotFoundError: 'Camera not found', // no suitable camera device installed
        NotSupportedError: 'Browser problem encountered', // page is not served over HTTPs (or localhost)
        NotReadableError: 'Cannot get clear QR image', // maybe camera is already in use or blur
        OvercontrainedError: 'Camera may be too close or too far', // passed contraints don't match any camera.
        IndexSizeError: 'Index Size Exceeded', // can be ignored
        default: error,
      };
      if (knownErrors[error.name]) {
        toast.warning(knownErrors[error.name] || knownErrors.default);
      }
    };

    const startCamera = async () => {
      try {
        // toast.info('Camera loading');
        const codeReader = new BrowserQRCodeReader();
        // the result from below is not used. It is kept just
        // to make sure browser confirms camera access.

        const videoInputDevicesList = await BrowserCodeReader.listVideoInputDevices();

        // choose your media device (webcam, frontal camera, back camera, etc.)
        const selectedDeviceId = videoInputDevicesList[0].deviceId;
        const previewElem = document.querySelector('#video-src');

        // you can use the controls to stop() the scan or switchTorch() if available
        await codeReader.decodeFromVideoDevice(selectedDeviceId, previewElem, (result, error, controls) => {
          // use the result and error values to choose your actions
          // you can also use controls API in this scope like the controls
          // returned from the method.
          // undefined means back camera
          state.stopCamera = controls;
          if (result) {
            onDecode(result.text, controls);
          }
          // if (error.message !== 'NotFoundException') {
          //   throw error;
          // }
        });
      } catch (e) {
        onError(e);
      }
    };

    onBeforeUnmount(() => {
      state.stopCamera.stop();
    });

    onMounted(async () => {
      startCamera();
    });
  },
};

</script>

<style scoped> </style>
