/**
 * Created by saikrishna on 27/12/18.
 */
export default {
  PROJECT: {
    field: 'project',
  },
  LOCATION: {
    field: 'location',
    multiple: false,
    required: true,
  },
  'CATALOG ID': {
    field: 'catId',
    multiple: false,
    required: true,
  },
  MEAS: {
    field: 'measure',
    multiple: false,
  },
  'MEAS UNITS': {
    field: 'measureUnits',
    multiple: false,
  },
  'MIN STOCK': {
    field: 'minQuantity',
    multiple: false,
  },
  'MAX STOCK': {
    field: 'maxQuantity',
    multiple: false,
  },
  QUANTITY: {
    field: 'quantity',
    multiple: false,
  },
  NOTES: {
    field: 'inventoryNotes',
    multiple: false,
  },
};
