<template>
  <div>
    <mf-multi-select
      v-if="isEditing"
      v-model="inputValue"
      :options="selectOpts"
      :titleLabel="titleLabel"
      :labelPosition="labelPosition"
      :placeholder="placeholder"
      :disabled="isDisabled"
      labelClass="has-text-black-bis is-italic is-size-3"
      :multiple="false"
      :searchable="true"
      openDirection="below"
      label="name"
      track-by="_id"
      :allow-empty="false"
      :preselectFirst="true"
      @select="$emit('select', $event)"
    >
    </mf-multi-select>
    <span
      v-else
    >
      {{ inputValue.name }}
    </span>
  </div>
</template>

<script>
import {
  computed, onMounted,
} from 'vue';
import {
  isEmpty,
} from 'lodash';
import MfMultiSelect from '@/components/abstract/MfMultiSelect';
import { useStore } from 'vuex';

export default {
  name: 'ProjectSelect',
  props: {
    titleLabel: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: 'top',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: Object,
    options: Array,
    rowData: Object,
    isEditing: Boolean,
    isDisabled: [Boolean, Function],
    hasPermission: {
      type: Boolean,
      default: true,
    },
    selfCompany: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MfMultiSelect,
  },
  setup(props, { emit }) {
    const store = useStore();
    const {
      options,
      selfCompany
    } = props;

    // computed
    const inputValue = computed({
      get: () => (isEmpty(props.value) ? null : props.value),
      set: (val) => (emit('update:value', val)),
    });

    const selectOpts = computed(() => {
      let projectList;
      if (!props.hasPermission) {
        projectList = options;
      } else {
        projectList = isEmpty(options) ? store.state.queryParams._projects : options;
      }
      if (selfCompany) {
        return _.filter(projectList, (project) => project.createdCompany._id === store.state.userData.company);
      }
      return projectList;
    });
    onMounted(async () => {
      if (isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
    })
    return {
      onMounted,
      inputValue,
      selectOpts,
    };
  },
};
</script>
