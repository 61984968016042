<template>
    <div>
        <div v-if="['Update','Transition'].includes(activity.action)">
          <div class="is-block has-text-weight-normal mb-2">
            <span v-if="activity.type=='ExcelImport'">,imported items from Excel</span>
             {{$filters.fullDateTime(activity.timeStamp)}}
            <span v-if="userAgent.getBrowser().name">
              from {{userAgent.getBrowser().name}} on {{userAgent.getOS().name}}
            </span>
            <span v-else>{{activity.userAgent}}</span>
            <span class="is-pulled-right">
               <i :class="!change ? 'icon-disclosearrow':
              'icon-disclosearrowup'"
               class="pointer"
               @click="isCollapsed()">
            </i>
            </span>
          </div>
          <div class="has-text-weight-bold mb-2 has-text-black-bis">
            {{userName}}
            <span v-if=" activity.type=='mergeOrder'">merged the {{moduleName}}</span>
            <span v-if="activity.module=='User'">
              <span v-if="activity.activityUser._id != activity.user._id">
                updated The user profile of
              </span>
              <span v-else>You updated account settings</span>
            </span>
            <span v-if="activity.module=='Company'">
              <span v-if="activity.activityUser.company !=activity.company._id">
                updated company profile of
              </span>
              <span v-else>
                updated company profile
              </span>
            </span>
            <span v-if="activity.module === 'TransferRequest'">
              updated the {{$_.upperFirst(activity.task.type)}} Transfer Request
            </span>
            <span
                    v-else-if="!['User','Company','ProductionOrder'].includes(activity.module)
              || ( activity.module=='ProductionOrder' && activity.type!='mergeOrder')"
            >
              updated the {{moduleName}}
            </span>
            <span v-if="activity.module=='InventoryManager'">with Following Details</span>
            <span v-else-if="activity.module === 'TransferRequest'">{{activity.task.taskId}}</span>
            <span v-else> '{{orderName}}'</span>

           </div>
          <ul v-show="change" v-for="(message,index) in activity.messages" :key="index"
              class="has-text-black-bis">
            <li>
              <span v-html="message.text"></span>
            </li>
          </ul>
        </div>
        <div v-if="['Add','Create','Login','Clone','Scan',
          'Cancel','Return','Receive','Remove', 'Release', 'Revive']
          .includes(activity.action)">
            <div class="is-block has-text-weight-normal mb-2">
            {{$filters.fullDateTime(activity.timeStamp)}}
            <span v-if="userAgent.getBrowser().name">
              from {{userAgent.getBrowser().name}} on {{userAgent.getOS().name}}
            </span>
            <span v-else class="text-overflow">{{activity.userAgent}}</span>
            <span class="is-pulled-right">
            <i :class="!change ? 'icon-disclosearrowup':
            'icon-disclosearrow'" class="pointer has-text-primary" v-if="activity.messages[1]"
            @click="isCollapsed()"></i>
            </span>
            </div>
            <div class="has-text-weight-bold mb-2 has-text-black-bis">
                {{activity.messages[0].text }}
            </div>
            <ul
                  v-if="activity.messages[1] && !change"
                  v-for="(message,index) in activity.messages"
                  :key="index" class="list-style"
          >
            <li v-if="index > 0">
              <span v-html="message.text"></span>
            </li>
          </ul>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import moment from 'moment';
import UAParser from 'ua-parser-js';
import { computed, reactive, toRefs } from '@vue/reactivity';
import _ from 'lodash';

export default defineComponent({
  name: 'ActivityStatement',
  props: ['statements', 'idx', 'showFirst'],
  setup(props) {
    const state = reactive({
      change: props.showFirst || false,
      activity: computed(() => {
        // const self = this;
        let date1;
        let date2;
        if (!_.isEmpty(props.statements.dateMessages)) {
          _.forEach(props.statements.dateMessages, (dateAct) => {
            date1 = _.find(dateAct.dates, { name: 'date1' });
            if (date1) {
              date1 = moment(String(date1.value))
                .format('MMM-DD-YYYY');
            }
            date2 = _.find(dateAct.dates, { name: 'date2' });
            if (date2) {
              date2 = moment(String(date2.value))
                .format('MMM-DD-YYYY');
            }
            if (dateAct && dateAct.text) {
              let datemsg = dateAct.text
                .replace('{{date1}}', date1)
                .replace('{{date2}}', date2);
              datemsg = datemsg.replace('On-Site', 'Onsite');
              props.statements.messages.push({
                text: datemsg,
                field: 'dates',
              });
            }
          });
        }
        if (props.idx === 0) props.change = false;
        else props.change = true;
        if (!_.isEmpty(props.statements.privilegedMessages)) {
          // eslint-disable-next-line max-len
          props.statements.messages = props.statements.messages.concat(props.statements.privilegedMessages);
        }
        if (_.isEmpty(props.statements.messages)) {
          return true;
        }
        return props.statements;
      }),
      userName: computed(() => `${state.activity.activityUser.firstName} ${state.activity.activityUser.lastName}`),
      moduleName: computed(() => _.words(state.activity.module)
        .join(' ')),
      orderName: computed(() => {
        const actName = state.activity[_.camelCase(state.activity.module)];
        if (actName) {
          if (state.activity.module === 'MaterialTemplate' || state.activity.module === 'ProductionTemplate' || state.activity.module === 'FormBuilder') {
            return actName.templateName;
          }
          return actName.name;
        } if (state.activity.module === 'Shipping') {
          return state.activity.shippingLabel.name;
        } if (_.get(state.activity, 'sourcingOrder.__t') === 'Sourcing') {
          return state.activity.sourcingOrder.name;
        }
        return true;
      }),
      userAgent: computed(() => {
        const parser = new UAParser();
        return parser.setUA(state.activity.userAgent);
      }),
    });
    const methods = {
      isCollapsed() {
        state.change = !state.change;
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
});
</script>

<style scoped>
</style>
