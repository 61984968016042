<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        Edit Description
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="cancel()"></i>
    </header>
    <section class="modal-card-body is-size-1">
        <o-loading :full-page="false" :active="isLoading" :can-cancel="false"></o-loading>
        <div class="field">
          <div class="field-label">
            <label class="label is-italic">
              Enter New Description
            </label>
          </div>
          <div class="field-body">
            <input class="input mb-3" rows="1" v-model="newOrderId">
            <p class="has-text-grey is-size-5">{{cards.length}} orders will be updated</p>
          </div>
        </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined"
        @click="cancel()">Cancel</button>
      <button class="button has-background-black-bis"
        @click="updateOrderId()"
        :disabled="isDisabled()">Update</button>
    </footer>
    <o-modal :active="isProgressBar" v-if="isProgressBar" :canCancel="false" class="modal-xs">
      <progress-bar
      :totalCount="totalProgressLength"
      :progressCount="progressCount"
      headerLabel="Updating Description"
      @turn-off-progress="turnOffProgress"
      progressType="percent"
      />
    </o-modal>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue';
import { useToast } from 'vue-toastification';
import _, { map } from 'lodash';
import OrderManager from '@/models/ProductionManager';

export default {
  props: {
    cards: Array,
    projectId: String,
    batchSize: Number,
  },
  setup(props, { emit }) {
    const totalProgressLength = props.cards.length;
    const toast = useToast();
    const state = reactive({
      isLoading: false,
      newOrderId: '',
      sourceOrderId: null,
      doneCount: 0,
      failCount: 0,
      progressCount: 0,
      isProgressBar: false,
    });
    const isDisabled = () => {
      if (state.newOrderId.trim().length < 4) return true;
      return false;
    };
    const turnOffProgress = () => {
      setTimeout(() => {
        state.isProgressBar = false;
        state.isLoading = false;
        emit('refresh-table');
        emit('close');
      }, 1000);
    };

    const validateOrderId = (newId) => {
      const regex = '^[A-Za-z0-9][A-Za-z0-9-._]*?[A-Za-z0-9]$';
      if (newId.length > 24) {
        return {
          errMsg: 'Maximum Length is 24',
        };
      }
      if (!RegExp(regex).test(newId)) {
        return {
          errMsg: 'Order Id cannot start or end with special characters.',
        };
      }
      return true;
    };
    const updateOrderId = async () => {
      let newID = state.newOrderId.trim();
      newID = newID.toString().replace(/ /g, '.').toUpperCase();
      const invalidMsg = validateOrderId(newID);
      if (invalidMsg !== true) {
        toast.error(invalidMsg.errMsg);
        return;
      }
      try {
        state.isLoading = true;
        const cardIds = map(props.cards, '_id');
        const cardIdschunk = _.chunk(cardIds, props.batchSize);
        state.isProgressBar = true;
        for (const chunk of cardIdschunk) {
          const params = {
            cardIds: chunk,
            newID,
            projectId: props.projectId,
          };
          const res = await OrderManager.bulkUpdateField(params);
          state.doneCount += res?.data?.updateCount || 0;
          state.progressCount = state.doneCount;
          state.failCount += chunk.size - res?.data?.updateCount;
        }
      } catch (e) {
        toast.success(`Only ${state?.doneCount} orders updated.`);
        toast.error('Update Failed. Please contact Manufacton Support');
      } finally {
        if (!state.failCount) {
          toast.success(`${state.doneCount} orders updated.`);
        } else {
          toast.warning(`${state.failCount} orders not updated`);
        }
      }
    };
    const cancel = () => {
      emit('close');
    };

    return {
      ...toRefs(state),
      updateOrderId,
      isDisabled,
      cancel,
      totalProgressLength,
      turnOffProgress,
    };
  },
};
</script>
<style scoped>
</style>
