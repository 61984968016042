<template>
    <div class="activity is-pulled-right">
      <aside>
        <div class="card">
          <header class="card-header has-background-grey-lighter">
            <label class="card-header-title has-text-black-bis">
              Notes ({{headerNotes}})
             </label>
              <i
              class=" icon-addnew has-text-black-bis"
              @click="open"
              v-if="canAdd && !notes.length"
            ></i>
            <div class="is-divider-vertical"></div>
            <span
              class="card-header-icon"
              aria-label="more options"
              @click="closeNotesSlider"
            >
              <i class="icon-close" @click="cancel"></i>
            </span>
          </header>
          <div class="card-content note-body-content">
            <div class="file-lists">
              <h3
                class="note-content-border has-text-black-bis has-text-weight-bold line-height"
              >
                {{ label }}
              </h3>
              <div v-if="isEnabled">
                <h3 class="is-italic">
                  {{ userName }}
                  <span class="is-pulled-right">
                    <i v-if="isEnabled" class="icon-close has-text-balck-bis" @click="cancel"></i>
                    <i
                      v-if="isEnabled"
                      class="icon-checkmark has-text-success"
                      @click="save"
                    ></i>
                  </span>
                </h3>
                <textarea
                  class="textarea"
                  rows="2"
                  v-model="notesVal"
                ></textarea>
              </div>
                 <div v-if="!isEnabled">
                <div
                  v-for="(note, idx) of notes"
                  :key="idx"
                  :class="$_.trim(note.noteText) ? 'note-content-border has-text-black-bis is-size-5': ''"
                >
                  <h3 class="is-italic">
                    <span class="is-pulled-right" v-if="canAdd">
                      <i class="icon-edit" @click="edit"></i>
                    </span>
                  {{ note.noteCreatedBy }}
                    <span class="is-pulled-right">
                      <h1> {{ note.noteCreatedAt }}</h1>
                    </span>
                  </h3>
                  <p>{{ note.noteText }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
</template>

<script>

import {
  reactive, toRefs, computed, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'NotesIconSlider',
  props: {
    isActive: Boolean,
    rowData: Object,
    value: String,
    isItem: Boolean,
    isShipping: Boolean,
    fromMove: Boolean,
    isDisabled: Boolean,
    isPurchaseModal:Boolean
  },
  setup({
    rowData, value, isItem, isActive, isShipping, fromMove, isDisabled, isPurchaseModal
  }, { emit }) {
    const store = useStore();
    const state = reactive({
      slideIsActive: isActive,
      notes: [],
      notesVal: '',
      userName: '',
      isEnabled: false,
    });
    if (!isItem && rowData.currentNote?.text?.length) {
      state.notes = [{
        noteText: rowData.currentNote.text,
        noteCreatedBy: '',
        noteCreatedAt: '',
      }];
    }
    if (!isItem && rowData.manager?.notes.length) {
      state.notes = [{
        noteText: rowData.manager.notes,
        noteCreatedBy: '',
        noteCreatedAt: '',
      }];
    }
    if (fromMove && (rowData[0]?.notes.length || rowData[0]?.currentNote.text)) {
      state.notes = [{
        noteText: rowData[0].notes[0] || rowData[0].currentNote.text || '',
        noteCreatedBy: '',
        noteCreatedAt: '',
      }];
    }

    // for items
    if (rowData._place === 'item' && rowData.simpleMemos.length && rowData.simpleMemos[0].text) {
      state.notes = [{
        noteText: rowData.simpleMemos[0].text,
        noteCreatedBy: rowData.simpleMemos[0].created?.by.user.name,
        noteCreatedAt: rowData.simpleMemos[0].created ? moment(rowData.simpleMemos[0].created.at).format('MM/DD/YYYY, h:mm:ss A') : '',
      }];
    }

    const notesText = computed({
      get: () => value,
      set: (val) => {
        emit('update:value', val);
      },
    });

    const label = computed(() => rowData.name || _.get(rowData, '[0].name', ''));
    const open = () => {
      state.isEnabled = true;
    };
    const edit = () => {
      state.isEnabled = true;
      state.notesVal = state.notes[0].noteText;
    };

    const cancel = () => {
      state.isEnabled = false;
    };

    const save = async () => {
      const noteText = state.notesVal;
      state.notesVal = '';
      notesText.value = noteText;
      if (isItem) {
        state.notes = [{
          noteText: rowData.simpleMemos.length ? rowData.simpleMemos[0].text : '',
          noteCreatedBy: state.userName,
          noteCreatedAt: moment().format('MM/DD/YYYY, h:mm:ss A'),
        }];
      } else if (fromMove) {
        state.notes = [{
          noteText,
          noteCreatedBy: state.userName,
          noteCreatedAt: moment().format('MM/DD/YYYY, h:mm:ss A'),
        }];
        _.set(rowData[0], 'notes', [noteText]);
        _.set(rowData[0].currentNote, 'text', noteText);
      } else {
        state.notes = [{
          noteText,
          noteCreatedBy: state.userName,
          noteCreatedAt: moment().format('MM/DD/YYYY, h:mm:ss A'),
        }];
        state.isEnabled = true;
      }
      const activeScreen = ['Prefabs', 'ProductionOrder'];
      if (activeScreen.includes(rowData.__t) && rowData._place !== 'item' && !rowData.isManager) {
        _.set(rowData, 'currentNote.text', noteText);
      } else if (activeScreen.includes(rowData.__t) && rowData._place !== 'item' && rowData.isManager) {
        _.set(rowData, 'manager.notes', noteText);
      } else if (isShipping) {
        _.set(rowData, 'delivery.notes', noteText);
      } else if (rowData._place === 'item') {
        isItem = true;
        _.set(rowData, 'simpleMemos[0].text', noteText);
      }
      // _.set(rowData, '_beforeEdit', _.cloneDeep(rowData));

      const eventObj = {
        type: 'save',
        data: rowData,
        isItem,
      };
      emit('update:note', eventObj);
      state.isEnabled = false;
    };

    onMounted(async () => {
      const user = await store.getters.userPromise;
      state.userName = user.name;
      if (isShipping) {
        if (rowData.delivery.notes && rowData.delivery.notes.length) {
          state.notes = [{
            noteText: rowData.delivery.notes,
            noteCreatedBy: '',
            noteCreatedAt: '',
          }];
        }
      }

     
      
      if (isPurchaseModal && _.isEmpty(_.get(_.first(rowData), 'currentNote.text', ''))) {
        state.notes = [];
      }

      // for items
      if (rowData._place === 'item' && rowData.simpleMemos.length) {
        if (rowData.simpleMemos[0].text?.length) {
          state.notes = [{
            noteText: rowData.simpleMemos[0].text,
            noteCreatedBy: rowData.simpleMemos[0].created?.by.user.name,
            noteCreatedAt: rowData.simpleMemos[0].created ? moment(rowData.simpleMemos[0].created.at).format('MM/DD/YYYY, h:mm:ss A') : '',
          }];
        } else {
          state.notes = [];
        }
      }
    });

    const closeNotesSlider = () => {
      emit('close');
    };

    const canAdd = computed(() => {
      if (rowData.isItemCompleted) return false
      if (store.state.activeScreen === 'schedule' && !fromMove) return (rowData._isEditing || rowData.isNew) && !isDisabled;
      if (['planning', 'coordination'].includes(store.state.activeScreen)) return false;
      return (!state.isEnabled && !isDisabled);
    });
    const headerNotes = computed(() => {
      const allHeadersCount = _.filter(state.notes, (val) => val.noteText.trim());
      return allHeadersCount.length;
    });

    return {
      ...toRefs(state),
      open,
      edit,
      cancel,
      save,
      notesText,
      label,
      closeNotesSlider,
      canAdd,
      headerNotes,
    };
  },
};
</script>
