<template>
<div>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <div class="list-view">
      <div class="backingpanel">
            <div class="column dash-panel is-inline-block">
              <div class="dash-panel-title">MATERIAL ORDERS BY PROJECT
                <select v-model="categoryTimeFrame">
                  <option v-for="option in timeframeOptions" :key="option.value" :value="option.value">{{option.text}}</option>
                </select>
              </div>
              <div class="dash-holder-med" id="catstats"></div>
            </div>
            <div class="column dash-panel is-inline-block">
              <div class="dash-panel-title">MATERIAL LEAD TIMES BY USER
                <select v-model="userTimeFrame">
                  <option v-for="option in timeframeOptions" :key="option.value" :value="option.value">{{option.text}}</option>
                </select>
              </div>
              <div class="dash-holder-med" id="userstats"></div>
            </div>
            <div class="column dash-panel is-inline-block">
              <div class="dash-panel-title">MATERIAL DELIVERIES BY VENDOR
                <select v-model="vendorTimeFrame">
                  <option v-for="option in timeframeOptions" :key="option.value" :value="option.value">{{option.text}}</option>
                </select>
              </div>
              <div class="dash-holder-med" id="vendorstats"></div>
            </div>
      </div>
  </div>
</div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, onMounted, computed, watch, watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PowerBIToken from '@/models/PowerBI';
import * as pbi from 'powerbi-client';
import Vendors from '@/models/Vendors';
import TableGutter from '@/components/TableGutter.vue';
import tableDefinition from '@/components/table-cols/materialCols';
import screens from '@/constants';
import _ from 'lodash';
import moment from 'moment';

import Version from '@/appVersion.json';

export default defineComponent({
  name: 'MaterialDashboard',
  setup() {
    function setupReport(report, drill, p) {
      // Clear any other loaded handler events
      report.off('loaded');

      // Triggers when a report schema is successfully loaded
      report.on('loaded', () => {
        // report.updateFilters(pbi.models.FiltersOperations.Add, [myFilter]);
      });

      // Clear any other rendered handler events
      report.off('rendered');

      // Triggers when a report is successfully embedded in UI
      report.on('rendered', () => {
        // console.log('Report render successful');
      });

      // Clear any other error handler events
      report.off('error');

      // Handle embed errors
      report.on('error', (event) => {
        const errorMsg = event.detail;
        console.error(errorMsg);
      });

      report.off('dataSelected');

      report.on('dataSelected', (event) => {
        const stageMap = {
          Requesting: 'preparation',
          Sourcing: 'sourcing',
          Ordering: 'ordering',
          Complete: 'complete',
        };
        if (p.category || p.uncategorized) {
          // do the thing! send stage name and category id
          const stages = _.get(event, 'detail.dataPoints[0].identity[0].equals', 'requesting');
          const isLate = _.get(event, 'detail.dataPoints[1].identity[1].equals', false);
          const categoryId = _.get(p, 'category._id', false);
          const params = {
            fromDashBoard: true,
            stages: stages === 'Ordering' ? 'delivery' : stageMap[stages], // this should be the "stage"
          };
          if (categoryId) {
            params.categoryId = categoryId;
          }
          if (isLate) {
            params.isLate = isLate;
            params.date = { deliverEndDate: moment().startOf('day')._d };
            params.date = JSON.stringify(params.date);
          }
          let routePath = `/materials/${
            stageMap[stages]
          }`;
          if ((stages && stages === 'Complete')) {
            routePath = '/scm/material-status/order-view';
          }
          // drill(routePath, params);
        }
      });

      report.off('dataHyperlinkClicked');

      report.on('dataHyperlinkClicked', (event) => {
        const url = _.get(event, 'detail.url').replaceAll('&amp;', '&');
        const split = url.split('?');
        if (split.length === 2) {
          const urlParams = new URLSearchParams(`?${split[1]}`);

          // TODO parse out the route as well as params!
          let routePath = '/prefab/schedule';
          const params = {};

          if (split[0].includes('scm/material-status') >= 0) {
            routePath = '/scm/material-status/order-view';
          }

          if (urlParams.get('fromDashBoard')) {
            params.fromDashBoard = urlParams.get('fromDashBoard');
          }
          if (urlParams.get('approvalStatus')) {
            params.approvalStatus = urlParams.get('approvalStatus');
          }
          if (urlParams.get('approvalMin')) {
            params.approvalMin = urlParams.get('approvalMin');
          }
          if (urlParams.get('approvalMax')) {
            params.approvalMax = urlParams.get('approvalMax');
          }
          if (urlParams.get('submittalState')) {
            params.submittalState = urlParams.get('submittalState');
          }
          if (urlParams.get('vendor') && urlParams.get('vendor') !== 'all') {
            params.vendor = urlParams.get('vendor');
          }
          if (urlParams.get('isLate')) {
            params.isLate = urlParams.get('isLate');
          }
          if (urlParams.get('isDelivered')) {
            params.isDelivered = urlParams.get('isDelivered');
          }
          if (urlParams.get('stages')) {
            params.stages = urlParams.get('stages');
          }
          if (urlParams.get('leadTimeMin')) {
            params.leadTimeMin = urlParams.get('leadTimeMin');
          }
          if (urlParams.get('leadTimeMax')) {
            params.leadTimeMax = urlParams.get('leadTimeMax');
          }

          drill(routePath, params);
        }
      });
    }

    const store = useStore();
    const router = useRouter();
    const state = reactive({
      isLoading: false,
      isActive: false,
      tableProps: tableDefinition,
      createOrderModal: false,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      isRejectActive: false,
      orderId: '',
      dates: [
        {
          kind: 'shipBy',
          label: 'shipBy',
        },
        {
          kind: 'onSiteBy',
          label: 'onSiteBy',
        },
      ],
      excelImportModal: false,
      allUsers: [],
      user: {},
      selectedProject: '',
      allProjects: [],
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedItem: {},
      isItem: false,
      rowField: {},
    });

    const permissionType = () => {
      const res = _.get(screens[store.state.activeScreen], 'permissionModule', '');
      if (res === 'shipping') return 'scm';
      return res;
    };
    const getAllProjects = computed(() => {
      const permModule = (permissionType() === 'scm')
        ? 'shipping' : permissionType();
      return store.getters.getProjectsOnPermission(permModule, store.state.queryParams._projects, 'data');
    });
    const groupedProjects = computed(() => {
      const projects = getAllProjects.value;
      return projects;
    });

    let showDash = false; // update this to true for UI instance and local
    if (_.get(Version, 'env', '') == 'dev') {
      showDash = true;
    }

    function drill(path, query) {
      router.push({ path, query });
    }
    const getDynamicClasses = computed(() => {
      const routeMeta = router.currentRoute.value.meta;
      let classes = '';
      if (routeMeta.activeViewColor) {
        classes = `${routeMeta.activeViewColor} `;
      }
      if (routeMeta.cardEditIcon) {
        classes += `icon-${routeMeta.cardEditIcon}`;
      }
      return classes;
    });
    const data = reactive({
      isActive: false,
      allCategories: [],
    });
    const clickActivity = () => {
      data.isActive = true;
    };

    const showSecond = ref(false);
    const showThird = ref(false);
    const biRef = ref('<div></div>');
    const showMobile = false;

    const projectIds = computed(() => store.getters.selectedIdsForKey('filteredProjects'));

    const projectFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'projects',
        column: '_id',
      },
      operator: 'In',
      values: projectIds.value,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const projectFilterM = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'materialOrders',
        column: 'project._id',
      },
      operator: 'In',
      values: projectIds.value,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const reports = [];

    const timeframeOptions = [
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Years, qty: 100, value: 'a', text: 'All Time',
      },
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Weeks, qty: 1, value: 'b', text: 'Past Week',
      },
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Months, qty: 1, value: 'c', text: 'Past Month',
      },
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Months, qty: 3, value: 'd', text: 'Past 3 Months',
      },
      {
        unit: pbi.models.RelativeDateFilterTimeUnit.Years, qty: 1, value: 'e', text: 'Past Year',
      },
    ];

    const userTimeFrame = ref('a');
    const categoryTimeFrame = ref('a');
    const vendorTimeFrame = ref('a');

    let userDateFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'MaterialOrders',
        column: 'created.at',
      },
      operator: 'All',
      values: [],
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        isHiddenInViewMode: true,
      },
    };

    const categoryDateFilter = {
      $schema: 'http://powerbi.com/product/schema#relativeDate',
      target: {
        table: 'MaterialOrders',
        column: 'OnsiteByDate',
      },
      operator: pbi.models.RelativeDateOperators.InLast,
      timeUnitsCount: 100,
      timeUnitType: pbi.models.RelativeDateFilterTimeUnit.Years,
      includeToday: true,
      filterType: pbi.models.FilterType.RelativeDate,
    };

    const vendorDateFilter = {
      $schema: 'http://powerbi.com/product/schema#relativeDate',
      target: {
        table: 'MaterialOrders',
        column: 'OnsiteByDate',
      },
      operator: pbi.models.RelativeDateOperators.InLast,
      timeUnitsCount: 100,
      timeUnitType: pbi.models.RelativeDateFilterTimeUnit.Years,
      includeToday: true,
      filterType: pbi.models.FilterType.RelativeDate,
    };

    watch(() => store.state.queryParams.projects, () => {
      projectFilter.values = projectIds.value;
      projectFilterM.values = projectIds.value;
      // for some reason reports.each did not work here...
      reports[0].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilter, projectFilterM]);
      reports[1].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilter, projectFilterM]);
      reports[2].updateFilters(pbi.models.FiltersOperations.Replace, [projectFilter, projectFilterM]);
    });

    watchEffect(() => {
      const { qty, unit } = timeframeOptions.find((element) => element.value === categoryTimeFrame.value);
      categoryDateFilter.timeUnitsCount = qty;
      categoryDateFilter.timeUnitType = unit;
      if (reports[0]) {
        reports[0].updateFilters(pbi.models.FiltersOperations.Replace, [categoryDateFilter]);
      }
    });

    watchEffect(() => {
      const { qty, unit } = timeframeOptions.find((element) => element.value === userTimeFrame.value);
      if (reports[1]) {
        userDateFilter = {
          $schema: 'http://powerbi.com/product/schema#relativeDate',
          target: {
            table: 'MaterialOrders',
            column: 'created.at',
          },
          operator: pbi.models.RelativeDateOperators.InLast,
          timeUnitsCount: qty,
          timeUnitType: unit,
          includeToday: true,
          filterType: pbi.models.FilterType.RelativeDate,
        };
        reports[1].updateFilters(pbi.models.FiltersOperations.Replace, [userDateFilter]);
      }
    });

    watchEffect(() => {
      const { qty, unit } = timeframeOptions.find((element) => element.value === vendorTimeFrame.value);
      vendorDateFilter.timeUnitsCount = qty;
      vendorDateFilter.timeUnitType = unit;
      if (reports[2]) {
        reports[2].updateFilters(pbi.models.FiltersOperations.Replace, [vendorDateFilter]);
      }
    });

    onMounted(async () => {
      data.allCategories = (await Vendors.getCategories({ limit: 500 })).data;
      await PowerBIToken.getEmbedToken().then((response) => {
        const embedData = response;

        // set up the request object
        const metrics = {
          type: 'report',
          tokenType: pbi.models.TokenType.Embed,
          accessToken: embedData.accessToken,

          // Use other embed report config based on the requirement.
          // We have used the first one for demo purpose
          embedUrl: embedData.embedUrl[0].embedUrl,
          // ALWAYS specify page name to future-proof
          pageName: '',
          reportSection: '',

          // Enable this setting to remove gray shoulders from embedded report
          // settings: {
          //     background: models.BackgroundType.Transparent
          // }
          settings: {
            layoutType: (showMobile ? pbi.models.LayoutType.MobilePortrait
              : pbi.models.LayoutType.Master),
            panes: {
              pageNavigation: {
                visible: false,
              },
              filters:
              {
                visible: false,
              },
            },
            hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent,
          },
          filters: [],
        };

        // Use the token expiry to regenerate Embed token for seamless end user experience
        // Refer https://aka.ms/RefreshEmbedToken
        // const tokenExpiry = embedData.expiry;

        // Embed Power BI report when Access token and Embed URL are available

        const powerbi = new pbi.service.Service(pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory, pbi.factories.routerFactory);

        const UncategorizedFilter = new pbi.models.AdvancedFilter(
          { table: 'items', column: 'category._id' }, 'And', { operator: 'IsBlank', value: '' },
        ).toJSON();

        metrics.pageName = 'ReportSection81b5198a343b103e4168';
        metrics.filters = [projectFilter, categoryDateFilter, projectFilterM];
        let reportholder = document.getElementById('catstats');
        let report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill, { uncategorized: true });
        reports.push(report);

        metrics.pageName = 'ReportSectionc58672ccba84125542e8';
        metrics.filters = [userDateFilter, projectFilterM];
        reportholder = document.getElementById('userstats');
        report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill, { uncategorized: true });
        reports.push(report);

        metrics.pageName = 'ReportSection13173f08d01765f3878d';
        metrics.filters = [vendorDateFilter, projectFilterM];
        reportholder = document.getElementById('vendorstats');
        report = powerbi.embed(reportholder, metrics);
        setupReport(report, drill, { uncategorized: true });
        reports.push(report);
      });
    });

    return {
      ...toRefs(data),
      ...toRefs(state),
      clickActivity,
      showSecond,
      showThird,
      biRef,
      showMobile,
      reports,
      getDynamicClasses,
      showDash,
      timeframeOptions,
      categoryTimeFrame,
      userTimeFrame,
      vendorTimeFrame,
    };
  },
});
</script>
<style scoped>

</style>
