<template>
  <h4 class="title is-capitalized">{{ label }} ({{ value.length }})</h4>
  <div class="mf-bom">
    <div v-for="bom in value" :key="bom._id"
      class="bom-file is-flex is-justify-content-space-between">
      <div class="mb-2 is-flex">
        <button
          v-if="!disableSubmit(bom)"
          @click="submitMaterial(bom)"
          v-tooltip="{ content: 'Submit BOM', placement: 'left' }"
          class="button"
        >
          <i class="icon-bomsubmit has-text-link"></i>
        </button>
        <span
          v-else-if="hasNotPurchasedItems(bom?.card)"
          class="is-flex is-justify-content-center is-align-items-center"
          v-tooltip="{ content: 'Some Items are Not Purchased', placement: 'left' }"
        >
          <i class="icon-bomsubmit has-text-grey-dark"></i>
        </span>
        <span
          v-else
          class="is-flex is-justify-content-center is-align-items-center"
          v-tooltip="{ content: 'Submitted', placement: 'left' }"
        >
          <i class="icon-bomsubmit has-text-grey-dark"></i>
        </span>
        <a
          @click.prevent="openMaterial(bom)"
          class="is-size-3 has-text-weight-normal line-height text-overflow"
        >
          {{ bom.name }}
        </a>
      </div>
      <div class="is-hidden">
        <button
          class="button p-0 m-0 is-outlined"
          v-if="!getIsUnlinkDisabled(bom)"
          @click="unlinkMaterial(bom)"
        >
          <i class="icon-removedelete"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import _ from 'lodash';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import MaterialManager from '@/models/MaterialManager';
import Order from '@/models/Orders';
import MoveOrders from '@/components/modals/MoveOrders.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import PermissionHelper from '@/components/mixins/PermissionHelper';

const { isDisabledOnPermission } = PermissionHelper();

export default {
  props: {
    value: Object,
    label: String,
    projectId: String,
    cardId: String,
    items: Array,
    stage: String,
    __t: String,
  },
  setup(props, { emit }) {
    const router = useRouter();

    const methods = {
      disableSubmit(material) {
        const { card } = material;
        if (_.isEmpty(card)) {
          return true;
        }
        return (
          _.get(card, 'items', []).length === 0
          || !_.some(card.items, { stage: 'preparation' })
          || this.hasNotPurchasedItems(card)
          || isDisabledOnPermission(card)
        );
      },
      reloadPage() {
        router.go(0);
      },
      async submitMaterial(material) {
        let cardData = await MaterialManager.getOne({
          cardId: material._id,
          projectId: props.projectId,
        });
        cardData._customStage = 'preparation';
        cardData = _.castArray(cardData);
        ModalProgrammatic.open({
          component: MoveOrders,
          props: {
            type: 'material-edit-preparation',
            cards: cardData,
            isActive: true,
            isMulti: false,
            kind: 'forward',
          },
          trapFocus: true,
          canCancel: false,
          events: {
            'reload-card': () => {
              emit('event', { type: 'refresh-card' });
            },
          },
          rootClass: 'modal-sm',
        });
      },

      async unlinkMaterial(material) {
        const confirmParam = {
          title:
            'Deleting this linked BOM will remove the items from the material List.',
          cbMessage: 'Remove the linked BOM Material orders as well',
          okButton: 'Continue',
          cancelButton: 'Cancel',
          isCheckboxAvailable: true,
          onConfirm: async (checkboxValue) => {
            let ids = _(props.value)
              .filter((item) => item._id.toString() !== material._id.toString())
              .map('_id')
              .value();
            if (this.$_.isEmpty(ids) && props.value.length === 1) {
              ids = [props.value[0]._id];
            }

            await Order.addMaterials(
              { _id: props.cardId, project: { _id: props.projectId } },
              ids,
            );

            const pmOrder = await Order.get({
              projectId: props.projectId,
              orderId: props.cardId,
            });

            ids.forEach(async (id) => {
              const matDetails = _.find(props.value, (item) => item.card.stage === 'preparation');
              matDetails.card.items.forEach((item) => {
                const detail = _.find(pmOrder.catQtyMaps, (cat) => cat.catId === item.catId);
                if (detail?.qtyToShip >= item.quantity) {
                  detail.qtyToShip -= item.quantity;
                } else if (detail?.qtyToConsume >= item.quantity) {
                  detail.qtyToConsume -= item.quantity;
                } else {
                  let remainingQty;
                  remainingQty = item.quantity - detail.qtyToShip;
                  detail.qtyToShip = 0;
                  detail.qtyToConsume -= remainingQty;
                }
              });
            });
            await pmOrder.save();

            if (checkboxValue) {
              await MaterialManager.archive({ _id: material._id });
            }
            emit('event', { type: 'refresh-card' });
          },
        };
        DialogProgrammatic.confirm(confirmParam);
      },

      isMM() {
        return props.__t === 'Materials';
      },

      getMaterialStage(material) {
        if (material.stage && material.stage !== 'mixed') return material.stage;
        return _.get(material, 'card.stage', '');
      },

      getIsUnlinkDisabled(material) {
        return this.getMaterialStage(material) !== 'preparation';
      },

      getCardStage() {
        const stageOrder = ['planning', 'coordination', 'detailing',
          'manufacturing', 'preparation', 'sourcing', 'qa', 'ordering', 'delivery',
          'in-transit', 'complete'];
        for (const stage of stageOrder) {
          if (_.some(props.items, { stage })) return stage;
        }
        if (methods.isMM() && props.stage !== 'rejected' && _.some(props.items, { stage: 'rejected' })) return 'preparation';
        return methods.isMM() ? 'ordering' : 'preparation';
      },

      openMaterial(material) {
        const stage = methods.getMaterialStage(material) === 'mixed' ? methods.getCardStage() : methods.getMaterialStage(material);
        router.push({
          name: 'material-edit',
          params: {
            module: 'material',
            projectId: props.projectId,
            cardId: material._id,
            stage,
          },
        });
      },

      hasNotPurchasedItems(card) {
        return _.some(card?.items, { purchase: false });
      },
    };
    return {
      ...methods,
    };
  },
};
</script>
<style scoped>
div.is-hidden {
  display: block !important;
  visibility: hidden !important;
}
</style>
