<template>
  <div>
    <div class="modal-card create-assembly-part">
      <!-- Modal Header -->
      <header class="modal-card-head">
        <h4 class="modal-card-title" >
           Select Part From Catalog
        </h4>
        <!-- <div class="is-divider-vertical"></div> -->
        <!-- <i class="icon-close is-clickable" @click="$emit('close')"></i> -->
      </header>

      <!-- Modal Body -->
      <div class="modal-card-body has-background-white p-0">

          <!-- Part details -->
          <div class="column">
            <div class="colum">
               <search-bar
                placeholder="Search"
                ref="searchComp"
                :shouldEmit="true"
                @search="getSearchValue"
              ></search-bar>
              <div class="table-container create-assembly-part">
                <mf-table
                  ref="partsTable"
                  :key="refreshKey"
                  :tableProps="tableProps"
                  :loadData="loadData"
                  @checkbox-toggled="updateCheckedRows"
                  :checkedRows="selectedParts"
                ></mf-table>
              </div>
            </div>
           </div>
           <!-- mf table -->
      </div> <!-- END OF MODAL CARD BODY -->

      <!-- Modal Footer -->
        <footer class="">
          <div class="is-size-5 footer-note has-text-white line-height">
            <span class="has-text-weight-bold">Note: </span>
              Assemblies that contain other assemblies cannot be used as parts
          </div>
          <div class="modal-card-foot is-justify-content-flex-end">
            <button class="button is-outlined" @click="$emit('close', {cancel: true})">
                Cancel
            </button>
            <button class="button has-background-black-bis" :disabled="disableCreate" @click="updateAssemblyPart">
                Select
            </button>
          </div>
        </footer>

      <o-loading
        :full-page="true"
        :active="isLoading"
        :can-cancel="true"
      ></o-loading>
    </div>
  </div>
</template>
<script>
import {
  reactive, toRefs, defineAsyncComponent, ref, computed,
} from 'vue';
import Catalogs from '@/models/Catalogs';
import {
  get, isEmpty, set, defaults, remove, concat, find, has,
} from 'lodash';
import uuid from 'uuid/v4';
import tableDefinition from '@/components/table-cols/partsDetailRowTabCols';
import { useToast } from 'vue-toastification';
import SearchBar from '@/components/SearchBar.vue';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default {
  name: 'CreatePartInAssembly',
  props: {
    rowData: Object,
  },
  components: {
    MfTable,
    SearchBar,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const partsTable = ref(null);
    const state = reactive({
      isLoading: false,
      refreshKey: 1,
      selectedParts: [],
      searchToken: '',
      rowData: props.rowData,
    });
    const tableProps = tableDefinition;

    const updateAssemblyPart = async () => {
      state.isLoading = true;
      try {
        const assemblies = remove(state.selectedParts, (part) => part?.__t === 'AssemblyItems');
        state.rowData.assemblies = concat(state.rowData.assemblies, assemblies);
        state.rowData.parts = concat(state.rowData.parts, state.selectedParts);
        const result = await Catalogs.updateAssemblyParts(state.rowData);

        if (!isEmpty(result.failedData)) {
          const errMessage = get(result.failedData[0], '_errorMsg', 'Please Contact ManufactOn Support');
          toast.error(errMessage);
        }
        if (!isEmpty(result.data)) {
          toast.success('Updated parts list.');
        }
      } catch (err) {
        console.log('err', err);
        toast.error(`${err.data.message || err.data.msg || err.data || 'There was some error creating parts for assembly'}`);
      }
      emit('close', { cancel: false });
      state.isLoading = false;
    };

    const loadData = async (params) => {
      // get all parts
      defaults(params, {
        type: 'assemblyAndItems',
        limit: 25,
        page: 1,
        sort: [],
        showItems: 'show-active-catalogs',
        showAssmbsParts: true,
        parentAssemblyId: props.rowData._id,
        parentCatId: props.rowData.catId,
      });
      params.search = state.searchToken;
      const { data: parts } = await Catalogs.getAssemblyParts(params);
      const listOfPartsAndAssemblies = concat(state.rowData.parts, state.rowData.assemblies);
      parts.forEach((part) => {
        part.isAlreadyPresent = find(listOfPartsAndAssemblies, { catId: part.catId });
        part.isAlreadySubAssembly = has(part, 'assemblies') && part.assemblies.length;
        if (!part.quantity) {
          part.quantity = '';
        }
      });
      return { data: parts };
    };

    const updateCheckedRows = (rows) => {
      rows.forEach((row) => {
        if (row.quantity <= 0) {
          row.quantity = 1;
        }
        row.uid = uuid();
        row.parentAssemblyId = props.rowData._id;
        row.parentCatId = props.rowData.catId;
        row.isPartRow = true;
      });

      state.selectedParts = rows;
    };

    const emitRefreshParams = () => {
      state.refreshKey += 1;
    };

    const getSearchValue = (searchValue) => {
      state.searchToken = searchValue;
      emitRefreshParams();
    };

    const disableCreate = computed(() => {
      if (isEmpty(state.selectedParts) || state.selectedParts.length === 0) {
        return true;
      }
      return false;
    });

    return {
      ...toRefs(state),
      updateAssemblyPart,
      emitRefreshParams,
      tableProps,
      loadData,
      updateCheckedRows,
      partsTable,
      getSearchValue,
      isEmpty,
      set,
      disableCreate,
    };
  },
};
</script>
<style scoped>
.mt-4 {
  margin-top: 16px !important;
}
</style>
