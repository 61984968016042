export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: false,
  stickeyHeaders: true,
  rearrangeCols: false,
  detailRowComponent: 'mf-detail-row',
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      tooltip: 'Customize Columns',
      isLine: true,
    }],
  },
  permissionModule: (screen) => (screen === 'planning' ? 'prefabs' : 'production-order'),
  toolBar: {
    // name: for drop down label, label: for action button label
    bulkActions: () => {
      return [{
        name: 'Excel Export', event: 'excelExport', label: 'Excel Export',
      }];
    },
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: () => {
     return [];
    },
  },
  fields: [
    {
      title: 'Form',
      name: 'field-generic-input',
      sortField: 'templateName',
      prop: 'templateName',
      width: '400px',
      id: 'name',
      sticky: true,
      isClickable: false,
      eventType: 'openForm',
      defaultVisible: true,
      alwaysVisible: true,
      searchField: false,
      searchable: true,
      bulkActionButtonField: true,
      inputProps: {
        type: 'text',
      },
    },
    {
      title: 'Complete',
      name: 'field-generic-input',
      id: 'complete',
      width: '112px',
      classRight: 'is-pulled-right',
      defaultVisible: true,
      prop: 'complete',
      sortField: 'complete',
      inputProps: {
        type: 'text',
      },
    },
    {
      title: 'Incomplete',
      name: 'field-generic-input',
      id: 'incomplete',
      width: '112px',
      defaultVisible: true,
      prop: 'inComplete',
      sortField: 'inComplete',
      inputProps: {
        type: 'text',
      },
    },
    {
      title: 'Total',
      name: 'field-generic-input',
      id: 'total',
      width: '112px',
      defaultVisible: true,
      sortField: 'total',
      prop: 'total',
      inputProps: {
        type: 'text',
      },
    },
    {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      hideFromChooser: true,
      alwaysVisible: true,
      sticky: true,
      width: '112px',
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      inputProps: {
        showEdit: false,
        groupButtons(rowData) {
          return [
            {
              icon: 'icon-exportexcel',
              event: 'excel-export',
              tooltip: 'Export to Excel',
              disabled: rowData.complete < 1,
            },
            {
              icon: 'icon-print',
              event: 'print-form',
              tooltip: 'Print',
              disabled: rowData.complete < 1,
            },
          ];
        },
      },
    },
  ],
};
