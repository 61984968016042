const errorOptions = {
  damage: 'Damage',
  missing: 'Missing',
  notToSpec: 'Not to spec',
};
const fields = {
  delivered: [
    {
      name: 'field-generic-input',
      width: '400px',
      title: 'Name',
      prop: 'name',
      id: 'name',
      sticky: false,
      searchable: false,
    }, {
      name: 'field-generic-input',
      width: '152px',
      title: 'Catalog ID',
      prop: 'catId',
      id: 'catId',
      sticky: false,
      searchable: false,
    },
    {
      name: 'field-generic-input',
      width: '296px',
      prop: 'orderName',
      title: 'Source Order',
      id: 'desc',
    },
    {
      name: 'actualy-qty',
      prop: 'underDeliver',
      width: '64px',
      title: 'QTY',
      id: 'quantity',
    },
  ],
  underDelivered: [
    {
      name: 'field-generic-input',
      width: '400px',
      title: 'Item Name',
      prop: 'name',
      id: 'name',
      sticky: false,
      searchable: false,
    }, {
      name: 'field-cat-id',
      prop: 'catId',
      width: '152px',
      title: 'Catalog ID',
      id: 'catId',
    }, {
      name: 'field-generic-input',
      width: '296px',
      prop: 'orderName',
      title: 'Source Order',
      id: 'desc',
    },
    // {
    //   name: '',
    //   width: '296px',
    //   title: 'Issue',
    //   id: 'issue',
    //   formatter(item) {
    //     return errorOptions[item.issueNote] || '';
    //   },
    // },
    {
      name: 'field-generic-input',
      prop: 'underDeliver',
      width: '64px',
      title: 'QTY',
      id: 'quantity',
    },
  ],
  nonDelivered: [
    {
      name: 'field-generic-input',
      width: '400px',
      title: 'Item Name',
      prop: 'name',
      id: 'name',
      sticky: false,
      searchable: false,
    }, {
      name: 'field-cat-id',
      prop: 'catId',
      width: '152px',
      title: 'Catalog ID',
      id: 'catId',
    }, {
      name: 'field-generic-input',
      width: '296px',
      prop: 'orderName',
      title: 'Source Order',
      id: 'desc',
    },
    {
      name: 'qty-needed',
      prop: 'quantity',
      width: '64px',
      title: 'QTY',
      id: 'quantity',
    },
  ],
};
export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  hasCheckBox: true,
  isCheckable: true,
  isDetailed: false,
  stickyHeaders: false,
  customRowKey: 'uid',
  toolBar: false,
  cols: fields,
};
