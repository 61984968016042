import {
  reactive, toRefs,
} from 'vue';
import _ from 'lodash';
import Shipping from '@/models/Shipping';

export default function () {
  const state = reactive({
  });
  const getCatQTyMap = (item) => {
    const predicate = {};
    if (_.isEmpty(item.catId)) {
      predicate._id = item._id;
    } else {
      predicate.catId = item.catId;
    }
    const { catQtyMaps } = item;
    const catQtyMap = _.find(catQtyMaps, predicate);
    return catQtyMap;
  };
  const updateItemQty = (catQtyMap, item) => {
    catQtyMap.total = item.quantity;
    if(item.qtyReserved) {
      if(item.qtyReserved < (catQtyMap.qtyToConsume + catQtyMap.qtyToShip))  
        catQtyMap.qtyToReturn = 0;
      else
        catQtyMap.qtyToReturn = item.qtyReserved - (catQtyMap.qtyToConsume + catQtyMap.qtyToShip);
      item.isDirty = true;
    }
  };
  const changeMaterialItem = (params) => { 
    const { type, item, isCutOrder } = params;
    const catQtyMap = isCutOrder ? item :getCatQTyMap(item);
    catQtyMap.isDirty = true;
    if (type === 'qtyToConsume') {
      catQtyMap.qtyToConsume = isCutOrder ? item.qtyToConsume : item.qtyToConsume - item.qtyConsumed;
      updateItemQty(catQtyMap, item);
    } else if (type === 'qtyToShip') {
      catQtyMap[type] = item[type];
      if (catQtyMap.qtyToConsume === '') {
        catQtyMap.qtyToConsume = 0;
      }
      if (catQtyMap.qtyToShip === '') {
        catQtyMap.qtyToShip = 0;
      }
      updateItemQty(catQtyMap, item);
    } else {
      // eslint-disable-next-line no-multi-assign
      catQtyMap[type] = item[type] = item[`${type}Old`];
    }
  };
  const validateQTy = (params) => {
    const { type, item, isCutOrder } = params;
    const otherType = (type === 'qtyToConsume') ? 'qtyToShip' : 'qtyToConsume';
    const catQtyMap = isCutOrder ? item : getCatQTyMap(item);
    if (!_.isEmpty(catQtyMap)
      && (catQtyMap[type] + catQtyMap[otherType] !== item.quantity)
      && item.purpose === 'kit') {
      const val = item.quantity - catQtyMap[type];
      item[otherType] = val;
      catQtyMap[otherType] = val;
      updateItemQty(catQtyMap, item);
    }
  };
  const calculateMax = (item, type) => {
    if (item.purpose === 'assembly') {
      return 9999;
    }
    const { catQtyMaps } = item;
    const predicate = {};
    if (_.isEmpty(item.catId)) {
      predicate._id = item._id;
    } else {
      predicate.catId = item.catId;
    }
    const catQtyMap = _.find(catQtyMaps, predicate);
    if (_.isEmpty(catQtyMap)) { return 0; }
    if (type === 'qtyToConsume') {
      return item.quantity + item.qtyConsumed - catQtyMap.qtyToShip;
    }
    return item.quantity - catQtyMap.qtyToConsume;
  };

  const consumeAndUnreserve = async (params) => {
    try {
      await Shipping.consumeAndUnreserve(params);
    } catch (e) {
      console.log('Error', e);
      throw e;
    }
    return true;
  };

  return {
    ...toRefs(state),
    getCatQTyMap,
    updateItemQty,
    changeMaterialItem,
    validateQTy,
    calculateMax,
    consumeAndUnreserve,

  };
}
