<template>
  <h4 class="title is-capitalized">
    {{label}} ({{ purchaseOrders.length }})
  </h4>
  <div
    v-for="purchaseOrder in purchaseOrders"
    :key="purchaseOrder._id"
    class="column pt-0 pl-0"
  >
    <a @click.prevent="openShippingOrder(purchaseOrder._id)" class="is-size-3">
      {{ purchaseOrder.name }}
    </a>
  </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs, onMounted,
} from 'vue';
import Shipping from '@/models/Shipping';
import { useRouter } from 'vue-router';
import { isEmpty } from 'lodash';

export default defineComponent({
  name: 'LinkedShippingOrders',
  props: ['value', 'label', 'projectId'],
  setup(props) {
    const router = useRouter();
    const state = reactive({
      purchaseOrders: [],
    });
    onMounted(async () => {
      const params = {};
      params.projectId = props.projectId;
      params.orderId = props.value;
      // code here and check
      if (!isEmpty(props.value)) {
        state.purchaseOrders = (await Shipping.getPurchaseShippingOrders(params)).data;
      }
    });
    const openShippingOrder = (o) => {
      router.push({
        name: 'shipping-edit',
        params: { projectId: props.projectId, cardId: o },
      });
    };
    return {
      ...toRefs(state),
      onMounted,
      openShippingOrder,
    };
  },
});
</script>
