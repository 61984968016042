<template>
  <div class="field">
    <div class="field-label">
      <label class="has-text-black-bis is-italic is-size-3" v-if="label"
        :class="labelPosition === 'is-left' ? 'line-height' : 'label'">
        {{ label }}
      </label>
    </div>
    <div class="field-body">
      <div class="is-flex has-text-black-bis" v-if="isBomCreated  && !isCutOrder">
        <span class="icon-checkmark mr-2"></span>
        <span class="line-height">All Available BOMs Created</span>
      </div>
      <div v-else>
        <o-checkbox class="p-0"
          v-model="create"
          v-if="!isCutOrder">
          <span class="has-text-black-bis">
            Create BOMs for Assembly Items
          </span>
        </o-checkbox>
      </div>
      <div class="is-flex has-text-black-bis" v-if="isBomSubmitted && !isCutOrder">
        <span class="icon-checkmark mr-2"></span>
        <span class="line-height">All Available BOMs Submitted</span>
      </div>
      <o-checkbox v-else class="p-0"
        v-model="submit"
        :disabled="isSubmitDisabled"
        v-tooltip="{ content: isSubmitDisabled ? 'Not all Items are Purchased' : '' }"
        v-if="!isCutOrder"
      >
        <span class="has-text-black-bis">
          Submit Assembly Item BOMs
        </span>
      </o-checkbox>
      <o-checkbox class="p-0 reserve-checkbox" v-if="isCutOrder && !hasNestedInvLoc"
        v-model="reserveMaterials">
        <span class="has-text-black-bis">
          Reserve Available Materials (Materials not reserved now can be reserved in Manufacturing)
        </span>
      </o-checkbox>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default ({
  props: {
    value: Object,
    label: String,
    isBomCreated: Boolean,
    isBomSubmitted: Boolean,
    isSubmitDisabled: Boolean,
    isCutOrder: Boolean,
    rowData: Object,
  },
  setup(props, { emit }) {
    const store = useStore();
    const submit = computed({
      get: () => props.value.submit,
      set: (val) => {
        emit('update:value', { val, type: 'submit' });
      },
    });
    const create = computed({
      get: () => props.value.create,
      set: (val) => {
        emit('update:value', { val, type: 'create' });
      },
    });
    const reserveMaterials = computed({
      get: () => props.value.reserveMaterials,
      set: (val) => {
        emit('update:value', { val, type: 'reserveMaterials' });
      },
    });

    const hasNestedInvLoc = computed(() => {
      let cardProject = store.getters.findProject({ _id: props.rowData.project._id });
      return cardProject.hasNesetedProjectInvLoc(store.state.userData);
    });

    return {
      create,
      submit,
      reserveMaterials,
      hasNestedInvLoc,
    };
  },
});
</script>
