<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <h4 class="modal-card-title">
                Select Locations
            </h4>
            <div class="is-divider-vertical"></div>
        </header>
        <section class="modal-card-body is-size-2 is-marginless">
            <div>
                <span class="label is-italic line-height">Selected : {{totalLocation ? 'All locations' : selectedLocs}}</span>
            </div>
            <div class="table-container">
                <mf-table
                        :tableProps="tableProps"
                        :loadData="loadData"
                        :hideGutter="true"
                        :api-mode="false"
                        :key="data.length"
                        ref="locationTable"
                        tableClass="inventory-location"
                        @checkbox-toggled="selectedRows"
                        @row-select="selectLocationtostock($event)"
                        :checkedRows="allSelectedLocations"
                >
                    <template v-slot:name="{ rowData: location, rowIndex: index }">
                        <div class="child-inventory-loc" :style="{width: `${(location.level - 1) * 16}px`}"></div>
                        <div class="is-flex is-align-items-center">
                        <i :class="[location.children.length ? 'icon-disclosearrow' : '',
                            location.isDetailRowEnabled ? 'fa-rotate-270 icon-disclosearrow' : '']"
                            @click="getChildLocations(location, index)"
                            :style="{marginLeft: `${(location.level - 1) * 16}px`}"></i>
                             <span>{{location.name}}</span>
                      </div>
                    </template>
                </mf-table>
            </div>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
            <button class="button is-outlined" true type="button" @click="cancel()">
                Cancel
            </button>
            <button
                    class="button has-background-black-bis"
                    type="button"
                    :disabled="isDisabled"
                    @click.once="setLocationFilter()"
            >
                Done
            </button>
        </footer>
        <o-loading
                :full-page="false"
                :active="isLoading"
                :can-cancel="true"
        ></o-loading>
    </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs, onMounted, computed,
} from 'vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import tableDefinition from '@/components/table-cols/locationFields';
import Locations from '@/models/Locations';
import { useStore } from 'vuex';
import _, { some } from 'lodash';

export default defineComponent({
  name: 'AddLocation',
  components: {
    MfTable,
  },
  props: {
    for: String,
    onlyNestedLocs: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const tableProps = tableDefinition[props.for];
    const store = useStore();
    const state = reactive({
      data: [],
      allCompLocationsSelected: false,
      allSelectedLocations: [],
      isLoading: false,
      selectedLocation: {},
      selectAll: false,
      locationForTransferRequest: null,
      checkedRows: [],
    });
    const isDisabled = computed(() => {
      if (props.for === 'filter' && (!some(Object.values(state.allSelectedLocations), '_id'))) {
        return true;
      }
      if (props.for === 'transferRequest' && !state.locationForTransferRequest) {
        return true;
      }
      return false;
    });
    const getAllLocations = async () => {
      state.isLoading = true;
      if (props.for === 'transferRequest') {
        const { data } = await Locations.allCompanyLocations({
          companyId: store.state.companyData._id,
          type: 'company_location',
        });
        state.data = [_.find(
          data,
          { nestedLocation: true },
        )];
      } else {
        const allProjects = store.state.queryParams._projects;
        const projectIds = allProjects.map((project) => project._id);
        state.data = await Locations.allLocationForUser({
          projectIds,
          // companyIds: _.compact(_.map(this.allCompanies, '_id')),
        }, true);

        // state.data = props.onlyNestedLocs ? _.filter(allLocns,
        // (loc) => loc.nestedLocation) : allLocns;
        const selectedLocsIds = store.getters.selectedIdsForKey('selectedCompanyLocations');
        _.forEach(state.data, (val) => {
          _.set(val, 'selected', _.includes(selectedLocsIds, _.get(val, '_id', '')));
          if (_.get(val, 'selected', false)) state.allSelectedLocations.push(val);
        });
      }
      state.isLoading = false;
    };
    const removeChildLocs = async (parent, idx) => {
      const removingLocs = [];
      state.data.forEach((d) => {
        const locParents = d.allParents;
        if (!_.isEmpty(locParents) && locParents.includes(parent._id)) {
          removingLocs.push(d);
        }
      });
      if (removingLocs.length) {
        removingLocs.forEach((r) => {
          state.data.splice(_.findIndex(state.data, { _id: r._id }), 1);
        });
      }
      state.data[idx].isDetailRowEnabled = false;
    };
    const getChildLocations = async (parent, idx) => {
      state.isLoading = true;
      if (!parent.isDetailRowEnabled) {
        const { data } = await Locations.getChildNodes({
          page: 1,
          limit: 9999,
          parent: parent._id,
          level: parent.level + 1,
        });
        state.data[idx].isDetailRowEnabled = true;
        state.data.splice(idx + 1, 0, ...data);
        const selectedLocsIds = store.getters.selectedIdsForKey('selectedCompanyLocations');
        _.forEach(data, (val) => {
          _.set(val, 'selected', _.includes(selectedLocsIds, _.get(val, '_id', '')));
          if (_.get(val, 'selected', false)) state.allSelectedLocations.push(val);
        });
      } else {
        removeChildLocs(parent, idx);
      }
      state.isLoading = false;
    };
    const toggleOrder = (location) => {
      const locId = location._id;
      let val;
      if (_.isEmpty(state.selectedLocation[locId])) {
        val = { value: true };
      } else {
        val = { value: !state.selectedLocation[locId].value };
      }
      _.set(state.selectedLocation, locId, val);
      state.allSelectedLocations.push(location);
    };
    const loadData = () => state.data;

    const setLocationFilter = () => {
      if (props.for === 'transferRequest') {
        emit('select-location', state.locationForTransferRequest);
        emit('close');
        return;
      }
      const locGroups = _.filter(
        Locations.groupLocations(state.allSelectedLocations, store.state.companyData),
        (group) => group.val && group.val.length > 0,
      );

      const allLocs = _(locGroups).map('val').flatten().value();
      store.commit('setAllLocationsLength', allLocs.length);
      store.commit('setQueryParams', { selectedCompanyLocationGroup: locGroups[0].key });
      store.commit('setQueryParams', { selectedCompanyLocations: allLocs });
      store.commit('setQueryParams', { selectedOldCompanyLocations: allLocs });

      emit('refresh-table');
      emit('close');
      emit('location-count');
    };
    const selectedRows = (rows) => {
      state.selectAll = rows.length === state.data.length;
      state.allSelectedLocations = rows;
    };

    onMounted(async () => {
      await getAllLocations();
    });

    const selectLocationtostock = (e) => {
      state.locationForTransferRequest = e;
    };

    const cancel = () => {
      emit('close');
    };

    const selectedLocs = computed(() => _.map(state.allSelectedLocations, 'name').join(', '));

    const totalLocation = computed(() => state.allSelectedLocations.length === state.data.length);

    return {
      getAllLocations,
      ...toRefs(state),
      tableProps,
      loadData,
      cancel,
      getChildLocations,
      toggleOrder,
      setLocationFilter,
      isDisabled,
      selectedRows,
      selectLocationtostock,
      selectedLocs,
      totalLocation,
    };
  },
});
</script>
<style scoped>
    ::v-deep(.o-table tbody) {
        min-height: 385px !important;
        max-height: 385px !important;
    }
</style>
