<template>
  <div>
    <div>
      <span class="is-clickable">
        <button
          v-if="!isDisabled"
          class="button px-0"
          v-tooltip="'Add New'"
        >
          <i
            class="icon-addnew"
            @click="isOpen = !isOpen"
          ></i>
        </button>
      </span>
    </div>
    <o-modal :active="isOpen" :canCancel="false" class="modal-sm">
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="modal-card has-text-dark has-text-weight-bold">Add Document(s)</div>
        </header>
        <section class="modal-card-body is-visible  has-background-white">
          <div v-if="!isTemplateProject">
            <div class="is-align-items-center is-justify-content-center">
              <div class="">
                <mf-file-picker
                  :isDragAndDrop="true"
                  :allPrivate="allPrivate"
                  @onInput="fileUploaded"
                  @dragAndDropFiles="onFilesDragAndDrop"
                  ref="filePicker"
                  v-bind="getAttrs"
                  :isQaQc="isQaQc || hidePrivate"
                >
                </mf-file-picker>
              </div>
            </div>
          </div>
          <div class="column pl-0">
            <div class="columns">
              <div class="column">
                <h3 class="is-pulled-left is-size-3 is-italic has-text-black-bis has-text-weight-semibold mr-2">
                  Add URL/Link
                </h3>
                <span
                  class="is-size-6 is-italic has-text-weight-normal has-text-grey-darker"
                >
                  (Include http:// or https://)
                </span>
              </div>
              <div class="column">
                <h3 class="has-text-black-bis is-size-3 has-text-weight-semibold">Display Name</h3>
              </div>
            </div>
          </div>
          <div class="is-divider my-0"></div>
          <div class="column mb-2 mt-1 pl-0">
            <div class="columns mb-1">
              <div class="column">
                <div class="columns">
                  <div class="column is-1" v-if="!isQaQc && !hidePrivate">
                    <o-checkbox
                  class="pt-1"
                  v-model="isURLPrivate"
                ></o-checkbox>
                  </div>
                  <div class="column">
                    <div class="field">
                      <div class="control has-icons-right">
                        <input
                          class="input pl-1"
                          type="url"
                          placeholder="Enter URL/Link"
                          v-model="url"
                          pattern="https?://.+"
                          @input="resetWarning"
                        />
                        <span
                          class="icon is-small is-right"
                          v-if="warn?.type === 'url'"
                        >
                          <i
                            class="fas fa-exclamation-triangle has-text-danger"
                          ></i>
                        </span>
                      </div>
                       <div class="has-text-danger is-size-5 " v-if="!$_.isEmpty(warn) && warn?.type === 'url'">
                          <span class="line-height">{{ warn.message }}</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <div class="control has-icons-right">
                        <input
                          class="input pl-1"
                          type="text"
                          v-model="name"
                          @input="resetWarning"
                        />
                        <div class="has-text-danger" v-if="!$_.isEmpty(warn) && warn?.type === 'name'">
                          <span class="line-height">{{ warn.message }}</span>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <button class="button px-0">
                      <i class="icon-close"  v-if="name.length || url.length" aria-label="more options"
                          @click="ClearName"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showForm">
            <div class="column pl-0">
              <div class="columns">
                <div class="column">
                  <h3 class="is-pulled-left is-size-3 is-italic has-text-black-bis has-text-weight-semibold">
                    Add Manufacton Form
                  </h3>
                </div>
              </div>
            </div>
            <div class="is-divider my-0"></div>
            <div class="column mb-2 mt-1 pl-0">
              <div class="columns">
               <div class="column">
                 <div class="columns is-flex is-align-items-center">
                   <div class="column is-1"><o-checkbox v-model="isFBPrivate" class=""></o-checkbox></div>
                   <div class="column">
                     <mf-multi-select
                       v-model="formBuilder"
                       :disabled="false"
                       :options="formBuilderList"
                       placeholder="Select Form"
                       :multiple="false"
                       @select="attachForm"
                       label="templateName"
                       track-by="_id"
                       :allow-empty="false"
                     >
                     </mf-multi-select>
                   </div>
                 </div>
               </div>
               <div class="column has-text-right">
                 <button class="button px-0"> 
                   <i class="icon-close" v-if="formBuilder" aria-label="more options"
                   @click="ClearTemplate"></i>
                 </button>
               </div>
              
             </div>
            </div>
          </div>
        </section>
        <footer
          class="modal-card-foot is-justify-content-flex-end is-align-items-center"
        >
          <div class="buttons">
            <button
              class="button is-outlined has-text-weight-bold"
              @click="resetAll"
            >
              Cancel
            </button>
            <button
              class="button has-text-weight-bold has-background-black-bis has-text-white"
              @click="addDocument"
            >
              Add Document(s)
            </button>
          </div>
        </footer>
      </div>
      <o-loading
        :full-page="false"
        :active="isLoading"
        :can-cancel="false"
      ></o-loading>
    </o-modal>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  ref,
  inject,
} from 'vue';
import { isEmpty } from 'lodash';
import Todo from '@/models/Todo';
import MfFilePicker from '@/components/MfFilePicker.vue';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import uuid from 'uuid/v4';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'MfAddFile',
  components: {
    'mf-file-picker': MfFilePicker,
    'mf-multi-select': MfMultiSelect,
  },
  props: {
    projectId: String,
    order: {
      type: Object,
      default: () => {},
    },
    defaultPrivate: {
      type: Boolean,
      default: false,
    },
    showForm: {
      type: Boolean,
      default: false,
    },
    formsList: {
      type: Array,
      default: () => [],
    },
    isDisabled: [Boolean, Function],
    isQaQc: {
      type: Boolean,
      default: false,
    },
    hidePrivate: {
      type: Boolean,
      defaul: false,
    },
  },
  setup(props, { emit }) {
    const { defaultPrivate, formsList, projectId , order} = props;
    const emitter = inject('emitter');
    const toast = useToast();
    const filePicker = ref(null);
    const data = reactive({
      showText: false,
      formBuilder: '',
      formBuilderList: [],
      name: '',
      url: '',
      allPrivate: false,
      warn: '',
      isOpen: false,
      dragAndDropFiles: [],
      allDocs: [],
      isLoading: false,
      isFBPrivate: false,
      isURLPrivate: false,
    });
    const store = useStore();
    // computed
    const isTemplateProject = computed(
      () =>
        // const project = store.getters.findProject({ _id: projectId });
        // if (!isEmpty(project)) {
        //   return project.isTemplateProject;
        // }
        // eslint-disable-next-line implicit-arrow-linebreak
        false
    );

    // methods

    const sendDoc = (docs) => {
      for (const doc of docs) {
        if (doc.type === 'file') {
          doc.visible = data.allPrivate || doc.isPrivate ? 'to-me' : 'to-all';
        } else if (doc.type === 'url') {
          doc.visible =
            data.allPrivate || data.isURLPrivate ? 'to-me' : 'to-all';
        } else if (doc.type === 'form') {
          doc.visible =
            data.allPrivate || data.isFBPrivate ? 'to-me' : 'to-all';
        } else {
          doc.visible = data.allPrivate ? 'to-me' : 'to-all';
        }
        doc.uuid = uuid();
      }
      emit('docs-added', docs);
      emitter.emit('checklist:addedFiles', docs);
    };

    const resetAll = () => {
      data.isOpen = false;
      data.formBuilder = null;
      data.name = '';
      data.url = '';
      data.warn = '';
      data.dragAndDropFiles = [];
      data.allDocs = [];
      data.isFBPrivate = false;
      data.isURLPrivate = false;
      filePicker.value.files = [];
      filePicker.value.resetFiles()
    };

    const fileUploaded = (files, isPrivate) => {
      if (props.isQaQc) {
        data.allDocs.push({
          url: files.fileGuid,
          name: files.name,
          type: 'file',
        });
      } else {
        data.allDocs.push({
          url: files[0].guid,
          name: files[0].name,
          isPrivate: isPrivate || false,
          type: 'file',
        });
      }
    };

    const getFormBuilderList = async () =>
      (await Todo.getAllTemplateForms()).data;

    const attachForm = (selectedOpt) => {
      data.formBuilder = selectedOpt;
    };

    const checkIfUrlNameAlreadyExists = (urlName) => {
      const orderFiles = order?.files || [];
      let duplicateUrlExists = false;
      const existingOrderFileNames = orderFiles.map((file) => file.name);

        if (existingOrderFileNames.includes(urlName)) {
          duplicateUrlExists = true;
        }
        return duplicateUrlExists;
    };

    const addDocument = async () => {
      data.isLoading = true;
      const name = data.name.trim();
      const pattern = /\b(http|https)/;
      const nameLenCheck = name && name.length < 3;
      if (data.url && !pattern.test(data.url)) {
        data.warn = { message: 'Please enter a valid URL.', type: 'url' };
        data.isLoading = false;
        return;
      }
      else if (data.url && !pattern.test(data.url)) {
        data.warn = { message: 'Please enter a valid URL.', type: 'url' };
        data.isLoading = false;
        return;
      } else if (((name && !data.url) ||
        (!name && data.url) ||
        nameLenCheck)) {
        data.warn = {
          message: 'Document name must contain at least 3 characters!',
          type: 'name',
        };
        data.isLoading = false;
        return;
      }
      if (data.url && name) {
        const urlExists = checkIfUrlNameAlreadyExists(name);
        if (urlExists) {
          toast.error('Duplicate URL Found');
          data.isLoading = false;
          return;
        }
        data.allDocs.push({ url: data.url, name, type: 'url' });
      }

      const { dragAndDropFiles } = data;
      if (dragAndDropFiles.length > 0) {
        try {
          await filePicker.value.uploadFile(dragAndDropFiles);
        } catch (e) {
          console.log(e);
          data.isLoading = false;
          return;
        }
      }
      if (data.formBuilder) {
        data.formBuilder.project = projectId;
        data.allDocs.push({
          url: uuid(),
          copiedFrom: data.formBuilder._id,
          name: data.formBuilder.templateName,
          type: 'form',
          formData: data.formBuilder.formData,
        });
      }
     
      sendDoc(data.allDocs);
      data.closeModal = true;
      resetAll();
      data.isLoading = false;
      CardDirtyBus.emit('setCustomDirty');
    };

    const onFilesDragAndDrop = (files) => {
      data.dragAndDropFiles = files;
    };

    const setAllPrivate = (isChecked) => {
      data.isFBPrivate = isChecked;
      data.isURLPrivate = isChecked;
    };

    const resetWarning = () => {
      data.warn = '';
    };

    onMounted(async () => {
      if (isEmpty(formsList)) {
        data.formBuilderList = await getFormBuilderList();
      } else data.formBuilderList = formsList;
    });

    const getAttrs = computed(() => {
      if (props.isQaQc) {
        return {
          path: 'companies/addFormBuilderFile',
          filePath: 'imageUrl',
          param: {
            company: store.state.userData.company,
            type: 'companies',
          },
        };
      }
      const paramsForFileUpload = {
        param: {
          projectId: props.projectId,
          type: 'project',
          order: order,
        }
      }
      return paramsForFileUpload;
    });

    const ClearName = () => {
      data.name = '';
      data.url = '';
      data.warn = '';
    };
    const ClearTemplate = () => {
      data.formBuilder = '';
    };
    return {
      isTemplateProject,
      ...toRefs(data),
      fileUploaded,
      attachForm,
      addDocument,
      filePicker,
      onFilesDragAndDrop,
      resetAll,
      setAllPrivate,
      resetWarning,
      getAttrs,
      ClearName,
      ClearTemplate,
    };
  },
});
</script>

<style scoped></style>
