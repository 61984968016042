export default {
  stickeyHeaders: true,

  fields: [{
    name: 'field-generic-input',
    width: '400px',
    title: 'Items',
    sticky: true,
    prop: 'name',
    id: 'name',
    isDisabled: true,
  }, {
    name: 'field-generic-input',
    width: '152px',
    title: 'Item ID',
    id: 'customId',
    prop: 'customId',
    isDisabled: true,
  }, {
    name: 'clock-hrs',
    width: '112px',
    title: 'Clock Hours',
    id: 'clockHrs',
  }, {
    name: 'clock',
    width: '64px',
    title: 'Clock',
    id: 'clock',
  },
  {
    name: 'workers',
    width: '80px',
    title: 'Workers',
    id: 'workers',
    inputProps: {
      type: 'text',
      disableEdit: true,
      textArea: true,
    },
  }, {
    name: 'field-clock-display',
    width: '112px',
    title: 'Total Hrs',
    prop: 'actualHrs',
    id: 'hours-cal',
    inputProps: {
      type: 'number',
      min: '0',
      step: '1',
      max: '999',
      fieldDirtyRequired: true,
    },
  },
  {
    name: 'itemDone',
    width: '112px',
    title: 'Done',
    prop: 'itemDone',
    id: 'itemDone',
  },
  {
    title: 'Actions',
    name: 'action-field',
    id: 'action',
    sticky: true,
    thAttrs: () => ({ class: 'action-sticky' }),
    tdClass: 'action-sticky',
    width: '112px',
    showDelete: true,
  }],
};
