import _ from 'lodash';
import store from '../../store';

const moveDetailToManuf = {title: 'Move Order To Manufacturing',
  actionBtn: 'Move',
  multiTitle: 'Move Orders To Manufacturing',
  __t: 'ProductionOrder',
  currentStage: 'detailing',
  trackingRuns: false,
  pickLocation: true,
  pickStage: true,
  nextStages: ['manufacturing'],
  pickName: false,
  cardTitle: 'Order Details',
  instantMulti: false,
  moveBasics: {
    basicDetails: [
      {
        label: 'col1',
        // title: 'Order Details',
        fields: (card) => {
          const fields = [
            {
              name: 'field-generic-input',
              label: 'Name',
              prop: 'name',
              isDisabled: true,
              textValue: card.oldName,
            },
            {
              name: 'field-generic-input',
              label: 'Project',
              prop: 'project.name',
              isDisabled: true,
              textValue: card.project.name,
            },
            {
              name: 'field-user-select',
              prop: 'owner.user',
              label: 'Owner',
              titleLabel: 'Owner *',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ];
          return fields;
        },
      },
      {
        label: 'col2',
        title: 'Production Details',
        fields: (card) => {
          const dates = {
            col1: [
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'manufactureBy',
                label: 'Manufacture By *',
                isEdit: true,
                item: card,
                id: 'manufactureBy-date',
                inputProps: {
                  kind: 'manufactureBy',
                  min: 'detailBy',
                  max: ['qaBy', 'deliver'],
                },
              },
            ],
            col2: [],
          };
          const project = store.getters.findProject({ _id: card.project._id });
          if (card._createTR && project.hasNesetedProjectInvLoc(store.state.userData)) {
            const picReqDate = {
              name: 'field-date',
              defaultVisible: false,
              label: 'Deliver Materials By',
              isEdit: true,
              id: 'deliverBy-date',
              item: card,
              inputProps: {
                kind: 'pickReqOnSite',
                max: ['qaBy', 'deliver'],
                min: 'detailBy',
              },
            };
            dates.col2.push(picReqDate);
          }
          const fields = [
            {
              name: 'field-location-select',
              prop: 'location',
              titleLabel: 'Production Location *',
              rowData: card,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            }, {
              name: 'field-dates-move-modal',
              cardProps: {
                dates,
              },
            }, {
              name: 'field-user-select',
              prop: '_alsoNotify',
              titleLabel: 'Notify',
              id: 'notifyUser',
              isMulti: true,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ];
          if (!card.isCutOrder) {
            fields.splice(2, 0, {
              name: 'field-date',
              defaultVisible: false,
              kind: 'dates',
              prop: 'deliver',
              isEdit: true,
              label: 'Onsite',
              item: card,
              id: 'deliver',
              inputProps: {
                disableEdit: false,
                kind: 'deliver',
                min: ['coord', 'detailBy', 'manufactureBy', 'qaBy'],
              },
            })
          }
          return fields;
        },
      },
      {
        label: 'col3',
        key: 'itemsCount',
        title: 'Order Items',
        fields: (card) => {
          const fields = [
            {
              name: 'field-generic-input',
              label: '',
              prop: 'name',
              isDisabled: true,
              textValue: `[${card.numberOfItems}] Prefab Items moving to Manufacturing`,
            },
          ]
          return fields;
        }
      },
      {
        label: 'col4',
        key: 'bomReserve',
        title: 'Order Materials',
        fields: (card) => {
          const fields = [
            {
              name: 'field-bom-modal',
              prop: '_bomTasks',
              cardProps: {
                isBomCreated: '_bomCreated',
                isBomSubmitted: '_bomSubmitted',
                isSubmitDisabled: '_disableSubmit',
                isCutOrder: 'isCutOrder',
              },
            }
          ]
          return fields;
        }
      },
    ],
    multiDetails: [
      {
        label: 'col1',
        // title: 'Order Details *',
        fields: [],
      },
      {
        label: 'col2',
        title: 'Move Order',
        fields: (cards) => {
          const fields = [
            {
              name: 'field-user-select',
              prop: '_alsoNotify',
              titleLabel: 'Notify',
              id: 'notifyUser',
              isMulti: true,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ];
          if (_.some(cards, (card) => !card._bomCreated || card.materials.length)) {
            const isBomCreated = _.every(cards, (card) => card._bomCreated);
            const isBomSubmitted = _.every(cards, (card) => card._bomSubmitted);
            const isSubmitDisabled = _.every(cards, (card) => card._disableSubmit);
            fields.push({
              name: 'field-bom-modal',
              prop: '_bomTasks',
              label: 'Linked Bills of Material (BOMs)',
              titleLabel: 'Linked Bills of Material (BOMs)',
              isBomCreated,
              isBomSubmitted,
              isSubmitDisabled,
            });
          }
          return fields;
        },
      },
    ],
  },
  showTable: true,
  tableBasics: {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    hasCheckBox: true,
    isCheckable: true,
    isDetailed: false,
    rearrangeCols: false,
    stickyHeaders: false,
    toolBar: false,
    fields: [
      {
        title: 'Item Name',
        name: 'field-generic-input',
        width: '50%',
        prop: 'name',
        id: 'name',
      },
      {
        title: 'Item ID',
        name: 'field-generic-input',
        width: '50%',
        id: 'customId',
        prop: 'customId',
      },
    ],
  },
  orderTableBasics: {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    hasCheckBox: true,
    isCheckable: false,
    isDetailed: false,
    rearrangeCols: false,
    stickyHeaders: false,
    toolBar: false,
    fields: [
      {
        title: 'Order Name',
        name: 'field-generic-input',
        width: '45%',
        prop: 'name',
        id: 'name',
      },
      {
        title: 'ID',
        name: 'field-generic-input',
        width: '25%',
        id: 'customId',
        prop: 'financialId',
      },
      {
        name: 'field-date',
        defaultVisible: false,
        kind: 'dates',
        width: '25%',
        prop: 'manufactureBy',
        title: 'Manufacture By *',
        isEdit: true,
        id: 'manufactureBy-date',
        inputProps: {
          kind: 'manufactureBy',
          min: 'detailBy',
          max: ['qaBy', 'deliver'],
        },
      },
      {
        name: 'field-date',
        defaultVisible: false,
        kind: 'dates',
        width: '25%',
        prop: 'deliver',
        isEdit: false,
        title: 'Onsite',
        id: 'deliver',
        inputProps: {
          kind: 'deliver',
          min: 'coord',
        },
      },
    ],
  },
}

export default {
  'material-edit-preparation-forward': {
    title: 'Move Order To Sourcing',
    actionBtn: 'Move',
    multiTitle: 'Move Orders To Sourcing',
    __t: 'Materials',
    currentStage: 'preparation',
    trackingRuns: false,
    pickLocation: true,
    cardTitle: 'Order Details *',
    pickStage: true,
    nextStages: ['sourcing'],
    pickName: false,
    moveBasics: {
      basicDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-generic-input',
                label: 'Name',
                prop: 'oldName',
                type: 'text',
                placeHolder: 'Enter Name',
              },
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                isDisabled: true,
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          title: 'Move Order',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-location-select',
                prop: 'location',
                titleLabel: 'Location *',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                rowData: card,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
                isMulti: true,
                filterText: 'Selected',
              },
            ];
            return fields;
          },
        },
      ],
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          title: 'Move Order',
          fields: [
            {
              name: 'field-location-select',
              prop: 'location',
              titleLabel: 'Location*',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-user-select',
              prop: '_alsoNotify',
              titleLabel: 'Notify',
              id: 'notifyUser',
              isMulti: true,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
    },
    showTable: true,
    tableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: true,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Item Name',
          name: 'field-generic-input',
          width: '45%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Item ID',
          name: 'field-generic-input',
          width: '30%',
          id: 'customId',
          prop: 'customId',
        },
        {
          title: 'Onsite',
          name: 'field-date',
          defaultVisible: true,
          prop: 'deliver',
          kind: 'dates',
          width: '25%',
          isEdit: false,
          inputProps: {
            kind: 'deliver',
            min: ['orderBy', 'available', 'shipBy'],
            disableEdit: false,
          },
        },
      ],
    },
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: true,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '45%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Order ID',
          name: 'field-generic-input',
          width: '25%',
          id: 'customId',
          prop: 'financialId',
        },
        {
          title: 'Onsite',
          name: 'field-date',
          defaultVisible: true,
          prop: 'deliver',
          kind: 'dates',
          width: '25%',
          isEdit: false,
          inputProps: {
            kind: 'deliver',
            min: ['orderBy', 'available', 'shipBy'],
            disableEdit: false,
          },
        },
      ],
    },
  },
  'material-edit-sourcing-forward': {
    title: 'Create Purchase Orders And Shipping Orders',
    actionBtn: 'Create',
    multiTitle: 'Create Purchase Orders And Shipping Orders',
    __t: 'Materials',
    currentStage: 'sourcing',
    trackingRuns: false,
    pickLocation: true,
    pickStage: true,
    nextStages: ['ordering'],
    pickName: false,
    cardTitle: 'Order Details *',
    moveBasics: {
      basicDetails: [],
    },
    showTable: true,
    tableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      isDetailed: false,
      stickeyHeaders: true,
      rearrangeCols: false,
      hasCheckBox: false,
      isCheckable: false,
      isCardView: true,
      toolBar: false,
      fields: [
        {
          title: 'Purchase/Shipping Order Name',
          name: 'field-generic-input',
          width: '400px',
          prop: 'name',
          id: 'name',
          sticky: true,
        },
        {
          title: 'Vendor',
          name: 'field-vendor-select',
          prop: 'baseDelivery.vendor',
          sortField: 'baseDelivery.vendor.name',
          width: '296px',
          id: 'vendor',
          cardProps: {
            isEditing: false,
          },
        },
        {
          title: 'Ship',
          name: 'field-date',
          defaultVisible: true,
          prop: 'shipBy',
          kind: 'dates',
          width: '152px',
          isEdit: true,
          inputProps: {
            kind: 'shipBy',
            min: ['available'],
            max: ['deliver'],
            disableEdit: true,
          },
        },
        {
          title: 'Deliver',
          name: 'field-date',
          defaultVisible: true,
          prop: 'deliver',
          kind: 'dates',
          width: '152px',
          isEdit: true,
          inputProps: {
            kind: 'deliver',
            min: ['shipBy'],
            disableEdit: false,
          },
        },
        {
          title: 'Delivery Destination',
          name: 'field-location-select',
          prop: 'baseDelivery.location',
          sortField: 'baseDelivery.location.name',
          width: '296px',
          id: 'location',
          isEditing: true,
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Recipient',
          name: 'field-user-select',
          prop: 'baseDelivery.recipient',
          sortField: 'baseDelivery.recipient.name',
          width: '296px',
          id: 'recipient',
          isEditing: true,
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          name: 'field-notes-icon',
          title: 'Notes',
          prop: 'notes',
          width: '64px',
          id: 'notes',
          fromMove: true,
          isPurchaseModal: true,
          cardProps: {
            orderId: 'id',
          },
        },
        {
          title: 'Shipping List',
          name: 'field-shipping-list',
          width: '112px',
          prop: 'externalEmails',
        },
      ],
    },
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      isDetailed: false,
      rearrangeCols: false,
      stickeyHeaders: true,
      hasCheckBox: false,
      isCheckable: false,
      isCardView: true,
      toolBar: false,
      fields: [
        {
          title: 'Purchase/Shipping Order Name',
          name: 'field-generic-input',
          width: '300px',
          prop: 'name',
          sticky: true,
          id: 'name',
        },
        {
          title: 'Vendor',
          name: 'field-vendor-select',
          prop: 'baseDelivery.vendor',
          sortField: 'baseDelivery.vendor.name',
          width: '296px',
          id: 'vendor',
          isEditing: false,
          cardProps: {
            isEditing: false,
          },
        },
        {
          title: 'Ship',
          name: 'field-date',
          defaultVisible: true,
          prop: 'shipBy',
          kind: 'dates',
          width: '112px',
          isEdit: true,
          inputProps: {
            kind: 'shipBy',
            max: ['deliver'],
            disableEdit: true,
          },
        },
        {
          title: 'Deliver',
          name: 'field-date',
          defaultVisible: true,
          prop: 'deliver',
          kind: 'dates',
          width: '112px',
          isEdit: true,
          inputProps: {
            kind: 'deliver',
            min: ['shipBy'],
            disableEdit: false,
          },
        },
        {
          title: 'Delivery Destination',
          name: 'field-location-select',
          prop: 'baseDelivery.location',
          sortField: 'baseDelivery.location.name',
          width: '296px',
          id: 'location',
          isEditing: true,
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Recipient',
          name: 'field-user-select',
          prop: 'baseDelivery.recipient',
          sortField: 'baseDelivery.recipient.name',
          width: '296px',
          id: 'recipient',
          isEditing: true,
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Notes',
          name: 'field-notes-icon',
          width: '64px',
          prop: 'notes',
          id: 'notes',
          fromMove: true,
          cardProps: {
            orderId: 'id',
          },
        },
        {
          title: 'Send Shipping List',
          name: 'field-shipping-list',
          width: '96px',
          prop: 'externalEmails',
        },
      ],
    },
  },
  'prefab-edit-forward': {
    title: 'Create Production Order',
    actionBtn: 'Move',
    multiTitle: 'Create Production Orders From Prefab Packages',
    __t: 'Prefabs',
    currentStage: 'planning',
    nextStages: ['coordination'],
    pickName: true,
    pickLocation: false,
    cardTitle: 'Order Details *',
    addFromTemplate: true,
    moveBasics: {
      basicDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            const isOneOrderPerItemTxt = 'Item name will become order name';
            fields = [
              {
                name: 'field-generic-input',
                label: 'Name',
                placeHolder: card.isOneOrderPerItem ? isOneOrderPerItemTxt : 'Production Order Name',
                prop: 'name',
                textValue: card.isOneOrderPerItem ? isOneOrderPerItemTxt : false,
              },
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-generic-input',
                label: 'Source Prefab Package',
                isClickable: false,
                isDisabled: true,
                textValue: card.oldName,
                id: 'source-prefab',
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          title: 'Production Details',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'coord',
                label: 'Coordinate *',
                isEdit: true,
                item: card,
                id: 'coord-date',
                inputProps: {
                  kind: 'coord',
                  max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
                },
              },
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'deliver',
                isEdit: true,
                label: 'Onsite *',
                id: 'deliver',
                item: card,
                inputProps: {
                  kind: 'deliver',
                  min: ['qaBy', 'manufactureBy', 'detailBy', 'coord'],
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                isEditing: true,
                id: 'notifyUser',
                isMulti: true,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                  isEditing: 'isEditing',
                },
              },
            ];
            return fields;
          },
        },
      ],
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          title: 'Production Details',
          fields: [
            {
              name: 'field-user-select',
              prop: '_alsoNotify',
              titleLabel: 'Notify',
              id: 'notifyUser',
              isMulti: true,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
    },
    showTable: true,
    tableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: true,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: true,
      toolBar: false,
      fields: [
        {
          title: 'Item Name',
          name: 'field-generic-input',
          width: '250px',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Item ID',
          name: 'field-generic-input',
          width: '152px',
          id: 'customId',
          prop: 'customId',
        },
        {
          title: 'Coordinate',
          name: 'field-date',
          defaultVisible: true,
          kind: 'dates',
          isEdit: false,
          width: '112px',
          inputProps: {
            kind: 'coord',
            max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
            disableEdit: false,
          },
        },
        {
          title: 'Onsite',
          name: 'field-date',
          defaultVisible: true,
          prop: 'deliver',
          kind: 'dates',
          width: '112px',
          inputProps: {
            kind: 'deliver',
            min: ['orderBy', 'available', 'shipBy'],
            disableEdit: true,
          },
        },
        {
          title: 'Order Template',
          name: 'templateSelect',
          width: '196px',
          prop: 'dataFromTemplate',
          stage: 'planning',
          purpose: 'general',
          inputProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
          },
        },
      ],
    },
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: false,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Order Name *',
          name: 'field-generic-input',
          width: '25%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Order ID',
          name: 'field-generic-input',
          width: '25%',
          id: 'customId',
          prop: 'financialId',
        },
        {
          title: 'Coordinate *',
          name: 'field-date',
          defaultVisible: true,
          kind: 'dates',
          isEdit: false,
          width: '25%',
          inputProps: {
            kind: 'coord',
            max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
            disableEdit: false,
          },
        },
        {
          title: 'OnSite *',
          name: 'field-date',
          defaultVisible: true,
          prop: 'deliver',
          kind: 'dates',
          width: '25%',
          inputProps: {
            kind: 'deliver',
            min: ['orderBy', 'available', 'shipBy'],
            disableEdit: false,
          },
        },
        {
          title: 'Order Template',
          name: 'field-template-select',
          width: '25%',
          prop: 'dataFromTemplate',
          stage: 'planning',
          isVarying: true,
          purpose: 'general',
          inputProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: true,
          },
        },
      ],
    },
  },
  'po-edit-forward': {
    title: 'Move Order To Detailing',
    actionBtn: 'Move',
    multiTitle: 'Move Orders To Detailing',
    __t: 'ProductionOrder',
    currentStage: 'coordination',
    trackingRuns: true,
    pickLocation: true,
    pickStage: true,
    nextStages: ['detailing'],
    cardTitle: 'Order Details *',
    pickName: false,
    moveBasics: {
      basicDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-generic-input',
                label: 'Name',
                prop: 'name',
                isDisabled: true,
                textValue: card.oldName,
              },
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                isDisabled: true,
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            if (!card._bomCreated || card.materials.length) {
              fields.push({
                name: 'field-bom-modal',
                prop: '_bomTasks',
                label: 'Linked Bills of Material (BOMs)',
                titleLabel: 'Linked Bills of Material (BOMs)',
                cardProps: {
                  isBomCreated: '_bomCreated',
                  isBomSubmitted: '_bomSubmitted',
                  isSubmitDisabled: '_disableSubmit',
                },
              });
            }
            return fields;
          },
        },
        {
          label: 'col2',
          title: 'Production Details',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'detailBy',
                label: 'Detail By *',
                isEdit: true,
                id: 'detailBy-date',
                item: card,
                inputProps: {
                  kind: 'detailBy',
                  max: ['manufactureBy', 'qaBy', 'deliver'],
                  min: 'coord',
                },
              },
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'deliver',
                isEdit: true,
                label: 'Onsite *',
                id: 'deliver',
                item: card,
                isModal: true,
                inputProps: {
                  kind: 'deliver',
                  min: ['coord', 'detailBy', 'manufactureBy', 'qaBy'],
                  isDisabled: false,
                },
              },
              {
                name: 'field-location-select',
                prop: 'location',
                titleLabel: 'Production Location *',
                isEditing: true,
                id: 'location',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                isMulti: true,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
      ],
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            const fields = [
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            if (!card._bomCreated || card.materials.length) {
              fields.push({
                name: 'field-bom-modal',
                prop: '_bomTasks',
                label: 'Linked Bills of Material (BOMs)',
                titleLabel: 'Linked Bills of Material (BOMs)',
                cardProps: {
                  isBomCreated: '_bomCreated',
                  isBomSubmitted: '_bomSubmitted',
                  isSubmitDisabled: '_disableSubmit',
                },
              });
            }
            return fields;
          },
        },
        {
          label: 'col2',
          title: 'Production Details',
          fields: [
            {
              name: 'field-location-select',
              prop: 'location',
              titleLabel: 'Production Locations*',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-user-select',
              prop: 'baseDelivery.recipient',
              titleLabel: 'Notify',
              id: 'notifyUser',
              isMulti: true,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
    },
    showTable: true,
    tableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: false,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Item Name',
          name: 'field-generic-input',
          width: '45%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Item ID',
          name: 'field-generic-input',
          width: '45%',
          id: 'customId',
          prop: 'customId',
        },
      ],
    },
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '25%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Order ID',
          name: 'field-generic-input',
          width: '25%',
          id: 'customId',
          prop: 'financialId',
        },
        {
          name: 'field-date',
          defaultVisible: false,
          kind: 'dates',
          prop: 'poDetailBy',
          isEdit: true,
          width: '25%',
          title: 'Detail By',
          id: 'poDetailBy',
          inputProps: {
            type: 'mf-date',
            kind: 'poDetailBy',
            max: ['poManufactureBy', 'poQaBy', 'deliver'],
            min: 'coord',
          },
        },
        {
          name: 'field-date',
          defaultVisible: false,
          kind: 'dates',
          width: '25%',
          prop: 'deliver',
          isEdit: true,
          title: 'Onsite',
          id: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: 'coord',
          },
        },
      ],
    },
  },
  'manager-edit-detailing-forward': {
    ...(moveDetailToManuf)
  },
  'cut-edit-detailing-forward': {
    ...(moveDetailToManuf),
    title: 'Move Cut Order To Manufacturing',
  },
  'manager-edit-manufacturing-forward': {
    title: 'Move Order To QA/QC',
    actionBtn: 'Move',
    multiTitle: 'Move Orders To QA',
    __t: 'ProductionOrder',
    currentStage: 'manufacturing',
    trackingRuns: false,
    pickLocation: true,
    pickStage: true,
    nextStages: ['qa'],
    pickName: false,
    cardTitle: 'Order Details *',
    instantMulti: false,
    moveBasics: {
      basicDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-generic-input',
                label: 'Name',
                prop: 'name',
                isDisabled: true,
                textValue: card.oldName,
              },
              {
                name: 'field-generic-input',
                label: 'Project',
                isDisabled: true,
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          title: 'QA/QC Details',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'qaBy',
                label: 'QA/QC By *',
                isEdit: true,
                id: 'qaBy-date',
                item: card,
                isModal: true,
                inputProps: {
                  kind: 'qaBy',
                  max: ['deliver'],
                  min: 'manufactureBy',
                },
              },
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'deliver',
                isEdit: true,
                label: 'Onsite *',
                item: card,
                id: 'deliver',
                inputProps: {
                  kind: 'deliver',
                  min: ['coord', 'detailBy', 'manufactureBy', 'qaBy'],
                },
              },
              {
                name: 'field-location-select',
                prop: 'location',
                titleLabel: 'QA/QC Location *',
                isEditing: true,
                id: 'location',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                isMulti: true,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
      ],
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: [],
        },
        {
          label: 'col2',
          title: 'Move Order',
          fields: [
            {
              name: 'field-user-select',
              prop: 'baseDelivery.recipient',
              titleLabel: 'Notify',
              id: 'notifyUser',
              isMulti: true,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
    },
    showTable: true,
    tableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: true,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Item Name',
          name: 'field-generic-input',
          width: '45%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Item ID',
          name: 'field-generic-input',
          width: '25%',
          id: 'customId',
          prop: 'customId',
        },
        {
          title: 'Completed',
          name: 'completed',
          width: '25%',
          id: 'completedStatus',
        },
      ],
    },
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: false,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '45%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Order ID',
          name: 'field-generic-input',
          width: '25%',
          id: 'customId',
          prop: 'financialId',
        },
        {
          name: 'field-date',
          defaultVisible: false,
          kind: 'dates',
          width: '25%',
          prop: 'qaBy',
          title: 'QA By *',
          isEdit: true,
          id: 'qaBy-date',
          inputProps: {
            kind: 'qaBy',
            max: ['deliver'],
            min: 'manufactureBy',
          },
        },
        {
          name: 'field-date',
          defaultVisible: false,
          kind: 'dates',
          width: '25%',
          prop: 'deliver',
          isEdit: false,
          title: 'Onsite',
          id: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: 'coord',
          },
        },
      ],
    },
  },
  'manager-edit-detailing-backward': {
    title: 'Move Order Back To Coordination',
    actionBtn: 'Move',
    multiTitle: 'Move Orders Back To Coordination',
    __t: 'ProductionOrder',
    currentStage: 'detailing',
    trackingRuns: false,
    pickLocation: true,
    pickStage: true,
    nextStages: ['coordination'],
    pickName: false,
    instantMulti: true,
    cardTitle: 'Order Details',
    singleConfirmMsg: 'All information added in Production Manager (runs, documents, checklists) will be removed',
    multiConfirmMsg: 'All information added in Production Manager (runs, documents, checklists) will be removed',
    moveBasics: {
      basicDetails: [
        {
          label: 'col1',
          fields: (card) => {
            const fields = [
              {
                name: 'field-generic-input',
                label: 'Name',
                prop: 'name',
                isDisabled: true,
                textValue: card.oldName,
              },
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                isDisabled: true,
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          // title: 'Coordination Details',
          fields: (card) => {
            const fields = [
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'coord',
                label: 'Coordinate By',
                isEdit: true,
                item: card,
                isModal: true,
                id: 'coord-date',
                inputProps: {
                  kind: 'coord',
                  max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
                  isDisabled: true,
                },
              },
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'deliver',
                isEdit: true,
                label: 'Onsite',
                item: card,
                id: 'deliver',
                isModal: true,
                inputProps: {
                  kind: 'deliver',
                  min: 'coord',
                  isDisabled: true,
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                isMulti: true,
                rowData: card,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
      ],
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details',
          fields: [
            {
              name: 'field-user-select',
              prop: 'owner.user',
              label: 'Owner',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col2',
          // title: 'Coordination Details ',
          fields: [
            {
              name: 'field-user-select',
              prop: '_alsoNotify',
              titleLabel: 'Notify',
              id: 'notifyUser',
              isMulti: true,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
    },
    showTable: true,
    tableBasics: {},
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '45%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Order ID',
          name: 'field-generic-input',
          width: '25%',
          id: 'customId',
          prop: 'financialId',
        },
        {
          name: 'field-date',
          kind: 'dates',
          prop: 'coord',
          title: 'Coordinate',
          isEdit: true,
          width: '25%',
          id: 'coord-date',
          inputProps: {
            kind: 'coord',
            max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
          },
        },
        {
          name: 'field-date',
          kind: 'dates',
          prop: 'deliver',
          isEdit: true,
          title: 'Onsite',
          id: 'deliver',
          width: '25%',
          inputProps: {
            kind: 'deliver',
            min: ['orderBy', 'available', 'shipBy', 'coord'],
          },
        },
      ],
    },
  },
  'manager-edit-manufacturing-backward': {
    title: 'Move Order Back To Detailing',
    multiTitle: 'Move Orders Back To Detailing',
    actionBtn: 'Move',
    __t: 'ProductionOrder',
    currentStage: 'manufacturing',
    trackingRuns: false,
    pickLocation: true,
    pickStage: true,
    nextStages: ['detailing'],
    pickName: false,
    instantMulti: true,
    singleConfirmMsg: 'Any materials reservations will be released',
    multiConfirmMsg: '',
    cardTitle: 'Order Details *',
    moveBasics: {
      basicDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            const fields = [
              {
                name: 'field-generic-input',
                label: 'Name',
                prop: 'name',
                isDisabled: true,
                textValue: card.oldName,
              },
              {
                name: 'field-generic-input',
                label: 'Project',
                prop: 'project.name',
                isDisabled: true,
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          title: 'Production Details',
          fields: (card) => {
            const dates = {
              col1: [
                {
                  name: 'field-date',
                  defaultVisible: false,
                  kind: 'dates',
                  prop: 'manufactureBy',
                  label: 'Manufacture By',
                  isEdit: false,
                  item: card,
                  id: 'manufactureBy-date',
                  inputProps: {
                    kind: 'manufactureBy',
                    min: 'detailBy',
                    max: ['qaBy', 'deliver'],
                  },
                },
              ],
              col2: [],
            };
            const fields = [
              {
                name: 'field-dates-move-modal',
                cardProps: {
                  dates,
                },
              },
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'deliver',
                isEdit: false,
                label: 'Onsite',
                item: card,
                id: 'deliver',
                inputProps: {
                  kind: 'deliver',
                  min: 'coord',
                },
              },
              {
                name: 'field-location-select',
                prop: 'location',
                titleLabel: 'Production Location',
                rowData: card,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                isMulti: true,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
      ],
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details',
          fields: [
            {
              name: 'field-user-select',
              prop: 'owner.user',
              label: 'Owner',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col2',
          title: 'Production Details',
          fields: [
            {
              name: 'field-location-select',
              prop: 'location',
              titleLabel: 'Production Locations *',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-user-select',
              prop: '_alsoNotify',
              titleLabel: 'Notify',
              id: 'notifyUser',
              isMulti: true,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
    },
    showTable: true,
    tableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: true,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Item Name',
          name: 'field-generic-input',
          width: '50%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Item ID',
          name: 'field-generic-input',
          width: '50%',
          id: 'customId',
          prop: 'customId',
        },
      ],
    },
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '45%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Order ID',
          name: 'field-generic-input',
          width: '25%',
          id: 'customId',
          prop: 'financialId',
        },
        {
          name: 'field-date',
          defaultVisible: false,
          kind: 'dates',
          prop: 'detailBy',
          isEdit: false,
          width: '25%',
          title: 'Detail By',
          id: 'detailBy',
          inputProps: {
            type: 'mf-date',
            kind: 'detailBy',
            max: ['manufactureBy', 'qaBy', 'deliver'],
            min: 'coord',
          },
        },
        {
          name: 'field-date',
          defaultVisible: false,
          kind: 'dates',
          width: '25%',
          prop: 'deliver',
          isEdit: false,
          title: 'Onsite',
          id: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: 'coord',
          },
        },
      ],
    },
  },
  'qa-edit-backward': {
    title: 'Move Order Back To Manufacturing',
    multiTitle: 'Move Orders Back To Manufacturing',
    actionBtn: 'Move',
    __t: 'ProductionOrder',
    currentStage: 'qa',
    trackingRuns: false,
    pickLocation: true,
    pickStage: true,
    nextStages: ['manufacturing'],
    pickName: false,
    cardTitle: 'Order Details *',
    instantMulti: true,
    moveBasics: {
      basicDetails: [
        {
          label: 'col1',
          // title: 'Order Details *',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-generic-input',
                label: 'Name',
                prop: 'name',
                isDisabled: true,
                textValue: card.oldName,
              },
              {
                name: 'field-generic-input',
                label: 'Project',
                isDisabled: true,
                textValue: card.project.name,
              },
              {
                name: 'field-user-select',
                prop: 'owner.user',
                label: 'Owner',
                titleLabel: 'Owner',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
        {
          label: 'col2',
          title: 'Production Details',
          fields: (card) => {
            let fields = [];
            fields = [
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'manufactureBy',
                label: 'Manufacture By',
                isEdit: true,
                item: card,
                id: 'manufactureBy-date',
                inputProps: {
                  kind: 'manufactureBy',
                  min: 'detailBy',
                  max: ['qaBy', 'deliver'],
                },
              },
              {
                name: 'field-date',
                defaultVisible: false,
                kind: 'dates',
                prop: 'deliver',
                isEdit: false,
                label: 'Onsite *',
                item: card,
                id: 'deliver',
                inputProps: {
                  kind: 'deliver',
                  min: 'coord',
                },
              },
              {
                name: 'field-location-select',
                prop: 'location',
                titleLabel: 'Production Location',
                isEditing: true,
                id: 'location',
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
              {
                name: 'field-user-select',
                prop: '_alsoNotify',
                titleLabel: 'Notify',
                id: 'notifyUser',
                isMulti: true,
                cardProps: {
                  companyId: 'created.by.company._id',
                  projectId: 'project._id',
                },
              },
            ];
            return fields;
          },
        },
      ],
      multiDetails: [
        {
          label: 'col1',
          // title: 'Order Details',
          fields: [
            {
              name: 'field-user-select',
              prop: 'owner.user',
              label: 'Owner',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col2',
          title: 'Production Details',
          fields: [
            {
              name: 'field-location-select',
              prop: 'location',
              titleLabel: 'Location',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-user-select',
              prop: '_alsoNotify',
              titleLabel: 'Notify',
              id: 'notifyUser',
              isMulti: true,
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
    },
    showTable: true,
    tableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: true,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Item Name',
          name: 'field-generic-input',
          width: '55%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Item ID',
          name: 'field-generic-input',
          width: '55%',
          id: 'customId',
          prop: 'customId',
        },
      ],
    },
    orderTableBasics: {
      defaultSortField: 'name',
      defaultSortOrder: 'asc',
      hasCheckBox: true,
      isCheckable: false,
      isDetailed: false,
      rearrangeCols: false,
      stickyHeaders: false,
      toolBar: false,
      fields: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          width: '45%',
          prop: 'name',
          id: 'name',
        },
        {
          title: 'Order ID',
          name: 'field-generic-input',
          width: '25%',
          id: 'customId',
          prop: 'financialId',
        },
        {
          name: 'field-date',
          defaultVisible: false,
          kind: 'dates',
          width: '25%',
          prop: 'manufactureBy',
          title: 'Manufacture By',
          isEdit: true,
          id: 'manufactureBy-date',
          inputProps: {
            kind: 'manufactureBy',
            min: 'detailBy',
            max: ['qaBy', 'deliver'],
          },
        },
        {
          name: 'field-date',
          defaultVisible: false,
          kind: 'dates',
          width: '25%',
          prop: 'deliver',
          isEdit: false,
          title: 'Onsite',
          id: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: 'coord',
          },
        },
      ],
    },
  },
};
