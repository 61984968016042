import { BaseOrder } from './BaseOrder';
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async get(params) {
    try {
      const { data } = await req.get(`orders/get/${params.orderId}`, {
        params: { projectId: params.projectId },
      });
      return new BaseOrder(data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async create(card) {
    try {
      const { data: order } = await req.post('/move/moveToPO', card, {
        params: { projectId: card.project._id },
      });
      return order;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async update(card) {
    let order = {};
    try {
      if (card.updateAfterCreation) {
        order = await req.post(`move/updatePO/${card._id}`, card, {
          params: { projectId: card.project._id },
        });
      } else {
        order = await req.post(`orders/update/${card._id}`, card, {
          params: { projectId: card.project._id },
        });
      }
      return order.data;
    } catch (e) {
      // TODO: Add an alert here?
      console.log('error in updating order', e);
      const err = e.response ? e.response : e;
      throw err;
    }
  },
  async addMaterials(card, materialIds) {
    const obj = {
      materialIds,
    };
    try {
      const { data: order } = await req.post(`orders/addMaterials/${card._id}`, obj, {
        params: { projectId: card.project._id },
      });
      return order;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e.response;
    }
  },
  async archive(card, type = 'return', bomArchive = false) {
    try {
      const { data: order } = await req.post(`orders/archive/${card._id}/${type}/${bomArchive}`, {}, {
        params: { projectId: card.project._id },
      });
      return order;
    } catch (e) {
      throw e;
    }
  },
  async combine(card) {
    try {
      const { data: order } = await req.post('orders/merge', card);
      return order;
    } catch (e) {
      throw e;
    }
  },
  async split(card) {
    try {
      const { data: order } = await req.post(`orders/split/${card._id}`, card, {
        params: { id: card._id },
      });
      return order;
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  async hasReservedItems(card) {
    try {
      const { data: result } = await req.get(`orders/hasReservedItems/${card._id}`, {
        params: { projectId: card.project._id },
      });
      return result.reservedFlag;
    } catch (e) {
      throw e;
    }
  },
};
