<template>
  <div>
      <mf-table
          ref="invalidListTable"
          :tableProps="tableCols"
          :hideGutter="true"
          :apiMode="false"
          :loadData="loadData"
        >
          <template v-slot:error-msg="{ rowData }">
            <span class="is-text-overflow-none pointer has-text-danger has-text-weight-bold"
                :class="{'has-text-success' : rowData._isValid}">
              {{rowData._errorMsg}}
            </span>
          </template>
        </mf-table>
  </div>
</template>

<script>
import {
  defineAsyncComponent, reactive, toRefs, computed,
} from 'vue';
import constants from '@/components/table-cols/InvalidListCols';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default {
  name: 'InvalidList',
  props: ['type', 'data', 'isNestedLocImport'],
  components: {
    MfTable,
  },
  setup(props) {
    const state = reactive({
    });

    const loadData = () => props.data;

    const tableCols = computed(() => {
      const cols = constants[props.type];
      return cols;
    });

    return {
      ...toRefs(state),
      loadData,
      tableCols,
    };
  },
};

</script>

<style scoped>
::v-deep(.o-table tbody) {
  min-height: 250px !important;
  max-height: 200px !important;
}
</style>
