export default {
  ID: {
    field: 'customId',
    multiple: true,
    displayType: 'stringedOptions',
  },
  NAME: {
    field: 'name',
    multiple: true,
    required: true,
    displayType: 'stringedOptions',
  },
  'CATALOG ID': {
    field: 'catId',
    multiple: false,
  },
  QUANTITY: {
    field: 'quantity',
    multiple: false,
  },
  MEASURE: {
    field: 'measure',
    multiple: false,
  },
  'MEASURE UNITS': {
    field: 'measureUnits',
    multiple: false,
  },
  'ORDER BY': {
    field: 'orderBy',
    multiple: false,
  },
  'SHIP BY': {
    field: 'shipBy',
    multiple: false,
  },
  'DOCUMENT NAMES': {
    field: 'docNames',
    multiple: false,
  },
  DOCUMENTS: {
    field: 'documents',
    multiple: false,
  },
  NOTES: {
    field: 'note',
    multiple: false,
  },
  PURCHASE: {
    field: 'purchase',
    multiple: false,
  },
};
