export default {
  RUN: {
    field: 'name',
    multiple: false,
    required: true,
  },
  'START DATE': {
    field: 'startDate',
    multiple: false,
  },
  'FINISH DATE': {
    field: 'finishDate',
    multiple: false,
  },
  OWNER: {
    field: 'owner',
    multiple: false,
  },
  LOCATION: {
    field: 'location',
    multiple: false,
  },
  HOURS: {
    field: 'hours',
    multiple: false,
  },
  MINUTES: {
    field: 'minutes',
    multiple: false,
  },
  SECONDS: {
    field: 'seconds',
    multiple: false,
  },
  'DOCUMENT NAMES': {
    field: 'docNames',
    multiple: false,
  },
  DOCUMENTS: {
    field: 'documents',
    multiple: false,
  },
};
