<template>
  <div class="table">
    <mf-table
      ref="orderChecklist"
      :tableProps="tab.tableProps"
      :apiMode="false"
      :loadData="loadData"
      :hideGutter="true"
      @cell-clicked="onHeaderClicked"
      :isLocallyFiltered="true"
    >
    <template v-slot:status="{ rowData }">
        <span v-if="rowData.isEditing">
          <mf-multi-select v-model="rowData.selectStatus"
                       :options="todoStatus"
                       :closeOnSelect="true"
                       :show-labels="false"
                       :multiple="false"
                       :taggable="false"
                       select-label=""
                       deselect-label=""
                       label="title"
                       :hideSelected="false"
                       @update:modelValue="selectionDone('status', $event, rowData)"
                       class="pendo-checklist-status-select">
          </mf-multi-select>
        </span>
        <span v-else>{{rowData.status}}</span>
    </template>
    <template v-slot:type="{ rowData }">
      <span v-if="rowData.isEditing">
          <mf-multi-select v-model="rowData.selectType"
                       :options="todoTypesCheck"
                       :closeOnSelect="true"
                       :show-labels="false"
                       :disabled="todoType==='pause'"
                       :multiple="false"
                       label="title"
                       :hideSelected="false"
                       @update:modelValue="selectionDone('type', $event, rowData)">
        </mf-multi-select>
        </span>
        <span v-else>{{rowData.type}}</span>
    </template>
    <template v-slot:assignee="{ rowData, rowIndex }">
      <span v-if="rowData.isEditing">
           <mf-multi-select
              v-model="rowData.assignedTo.user"
              :options="getAssignee(rowData)"
              :closeOnSelect="true"
              label="name"
              track-by="_id"
              :multiple="false"
              :hideSelected="false"
              group-values="val" group-label="key"
              @input="updateUserCompany($event, rowIndex)"
              type="grouped"
              class="pendo-checklist-assignee-select">
            </mf-multi-select>
      </span>
      <span v-else>{{rowData.assignedTo.user.name}} ({{rowData.assignedTo.company.name}})</span>
    </template>
    <template v-slot:private="{ rowData, rowField }">
      <span v-if="rowData.isEditing && !rowField.isDisabled">
          <o-checkbox
            v-model="rowData.private"
            @click="onPrivate(rowData)">
          </o-checkbox>
      </span>
      <span v-else-if="rowField.showIcons" class="is-flex is-justify-content-center">
        <i :class="rowData.private ? 'icon-checkmark' : ''"></i>
      </span>
      <span v-else>
          <o-checkbox class="field"
            v-model="rowData.private"
            :disabled="true">
          </o-checkbox>
      </span>
    </template>
    <template v-slot:checklist-docs="{ rowData: checklistRow, rowField }">
      <field-item-docs-icon
        :value="checklistRow.files"
        :rowData="checklistRow"
        :rowField="rowField"
        :type="rowField.for"
        :checkListOrder="rowData"
        >
      </field-item-docs-icon>
    </template>
      <template #taskId="{ rowData }">
        {{ rowData.taskId }}
      </template>
      <template #taskSource="{ rowData }">
        <span class="has-text-link is-clickable" @click="triggerModal(rowData)">
          {{ rowData.form?.name }}
        </span>
      </template>
    </mf-table>
  </div>
  <excel-import
    :card="rowData"
    :tab="tab?.id"
    :isExcelModalActive="isExcelModalActive"
    :dates="dates"
    :batchSize="50"
    :validateExcelItem="validateExcelItem"
    :saveExcelItem="saveExcelItem"
    modalHeaderTitle="import tasks from excel"
    @close-modal="closeModal"
    @refresh-table="refreshTable"
  />
</template>

<script>
import {
  computed, inject, defineComponent, reactive, toRefs, ref, onMounted,
} from 'vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import Users from '@/models/Users';
import Projects from '@/models/Projects';
// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';
import LocalSort from '@/components/utils/LocalSort';
import translator from '@/languages/translator';
import ExcelImport from '@/components/ExcelImport.vue';
import Validation from '@/components/utils/Validations';
import moment from 'moment';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import QaQcmodal from '@/components/modals/QaQcModal.vue';

export default defineComponent({
  name: 'OrderCheckList',
  components: {
    'mf-table': MfTable,
    ExcelImport,
  },
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    rowIndex: Number,
    edit: {
      type: Boolean,
    },
    tab: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: 'edit',
    },
    todoType: String,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      todoStatus: [
        { title: 'Not Started', icon: 'question-circle' },
        { title: 'In Progress', icon: 'chevron-circle-right' },
        { title: 'Inactive', icon: 'exclamation-triangle' },
        { title: 'Complete', icon: 'trophy' },
        { title: 'QC Fail', icon: 'trophy' },
        { title: 'QC Inspect', icon: 'trophy' },
      ],
      todoTypes: [
        { title: 'Planning', value: 'Planning', icon: 'book' },
        { title: 'Coordination', value: 'Coordination', icon: 'icon icon-production-order' },
        { title: 'Detailing', value: 'Detailing', icon: 'edit' },
        { title: 'Manufacturing', value: 'Manufacturing', icon: 'icon icon-contractor' },
        { title: 'Constraint', value: 'Constraint', icon: 'unlink' },
        { title: 'Purchase', value: 'Purchase', icon: 'shopping-cart' },
        { title: 'QA/QC', value: 'Quality Assurance', icon: 'check-square' },
        { title: 'Delivery', value: 'Delivery', icon: 'truck' },
        { title: 'Pause', value: 'Pause', icon: 'pause' },
        { title: 'Material', value: 'Material', icon: 'cubes' },
        { title: 'Complete', value: 'Complete', icon: 'trophy' }],
      pvtGroupedUsers: [],
      allUsers: [],
      groupedUsers: [],
      groupUsersWithRun: [],
      isExcelModalActive: false,
      dates: [{ kind: 'dueDate', label: 'Due Date' }],
      checkListOrder: props.rowData,
    });
    const emitter = inject('emitter');
    const user = store.state.userData;
    const { t } = translator;
    const orderChecklist = ref(null);

    // computed
    const isNotTemplate = computed(() => !(props.rowData.__t === 'MatTemplates' || props.rowData.__t === 'ProdTemplates'));
    const todoTypesCheck = computed(() => {
      for (const key in state.todoTypes) {
        if (state.todoTypes[key].title === 'Material' && ['ProductionOrder', 'Prefabs'].includes(props.rowData?.__t)) delete state.todoTypes[key];
      }
      return _.filter(state.todoTypes);
    });
    // methods
    // eslint-disable-next-line no-unused-vars
    const onPrivate = (rowData) => {
      if (!rowData.private) rowData.assignedTo.user = null;
    };
    const groupUsers = (users) => {
      let gUsers = _.groupBy(users, 'companyName');
      gUsers = _.map(gUsers, (val, key) => ({ val, key }));
      return gUsers;
    };
    const pvtGroupUsers = (users) => {
      const filteredUsers = [];
      users.forEach((usr) => {
        if (usr.company === props.rowData.owner.company._id) {
          filteredUsers.push(usr);
        }
      });
      let allUsers = _.groupBy(filteredUsers, 'companyName');
      allUsers = _.map(allUsers, (val, key) => ({ val, key }));
      return allUsers;
    };
    const getUsersData = async () => {
      let users;
      let usersWithRun;
      const orderCreator = { name: '(Order Creator)', companyName: user.companyName };
      const runCreator = {
        name: t('Work Step Owner'),
        companyName: user.companyName,
      };
      if (props.rowData.__t === 'MatTemplates' || props.rowData.__t === 'ProdTemplates') {
        users = (await Users.allUsers({ limit: 1000 })).data;
        users = _.filter(users, (u) => u.activated && !u.archived);
        users.unshift(orderCreator);
        usersWithRun = [runCreator, ...users];
      } else {
        users = await Projects.linkedProjectUsers([props.rowData.project._id]);
        state.pvtGroupedUsers = pvtGroupUsers(users);
      }
      state.allUsers = users;
      state.groupedUsers = groupUsers(users);
      state.groupUsersWithRun = groupUsers(usersWithRun);
    };
    onMounted(async () => {
      await getUsersData();
    });
    const loadData = () => {
      let checkListData = [];
      if (props.mode === 'edit') {
        _.forEach(props.rowData?.simpleTodos, (todo) => {
          const todoValueObj = _.find(state.todoTypes, { value: todo.type });
          _.set(todo, 'selectStatus', _.find(state.todoStatus, { title: todo.status }));
          _.set(todo, 'selectType', _.find(state.todoTypes, {
            title: todoValueObj.title, value: todoValueObj.value,
          }));
          todo.isEditing = true;
        });
        _.forEach(props.rowData?.todos, (todo) => {
          const todoValueObj = _.find(state.todoTypes, { value: todo.type });
          _.set(todo, 'selectStatus', _.find(state.todoStatus, { title: todo.status }));
          _.set(todo, 'selectType', _.find(state.todoTypes, {
            title: todoValueObj.title, value: todoValueObj.value,
          }));
          todo.isEditing = true;
          todo.project = { _id: props.rowData.project._id };
          todo.instanceOf = 'CheckList';
        });
      }
      checkListData = props.rowData?.todos;
      if (props.todoType === 'pause') {
        checkListData = _.filter(checkListData, (todo) => todo.type === 'Pause');
      }
      checkListData = _.filter(checkListData, (todo) => !todo.archived.value);
      return LocalSort.filter(checkListData, orderChecklist?.value?.sort);
    };
    const selectionDone = (selectionType, opt, row) => {
      if (selectionType === 'status') {
        row[selectionType] = opt.title;
      } else {
        row[selectionType] = opt.value;
      }
    };

    const getTodoType = () => {
      const typeMap = {
        prefab: 'Planning',
        order: 'Coordination',
        detailing: 'Detailing',
        manufacturing: 'Manufacturing',
        qa: 'Quality Assurance',
        pause: 'Pause',
        material: 'Material',
      };
      if (typeMap[props.todoType]) {
        return typeMap[props.todoType];
      }

      if (props.rowData.__t === 'Materials') {
        return 'Material';
      }
      return 'Planning';
    };

    const refreshTable = () => orderChecklist?.value?.refreshTable();

    const addNewTodo = (todoData) => {
      let checklist = {};
      let clIndex;
      let todoUUID;
      if (todoData && todoData.type === 'form') {
        todoUUID = todoData.uuid;
        checklist = {
          assignedTo: {
            user: { _id: user._id, name: user.name },
            company: { _id: user.company, name: user.companyName },
          },
          status: 'Not Started',
          private: !!(['detailing', 'manufacturing', 'qa'].includes(props.rowData.stage)),
          type: getTodoType(),
          isEditing: true,
          name: todoData.name,
          uuid: todoUUID,
        };
      } else {
        clIndex = props.rowData.newTodo();
        checklist = {
          assignedTo: {
            user: { _id: user._id, name: user.name },
            company: { _id: user.company, name: user.companyName },
          },
          status: 'Not Started',
          private: !!(['detailing', 'manufacturing', 'qa'].includes(props.rowData.stage)),
          type: getTodoType(),
          uuid: uuid(),
          isEditing: true,
        };
      }
      _.assign(props.rowData.todos[clIndex], checklist);
    };

    const deleteTodo = (opts) => {
      const dltTodo = opts.data;
      let dltData = {};
      const formFile = _.find(dltTodo.files, { type: 'form' });
      dltTodo.archived.value = true;
      if (formFile) {
        if (dltTodo.sources.length === 1) {
          dltData = props.rowData;
        } else {
          const runOrItemArr = dltTodo.sources[1].type === 'run' ? props.rowData.manager.runs : props.rowData.items;
          dltData = _.find(runOrItemArr, (dt) => {
            const urls = _.map(dt.simpleFiles, 'url');
            return urls.includes(formFile.url);
          });
        }
        const simpleFileIndex = _.findIndex(dltData.simpleFiles, { url: formFile.url });
        if (simpleFileIndex > -1) {
          dltData.simpleFiles[simpleFileIndex].archived.value = true;
          dltData.simpleFiles.splice(simpleFileIndex, 1);
        }
      }
    };

    const onHeaderClicked = (opts) => {
      if (opts.type === 'addNew') {
        addNewTodo(opts);
      } else if (opts.type === 'delete') {
        deleteTodo(opts);
      } else if (opts.type === 'excelImport') {
        state.isExcelModalActive = true;
      }
      orderChecklist.value.refreshTable();
    };

    emitter.on('emitGetAllFiles', (formAdded) => {
      // get the latest doc added
      const doc = formAdded[formAdded.length - 1];
      if (doc.type === 'form') {
        addNewTodo(doc);
      }
      refreshTable();
    });

    emitter.on('deletedForm', () => {
      refreshTable();
    });

    const getAssignee = (rowData) => {
      if (rowData.private && isNotTemplate.value) {
        return state.pvtGroupedUsers;
      }
      if (rowData.sources[1] && rowData.sources[1].type === 'run') {
        return state.groupUsersWithRun;
      }
      return state.groupedUsers;
    };
    const addDocs = (doc, cl) => {
      const file = _.pick(doc, ['name', 'type', 'visible', 'url', 'uuid', 'formData', 'copiedFrom']);
      file.archived = {
        value: false,
      };
      let todoIndex = 0;
      if (cl._id) {
        todoIndex = _.findIndex(props.rowData.simpleTodos, (todo) => cl._id === todo._id);
      } else {
        todoIndex = _.findIndex(props.rowData.simpleTodos, (todo) => cl.uuid === todo.uuid);
      }
      if (todoIndex === -1) {
        cl.files.push(file);
      } else {
        if (_.isEmpty(props.rowData.simpleTodos[todoIndex].files)) {
          // eslint-disable-next-line vue/no-mutating-props
          props.rowData.simpleTodos[todoIndex].files = [];
        }
        // eslint-disable-next-line vue/no-mutating-props
        props.rowData.simpleTodos[todoIndex].files.push(file);
      }
    };
    const removeDocs = (fileToDelete, cl) => {
      let todoIndex = 0;
      if (cl._id) {
        todoIndex = _.findIndex(props.rowData.simpleTodos, (todo) => cl._id === todo._id);
      } else {
        todoIndex = _.findIndex(
          props.rowData.simpleTodos,
          (todo) => cl.uuid === todo.uuid,
        );
      }
      let removingFile;
      if (todoIndex === -1) {
        removingFile = _.find(cl.files, (file) => {
          if (fileToDelete.uuid) return file.uuid === fileToDelete.uuid;
          return file._id === fileToDelete._id;
        });
      } else {
        removingFile = _.find(props.rowData.simpleTodos[todoIndex].files, (file) => {
          if (fileToDelete.uuid) return file.uuid === fileToDelete.uuid;
          return file._id === fileToDelete._id;
        });
      }
      if (!removingFile.uuid) {
        removingFile.archived.value = true;
      } else if (todoIndex === -1) {
        const index = _.findIndex(cl.files, {
          uuid: fileToDelete.uuid,
        });
        delete cl.files[index];
      } else {
        const index = _.findIndex(props.rowData.simpleTodos[todoIndex].files, {
          uuid: fileToDelete.uuid,
        });
        delete props.rowData.simpleTodos[todoIndex].files[index];
      }
    };

    const validateExcelItem = (checklist) => {
      let errMsg = '';
      if (checklist.text) {
        checklist.text = _.toString(checklist.text);
      }
      if (_.isEmpty(checklist.text)) {
        errMsg = 'todo is a mandatory field';
      } else if (_.isUndefined(checklist.dueDate) && !['ProdTemplates', 'MatTemplates'].includes(props.rowData.__t)) {
        errMsg = 'Due date is a mandatory field';
      } else if (checklist.assignee && !Validation.validateEmail(_.trim(checklist.assignee))) {
        errMsg = 'Assignee Field must have a valid email!';
      }
      return errMsg;
    };

    const saveExcelItem = (obj) => {
      const status = _.map(state.todoStatus, (key) => key.title);
      _.map(obj, (checklist) => {
        let checklistUser = _.find(state.allUsers, (usr) => usr.email === checklist.assignee);
        checklistUser = checklistUser || user;
        const clIndex = props.rowData.newTodo();
        const temp = props.rowData.todos[clIndex];
        _.set(temp, 'files', []);
        if (!_.isEmpty(checklist.text)) { temp.text = checklist.text; }
        if (!_.isEmpty(checklist.status)
          && status.includes(_.startCase(checklist.status))) {
          _.set(temp, 'status', _.startCase(checklist.status));
        } else {
          _.set(temp, 'status', 'Not Started');
        }
        if (!_.isUndefined(checklist.dueDate) && Validation.isDateValid(checklist.dueDate)) {
          temp.dueDate = moment(checklist.dueDate);
        }
        temp.assignedTo = {
          user: { _id: checklistUser._id, name: checklistUser.name },
          company: { _id: checklistUser.company, name: checklistUser.companyName },
        };
        if (!_.isEmpty(checklist.type) && !['material', 'pause'].includes(props.todoType) && _.some(state.todoTypes, { title: _.startCase(checklist.type) })) {
          temp.type = checklist.type === 'Material' ? 'Planning' : _.startCase(checklist.type);
        } else if (props.todoType === 'pause') {
          temp.type = 'Pause';
        } else if (props.todoType === 'material') {
          temp.type = 'Material';
        } else { temp.type = 'Planning'; }
        if (!_.isEmpty(checklist.private) && ['true', 'yes', 'private'].includes(checklist.private.toLowerCase())) {
          temp.private = true;
        } else { temp.private = false; }
        _.set(temp, 'isEditing', true);
        _.set(temp, 'excelFile', checklist.excelFile);
        _.set(temp, 'uuid', uuid());
      });
    };

    const closeModal = () => {
      state.isExcelModalActive = false;
    };

    const triggerModal = (data) => {
      ModalProgrammatic.open({
        component: QaQcmodal,
        props: {
          rowData: data,
          card: props.rowData,
          fromTask: true,
        },
        events: {
        },
        canCancel: false,
        rootClass: 'modal-sm qa-modal',
      });
    };

    emitter.on('checklist:addedFiles', (payload) => {
      payload.forEach((file) => {
        props.rowData.files.push(file);
      });
    });

    return {
      ...toRefs(state),
      loadData,
      onPrivate,
      orderChecklist,
      selectionDone,
      getAssignee,
      getTodoType,
      addNewTodo,
      onHeaderClicked,
      addDocs,
      removeDocs,
      todoTypesCheck,
      refreshTable,
      closeModal,
      saveExcelItem,
      validateExcelItem,
      triggerModal,
    };
  },
});
</script>
