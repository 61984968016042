export default {
  TODO: {
    field: 'text',
    multiple: true,
    required: true,
    displayType: 'stringedOptions',
  },
  ASSIGNEE: {
    field: 'assignee',
    multiple: false,
  },
  'DUE DATE': {
    field: 'dueDate',
    multiple: false,
    required: true,
  },
  STATUS: {
    field: 'status',
    multiple: false,
  },
  TYPE: {
    field: 'type',
    multiple: false,
  },
  PRIVATE: {
    field: 'private',
    multiple: false,
  },
};
