<template>
  <div class="card-edit" v-on="dirtyListeners" :key="refreshKey">
    <card-edit-subheader v-if="cardLoaded"
      :type="type"
      :module="card.__t"
      :stage="stage"
      :card="card">
    </card-edit-subheader>
    <div class="edit-body">
      <card-basics v-if="cardLoaded" :type="type" :card="card"
        @accordianChanged="setAccordionStatus"
        :accordionStatus="accordionStatus" @update-dates="refreshKey++"></card-basics>
      <!-- table section goes below -->
      <div class="card-edit-item-table has-background-white">
        <mf-detail-row v-if="cardLoaded" :rowData="card" :key="refreshKey" :refreshCard="loadCard" @update-dates="refreshKey++"
        @tabName="setTabName" :tabLabel="selectedTabName"
        :catalogCollection="catalogCollection"> </mf-detail-row>
      </div>
    </div>
    <transition name="slide">
      <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedItem"
        @close="closeNotesSlider"
      >
      </notes-icon-slider>
    </transition>
    <transition name="slide">
      <item-document-slider
        v-if="isFileListVisible"
        :isActive="isFileListVisible"
        :rowData="selectedItem"
        :rowField="rowField"
        @close="closeDocumentSlider"
        :card="card"
        >
      </item-document-slider>
    </transition>
    <card-footer
      v-if="cardLoaded"
      :showSave="isDirty"
      :type="type"
      @reload-card="loadCard"
      @reload-page="reloadPage"
      @changeCard="changeCard"
      :isNewCard="isNewCard"
      :card="card"
      :projectId="projectId"
      :stage="stage"
      :cardId="cardId"
      :saveCard="saveCard"
      >
    </card-footer>
    <o-loading :active="isLoading"></o-loading>
  </div>
</template>

<script>
import {
  reactive, toRefs, onBeforeMount, onBeforeUnmount, nextTick, inject,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import MaterialManager from '@/models/MaterialManager';
import CardEditSubHeader from '@/components/card-edit/CardEditSubheader.vue';
import CardBasics from '@/components/card-edit/CardBasics.vue';
import MfDetailRow from '@/components/table-fields/MfDetailRow.vue';
import { BaseOrder } from '@/models/BaseOrder';
import CardFooter from '@/components/card-edit/CardFooter.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import isUndefined from 'lodash';
import Catalogs from '@/models/Catalogs';

export default {
  name: 'mm-edit',
  components: {
    'card-edit-subheader': CardEditSubHeader,
    'card-basics': CardBasics,
    'mf-detail-row': MfDetailRow,
    'card-footer': CardFooter,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    cardId: {
      type: String,
      required: true,
    },
    stage: {
      type: String,
      required: true,
    },
    orderJSON: {
      type: String,
    },
  },
  setup(props) {
    const { cardId, projectId, orderJSON } = props;
    const emitter = inject('emitter');
    const { onInitDirty, onCardLoaded, saveCard } = CardEditMixin();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    // data
    const data = reactive({
      card: {},
      cardLoaded: false,
      type: '',
      isLoading: false,
      isNewCard: false,
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedItem: {},
      isItem: false,
      refreshKey: 0,
      rowField: {},
      accordionStatus: false,
      selectedTabName: '',
      catalogCollection: [],
    });
    onInitDirty(data);

    // methods
    // TODO: need to call from mixin
    const loadCard = async () => {
      onInitDirty(data);
      data.isLoading = true;
      const shippingStages = ['not-started', 'in-transit', 'complete', 'delivery', 'released-to-inventory',
        'fulfilled', 'in-storage', 'mixed-shipping'];
      const type = shippingStages.includes(props.stage) ? 'material-edit-ordering' : 'material-edit-preparation';
      data.type = store.state.activeScreen === 'untitled' ? type : store.state.activeScreen;
      let order;
      if (props.cardId !== 'add') {
        order = await MaterialManager.getOne({
          cardId,
          projectId,
        });
        order = await order.attachVendors();
        data.isNewCard = true;
        await nextTick(); /* required since props from route are not updated
        fast enough before cardInit is called */
      } else order = new BaseOrder(JSON.parse(orderJSON));

      order._beforeEdit = _.cloneDeep(order);

      // await cardInit();
      // refreshTable();
      data.isLoading = false;
      order._customStage = props.stage;
      data.card = order;
      onCardLoaded(data.card, props.stage);
      ({ data: data.catalogCollection } = await Catalogs.getAssemblyParts({
        type: 'Parts',
        search: '',
        page: 1,
        limit: 10000,
      }));
      data.refreshKey++;
    };

    const changeCard = (newCard) => {
      data.card = newCard;
    };

    const reloadPage = async () => {
      /* Since the prop 'cardId' comes from the route. This step is required for when
          a new prefab/mm is being added. The 'cardId' prop is set to 'add' when creating
          a new card, this needs to be reset to the created card's _id so that refresh
          works as expected. */
      if (cardId === 'add') {
        router.push({
          name: route.name,
          params: {
            cardId: data.card._id,
            projectId,
            stage: props.stage,
          },
        });
        data.isNewCard = true;
        await loadCard();
      } else {
        await loadCard();
      }
    };

    const closeNotesSlider = (() => {
      data.isSlideNotesActive = false;
      data.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      data.isFileListVisible = false;
    };

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      if (!payload?.fromMove) {
        data.isFileListVisible = !payload.isActive;
        data.selectedItem = payload.data;
        data.isItem = payload.isItem;
        data.isSlideNotesActive = payload.isActive && isUndefined(payload.fromMove);
      }
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      data.isSlideNotesActive = !payload.isActive;
      data.selectedItem = payload.data;
      data.rowField = payload.rowField;
      data.isFileListVisible = payload.isActive;
    });

    // mounted
    onBeforeMount(async () => {
      data.type = store.state.activeScreen;
      await loadCard();
      data.cardLoaded = true;
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });

    const setAccordionStatus = (val) => { data.accordionStatus = val; };

    const setTabName = (val) => {
      data.selectedTabName = val;
    };

    return {
      ...toRefs(data),
      loadCard,
      changeCard,
      reloadPage,
      saveCard,
      closeNotesSlider,
      closeDocumentSlider,
      setAccordionStatus,
      setTabName,
    };
  },
};
</script>

<style scoped></style>
