<template>
  <o-modal :active="isActive" :canCancel="false" class="modal-sm">
    <div class="modal-card">
      <header class="modal-card-head">
        <h4
          class="modal-card-title is-size-4 has-text-black-bis is-capitalized has-text-weight-bold"
        > {{headStr}}
        </h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable is-size-2" @click="$emit('close')"></i>
      </header>
      <section class="modal-card-body is-marginless is-visible">
        <div class="mb-3 has-text-black-bis is-flex is-justify-content-space-between">
          <div class="has-text-weight-bold">
            Order: {{card.name}}
          </div>
          <div>*required</div>
        </div>
        <field-generic-input
          label="Title*"
          :value="model.title"
          :isEditing="true"
          @update:value="(val) =>  model.title= val"
        >
        </field-generic-input>
        <div class="field">
          <label class="label"><em>Message *</em></label>
          <div class="control">
            <textarea
              v-model.trim="model.message"
              class="textarea"
            >
            </textarea>
          </div>
        </div>
        <div class="field" multiple="true">
          <div class="is-flex is-justify-content-space-between">
            <label class="label"><em>Recipients *</em></label>
            <button class="button px-0 is-pulled-right mr-0" @click="activateDropdown">
              <i class="icon-addnew has-text-black"></i>
            </button>
          </div>
          <div class="control">
            <VueMultiselect
              class="recipient-field"
              v-model="model.notifyType"
              :options="groupedListUsers"
              track-by="_id"
              label="name"
              :multiple="true"
              group-values="val"
              group-label="key"
              :searchable="true"
              :allow-empty="true"
              :tags="true"
              ref="recipientField"
              :close-on-select="false"
            >
            </VueMultiselect>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <div>
          <button
            class="button is-outlined"
            @click="$emit('close')"
          >
            Discard
          </button>
          <button
            class="button is-success"
            @click="sendNotification" :disabled="isButtonDisabled"
          >
            Send Notification
          </button>
        </div>
      </footer>
    </div>
  </o-modal>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  ref,
  computed,
} from 'vue';
import {
  get,
  map,
  isEmpty,
  debounce,
} from 'lodash';
import Notifications from '@/models/Notifications';
import Projects from '@/models/Projects';
import Users from '@/models/Users';
import { useToast } from 'vue-toastification';
import VueMultiselect from 'vue-multiselect';

export default {
  name: 'NotifyUsers',
  props: {
    isActive: {
      type: Boolean,
      require: true,
      default: false,
    },
    card: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: String,
      default: () => (''),
    },
    run: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    VueMultiselect,
  },
  setup(props, { emit }) {
    const { card } = props;
    const toast = useToast();
    // data
    const data = reactive({
      model: {
        notifyType: [],
        title: '',
        message: '',
        project: null,
      },
      groupedListUsers: [],
    });

    const recipientField = ref(null);

    // methods
    const validate = () => {
      if (isEmpty(data.model.message)) {
        return 'Message can not be empty';
      }
      return false;
    };

    const sendNotification = debounce(async () => {
      const errMsg = validate();
      if (!errMsg) {
        data.model.cardId = get(card, '_id', null);
        await Notifications.sendNotification(data.model);
        toast.success('Notifications sent');
        emit('close');
      } else {
        toast.error(errMsg);
      }
    }, 300, {
      leading: true,
      trailing: false,
    });

    // mounted
    onMounted(async () => {
      if (isEmpty(card) || !card._id) return;
      data.model.notifyType = [];
      data.model.cardOwner = card.owner;
      data.model.project = card.project;
      const project = await Projects.getOne(card.project._id);
      const projectCompanies = map(project.projectCompanies, '_id');
      projectCompanies.push(card.owner.company._id);
      const params = {
        companyIds: projectCompanies,
        projectId: card.project._id,
        ignoreCompany: true,
      };
      const userOptions = await Users.getCompanyProjectAllLinkedUsers({ params });
      const users = Users.groupUsers(userOptions);
      users.unshift({
        key: 'Groups',
        val: [{
          name: 'Order Owner',
          _id: 'orderOwner',
        }, {
          name: 'Project Members (All Companies)',
          _id: 'allCompanies',
        }, {
          name: 'Project Members (My Company)',
          _id: 'projectMembers',
        }],
      });
      data.groupedListUsers = users;
    });

    const activateDropdown = () => {
      recipientField.value.activate();
    };
    const headStr = computed(() => (isEmpty(props.from) ? 'Notify Users' : 'QA/QC Notification'));
    const isButtonDisabled = computed(() => {
      const { title, message, notifyType } = data.model;
      return !(title.length > 0 && message.length > 0 && notifyType.length > 0);
    });

    return {
      ...toRefs(data),
      sendNotification,
      recipientField,
      activateDropdown,
      isButtonDisabled,
      headStr,
    };
  },
};
</script>
<style scoped>
.modal-card-body {
  height: 480px;
}
</style>
