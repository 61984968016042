// do not commit this file unless necessary.

export default {
  version: 12,
  dev: 'https://dev.manufacton.com/',
  local: 'http://localhost:3002/',
  plugin: 'https://plugin.manufacton.com/',
  ship: 'https://ship.manufacton.com/',
  demo: 'https://demo.manufacton.com/',
  app: 'https://app.manufacton.com/',
  stage: 'https://stage.manufacton.com/',
  test: 'https://test-update.manufacton.com/',
  hfdm: 'https://hfdm.manufacton.com/',
  ui: 'https://ui.manufacton.com/',
  s3: 'https://s3-us-west-2.amazonaws.com/message-store-prod/',
  base: '/',
  active: process.env.NODE_ENV === 'production' ? 'base' : 'local',
};
