export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: false,
  stickeyHeaders: true,
  rearrangeCols: false,
  permissionModule: 'scm',
  detailRowComponent: 'mf-detail-row',
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      isLine: true,
      tooltip: 'Customise Columns',
    }],
  },
  toolBar: {
    // name: for drop down label, label: for action button label
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: [],
  },
  fields: [
    {
      title: 'Order Name',
      name: 'field-generic-input',
      sortField: 'name',
      prop: 'name',
      width: '400px',
      sticky: true,
      id: 'name',
      defaultVisible: true,
      alwaysVisible: true,
      isClickable: true,
      eventType: 'openCard',
      searchField: false, // to give a search bar for the field
      searchable: true, // to place any custom field in the header of the column like for below
      inputProps: {
        type: 'text',
      },
    }, {
      title: 'Project',
      name: 'field-generic-input',
      prop: 'project.name',
      sortField: 'project',
      searchable: true,
      filterText: 'Projects',
      width: '296px',
      id: 'project',
      isDisabled: true,
      defaultVisible: true,
      alwaysVisible: false,
    }, {
      title: 'Order Type',
      name: 'module',
      width: '196px',
      prop: 'module',
      id: 'module',
      filterText: 'Order Type',
      sortField: 'module.value',
      searchable: true,
      defaultVisible: true,
      alwaysVisible: false,
    }, {
      title: 'Order Stage',
      name: 'field-stage',
      prop: 'stage',
      width: '152px',
      id: 'stage',
      defaultVisible: true,
      sortField: 'stage',
      alwaysVisible: false,
      searchable: true,
      filterText: 'Stages',
      cardProps: {
        cardType: '__t',
      },
    },
    {
      title: 'Modified',
      name: 'dateOfChange',
      id: 'date',
      defaultVisible: true,
      dateFilter: 'Date',
      alwaysVisible: false,
      width: '152px',
      searchable: true,
    }, {
      title: 'Date Type',
      name: 'dateType',
      width: '112px',
      id: 'dateType',
      defaultVisible: true,
      alwaysVisible: false,
      searchable: true,
    }, {
      title: 'Old Date',
      name: 'oldDate',
      width: '112px',
      id: 'oldDate',
      dateFilter: 'oldDate',
      defaultVisible: true,
      alwaysVisible: false,
      searchable: true,
    }, {
      title: 'New Date',
      name: 'newDate',
      width: '112px',
      id: 'newDate',
      defaultVisible: true,
      alwaysVisible: false,
      searchable: true,
    },
    {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      width: '112px',
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      noRequiredCheck: true,
      sticky: true,
      hideFromChooser: true,
      alwaysVisible: true,
      searchable: true,
      clearBtn: true,
      inputProps: {
        showEdit: true,
      },
    },

  ],
};
