import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async getTask(params) {
    try {
      const { data } = await req.get('task/getTask', { params });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getOne(params) {
    try {
      const { data: task } = await req.get(`task/get/${params.taskId}`);
      return task;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async list(params) {
    try {
      const { data } = await req.get('task/list', { params });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async create(task) {
    try {
      const result = await req.post('task/create/', task);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async update(id, taskMeta) {
    try {
      const result = await req.post(`task/update/${id}`, taskMeta);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getItemLocation(taskId) {
    try {
      const locations = await req.get(`task/itemLocations/${taskId}`);
      return locations;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async executeStock(params) {
    try {
      const result = await req.post(`task/executeStock/${params.taskId}`, params);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async executePick(params) {
    try {
      const result = await req.post(`task/executePick/${params.id}`, params);
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getUniqueTaskId(type, companyId) {
    try {
      const uniqueId = await req.get(`task/generateTaskId/${type}/${companyId}`);
      return uniqueId;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
