import GenerateRequest from './resources';

const req = GenerateRequest();
export default {
  async getTodo(params) {
    try {
      const checklist = await req.get('checklist/get/', { params });
      return checklist.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async updateTodo(todo) {
    try {
      const checklist = await req.post('checklist/updateSingle/', todo);
      return checklist.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getFormBuilder(params) {
    try {
      const checklist = await req.get('formBuilder/get/', { params });
      return checklist.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  // take form data and flag for clone. updateFormTemplate(formTemplate, makeClone)
  async updateFormTemplate(templateForm, makeClone) {
    try {
      const reqObj = {
        templateForm,
        makeClone: makeClone || false,
      };
      const updatedFormTemplate = await req.post('formBuilder/updateFormTemplate/', reqObj);
      return updatedFormTemplate;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async cloneForm(params) {
    try {
      const checklist = await req.get('formBuilder/cloneForm', { params });
      return checklist.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getAllTemplateForms(params) {
    try {
      const checklist = await req.get('formBuilder/getTemplateForms/', { params });
      return checklist.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getTemplateFormsStatus(params) {
    try {
      const checklist = await req.get('formBuilder/getTemplateFormsStatus/', { params });
      return checklist.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async archiveForm(card) {
    try {
      const { data: order } = await req.post('formBuilder/archiveForm', card);
      return order;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getCompletedFormData(params) {
    try {
      const forms = await req.get('formBuilder/getCompletedFormData', { params });
      return forms.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getFormsBuilder(params) {
    try {
      const checklist = await req.get('formBuilder/getTemplates/', { params });
      return checklist.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
