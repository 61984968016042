<template>
    <TableGutter
      :selectedAction="isBulkActionEnabled"
      :tools="tableProps.gutterOpts"
      @cellClicked="eventHandler"
       @cc-modal-closed="triggerSetHeaderHeight"
       v-if="view !== 'cal-view'"
      @re-render-table="rerenderMfTable += 1"
    >
      <template v-slot:viewModel>
        <div class="column is-narrow is-flex pr-0">
          <button class="button has-background-black-bis has-text-white
            is-size-5 has-text-weight-bold pl-0"
            :disabled="isBulkActionEnabled !== null"
          @click="viewModelOpen = true">
          <i class="icon-linked-model"></i>
          View Model</button>
        <div class="is-divider-vertical"></div>
      </div>
    </template>
    <template v-slot:selectView>
      <div class="column is-2 pl-0 pr-0 is-3-desktop">
        <div class="columns is-align-items-center">
          <div class="column is-narrow">
            <label class="label has-text-black-bis is-size-3 is-italic has-text-weight-bold">
              View As:
            </label>
          </div>
          <div class="column">
            <mf-multi-select
              :disabled="isBulkActionEnabled !== null"
              :modelValue="getSelectedVal()"
              track-by="type"
              label="label"
              :multiple="false"
              :options="viewOptions"
              :allow-empty="false"
              :closeOnSelect="true"
              @update:modelValue="setView($event)"
              class="is-capitalized"
            ></mf-multi-select>
          </div>
        </div>
      </div>
    </template>
  </TableGutter>
  <lmv-project-modal
    v-if="viewModelOpen"
    :isActive="viewModelOpen"
    @close="viewModelOpen = false">
  </lmv-project-modal>
  <order-view ref="orderView" v-if="view === 'order-view'" :key="rerenderMfTable"></order-view>
  <item-view ref="itemView" v-if="view === 'item-view'" :key="rerenderMfTable"
             :excelExport="excelExport"
             type="productionStatus"
  ></item-view>
  <LoadIFrame  src= '/scm/production-status/cal-view' v-if="view === 'cal-view'"></LoadIFrame>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, onMounted, computed, watch,
} from 'vue';
import ProductionStatusCols from '@/components/table-cols/ProductionStatusCols';
import PSItemViewCols from '@/components/table-cols/PSItemViewCols';
// import _ from 'lodash';
import { useStore } from 'vuex';
import LmvProjectModal from '@/components/modals/LmvProjectModal.vue';
import TableGutter from '@/components/TableGutter.vue';
import OrderView from '@/views/scm/ProductionStatusOrderView.vue';
import ItemView from '@/views/scm/BaseOrderItemView.vue';
import { useRouter } from 'vue-router';
import ExcelExportMixin from '@/components/mixins/ExcelExportMixin';
import LoadIFrame from '@/views/LoadIFrame.vue';

export default defineComponent({
  name: 'Production-Status',
  components: {
    LmvProjectModal,
    TableGutter,
    OrderView,
    ItemView,
    LoadIFrame,
  },
  props: {
    view: {
      type: String,
    },
  },
  setup(props) {
    const PSTable = ref(null);
    const orderView = ref(null);
    const itemView = ref(null);
    const isBulkActionEnabled = computed(() => {
      if (props.view === 'order-view') {
        return orderView?.value?.$refs.PSTable?.selectedBulkAction;
      }
      return itemView?.value?.$refs.BOItemTable?.selectedBulkAction;
    });
    const store = useStore();
    const router = useRouter();
    const { excelExport } = ExcelExportMixin();
    const viewOptions = [{
      label: 'Orders',
      type: 'order-view',
    }, {
      label: 'Items',
      type: 'item-view',
    }, {
      label: 'Calendar',
      type: 'cal-view',
    }];
    const colsMap = {
      'order-view': ProductionStatusCols,
      'item-view': PSItemViewCols,
    };
    onMounted(async () => {
      await store.getters.userPromise;
      if (store.state.queryParams.selectedList !== props.view) {
        store.commit('setQueryParams', {
          selectedList: props.view,
        });
      }
    });
    const qp = computed(() => store.state.queryParams);
    const state = reactive({
      viewModelOpen: false,
      viewAs: viewOptions[0],
      rerenderMfTable: 0,
    });
    const tableProps = computed(() => colsMap[props.view]);
    const setView = (event) => {
      const key = 'selectedList';
      store.commit('setQueryParams', { [key]: event.type });
    };
    const getSelectedVal = () => ({
      label: qp.value.selectedList === 'order-view' ? 'orders' : 'items',
      type: qp.value.selectedList,
    });
    watch(
      () => qp.value.selectedList,
      (newVal, oldVal) => {
        store.commit('setQueryParams', {
          oldselectedList: oldVal,
        });
        if (newVal !== '' && newVal !== props.view) {
          router.push({ path: `/scm/production-status/${newVal}` });
        }
      },
    );

    const eventHandler = (event) => {
      if (event === 'excelExport') {
        excelExport();
      }
    };
      // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      if (props.view === 'order-view') {
        // eslint-disable-next-line no-unused-expressions
        orderView?.value?.$refs.PSTable?.closeColumnChooserModal();
        // eslint-disable-next-line no-unused-expressions
        orderView?.value?.$refs.PSTable?.refreshFilterOpts();
      } else {
        // eslint-disable-next-line no-unused-expressions
        itemView?.value?.$refs.BOItemTable?.closeColumnChooserModal();
      }
    };
    return {
      ...toRefs(state),
      viewOptions,
      setView,
      PSTable,
      qp,
      getSelectedVal,
      tableProps,
      eventHandler,
      excelExport,
      orderView,
      itemView,
      isBulkActionEnabled,
      triggerSetHeaderHeight,
    };
  },
});
</script>
