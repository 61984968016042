import { castArray, isArray } from 'lodash';
import GenerateRequest from './resources';
import { BaseOrder } from './BaseOrder';

const req = GenerateRequest();
export default {
  async export(params = {}) {
    if (!isArray(params)) {
      params = castArray(params);
    }
    for (const param of params) {
      param.tzinfo = new Date().getTimezoneOffset();
      if (param.stage && param.stage.length) {
        param.stage = BaseOrder.appendShippingStages(param.stage);
      }
    }
    const { data } = await req.post('reportings/addToQueue', params);
    return data;
  },
};
