import _ from 'lodash';

class Project {
  constructor(project) {
    Object.assign(this, project);
  }

  getDefaults(card, cardStage) {
    const stage = cardStage.split(':')[0].toLowerCase();
    let routes;
    if (['pick', 'stock'].includes(stage)) {
      routes = _.find(this.orderRoute, s => s.stage === stage);
    } else routes = _.find(this.orderRoute, s => s.purpose === card.purpose && s.stage === stage);
    return routes;
  }

  hasNesetedProjectInvLoc(user) {
    const settingForProject = _.find(this.projectSettings, dt => dt.companyId.toString() === user.company.toString());
    return settingForProject.isNestedInventoryLocation;
  }
}
export default Project;
