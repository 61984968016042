<template>
  <div class="field" v-if="value">
    <label v-if="label" class="label has-text-black-bis is-italic is-size-3 is-flex
      is-align-items-center ml-0">
      <i class="icon-kit"></i>
      {{ label }}
    </label>
    <div class="is-size-3 pl-1 line-height" :class="label?'has-text-weight-bold': ''">
      <div v-if="!value" ></div>
      <div v-else-if="value && rowData?.isDisabled" class="has-text-black"> {{ value}} </div>
      <a v-else @click="kitUrl()" class="is-text-link text-overflow text-clamp" v-tooltip="value"> {{ value }}</a>
    </div>
  </div>
  <!-- <div v-else class="is-invisible"> </div> -->
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'kitView',
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    projectId: String,
    rowData: {
      type: Object,
    },
  },
  setup(props) {
    const router = useRouter();

    const kitUrl = () => {
      router.push({
        name: 'kitView',
        params: {
          customId: props.value,
          projectId: props.projectId ? props.projectId : props.rowData.project._id,
        },
      });
    };
    return {
      kitUrl,
    };
  },
});
</script>
<style scoped>
.text-overflow {
  -webkit-line-clamp: 1;
}
label i:hover {
  cursor: default;
  color: inherit !important;
}
</style>
