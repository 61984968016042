<template>
    <div class="">
        <button class="button rotate-min-90"  @click="updatePage('first')" :disabled="page === 1">
          <i class="icon-collapseall"></i>
        </button>
        <button class="button mx-2" @click="updatePage('dec')" :disabled="page === 1">
          <i class="icon-disclosearrowleft"></i>
        </button>
        <span class="is-size-3">Page </span>
        <input type="text" class="count-input mx-2" v-model="page" @keypress.enter="updatePage"/>
        <span class="is-size-3"> of {{ total }}</span>
        <button class="button mx-2" @click="updatePage('inc')" :disabled="page === total">
          <i class="icon-disclosearrowright"></i>
        </button>
        <button class="button rotate-90" @click="updatePage('last')" :disabled="page === total">
          <i class="icon-collapseall"></i>
        </button>
      </div>
  </template>

<script>
import { defineComponent, reactive, toRefs, computed } from 'vue';

export default defineComponent({
  name: 'Pagination',
  props: {
    initialValue: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      page: props.initialValue,
    });

    const updatePage = (type) => {
      if(state.page > props.total) state.page = props.total;
      if(type === 'inc') state.page++;
      else if(type === 'dec') state.page--;
      else if(type === 'first') state.page = 1;
      else if (type === 'last') state.page = props.total;

      if(state.page) emit('pagination', {page: state.page});
    }

    return {
      ...toRefs(state),
      updatePage,
    };
  },
});
</script>