<template>
  <o-loading
    :full-page="true"
    :active="isLoading"
    :can-cancel="false"
  ></o-loading>
  <div class="list-view">
      <TableGutter
        :selectedAction="isBulkActionEnabled"
        :tools="tableProps.gutterOpts"
        @cellClicked="refreshTable"
        @cc-modal-closed="triggerSetHeaderHeight"
        @re-render-table="rerenderMfTable += 1"
      >
        <template v-slot:showComplete>
          <div class="column is-narrow is-flex pr-0">
            <div class="is-divider-vertical"></div>
            <o-field>
              <o-switch class="has-text-black-bis" v-model="completeActivity"
                @click="refreshTable('completeActivity', !completeActivity)">
                Show Complete Activities
              </o-switch>
            </o-field>
          </div>
        </template>
        <template v-slot:hideActivities>
          <div class="column is-narrow is-flex pr-0">
            <div class="is-divider-vertical"></div>
            <o-field>
              <o-switch class="has-text-black-bis" v-model="hiddenActivity"
                @click="refreshTable('hiddenActivity', !hiddenActivity)">
                Show Hidden Activities
              </o-switch>
            </o-field>
          </div>
        </template>
      </TableGutter>
      <mf-table
        ref="scheduleTable"
        :tableProps="tableProps"
        :loadData="loadData"
        :hideGutter="true"
        :tableName="stageFromUrl"
        :screenTable="true"
        @cell-clicked="onCellClicked"
        :key="rerenderMfTable"
      >
        <template v-slot:submittal="{ rowData }">
          <span v-if="rowData.submittal">
            <a :href="procoreUrl(rowData.submittal)" > {{ rowData.submittal?.name }} </a>
          </span>
          <i v-if="rowData.isEditing && rowData.orderStage !== 'delivery'"
            class="is-clickable icon-procore pl-1"
            @click="selectedRow= rowData, isListSubsActive = true"
          ></i>
        </template>
        <template v-slot:vendor="{ rowData }">
          <span class="is-clickable" v-if="rowData.vendorData[0]?.isTemporary" >
            <VDropdown :shown="isOpen[rowData._id]" :autoHide="true">
              <span class="has-text-danger" @click="isOpen[rowData._id] = !isOpen[rowData._id]">
                <i class="fa fa-exclamation" />
                {{rowData.vendorData[0]?.name}}
              </span>
                <template #popper>
                  <div class="card">
                    <header class="card-header has-background-grey-lighter">
                      <h4 class="card-header-title is-size-3 has-text-black-bis is-paddingless" >
                          Vendor Not Found
                      </h4>
                    </header>
                    <section class="card-content is-marginless has-background-white has-text-black-bis">
                      <h3 class="has-text-weight-normal mb-0 mt-2"> Add the Vendor,or change it in Procore</h3>
                    </section>
                    <footer class="card-footer is-justify-content-center is-flex" >
                      <button @click="addVendor(rowData.vendorData[0], rowData)" class="button has-background-black-bis has-text-white " > Add Vendor </button>
                    </footer>
                  </div>
              </template>
            </VDropdown>
          </span>
          <span v-else>
            {{rowData.vendor?.name}}
          </span>
        </template>
      </mf-table>
  <list-submittals :projectId="selectedProject?._id" @selectedSubmittal="selectedSubmittal"
    @close="isListSubsActive = false" :isListSubsActive="isListSubsActive" >
  </list-submittals>
  <activity-log
    :isActive="isActive"
    :id="activityDetails.id"
    :projectId="activityDetails.projectId"
    :orderName="activityDetails.orderName"
    @update:isActive="isActive = false"
  ></activity-log>
  <excel-import
    ref="excel"
    :isExcelModalActive="excelImportModal"
    :dates="dates"
    :saveExcelItem="saveExcelItem"
    :validateExcelItem="validateRow"
    :selectedProjectId="selectedProject"
    @close-modal="closeModal"
    :batchSize="100"
    :importLimit="10000"
    importModule="schedule-import"
    modalHeaderTitle="import P6 Activities from excel"
    @refresh-table="refreshTable"
  >
  </excel-import>
  <transition name="slide">
    <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedItem"
        @close="closeNotesSlider"
      >
    </notes-icon-slider>
  </transition>
 </div>
</template>

<script>
import moment from 'moment';
import {
  reactive, toRefs, computed, ref, onBeforeUnmount, inject,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import _ from 'lodash';
import Schedule from '@/models/Schedule';
import Shipping from '@/models/Shipping';
import Vendors from '@/models/Vendors';
import { useToast } from 'vue-toastification';
import SupplyChain from '@/models/SupplyChain';
import tableDefinition from '@/components/table-cols/plannerCols';
import ExcelImport from '@/components/ExcelImport.vue';
import TableGutter from '@/components/TableGutter.vue';
import ExcelMixin from '@/components/mixins/ExcelMixin';
import ShippingReceive from '@/components/modals/ShippingReceive.vue';
import ListSubmittals from '@/components/modals/ListSubmittals.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import ExcelExport from '@/models/ExcelExport';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import MfTable from '../components/table-fields/MfTable.vue';
import ActivityLog from '../components/ActivityLog.vue';
import CloneCard from '../components/modals/CloneCard.vue';

export default {
  name: 'Schedule',
  components: {
    MfTable,
    // CreateOrder,
    ActivityLog,
    ExcelImport,
    TableGutter,
    ListSubmittals,
    'notes-icon-slider': NotesIconSlider,
  },
  setup() {
    const emitter = inject('emitter');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const { validateDates } = ExcelMixin();
    const scheduleTable = ref(null);
    const isBulkActionEnabled = computed(() => scheduleTable?.value?.selectedBulkAction);
    const procoreUrl = (value) => `https://app.procore.com/${value.project}
      /project/submittal_logs/${value.id}`;
    const excel = ref(null);
    const state = reactive({
      isLoading: false,
      isActive: false,
      isOpen: {},
      isTableReady: true,
      isListSubsActive: false,
      tableProps: tableDefinition,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      selectedRow: {},
      importFailCount: 0,
      completeActivity: store.state.completeActivity,
      hiddenActivity: store.state.hiddenActivity,
      excelImportModal: false,
      batchSize: 500,
      dates: [
        { kind: 'deliver', label: 'Onsite' },
      ],
      vendors: [],
      isSlideNotesActive: false,
      selectedItem: {},
      rerenderMfTable: 0,
    });
    const selectedProject = computed(() => {
      const { project } = store.state.queryParams;
      return project || {};
    });
    const stageFromUrl = computed(() => route.path.split('/').pop());

    const refreshTable = (type, val) => {
      if (!_.isEmpty(type) && ['completeActivity', '']) {
        if (type === 'completeActivity') {
          store.commit('setCompleteActivity', val);
        } else if (type === 'hiddenActivity') {
          store.commit('setHiddenActivity', val);
        }
      }
      // eslint-disable-next-line no-unused-expressions
      scheduleTable.value?.refreshTable();
    };

    const validateRow = (obj) => {
      let errMsg = null;
      if (_.isEmpty(obj.activityName)) {
        errMsg = 'Name Field is Mandatory!';
      } else if (obj.activityName.length < 3) {
        errMsg = 'Name Field must contain three or more characters!';
      } else if (_.isEmpty(obj.activityId)) {
        errMsg = 'Activity Id is Mandatory ';
      }
      if (!errMsg && state.dates) {
        validateDates(obj, state.dates);
      }
      return errMsg;
    };

    const loadData = async (commonParam) => {
      let fromDashBoard;
      let approvalMin;
      let approvalMax;
      let approvalStatus;
      let submittalState;
      let vendor;
      let isLate;
      if (route.query) {
        ({
          fromDashBoard,
          approvalMin,
          approvalMax,
          approvalStatus,
          submittalState,
          vendor,
          isLate,
        } = route.query);
      }
      const shipStatus = store.getters.selectedValuesForKey('shippingStatus', false);
      if (shipStatus.includes('fulfilled')) {
        const idx = shipStatus.indexOf('fulfilled');
        shipStatus[idx] = 'complete';
      }
      if (shipStatus.includes('not-started')) {
        const idx = shipStatus.indexOf('not-started');
        shipStatus[idx] = 'scheduled';
      }
      const paramObj = {
        projectId: store.state.queryParams.project?._id,
        showHidden: store.state.hiddenActivity,
        showCompleted: store.state.completeActivity,
        showArchived: false,
        // submittals: this.selectedSubmittals,
        submittalResponse: store.getters.selectedValuesForKey('submittalResponse', false),
        status: shipStatus,
        stage: store.getters.selectedValuesForKey('orderStage', false),
        vendors: _.isEmpty(store.state.queryParams.vendor) ? []
          : _.map(store.state.queryParams.vendor, '_id'),
        ...store.getters.selectedDatesForKeys([
          'deliverStartDate',
          'deliverEndDate',
          'submitByStartDate',
          'submitByEndDate',
          'approveByStartDate',
          'approveByEndDate',
          'orderByStartDate',
          'orderByEndDate',
          'shipByStartDate',
          'shipByEndDate',
          'deliverByStartDate',
          'deliverByEndDate',
          'releaseStartDate',
          'releaseEndDate',
        ]),
        fromDashBoard,
        approvalMin,
        approvalMax,
        approvalStatus,
        submittalState,
        vendor,
        isLate,
        ...commonParam,
      };
      const { project } = store.state.queryParams;
      paramObj.projectId = _.isArray(project) ? project[0]._id : project._id;
      const apiResp = await Schedule.get(paramObj);
      return apiResp;
    };
    const archiveSchedule = async (schedule) => {
      const confirmParams = {
        title: 'Remove Activity',
        message: `Are you sure you want to Remove "${schedule.activityName}"?`,
        okButton: 'Remove',
        cancelButton: 'Cancel',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          try {
            _.set(schedule, 'archived.value', true);
            state.isLoading = true;
            await Schedule.updateSchedule(schedule);
            state.isLoading = false;
            toast.success('Schedule Archived');
            refreshTable();
          } catch (e) {
            toast.error(
              `Error Hiding - ${e.data}.  Please contact ManufactOn support`,
            );
          }
        },
      };
      DialogProgrammatic.confirm(confirmParams);
    };
    const hideSchedule = async (schedule) => {
      const confirmParams = {
        title: 'Hide Activity',
        message: `Are you sure you want to Hide "${schedule.activityName}"?`,
        okButton: 'Hide',
        cancelButton: 'Cancel',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          try {
            schedule.isHidden = true;
            state.isLoading = true;
            await Schedule.updateSchedule(schedule);
            state.isLoading = false;
            toast.success('Schedule Updated');
            refreshTable();
          } catch (e) {
            toast.error(
              `Error Hiding - ${e.data}.  Please contact ManufactOn support`,
            );
          }
        },
      };
      DialogProgrammatic.confirm(confirmParams);
    };
    const unHideSchedule = async (schedule) => {
      const confirmParams = {
        title: 'Unhide Schedule?',
        message: `Are you sure you want to unhide '${schedule.activityName}'?`,
        okButton: 'Yes',
        cancelButton: 'No',
        type: 'danger',
        hasIcon: true,
        rootClass: 'danger-dialog',
        onConfirm: async () => {
          try {
            schedule.isHidden = false;
            state.isLoading = true;
            await Schedule.updateSchedule(schedule);
            state.isLoading = false;
            toast.success('Schedule Updated');
            refreshTable();
          } catch (e) {
            toast.error(
              `Error archiving - ${e.data}.  Please contact ManufactOn support`,
            );
          }
        },
      };
      DialogProgrammatic.confirm(confirmParams);
    };
    const cloneAction = async (event, disableCB) => {
      ModalProgrammatic.open({
        component: CloneCard,
        props: {
          card: event.data,
          headerTitle: 'Activity',
          packageName: 'Prefab Package',
          copyOpts: [
            {
              model: 'submittal',
              label: 'Linked Submittals',
              disabled: disableCB.submittal,
            },
            {
              model: 'order',
              label: 'Associated Items',
              disabled: disableCB.order,
            },
          ],
          fields: [
            {
              model: 'activityName',
              label: 'Activity Name',
            },
            {
              model: 'suffix',
              label: 'Activity Name Suffix',
              isEdit: true,
            },
            {
              model: 'activityId',
              label: 'New Activity ID',
            },
          ],
        },
        trapFocus: true,
        canCancel: true,
        rootClass: 'modal-sm',
        events: {
          'refresh-table': async () => {
            // eslint-disable-next-line no-unused-expressions
            await scheduleTable.value.refreshTable();
          },
        },
      });
    };
    const onCellClicked = async (event) => {
      if (event.event === 'updateSubmittal' && !state.vendors.length) {
        const vendors = await Vendors.getVendors({ limit: 1000, showTemporary: true });
        state.vendors = vendors.data;
      } else if (event.type === 'save') {
        try {
          const order = _.cloneDeep(event.data);
          state.isLoading = true;
          _.set(event.data, 'isEditing', !event.data.isEditing);
          const updatedOrder = await Schedule.updateSchedule(order);
          Object.assign(event.data, updatedOrder);
          refreshTable();
          state.isLoading = false;
        } catch (e) {
          console.log(e);
        }
      } else if (event.type === 'receiveShippingOrder' && event.data.shipping) {
        const { data } = event;
        const shipment = await Shipping.getDetailedLabel({
          projectId: data.project._id,
          shippingId: data.shipping._id,
        });
        ModalProgrammatic.open({
          component: ShippingReceive,
          props: { shippingLabel: shipment, redirectPath: '/prefab/schedule' },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          events: {
            close: (moved) => {
              if (moved) refreshTable();
            },
          },
        });
      } else if (event.type === 'openCard' && !!event.data.order) {
        const card = event.data;
        const stage = ['delivery'].includes(card.orderStage) ? 'ordering' : card.orderStage;
        router.push({
          name: 'sourcing-edit',
          params: {
            module: 'material',
            projectId: card.project._id,
            cardId: card.order._id,
            stage,
          },
          query: {
            fromScheduled: true,
          },
        });
      } else if (event.type === 'openShipping' && !!event.data.shipping) {
        const { data } = event;
        router.push({
          name: 'shipping-edit',
          params: { projectId: data.project._id, cardId: data.shipping._id },
        });
      } else if (event.type === 'unHide') {
        await unHideSchedule(_.cloneDeep(event.data));
      } else if (event.type === 'hide') {
        await hideSchedule(_.cloneDeep(event.data));
      } else if (event.type === 'archive') {
        await archiveSchedule(_.cloneDeep(event.data));
      } else if (event.type === 'clone') {
        const { data } = event;
        const disableCB = {};
        const { order, submittal } = data;
        if (!submittal) { disableCB.submittal = true; }
        if (!order) {
          disableCB.order = true;
        } else {
          const params = {
            projectId: data.project._id,
            orderId: data.order._id,
          };
          const [mm] = (await SupplyChain.supplyChain(params)).data;
          disableCB.order = _.some(
            mm.items,
            (item) => !['preparation', 'sourcing'].includes(item.stage),
          );
        }
        cloneAction(event, disableCB);
      } else if (['acceptUpdate', 'rejectUpdate'].includes(event.type)) {
        state.isLoading = true;
        await Schedule.resolveConflict({
          ids: [event.data._id],
          mode: event.type,
        });
        state.isLoading = false;
        refreshTable();
      } else if (event.type === 'activity') {
        state.isActive = true;
        state.activityDetails = {
          id: event.data._id,
          projectId: event.data.project._id,
          orderName: event.data.activityName,
        };
      } else if (event.type === 'excelImport') {
        state.excelImportModal = true;
      } else if (event.type === 'excelExport') {
        const ScreenCols = !_.isEmpty(store.state.colsMap[store.state.activeScreen])
          && !_.isBoolean(store.state.colsMap[store.state.activeScreen][0]?.visible)
          ? store.state.colsMap[store.state.activeScreen]
          : store.state.colsMap[store.state.activeScreen].filter((i) => i.visible === true);
        let columns = [];
        ScreenCols.forEach((obj) => {
          columns.push({
            field: obj.id,
            title: obj.title,
            show: true,
            disabled: false,
            sortAttr: obj.sortField,
          });
        });
        columns = columns.slice(0, columns.length - 1);
        const paramObj = {
          showAllCompanyOrders: !!store.state.queryParams.showAllCompanyOrders,
          projectId: store.state.queryParams.project?._id,
          showHidden: state.hiddenActivity || false,
          showCompleted: state.completeActivity || false,
          showArchived: state.showArchived || false,
          submittalResponse: store.getters.selectedValuesForKey('submittalResponse', false),
          status: store.getters.selectedValuesForKey('shippingStage', false),
          stage: store.getters.selectedValuesForKey('orderStage', false),
          vendors: _.isEmpty(store.state.queryParams.vendors) ? []
            : _.map(store.state.queryParams.vendors, '_id'),
          ...store.getters.selectedDatesForKeys([
            'deliverStartDate',
            'deliverEndDate',
            'submitByStartDate',
            'submitByEndDate',
            'approveByStartDate',
            'approveByEndDate',
            'orderByStartDate',
            'orderByEndDate',
            'shipByStartDate',
            'shipByEndDate',
            'deliverByStartDate',
            'deliverByEndDate',
          ]),
          name: 'Schedule',
          type: 'excel',
          module: ['Schedule'],
          exportFromCard: true,
          excelHeaders: { columns },
        };
        await ExcelExport.export(paramObj);
        toast.success('Excel report emailed to you');
      }
    };

    const methods = {
      async addVendor(vendor, rowData) {
        state.isLoading = true;
        await Vendors.addVendor(vendor);
        state.isOpen[rowData._id] = false;
        rowData.vendorData[0].isTemporary = false;
        state.isLoading = false;
        toast.success('Vendor Added');
        refreshTable();
      },
      changeVendor(row, vendor) {
        if (!_.isEmpty(vendor)) {
          const matchedVendor = _.find(state.vendors, { name: vendor.name });
          row.vendor = {
            _id: matchedVendor?._id,
            name: vendor.name,
            newVendor: !matchedVendor,
          };
        }
        // update on site date with item
      },
      selectedSubmittal(obj) {
        if (obj) {
          const row = state.selectedRow;
          row.submittal = obj;
          row.submittal.name = obj.fullName;
          for (const dateType of ['approveBy', 'submitBy', 'orderBy']) {
            const date = obj[dateType];
            if (!_.isEmpty(date)) {
              if (_.isEmpty(row[dateType])) { row[dateType] = { value: '', isValid: true }; }
              _.assign(row[dateType], { value: moment(date).hours(12).toDate() });
            }
          }
          row.submittalResponse = _.get(obj, 'submittalResponse', 'noWorkflow');
          methods.changeVendor(row, _.get(obj, 'vendor', null));
        }
        state.isListSubsActive = false;
      },
      async saveExcelItem(obj) {
        const { project } = store.state.queryParams;
        const projectId = _.isArray(project) ? project[0]._id : project._id;
        const projectName = _.isArray(project) ? project[0].name : project.name;
        _.map(obj, (schedule) => {
          schedule.project = {
            _id: projectId,
            name: projectName,
          };
        });
        try {
          await Schedule.importSchedules(obj);
        } catch (err) {
          state.importFailCount = +obj.length;
        }
      },
    };

    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      // eslint-disable-next-line no-unused-expressions
      scheduleTable?.value?.closeColumnChooserModal();
      // eslint-disable-next-line no-unused-expressions
      scheduleTable?.value?.refreshFilterOpts();
    };

    const closeModal = () => {
      state.excelImportModal = false;
    };

    const checkToDisableNext = (project) => {
      excel.value.checkToDisableNext(project);
    };
    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      state.selectedItem = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive && _.isUndefined(payload.fromMove);
    });

    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      state.selectedItem = {};
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
    });

    return {
      ...toRefs(state),
      loadData,
      onCellClicked,
      scheduleTable,
      stageFromUrl,
      ...methods,
      closeModal,
      refreshTable,
      isBulkActionEnabled,
      checkToDisableNext,
      excel,
      validateRow,
      selectedProject,
      triggerSetHeaderHeight,
      procoreUrl,
      closeNotesSlider,
    };
  },
};
</script>

<style scoped lang="sass"></style>
