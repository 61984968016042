export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  stickeyHeaders: true,
  toolBar: {
    buttons: [{
      event: 'excelExport',
      icon: 'icon-exportexcel',
      tooltip: 'Export to Excel',
    }],
  },
  fields: [
    {
      title: 'Item Name',
      name: 'field-generic-input',
      // sortField: 'name',
      prop: 'name',
      width: '400px',
      id: 'name',
      defaultVisible: true,
      alwaysVisible: true,
      sticky: true,
    },
    {
      title: 'Order ID',
      name: 'field-generic-input',
      prop: 'financialId',
      width: '152px',
      id: 'id',
      // sortField: 'financialId',
      isDisabled: false,
      defaultVisible: false,
    }, {
      name: 'field-generic-input',
      width: '296px',
      title: 'Source Order',
      prop: 'cardName',
      isClickable: false,
      eventType: 'openCard',
      id: 'sourceOrder',
      defaultVisible: true,
      isDisabled: true,
      inputProps: {
        type: 'text',
      },
    },
    {
      title: 'Project',
      name: 'field-generic-input',
      prop: 'project.name',
      // sortField: 'project',
      width: '296px',
      id: 'project',
      searchable: true,
      filterText: 'Projects',
      defaultVisible: true,
    }, {
      title: 'Status',
      name: 'field-stage',
      prop: 'statusToShow',
      width: '196px',
      id: 'shippingStatus',
      searchable: true,
      filterText: 'Status',
      defaultVisible: true,
      props: {
        isStatus: true,
      },
    }, {
      title: 'Recipient',
      name: 'field-location-select',
      prop: 'delivery.recipient',
      // sortField: 'delivery.recipient.name',
      width: '152px',
      id: 'recipients',
      searchable: true,
      filterText: 'Recipients',
      defaultVisible: true,
    },
    {
      title: 'Current Location',
      name: 'field-location-select',
      // sortField: 'delivery.currentLocation.name',
      width: '296px',
      defaultVisible: true,
      id: 'nowAt',
      searchable: true,
      filterText: 'Locations',
      show: true,
      prop: 'delivery.currentLocation',
      inputProps: {
        kind: 'delivery.currentLocation',
        disableEdit: true,
      },
    },
    {
      title: 'Delivery Location',
      name: 'field-location-select',
      prop: 'delivery.deliveryLocation',
      // sortField: 'delivery.deliveryLocation.name',
      width: '296px',
      defaultVisible: true,
      id: 'deliveryLocation',
      alwaysVisible: false,
      searchable: true,
      filterText: 'Locations',
    },
    {
      name: 'field-generic-input',
      width: '112px',
      title: 'QTY',
      id: 'quantity',
      prop: 'quantity',
      defaultVisible: true,
    }, {
      title: 'Deliver By',
      name: 'field-date',
      defaultVisible: true,
      alwaysVisible: false,
      prop: 'delivery.deliverBy',
      kind: 'dates',
      id: 'deliveryDate',
      width: '152px',
      searchable: true,
      dateFilter: 'deliver',
      sortField: 'dates.deliverBy',
      inputProps: {
        kind: 'delivery.deliverBy',
        min: 'delivery.deliveryStart',
        disableEdit: false,
      },
    }, {
      title: 'Ship By',
      name: 'field-date',
      defaultVisible: true,
      prop: 'delivery.deliveryStart',
      kind: 'dates',
      width: '152px',
      id: 'shipDate',
      searchable: true,
      dateFilter: 'ship',
      inputProps: {
        kind: 'delivery.deliveryStart',
        max: 'delivery.deliverBy',
        disableEdit: false,
      },
    }, {
      title: 'Onsite(Final)',
      id: 'onSiteFinal',
      name: 'field-date',
      defaultVisible: true,
      prop: 'minItemDelivery',
      kind: 'dates',
      searchable: true,
      dateFilter: 'minItemDelivery',
      width: '152px',
      inputProps: {
        kind: 'minItemDelivery',
        disableEdit: false,
      },
    },
    {
      title: 'Actions',
      name: 'action',
      id: 'action',
      searchable: true,
      clearBtn: true,
      sticky: true,
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      width: '112px',
      hideFromChooser: true,
      alwaysVisible: true,
    },
  ],
};
