import _ from 'lodash';
import GenerateRequest from './resources';
import User from './UserClass';

const req = GenerateRequest();

export default {
  async allUsers(params) {
    params = _.extend({
      response: 'normal',
    }, params);
    try {
      const result = await req.get('users/getAllUsers', {
        params,
      });
      const items = result.data.items.map(user => new User(user));
      return {
        data: items,
        total: result.data.totalCount,
      };
    } catch (e) {
      // TODO: Add an alert here?
      throw e;
    }
  },
  async archiveViewMember(params) {
    try {
      const user = await req.delete(`users/archiveViewMember/${params._id}`, params);
      return user;
    } catch (e) {
      throw e;
    }
  },
  async getAllViewMembers(params) {
    const companyId = params.user.company;
    delete params.user;
    const { data } = await req.get('users/getAllViewMembers', {
      params,
    });
    for (const user of data.items) {
      user.company = _.find(user.viewCompanies, { _id: companyId });
      if (user.company) {
        for (const permission of user.company.permissions) {
          user[permission] = true;
        }
        user.archived = _.get(user.company, 'archived.value', false);
      }
    }
    return {
      data: data.items,
      total: data.totalCount,
    };
  },
  async signUp(user, company) {
    try {
      const { data } = await req.post('users/createGC', {
        user,
        company,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async listProjectViewUsers(params) {
    const companyId = params.user.company;
    delete params.user;
    const { data } = await req.get('users/getAllViewMembers', {
      params,
    });
    for (const user of data.items) {
      user.company = _.find(user.viewCompanies, { _id: companyId });
      user.companyName = _.get(user.company, 'name', '');
    }
    return {
      items: data.items,
      data: data.items,
      total: data.totalCount,
    };
  },
  async getUserToActivate(email, key) {
    try {
      const { data } = await req.post('accounts/getUserByEmailandKey', {
        email,
        key,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async activateAccount(userDetails) {
    try {
      const { data } = await req.post('accounts/activateAccount', { userDetails });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async login(user) {
    try {
      const { data } = await req.post('login', {
        email: user.email,
        password: user.password,
      });
      data.user = new User(data.user);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async appLogin(user) {
    try {
      const { data } = await req.post('login', {
        email: user.email,
        password: user.password,
        device: user.device,
      });
      data.user = new User(data.user);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async logout() {
    try {
      const { data } = await req.get('logout');
      return data;
    } catch (e) {
      throw e;
    }
  },
  async forgotPassword(user) {
    try {
      const { data } = await req.post('users/forgotPassword', { email: user.email });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async getUser() {
    try {
      const { data } = await req.get('users/me');
      return new User(data);
    } catch (e) {
      throw e;
    }
  },
  async allCompanies(params) {
    try {
      const result = await req.get('users/allCompanies', { params });
      return {
        data: result.data.items,
        total: result.data.totalCount,
      };
    } catch (e) {
      throw e;
    }
  },
  async inviteViewUsersToProject(params) {
    return (await req.post('users/inviteViewUsersToProject', params)).data;
  },
  async updateViewUser(user) {
    try {
      const updatedUser = await req.post('users/updateViewUser', user);
      return updatedUser;
    } catch (e) {
      throw e;
    }
  },
  async editUser(usr) {
    try {
      const user = await req.post('users/editUser', usr);
      user.data = new User(user.data);
      return user;
    } catch (e) {
      throw e;
    }
  },
  async archiveCompanyUser(usr) {
    try {
      const user = await req.delete(`users/${usr._id}`, usr);
      return user;
    } catch (e) {
      throw e;
    }
  },
  async getCompanyProjectAllLinkedUsers(queryParams) {
    try {
      const users = await req.get('users/getCompanyProjectAllLinkedUsers', queryParams);
      return _.sortBy(users.data, 'name');
    } catch (e) {
      throw e;
    }
  },
  groupUsers(users) {
    let gUsers = _.groupBy(users, 'companyName');
    gUsers = _.map(gUsers, (val, key) => ({
      val,
      key,
    }));
    gUsers.forEach((user) => {
      user.val = _.sortBy(user.val, 'name');
    });
    return gUsers;
  },
  async getGroupedProjectLinkedUsers(companyIds, projectId) {
    const allUsers = await this.getCompanyProjectAllLinkedUsers({
      params: {
        companyIds: _.castArray(companyIds),
        projectId,
      },
    });
    return this.groupUsers(allUsers);
  },
  async resetPassword(password, userObj) {
    try {
      const user = await req.post('users/changePassword', {
        password,
        user: userObj,
      });
      return user.data;
    } catch (e) {
      throw e;
    }
  },
  async updateAccount(userObj, imageUrl, signatureUrl) {
    try {
      const user = await req.post('users/updateAccount', {
        user: userObj,
        imageUrl,
        signatureUrl,
      });
      return user.data;
    } catch (e) {
      throw e.response;
    }
  },
  async getCalendarDetails() {
    try {
      const calendar = await req.get('ics/settings');
      return calendar.data;
    } catch (e) {
      throw e.response;
    }
  },
  async sendActivationMail(params) {
    try {
      return (await req.post(`users/sendActivationMail?id=${params.id}`)).data;
    } catch (e) {
      throw e.response;
    }
  },
  async resendActivateUser(params) {
    try {
      return (await req.post(`users/reactivateUser/?id=${params.id}`)).data;
    } catch (e) {
      throw e.response;
    }
  },
  async isPurchaseManager(params) {
    try {
      return (await req.get(`/users/isPMForProject?id=${params.id}`)).data;
    } catch (e) {
      throw e.response;
    }
  },
  async removeViewUser(params) {
    const { data } = await req.delete(`users/removeViewUserFromProject/${params.id}/${params.userId}`);
    return data;
  },
  async clearDevices(params) {
    try {
      const { data } = await req.post('users/clearDevices', params);
      return data;
    } catch (e) {
      throw e.response;
    }
  },
  async sendDeviceLogs(params) {
    try {
      return (await req.post('users/sendDeviceLogs', params)).data;
    } catch (e) {
      throw e;
    }
  },
  async getUsers(params) {
    try {
      const { data } = await req.get('users/getUsers', { params });
      return data.map(u => new User(u));
    } catch (e) {
      throw e;
    }
  },
  getPermissionValue(key, value, userData, allProjects, selectedCompany) {
    const { resolvedPermissionGroups } = userData;
    const { permissionModule, rowData: data } = value;
    let permissionKey = ['save', 'openOrder'].includes(key) ? 'data' : key;
    if (key === 'transfer_request_list') permissionKey = 'transfer_request';
    const projectKeyExists = data && data.project
      && _.some(userData.permissionSettings, { project: data.project._id });
    const projectId = '000000000000000000000000';
    const companyToConsider = !_.isEmpty(selectedCompany) ? selectedCompany : userData.company;
    let projectKey = `${companyToConsider}:ProjectId:${permissionModule}:${permissionKey}`;
    if (projectKeyExists) {
      projectKey = projectKey.replace('ProjectId', data.project._id);
      if (!resolvedPermissionGroups[projectKey]) {
        projectKey = projectKey.replace(`${data.project._id}`, projectId);
      }
    } else projectKey = projectKey.replace('ProjectId', projectId);
    const userPartofProject = data && data.project
      && _.some(allProjects, { _id: data.project._id });
    const userHasDataViewPermission = !['none'].includes(resolvedPermissionGroups[projectKey]);
    const userHasDataEditPermission = !['none', 'view', 'viewPartial'].includes(resolvedPermissionGroups[projectKey]);
    const userHasTRPermission = ['alter'].includes(resolvedPermissionGroups[projectKey]);
    const canOpenOrder = !['viewPartial'].includes(resolvedPermissionGroups[projectKey]);
    if (key === 'data') {
      return userHasDataViewPermission && userPartofProject;
    }
    if (key === 'transfer_request_list') {
      return userHasTRPermission;
    }
    if (key === 'openOrder') return canOpenOrder;
    return userHasDataEditPermission;
  },
  filterVisibleModules(permission, company = '[0-9a-z]{24}') {
    const allowedKeys = [];
    for (const key of Object.keys(permission)) {
      if (permission[key] !== 'none') allowedKeys.push(key);
    }
    const mergedKeys = `|${allowedKeys.join('||')}|`;
    const regex = new RegExp('\\|([0-9a-z]{24}):([0-9a-z]{24}):([a-z:&\\-_]+)\\|', 'g');
    const companyRegex = new RegExp(`\\|(${company}):([0]{24}):([a-z:&\\-_]+)\\|`, 'g');
    let companyLvlModules = Array.from(mergedKeys.matchAll(companyRegex));
    companyLvlModules = companyLvlModules.map(a => a[3]);
    let allowedModules = Array.from(mergedKeys.matchAll(regex));
    allowedModules = allowedModules.map(a => a[3]);
    return { allModules: allowedModules, companyModules: companyLvlModules };
  },
};
