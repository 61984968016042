import _ from 'lodash';
import validateLmv from '@/components/utils/lmv3dotValidation';

const commonFields = [
  {
    title: 'Material Request',
    name: 'field-generic-input',
    sortField: 'name',
    prop: 'name',
    width: '400px',
    id: 'name',
    isClickable: true,
    sticky: true,
    eventType: 'openCard',
    defaultVisible: true,
    alwaysVisible: true,
    searchField: false, // to give a search bar for the field
    searchable: true, // to place any custom field in the header of the column like for below
    bulkActionButtonField: true,
    inputProps: {
      type: 'text',
    },
  },
  {
    name: 'field-generic-input',
    isDisabled: false,
    defaultVisible: false,
    sortField: 'financialId',
    width: '152px',
    title: 'Description',
    prop: 'financialId',
    id: 'financialId',
  },
];

const actionField = {
  title: 'Actions',
  name: 'table-field-actions',
  id: 'action',
  hideFromChooser: true,
  alwaysVisible: true,
  width: '112px',
  searchable: true,
  clearBtn: true,
  permissionModule: 'material-manager',
  sticky: true,
  thAttrs: () => ({ class: 'action-sticky' }),
  tdClass: 'action-sticky',
  inputProps: {
    showEdit: true,
    options(card, store, user, actualStage) {
      let opts = [];
      const exportOpts = {
        icon: 'icon-export',
        label: 'Export Items',
        event: 'export',
      };
      if (card.stage === 'preparation' && !_.some(card.items, ['stage', 'rejected'])) {
        opts = [
          {
            label: 'Clone',
            event: 'clone',
            icon: 'icon icon-clone',
          },
          {
            label: 'Delete',
            event: 'remove',
            icon: 'icon-removedelete',
          },
          {
            label: 'History',
            event: 'activity',
            icon: 'fa fa-history',
          },
        ];
      } else if (card.stage === 'ordering' || actualStage === 'ordering') {
        opts = [
          {
            icon: 'fa fa-qrcode',
            label: 'Print QR',
            event: 'printQr',
          },
          exportOpts,
        ];
        if (!card.isLocked) {
          opts.push({
            label: 'Delete',
            event: 'remove',
            icon: 'icon-removedelete',
          });
        }
        opts.concat([
          exportOpts,
          {
            label: 'History',
            event: 'activity',
            icon: 'fa fa-history',
          }]);
      } else if (card.stage === 'delivery' && card.__t === 'Sourcing') {
        opts = [
          {
            label: 'Delete',
            event: 'remove',
            icon: 'icon-removedelete',
          },
          {
            label: 'History',
            event: 'activity',
            icon: 'fa fa-history',
          },
          exportOpts,
          {
            icon: 'fa fa-qrcode',
            label: 'Print QR',
            event: 'printQr',
          },
        ];
      } else if (card.__t === 'Sourcing' && card.stage === 'sourcing') {
        opts = [
          {
            label: 'Clone',
            event: 'clone',
            icon: 'icon icon-clone',
            isDisabled() {
              return card.fromSchedule;
            },
          },
          {
            label: 'Reject',
            event: 'reject',
            icon: 'icon-reject',
          },
          exportOpts,
          {
            label: 'History',
            event: 'activity',
            icon: 'fa fa-history',
          },
        ];
        const extraOpts = [
          {
            label: 'Split',
            event: 'split',
            icon: 'icon-split',
            isDisabled() {
              return card.fromSchedule;
            },
          },
        ];
        opts = _.concat(opts, extraOpts);
      } else if (card.stage === 'preparation') {
        opts = [
          {
            label: 'Export Items',
            event: 'export',
            icon: 'icon-export',
          },
          {
            icon: 'fa fa-qrcode',
            label: 'Print QR',
            event: 'printQr',
          },
        ];
      } else {
        opts = [{
          label: 'Delete',
          event: 'remove',
          icon: 'icon-removedelete',
        },
        {
          label: 'History',
          event: 'activity',
          icon: 'fa fa-history',
        }];
      }
      if (validateLmv.validate3dots(card.items)) {
        opts.push({
          icon: 'icon-linked-model',
          label: 'View Model',
          event: 'lmv',
        });
      }
      return opts;
    },
  },
};

export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: true,
  rearrangeCols: false,
  detailRowComponent: 'mf-detail-row',
  permissionModule: 'material-manager',
  stickeyHeaders: true,
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      tooltip: 'Customize Columns',
    }],
  },
  toolBar: {
    // name: for drop down label, label: for action button label
    bulkActions: (stage) => {
      const opts = [];
      if (stage === 'ordering') {
        opts.push({
          name: 'Print QR Labels',
          event: 'bulkQrPrint',
          label: 'Print QR',
          refreshOnCancel: true,
        });
      }
      if (stage === 'preparation') {
        opts.push({
          name: 'Move Orders To Sourcing', event: 'moveForward', label: 'Move Forward',
        });
        opts.push({
          name: 'Move Kits To Sourcing',
          event: 'moveForwardKits',
          label: 'Move Forward',
          refreshOnCancel: true,
        });
      }
      if (stage === 'sourcing') {
        opts.push({
          name: 'Create Purchase Orders and Shipping Orders', event: 'moveForward', label: 'Create', refreshOnCancel: true,
        },
        {
          name: 'Combine Orders (limit 2)', event: 'combine', label: 'Combine Orders', refreshOnCancel: true,
        },
        {
          name: 'Reorganize Items', event: 'reorganize', label: 'Reorganize Items', refreshOnCancel: true, hideBulkActionBtns: true,
        });
      }
      return opts;
    },
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: (stage) => {
      const buttons = [{
        event: 'addNew',
        icon: 'icon-addnew',
        tooltip: stage === 'ordering' ? 'Create Purchase Order' : 'Add',
        isLine: true,
      }];
      if (stage === 'preparation') {
        buttons.push(
          {
            event: 'excelImport',
            showOptionsForStage: 'preparation',
            icon: 'icon-importexcel',
            tooltip: 'Import From Excel',
          },
        );
      }
      return buttons;
    },
  },
  fields: [
    {
      'mm-preparation': [
        ...commonFields,
        {
          title: 'Kit ID',
          name: 'field-kit-id',
          prop: 'customId',
          width: '152px',
          id: 'customId',
          sortField: 'customId',
          isDisabled: true,
          defaultVisible: true,
          alwaysVisible: false,
        },{     
          title: 'Activity',
          name: 'field-generic-input',
          prop: 'activity',
          sortField: 'activity',
          searchable: true,
          width: '152px',
          id: 'activity',
          isDisabled: false,
          defaultVisible: false,
          alwaysVisible: false,
        }, {
          title: '',
          name: 'lmvIcon',
          width: '64px',
          id: 'lmvIcon',
          defaultVisible: true,
          alwaysVisible: false,
          customHeader: true,
          customSlotHeader: true,
          inputProps: {
            icon: 'icon-linked-model',
          },
        },
        {
          title: 'Project',
          name: 'field-generic-input',
          prop: 'project.name',
          sortField: 'project',
          searchable: true,
          filterText: 'Projects',
          width: '296px',
          id: 'project',
          isDisabled: true,
          defaultVisible: true,
          alwaysVisible: false,
        },
        {
          title: 'Stage',
          name: 'field-stage',
          prop: 'stage',
          searchable: true,
          filterText: 'Stages',
          id: 'materialStage',
          width: '152px',
          defaultVisible: true,
          alwaysVisible: false,
          sortField: 'stage',
          cardProps: {
            cardType: '__t',
          },
        }, {
          title: 'Owner',
          name: 'field-user-select',
          prop: 'owner.user',
          width: '296px',
          sortField: 'owner.user',
          id: 'owners',
          searchable: true,
          filterText: 'Owners',
          defaultVisible: true,
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Company',
          name: 'field-generic-input',
          id: 'company',
          prop: 'owner.company.name',
          // searchable: true,
          // filterText: 'Companies',
          width: '296px',
          sortField: 'owner.company',
          defaultVisible: false,
          alwaysVisible: false,
          isDisabled: true,
          iPadVisible: true,
        },
        {
          title: 'Vendor',
          name: 'field-vendor-select',
          prop: 'baseDelivery.vendor',
          sortField: 'baseDelivery.vendor',
          width: '296px',
          id: 'vendors',
          searchable: true,
          filterText: 'Vendors',
          defaultVisible: false,
          cardProps: {
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Location',
          name: 'field-location-select',
          prop: 'baseDelivery.location',
          sortField: 'baseDelivery.location',
          width: '296px',
          id: 'locations',
          searchable: true,
          filterText: 'Locations',
          defaultVisible: true,
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Recipient',
          name: 'field-user-select',
          prop: 'baseDelivery.recipient',
          sortField: 'baseDelivery.recipient',
          width: '296px',
          id: 'recipients',
          searchable: true,
          filterText: 'Recipients',
          defaultVisible: false,
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
          inputProps: {
            kind: 'baseDelivery.recipient',
          },
        },
        {
          title: 'Level',
          name: 'field-lvl-zone',
          sortField: 'level',
          isDisabled: false,
          width: '296px',
          id: 'level',
          prop: 'baseDelivery.level',
          hideFromChooser: false,
          alwaysVisible: false,
          searchable: true,
          searchField: 'baseDelivery.level',
          searchPlaceHolder: 'Level',
          filterText: 'Level',
          inputProps: {
            options: 'levels',
          },
        },
        {
          title: 'Zone',
          name: 'field-lvl-zone',
          isDisabled: false,
          sortField: 'baseDelivery.zone',
          width: '296px',
          id: 'zone',
          prop: 'baseDelivery.zone',
          hideFromChooser: false,
          alwaysVisible: false,
          searchField: 'baseDelivery.zone',
          searchable: true,
          searchPlaceHolder: 'Zone',
          filterText: 'Zone',
          iPadVisible: true,
          inputProps: {
            options: 'zones',
          },
        },
        {
          title: 'Earliest Order By',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          sortField: 'dates.orderBy',
          prop: 'orderBy',
          kind: 'dates',
          width: '152px',
          id: 'orderBy',
          searchable: true,
          dateFilter: 'orderBy',
          inputProps: {
            kind: 'orderBy',
            max: ['available', 'shipBy', 'deliver'],
            disableEdit: true,
          },
        },
        {
          title: 'Onsite',
          name: 'field-date',
          searchable: true,
          dateFilter: 'deliver',
          sortField: 'dates.deliver',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'deliver',
          kind: 'dates',
          width: '152px',
          id: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: ['orderBy', 'available', 'shipBy'],
            disableEdit: (data) => {
              if (['complete', 'delivery'].includes(data.stage)) {
                return true;
              }
              return false;
            },
          },
        },
        {
          name: 'field-notes-icon',
          title: 'Notes',
          prop: 'notes',
          defaultVisible: true,
          width: '64px',
          id: 'notes',
          inputProps: {
            isItem: false,
          },
        },
        actionField,
      ],
    },
    {
      'mm-sourcing': [
        ...commonFields,
        {
          title: 'Kit ID',
          name: 'field-kit-id',
          prop: 'customId',
          width: '152px',
          id: 'customId',
          sortField: 'customId',
          isDisabled: true,
          defaultVisible: false,
          alwaysVisible: false,
        },{     
          title: 'Activity',
          name: 'field-generic-input',
          prop: 'activity',
          sortField: 'activity',
          searchable: true,
          width: '152px',
          id: 'activity',
          isDisabled: false,
          defaultVisible: false,
          alwaysVisible: false,
        }, {
          title: ' ',
          name: 'lmvIcon',
          width: '64px',
          id: 'lmvIcon',
          defaultVisible: true,
          alwaysVisible: false,
          customHeader: true,
          customSlotHeader: true,
          inputProps: {
            icon: 'icon-linked-model',
          },
        },
        {
          title: 'Project',
          name: 'field-generic-input',
          prop: 'project.name',
          sortField: 'project',
          searchable: true,
          filterText: 'Projects',
          width: '296px',
          id: 'project',
          isDisabled: true,
          defaultVisible: true,
          alwaysVisible: false,
        },
        {
          title: 'Stage',
          name: 'field-stage',
          prop: 'stage',
          searchable: true,
          filterText: 'Stages',
          sortField: 'stage',
          id: 'materialStage',
          width: '152px',
          defaultVisible: false,
          alwaysVisible: false,
          cardProps: {
            cardType: '__t',
          },
        },
        {
          title: 'Owner',
          name: 'field-user-select',
          prop: 'owner.user',
          width: '296px',
          sortField: 'owner.user',
          id: 'owners',
          defaultVisible: true,
          searchable: true,
          filterText: 'Owners',
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Company',
          name: 'field-generic-input',
          id: 'company',
          prop: 'owner.company.name',
          // searchable: true,
          // filterText: 'Companies',
          width: '296px',
          sortField: 'owner.company',
          defaultVisible: false,
          alwaysVisible: false,
          isDisabled: true,
          iPadVisible: true,
        }, {
          title: 'Vendor',
          name: 'field-vendor-select',
          prop: 'baseDelivery.vendor',
          sortField: 'baseDelivery.vendor',
          width: '296px',
          id: 'vendors',
          searchable: true,
          filterText: 'Vendors',
          defaultVisible: true,
          cardProps: {
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Location',
          name: 'field-location-select',
          prop: 'baseDelivery.location',
          sortField: 'baseDelivery.location',
          width: '296px',
          id: 'locations',
          searchable: true,
          filterText: 'Locations',
          defaultVisible: true,
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Recipient',
          name: 'field-user-select',
          prop: 'baseDelivery.recipient',
          sortField: 'baseDelivery.recipient',
          width: '296px',
          id: 'recipients',
          defaultVisible: true,
          searchable: true,
          filterText: 'Recipients',
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
          inputProps: {
            kind: 'baseDelivery.recipient',
          },
        }, {
          title: 'Level',
          name: 'field-lvl-zone',
          sortField: 'level',
          isDisabled: false,
          width: '296px',
          id: 'level',
          prop: 'baseDelivery.level',
          hideFromChooser: false,
          alwaysVisible: false,
          searchable: true,
          searchField: 'baseDelivery.level',
          searchPlaceHolder: 'Level',
          filterText: 'Level',
          inputProps: {
            options: 'levels',
          },
        },
        {
          title: 'Zone',
          name: 'field-lvl-zone',
          isDisabled: false,
          sortField: 'baseDelivery.zone',
          width: '296px',
          id: 'zone',
          prop: 'baseDelivery.zone',
          hideFromChooser: false,
          alwaysVisible: false,
          searchField: 'baseDelivery.zone',
          searchable: true,
          searchPlaceHolder: 'Zone',
          filterText: 'Zone',
          iPadVisible: true,
          inputProps: {
            options: 'levels',
          },
        }, {
          title: 'Earliest Order By',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          sortField: 'dates.orderBy',
          prop: 'orderBy',
          kind: 'dates',
          width: '152px',
          id: 'orderBy',
          searchable: true,
          dateFilter: 'orderBy',
          inputProps: {
            kind: 'orderBy',
            max: ['available', 'shipBy', 'deliver'],
            disableEdit: true,
          },
        }, {
          title: 'Onsite',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          sortField: 'dates.deliver',
          prop: 'deliver',
          kind: 'dates',
          width: '152px',
          id: 'deliver',
          searchable: true,
          dateFilter: 'deliver',
          inputProps: {
            kind: 'deliver',
            min: ['orderBy', 'available', 'shipBy'],
            disableEdit: false,
          },
        },
        {
          name: 'field-notes-icon',
          title: 'Notes',
          prop: 'notes',
          defaultVisible: true,
          width: '64px',
          id: 'notes',
          inputProps: {
            isItem: false,
          },
        },
        actionField,
      ],
    },
  ],
};
