<template>
    <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
    <div class="list-view">
        <mf-table
                ref="ownerChangeTable"
                :tableProps="tableProps"
                :hideGutter="true"
                :loadData="loadData"
                :screenTable="true"
                tableName="ownerChangeTable"
                @cell-clicked="$emit('cell-clicked', $event)"
        >
            <template v-slot:orderType="{rowData}">
                {{$_.get(rowData, 'module', '')}}
            </template>
            <template v-slot:oldOwner="{rowData}">
                {{$_.get(rowData, 'ownerChange[0].name', '')}}
            </template>
            <template v-slot:newOwner="{rowData}">
                {{$_.get(rowData, 'ownerChange[1].name', '')}}
            </template>
            <template v-slot:dateOfChange="date">
              <span class=" is-pulled-right">
                {{$filters.usaDate(date.rowData.timeStamp)}}
              </span>
            </template>
             <template v-slot:module="data">
               {{$_.startCase(data.rowData.module)}}
            </template>
        </mf-table>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import Activity from '@/models/Activity';
import { extend, keys } from 'lodash';
import ownerViewCols from '@/components/table-cols/ownerChangeViewCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import {
  ref, reactive, toRefs,
} from 'vue';

export default ({
  props: {
    showEmpty: Boolean,
    moduleName: Object,
  },
  components: {
    MfTable,
  },
  setup(props) {
    const store = useStore();
    const ownerChangeTable = ref(null);
    const state = reactive({
      tableProps: ownerViewCols,
      isLoading: false,
    });

    const refreshTable = async () => {
      await ownerChangeTable.value?.refreshTable();
    };
    const loadData = async (commonParam) => {
      const result = await Activity.getrecentEvents({
        params: {
          projectId: store.getters.selectedIdsForKey('filteredProjects'),
          createdCompany: store.getters.selectedIdsForKey('companies', false),
          ...store.getters.selectedDatesForKeys([
            'DateStartDate',
            'DateEndDate',
          ]),
          fieldChange: ['Owner'],
          search: store.state.queryParams.searchText,
          module: store.state.queryParams.module.map((mod) => mod.value),
          ...commonParam,
        },
      });

      result.data = result.data.filter((data) => {
        const moduleName = data.module.charAt(0).toLowerCase() + data.module.slice(1);
        return data[moduleName];
      }).map((ownerAct) => {
        extend(ownerAct, ownerAct.order);
        if (keys(props.moduleName).indexOf(ownerAct.module) > -1) {
          ownerAct.cardId = ownerAct[props.moduleName[ownerAct.module].module]._id;
        }
        return ownerAct;
      });
      return result;
    };

    return {
      ...toRefs(state),
      ownerChangeTable,
      loadData,
      refreshTable,
    };
  },
});
</script>
