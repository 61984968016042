<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="card-edit" v-on="dirtyListeners" :key="refreshKey">
    <card-edit-subheader
      v-if="cardLoaded"
      type="shipping-edit"
      :card="shipment"
      :status="shipment.status"
    />
    <div class="edit-body">
      <shipping-basics
        v-if="cardLoaded"
        :type="shipmentMode"
        :shippingLabel="shipment"
        :projectUsers="projectUsers"
        :projectLocations="projectLocations"
        :disableFieldsForInstorage="disableFieldsForInstorage"
        :shipFromInventory="isShipFromInventory"
        :refreshFunction="refreshFunction"
      />
      <!-- table section goes below -->
      <div class="card-edit-item-table has-background-white shipping-card">
        <mf-detail-row
          v-if="cardLoaded"
          :rowData="shipment"
          :key="refreshKey"
          :refreshCard="loadCard"
          @tabName="setTabName"
          :tabLabel="selectedTabName"
          @cell-clicked="onCellClicked"
          :deliveryDetails="deliveryDetails"
          @refreshCardViewOnKey="refreshCardOnKey"
        />
      </div>
    </div>
    <!-- Todo: replace below footer with card-footer -->
    <footer class="card-footer">
      <shipping-buttons
        v-if="cardLoaded && !isCreateShipping"
        :showSave="isDirty"
        type="shipping-edit"
        :card="shipment"
        :status="shipment.status"
        @event="buttonEvent($event)"
        @reload-card="loadCard"
      />
      <send-shipping-list
        v-if="cardLoaded"
        :offSetValue="[0, 34]"
        :isVisible="isShippingListVisible"
        :value="shipment.externalEmails"
        @update:modal="(isShippingListVisible = false)"
        @update:value="updateExternalEmail"
        @sendShippingList="sendShippingList"
      />
      <div class="buttons" v-if="cardLoaded && isCreateShipping">
        <button class="button is-outlined is-danger">
          <span
            class="is-capitalized is-size-5 has-text-weight-bold"
            @click.prevent="cancelChanges"
          >
            Cancel
          </span>
        </button>
        <button
          class="button is-success"
          :disabled="isDisabled"
        >
          <span
            class="is-capitalized is-size-5 has-text-weight-bold"
            @click.prevent="createShippingOrder"
          >
            Create shipment
          </span>
        </button>
      </div>
    </footer>
    <transition name="slide">
      <notes-icon-slider
        v-if="deliveryNotes.isSlideNotesActive"
        :isActive="deliveryNotes.isSlideNotesActive"
        :rowData="deliveryNotes.selectedItem"
        :isShipping="deliveryNotes.isShipping"
        @close="closeNotesSlider"
        :isDisabled="deliveryNotes.isDisabled"
      />
    </transition>
    <transition name="slide">
      <item-document-slider
        v-if="deliveryNotes.isFileListVisible"
        :isActive="deliveryNotes.isFileListVisible"
        :rowData="deliveryNotes.selectedItem"
        :rowField="deliveryNotes.docRowField"
        @close="closeDocumentSlider"
        :isDisabled="deliveryNotes.isDisabled"
      />
    </transition>
    <o-modal
      v-if="isDeliveryItemsActive"
      :active="isDeliveryItemsActive"
      :canCancel="false"
      :trapFocus="true"
      class="modal-sm"
    >
      <delivery-items
        :rowData="isDeliveryItemsRow"
        :isUnscheduledRow="isUnscheduledRow"
        @close="toggleDeliveryItems"
      />
    </o-modal>
    <o-modal
      v-if="isCreateDelActive"
      :active="isCreateDelActive"
      :canCancel="false"
      :trapFocus="true"
      class="modal-sm"
    >
      <create-deliveries
        :card="createDelRow"
        :masterShipment="mS"
        @close="toggleCreateDel"
      />
    </o-modal>
    <o-loading :active="isLoading" />
  </div>

</template>

<script>
import {
  reactive, toRefs, onBeforeMount, onBeforeUnmount, onMounted, computed, inject,
} from 'vue';
import { useStore } from 'vuex';
import Shipping from '@/models/Shipping';
import ShippingLabel from '@/models/ShippingLabel';
import CardEditSubHeader from '@/components/card-edit/CardEditSubheader.vue';
import ShippingBasics from '@/components/card-edit/ShippingBasics.vue';
import MfDetailRow from '@/components/table-fields/MfDetailRow.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import Projects from '@/models/Projects';
import Locations from '@/models/Locations';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import ShippingReceive from '@/components/modals/ShippingReceive.vue';
import ShippingButtons from '@/components/card-edit/ShippingButtons.vue';
import _ from 'lodash';
import SendShippingList from '@/components/modals/SendShippingList.vue';
import CreateDeliveries from '@/components/modals/CreateDeliveries.vue';
import DeliveryItems from '@/components/modals/DeliveryItems.vue';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';
import { useRoute, useRouter } from 'vue-router';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import { useToast } from 'vue-toastification';
import UtilityMixin from '../../components/mixins/UtilityMixin';

export default {
  name: 'shipping-edit',
  components: {
    'card-edit-subheader': CardEditSubHeader,
    'shipping-basics': ShippingBasics,
    'mf-detail-row': MfDetailRow,
    'shipping-buttons': ShippingButtons,
    'send-shipping-list': SendShippingList,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
    CreateDeliveries,
    DeliveryItems,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    cardId: {
      type: String,
      required: true,
    },
    stage: {
      type: String,
    },
    orderJSON: {
      type: String,
      default: null,
    },
    orderCard: {
      type: String,
      default: null,
    },
    shipFromInventory: {
      type: String,
      default: 'false',
    },
    cards: {
      type: Array,
      default: () => null,
    },
    isMultiShipment: {
      type: Boolean,
      defaulr: false,
    },
  },
  setup(props) {
    const {
      cardId,
      projectId,
      orderJSON,
    } = props;
    const toast = useToast();
    const store = useStore();
    const emitter = inject('emitter');
    const router = useRouter();
    const route = useRoute();
    const { onInitDirty } = CardEditMixin();
    const { getAllLocations } = UtilityMixin();
    const {
      deleteOrder,
      markOrderFinal,
      releaseToInv,
      cancelShipment,
      getInventoryItems,
      scheduleDelivery,
      updateShipment,
      consumeAndUnreserve,
      generateShipPdf,
    } = GeneralShippingMixin();
    const data = reactive({
      shipment: {},
      cardLoaded: false,
      isLoading: false,
      projectUsers: {},
      projectLocations: {},
      isShippingListVisible: false,
      disableFieldsForInstorage: false,
      isCreateShipping: false,
      itemsData: [],
      refreshKey: 0,
      orderCard: {},
      selectedTabName: '',
      updatedShipment: {},
      deliveryNotes: {
        isSlideNotesActive: false,
        isFileListVisible: false,
        selectedItem: {},
        isDisabled: true,
      },
      isDeliveryItemsRow: {},
      isUnscheduledRow: false,
      createDelRow: {},
      mS: {},
      isCreateDelActive: false,
      isDeliveryItemsActive: false,
      deliveryDetails: [],
      isShipFromInventory: props.shipFromInventory.toLowerCase() === 'true',
    });
    onInitDirty(data);

    // delivery details icon
    const setDeliveryDetails = (order) => {
      const shippingOrder = _.cloneDeep(order);
      if (shippingOrder.partialShipments) {
        shippingOrder.partialShipments.forEach((o) => {
          o.items.forEach((i) => {
            i.slName = o.name;
          });
        });
        let partialOrderItems = shippingOrder.partialShipments.map((ord) => ord.items);
        partialOrderItems = _.flatten(partialOrderItems);
        data.deliveryDetails = _.groupBy(partialOrderItems, (item) => (item._id === '000000000000000000000000' ? item.catId : item._id));
      }
    };

    // methods
    // TODO: need to call from mixin
    const loadCard = async () => {
      onInitDirty(data);
      data.isLoading = true;
      let order;
      if (props.cardId !== 'add') {
        // Incase we have a updated shipment id after master split occured
        const mShipId = localStorage.getItem('shipmentId');
        if (mShipId && mShipId.length) {
          router.push({
            name: 'shipping-edit',
            params: {
              cardId: mShipId,
              projectId,
            },
          });
        }
        const masterShipment = await Shipping.getPartialShipments({
          shippingLabelId: cardId,
        });
        order = masterShipment;
      } else if (props.cards && props.isMultiShipment) {
        order = new ShippingLabel(JSON.parse(orderJSON));
        order.items.forEach((item) => {
          item.quantity = item.available;
        });
        const cards = JSON.parse(props.cards);
        if (cards && cards.length) {
          for (const c of cards) {
            const materialItems = await getInventoryItems(c);
            const materialItemsToLoad = materialItems.filter((mItem) => {
              if (mItem.qtyToShip === 0) { return false; }
              mItem.available = mItem.qtyToShip || mItem.available;
              mItem.availableInInv = mItem.catId ? 0 : 'N/A';
              _.set(mItem, 'selectedQty', mItem.available);
              _.set(mItem, 'initialAvailable', mItem.available);
              return mItem;
            });
            order.items.push(...materialItemsToLoad);
          }
        }
      } else {
        data.orderCard = JSON.parse(props.orderCard);
        order = new ShippingLabel(JSON.parse(orderJSON));
        if (data.orderCard && data.orderCard.items) {
          data.orderCard.items.forEach((item) => {
            const idx = _.findIndex(order.items, (i) => item._id === i._id);
            if (idx !== -1) {
              order.items[idx].quantity = item.quantity;
            }
          });
        }
      }
      // set masterShipmentId to localStorage
      if (props.cardId !== 'add') {
        localStorage.setItem('shipmentId', order._id);
      }
      data.shipment = order;
      setDeliveryDetails(order);
      data.isLoading = false;
    };
    const cancelChanges = (async () => {
      if (props.cardId === 'add') {
        router.go(-1);
      } else {
        await loadCard();
      }
    });
    const refreshFunction = () => {
     refreshCardOnKey(true)
    }

    const getProjectId = (project) => {
      if (
        data.shipment.delivery.currentProject._id === store.state.commonStockProject
      ) {
        return data.shipment.delivery.deliveryProject._id;
      }
      if (
        data.shipment.delivery.deliveryProject._id
        === store.state.commonStockProject
      ) {
        return data.shipment.delivery.currentProject._id;
      }
      return project._id;
    };
    const fetchUsers = async (pId) => {
      const allUsers = await Projects.linkedProjectUsers([pId]);
      const users = _.filter(allUsers, (usr) => usr.company === _.get(store.state.userData, 'company._id', store.state.userData.company));
      return users;
    };
    const fetchLocations = async (pId, isGIProject) => {
      if (_.isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
      let locs = await getAllLocations(pId);
      locs = _.filter(locs, (loc) => loc.company._id === _.get(store.state.userData, 'company._id', store.state.userData.company));
      if (isGIProject) {
        _.remove(locs, (loc) => loc.type !== 'gi_location');
      }
      if (props.shipFromInventory) {
        locs = _.partition(locs, (loc) => loc.nestedLocation)[
          +(pId !== store.state.queryParams.commonStockProject._id)
        ];
      }
      return Locations.groupLocations(locs, store.state.companyData);
    };
    const projectChanged = async (project) => {
      if (project._id) {
        // set locations list
        if (_.isEmpty(data.projectLocations[project._id])) {
          data.projectLocations[project._id] = [];
          const locs = await fetchLocations(project._id, project.isGI);
          if (locs.length) data.projectLocations[project._id] = locs;
        }
        // set owners/recipients/also notify lists
        if (
          _.isEmpty(data.projectUsers[project._id])
          || data.shipment.delivery.currentProject._id
            === store.state.commonStockProject
        ) {
          const pId = getProjectId(project);
          data.projectUsers[projectId] = [];
          const users = await fetchUsers(pId);
          if (users.length) data.projectUsers[projectId] = users;
        }
      }
    };
    const buttonEvent = async (params) => {
      if (params === 'cancelShipment') {
        await cancelShipment(data.shipment);
      } else if (params === 'updateShipment') {
        data.isLoading = true;
        const { shipment } = data;
        if (shipment?.shipType === 's-m' && ['in-storage', 'released-to-inventory', 'zombie'].includes(shipment.status)) {
          shipment.partialShipment = [];
          await shipment.updateShippingDetails();
        } else {
          const shippingOrder = _.cloneDeep(shipment);
          if (data.itemsData.length) {
            shippingOrder.items = _.cloneDeep(data.itemsData);
          }
          data.updatedShipment = shippingOrder;
          try {
            await updateShipment(data.updatedShipment);
          } catch (err) {
            throw new Error(`Shipment update failed ${err}`);
          }
        }
        await loadCard();
        data.refreshKey++;
        data.isLoading = false;
      } else if (params === 'receiveItems') {
        ModalProgrammatic.open({
          component: ShippingReceive,
          props: {
            shippingLabel: data.shipment,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
        });
      } else if (params === 'deleteOrder') {
        data.isLoading = true;
        const refresh = false;
        await deleteOrder(data.shipment, refresh, data);
        data.isLoading = false;
      } else if (params === 'sendShippingList') {
        data.isShippingListVisible = true;
      } else if (params === 'markFinal') {
        data.isLoading = true;
        await markOrderFinal(data.shipment, () => refreshCardOnKey(true), data);
        data.isLoading = false;
      } else if (params === 'releaseToInventory') {
        data.isLoading = true;
        const deliveryLoc = _.get(data.shipment.delivery, 'currentLocation', {});
        await releaseToInv(deliveryLoc, data.shipment, loadCard, data, () => refreshCardOnKey(true));
        data.isLoading = false;
      } else if (params === 'schedule') {
        data.shipment.name = `Revived of ${data.shipment.name}`;
        ModalProgrammatic.open({
          component: ShippingReceive,
          props: {
            shippingLabel: data.shipment,
            isSchedule: true,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
        });
        data.disableFieldsForInstorage = false;
        await loadCard();
        data.refreshKey++;
      } else if (params === 'scheduleDelivery') {
        data.isLoading = true;
        await scheduleDelivery(data.shipment);
        data.isLoading = false;
      } else if (params === 'cancelDelivery') {
        data.disableFieldsForInstorage = false;
        await loadCard();
      } else if (params === 'reloadCard') {
        await loadCard();
        data.refreshKey++;
      }
    };

    const checkAlreadyExists = (email) => data.shipment.externalEmails
      .map((val) => val.toUpperCase())
      .includes(email.toUpperCase());

    const updateExternalEmail = (emails) => {
      data.shipment.externalEmails = emails;
      data.shipment.generatePdf = data.shipment.externalEmails.length > 0;
    };

    const isPartialPMShip = (order, itemsReady) => {
      if (!_.isEmpty(order.catQtyMaps)) {
        if (itemsReady.length < order.items.length) return true;
        return !_.every(
          order.items,
          ({ _id, quantity }) => _.find(itemsReady, { _id, available: quantity }),
        );
      }
      return false;
    };

    // create shipping order
    const createShippingOrder = async () => {
      const { shipment, orderCard: card } = data;
      const validate = shipment.validateLabel();
      if (validate.error) {
        toast.error(validate.errText);
        return;
      }
      shipment.items.forEach((i) => {
        i.quantity = i.selectedQty;
      });
      const selectedItems = shipment.items;
      // Todo: shipment.items should be selected Items.
      const nonCatItems = _.some(selectedItems, ({ catId }) => _.isEmpty(catId));
      let deliveryLoc = _.get(shipment, '_delivery.deliveryLocation', false);
      if (nonCatItems && deliveryLoc) {
        if (_.isUndefined(deliveryLoc.nestedLocation)) {
          [deliveryLoc] = await Locations.getOne({ id: deliveryLoc._id });
        }
        if (_.get(deliveryLoc, 'nestedLocation', false)) {
          toast.error(`Cannot ship items without catalog id to nested location '${deliveryLoc.name}'`);
          return;
        }
      }
      data.isLoading = true;
      const multiShipments = [];
      try {
        let allItems = [selectedItems];
        if (selectedItems.length > 50) {
          allItems = _.chunk(selectedItems, 50);
        }
        for (const idx of _.times(allItems.length)) {
          let shipmentToConsider = shipment;
          if (idx !== 0) {
            // eslint-disable-next-line prefer-const
            let { _newItems, ...newShipment } = _.cloneDeep(shipment);
            newShipment = new ShippingLabel(newShipment);
            newShipment.name = `${shipment.name} ${idx}`;
            shipmentToConsider = newShipment;
            newShipment.createNewLabel = true;
          }
          for (const dataItem of allItems[idx]) {
            if (!_.isEmpty(card) || !_.isEmpty(props.cards)) {
              if (dataItem.isKitMaterial) {
                shipmentToConsider.addItemFromShipment(
                  dataItem,
                  dataItem.sl,
                  dataItem.quantity,
                );
              } else {
                // Todo: made Qty available when create shipping.
                dataItem.selectedQty = dataItem.quantity || dataItem.available || dataItem.total;
                shipmentToConsider.addItemFromCard(dataItem, (card?._id) ? card
                  : dataItem.source._id);
              }
            } else if (dataItem.isInventoryItem) {
              dataItem.selectedQty = dataItem.quantity || dataItem.available || dataItem.total;
              shipmentToConsider.addItemFromInventory(dataItem, dataItem.selectedQty);
            }
          }
          multiShipments.push(shipmentToConsider);
        }
        if (multiShipments.length > 1) {
          for (const sl of multiShipments) {
            if (props.cards && props.cards.length > 0) {
              sl.contributedCards = _.map(props.cards, '_id');
            } else if (card && card._id) {
              sl.contributedCards = [card._id];
            }
          }
        }
        const newShipments = [];
        for (const sl of multiShipments) {
          let newPS = {};
          try {
            if (!_.isEmpty(_.get(data.orderCard, '_id', '')) && route.params.cardId === 'add') {
              await consumeAndUnreserve({ order: data.orderCard });
            }
            newPS = await sl.save();
            await generateShipPdf(newPS);
          } catch (e) {
            console.log(e);
            throw e;
          }
          newShipments.push(newPS);
        }
        if (newShipments && newShipments.length > 1) {
          await router.push({ path: '/logistics/shipping/order-view' });
        } else {
          // eslint-disable-next-line prefer-destructuring
          data.card = newShipments[0];
          router.push({
            name: 'shipping-edit',
            params: {
              cardId: data.card._id,
              projectId: data.card.project._id,
            },
          });
        }
      } catch (e) {
        toast.error(e.message || e.data.message || 'Error while processing shipment. Please try again later', '');
        console.log('Error while processing shipment', e);
      } finally {
        toast.warning('Shipping list being generated and will be attached in  a moment.');
        data.isLoading = false;
      }
    };

    const shipmentMode = computed(() => (data.isCreateShipping ? 'create-shipping' : 'shipping-edit'));

    const sendShippingList = async () => {
      data.isLoading = true;
      await updateShipment(data.shipment);
      data.isLoading = false;
    };

    const setTabName = (val) => {
      data.selectedTabName = val;
    };

    const toggleCreateDel = () => {
      data.isCreateDelActive = !data.isCreateDelActive;
    };

    const toggleDeliveryItems = async (params) => {
      data.isDeliveryItemsActive = !data.isDeliveryItemsActive;
      if (params?.doRefresh) {
        await loadCard();
        data.refreshKey++;
      }
    };

    emitter.on('toggle:isDeliveryItems', (payload) => {
      data.isDeliveryItemsRow = payload.rowData;
      data.isUnscheduledRow = payload.isUnscheduledRow;
      data.isDeliveryItemsActive = true;
    });

    emitter.on('toggle:createDelModal', (payload) => {
      data.createDelRow = payload.card;
      data.mS = payload.masterShipment;
      data.isDeliveryItemsActive = false;
      if (!data.isDeliveryItemsActive) {
        data.isCreateDelActive = payload.isActive;
      }
    });

    emitter.on('refreshCardOnKey', () => {
      refreshCardOnKey(true);
    });

    // mounted
    onBeforeMount(async () => {
      await loadCard();
      await projectChanged(_.get(data.shipment, 'delivery.currentProject', {}));
      await projectChanged(_.get(data.shipment, 'delivery.deliveryProject', {}));
      data.cardLoaded = true;
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      localStorage.removeItem('shipmentId');
      emitter.off('refreshCardOnKey');
    });

    onMounted(() => {
      data.isCreateShipping = props.cardId === 'add';
    });

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      data.deliveryNotes.selectedItem = payload.data;
      data.deliveryNotes.isItem = payload.isItem;
      data.deliveryNotes.isSlideNotesActive = payload.isActive;
      data.deliveryNotes.isShipping = payload.isShipping;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      data.deliveryNotes.selectedItem = payload.data;
      data.deliveryNotes.docRowField = payload.rowField;
      data.deliveryNotes.isFileListVisible = payload.isActive;
      data.deliveryNotes.isShipping = payload.isShipping;
    });

    const closeNotesSlider = (() => {
      data.deliveryNotes.isSlideNotesActive = false;
      data.deliveryNotes.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      data.deliveryNotes.isFileListVisible = false;
    };

    const onCellClicked = (params) => {
      if (params.type === 'edit') {
        data.deliveryNotes.isDisabled = false;
      } else if (params.type === 'save') {
        data.deliveryNotes.isDisabled = true;
        data.refreshKey++;
      } else {
        data.deliveryNotes.isDisabled = true;
      }
    };

    const isDisabled = computed(() => {
      if (!data.shipment.items.length || _.every(data.shipment.items, ['selectedQty', 0])) {
        return true;
      }
      if (data.shipment.items.length) {
        const isNoQtyChanged = _.some(data.shipment.items, { selectedQty: 0 });
        if (isNoQtyChanged) return true;
      }
      return false;
    });

    const refreshCardOnKey = async (val) => {
      if (val) {
        await loadCard();
        data.refreshKey++;
      }
    };

    return {
      ...toRefs(data),
      loadCard,
      buttonEvent,
      setTabName,
      shipmentMode,
      checkAlreadyExists,
      updateExternalEmail,
      createShippingOrder,
      isPartialPMShip,
      cancelChanges,
      sendShippingList,
      closeDocumentSlider,
      closeNotesSlider,
      onCellClicked,
      toggleCreateDel,
      toggleDeliveryItems,
      isDisabled,
      refreshCardOnKey,
      refreshFunction, 
    };
  },
};
</script>

<style scoped>
</style>
