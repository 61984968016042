import LmvFilterCols from '@/components/table-cols/LmvFilterCols';
import MaterialCols from './materialCols';
import PlannerCols from './plannerCols';
import ShippingCols from './shippingOrderViewCols';
import MaterialRowDetails from './materialRowDetails';
import SourcingRowDetails from './sourcingRowDetails';
import OrderChecklistCols from './orderChecklistCols';
import ShippingItemsDetails from './shippingItemDetailsCols';
import PlanningRowDetails from './planningRowDetails';
import ScheduleRowDetails from './scheduleRowDetails';
import ManagerCols from './managerCols';
import MaterialStatusCols from './materialStatusCols';
import ProductionStatusCols from './ProductionStatusCols';
import PSItemViewCols from './PSItemViewCols';
import MaterialStatusItemViewCols from './MaterialStatusItemViewCols';
import inventoryCols from './inventoryCols';
import transferRequestCols from './transferRequestCols';
import PMRowDetails from './PMRowDetails';
import TasksCols from './tasksCols';
import notificationCols from './notificationCols';
import taskCol from './taskCol';
import assemblyDetailCols from './assemblyRowDetailsCols';
import partsDetailCols from './partsRowDetailsCols';

export default {
  material: MaterialCols,
  planner: PlannerCols,
  shipping: ShippingCols,
  MaterialRowDetails,
  SourcingRowDetails,
  OrderChecklistCols,
  ShippingItemsDetails,
  PlanningRowDetails,
  ScheduleRowDetails,
  managerCols: ManagerCols,
  materialStatus: MaterialStatusCols,
  ProductionStatusCols,
  PSItemViewCols,
  MSItemViewCols: MaterialStatusItemViewCols,
  inventoryCols,
  transferRequestCols,
  PMRowDetails,
  tasks: TasksCols,
  notificationCols,
  LmvFilterCols,
  taskCol,
  assemblyDetailCols,
  partsDetailCols,
};
