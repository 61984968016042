import { isFinite, clamp } from 'lodash';

export default {
  restrict(val) {
    val = parseInt(val, 10);
    if (!isFinite(val)) {
      return null;
    }
    return clamp(Math.floor(val), 1, 9999);
  },
  inputProps: {
    type: 'number',
    max: 9999,
    min: 1,
    step: 1,
  },
};
