<template>
  <div class="field">
    <label class="label has-text-black-bis is-italic is-size-3 ml-0">
      {{ label }}
    </label>
    <div class="submittal-list">
      <i
        class="is-clickable icon-procore pl-1"
        @click="openSubmittals()"
      ></i>
      <div v-if="isActive" class="has-text-black-bis is-flex is-align-items-center">
        <a :href="procoreUrl()" class="is-size-3  line-height is-italic" > {{ value.name }} </a>
        <i  v-if="value" class="icon-close is-clickable" @click="deleteSubmittal"></i>
      </div>
    </div>
    <list-submittals  :projectId="projectId" @selectedSubmittal="selectedSubmittal"
        @close="isListSubsActive = false" :isListSubsActive="isListSubsActive" ></list-submittals>
  </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs, onMounted,
} from 'vue';
import { DialogProgrammatic } from '@/components/Dialog';
import { useStore } from 'vuex';
import Project from '@/models/Projects';
import _ from 'lodash';
import { useRouter } from 'vue-router';
import ListSubmittals from '@/components/modals/ListSubmittals.vue';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import Procore from '@/models/Procore';

export default defineComponent({
  name: 'submittal',
  components: {
    ListSubmittals,
  },
  props: ['value', 'label', 'projectId'],
  setup(props, { emit }) {
    const { value, projectId } = props;
    const store = useStore();
    const router = useRouter();

    const localState = reactive({
      isActive: !_.isEmpty(_.get(value, 'name', null)),
      isListSubsActive: false,
      procoreSettings: null,
      selectedData: {},
      token: {},
    });

    const { userData } = store.state;
    onMounted(async () => {
      const project = await Project.get(projectId);
      localState.procoreSettings = _.find(_.get(project, 'apis', []), { name: 'procore' });
      if (!_.isUndefined(localState.procoreSettings)) {
        try {
          await Procore.getProcoreToken({ refreshToken: true });
        } catch (e) {
          console.log(e);
          localState.token = e.data;
        }
      }
    });

    const procoreUrl = () => `https://app.procore.com/${value.project}
      /project/submittal_logs/${value.id}`;

    const openSubmittals = async () => {
      if (!_.isEmpty(localState?.token?.error)) {
        const confirmParam = {
          title: 'NOT AUTHENTICATED WITH PROCORE',
          message:
            'You must enable Procore authentication before attaching a Procore submittal to this order',
          okButton: 'Authenticate Procore',
          cancelButton: 'Cancel',
          onConfirm: () => {
            router.push({
              name: 'third-party-plugins',
            });
          },
        };
        DialogProgrammatic.confirm(confirmParam);
      } else if (_.isUndefined(localState.procoreSettings)) {
        const res = await Project.checkProjectAdmin([projectId, userData.id]);
        if (res) {
          const confirmParam = {
            title: 'PROJECT IS NOT LINKED TO PROCORE',
            message:
                'Project Admin must link this project to Procore before attaching submittals. Do you want to link this project to Procore',
            okButton: 'Link Project to Procore',
            cancelButton: 'Cancel',
            onConfirm: () => {
              router.push({
                name: 'view-project',
                params: {
                  module: 'projects', projectId, tab: 'SubmittalSettings',
                },
              });
            },
          };
          DialogProgrammatic.confirm(confirmParam);
        } else {
          console.log(
            'Please contact your ManufactOn Admin to link this project to Procore',
          );
        }
      } else {
        localState.isListSubsActive = true;
        localState.isActive = true;
      }
    };
    const selectedSubmittal = (val) => {
      localState.selectedData = {
        project: localState.procoreSettings.params.project_id,
        name: val.title,
        id: val.id,
      };
      emit('update:value', localState.selectedData);
      localState.isActive = true;
    };

    const deleteSubmittal = () => {
      localState.isActive = !localState.isActive;
      emit('update:value', {});
      CardDirtyBus.emit('setCustomDirty');
    };

    return {
      procoreUrl,
      ...toRefs(localState),
      selectedSubmittal,
      openSubmittals,
      deleteSubmittal,
    };
  },
});
</script>
<style scoped>
/* .submittal-list {
  height: 87px;
} */
</style>
