<template>
  <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
  ></o-loading>
  <o-modal :active="isActive" :canCancel="false" class="modal-sm">
    <div class="modal-card">
      <header class="modal-card-head">
        <h4 class="modal-card-title has-text-weight-bold">
          {{ getModalTitle }}
        </h4>
        <div v-if="type !== 'forge' && type !== 'subscription'">
          <mf-multi-select v-if="options?.length"
            :preselect-first="true"
            v-model="selectedAction"
            track-by="id"
            label="name"
            :multiple="false"
            :options="options"
            :allow-empty="false"
            @update:modelValue="fireEvent($event)"
          ></mf-multi-select>
        </div>
        <div v-if="type === 'forge' || type === 'subscription'">
          <div class="is-divider-vertical"></div>
            <i class="icon-close is-clickable" @click="closeModal"></i>
        </div>
      </header>
      <section class="modal-card-body has-background-white pr-0 pb-0" :key="updateTable">
        <search-bar
          placeholder="Search"
          ref="searchComp"
          :shouldEmit="shouldEmit"
          @search="getSearchValue" class="mr-5"
        ></search-bar>
        <div class="project-filter">
        <mf-table
          :key="selectedAction?.id"
          ref="projectTable"
          :tableProps="tableProps"
          :apiMode="false"
          :checkedRows="checkedRow"
          :loadData="loadData"
          @row-select="getSelectedRow($event)"
          @checkbox-toggled="getSelectedRow"
          :isLocallyFiltered="true"
            tableName="projectTable"
        >
        </mf-table>
        </div>
      </section>
      <footer class="">
        <div class="is-size-5 footer-note">
          <span class="has-text-weight-bold">Note: </span>
          Contact your Manufacton/company admin if you cannot find the project you are looking
          for in this Projects list. Projects must be added to Manufacton at the Company level.
        </div>
        <div class="modal-card-foot is-justify-content-flex-end">
          <button
            class="button is-outlined"
            @click="closeModal"
          >
            Cancel
          </button>
          <button
            class="button has-background-black-bis"
            :disabled="isDisabled"
            @click="saveData"
          >
            {{ buttonName }}
          </button>
        </div>
      </footer>
    </div>
  </o-modal>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
  computed,
  ref,
} from 'vue';
import _, { forEach } from 'lodash';
import { useStore } from 'vuex';
import globalProjectCols from '@/components/table-cols/selectProjectCols/globalProjectCols';
import SearchBar from '@/components/SearchBar.vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import LocalSort from '@/components/utils/LocalSort';
import { useToast } from 'vue-toastification';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'SelectProjects',
  components: {
    SearchBar,
    MfTable,
    MfMultiSelect,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'all',
    },
    view: {
      type: String,
      default: 'material-status',
    },
  },
  emits: ['cancel', 'filter:count'],
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const tableDefinition = globalProjectCols;
    const buttonName = 'Apply Filter';
    const searchComp = ref(null);
    const route = useRoute();
    const state = reactive({
      searchText: '',
      selectedAction: null,
      selectedRow: [],
      options: [],
      checkedRow: [],
      buttonName,
      allProjects: [],
      fetchedProjects: [],
      filteredProjects: [],
      tableProps: tableDefinition,
      shouldEmit: true,
      isLoading: false,
      isDisabled: true,
      updateTable: 0,
    });
    const projectTable = ref(null);
    const prevView = computed(() => store.state.queryParams.oldselectedList);

    const getSelectedRow = (row) => {
      state.selectedRow = row;
      if (state.selectedRow) {
        state.isDisabled = false;
      }
    };

    const getSearchValue = (searchText) => {
      projectTable.value.isLoading = true;
      state.searchText = searchText;
      projectTable.value.refreshTable();
    };

    // eslint-disable-next-line consistent-return
    const loadData = (sortParams) => {
      try {
        state.isLoading = true;
        let fetchedProjectsData = state.allProjects;
        if (props.type === 'all') {
          state.checkedRow = state.filteredProjects;
        }
        // if search, filter based on search query
        if (fetchedProjectsData.length > 0 && state.searchText) {
          fetchedProjectsData = _
            .filter(fetchedProjectsData,
              (project) => project.name.toLowerCase().includes(state.searchText.toLowerCase()));
        }
        state.isLoading = false;
        return LocalSort.filter([...fetchedProjectsData], sortParams);
      } catch (e) {
        console.log('error', e);
      }
    };

    const fetchActiveProjects = () => {
      state.allProjects = store.state.queryParams._projects;
      const fetchFilteredProjects = store.state.queryParams.projects;
      const filteredProjectIds = fetchFilteredProjects.map((project) => project._id);
      state.filteredProjects = _.filter(store.state.queryParams._projects,
        (project) => !project.isTemplateProject && filteredProjectIds.includes(project._id));
    };

    const fetchFreqUsedProjects = () => {
      state.allProjects = store.state.queryParams.activeProjects;
      const mappedFeqIds = _.map(store.state.queryParams.activeProjects, '_id');
      state.filteredProjects = _.filter(state.filteredProjects, (p) => mappedFeqIds.includes(p._id));
    };

    const fireEvent = async (eventHandler) => {
      if (eventHandler.id === 1) {
        fetchActiveProjects();
      }
      if (eventHandler.id === 2) {
        fetchFreqUsedProjects();
      }
      loadData();
      await projectTable.value.refreshTable();

      state.modalTitle = `Project Filter: Viewing (${state.filteredProjects.length}
        /${state.allProjects.length}) Projects`;
    };

    const emitProjectsCount = () => {
      const opts = [{
        id: 1,
        name: `Active Projects (${ store.state.queryParams?._projects?.length || 0 })`,
      },
      {
        id: 2,
        name: `Frequently Used Projects (${ store.state.queryParams?.activeProjects?.length || 0 })`,
      }];
      state.options = opts;
      emit('filter:count', {
        totalProjectsCount: state.allProjects.length,
        filteredProjectsCount: state.filteredProjects.length,
      });
    };

    const closeModal = async () => {
      state.selectedRow = [];
      state.searchText = '';
      if (searchComp.value) {
        searchComp.value.searchText = '';
        state.checkedRow = state.filteredProjects;
        projectTable.value.selectedRows = state.filteredProjects;
        await projectTable.value.refreshTable();
      }
      emit('cancel');
    };

    const isDashboardScreen = () => _.get(route, 'path', []).split('/').includes('dashboard');

    const saveData = async () => {
      // we save the selected data
      let selectedData = state.selectedRow;
      if (props.type === 'all') {
        if (_.isEmpty(selectedData)) {
          selectedData = store.state.queryParams.activeProjects;
        }
        const projects = { projects: selectedData };
        if (store.state.activeScreen === 'home' || isDashboardScreen()) _.set(projects, 'filteredProjects', selectedData);
        // filter out the selected data
        if (store.state.activeScreen === 'schedule') store.commit('setQueryParams', { project: selectedData });
        store.commit('setQueryParams', projects);
        store.commit('setRefreshFilters', true);
        state.filteredProjects = selectedData;
        state.modalTitle = `Viewing (${selectedData.length}/${state.allProjects.length}) Projects`;
        emit('refreshFilter');
      }
      state.isDisabled = true;
      emitProjectsCount();
      state.searchText = '';
      await projectTable.value.refreshTable();
      closeModal();
    };

    onMounted(() => {
      fetchActiveProjects();
      loadData();
      emitProjectsCount();
    });

    const getModalTitle = computed(() => {
      if (state.filteredProjects.length || state.selectedRow.length) {
        return `Viewing (${state.selectedRow.length || state.filteredProjects.length}
          /${state.allProjects.length}) Projects`;
      }
      return 'Select Projects';
    });

    watch(
      () => store.state.queryParams._projects,
      async () => {
        fetchActiveProjects();
        loadData();
        emitProjectsCount();
      },
    );

    const getScreenStatus = (screen) => {
      return ['production-status-order-view', 'material-status-order-view', 'production-status-item-view', 'material-status-item-view', 'lmv-view'].includes(screen);
    }

    const commitProjects = (activeProjects, allProjects) => {
      store.commit('setQueryParams', {
        projects: activeProjects,
      });
      store.commit('setQueryParams', {
        activeProjects: activeProjects,
      });
      store.commit('setQueryParams', {
        _projects: allProjects,
      });
    }

    watch(
      () => store.state.activeScreen,
      (newScreen, oldScreen) => {
        if (getScreenStatus(newScreen) !== getScreenStatus(oldScreen)){
          const otherCompIds = _.map(store.state.queryParams._otherCompanyProjects, 'createdCompany._id');
          store.commit('setOtherCompanyIds', otherCompIds);
          let activeProjects=_.cloneDeep(store.state.queryParams.projects);
          let allProjects=_.cloneDeep(store.state.queryParams._projects);
          if ((getScreenStatus(store.state.activeScreen)) 
          && (_.differenceWith(store.state.queryParams._otherCompanyProjects, allProjects, _.isEqual).length!==0)){
            activeProjects.push(...store.state.queryParams._otherCompanyProjects);
            allProjects.push(...store.state.queryParams._otherCompanyProjects);
            commitProjects(activeProjects, allProjects);
            state.updateTable++;
          } else if(!getScreenStatus(store.state.activeScreen)) {
            const otherProjs = store.state.queryParams._otherCompanyProjects
            let newArray;
            if (activeProjects.length === otherProjs.length) {
               newArray = activeProjects.filter(
                    (aProj) => otherProjs.some((oProj) => oProj._id === aProj._id));
              activeProjects = newArray.length === activeProjects.length ? allProjects : activeProjects;
            }
            activeProjects=_.differenceWith(activeProjects, store.state.queryParams._otherCompanyProjects, _.isEqual);
            allProjects=_.differenceWith(allProjects, store.state.queryParams._otherCompanyProjects, _.isEqual)
            commitProjects(activeProjects, allProjects);
            state.updateTable++;
          }
        }
      },
    )

    return {
      ...toRefs(state),
      onMounted,
      prevView,
      fireEvent,
      getSearchValue,
      projectTable,
      getSelectedRow,
      loadData,
      saveData,
      closeModal,
      watch,
      getModalTitle,
      searchComp,
    };
  },
});
</script>
<style scoped>
::v-deep(.mf-table .o-table__wrapper) {
  min-height: 62vh !important;
  max-height: 62vh !important;
}
::v-deep(.o-modal__content .mf-table) {
  margin-top: 8px;
}
::v-deep(.modal-card-body) {
  height: 71vh;
}
::v-deep(.footer-note) {
  height: 40px !important;
}
</style>
