<template>
  <div class="modal-card">
    <section class="modal-card-body has-background-white no-shadow">
      <search-bar
        placeholder="Search"
        :shouldEmit="shouldEmit"
        @search="getSearchValue"
      ></search-bar>
      <div class="table-container">
        <mf-table
          ref="templatesTable"
          :apiMode="true"
          :tableProps="tableProps"
          :loadData="loadData"
          @row-select="getSelectedRow($event)"
        >
          <template v-slot:location="{ rowData }">
            <span v-if="rowData.__t === 'MatTemplates'">
              {{$_.get(rowData, 'baseDelivery.location.name', '')}}
            </span>
            <span v-else>
              {{$_.get(rowData, 'manager.location.name', '')}}
            </span>
          </template>
        </mf-table>
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <div class="column is-narrow">
        <button class="button is-outlined" @click="cancel()">
          Back
        </button>
        <button
          class="button has-background-black-bis"
          :disabled="isDisabled"
          @click="selectTemplate"
        >
          Apply Template
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import _ from 'lodash';
import uuid from 'uuid/v4';
import SearchBar from '@/components/SearchBar.vue';
import SupplyChain from '@/models/SupplyChain';
import Projects from '@/models/Projects';
import tableDefinition from '@/components/table-cols/templateListCols';
import {
  defineAsyncComponent, reactive, toRefs, ref,
} from 'vue';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default ({
  name: 'SelectTemplates',
  components: {
    SearchBar,
    MfTable,
  },
  props: ['stage', 'purpose', 'isCutOrder'],
  setup(props, { emit }) {
    const state = reactive({
      tableProps: tableDefinition,
      selectedRow: {},
      orderList: [],
      searchText: '',
      shouldEmit: true,
      isLoading: false,
      isDisabled: true,
      templateProject: false,
    });
    const templatesTable = ref(null);

    const cancel = () => {
      state.selectedRow = null;
      emit('close-template-modal');
    };

    const stage = props.stage ? props.stage : 'production';

    const loadData = async () => {
      try {
        state.isLoading = true;
        const templateProjects = (await Projects.haveTemplateProject())
          .templateProject;
        state.templateProject = templateProjects;
        if (state.templateProject && state.templateProject._id) {
          const tempQuery = {
            projectId: state.templateProject._id,
            module: ['preparation', 'sourcing', 'ordering'].includes(stage)
              ? 'MatTemplates'
              : 'ProdTemplates',
            search: state.searchText,
            filterNoItemOrders: false,
            limit: 9999,
            page: 1,
          };
          if (props.isCutOrder) {
            tempQuery.type = 'cutOrder';
          }
          state.orderList = await SupplyChain.supplyChain(tempQuery);
          const dataCount = state.orderList.data.length;
          state.orderList.data = _.filter(state.orderList.data, (list) => {
            if (props.stage !== 'preparation') {
              return (list.purpose === props.purpose || (props.isCutOrder && list.purpose === 'cutOrder')) && ['all', props.stage].includes(list.stageUsed);
            } return true;
          });
          state.orderList.total -= (dataCount - state.orderList.data.length);
          if (state.orderList.data.length > 0 && state.searchText) {
            state.orderList.data = _.filter(state.orderList.data, (template) => template.name.toLowerCase().includes(state.searchText.toLowerCase()));
          }
          state.isLoading = false;
          return state.orderList;
        }
        state.orderList = [];
      } catch (error) {
        console.error(error);
      }
    };

    const getSelectedRow = (selectedRow) => {
      state.selectedRow = selectedRow;
      if (state.selectedRow) {
        state.isDisabled = false;
      }
    };

    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      templatesTable.value.refreshTable();
    };

    const selectTemplate = () => {
      const selectedOrder = _.find(state.orderList.data, { _id: state.selectedRow._id });
      for (const todo of selectedOrder.todos) {
        for (const todoFile of todo.files) {
          if (todoFile.type === 'form') {
            const linkedFile = _.find(selectedOrder.files, { url: todoFile.url });
            todoFile.url = uuid();
            if (linkedFile) linkedFile.url = todoFile.url;
          }
        }
      }
      emit('fill-template-data', selectedOrder);
    };

    return {
      ...toRefs(state),
      loadData,
      cancel,
      templatesTable,
      getSearchValue,
      getSelectedRow,
      selectTemplate,
    };
  },
});
</script>

<style scoped>
.modal-sm .modal-card .modal-card-body {
  height: 500px;
  padding-bottom: 0;
  overflow: hidden;
}
::v-deep(.o-table tbody) {
  min-height: 350px !important;
  max-height: 350px !important;
  overflow-y: scroll;
}
::v-deep(.modal-card-body .mf-table .o-table__wrapper) {
  min-height: 450px;
  max-height: 450px;
}
</style>
