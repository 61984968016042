import _ from 'lodash';
import GenerateRequest from './resources';
import { BaseOrder } from './BaseOrder';

const req = GenerateRequest();

export default {
  async getOne(params) {
    try {
      const { data: mm } = await req.get(`materials/get/${params.cardId}`, {
        params: { projectId: params.projectId },
      });
      return new BaseOrder(mm);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async create(card) {
    try {
      const { data: material } = await req.post(
        card.__t === 'Materials' ? 'materials/create' : 'materials/moveToSourcing',
        card,
        { params: { projectId: card.project._id } },
      );
      return material;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async update(card) {
    try {
      const { data: material } = await req.post(
        'materials/update',
        card,
        { params: { projectId: card.project._id } },
      );
      return material;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async getFieldRequest(params) {
    try {
      const { data: mm } = await req.get(`materials/getFieldRequest/${params.deviceId}`, {
        params: {
          projectIds: params.projectIds,
          userId: params.userId,
          stage: params.stage,
          type: params.type,
        },
      });
      if (!_.isNull(mm)) { return new BaseOrder(mm); }
      return null;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getWishList(params) {
    try {
      const { data: mm } = await req.get(`materials/getWishList/${params.orderId}`, {
        params: {
          projectIds: params.projectIds,
        },
      });
      if (!_.isNull(mm)) { return new BaseOrder(mm); }
      return null;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async clone(card) {
    try {
      const { data: material } = await req.post('materials/cloneMaterial', card, {
        params: { projectId: card.project._id },
      });
      if (!_.isNull(material)) { return new BaseOrder(material); }
      return null;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async combineOrders(card) {
    try {
      const { data: material } = await req.post('materials/mergeMaterials', card);
      return material;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async split(card) {
    try {
      const { data: order } = await req.post('materials/split', card);
      return order;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async updateWishlist(params) {
    try {
      const data = await req.post('materials/updateWishlist', params);
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async pullToSourcing(cardIds, card) {
    const params = {
      cardIds,
    };
    if (!_.isEmpty(card)) params.card = card;
    try {
      const { data } = await req.post('materials/pullToSourcing', params);
      return data.orders;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getKitIds(params) {
    try {
      const { data } = await req.get('prefabs/getKitIds', {
        params,
      });
      return { data: data.data.map(order => new BaseOrder(order)), total: data.total };
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async kitBOMToSourcing(params) {
    try {
      const { data } = await req.post('materials/kitbomToSourcing', params);
      return data.orders;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async moveToNextStage(card) {
    try {
      const { data: order } = await req.post('materials/moveToNextStage', card);
      return new BaseOrder(order);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  moveToNextStageFromCard(card) {
    if (!(card instanceof BaseOrder)) {
      card = new BaseOrder(card);
    }
    const newCard = _.pick(card.toJSON(), ['_id', 'owner', 'name', 'dates',
      'items', 'location', 'project', 'notifyUsers', 'stage', 'currentNote', 'notes']);
    if (['preparation', 'ordering'].includes(newCard.stage)) newCard.baseDelivery = card.baseDelivery;
    else newCard.baseDelivery = { location: newCard.location };
    newCard.items = newCard.items.map(i => i._id);
    newCard.projectId = card.project._id;
    newCard.stage = _.last(newCard.stage.split(':'));
    return this.moveToNextStage(newCard);
  },
  async getItems(params) {
    try {
      const data = await req.get('items/getItems', { params });
      return _.compact(data.data.map((d) => {
        d.id = d._id.catId;
        return d;
      }));
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async archive(card) {
    try {
      const { data: order } = await req.post('materials/archiveMaterial', card);
      return order;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async requestorMetrics(params) {
    try {
      const data = await req.post('materials/getleadTimesByUser', params);
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async categoryMetrics(params) {
    try {
      const data = await req.post('materials/getCategoryMetrics', params);
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getVendorsMetrics(params) {
    try {
      const data = await req.post('materials/getVendorsMetrics', params);
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async reject(params) {
    try {
      const res = await req.post('materials/reject', params);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async createBOM(params) {
    try {
      const { data: materials } = await req.get(`materials/createBOM/${params.cardId}`, {
        params,
      });
      return materials;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async reOrganize(params) {
    try {
      const { data: result } = await req.get('materials/reorganizeOrders', { params });
      // eslint-disable-next-line guard-for-in
      for (const order of result.data) {
        order.count = (_.uniq(_.map(order.reOrganizeBy, 'catId'))).length;
      }
      return result;
    } catch (e) {
      console.log('err', e);
      throw e;
    }
  },
  async createReorganizeOrder(params) {
    try {
      const { data: result } = await req.post('materials/reorganize', { params });
      return result;
    } catch (e) {
      console.log('err', e);
      throw e;
    }
  },
};
