<template>
  <div>
    <mf-table
      ref="materialStatusTable"
      :tableProps="tableProps"
      :loadData="loadData"
      :tableName="stageFromUrl"
      :screenTable="true"
      :hideGutter="true"
      @cell-clicked="onCellClicked"
    >
    <template v-slot:itemPercComplete="{ rowData }">
      {{calculatePercentCompleteItemLevel(rowData)}}
    </template>
    <template v-slot:runPercComplete="psData">
      {{calculatePercentCompleteRunLevel(psData.rowData)}}
    </template>
    <template v-slot:level-select="{ rowData }">
      <div v-if="rowData.__t !== 'Prefabs'">
        <span v-if="rowData.isEditing && rowData.projectLvlsAndZns.levels.length !==0">
          <mf-multi-select v-model="rowData.level"
                        :options="rowData.projectLvlsAndZns.levels"
                        :closeOnSelect="true"
                        :multiple="false"
                        :taggable="false"
                        :hideSelected="false"
                        class="pendo-level-select"
                        type="arraySort"
          >
          </mf-multi-select>
        </span>
        <span v-else-if="rowData.isEditing">
          <input type="text" placeholder="level" class="input" v-model.trim="rowData.level">
        </span>
        <span v-else>
              {{rowData.level}}
        </span>
      </div>
    </template>
    <template v-slot:zone-select="{ rowData }">
      <div v-if="rowData.__t !== 'Prefabs'">
          <span v-if="rowData.isEditing && rowData.projectLvlsAndZns.zones.length !==0">
            <mf-multi-select v-model="rowData.zone"
                          :options="rowData.projectLvlsAndZns.zones"
                          :closeOnSelect="true"
                          :multiple="false"
                          :taggable="false"
                          :hideSelected="false"
                          class="pendo-zone-select"
                          type="arraySort"
            >
            </mf-multi-select>
          </span>
          <span v-else-if="rowData.isEditing">
              <input type="text" placeholder="zone" class="input" v-model.trim="rowData.zone">
          </span>
          <span v-else>
                {{rowData.zone}}
          </span>
        </div>
    </template>
    <template v-slot:lastModified="psData">
      {{$filters.usaDate(psData.rowData.lastModified.at)}}
    </template>
    <template v-slot:company="psData">
      {{psData.rowData.owner.company.name}}
    </template>
    <template v-slot:project="psData">
      {{psData.rowData.project.name}}
    </template>
    <template #lmvIcon="{rowData, rowField, isHeader, rowIndex }">
        <IconField
          :rowData="rowData"
          :rowField="rowField"
          :isHeader="isHeader"
          :rowIndex="rowIndex"
          :projects="allProjects" />
      </template>
    </mf-table>
  </div>
  <activity-log
      :isActive="isActive"
      :id="activityDetails.id"
      :projectId="activityDetails.projectId"
      :orderName="activityDetails.orderName"
      @update:isActive="isActive=false">
  </activity-log>
  <transition name="slide">
    <notes-icon-slider
      v-if="isSlideNotesActive"
      :isActive="isSlideNotesActive"
      :rowData="selectedOrder"
      @close="closeNotesSlider"
      @update:note="updateNotes($event)"
    >
    </notes-icon-slider>
  </transition>
    <transition name="slide">
      <item-document-slider
        v-if="isFileListVisible"
        :isActive="isFileListVisible"
        :rowData="selectedItem"
        :rowField="rowField"
        @close="closeDocumentSlider"
        >
      </item-document-slider>
    </transition>
</template>
<script>
import {
  reactive,
  toRefs,
  computed,
  ref,
  inject,
  onBeforeUnmount,
  onBeforeMount,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  isEmpty, map, filter, get, cloneDeep, set,
} from 'lodash';
import MfTable from '@/components/table-fields/MfTable.vue';
import tableDefinition from '@/components/table-cols/materialStatusCols';
import ActivityLog from '@/components/ActivityLog.vue';
import SupplyChain from '@/models/SupplyChain';
import { useToast } from 'vue-toastification';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import UtilityMixin from '@/components/mixins/UtilityMixin';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import moment from 'moment';
import IconField from '../../components/fields/IconField.vue';
import Projects from '../../models/Projects'

export default {
  name: 'Material-Status-order-view',
  components: {
    MfTable,
    ActivityLog,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
    IconField,
  },
  props: {
    excelExport: Function,
  },
  setup(props) {
    const materialStatusTable = ref(null);
    const emitter = inject('emitter');
    const route = useRoute();
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { printQr, openLmvModel, openWithUserPref } = CardEditMixin();
    const { getNegativeList } = UtilityMixin();
    const stageFromUrl = computed(() => route.path.split('/').pop());
    const state = reactive({
      tableProps: tableDefinition,
      isLoading: false,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      orderId: [],
      showDuplicates: false,
      isMassUpdateActive: false,
      isActive: false,
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedOrder: {},
      selectedItem: {},
      isItem: false,
      rowField: {},
      allProjects: [],
    });
    const calculatePercentCompleteRunLevel = (row) => get(row, 'manager.stats.itemPerc', 0);
    const calculatePercentCompleteItemLevel = (row) => get(row, 'manager.stats.runsPerc', 0);
    const loadData = async (commonParams) => {
      const allProjectIds = store.getters.selectedIdsForKey('filteredProjects');
      state.isLoading = true;
      let projectId;
      let categoryId;
      let date;
      let stages;
      let vendor;
      let poCreatedAtStart;
      let fromDashBoard;
      let isLate;
      let isDelivered;
      let upcoming;
      if (route.query) {
        ({
          projectId, categoryId, date, stages, vendor, poCreatedAtStart, fromDashBoard, isLate, isDelivered, upcoming,
        } = route.query);
      }
      const startOffSetDate = upcoming === '<1Week' ? 0 : 7;
      const endOffSetDate = upcoming === '<1Week' ? 6 : 41;
      const dashBoardParams = {
        projectId: !isEmpty(projectId) ? projectId : allProjectIds,
        fromDashBoard: route.query.fromDashBoard === 'true',
        vendor: route.query.vendor,
        module: ['Materials', 'Sourcing'],
        ...commonParams,
      };
      if (isLate) {
        dashBoardParams.isLate = isLate === 'true';
      }
      if (isDelivered) {
        dashBoardParams.isDelivered = isDelivered === 'true';
      }
      if (upcoming) {
        dashBoardParams.deliverStartDate = (upcoming === 'Overdue') ? ''
          : moment().add(startOffSetDate, 'days').toDate();
        dashBoardParams.deliverEndDate = (upcoming === 'Overdue') ? moment().toDate()
          : moment().add(endOffSetDate, 'days').toDate();
      }
      let vendors = [];
      if (vendor) {
        vendors = vendor;
      } else {
        vendors = isEmpty(store.state.queryParams.vendors)
          ? []
          : map(store.state.queryParams.vendors, '_id');
      }
      // send all the projectIds when materialId in route.query is present.
      if (!isEmpty(state.orderId)) projectId = store.getters.selectedIdsForKey('_projects');
      const dateToQuery = isEmpty(date) ? store.getters.selectedDatesForKeys([
        'deliverStartDate',
        'deliverEndDate',
        'availableStartDate',
        'availableEndDate',
        'orderByStartDate',
        'orderByEndDate',
        'shipByStartDate',
        'shipByEndDate',

      ]) : date;
      if (!fromDashBoard) {
        vendor = isEmpty(store.state.queryParams.vendor) ? []
          : map(store.state.queryParams.vendors, '_id');
      }
      let params = {
        filterNoItemOrders: false,
        module: ['Materials', 'Sourcing'],
        showAllCompanyOrders: store.state.queryParams.showAllCompanyOrders,
        company: store.getters.selectedIdsForKey('companies', false),
        location: store.getters.selectedIdsForKey('locations', false),
        tag: store.getters.selectedIdsForKey('tags'),
        owner: store.getters.selectedOwner,
        submittals: store.getters.selectedSubmittals,
        ...dateToQuery,
        category: !isEmpty(categoryId) ? [categoryId] : [],
        recipient: store.getters.selectedIdsForKey('recipients'),
        supplier: store.getters.selectedIdsForKey('suppliers'),
        vendor: vendors,
        search: store.state.queryParams.searchText,
        isManager: false,
        stage: !isEmpty(stages) ? [stages] : store.getters.selectedValuesForKey('materialStage'),
        showPrivate: true,
        orderId: state.orderId,
        levels: store.getters.selectedIdsForKey('level'),
        zones: store.getters.selectedIdsForKey('zone'),
        screen: 'materialStatus',
        showDuplicates: state.showDuplicates,
        poCreatedAtStart,
        fromDashBoard,
        isLate,
        isDelivered,
        ...commonParams,
      };
      const projects = !isEmpty(projectId) ? projectId : allProjectIds;
      // when we refresh the page the projects to set in store takes time and the loadData
      // is triggered which sends the activeproject where the selected filter project is different
      // so used the showAllCompanyOrders flag to filter the orders based on all projects
      if (!isEmpty(state.orderId) && isEmpty(store.state.queryParams?._projects)) {
        params.showAllCompanyOrders = true;
      }
      params = await getNegativeList(params, {
        projectId: projects,
        owner: store.getters.selectedOwner?.user?._id,
        recipient: store.getters.selectedIdsForKey('recipient'),
      });
      const actualParams = fromDashBoard ? dashBoardParams : params;
      const data = await SupplyChain.supplyChain(actualParams);
      if (materialStatusTable.value?.selectedBulkAction?.event === 'massUpdateDates') {
        const massUpdateData = {
          total: data.total,
          data: filter(data.data, (order) => {
            const projectSetting = store.getters.getProjectSettings(
              order.project._id,
              order.owner.company._id,
            );
            if ((['delivery', 'mixed', 'in-transit', 'complete',
              'rejected'].includes(order.stage))
              || (order.isManager === false && (projectSetting.isPrivate
              && order.owner.company._id !== store.state.userData.company))) {
              return false;
            }
            return true;
          }),
        };
        state.isLoading = false;
        return massUpdateData;
      }
      state.isLoading = false;
      return data;
    };
    const refreshTable = async () => {
      await materialStatusTable.value.refreshTable();
    };
    // eslint-disable-next-line consistent-return
    const saveRow = async (rowData) => {
      let order = cloneDeep(rowData);
      try {
        store.commit('setLoading', true);
        order = await order.save();
        set(rowData, 'isEditing', !rowData.isEditing);
        Object.assign(rowData, order);
      } catch (e) {
        const msg = get(e.data, 'message', '')
          || get(e.data, 'msg', '') || _.get(e, 'message', '')
          || 'Error saving: please contact ManufactOn support';
        return toast.error(msg);
      } finally {
        store.commit('setLoading', false);
      }
    };
    function openRow(card) {
      router.push({
        name: `${card.__t === 'Souring' ? 'sourcing' : 'material'}-edit`,
        params: { projectId: card.project._id, cardId: card._id, stage: card.stage },
      });
    }
    const onCellClicked = async (event) => {
      const order = event.data;
      if (event.type === 'save') {
        await saveRow(order);
      } else if (event.type === 'openCard') {
        openWithUserPref(event.data);
      } else if (event.type === 'activity') {
        state.isActive = true;
        state.activityDetails.id = event.data._id;
        state.activityDetails.projectId = event.data.project._id;
        state.activityDetails.orderName = event.data.name;
      } else if (event.type === 'excelExport') {
        props.excelExport();
      } else if (event.type === 'printQr') {
        printQr(event.data);
      } else if (event.type === 'lmv') {
        openLmvModel(event.data);
      }
    };

    const updateNotes = async (event) => {
      if (event.type === 'save' && !event.isItem) {
        let order = cloneDeep(event.data);
        set(event.data, 'isEditing', !event.data.isEditing);
        order = await saveRow(order);
        Object.assign(event.data, order);
      }
    };

    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      state.selectedOrder = {};
    });

    const closeDocumentSlider = () => {
      state.isFileListVisible = false;
    };

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      state.selectedOrder = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      state.selectedItem = payload.data;
      state.rowField = payload.rowField;
      state.isFileListVisible = payload.isActive;
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });
    onBeforeMount(async() => {
      state.allProjects = await Projects.allProjects();
      if (route?.query?.materialId) {
        state.orderId = route.query.materialId.split(',');
        state.showDuplicates = true;
      }
    });

    return {
      ...toRefs(state),
      materialStatusTable,
      stageFromUrl,
      loadData,
      calculatePercentCompleteRunLevel,
      calculatePercentCompleteItemLevel,
      onCellClicked,
      updateNotes,
      closeNotesSlider,
      closeDocumentSlider,
    };
  },
};
</script>
