export default {
  'ORDER ID': {
    field: 'uniqueOrderId',
    multiple: false,
    required: true,
  },
  'ITEM NAME': {
    field: 'name',
    multiple: false,
    required: true,
  },
  QUANTITY: {
    field: 'quantity',
    multiple: false,
    required: true,
  },
  'CATALOG ID': {
    field: 'catId',
    multiple: false,
  },
  'ITEM ID': {
    field: 'itemId',
    multiple: false,
  },
  NOTES: {
    field: 'note',
    multiple: false,
  },
};
