<template>
  <o-modal
      :active="isActive"
      :canCancel="false"
      class="modal-md"
    >
    <div class="modal-card">
      <!-- Modal Header -->
      <header class="modal-card-head">
        <h4 class="modal-card-title" >
           Create Catalog Cut Part
        </h4>
        <!-- <div class="is-divider-vertical"></div> -->
        <!-- <i class="icon-close is-clickable" @click="$emit('close')"></i> -->
      </header>

      <!-- Modal Body -->
      <div class="modal-card-body is-visible has-background-white p-0">
          <div class="column pt-0">
              <h4 class="is-size-3">
                Cut Part Details
              </h4>
            <div class="columns">

              <div class="column pt-0">
                  <div class="field">
                    <div class="field-label">
                      <label class="label is-size-3"> Name *</label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                          <input class="input"
                            v-model="assemblyName"
                            placeholder="Enter Name">
                        </div>
                    </div>
                  </div>

                   <div class="field">
                    <div class="field-label">
                      <label class="label is-size-3"> Description </label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <textarea class="textarea" rows="3" v-model="assemblyDesc"
                        placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                  </div>
                </div>

                <div class="column pt-0">
                  <div class="field">
                    <div class="field-label">
                      <label class="label is-size-3"> Catalog ID* (Catalog ID will be generated if one is not entered.) </label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <input class="input"
                        @blur="catIdValidate($event)"
                        v-model="assemblyCatId"
                        placeholder="Enter Catalog ID">
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="field-label">
                      <label class="label is-size-3"> Cut Part ID</label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                          <input class="input"
                            v-model="assemblyId"
                            placeholder="Enter ID">
                        </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="field-label">
                      <label class="label is-size-3"> Category </label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <mf-multi-select
                          :modelValue="!isEmpty(selectedCategories) ? selectedCategories : []"
                          v-model="selectedCategories"
                          label="name"
                          placeholder="Select Category"
                          :searchable="false"
                          :allow-empty="false"
                          :options="categories?.data"
                          :multiple="false"
                          track-by="_id"
                          @update:modelValue="fetchSubCategories()"
                        ></mf-multi-select>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="field-label">
                      <label class="label is-size-3"> Sub Category </label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                            <mf-multi-select
                              :modelValue="!isEmpty(selectedSubcategory) ? selectedSubcategory : []"
                              v-model="selectedSubcategory"
                              label="name"
                              placeholder="Select Subcategory"
                              :searchable="false"
                              :allow-empty="false"
                              :options="subCategories"
                              track-by="_id"
                            ></mf-multi-select>
                        </div>
                    </div>
                  </div>
               </div>
            </div> <!-- end of columns -->
          </div>
          <div class="column">
            <div class="columns ">
              <div class="column is-12">
                <h4 class="is-size-3">
                  Cut Part Material* (Limited to 1 Catalog Part)
                </h4>
                <search-bar
                  placeholder="Search"
                  ref="searchComp"
                  :shouldEmit="true"
                  @search="getSearchValue"
                ></search-bar>
                <div class="table-container modal-table">
                  <mf-table
                  ref="partsTable"
                  :key="refreshKey"
                  :apiMode="true"
                  :tableProps="tableProps"
                  :loadData="loadData"
                  @row-select="getSelectedRow($event)"
                ></mf-table>
                </div>
              </div>
            </div>
           </div>
           <!-- mf table -->
        </div>

      </div>

      <!-- Modal Footer -->
      <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
          <div class="column is-narrow ">
            <div>
              <button class="button is-outlined" @click="$emit('close', {cancel: true})">
                Cancel
              </button>
               <button class="button has-background-black-bis" :disabled="disableCreate" @click="createAssembly">
                Create Cut Part
              </button>
            </div>
          </div>
      </footer>
      <o-loading
        :full-page="true"
        :active="isLoading"
        :can-cancel="true"
      ></o-loading>
  </o-modal>
</template>
<script>
import {
  reactive, toRefs, onMounted, defineAsyncComponent, ref, computed,
} from 'vue';
import { useStore } from 'vuex';
import Vendors from '@/models/Vendors';
import Catalogs from '@/models/Catalogs';
import Validation from '@/components/utils/Validations';
import {
  get, isEmpty, forEach, inRange, trim, defaults, first, has,
} from 'lodash';
import tableDefinition from '@/components/table-cols/materialsAddCols';
import { useToast } from 'vue-toastification';
import SearchBar from '@/components/SearchBar.vue';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default {
  name: 'CreateAssembly',
  props: ['isActive'],
  components: {
    MfTable,
    SearchBar,
  },
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const partsTable = ref(null);
    const state = reactive({
      isLoading: false,
      selectedCategories: {},
      selectedSubcategory: {},
      categories: [],
      subCategories: [],
      assemblyId: '',
      assemblyName: '',
      assemblyDesc: '',
      assemblyCatId: '',
      refreshKey: 1,
      selectedParts: [],
      searchToken: '',
    });
    const tableProps = tableDefinition;
    const validationChecks = (assemblyParts) => {
      let errMsg = null;
      if (isEmpty(assemblyParts.name) || assemblyParts.name.trim().length < 3) {
        errMsg = 'Cut Part Name should be atleast 3 characters long!';
      } else if (!isEmpty(assemblyParts.catId) && !inRange(assemblyParts.catId.length, 4, 33)) {
        errMsg = 'CatId should be 4 to 32 character long';
      } else if (!isEmpty(assemblyParts.catId) && /^[A-Za-z0-9._-]+$/.test(assemblyParts.catId) === false) {
        errMsg = 'CatId can only contain alpha-numeric characters, periods, dashes and underscores';
      }

      if (!isEmpty(assemblyParts.parts)) {
          if (isEmpty(assemblyParts.parts.catId) || (!isEmpty(assemblyParts.parts.catId) && !Validation.validateCatalogId(assemblyParts.parts.catId))) {
            errMsg = "part's catId is mandatory and should be 4 to 32 character long";
          } else if (!Validation.isValidFloat(assemblyParts.parts, 'quantity')) {
            if (!trim(assemblyParts.parts.quantity)) {
              assemblyParts.parts.quantity = 1;
            } else {
              errMsg = 'Part Quantity must be integer and should be in range between 0.0001 and 9999';
            }
          }
      }
      if (errMsg) {
        toast.error(errMsg);
        return false;
      }
      return true;
    };
    const catIdValidate = (event) => {
      const assemblyCatId = event.target.value;
      if (!isEmpty(assemblyCatId)) {
        state.assemblyCatId = Validation.getValidCatId(assemblyCatId);
        if (assemblyCatId.toUpperCase() !== state.assemblyCatId) {
          toast.success('Updated Catalog Id by removing unsupported characters');
        }
      }
    };
    const validateCostCode = (assemblyParts) => {
      if (!isEmpty(assemblyParts.costCode)) {
        forEach(assemblyParts.costCode, (costCode) => {
          if (!isEmpty(costCode.code)) {
            costCode.code = costCode.code.trim();
            costCode.code = costCode.code.replace(/ /g, '.');
          }
        });
      }
    };

    const createAssembly = async () => {
      const type = 'cutparts';
      state.isLoading = true;

      const newAssembly = {
        customId: state.assemblyId,
        name: state.assemblyName,
        desc: state.assemblyDesc,
        category: state.selectedCategories,
        catId: state.assemblyCatId,
        subCategory: isEmpty(state.selectedSubcategory) ? state.subCategories[0] : state.selectedSubcategory,
        isEditing: true,
        newRow: true,
        parts: state.selectedParts,
        costCode: '',
        isCutPart: true,
      };
      try {
        // validate cost code
        validateCostCode(newAssembly);

        if (!isEmpty(newAssembly.catId)) {
          newAssembly.catId = Validation.getValidCatId(newAssembly.catId);
        }
        let assemblyParts;
        if (validationChecks(newAssembly)) {
          assemblyParts = await Catalogs.addAssemblyParts([newAssembly], type);

          if (!isEmpty(assemblyParts.failedData)) {
            const errMessage = get(assemblyParts.failedData[0], '_errorMsg', 'Please Contact ManufactOn Support');
            toast.error(errMessage);
          }

          if (!isEmpty(assemblyParts.data)) {
            toast.success('New Cut Part added');
            emit('close');
          }
        }
      } catch (err) {
        console.log('err', err);
        toast.error(`${err.data.message || err.data.msg || 'There was some error creating Cut Part'}`);
      }
      state.isLoading = false;
    };

    const loadData = async (params) => {
      defaults(params, {
        // categoryId: get(state.selectedCategories, '_id', ''),
        // subCategoryId: state.selectedSubcategory?._id,
        type: 'Parts',
        limit: 25,
        page: 1,
        sort: [],
        showItems: 'show-active-catalogs',
        isCutPart: true,
      });
      params.search = state.searchToken;
      const data = await Catalogs.getAssemblyParts(params);
      forEach(data.data, (part) => {
        part.isAlreadySubAssembly = has(part, 'assemblies') && part.assemblies.length;
        if (!part.quantity) {
          part.quantity = '';
        }
      });

      return data;
    };

    const emitRefreshParams = () => {
      emit('re-render-table', { categories: state.selectedCategories, subCategories: state.selectedSubcategory });
      state.refreshKey++;
    };

    const fetchSubCategories = async () => {
      if (!isEmpty(state.selectedCategories)) {
        const categoryId = get(state.selectedCategories, '_id', '');
        if (!isEmpty(categoryId)) {
          state.isLoading = true;
          state.subCategories = await Vendors.getSubCategories({ categoryId });
          state.selectedSubcategory = first(state?.subCategories) || [];
          state.isLoading = false;
          // emitRefreshParams();
          return;
        }
      }
      state.subCategories = [];
      state.selectedSubcategory = {};
      // emitRefreshParams();
    };

    const getSelectedRow = (selectedRow) => {
      state.selectedParts = selectedRow;
    };

    const getSearchValue = (searchValue) => {
      state.searchToken = searchValue;
      emitRefreshParams();
    };

    onMounted(async () => {
      // initialze all categories
      state.isLoading = true;
      state.categories = await Vendors.getCategories({ limit: 500 });
      state.isLoading = false;
    });

    const disableCreate = computed(() => {
      if (isEmpty(state.assemblyName) || state.assemblyName.length <= 2) {
        return true;
      }
      if (isEmpty(state.selectedParts)) return true;
      return false;
    });

    const itemsCanSelect = computed(() => state.selectedParts.length);

    return {
      ...toRefs(state),
      createAssembly,
      fetchSubCategories,
      emitRefreshParams,
      validateCostCode,
      tableProps,
      loadData,
      partsTable,
      getSearchValue,
      isEmpty,
      disableCreate,
      catIdValidate,
      itemsCanSelect,
      getSelectedRow,
    };
  },
};
</script>
<style scoped>
</style>
