import { get } from 'lodash';
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async getProjectView(params) {
    try {
      const { data: productivity } = await req.get('productivity/projectView', {
        params: {
          projectId: params.projectId,
          userCompanyId: params.userCompanyId,
          customId: get(params, 'customId', ''),
          stage: get(params, 'stage', []),
        },
      });
      return productivity;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getAllRunView(params) {
    try {
      const { data: productivity } = await req.get('productivity/allRunView', {
        params: {
          projectId: params.projectId,
          userCompanyId: params.userCompanyId,
        },
      });
      return productivity;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getRawData(params) {
    params = {
      limit: 25,
      page: 1,
      ...params,
    };
    try {
      const { data: productivity } = await req.get('productivity/chart/rawData', { params });
      return productivity;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
