<template>
  <div v-if="isStoreInit" class="column">
    <div class="columns is-fullheight">
      <o-loading :active="store.state.isLoading"></o-loading>
      <SideMenu v-if="isLoggedIn"/>
      <div class="column main-container" :class="getActiveViewColor">
        <section class="header">
          <MainHeader v-if="!hideMainHeader"/>
          <ProjectTitleBar v-if="!hideMainHeader"/>
          <router-view :key="$route.fullPath"
            v-show="hideMainHeader || hideSubHeader || isInit || isSubmittals"
          />
        </section>
        <!-- <footer class="footer"> -->
        <!-- </footer> -->
      </div>
    </div>
    <o-modal :active="orientationModal" has-modal-card :canCancel="false">
       <div class="modal-card is-small">
          <header class="modal-card-head">
          </header>
          <section class="modal-card-body">
            <p class="has-text-centered is-size-3">
              Portrait mode not supported. Please rotate to landscape mode.</p>
          </section>
          <footer class="modal-card-foot">
          </footer>
       </div>
    </o-modal>
  </div>
</template>

<script>
import {
  reactive, computed, onBeforeMount, watch, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { debounce } from 'lodash';
import SideMenu from '@/components/SideMenu.vue';
import screens from '@/constants';
import MainHeader from '@/components/MainHeader.vue';
import ProjectTitleBar from '@/components/ProjectTitleBar.vue';

export default {
  components: {
    SideMenu,
    MainHeader,
    ProjectTitleBar,
  },
  setup() {
    const localState = reactive({
      isInit: false,
      orientationModal: false,
      isStoreInit: false,
    });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const callOrientationToast = () => {
      const restrictPortrait = window.matchMedia('(orientation: portrait)');
      const isHandHeldDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (restrictPortrait.matches && (isHandHeldDevice || (navigator.platform === 'MacIntel'
      && navigator.maxTouchPoints > 1))) {
        localState.orientationModal = true;
      } else {
        localState.orientationModal = false;
      }
    };

    const isLoggedIn = computed(() => !(route.meta && route.meta.skipUser));

    const isRejected = computed(() => ['rejected', 'delivery', 'complete', 'mixed'].includes(route.params?.stage));

    const screenConstants = computed(() => screens[store.state.activeScreen] || {});

    const hideMainHeader = computed(() => screenConstants.value.hideMainHeader || isRejected.value || ['lmv-view', 'lmv-view-card', 'lmv-view-item'].includes(store.state.activeScreen));

    const hideSubHeader = computed(() => screenConstants.value.hideSubHeader || false);

    const isSubmittals = computed(() => screenConstants.value.isSubmittals || false);

    onBeforeMount(async () => {
      await store.restored;
      localState.isStoreInit = true;
      callOrientationToast();
      window.addEventListener('resize', callOrientationToast);
      const routeMeta = router.currentRoute.value.meta;
      if (routeMeta && routeMeta.skipUser) return;
      await store.dispatch('getUserData');
      // if (store.state.userData._id) store.dispatch('updateActiveProj');
      // initialized is set in GlobalFilters, but if the page has no filters to show
      // then take to be yes
      if (hideSubHeader.value) {
        store.commit('setQueryParams', { initialized: true });
      }
      // store.commit('setIcons', [{ name: 'multiDelete', val: false },
      // { name: 'eyeIcon', val: false }]);
      if (isLoggedIn.value && process.env.NODE_ENV === 'production') {
        const user = await store.getters.userPromise;
        // eslint-disable-next-line
        pendo.initialize({
          visitor: {
            id: user._id,
            email: user.email,
            userName: user.firstName,
            role: user.role,
          },
          account: {
            id: user.company,
            name: user.companyName,
          },
        });
      }
      // required because in the reducer in the store we set the below qps to empty
      // and when user opens a card in new tab, the qps are empty Ref #8278
      if (store.state.userData._id) store.dispatch('updateProjects');
      // Ref:Rio#6841 required because account settings tab doesn't have global filter and the below
      // written watch won't be triggered if user just reloads the page being in account settings.
      // if (route.name === 'account') localState.isInit = true;
      localState.isInit = true;
    });

    watch(
      () => store.state.queryParams,
      debounce((val) => {
        localState.isInit = val.initialized;
      }, 1000),
      { deep: true },
    );
    const getActiveViewColor = computed(() => {
      const routeMeta = router.currentRoute.value.meta;
      if (routeMeta.activeViewColor) {
        return routeMeta.activeViewColor;
      }
      return 'mf-home-background';
    });
    return {
      hideMainHeader,
      hideSubHeader,
      isSubmittals,
      ...toRefs(localState),
      store,
      isLoggedIn,
      getActiveViewColor,
    };
  },
};
</script>

<style scoped>
</style>
