import _ from 'lodash';
import store from '@/store';

export default function () {
  const bulkRequiredCheck = {
    archive: [
      { module: 'material-manager', key: 'data' },
      { module: 'material-manager', key: 'move' },
    ],
    orderMoreInv: [
      { module: 'material-manager', key: 'data' },
    ],
  };

  function commonTypes() {
    return {
      edit: 'data',
      split: 'data',
      combine: 'data',
      openCard: 'data',
      massUpdate: 'data',
    };
  }

  const moduleTypeFilter = {
    'material-manager': {
      ...commonTypes(),
      moveForward: 'move',
      clone: 'data',
      remove: 'data',
      archive: 'data',
      ship: 'move',
      reject: 'data',
      bulkActions: 'data',
    },
    prefabs: {
      ...commonTypes(),
      addPrefab: 'data',
      moveForward: 'move',
      clone: 'data',
      archive: 'data',
      p6ExcelImport: 'data',
      bulkActions: 'data',
    },
    'app&projects': {
      openCard: 'projects',
      alterCompany: 'companies',
      alterMember: 'members',
      alterProject: 'projects',
      companyTemplates: 'company_templates',
      measureUnits: 'measure_units',
      assemblyParts: 'assembly_parts',
      companyLocations: 'company_locations',
      account: 'company_settings',
      thirdPartyPlugins: 'plugins',
      generalInventory: 'general_inventory',
      companyProfile: 'company_profile',
      companyColors: 'company_colors',
      companyQr: 'company_qrs',
      lists: 'company_lists',
      companySecurity: 'company_security',
      projectMembers: 'projects',
      projectCompanies: 'projects',
      deliveryLocations: 'projects',
      locationImport: 'projects',
      archiveProjectApp: 'projects',
      removeUser: 'projects',
      deleteLocation: 'projects',
      remove: 'company_templates',
      clone: 'company_templates',
      vendors: 'vendors',
      permissionGroup: 'manufacton_settings',
      archive: 'measure_units',
      catalogArchive: 'assembly_parts',
      catalogDelete: 'assembly_parts',
    },
    'production-order': {
      ...commonTypes(),
      moveForward: 'move',
      moveBackward: 'move',
      archive: 'data',
      bulkActions: 'data',
    },
    'production-manager': {
      ...commonTypes(),
      moveForward: 'move',
      moveBackward: 'move',
      managerDetailRow: 'data',
      remove: 'data',
      bulkActions: 'data',
    },
    shipping: {
      ...commonTypes(),
      openCardByName: 'data',
      delete: 'data',
      release: 'receive',
      shipMulti: 'data',
      shipOne: 'data',
      ship: 'data',
      receive: 'receive',
      markFinal: 'data',
      cancelDelivery: 'data',
      managePartials: 'data',
      shippingList: 'data',
      bulkActions: 'data',
    },
    inventory: {
      edit: 'data',
      archive: 'data',
      inventoryOne: 'data',
      inventoryMulti: 'data',
      incDec: 'quantity',
      ordermore: 'data',
      saveAll: 'transfer_request',
      createShipment: 'data',
      createTransferRequest: 'quantity',
      invMultiRemove: 'data',
      createTr: 'data',
      shipInventory: 'data',
      export: 'data',
      import: 'data',
      bulkActions: 'data',
    },
    scm: {
      psMassUpdate: 'production-status',
      productionStatus: 'production-status',
      materialStatus: 'material-status',
      msMassUpdate: 'material-status',
      shippingStatus: 'shipping',
      massUpdate: 'checklist',
      pauseRestart: 'production-status',
      bulkActions: store.state.activeScreen === 'material-status-order-view' ? 'material-status' : 'production-status',
    },
  };

  function getResult(...obj) {
    const { resolvedPermissionGroups, company } = store.state.userData;
    const val = _.get(resolvedPermissionGroups, `${company}:${obj[0]}:${obj[1]}:${obj[2]}`, '');
    return val;
  }

  function setPermission(result, type) {
    const scmTypes = ['productionStatus', 'materialStatus', 'shippingStatus'];
    if (scmTypes.includes(type)
      && result !== 'alter') {
      // el.classList.add('disabled-anchor-tag');
      // el.classList.add('member-anchor-disabled');
      return true;
    } if (!(scmTypes.includes(type))
    && ((type === 'PermissionGroup' && result === 'none')
      || (type !== 'PermissionGroup' && result !== 'alter'))) {
      // el.setAttribute('disabled', 'disabled');
      // el.classList.add('disabledPer');
      return true;
    }
    return false;
  }

  function getPermissionValues(binding, fromDirective = false) {
    const { permissionModule, type, requiredCheck } = binding;
    const compProjId = '000000000000000000000000';
    let project = binding.project ? binding.project._id : compProjId;
    const actionType = _.get(moduleTypeFilter, `${permissionModule}.${_.camelCase(type)}`);
    if (actionType) {
      let result = getResult(project, permissionModule, actionType);
      if (!result) { // if project level permission not found then checking for global level
        project = '000000000000000000000000';
        result = getResult(project, permissionModule, actionType);
      } // added the below if condition as manufacton tab visible has view and none option.
      if (_.camelCase(type) === 'permissionGroup' && result === 'view') result = 'alter';
      if (result === 'alter' && requiredCheck) {
        requiredCheck.forEach((pm) => {
          if (result === 'alter') result = getResult(project, pm.module, pm.key);
        });
        if (permissionModule === 'scm'
        && ['productionStatus', 'materialStatus'].includes(_.camelCase(type))
        && result === 'view') {
          result = 'alter';
        }
      }
      if (fromDirective) {
        return { result, type };
      }
      return setPermission(result, type);
    }
    if (fromDirective) return { result: null, type };
    return false;
  }

  function getMappedModules() {
    if (['mm-preparation', 'mm-sourcing', 'mm-ordering', 'inventory'].includes(store.state.activeScreen)) {
      return 'material-manager';
    } if (['detailing', 'manufacturing', 'qa'].includes(store.state.activeScreen)) {
      return 'production-manager';
    }
  }

  function getReqCheckForBulkActions(permissionModule, event) {
    const required = [];
    if (['shipping', 'inventory'].includes(permissionModule)
        && !['archive', 'orderMoreInv', 'archiveInventoryItem', 'localInventoryTransfer'].includes(event)) {
      if (!['shipInventory'].includes(event)) {
        required.push({ module: getMappedModules(), key: 'move' });
      }
      if (permissionModule === 'inventory') {
        required.push({ module: 'shipping', key: 'data' });
        required.push({ module: 'shipping', key: 'receive' });
      }
    } else if (permissionModule === 'inventory'
        && ['archive', 'orderMoreInv'].includes(event)) {
      return bulkRequiredCheck[event];
    } else if (store.state.activeScreen === 'mm-sourcing') {
      return [{ module: 'shipping', key: 'data' }];
    } else if (event === 'massUpdateDates') {
      return store.state.activeScreen === 'material-status-order-view'
        ? [{ module: 'material-manager', key: 'data' }] : [{ module: 'production-manager', key: 'data' }];
    }
    return required;
  }

  function getType(type) {
    return type;
  }

  return {
    commonTypes,
    getResult,
    getPermissionValues,
    getType,
    getReqCheckForBulkActions,
  };
}
