<template>
  <iframe :src="srcurl" class="full-remaining-height"></iframe>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'load-iframe',
  props: ['src', 'submittalId', 'projectId', 'tab'],
  setup(props) {
    console.log('props   ', props.src);
    // const srcurl = computed(() => '/classic/#/settings/projects');
    const url = '/classic';
    // const url = 'http://localhost:3001/classic'; // uncomment for local

    const router = useRouter();
    const srcurl = computed(() => {
      if (router.currentRoute?.value?.name === 'mfPublic') {
        return `${url}/index.html#/mfPublic/submittal/${props.submittalId}`;
      }
      if (router.currentRoute?.value?.name === 'view-project') {
        return `${url}/index.html#/projects/${props.projectId}/${props.tab}`;
      }
      return `${url}/index.html#${props.src}`;
    });
    return {
      srcurl,
    };
  },
});
</script>
<style scoped>
.full-remaining-height {
  width: 100%;
  height: 92vh;
  margin-left: 1px;
}

</style>
