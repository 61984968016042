<template>
  <o-modal :active="isActive" class="modal-xs" :canCancel='false'>
    <div class="modal-card">
      <!-- Modal Header -->
      <header class="modal-card-head">
        <h4 class="modal-card-title" >
          Add {{ isManualTimer ? "Manual" : "Recorded" }} Work Time
        </h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="$emit('close')"></i>
      </header>
      <!-- Modal Body -->

      <section class="modal-card-body has-background-white">
        <p class="is-size-5 has-text-weight-normal is-italic has-text-right">*required</p>
        <div class="title has-text-black-bis">
          <label class="line-height">Work Step: {{ workstep?.name }}</label>
        </div>
        <div class="column">
          <div class="columns">
            <div class="column px-0">
              <label class="is-pulled-left line-height">Work Duration{{ isManualTimer ? '*' : '' }}</label>
            </div>
            <div class="column px-0">
              <div class="is-flex is-align-items-center is-justify-content-space-between" v-if="isManualTimer">
                <input type="number" v-model="timeInHrs" class="input timer-width-hour timeInput"
                  placeholder="hh"
                  :min="0" :max="999"
                />
              h:
                <input  type="number" v-model="timeInMinutes" class="input timer-width-min timeInput"
                  placeholder="mm"
                  :min="0" :max="59"
                />
              m:
                <input type="number" v-model="timeInSeconds" class="input timer-width-min timeInput"
                  placeholder="ss"
                  :min="0" :max="59"
                />
              s
              </div>
              <label v-else class="is-pulled-left line-height">{{ getHoursMinutesSecondsString(elapsedTime) }}</label>
            </div>
          </div>
          <div class="columns">
            <div class="column px-0">
              <label class="is-pulled-left line-height">Number of Workers*</label>
            </div>
            <div class="column px-0">
              <input v-model="numberOfWorkers" type="number" class="input timer-width-hour timeInput" :min="0"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
            </div>
          </div>
          <div class="columns">
            <div class="column px-0">
              <label class="is-pulled-left line-height">Total Work Time</label>
            </div>
            <div class="column px-0">
              <label class="is-pulled-left line-height">{{ getHoursMinutesSecondsString(totalElapsedTime) }}</label>
            </div>
          </div>
        </div>
      </section>

      <!-- Modal Footer -->
      <footer class="modal-card-foot is-justify-content-space-between">
        <div class="column is-narrow">
          <button class="button" :class="isManualTimer ? 'is-outlined' : 'is-danger'" @click="$emit('cancel-timer', workstep)">
            Cancel{{ isManualTimer ? '' : ' Timer' }}
          </button>
        </div>
        <div class="column is-narrow">
          <button v-if="!isManualTimer" class="button is-outlined" @click="$emit('close')">
            Continue Timer
          </button>
          <button
            class="button is-success"
            @click.once="saveTimer()"
            :disabled="isAddTimerDisabled"
          >
            Add Time
          </button>
        </div>
      </footer>
    </div>
    <o-loading
      :full-page="true"
      :active="isLoading"
      :can-cancel="false"
    >
    </o-loading>
  </o-modal>
</template>

<script>
import {
  defineComponent, reactive, toRefs, computed,
} from 'vue';
import UtilityMixin from '../mixins/UtilityMixin';
import { find, get } from 'lodash';
import moment from 'moment';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'RunsTimer',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isManualTimer: {
      type: Boolean,
      default: true,
    },
    workstepId: {
      type: Object,
      default: {},
    },
    card: Object,
    isLoading: {
      type: Boolean,
    }
  },
  setup(props, { emit }) {
    const toast = useToast();
    const state = reactive({
      workstep: {},
      hours: 0,
      minutes: 0,
      seconds: 0,
      elapsedTime: 0,
      totalElapsedTime: 0,
      noOfWorkers: 0,
    });
    const { getHoursMinutesSecondsString, getHoursMinutesSeconds } = UtilityMixin();
    const maxHoursAllowed = 999;
    const maxTotalElapsedtimeAllowed = 3599999;   // (999 Hours * 3600) + (59 Minutes * 60) + 59

    state.workstep = find(get(props, 'card.manager.runs', []), (run) => run._id === props?.workstepId || run.uuid === props?.workstepId);

    const managerPT = _.get(
      props.card.manager,
      `pt.${state?.workstep?.uuid}`, {},
    );
    const startTimer = get(managerPT, 'startTimer', moment().toISOString());
    const stopTimer = moment().toISOString();
    const clockSeconds = props.isManualTimer ? 0 : (moment(stopTimer).diff(startTimer, 'seconds'));
    state.elapsedTime = clockSeconds;
    state.totalElapsedTime = clockSeconds * state.numberOfWorkers || 0;
    const { hours, minutes, seconds } = getHoursMinutesSeconds(state.elapsedTime);
    state.hours = hours;
    state.minutes = minutes;
    state.seconds = seconds;

    const timeInHrs = computed({
      get: () => {
        return state.hours;
      },
      set: (val) => {
        val = parseInt(val, 10);
        if ((val < 0
          || Number.isNaN(val))) { val = 0; }
        if (val >= maxHoursAllowed) {
          val = maxHoursAllowed;
        }
        state.hours = val;
        state.totalElapsedTime = ((state.hours * 3600) + (state.minutes * 60) + state.seconds) * state.noOfWorkers;
        if (state.totalElapsedTime > maxTotalElapsedtimeAllowed) {
          toast.error(`Entered hours maximum is ${maxHoursAllowed}`);
        }
      }
    });

    const timeInMinutes = computed({
      get: () => {
        return state.minutes;
      },
      set: (val) => {
        val = parseInt(val, 10);
        if ((val < 0
          || Number.isNaN(val))) { val = 0; }
        if (val >= 59) val = 59;
        state.minutes = val;
        state.totalElapsedTime = ((state.hours * 3600) + (state.minutes * 60) + state.seconds) * state.noOfWorkers;
        if (state.totalElapsedTime > maxTotalElapsedtimeAllowed) {
          toast.error(`Entered hours maximum is ${maxHoursAllowed}`);
        }
      }
    });

    const timeInSeconds = computed({
      get: () => {
        return state.seconds;
      },
      set: (val) => {
        val = parseInt(val, 10);
        if ((val < 0
          || Number.isNaN(val))) { val = 0; }
        if (val >= 59) val = 59;
        state.seconds = val;
        state.totalElapsedTime = ((state.hours * 3600) + (state.minutes * 60) + state.seconds) * state.noOfWorkers;
        if (state.totalElapsedTime > maxTotalElapsedtimeAllowed) {
          toast.error(`Entered hours maximum is ${maxHoursAllowed}`);
        }
      }
    });

    const numberOfWorkers = computed({
      get: () => {
        return state.noOfWorkers;
      },
      set: (val) => {
        state.noOfWorkers = val;
        state.totalElapsedTime = ((state.hours * 3600) + (state.minutes * 60) + state.seconds) * state.noOfWorkers;
        if (state.hours > maxHoursAllowed || (state.hours * val) > maxHoursAllowed || state.totalElapsedTime > maxTotalElapsedtimeAllowed) {
          toast.error(`Entered hours maximum is ${maxHoursAllowed}`);
        }
      }
    });

    const isAddTimerDisabled = computed(() => state.totalElapsedTime <= 0);

    const saveTimer = () => {
      state.workstep.workers = state.noOfWorkers;
      if (props.isManualTimer) {
        state.workstep.clockHrs = state.hours;
        state.workstep.clockMins = state.minutes;
        state.workstep.clockSeconds = state.seconds;
      }
      emit('save-timer', state.workstep)
    }

    return {
      ...toRefs(state),
      getHoursMinutesSecondsString,
      timeInHrs,
      timeInMinutes,
      timeInSeconds,
      numberOfWorkers,
      isAddTimerDisabled,
      saveTimer,
    }
  },
});
</script>