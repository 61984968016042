import _ from 'lodash';

export default {
  setSortParams(sortArr) {
    const sortParamsObj = {};
    _.forEach(sortArr, (elm) => {
      Object.assign(sortParamsObj, { [elm.sortField]: elm.direction === 'asc' ? 1 : -1 });
    });
    return sortParamsObj;
  },
};
