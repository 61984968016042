<template>
  <div class="field">
    <mf-multi-select
      v-if="showDropdown"
      v-model="inputValue"
      :options="selectOpts"
      :max-height="200"
      :titleLabel="titleLabel"
      :labelPosition="labelPosition"
      :disabled="disableLocation"
      labelClass="has-text-black-bis is-italic is-size-3"
      label="name"
      track-by="_id"
      group-label="key"
      group-values="val"
      :allow-empty="false"
    >
    </mf-multi-select>
    <span
      v-else-if="inputValue"
    >
      {{ inputValue.name }}
    </span>
  </div>
</template>

<script>
import {
  onMounted,
  reactive,
  toRefs,
  computed,
  watch,
} from 'vue';
import {
  isEmpty,
  isUndefined,
  isFunction,
  find,
  filter,
} from 'lodash';
import { useStore } from 'vuex';
import MfMultiSelect from '@/components/abstract/MfMultiSelect';
import Locations from '@/models/Locations';
import Projects from '@/models/Projects';
import UtilityMixin from '../mixins/UtilityMixin';

export default {
  name: 'LocationSelect',
  props: {
    shipFromInventory: {
      type: Boolean,
      default: false,
    },
    titleLabel: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: 'top',
    },
    value: Object,
    companyId: String,
    projectId: String,
    options: Array,
    rowData: Object,
    isEditing: Boolean,
    rowField: Object,
    currentProjectId: String,
    deliveryProjectId: String,
    isDisabled: [Boolean, Function],
    commonStockProjectId: String,
  },
  components: {
    MfMultiSelect,
  },
  setup(props, { emit }) {
    const {
      companyId,
      projectId,
      options,
      rowField,
      currentProjectId,
      deliveryProjectId,
    } = props;
    const store = useStore();
    const { getAllLocations } = UtilityMixin();
    // data
    const data = reactive({
      selectOpts: [],
    });

    const inputValue = computed({
      get: () => (isEmpty(props.value) ? null : props.value),
      set: (val) => (emit('update:value', val)),
    });

    const getCommonStockProjectId = async () => {
      const commonStockProject = store.state.queryParams.commonStockProject
      || await Projects.getCommonStockProject();
      return commonStockProject?._id;
    };

    // methods
    const loadData = async () => {
      if (isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
      let project = {};
      let allLocations = [];
      if (['deliveryLocation', 'deliveryLocations'].includes(rowField?.id)) {
        if (deliveryProjectId
          && await getCommonStockProjectId() === deliveryProjectId
          && currentProjectId) {
          project = store.getters.findProject({ _id: currentProjectId });
        } else project = store.getters.findProject({ _id: deliveryProjectId });
        allLocations = await getAllLocations(project._id);
      } else if (!isUndefined(props.projectId)) {
        allLocations = await getAllLocations(props.projectId);
      }
      allLocations = filter(allLocations, (loc) => loc.company._id === store.state.userData.company)
      return Locations.groupLocations(allLocations, store.state.companyData);
    };

    const populateOpts = async () => {
      const { commonStockProject } = store.state.queryParams;
      if ((props.deliveryProjectId === commonStockProject._id)&& (!props?.rowField || props?.rowField?.id !== 'nowAt')) {
        let locs = await getAllLocations(props.deliveryProjectId);
        locs = [find(locs, { nestedLocation: true })];
        locs = await Locations.groupLocations(locs, store.state.companyData);
        data.selectOpts = locs;
        inputValue.value = find(locs[0].val, (loc) => loc._id === props?.value._id) || {};
        return null;
      }
      if (!props.isEditing && !props.shipFromInventory) return;
      if (isEmpty(options)) {
        data.selectOpts = await loadData();
        if (props.deliveryProjectId && props.deliveryProjectId !== commonStockProject._id) {
          data.selectOpts[0].val = data.selectOpts[0].val.filter((loc) => loc.nestedLocation !== true);
        }
      } else {
        data.selectOpts = props.options;
      }
    };

    const showDropdown = computed(() => {
      if (store.state.activeScreen === 'production-status-order-view') {
        if (['coordination', 'planning'].includes(props.rowData.stage)) {
          return false;
        }
      }
      return props.isEditing;
    });

    onMounted(populateOpts);

    // watch(() => props.isEditing, populateOpts);
    watch(() => props.options?.length || props.isEditing, populateOpts);

    const disableLocation = computed(() => {
      if (isFunction(rowField?.inputProps?.isDisabled)) {
        return rowField?.inputProps?.isDisabled(props.rowData);
      }
      return props.isDisabled;
    });

    return {
      ...toRefs(data),
      inputValue,
      showDropdown,
      loadData,
      disableLocation,
    };
  },
};
</script>
