<template>
  <component
    v-for="f of fields"
    :key="f.prop"
    :is="f.name"
    v-bind="propFormatter(f)"
    :value="$_.includes(f.prop, 'uniqueOrderId') ? $_.get(card, 'uniqueOrderId.isSystemGenerated', false) ? '' : $_.get(card, f.prop, '') : $_.get(card, f.prop, '')"
    @update:value="(v) => updateValue(v, f)"
    :isEditing="true"
    :rowField="f"
    :row-data="card"
    @event="(e) => $emit('event', e)"
    @cell-clicked="(e) => $emit('cellClicked', {type: e.type, data: card})"
    :disablePause="disablePause"
  >
  </component>
</template>
<script>
import { defineComponent } from 'vue';
import {
  get, mapValues, indexOf, find, set, clamp, isEmpty,
} from 'lodash';
import { DialogProgrammatic } from '@/components/Dialog';

export default defineComponent({
  name: 'BaseCardColumn',
  emits: ['event', 'cellClicked', 'updateDates'],
  props: {
    fields: Object,
    card: Object,
    kind: String,
    disablePause: Boolean,
  },
  setup(props, { emit }) {
    const dateMinMax = (f) => {
      const date = f.prop;
      const datesOrder = props.card.datesOrder();
      const idx = indexOf(datesOrder, date);
      const obj = { kind: date, ...f.inputProps };
      let isEdit;
      if (f?.inputProps?.disableEdit) {
        isEdit = false;
      } else isEdit = true;

      if (props.card.isCutOrder){
        if (f.prop === 'detailBy') {
          obj.max = ['manufactureBy']
        }
        if (f.prop === 'manufactureBy') {
          obj.min = ['detailBy']
        }
      } else if (datesOrder.includes(date)) {
        if (idx > 0) obj.min = datesOrder.slice(0, idx);
        if (idx < datesOrder.length - 1) obj.max = datesOrder.slice(idx + 1);
      }
      return {
        ...f,
        inputProps: obj,
        value: find(props.card.dates, (d) => d.kind === date)?.value,
        item: props.card,
        isEdit,
      };
    };
    const propFormatter = (f) => {
      if (props.kind === 'dates') {
        return dateMinMax(f);
      }
      if (props.kind === 'constant') return f;
      const cardProps = mapValues(f.cardProps || {}, (v) => get(props.card, v));
      const inputProps = get(f, 'inputProps', []);
      if (f && get(f, 'label', '') === 'files') return { ...f, order: props.card };
      if (f && get(f, 'label', '') === 'Tags') return { ...f, card: props.card };
      return {
        ...f, ...cardProps, ...inputProps, value: get(props.card, f.value, []),
      };
    };

    const updateValue = async (v, f) => {
      if (f.prop === 'baseDelivery.recipient') {
      // The server expects the data to be in recipientId and not recipient for MM
        set(props.card, 'baseDelivery.recipientId', v);
      } else if (f.prop === '_bomTasks') {
        set(props.card, `_bomTasks.${v.type}`, v.val);
        return;
      } else if (f.prop === 'baseDelivery.vendor' && (props.card.isMM() || props.card.isSourcing())) {
        if (!isEmpty(props.card.items)) {
          const confirmParam = {
            title: 'Update Unit Prices?',
            message: 'Would you like to update the unit price\'s data with new vendor\'s data',
            okButton: 'Yes,update',
            cancelButton: 'Do not update',
            type: 'warning',
            onConfirm: async () => {
              await props.card.calcItemCostAndDate([], v);
            },
          };
          await props.card.calcItemCostAndDate([], v, 'leadTime');
          if (['sourcing', 'ordering', 'delivery'].includes(props.card?.stage)) DialogProgrammatic.confirm(confirmParam);
          emit('updateDates');
        }
      } else if (f.prop === 'extraCost') {
        set(props.card, 'calcExtraCost', clamp(v, 0, 999999));
      }
      set(props.card, f.prop, v);
    };

    return {
      propFormatter,
      dateMinMax,
      updateValue,
    };
  },
});
</script>
