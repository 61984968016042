<template>
  <div>
    <VDropdown :shown="isOpen" placement="bottom-start" @update:shown="setDateOnShow">
      <!-- This will be the popover reference (for the events and position) -->
      <div
        class="is-flex is-align-items-center pl-1 pr-2 is-clickable has-text-weight-normal
          is-justify-content-space-between has-background-white multidate-col"
        @click="isOpen = !isOpen" :disabled="fromDashBoard"
      >
        <div class="is-size-3 text-overflow text-clamp" v-tooltip="datePlaceholder" :key="datePlaceholder">
          {{datePlaceholder}}
        </div>
         <i class="icon-datepicker"></i>
      </div>
      <!-- This will be the content of the popover -->
      <template #popper>
        <div  class="card">
          <header class="card-header has-background-grey-lighter">
            <h4
              class="card-header-title is-size-3 has-text-black-bis
                  is-capitalized has-text-weight-bold is-paddingless"
            >
              {{title}}
            </h4>
          </header>
          <section class="card-content is-marginless has-background-white">
            <div class="column">
              <div class="columns">
                <div class="column pl-0">
                   <field-generic-input
                      label="From: "
                      labelPosition="is-left"
                      :value="fromDateFormatted"
                      placeholder="MM/DD/YYYY"
                      class="has-text-weight-bold"
                      :disabled="true"
                      :isEditing="true"
                    >
                     </field-generic-input>
                </div>
                <div class="column pl-0">
                  <field-generic-input
                      label="To: "
                      labelPosition="is-left"
                      :value="toDateFormatted"
                      class="has-text-weight-bold"
                      :disabled="true"
                      placeholder="MM/DD/YYYY"
                      :isEditing="true"
                    >
                  </field-generic-input>
                </div>
              </div>
            </div>

            <div class="is-flex column is-paddingless">
                <div class="">
                  <o-datepicker
                    v-model="fromDate"
                    class="is-inline"
                    inline
                    :placeholder="dateFormat"
                  >
                  </o-datepicker>
                </div>
                <div class="">
                  <o-datepicker
                    v-model="toDate"
                    class="is-inline"
                    inline
                    :placeholder="dateFormat"
                    :min-date="fromDate"
                    :disabled="!fromDate"
                    ref="fromDatePicker"
                    :rootClass="fromDatePicker && fromDatePicker.showPrev ? 'prv-button' : ''"
                  >
                  </o-datepicker>
                </div>
            </div>
          </section>
          <footer
            class="card-footer is-justify-content-space-between has-background-grey-lighter"
          >
            <div>
              <button
                class="button is-outlined has-text-weight-bold"
                @click="clear(false)"
              >
                Clear All
              </button>
            </div>
            <div class="buttons">
              <button
                @click="clear(true)"
                class="button is-outlined is-danger has-text-weight-bold"
              >
                Discard
              </button>
              <button
                class="button has-text-weight-bold is-success has-text-white"
                :disabled="applyDisabled"
                @click="apply(true)"
              >
                Apply
              </button>
            </div>
          </footer>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  ref,
  onBeforeMount,
} from 'vue';
import { pick, debounce } from 'lodash';
import moment from 'moment';
import { useRoute } from 'vue-router';

export default {
  name: 'DateRange',
  props: {
    title: {
      type: String,
      default: 'Date Range',
    },
    dateValues: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const fromDatePicker = ref(null);
    // data
    const data = reactive({
      isOpen: false,
      dateFormat: 'MM/DD/YYYY',
      fromDate: null,
      toDate: null,
      datePlaceholder: 'All Dates',
    });

    // computed
    const fromDateFormatted = computed(
      () => (data.fromDate ? moment(data.fromDate).format(data.dateFormat) : ''),
    );
    const toDateFormatted = computed(
      () => (data.toDate ? moment(data.toDate).format(data.dateFormat) : ''),
    );
    const applyDisabled = computed(() => !(data.fromDate || data.toDate));
    const fromDashBoard = computed(() => route.query.fromDashBoard === 'true');

    const getDatePlaceHolder = () => {
      if (data.fromDate || data.toDate) {
        const fromDate = (data.fromDate ? moment(data.fromDate).format(data.dateFormat) : '');
        const toDate = (data.toDate ? moment(data.toDate).format(data.dateFormat) : '');
        data.datePlaceholder = `${fromDate} ${toDate}`;
      } else {
        data.datePlaceholder = 'All Dates';
      }
    };

    // methods
    const apply = (canClose) => {
      if (canClose) data.isOpen = false;
      emit('filter', pick(data, ['fromDate', 'toDate']));
      getDatePlaceHolder();
    };

    const clear = (canClose) => {
      data.fromDate = null;
      data.toDate = null;
      if (canClose) apply(canClose);
    };
    onBeforeMount(() => {
      if (props.dateValues.startDate) {
        data.fromDate = props.dateValues.startDate;
      }
      if (props.dateValues.endDate) {
        data.toDate = props.dateValues.endDate;
      }
      getDatePlaceHolder();
    });

    const setDateOnShow = debounce((val) => {
      if (val) {
        if (props.dateValues?.startDate && !data.fromDate) {
          data.fromDate = props.dateValues.startDate;
        }
        if (props.dateValues?.endDate && !data.toDate) {
          data.toDate = props.dateValues.endDate;
        }
      }
    }, {
      leading: true,
      trailing: false,
    });

    return {
      ...toRefs(data),
      fromDateFormatted,
      toDateFormatted,
      applyDisabled,
      apply,
      clear,
      fromDatePicker,
      fromDashBoard,
      setDateOnShow,
    };
  },
};
</script>
<style scoped>
  /* .pr-2 {
    width: 100px;
  } */
  .card {
    width: 634px !important;
  }
  .card-content {
    height: 340px;
  }
</style>
