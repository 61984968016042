<template>
  <div>
    <transition name="slide">
      <div v-if="isActiveComponent" class="activity is-pulled-right">
        <aside>
          <div class="card">
            <header class="card-header">
              <label class="card-header-title has-text-black-bis">
                Documents({{ simpleFiles.length }})
              </label>
            <div>
              <mf-add-file
                :order="order"
                :projectId="projectId"
                :showForm="showForm"
                :defaultPrivate="isDefPrivate"
                @docs-added="docsAdded"
              >
              </mf-add-file>
            </div>
              <div class="is-divider-vertical"></div>
              <span
                class="card-header-icon"
                aria-label="more options"
                @click="closeItemDoc"
              >
                <i class="icon-close"></i>
              </span>
            </header>
            <div class="card-content">
              <div class="is-size-3 has-text-black-bis has-text-weight-bold mb-2 line-height">
                {{ itemName }}
              </div>
              <p class="control has-icons-left has-icons-right">
                <input type="text" v-model="searchText" class="input" placeholder="Search">
                <span class="icon is-left">
                  <i class="icon-search"></i>
                </span>
              </p>
              <file-list
                :files="filteredFileList"
                :closable="isClosable"
                :projectId="projectId"
                :order="order"
                @close="deleteFile"
                @update:viewForm="toggleFormView($event)"
              >
              </file-list>
              <form-preview
                v-if="viewForm"
                :viewForm="viewForm"
                :templateForm="templateForm"
                @update:viewForm="toggleFormView($event)"
                ></form-preview>
            </div>
          </div>
        </aside>
      </div>
    </transition>
  </div>
</template>
<script>
import {
  defineComponent, computed, reactive, toRefs,
} from 'vue';
import { some } from 'lodash';
import FileList from '@/components/card-edit/FileList.vue';
import MfAddFile from '@/components/MfAddFile.vue';
import FormPreview from '@/components/modals/FormPreview.vue';

export default defineComponent({
  name: 'ItemDocs',
  components: {
    'file-list': FileList,
    'mf-add-file': MfAddFile,
    'form-preview': FormPreview,
  },
  props: {
    isActive: Boolean,
    simpleFiles: Array,
    order: {
      type: Object,
      default: () => {},
    },
    projectId: String,
    __t: String,
    _customStage: String,
    isSL: Boolean,
    isBO: Boolean,
    isCL: Boolean,
    isManager: Boolean,
    type: String,
    itemName: String,
  },
  setup(props, { emit }) {
    const data = reactive({
      searchText: '',
      viewForm: false,
      templateForm: null,
    });

    const filteredFileList = computed(() => {
      const list = props.simpleFiles;
      return data.searchText === '' ? list : list.filter((el) => el?.name.toLowerCase().includes(data.searchText.toLowerCase()));
    });
    const isActiveComponent = computed({
      get: () => props.isActive,
      set: (val) => emit('update:isActive', val),
    });
    const isDefPrivate = computed(() => props.isManager);
    const closeItemDoc = () => {
      isActiveComponent.value = false;
    };
    const docsAdded = (docs) => {
      emit('update:docs', docs);
    };

    const deleteFile = async (fileToDelete) => {
      emit('remove:doc', fileToDelete);
    };

    const isClosable = (file) => {
      if (props.isSL) {
        return !file.master;
      } if (props.isBO && some(file.sources, { stage: 'planning' })) {
        return props._customStage === 'planning' || file.type === 'form';
      }
      return true;
    };

    const showForm = () => ['ProdTemplates', 'MatTemplates', 'Prefabs', 'Materials', 'ProductionOrder'].includes(props.__t);

    // if form viewed, send templateForm
    const toggleFormView = (eventObj) => {
      data.viewForm = eventObj.openForm;
      data.templateForm = eventObj.templateForm;
    };

    return {
      ...toRefs(data),
      isActiveComponent,
      closeItemDoc,
      docsAdded,
      deleteFile,
      isClosable,
      showForm,
      isDefPrivate,
      filteredFileList,
      toggleFormView,
    };
  },
});
</script>
