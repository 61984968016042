/* global AutodeskNamespace:true */
/* global Autodesk:true */
/* eslint no-undef: "error" */
import _ from 'lodash';
import Forge from '@/models/Forge';
import store from '../../../store';

let fvParams = {};

function MfactExtension(viewer, extOpt) {
  Autodesk.Viewing.Extension.call(this, viewer, extOpt);
  this.load = () => {
    console.log('Property window Ext');
    return true;
  };

  this.unload = () => {
    console.log('Property Ext unloaded');
    return true;
  };
  const onSelectionChanged = (event) => {
    const nodeId = event.dbIdArray[0];
    const currentModel = event.model;
    if (currentModel) {
      currentModel.getProperties(nodeId, (result) => {
        // REvit
        let itemId = _.get(_.find(result.properties, (pr) => pr.attributeName == 'MFact_ItemID' && !_.isEmpty(pr.displayValue)), 'displayValue', '');
        if (itemId === '') {
          // NavisWorks
          itemId = _.get(_.find(result.properties, (pr) => pr.displayName == 'MFact_ItemID' && !_.isEmpty(pr.displayValue)), 'displayValue', '');
        }
        if (itemId === '') {
          const parent = _.get(_.find(result.properties, { displayName: 'parent' }), 'displayValue', '');
          _.forEach(Autodesk.Viewing.Viewer3D.prototype.dbidsMap, (dbids, tmpId) => {
            if (_.indexOf(dbids, parent) !== -1) {
              itemId = tmpId;
            }
          });
        }
        // guid based search is our first PoC
        // for reference
        // https://github.com/manufacton/Amazon/pull/5289/files#diff-3cf0548c265859424908a2f5f9bf52a1L382
        // https://github.com/manufacton/Amazon/pull/5289/files#diff-3cf0548c265859424908a2f5f9bf52a1L449
        viewer.setPropertyPanel(null);
        if (itemId !== '') {
          const panel = Autodesk.ADN.Viewing.Extension.MetaPropertyPanel(viewer, itemId);
          viewer.setPropertyPanel(panel);
        }
      });
    }
  };
  // Hook on element selection
  // Step 1
  viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionChanged);

  // MetaPropertyPanel
  // Overrides native viewer property panel
  Autodesk.ADN.Viewing.Extension.MetaPropertyPanel = (viewerPanel, itemId) => {
    const _panel = this;
    Autodesk.Viewing.Extensions.ViewerPropertyPanel.call(_panel, viewerPanel);

    // Adds new meta property to panel
    _panel.addMetaProperty = (metaProperty, options) => {
      const element = this.tree.getElementForNode({
        name: metaProperty.name,
        value: metaProperty.value,
        category: metaProperty.category,
      });
      if (element) {
        return false;
      }

      let parent = null;
      if (metaProperty.category) {
        parent = this.tree.getElementForNode({ name: metaProperty.category });
        if (!parent) {
          parent = this.tree.createElement_(
            {
              name: metaProperty.category,
              type: 'category',
            },
            this.tree.myRootContainer,
            options && options.localizeCategory ? { localize: true } : null,
          );
        }
      } else {
        parent = this.tree.myRootContainer;
      }
      this.tree.createElement_(
        metaProperty,
        parent,
        options && options.localizeProperty ? { localize: true } : null,
      );

      return true;
    };

    // Hook for info button click, setAggregatedProperties override
    // Step 2
    _panel.setAggregatedProperties = async () => {
      // Add some default meta properties to the panel
      let itemProps = {};
      const company = _.get(store.state.userData, 'company', '');
      try {
        if (company === '') {
          throw new Error('Company Cannot be Empty');
        }
        itemProps = await Forge.getLmvProperties({
          itemId,
          projectId: fvParams.projectId,
          company,
        });
      } catch (e) {
        _panel.addMetaProperty({
          name: 'Server error',
          category: 'Manufacton',
          value: `Please Contact Manufacton Support. ${e}`,
        });
      }
      itemProps.propertyArray.forEach((property) => {
        _panel.addMetaProperty(property);
      });
      Autodesk.Viewing.Extensions.ViewerPropertyPanel.prototype.resizeToContent.call(_panel);
    };

    // Creates a text property
    function createTextProperty(property, parent) {
      const value = document.createElement('div');
      value.textContent = property.value;
      value.title = property.value;
      value.className = 'property-value';
      parent.appendChild(value);
      return value;
    }

    // Creates a link property
    function createLinkProperty(property, parent) {
      const value = document.createElement('div');
      const link = document.createElement('a');
      const text = document.createTextNode(property.value);
      link.setAttribute('href', property.href);
      link.appendChild(text);
      value.className = 'property-value';
      value.appendChild(link);
      parent.appendChild(value);
      return value;
    }

    // Hook on info panel render, displayProperty override
    _panel.displayProperty = (property, parent, displayOpt) => {
      const name = document.createElement('div');
      let text = property.name;
      if (displayOpt && displayOpt.localize) {
        name.setAttribute('data-i18n', text);
        text = Autodesk.Viewing.i18n.translate(text);
      }
      name.textContent = text;
      name.title = text;
      name.className = 'property-name';

      const separator = document.createElement('div');
      separator.className = 'separator';

      parent.appendChild(name);
      parent.appendChild(separator);

      let value = null;
      // native properties dont have a dataType
      // display them just as text
      if (!property.dataType) {
        value = createTextProperty(property, parent);
        return [name, value];
      }
      switch (property.dataType) {
        case 'text':
          value = createTextProperty(property, parent);
          break;
        case 'link':
          value = createLinkProperty(property, parent);
          break;
        // case 'img':
        //   value = createImageProperty(property, parent);
        //   break;
        // case 'file':
        //   value = createFileProperty(property, parent);
        //   break;
        default:
          break;
      }
      // Make the property name and value highlightable.
      return [name, value];
    };

    // Download util
    function downloadURI(uri, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = uri;
      link.click();
    }

    // onPropertyClick handle
    _panel.onPropertyClick = (property) => {
      if (!property.dataType) { return; }
      switch (property.dataType) {
        case 'text':
          // nothing to do for text
          break;
        // opens link in new tab
        case 'link':
          window.open(property.href, '_blank');
          break;
        // download image or file
        case 'img':
        case 'file':
          downloadURI(property.href, property.filename);
          break;
        default:
          break;
      }
    };
    return _panel;
  };
}

export default {
  init(params) {
    fvParams = params;
    // Hook on model element selection,
    // uses the MetaProperties extension to load the latest item info from SCM
    AutodeskNamespace('Autodesk.ADN.Viewing.Extension');
    MfactExtension.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
    MfactExtension.prototype.constructor = MfactExtension;
    const Ext = Autodesk.ADN.Viewing.Extension;
    Ext.MetaPropertyPanel = MfactExtension;
    Ext.MetaPropertyPanel.prototype = Object.create(Autodesk.Viewing.Extensions.ViewerPropertyPanel.prototype);
    Ext.MetaPropertyPanel.prototype.constructor = Ext.MetaPropertyPanel;
    const extName = 'Autodesk.ADN.Viewing.Extension.MetaPropertyPanel';
    Autodesk.Viewing.theExtensionManager.registerExtension(extName, Ext.MetaPropertyPanel);
  },
  self: MfactExtension,
};
