import { reactive } from 'vue';
import store from '@/store';
import { isEmpty, debounce } from 'lodash';

export default function (params) {
  const state = reactive({
    numRowsToBottom: 2,
    rowElemHeight: 0,
    pageOffset: 0,
  });
  const setRowElemHeight = () => {
    if (params.root.value) {
      const rowElem = params.root.value.getElementsByTagName('tr')[0];
      state.rowElemHeight = rowElem.clientHeight;
    }
  };

  const noMoreData = () => !!(params.total === params.tableData.length);

  const isDownScroll = (scrollTop) => {
    const result = !!(state.pageOffset - scrollTop < 0);
    state.pageOffset = scrollTop;
    return result;
  };

  const handleInfiniteScroll = debounce(async ({ target }, scrollParams) => {
    const {
      clientHeight,
      scrollTop,
      scrollHeight,
    } = target;

    if (!scrollParams || isEmpty(scrollParams)) {
      scrollParams = params;
    }
    params.total = scrollParams.total;
    if (noMoreData() || !isDownScroll(scrollTop)) return;
    if (!state.rowElemHeight) setRowElemHeight();
    if (clientHeight + scrollTop > scrollHeight - (state.numRowsToBottom * state.rowElemHeight)) {
      if (!scrollParams.isLoading) {
        scrollParams.page += 1;
        await params.dataManager(scrollParams.page, true);
      }
    }
  }, 500);
  return {
    setRowElemHeight,
    handleInfiniteScroll,
  };
}
