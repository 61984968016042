<template>
  <h2>{{msg}}</h2>
</template>

<script>
import {
  defineComponent, onMounted, ref,
} from 'vue';
import Oauth from '@/models/Procore';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ProcoreAuth',
  props: {
    oauth: {
      type: String,
      default: 'authorize', // callback
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const msg = ref('Preparing...');
    const getAuth = async () => {
      if (props.oauth === 'authorize') {
        const auth = await Oauth.getAccessToken();
        if (auth.client_id) {
          msg.value = 'Redirecting to Procore...';
          window.location = auth.oauth_url;
        }
      } else if (props.oauth === 'callback') {
        const { code } = route.query;
        try {
          const auth = await Oauth.setAccessToken({ code });
          if (auth.access_token !== '') {
            toast.success('Procore link enabled');
          } else {
            toast.error('Error on Procore Auth');
          }
          if (store.getters.hasCompanyPermissionForModule('app&projects:projects')) {
            router.push({ name: 'projects' });
          } else {
            router.push({ name: 'user settings' });
          }
        } catch (e) {
          toast.open('Error on Procore Auth');
          router.push({ name: 'user settings' });
        }
      }
    };
    onMounted(() => {
      getAuth();
    });
    return {
      msg,
      getAuth,
    };
  },
});
</script>
