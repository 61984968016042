<template>
  <h2>{{ msg }}</h2>
</template>

<script>
import {
  defineComponent, onMounted, ref,
} from 'vue';
import OAuth from '@/models/Forge';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'ForgeAuth',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const toast = useToast();
    const msg = ref('Preparing...');
    const getAuth = async () => {
      if (route.params.oauth === 'authorize') {
        const forgeAuth = await OAuth.getAccessToken();
        if (forgeAuth.client_id) {
          msg.value = 'Redirecting to Autodesk...';
          window.location = forgeAuth.oauth_url;
        }
      } else if (route.params.oauth === 'callback') {
        const { code } = route.query;
        try {
          const auth = await OAuth.setAccessToken({ code });
          if (auth.access_token !== '') {
            toast.success('Model view enabled');
          } else {
            toast.error('Error on BIM Auth');
          }
          if (store.state.forgeRedirectUrl) {
            window.location = store.state.forgeRedirectUrl;
          } else router.push({ name: 'projects' });
          store.commit('setForgeRedirectUrl', '');
        } catch (e) {
          router.push({ name: 'user settings' });
        }
      }
    };
    onMounted(() => {
      getAuth();
    });
    return {
      msg,
      getAuth,
    };
  },
});
</script>
