<template>
  <div>
    <slot name="activity-icon"></slot>
    <transition name="slide">
      <div v-if="isActiveComponent" class="activity is-pulled-right">
        <aside>
          <div class="card">
            <header class="card-header has-background-grey-lighter">
              <p
                class="card-header-title has-text-black-bis"
                v-if="projectName"
              >
                History - {{ projectName }}
              </p>
              <label class="card-header-title has-text-black-bis" v-else
                >History</label
              >
              <div class="is-divider-vertical"></div>
              <span
                class="card-header-icon"
                aria-label="more options"
                @click="closeActivity"
              >
                <i class="icon-close"></i>
              </span>
            </header>

            <div class="card-content">
              <p class="is-size-3 mb-2 has-text-black-bis has-text-weight-semibold
                text-overflow line-height">
                {{ orderName }}
              </p>
              <p class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="text"
                  placeholder="Search"
                  v-model="search"
                  @input="loadData"
                />
                <span class="icon is-small is-left">
                  <i class="icon-search"></i>
                </span>
                <span class="icon is-small is-right" v-if="search.length > 0"
                  @click="clearSearch">
                  <i class="icon-close is-clickable"></i>
                </span>
              </p>
            </div>
            <div class="card-content">
              <!-- <div class="activity-tabs">
                <button
                  class="button"
                  :class="
                    isWeeksActivity
                      ? 'is-selected-background'
                      : 'has-background-black-bis'
                  "
                  @click="getActivityData(true)"
                >
                  Weeks Activity
                </button>
                <button
                  class="button"
                  :class="
                    isWeeksActivity
                      ? 'has-background-black-bis'
                      : 'is-selected-background'
                  "
                  @click="getActivityData(false)"
                >
                  All Time
                </button>
              </div> -->
              <div class="sub-content">
                <div
                  v-for="(activity, index) in display.activities"
                  :key="index"
                >
                  <activity-statement class="activity-content"
                    v-if="getActivity(activity)"
                    :statements="activity"
                    :idx="index"
                    :showFirst="!index"
                  ></activity-statement>
                  <span v-if="showEmptyMsg"
                    >No Activity to display!
                    <!-- <b-message type="is-info" icon-size="is-small"-->
                    <!-- has-icon>No Activity to display!</b-message>-->
                  </span>
                </div>
                <div class="button" @click="loadMore()" v-if="hideShowMore">
                    Show more
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  defineComponent, reactive, computed, toRefs, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import Activity from '@/models/Activity';
import ActivityStatement from '@/views/ActivityStatement.vue';
import screens from '@/constants';

export default defineComponent({
  name: 'ActivityLog',
  components: {
    'activity-statement': ActivityStatement,
  },
  props: [
    'id',
    'projectId',
    'projectName',
    'isManager',
    'topLevelActivity',
    'name',
    'partialShipment',
    'type',
    'isTemplate',
    'isActive',
    'orderName',
    'leastStage',
    'stage',
    // 'atype',
    // 'locationId',
  ],
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      // isActiveComponent : props.isActive,
      display: {
        activities: [],
      },
      isCloseActivity: false,
      showEmptyMsg: false,
      page: 1,
      limit: 10,
      hideShowMore: false,
      search: '',
      company: '',
      isLoadingVisible: false,
      userId: '',
      commonStockProject: {},
      isWeeksActivity: false,
    });
    const projectActivityType = computed(() => store.state.projectActivityType);
    const module = computed(() => {
      if (store.state.activeScreen === 'untitled') {
        return ['Materials','Sourcing'].includes(props.type) ? 'MaterialManager' : 'ProductionOrder';
      }
      if (['detailing', 'qa', 'manufacturing',
        'kit-view-edit', 'po-edit'].includes(store.state.activeScreen)) {
        return 'ProductionOrder';
      }
      if (props.partialShipment) {
        return '';
        // return screens['shipping-order-view'].activityModule;
      }
      if (store.state.activeScreen === 'formBuilder-template-edit') {
        return 'FormBuilder';
      }

      if ((props.stage === 'mixed' && props.leastStage === 'planning'
      && store.state.activeScreen === 'production-status-order-view')
      || (props.stage === 'planning'
      && store.state.activeScreen === 'production-status-order-view')) {
        return 'PrefabPackage';
      }

      if (store.state.activeScreen === 'production-status-order-view') {
        return 'ProductionOrder';
      }

      if (store.state.activeScreen === 'material-status-order-view') {
        return 'MaterialManager';
      }
      if (store.state.activeScreen === 'inventory') {
        return 'InventoryManager';
      }
      if (props.isTemplate) {
        return props.isManager ? 'ProductionTemplate' : 'MaterialTemplate';
      }
      return screens[store.state.activeScreen].activityModule || '';
    });
    const methods = {
      getActivity(activity) {
        return (
          _.get(activity, 'messages.length', null)
          || _.get(activity, 'dateMessages.length', null)
        );
      },
      loadMore() {
        state.page += 1;
        methods.loadData();
      },
      async loadData() {
        state.hideShowMore = false;
        // state.showLoading();
        const response = state.isWeeksActivity
          ? await Activity.getWeeksActivity(methods.getParams())
          : await Activity.getActivity(methods.getParams());
        methods.hideLoading();
        if (response.data.totalCount) {
          state.showEmptyMsg = false;
          if (state.page === 1) {
            state.display.activities = response.data.data;
          } else {
            state.display.activities.push(...response.data.data);
          }
          state.hideShowMore = response.data.totalCount > state.display.activities.length;
        } else {
          state.hideShowMore = false;
          state.showEmptyMsg = true;
          state.display.activities = [];
        }
        return state.display.activities;
      },
      getParams() {
        const params = {};
        params.id = props.id;
        params.module = module.value;
        params.projectId = props.projectId;
        params.fromActivity = true;
        params.company = store.state.userData.company;
        params.page = state.page;
        params.limit = 20;
        params.search = state.search;
        return params;
      },
      // getParams() {
      //     const params = {};
      //     if (state.id) {
      //         params.id = state.id;
      //     }
      //     if (state.module) {
      //         if (state.topLevelActivity) {
      //             params[_.toLower('topLevelActivity')] = state.topLevelActivity;
      //             params.company = state.company;
      //         } else {
      //             params.module = state.module;
      //         }
      //     }
      //     if (state.projectId) {
      //         params.projectId = state.projectId;
      //     }
      //     // here PO we are giving isManager true to get only PO activities.
      //     if (props.isManager) {
      //         params.isManager = props.isManager;
      //     }
      //     if (state.search) {
      //         params.search = state.search;
      //     }
      //     if (
      //         ['projects', 'members-list', 'company-profile',
      //   'formBuilder-template-edit', 'manufacton-settings'].includes(store.state.activeScreen) &&
      //         !state.topLevelActivity
      //     ) {
      //  params.id = store.state.activeScreen === 'formBuilder-template-edit'
      // ? state.id : state.company;
      //         params.company = state.company;
      //     }
      //     if (store.state.activeScreen === 'inventory') {
      //         params.projectId = state.selectedIdsForKey('projectsAndGI');
      //         if (!_.isEmpty(state.commonStockProject)) {
      //             params.projectId.push(state.commonStockProject._id);
      //         }
      //     }
      //     if (store.state.activeScreen === 'companies') {
      //         params.id = state.company;
      //     }
      //     if (store.state.activeScreen === 'view-project') {
      //         params.id = store.state.queryParams.projectId;
      //         params.projectId = store.state.queryParams.projectId;
      //         params.company = state.company;
      //         if (state.projectActivityType) params.type = state.projectActivityType;
      //     }
      //     if (store.state.activeScreen.startsWith('account')) {
      //         if (store.state.userData.viewOnlyMember) {
      //             params.id = state.userId;
      //             params.user = state.userId;
      //             params.type = 'accountSetting';
      //         } else {
      //             params.id = state.userId;
      //             params.user = state.userId;
      //             params.type = 'accountSetting';
      //             params.company = state.company;
      //         }
      //     }
      //     if (store.state.activeScreen === 'transfer-request') {
      //         params.id = state.id;
      //         params.company = state.company;
      //     }
      //     params.fromActivity = true;
      //     params.page = state.page;
      //     params.limit = state.limit;
      //     return params;
      // },
      showActivity() {
        state.isActive = true;
        methods.loadData();
        store.dispatch('showActivityLog');
      },
      showLoading() {
        state.isLoadingVisible = true;
      },
      hideLoading() {
        state.isLoadingVisible = false;
      },
      // refresh: _.debounce(function refresh() {
      //     this.display.activities = [];
      //     this.page = 1;
      //     this.loadData();
      // }, 500),
      getActivityData(isWeeksActivity) {
        state.isWeeksActivity = isWeeksActivity;
        methods.loadData();
      },
    };
    const isActiveComponent = computed({
      get: () => {
        if (props.isActive) {
          methods.loadData();
        }
        return props.isActive;
      },
      set: (val) => emit('update:isActive', val),
    });
    const closeActivity = () => {
      isActiveComponent.value = false;
      // state.isActiveComponent = false;
      // props.isActive = false;
      // state.hideShowMore = false;
      // state.page = 1;
      // state.display.activities = [];
      // state.search = '';
      // store.dispatch('hideActivityLog');
    };

    const clearSearch = () => {
      state.search = '';
      methods.getActivityData(state.isWeeksActivity);
    };

    return {
      ...toRefs(state),
      ...methods,
      isActiveComponent,
      closeActivity,
      projectActivityType,
      clearSearch,
    };
  },
});
</script>
