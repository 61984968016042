<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        Finish Combining Orders
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="cancel()"></i>
    </header>
    <section class="modal-card-body is-size-4">
        <o-loading :full-page="false" :active="isLoading" :can-cancel="false"></o-loading>
        <div class="field">
          <div class="field-label">
            <label class="label">Select Order Data</label>
          </div>
          <div class="field-body">
            <div class="line-height has-text-black-bis has-text-weight-normal mb-1">
              Use which Order's data in the combined Order?
            </div>
            <div v-for="card in cards" :key="card._id" class="line-height has-text-black-bis">
             <input type="radio" :id="card._id"
                 :value="card._id" v-model="sourceOrderId"
                 @click="sourceOrderId = card._id"
                 :checked="sourceOrderId === card._id"
              />
              <label :for="card._id" class="has-text-black-bis">{{card.name}}</label>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="field-label">
            <label class="label is-italic">
              Name the Combined Order
            </label>
          </div>
          <div class="field-body">
            <textarea class="textarea" rows="3" v-model="combinedOrderName"></textarea>
          </div>
        </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined"
        @click="cancel()">Cancel</button>
      <button class="button has-background-black-bis"
        @click="combine()"
        :disabled="!sourceOrderId">Finish Combine</button>
    </footer>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue';
import { useToast } from 'vue-toastification';
import {
  forEach, find, assign, get,
} from 'lodash';
import Material from '@/models/MaterialManager';
import Order from '@/models/Orders';
import OrderManager from '@/models/ProductionManager';
import { DialogProgrammatic } from '@/components/Dialog';

export default {
  props: {
    cards: Array,
  },
  setup({ cards }, { emit }) {
    const toast = useToast();
    const state = reactive({
      isLoading: false,
      combinedOrderName: `COMBINED - ${cards[0].name} + ${cards[1].name}`,
      sourceOrderId: null,
    });

    const combine = async () => {
      const orderName = state.combinedOrderName.trim();
      if (orderName.length < 3) {
        toast.warning('Card name must contain at least 3 characters!');
        return;
      }
      const params = {
        sourceId: cards[0]._id === state.sourceOrderId ? cards[1]._id : cards[0]._id,
        destId: state.sourceOrderId,
      };
      state.isLoading = true;
      if (cards[0].__t === 'Sourcing') {
        const combineObj = assign(params, { newMaterialName: orderName });
        await Material.combineOrders(combineObj).catch((err) => {
          const confirmParam = {
            title: 'Error : Combined Order could not be created.',
            message: err.data,
            okButton: 'Ok',
            type: 'danger',
            onConfirm: () => {
              emit('refresh-table');
              emit('close');
            },
          };
          DialogProgrammatic.confirm(confirmParam);
        });
        forEach(cards[0].items, (item) => {
          const secItem = find(cards[1].items, (dt) => dt.catId === item.catId);
          if (secItem && item.costCode !== secItem.costCode) {
            toast.warning('Item cost code conflicts were resolved by using the first Order`s values.');
          }
        });
      } else if (!cards[0].isManager) {
        const combineObj = assign(params, { name: orderName, projectId: cards[1].project._id });
        await Order.combine(combineObj);
      } else {
        const combineObj = assign(params, {
          name: orderName,
          projectId: cards[1].project._id,
          stage: 'detailing',
        });
        await OrderManager.combine(combineObj);
      }
      state.isLoading = false;
      emit('refresh-table');
      emit('close');
    };
    const cancel = () => {
      emit('cancel');
      emit('close');
    };

    return {
      ...toRefs(state),
      combine,
      cancel,
    };
  },
};
</script>
<style scoped>
.modal-card-body {
    height: 184px;
  }
</style>
