<template>
  <div class="is-relative docs-count is-clickable" @click="showFilesList(true)">
    <span>{{ getFiles.length }}</span>
    <i class="icon-attacheddocumentsOL is-size-3"></i>
  </div>
  <item-document-list
    :isActive="isFileListVisible"
    :simpleFiles="getFiles"
    :order="order"
    :projectId="getProjectId"
    :__t="__t"
    :_customStage="_customStage"
    :isSL="isSL"
    :isBO="isBO"
    :isCL="isCL"
    :type="rowField.for"
    :itemName="getItemName()"
    @update:isActive="showFilesList"
    @update:docs="docsAdded"
    @remove:doc="deleteFile"
  ></item-document-list>
</template>
<script>
import { reactive, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import {
  get, cloneDeep, pick, isEmpty, find, filter, findIndex,
} from 'lodash';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import ItemDocumentList from '@/components/card-edit/ItemDocumentList.vue';
import { DialogProgrammatic } from '@/components/Dialog';

export default {
  name: 'ItemDocumentField',
  props: {
    rowData: Object,
    rowField: Object,
    value: Array,
    key: String,
  },
  components: {
    'item-document-list': ItemDocumentList,
  },
  setup(props, { emit }) {
    const store = useStore();
    const data = reactive({
      fileListVisibility: false,
      isBO: props.rowData.instanceOf === 'BaseOrder',
      isSL: props.rowData.instanceOf === 'ShippingLabel',
      isCL: props.rowData.instanceOf === 'CheckList',
      __t: get(props.rowData, '__t', props.rowData?.order?.__t),
      _customStage: get(props.rowData, '_customStage', props.rowData?.order?.__t),
      order: props.rowData.order || props.rowData,
    });
    const getFiles = computed(() => (props.rowData[props.rowField.id]
      ? props.rowData[props.rowField.id].filter((file) => file.archived.value === false)
      : []));
    const getProjectId = computed(() => {
      if (data.isBO) {
        return get(props, 'order.project._id', '');
      }
      if (data.isSL) {
        return get(props, 'delivery.currentProject._id', '');
      }
      return get(props.rowData, 'project._id', props.rowData?.order?.project?._id);
    });
    const isFileListVisible = computed({
      get: () => data.fileListVisibility,
      set: (visibility) => {
        data.fileListVisibility = visibility;
      },
    });

    const getItemName = () => props.rowData?.name;

    const methods = {
      showFilesList(visibility) {
        isFileListVisible.value = visibility;
      },
      docsAdded(docs) {
        for (const doc of docs) {
          if (data.isBO) {
            const file = props.rowData.newFile();
            const docCopy = cloneDeep(doc);
            delete doc.formData;
            Object.assign(file, doc);
            if (doc.type === 'form' && props.rowData.addCheckListToForm) {
              props.rowData.addCheckListToForm(
                { doc: docCopy, rowData: props.rowData },
                store.state.userData,
              );
            }
          } else if (data.isSL) {
            const file = pick(doc, [
              'name',
              'type',
              'visible',
              'url',
              'uuid',
              'copiedFrom',
              'formData',
            ]);
            file.sources = [
              {
                type: 'shipping',
              },
            ];
            file.archived = {
              value: false,
            };
            if (props.rowData && props.rowData.delivery) {
              if (isEmpty(props.rowData.files)) {
                // eslint-disable-next-line vue/no-mutating-props
                props.rowData.files = [file];
              } else {
                // eslint-disable-next-line vue/no-mutating-props
                props.rowData.files.push(file);
              }
            }
          } else if (data.isCL) {
            emit('add:todoDocs', doc);
          }
        }
      },

      async deleteFile(fileToDelete) {
        // TODO: should add dialog for file removing.
        const confirmParam = {
          title: 'DELETE FILE',
          message: 'Are you sure you want to delete this file? This cannot be undone.',
          okButton: 'Delete',
          cancelButton: 'Cancel',
          onConfirm: () => {
            if (data.isBO) {
              const removingFile = find(props.rowData.simpleFiles, (file) => {
                if (!fileToDelete.uuid) return file._id === fileToDelete._id;
                return file.uuid === fileToDelete.uuid;
              });
              removingFile.archived.value = true;
              if (removingFile.type === 'form') {
                props.rowData.deleteForm({ doc: removingFile, rowData: props.rowData });
              }
            }
            if (data.isSL) {
              if (props.rowData && props.rowData.delivery) {
                const removingFile = find(props.rowData.files, (file) => {
                  if (!file.uuid) return file._id === fileToDelete._id;
                  return file.uuid === fileToDelete.uuid;
                });
                if (removingFile.uuid) {
                  // eslint-disable-next-line vue/no-mutating-props
                  props.rowData.files = filter(props.rowData.files, findIndex(props.rowData.files, {
                    uuid: fileToDelete.uuid,
                  }));
                } else {
                  removingFile.archived.value = true;
                }
              }
            }
            if (data.isCL) {
              emit('remove:todoDocs', fileToDelete);
            }
            CardDirtyBus.emit('setCustomDirty');
          },
        };
        DialogProgrammatic.confirm(confirmParam);
      },

      showForm() {

      },

    };
    return {
      ...toRefs(data),
      ...methods,
      isFileListVisible,
      getFiles,
      getProjectId,
      getItemName,
    };
  },
};
</script>
