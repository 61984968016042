export default {
  PROJECT: {
    field: 'project',
  },
  'KIT ID': {
    field: 'customId',
    multiple: false,
    required: true,
  },
  'CATALOG ID': {
    field: 'catId',
    multiple: false,
    required: true,
  },
  QUANTITY: {
    field: 'quantity',
    multiple: false,
  },
  LEVEL: {
    field: 'level',
    multiple: true,
    displayType: 'stringedOptions',
  },
  ZONE: {
    field: 'zone',
    multiple: true,
    displayType: 'stringedOptions',
  },
  'DOCUMENT NAMES': {
    field: 'docNames',
    multiple: false,
  },
  DOCUMENTS: {
    field: 'documents',
    multiple: false,
  },
  NOTES: {
    field: 'note',
    multiple: false,
  },
};
