<template>
  <div>
    <div v-if="isHeader">
      <mf-multi-select
        :options="$_.sortBy(options, 'name')"
        :multiple="false"
        :closeOnSelect="true"
        label='name'
        trackBy='val'
        v-model="selected"
        :allow-empty="false"
        :preselect-first="true"
        openDirection="below"
        :noDirtyEmit="true"
        @update:modelValue="onInput($event)"
      >
      </mf-multi-select>
    </div>
    <div v-else-if="!isHeader">
      <template v-if="!isPtrackEnabled">
        <generic-field v-bind="allProps"></generic-field>
      </template>
      <template v-else>
        <div class="order-pt-line">
          <mf-multi-select :options="unUsedCompanyRuns"
                        :modelValue="[{
                        copiedFrom: rowData.copiedFromId,
                        name: rowData.name
                        }]"
                        :disabled="disableRunSelect"
                        @select="selectPreRun(rowData, $event)"
                        :multiselect="false"
                        track-by="copiedFrom" label="name"
                        openDirection="below"
                        class="pendo-run-select"
                        @dropdown:active="setDropdownStatus">
          </mf-multi-select>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  defineComponent, onMounted, reactive, toRefs, computed,
} from 'vue';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import { useStore } from 'vuex';
import GenericField from '@/components/fields/GenericField.vue';
import Company from '@/models/Companies';
import CardEditMixin from '../mixins/CardEditMixin';
import {
  differenceBy, filter, get, set, debounce,
} from 'lodash';
import translator from '@/languages/translator';

export default defineComponent({
  name: 'RunFilterField',
  components: {
    'mf-multi-select': MfMultiSelect,
    GenericField,
  },
  props: {
    isHeader: Boolean,
    rowData: Object,
  },
  setup(props, { emit }) {
    const { rowData } = props;
    const { t } = translator;
    const store = useStore();
    const { disableStatus } = CardEditMixin();
    const state = reactive({
      options: [
        { name: t('All Work Steps'), val: 'all' }, // update the name in pm-edit.vue as well
        { name: t('All Pending Work Steps'), val: 'all-pending' },
        { name: t('My Work Steps'), val: 'my-runs' },
        { name: t('My Pending Work Steps'), val: 'my-pending' },
      ],
      selected: store.state.runFilter,
      disableRunSelect: ['qa', 'delivery', 'complete'],
      companyRuns: [],
    });
    const methods = {
      onSelect(selectedOption) {
        emit('cell-clicked', {
          selectedOption, type: 'run-filter',
        });
      },
      onInput(event) {
        state.selected = event;
        store.commit('setRunFilter', event);
        methods.onSelect(event);
      },
      selectPreRun(newRun, preRun) {
        newRun.copiedFromId = preRun.copiedFrom;
        newRun.name = preRun.name;
      },
    };
    onMounted(async () => {
      await store.dispatch('getCompanyRuns');
      state.companyRuns = store.state.companyRuns;
      if (store.state.activeScreen === 'manager-edit-detailing') {
        state.options = [
          { name: t('All Work Steps'), val: 'all' },
          { name: t('My Work Steps'), val: 'my-runs' },
        ];
      }
    });
    const filteredRuns = computed(() => rowData ? filter(rowData.order.manager.runs,
      (r) => !r.archived.value) : []);
    const isPtrackEnabled = computed(() => get(props, 'order.manager.pTrackEnabled', true));
    const unUsedCompanyRuns = computed(() => {
      state.companyRuns.forEach((r) => { r.copiedFrom = r.copiedFrom || r._id; });
      const curRuns = filteredRuns.value;
      return differenceBy(
        state.companyRuns, curRuns,
        (r) => r.name || r.copiedFromId || r.copiedFrom,
      );
    });
    const disableRunSelect = computed(() => {
      if (rowData && ['qa', 'delivery', 'complete'].includes(rowData.order._customStage)) return true;
      if (rowData && rowData?.stats?.actualHrs) return true;
      return disableStatus(rowData.order.status) ? true : false;
    });
    const setDropdownStatus = debounce((val) => {
      set(rowData, 'dropdownActive', val);
    }, 100, { leading: true, trailing: false });
    return {
      ...toRefs(state),
      ...methods,
      unUsedCompanyRuns,
      isPtrackEnabled,
      disableRunSelect,
      setDropdownStatus,
    };
  },
});
</script>
