<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
    <div class="list-view">
      <TableGutter
        :selectedAction="isBulkActionEnabled"
        :tools="tableProps.gutterOpts"
        @cc-modal-closed="triggerSetHeaderHeight"
        @re-render-table="rerenderMfTable += 1"
      >
      </TableGutter>
      <mf-table
        ref="trTable"
        :tableProps="tableProps"
        :loadData="loadData"
        :screenTable="true"
        :hideGutter="true"
        :apiMode="true"
        @cell-clicked="onCellClicked"
        tableClass="transfer-request-table"
        :key="rerenderMfTable"
      >
        <template v-slot:requestTypes="{ rowData }">
          <div>{{$_.capitalize(rowData.type)}}</div>
        </template>
        <template v-slot:taskId="{ rowData }">
          <a v-tooltip="rowData.taskId" class="has-text-link">
            {{ rowData.taskId }}
          </a>
        </template>
        <template v-slot:owner="{ rowData }">
          <span v-if="rowData.isEditing">
              <mf-multi-select v-model="rowData.assignee.user"
                          :options="allUsers"
                          :multiple="false"
                          :searchable="true"
                          label="name"
                          track-by="_id"
                          :allow-empty="false">
              </mf-multi-select>
            </span>
            <span v-else>
              {{$_.get(rowData,'assignee.user.name','')}}
            </span>
        </template>
        <template v-slot:createdAt="{ rowData }">
          <span class="is-pulled-right">
          {{$filters.usaDate(rowData.created.at)}}
          </span>
        </template>
        <template v-slot:sourceOrder="{ rowData }"
        >
          <a class="text-overflow line-height" @click="SourceCardOpen(rowData)"
           >
           <span v-tooltip="sourceOrderName(rowData).length > 80 ? sourceOrderName(rowData) : ''">
              {{sourceOrderName(rowData)}}
           </span>
          </a>
        </template>
      </mf-table>
      <activity-log
        :isActive="activityModalOpen"
        :id="activityDetails.id"
        :orderName="activityDetails.orderName"
        @update:isActive="activityModalOpen=false">
      </activity-log>
    </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, onMounted, computed,
} from 'vue';
import Tasks from '@/models/Task';
import Users from '@/models/Users';
import {
  map, isEmpty, pick, get,
} from 'lodash';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import TableGutter from '@/components/TableGutter.vue';
import transferRequestCols from '@/components/table-cols/transferRequestCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import ActivityLog from '@/components/ActivityLog.vue';

export default defineComponent({
  name: 'TransferRequest',
  components: {
    TableGutter,
    MfTable,
    MfMultiSelect,
    ActivityLog,
  },
  props: {},
  setup() {
    const trTable = ref(null);
    const isBulkActionEnabled = computed(() => trTable?.value?.selectedBulkAction);
    const store = useStore();
    const router = useRouter();
    const tableProps = transferRequestCols;
    const state = reactive({
      allUsers: [],
      isLoading: false,
      activityModalOpen: false,
      activityDetails: {
        id: '',
        orderName: '',
      },
      rerenderMfTable: 0,
    });
    onMounted(async () => {
      const params = {
        showUser: 'active',
        limit: 1000,
      };
      state.allUsers = (await Users.allUsers(params)).data;
    });
    const qp = computed(() => store.state.queryParams);
    const loadData = async (commonParam) => {
      const types = map(qp.value.requestTypes, 'value');
      // status is going empty so the backend sends only the not started and inprogress
      // transfer request. so if status is mepty then send all 3 status to backend.
      const status = !isEmpty(qp.value.taskStatus) ? map(qp.value.taskStatus, 'value')
        : ['complete', 'in-progress', 'not-started'];
      const assignee = map(qp.value.assignee, '_id');
      const params = {
        search: qp.value.searchText,
        ...store.getters.selectedDatesForKeys([
          'createdEndDate',
          'createdStartDate',
          'detailByEndDate',
          'detailByStartDate',
          'dueDateEndDate',
          'dueDateStartDate',
        ]),
        types,
        assignee,
        status,
        created: qp.value.created,
        ...commonParam,
      };
      const data = await Tasks.list(params);
      if (isEmpty(data)) {
        data.data = [];
      }
      return data;
    };
    const sourceOrderName = (rowData) => {
      if (rowData.type === 'pick') {
        return get(rowData, 'ProdOrders.name', '');
      }
      if (rowData.type === 'stock') {
        return get(rowData, 'ShippingOrders.name', '');
      }
      return '';
    };
    const updateRow = async (task) => {
      state.isLoading = true;
      try {
        const taskMeta = pick(task, ['taskId', 'dueDate', 'assignee']);
        await Tasks.update(task._id, taskMeta);
        trTable.value.refreshTable();
      } catch (error) {
        console.error(error);
      } finally {
        state.isLoading = false;
      }
    };
    const openRow = (data) => {
      router.push({
        name: `${store.state.activeScreen}-edit`,
        params: { requestId: data._id },
      });
    };

    const onCellClicked = async (event) => {
      const order = event.data;
      if (event.type === 'save') {
        await updateRow(order);
      } else if (event.type === 'closeRequest') {
        const confirmParam = {
          title: 'Close Request',
          message: 'This Transfer Request is not complete! Are you sure you want to close it?',
          okButton: 'Close Request',
          cancelButton: 'Cancel',
          type: 'danger',
          cancelText: 'Cancel',
          hasIcon: true,
          canCancel: ['button'],
          onConfirm: async () => {
            await Tasks.update(event.data._id, { status: 'complete' });
            trTable.value.refreshTable();
          },
        };
        DialogProgrammatic.confirm(confirmParam);
      } else if (event.type === 'activity') {
        state.activityModalOpen = true;
        state.activityDetails.id = event.data._id;
        state.activityDetails.orderName = event.data.taskId;
      } else if (event.type === 'openCard') {
        openRow(event.data);
      }
    };
    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      trTable?.value?.closeColumnChooserModal();
    };
    const getUrl = (name, params) => {
      router.push({
        name,
        params,
      });
    };
    const SourceCardOpen = (rowData) => {
      if (rowData.type === 'pick') {
        getUrl('manager-edit', {
          module: 'manager',
          projectId: get(rowData, 'ProdOrders.project._id', ''),
          cardId: get(rowData, 'ProdOrders._id', ''),
          stage: get(rowData, 'ProdOrders.stage', ''),
        });
      }
      if (rowData.type === 'stock') {
        getUrl('shipping-edit', {
          module: 'shipping',
          projectId: get(rowData, 'ShippingOrders.project._id', ''),
          cardId: get(rowData, 'ShippingOrders._id', ''),
        });
      }
    };
    return {
      ...toRefs(state),
      trTable,
      qp,
      tableProps,
      loadData,
      isBulkActionEnabled,
      onCellClicked,
      triggerSetHeaderHeight,
      sourceOrderName,
      SourceCardOpen,
    };
  },
});
</script>
