export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  customRowKey: 'kitId',
  fields: [{
    title: '',
    name: 'table-field-radio',
    width: '7%',
    prop: 'selected',
    id: 'selected',
  }, {
    name: 'field-generic-input',
    prop: 'name',
    width: '48%',
    title: 'Kit Name (in Prefab Package)',
    id: 'name',
  },
  {
    name: 'field-generic-input',
    prop: '_id.customId',
    width: '25%',
    title: 'Kit ID',
    id: '_id.customId',
  }, {
    name: 'field-generic-input',
    prop: 'assembliesCount',
    width: '35%',
    title: '# Assemblies',
    id: 'quantity',
  }, {
    name: 'field-generic-input',
    prop: 'bomsCount',
    width: '35%',
    title: 'BOMs',
    id: 'quantity',
  }, {
    name: 'field-date',
    prop: 'deliver',
    width: '30%',
    title: 'Earliest Order By',
    id: 'deliver',
    inputProps: {
      kind: 'deliver',
      disableEdit: true,
      hideCalendarIcon: true,
    },
  }],
};
