<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        Clone {{headerTitle}}
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="$emit('close')"></i>
    </header>
    <section class="modal-card-body is-size-4">
      <o-loading :full-page="false" :active="isLoading" :can-cancel="false"></o-loading>
      <div v-if="isSchedule" class="columns">
        <div v-for="field in fields" :key="field.model" class="column">
          <div class="field-label">
            <label class="label is-italic">{{field.label}}</label>
          </div>
          <div>
            <input v-if="field.isEdit" v-model.trim="cloneOpt[field.model]">
            <span v-else>{{ card[field.model] }}</span>
          </div>
        </div>
      </div>
      <div v-else class="field">
        <div class="field-label">
          <label class="label is-italic">New {{packageName? packageName: 'Order'}}  Name</label>
        </div>
        <div class="field-body">
          <input
            class="input"
            type="text"
            v-model.trim="cloneOpt.name"
            placeholder="Enter the name for the cloned order(s)"
            required />
        </div>
      </div>
      <div v-if="card.__t === 'Prefabs'" class="field">
        <div class="field-label">
          <label class="label is-italic is-size-3">Number Of Copies</label>
        </div>
        <div class="field-body">
          <input
            class="input binary-input is-pulled-left mr-2"
            type="number"
            v-model="copiesCount"
            @change="updateCopies"
            required />
          <span class="label is-italic has-text-grey has-text-weight-normal is-size-3">
            5 Maximum
          </span>
        </div>
      </div>
      <div>
        <div class="field">
          <div class="field-label">
            <label class="label is-italic is-size-3">Copy These While Cloning</label>
          </div>
          <div class="field-body check-box">
          <div v-for="(checkbox, $index) in alsoCopy" :key="$index" >
            <o-checkbox v-model="cloneOpt[checkbox.model]"
              class="is-paddingless has-text-black-bis is-size-3"
              @input="changeAlsoCopy(checkbox)"
              :disabled="disabled(checkbox)">
              {{checkbox.label}}
            </o-checkbox>
          </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined" @click="cancel()">Cancel</button>
      <button class="button has-background-black-bis"
              @click="confirmClone()"
              :disabled="errName">Clone</button>
    </footer>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import {
  trim, get, times, clone, pick, some,
} from 'lodash';
import MaterialManager from '@/models/MaterialManager';
import Prefab from '@/models/Prefabs';
import { BaseOrder } from '@/models/BaseOrder';
import Schedule from '@/models/Schedule';
// import Todo from '@/models/Todo';

export default defineComponent({
  name: 'CloneCard',
  props: {
    card: {
      type: Object,
    },
    headerTitle: {
      type: String,
    },
    packageName: {
      type: String,
    },
    copyOpts: {
      type: Array,
      default: () => [
        {
          model: 'todos',
          label: 'Order Checklist',
        },
        {
          model: 'documents',
          label: 'Order Documents',
        },
        {
          model: 'notes',
          label: 'Order Notes',
        },
        {
          model: 'productionOrders',
          label: 'Production Orders',
        },
        {
          model: 'productionRuns',
          label: 'Production Runs',
        },
      ],
    },
    fields: {
      type: Array,
      default: [],
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    const store = useStore();
    const state = reactive({
      isLoading: false,
      copiesCount: 1,
      alsoCopy: props.copyOpts,
      cloneOpt: {
        copies: 1,
        name: `${trim(get(props.card, 'name', ''))} - Clone(1)`,
        _id: get(props.card, '_id', ''),
        project: get(props.card, 'project', ''),
        submittal: false,
        productionOrders: true,
        productionRuns: true,
      },
      isSchedule: store.state.activeScreen === 'schedule',
    });

    if (props.card instanceof BaseOrder) {
      state.alsoCopy.push(
        {
          model: 'todos',
          label: 'Order Checklist',
        },
      );
    }
    const errName = computed(() => (trim(state.cloneOpt.name) === '' || (trim(state.cloneOpt.name)).length < 3));
    const cancel = () => {
      emit('close');
    };

    const confirmClone = async () => {
      if (state.cloneOpt.name.length >= 3) {
        state.isLoading = true;
        for (const idx of times(state.cloneOpt.copies)) {
          const param = clone(state.cloneOpt);
          // if (props.card.__t && ['MatTemplates', 'ProdTemplates'].includes(props.card.__t)) {
          //   for (const i of ['todos', 'documents', 'notes']) {
          //     param[i] = true;
          //   }
          // }
          param.name = state.cloneOpt.copies > 1 ? `${state.cloneOpt.name}(${idx + 1})`
            : state.cloneOpt.name;
          if (props.card.__t === 'Prefabs') {
            /* eslint no-await-in-loop: "off" */
            await Prefab.clone(param);
          }
          //    else if (this.card.cardType === 'templateForm') {
          //   // form cloning
          //   param.templateName = param.name;
          //   param.cardType = this.card.cardType;
          //   await Todo.updateFormTemplate(param, true);
          // } else if (this.card.__t === 'MatTemplates') {
          //   // Material template cloning
          //   await MaterialManager.clone(param);
          // } else if (this.card.__t === 'ProdTemplates') {
          //   // Material template cloning
          //   await ProdTemplates.clone(param);
          // }
        }
        if (['Materials', 'Sourcing'].indexOf(props.card.__t) > -1) {
          await MaterialManager.clone(state.cloneOpt);
        }
        if (state.isSchedule) {
          const orderParam = {
            _id: get(props.card, 'order._id', ''),
            name: `Clone of ${get(props.card, 'order.name', '')}`,
            project: get(props.card, 'project', ''),
            cloneSubmittal: state.cloneOpt.submittal,
          };
          const scheduleParam = {
            _id: props.card._id,
            activityName: `${props.card.activityName} ${state.cloneOpt.suffix || ''}`,
            project: get(props.card, 'project', ''),
            cloneSubmittal: state.cloneOpt.submittal,
          };
          // clone mm, get cloned id
          // clone sched, set orderId in backend, set cloned Sch parent, actual sch children ids
          // set fromSchedule true and schOrderId in cloned mm
          let clonedMM;
          if (state.cloneOpt.order) {
            clonedMM = await MaterialManager.clone(orderParam);
            scheduleParam.order = pick(clonedMM, ['_id', 'name']);
            scheduleParam.orderStage = clonedMM.stage;
          }
          if (state.cloneOpt.submittal) {
            scheduleParam.submittal = props.card.submittal;
          }
          await Schedule.clone(scheduleParam);
        }
        state.isLoading = false;
        cancel();
        emit('refresh-table');
      } else {
        state.isLoading = false;
        toast.error('Card name must contain at least 3 characters!');
      }
    };

    const updateCopies = () => {
      if (state.copiesCount < 1 || state.copiesCount > 5) {
        toast.error('Number of copies must be in range 1-5');
        state.copiesCount = state.cloneOpt.copies;
        return;
      }
      state.cloneOpt.copies = state.copiesCount;
    };

    const disabled = (checkbox) => {
      const { model } = checkbox;
      if (['documents', 'todos'].includes(model)) {
        const { simpleFiles } = props.card;
        if (some(simpleFiles, { type: 'form' })) {
          state.cloneOpt[model] = true;
          return true;
        }
      }
      return (!!checkbox.disabled);
    };

    return {
      ...toRefs(state),
      confirmClone,
      errName,
      cancel,
      updateCopies,
      disabled,
    };
  },
});
</script>
