import { map } from 'lodash';
import GenerateRequest from './resources';
import { BaseOrder } from './BaseOrder';

const req = GenerateRequest();

export default {
  async getAccessToken() {
    try {
      const { data: auth } = await req.get('procore/oauthToken', {});
      return auth;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async setAccessToken(params) {
    try {
      const { data: auth } = await req.get('/procore/callback', {
        params: { code: params.code },
      });
      return auth;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getCompanies() {
    try {
      const { data: companies } = await req.get('/procore/companies', {
        params: { },
      });
      return companies;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getProjects(params) {
    try {
      const { data: projects } = await req.get('/procore/projects', {
        params: { procoreCompanyId: params.companyId },
      });
      return projects;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async linkProject(params) {
    /**
     * {
        procoreCompanyId: '',
        procoreProjectId: '',
        projectId: '',
      }
     */
    try {
      const { data: project } = await req.post('procore/linkProCoreProject', params);
      return project;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      return {};
    }
  },
  async getListOfSubmittals(params) {
    try {
      const { data: submittals } = await req.get(`procore/submittals/${params.projectId}`);
      return submittals.mfData;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getProcoreToken(params) {
    try {
      const { data } = await req.get('procore/refreshToken', { params });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getSubmitalOrders(params) {
    try {
      const { data: submittalData } = await req.get('public/submittals', {
        params,
      });
      return { data: submittalData.map(procoreData => new BaseOrder(procoreData)) };
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
