import PermissionMixin from '@/components/mixins/PermissionMixin';

const { getPermissionValues } = PermissionMixin();
function setPermission(result, el, type) {
  const scmTypes = ['productionStatus', 'materialStatus', 'shippingStatus'];
  if (scmTypes.includes(type)
    && ['viewPartial', 'none'].includes(result)) {
    el.classList.add('disabled-anchor-tag');
  } else if (!(scmTypes.includes(type))
  && ((type === 'PermissionGroup' && result === 'none')
    || (type !== 'PermissionGroup' && result !== 'alter'))) {
    el.setAttribute('disabled', 'disabled');
    el.classList.add('disabledPer');
  }
}

export default {
  mounted: (el, binding) => {
    const { result, type } = getPermissionValues(binding.value, true);
    if (result !== null) setPermission(result, el, type);
  },
};
