/* eslint-disable max-len */
import { createStore } from 'vuex';
import screens from '@/constants';
import VuexPersistence from 'vuex-persist';
import localforage from 'localforage';
import _ from 'lodash';
import Users from '@/models/Users';
import Companies from '@/models/Companies';
import Projects from '@/models/Projects';
import defaultStatus from '@/components/DefaultFilterOpts';
import translator from '@/languages/translator';
import invalidListConstants from '@/components/excel-cols/invalidListConstants';
import qrScan from '@/store/modules/qrScan.module';
import VolatileCache from './utils/VolatileCache';

const permMap = new VolatileCache(10 * 60);

// using localforage with vuex Persistence
/* using a reducer to fix this https://issueexplorer.com/issue/championswimmer/vuex-persist/225 */
const createPersistedState = new VuexPersistence({
  storage: localforage, // IndexDB
  asyncStorage: true,
  key: 'vuex',
  reducer: (state) => {
    const authToken = localStorage.getItem('auth-token');
    if (!authToken) {
      return {};
    }
    const obj = _.cloneDeep(state);
    delete obj.userData;
    // delete obj.itemsWithGuid;
    delete obj.userPromise;
    delete obj.companyData;
    // delete obj.activeScreenCols;
    delete obj.extraCols;
    delete obj.tableUpdates;
    delete obj.queryParams._projects;
    delete obj.queryParams._projectsAndGI;
    return obj;
  },
});

const { t } = translator;
let isFetchingCompanyRuns = false;

const getDefaultState = () => (
  {
    massDateObjects: {},
    itemLimits: {
      prefab: 500,
      mm: 500,
      po: 50,
      pm: 50,
      shippingLabel: 50,
    },
    excelOrderLimit: {
      prefab: 500,
      mm: 500,
      tpm: 50,
      tmm: 500,
    },
    runsLimit: {
      pm: 100,
    },
    multiCreateMaterialActive: false,
    reOrderRunsName: [],
    hiddenActivity: false,
    completeActivity: false,
    toggleMyNotify: false,
    multiCreateTransferReqActive: false,
    multiShipInventoryActive: false,
    forgeRedirectUrl: '',
    templateView: 'Production Orders',
    colorScheme: {},
    companyRuns: [],
    productivityData: true,
    isLoggedIn: false,
    showSidebar: false,
    activityLogVisible: false,
    multiRemoveActive: false,
    projectViewOnlyMember: false,
    viewOnlyMember: false,
    searchText: '',
    colsMap: {}, // to show column filter, {screen: '', visible: {}}
    scheduleExcelExportCols: [],
    extraCols: {},
    detailedCols: {},
    analysisTab: 'overallProject',
    activeScreen: '',
    // activeScreenCols: [],
    sortOrder: {},
    projectActivityType: '',
    inventoryLocation: '',
    runFilter: { name: t('All Work Steps'), val: 'all' },
    queryParams: {
      getOnlyRoot: false,
      hideInvOrders: false,
      showAllCompanyOrders: false,
      selectedCompanyLocations: [],
      selectedOldCompanyLocations: [],
      selectedCompanyLocationGroup: '',
      projectsAndGI: [],
      projects: [],
      filteredProjects: [],
      selectedLmvProject: [],
      projectsUpdate: 0,
      ptProjects: [],
      activeProjects: [],
      locations: [],
      allLocations: '',
      companies: [],
      _locations: [],
      _projects: [],
      _otherCompanyProjects: [],
      _projectsAndGI: [],
      _users: [],
      allUsers: [],
      users: [],
      owners: [],
      owner: [],
      recipient: [],
      roles: [],
      permissionGroups: [],
      isAdmin: '',
      inAllProjects: '',
      deliveryLocations: [],
      currentLocations: [],
      suppliers: [],
      vendors: [],
      vendor: [],
      categories: [],
      subCategories: [],
      tags: [],
      recipients: [],
      assignee: [],
      project: {},
      projectId: '',
      location: '',
      source: [],
      types: [],
      todoStatus: [],
      todoTypes: [],
      todoPrivacy: {},
      dateFrom: '',
      dateTo: '',
      searchText: '',
      catId: '',
      shipStatus: [],
      orderStage: [],
      allStage: [],
      dataSource: [],
      materialStage: [],
      lmvStage: [],
      orderStatus: [],
      submittals: [],
      submittalResponse: [],
      selectedList: '',
      oldselectedList: '',
      allDates: {},
      projectPinned: false,
      GIProjectPinned: false,
      initialized: true,
      multiMoveShipLocId: '',
      multiMoveInvLoc: '',
      multiMoveProjectId: '',
      inStock: { label: 'In Stock', value: 'isAvailable' },
      calOption: [],
      isActiveSelected: {
        projects: false,
        projectsAndGI: false,
      },
      commonStockProject: {},
      isLoading: false,
      module: [],
      oldStage: [],
      newStage: [],
      level: [],
      zone: [],
      category: {},
      subCategory: {},
      bulkQrPdf: false,
      bulkWorkStep: false,
    },
    projectData: {},
    userData: {},
    companyData: {},
    // itemsWithGuid: [],
    showIcons: {
      eyeIcon: false,
      multiDelete: false,
    },
    defaultItem: false,
    activeTabIndex: 0,
    availablePermissionGroups: [],
    onePerItem: false,
    superAdminGroup: {},
    tableUpdates: 0,
    editMultipleEntries: false,
    mmDbTimeframeFilter: {
      materialRequests: 'months:1',
      vendorFulfillment: 'months:1',
    },
    addPartsModalActive: false,
    allLocationsLength: 0,
    hideFilterDropdown: false,
    refreshFilters: false,
    selectedWorkstep: '',
    selectedProject: '',
    otherCompanyIds: [],

  }
);

let userLastUsedProjects = [];
let userLastUsedColsMap = {};

export default createStore({
  state: getDefaultState(),
  getters: {
    /* Vue Devtools bug : https://github.com/vuejs/vuex/issues/2046
    * the above state not updating even though its changed
    * so use the state getter to check the updates in devtools
    */
    state: (state) => state,
    getProjectSettings: (state) => (projectId, companyId) => {
      const allProjects = state.queryParams._projectsAndGI;
      let project = _.find(allProjects, (proj) => proj._id === projectId);
      let projectSetting = {};
      if (!_.isEmpty(project)) {
        projectSetting = _.find(project.projectSettings, { companyId });
        if (_.isEmpty(projectSetting)) {
          let projectSettings = [];
          project = _.find(allProjects, (lp) => ((lp._id === projectId && lp.isMaster)
              || _.some(lp.childProjects, { _id: projectId })));
          projectSettings.push(project.projectSettings);
          for (const childProject of project.childProjects) {
            projectSettings.push(childProject.projectSettings);
          }
          projectSettings = _.flatten(projectSettings);
          projectSetting = _.find(projectSettings, { companyId });
        }
      }
      return projectSetting;
    },
    selectedIdsForKey: (state) => (key, sendAll = true, emptyReturnType = null) => {
      if (key === 'filteredProjects' && state.queryParams[key].length === 0) key = 'projects';
      if (key.includes('projects')) {
        if (state.queryParams[key].length === 0 && !state.userData.rememberLastUsedProjects) {
          state.queryParams.isActiveSelected[key] = true;
        } else {
          state.queryParams.isActiveSelected[key] = false;
        }
        if (state.queryParams.isActiveSelected[key]) return _.map(state.queryParams.activeProjects, '_id');
      }
      if (state.queryParams[key]?.length === 0 && state.queryParams[`_${key}`] && sendAll) {
        return _.map(state.queryParams[`_${key}`], '_id');
      } if (state.queryParams[key]?.length === 0) {
        return emptyReturnType;
      }
      return _.map(state.queryParams[key], '_id');
    },
    selectedObjectsForKey: (state) => (key) => {
      if (key.includes('projects')) {
        if (state.queryParams[key].length === 0 && !state.userData.rememberLastUsedProjects) {
          state.queryParams.isActiveSelected[key] = true;
        }
        if (state.queryParams.isActiveSelected[key]) return state.queryParams.activeProjects;
      }
      if (state.queryParams[key].length === 0 && state.queryParams[`_${key}`]) {
        return _.map(state.queryParams[`_${key}`]);
      }
      return _.map(state.queryParams[key]);
    },
    selectedValuesForKey: (state, getters) => (key, sendAll = true) => {
      if (key === 'filteredProjects') {
        const projectIds = getters.selectedIdsForKey('filteredProjects');
        return !_.isEmpty(state.queryParams[key])
          ? state.queryParams[key]
          : state.queryParams._projects.filter((project) => projectIds.includes(project._id));
      }
      if (state.queryParams[key] && state.queryParams[key].length === 0 && sendAll) {
        if (_.isArray(defaultStatus[key])) return _.map(defaultStatus[key], 'value');
        if (!_.isEmpty(defaultStatus[key]) && defaultStatus[key][state.activeScreen]) {
          return _.map(defaultStatus[key][state.activeScreen], 'value');
        }
      }
      return _.map(state.queryParams[key], 'value');
    },
    selectedDatesForKeys: (state) => (keys) => _.reduce(keys, (sum, key) => {
      if (state.queryParams.allDates[key]) sum[key] = state.queryParams.allDates[key];
      return sum;
    }, {}),
    selectedOwners: (state) => {
      if (state.queryParams.owners.length > 0) {
        return { user: { _id: _.map(state.queryParams.owners, '_id') } };
      }
      return null;
    },
    selectedOwner: (state) => {
      if (state.queryParams.owner.length > 0) {
        return { user: { _id: _.map(state.queryParams.owner, '_id') } };
      }
      return null;
    },
    scmSelectedOwner: (state) => {
      if (state.queryParams?.owner?.length) {
        return _.map(state.queryParams.owner, '_id');
      }
      return null;
    },
    selectedSubmittals: (state) => {
      if (state.queryParams.submittals.length > 0) {
        return _.map(state.queryParams.submittals, 'id');
      }
      return null;
    },
    getStageColor: (state) => (card) => {
      const { stage } = card;
      const isMM = card.isMM ? card.isMM() : false;
      if (['preparation', 'sourcing', 'ordering', 'coordination',
        'detailing', 'manufacturing', 'delivery', 'complete'].includes(stage)) {
        return state.colorScheme[stage];
      } if (stage === 'in-transit') {
        return state.colorScheme.inTransit;
      } if (stage === 'planning') {
        return state.colorScheme.prefab;
      } if (stage === 'qa') {
        return state.colorScheme[isMM ? 'fieldverify' : 'qa'];
      }
      return state.colorScheme.mixColor;
    },
    userPromise: (state) => new Promise((resolve) => {
      const interval = setInterval(() => {
        if (state.userData._id) {
          resolve(state.userData);
          clearInterval(interval);
        }
      }, 200);
    }),
    activeScreenCols: ({ colsMap, activeScreen }) => {
      // const device = window.screen.width <= 768 ? 'iPadVisible' : 'defaultVisible';
      const device = 'defaultVisible';
      const screen = activeScreen;
      const specCols = _.keyBy(colsMap[screen] || [], 'id');
      const screenCols = screens[screen].cols;
      const cols = [];
      for (const specCol of colsMap[screen]) {
        const c = _.find(screenCols, { id: specCol.id });
        if (_.isEmpty(c)) continue;
        const visible = c.alwaysVisible
          || (!specCols[c.id] || _.isUndefined(specCols[c.id].visible)
            ? (c[device] || false) : specCols[c.id].visible);
        const col = {
          ...c,
          visible,
          tdAttrs: () => ({ style: `width: ${c.width}`, class: c.tdClass || '' }),
        };
        cols.push(col);
      }
      return cols;
    },
    getProjectsOnPermission: (state) => (pModule, allProjects, type) => {
      let projects = [];
      const { resolvedPermissionGroups, company } = state.userData;
      const key = type || 'data';
      const newRegex = new RegExp(`\\|(${company}):([0-9a-z]{24}):(${pModule}):(${key})\\|`, 'g');
      const mergedKeys = `|${Object.keys(resolvedPermissionGroups).join('||')}|`;
      const projectsPermission = Array.from(mergedKeys.matchAll(newRegex));
      projectsPermission.forEach((perm) => {
        const permissionValue = resolvedPermissionGroups[perm[0].replace(/\|/g, '')];
        if (perm[2] === '000000000000000000000000' && permissionValue === 'alter') {
          projects = _.cloneDeep(allProjects);
        } else {
          const res = _.find(projects, { _id: perm[2] });
          if (permissionValue !== 'alter' && res) {
            projects.splice(_.findIndex(projects, { _id: perm[2] }), 1);
          } else if (permissionValue === 'alter' && !res) {
            const project = _.find(allProjects, { _id: perm[2] });
            if (project) projects.push(project);
          }
        }
      });
      return projects;
    },
    getPermissionVal: (state) => (value, key = 'save') => {
      const mapkey = `${_.get(value, 'rowData.project._id', '0')}_${key}_${value.permissionModule}`;

      if (permMap.has(mapkey)) return permMap.get(mapkey);

      const projects = _.cloneDeep(state.queryParams._projectsAndGI);
      projects.push(state.queryParams.commonStockProject);
      const res = Users.getPermissionValue(
        key, value, state.userData,
        projects,
      );
      permMap.set(mapkey, res);
      return res;
    },
    findProject: ({ queryParams: qp }) => (id) => _.find(qp._projects, id)
      || _.find(qp._projectsAndGI, id),
    getActiveScreenFilters: () => (screen) => _.get(screens, `${screen}.filters`, []),
    excelColumns: (state) => (key) => screens[key || state.activeScreen]?.excelColumns,
    selectedProjectId: (state) => _.get(state.queryParams.project, '_id'),
    selectedInStock: (state) => {
      if (state.queryParams.inStock) {
        return state.queryParams.inStock.value;
      }
      return null;
    },
    hasPermissionForModule: (state) => (key) => state.userData.allowedModules.allModules.includes(key),
    hasCompanyPermissionForModule: (state) => (key) => state.userData.allowedModules.companyModules.includes(key),
    hasPermissionForAnyModule: (state, getters) => (keys) => _.some(keys, getters.hasPermissionForModule),
    hasPermissionForAllModules: (state, getters) => (keys) => _.every(keys, getters.hasPermissionForModule),
    getStageWiseModule: () => (order) => {
      const stageWisePermissionModule = {
        planning: 'prefabs',
        coordination: 'production-order',
        manufacturing: 'production-manager',
        detailing: 'production-manager',
        qa: 'production-manager',
        delivery: 'production-manager',
        'released-to-inventory': 'production-manager',
        preparation: 'material-manager',
        ordering: 'material-manager',
        sourcing: 'material-manager',
        complete: 'production-manager',
        fulfilled: 'production-manager',
        'in-storage': 'material-manager',
        'in-transit': 'material-manager',
        'mixed-shipping': 'material-manager',
      };
      const stage = order.stage === 'mixed' ? order.leastStage : order.stage;
      if ((['Materials', 'Sourcing'].includes(order.__t) || order.baseCardType === 'Materials')
        && ['qa', 'delivery', 'fulfilled', 'complete'].includes(stage)) {
        return 'material-manager';
      }
      return stageWisePermissionModule[stage];
    },
    getSubtabsModule: () => (tab) => {
      const subTabCheck = {
        companyTemplates: 'app&projects:company_templates',
        companyColors: 'app&projects:company_colors',
        qrLabel: 'app&projects:company_qrs',
        lists: 'app&projects:company_lists',
        loginSecurity: 'app&projects:company_security',
        permissionGroups: 'app&projects:company_permissions',
        companyInfo: 'app&projects:company_settings',
        companyLocations: 'app&projects:company_locations',
        generalInventory: 'app&projects:general_inventory',
        measureUnits: 'app&projects:measure_units',
        assemblyParts: 'app&projects:assembly_parts',
        vendors: 'app&projects:vendors',
      };
      return subTabCheck[tab];
    },
    hasShippingAccess: (state) => (data) => {
      const { recipient } = data._delivery;
      const { createdBy } = data;
      const userId = state.userData.id;
      return (recipient.id === userId) || (createdBy.id === userId);
    },
    isViewer: (state, getters) => (userToConsider = {}) => {
      const user = !_.isEmpty(userToConsider) ? userToConsider : state.userData;
      if (!_.isEmpty(user) && _.get(user, 'multiCompanies', []).length > 1) {
        const nonArchivedCompanies = getters.getViewerCompanies;
        if (!_.isEmpty(nonArchivedCompanies)) return true;
      }
      return false;
    },
    viewerHasPermission: (state, getters) => {
      const { userData } = state;
      const selectedCompany = getters.selectedIdsForKey('companies', false);
      const nonArchivedCompanies = getters.getViewerCompanies;
      const multiCompanyOwners = _.keyBy(nonArchivedCompanies, 'company._id');
      const selectedCompanyOwner = _.get(multiCompanyOwners[selectedCompany], 'companyOwner', userData._id);
      return !_.isEmpty(userData) && !_.isEmpty(selectedCompany)
      && !_.isEmpty(nonArchivedCompanies)
      && userData._id.toString() === selectedCompanyOwner.toString();
    },
    getViewerCompanies: (state) => {
      const { userData } = state;
      let nonArchivedCompanies = [];
      if (_.get(userData, 'multiCompanies', []).length > 1) {
        nonArchivedCompanies = userData.multiCompanies.filter((comp) => !_.get(comp, 'archived.value', false));
      }
      return nonArchivedCompanies;
    },
    invalidExcelColumns: (state) => (key) => {
      const invalidExcelColsType = _.get(screens, `[${key || state.activeScreen}].invalidExcelColsType`, null);
      if (invalidExcelColsType) {
        return _.get(invalidListConstants, invalidExcelColsType, []);
      }
      return _.get(invalidListConstants, key, []);
    },
    selectedTodoPrivacy: (state) => {
      if (_.isArray(state.queryParams.todoPrivacy) && state.queryParams.todoPrivacy.length === 1) {
        return _.some(state.queryParams.todoPrivacy, (p) => p.value);
      }
      return null;
    },
    getUserLastUsedProjects: () => userLastUsedProjects,
    getUserLastUsedColsMap: () => userLastUsedColsMap,
  },
  mutations: {
    resetToInitState: (state) => {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setLoggedIn: (state, loggedIn) => {
      state.isLoggedIn = loggedIn;
    },
    setLoading: (state, val) => {
      state.isLoading = val;
    },
    setSortOrder: (state, { key, sort }) => {
      key = `${state.activeScreen}_${key}`;
      state.sortOrder[key] = sort;
    },
    setActiveScreen: (state, activeScreen) => {
      state.activeScreen = activeScreen;
    },
    setTestData: (state, includeTestData) => {
      state.includeTestData = includeTestData;
    },
    resetSearchText(state) {
      state.queryParams.searchText = '';
    },
    setRefreshFilters(state, val) {
      state.refreshFilters = val;
    },
    setQueryParams: (state, params) => {
      if (params.projects) {
        params.projectsUpdate = state.queryParams.projectsUpdate + 1;
      }
      state.queryParams = {
        ...state.queryParams,
        ...params,
      };
    },
    setUserData: (state, { userData }) => {
      let selectedCompany = userData.company;
      const nonArchivedCompanies = userData.multiCompanies.filter((comp) => !_.get(comp, 'archived.value', false));
      // added the below code for tabs visiblity for view only member
      if (!_.isEmpty(nonArchivedCompanies)) {
        if (state.queryParams.companies.length && nonArchivedCompanies.length > 1) {
          selectedCompany = state.queryParams.companies[0]._id;
        } else {
          selectedCompany = _.get(nonArchivedCompanies[0], 'company._id', userData.company);
        }
      }
      if (userData.resolvedPermissionGroups) {
        userData.allowedModules = Users
          .filterVisibleModules(userData.resolvedPermissionGroups, selectedCompany);
        const multiCompanyOwners = _.keyBy(nonArchivedCompanies, 'company._id');
        const selectedCompanyOwner = _.get(multiCompanyOwners[selectedCompany], 'companyOwner', userData._id);
        const isCompanyOwner = userData._id.toString() === selectedCompanyOwner.toString();
        if (!_.isEmpty(userData) && !_.isEmpty(selectedCompanyOwner)
          && !_.isEmpty(nonArchivedCompanies)
          && (isCompanyOwner || nonArchivedCompanies.length === 1)) {
          userData.allowedModules.companyModules.push('material-manager:overview');
        }
      }
      state.userData = userData;
    },
    setCompanyData: (state, { companyData }) => {
      state.companyData = companyData;
    },
    setColorScheme: (state, { colorScheme }) => {
      state.colorScheme = colorScheme;
    },
    setUserPromise: (state, up) => {
      state.userPromise = up;
    },
    setPermissionGroups: (state, val) => {
      state.availablePermissionGroups = val;
      state.superAdminGroup = _.find(val, (dt) => dt.name === 'Super Admin' && dt.isLocked);
    },
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },
    changeScreen(state, { screen }) {
      if (!screens[screen] && !['lmv-view', 'lmv-view-card', 'lmv-view-item'].includes(screen)) screen = 'untitled';
      state.activeScreen = screen;
      // columns within current state (contains only 'id' and 'visible' props)
      state.extraCols = {};
      let filteredCols = _.cloneDeep(_.isEmpty(state.colsMap[screen]) ? screens[screen].cols : state.colsMap[screen]);
      if (!_.isEmpty(state.userData)) {
        const { userData, queryParams } = state;
        const { resolvedPermissionGroups } = userData;
        const { permissionModule, permissionKey } = screens[screen];
        let { company } = state.userData;
        const nonArchivedCompanies = userData.multiCompanies.filter((comp) => !_.get(comp,
          'archived.value', false));
        if (!_.isEmpty(userData) && !_.isEmpty(nonArchivedCompanies)) {
          company = _.get(queryParams.companies[0], '_id', userData.company);
        }
        let keyToConsider = permissionKey || 'data';
        let moduleToConsider = permissionModule;
        if (permissionKey === 'productionStatus') keyToConsider = 'production-status';
        if (permissionKey === 'materialStatus') keyToConsider = 'material-status';
        if (permissionKey === 'shippingStatus') moduleToConsider = 'shipping';
        const key = `${company}:000000000000000000000000:${moduleToConsider}:${keyToConsider}`;
        const ownerLocationKey = key
          .replace(`${moduleToConsider}`, 'general')
          .replace(`${keyToConsider}`, 'owner_location');
        const ownerLocationPermission = resolvedPermissionGroups[ownerLocationKey];
        const permissionValue = resolvedPermissionGroups[key];
        const multiCompanyOwners = _.keyBy(nonArchivedCompanies, 'company._id');
        const selectedCompany = !_.isEmpty(queryParams.companies)
        && !_.isEmpty(nonArchivedCompanies) ? queryParams.companies[0] : userData.company;
        const selectedCompanyOwner = selectedCompany ? _
          .get(multiCompanyOwners[selectedCompany._id], 'companyOwner', userData._id) : userData._id;
        // Remove actionFields and detailRow if module permission is set to 'limited view'
        if ((!_.isEmpty(nonArchivedCompanies)
          && userData._id.toString() !== selectedCompanyOwner.toString())
          || permissionValue === 'viewPartial') {
          filteredCols = filteredCols.filter((col) => !['action',
            'detailRow', 'recipient', 'baseDelivery.recipient.name']
            .includes(col.id));
          state.detailedCols[screen] = { hide: true };
        } else {
          state.detailedCols[screen] = { hide: false };
        }
        // Remove owner and location columns if 'owner and location' permission is set to 'no'.
        if (ownerLocationPermission === 'none') {
          filteredCols = filteredCols.filter((col) => !['owner',
            'manager.owner.user.name', 'deliveryLocation',
            'location', 'nowAt', 'baseDelivery.location.name'].includes(col.id));
        }
      }
      if (!['login'].includes(screen)) {
      state.colsMap[screen] = state.colsMap[screen]
      || screens[screen].cols.map((c) => _.pick(c, ['id', 'width', 'visible', 'sortField', 'title']));
      state.colsMap[screen] = filteredCols.map((c) => _.pick(c, ['id', 'width', 'visible', 'sortField', 'title']));
      }
    },
    setColumns: (state, columns) => {
      state.colsMap[state.activeScreen] = _.map(columns, (c) => _.pick(c, ['id', 'width', 'visible', 'sortField', 'title']));
    },
    setPerItem: (state, val) => {
      state.onePerItem = val;
    },
    updateColsMap(state, val) {
      state.colsMap = _.isUndefined(val) ? {} : val;
    },
    setDefaultItem: (state, val) => {
      state.defaultItem = val;
    },
    setMultiCreateMatActive(state, val) {
      state.multiCreateMaterialActive = val;
    },
    setreOrderRunsName(state, val) {
      state.reOrderRunsName = val;
    },
    setHiddenActivity(state, val) {
      state.hiddenActivity = val;
    },
    setCompleteActivity(state, val) {
      state.completeActivity = val;
    },
    setToggleMyNotify(state, val) {
      state.toggleMyNotify = val;
    },
    setAllLocationsLength: (state, val) => {
      state.allLocationsLength = val;
    },
    setMultiRemoveActive: (state, val) => {
      state.multiRemoveActive = val;
    },
    setMultiCreateTransferReqActive(state, val) {
      state.multiCreateTransferReqActive = val;
    },
    setMultiShipInventoryActive(state, val) {
      state.multiShipInventoryActive = val;
    },
    setHideFilterDropdown(state, val) {
      state.hideFilterDropdown = val;
    },
    setCompanyRuns(state, val) {
      state.companyRuns = val;
    },
    setRunFilter: (state, filter) => {
      state.runFilter = filter;
    },
    setProject: (state, val) => {
      state.queryParams.project = val;
    },
    setUserLastUsedProjects: (state, val) => {
      userLastUsedProjects = _.isUndefined(val) ? state.queryParams.projects : val;
    },
    setUserLastUsedColsMap: (state, val) => {
      userLastUsedColsMap = _.isUndefined(val) ? state.colsMap : val;
    },
    setIsActiveSelectedVal: (state, projectKey, val) => {
      state.queryParams.isActiveSelected[projectKey] = val;
    },
    setSelectedWorkStep: (state, val) => {
      state.queryParams.selectedWorkstep = val;
    },
    setSelectedProject: (state, val) => {
      state.queryParams.selectedProject = val;
    },
    setOtherCompanyIds: (state,val) => {
      state.queryParams.otherCompanyIds = val;
    }
  },
  actions: {
    getUserData: async (ctx) => {
      ctx.commit('setColorScheme', { colorScheme: Companies.defaultColorSchemes() });
      const up = Users.getUser();
      ctx.commit('setUserPromise', up);
      const [userData, colorScheme, permissionGroups, companyData] = await Promise.all([
        up,
        Companies.colorSchemes(),
        Companies.getPermissionGroups(),
        Companies.getOne(),
      ]);
      ctx.commit('setPermissionGroups', permissionGroups);
      ctx.commit('setUserData', { userData });
      ctx.commit('setColorScheme', { colorScheme });
      ctx.commit('setCompanyData', { companyData });
    },
    getCompanyData: async (ctx) => {
      const companyData = await Companies.getOne(ctx.state.userData.company);
      ctx.commit('setCompanyData', { companyData });
    },
    getCompanyRuns: async ({ state, commit }) => {
      if (isFetchingCompanyRuns === false && state.companyRuns.length === 0) {
        isFetchingCompanyRuns = true;
        const companyDataPromise = Companies.getCompanyRuns('companyRuns');
        commit('setCompanyRuns', await companyDataPromise);
        isFetchingCompanyRuns = false;
      }
    },
    getColorScheme: async (ctx) => {
      const colorScheme = await Companies.colorSchemes(ctx.state.userData.company);
      ctx.commit('setColorScheme', { colorScheme });
    },
    updateProjects: async (ctx, companyId = '') => {
      const { userData } = ctx.state;
      const nonArchivedCompanies = userData.multiCompanies.filter((comp) => !_.get(comp, 'archived.value', false));
      let projects = await Projects.projectsForCompany(
        !_.isEmpty(companyId) && !_.isEmpty(nonArchivedCompanies) ? companyId : userData.company,
        { sort: 'activity' },
      );
      // eslint-disable-next-line prefer-const
      let { allProjects, allGIs, userCreatedProjects } = projects;
      allProjects = _.filter(allProjects, { isTemplateProject: false });
      let otherCompanyProjects=[];
      userCreatedProjects = _.filter(userCreatedProjects, (p)=>p.createdCompany._id === userData.company);
      allProjects=_.filter(allProjects, (p)=>{
        if(p.createdCompany._id === userData.company){
          return true;
        } else {
          otherCompanyProjects.push(p)
          return false;
        }
      })
      ctx.commit('setQueryParams', {
        _otherCompanyProjects: otherCompanyProjects,
      });
      ctx.commit('setQueryParams', {
        activeProjects:
        _.take(userCreatedProjects, userData.activeProjectsCount || 5),
      });
      ctx.commit('setQueryParams', {
        _projects: allProjects,
      });
      ctx.commit('setQueryParams', {
        _projectsAndGI:
        allProjects.concat(allGIs),
      });
      ctx.commit('setQueryParams', {
        commonStockProject: await Projects.getCommonStockProject(),
      });
    },
    selectActiveProjects: (ctx, key) => {
      ctx.commit('setQueryParams', { [key]: ctx.state.queryParams.activeProjects });
      ctx.state.queryParams.isActiveSelected[key] = true;
    },
    setHideFilterDropdown: (ctx, val) => {
      ctx.commit('setHideFilterDropdown', val);
    },
    updateActiveProj: async (ctx, companyId = '') => {
      const { userData } = ctx.state;
      const nonArchivedCompanies = userData.multiCompanies.filter((comp) => !_.get(comp,
        'archived.value', false));
      const { userCreatedProjects } = await Projects.projectsForCompany(
        !_.isEmpty(companyId) && !_.isEmpty(nonArchivedCompanies) ? companyId : userData.company,
        { sort: 'activity' },
      );
      ctx.commit('setQueryParams', {
        activeProjects:
        _.take(userCreatedProjects, userData.activeProjectsCount || 5),
      });
    },
    resetState: ({
      commit, state, getters,
    }) => {
      if (state.userData.rememberLastUsedProjects) commit('setUserLastUsedProjects');
      commit('setUserLastUsedColsMap', state.colsMap);
      commit('resetToInitState');
      commit('setQueryParams', { projects: getters.getUserLastUsedProjects });
      commit('updateColsMap', getters.getUserLastUsedColsMap);
      commit('setUserLastUsedProjects', []);
      commit('setUserLastUsedColsMap', {});
    },
  },
  modules: {
    qrScan,
  },
  plugins: [createPersistedState.plugin],
});
