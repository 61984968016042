<template>
  <div class="is-relative note-count is-clickable" @click="popOver = !popOver">
    <span>{{ getInstallLocs.length }}</span>
    <i class="icon-attachedlocationsOLv1"></i>
  </div>
  <install-locs-popover
    :isOpen="popOver"
    :installLocs="getInstallLocs"
    @closePopover="closePopover"
    :rowData="rowData"
  />
</template>

<script>
import {
  reactive, toRefs, computed, inject,
} from 'vue';
import { get, set } from 'lodash';
import InstallLocsPopover from '@/components/modals/InstallLocsPopover.vue';

export default {
  name: 'InstallLocsIconField',
  components: {
    'install-locs-popover': InstallLocsPopover,
  },
  props: ['value', 'rowField', 'rowData', 'isItem', 'isShipping'],
  setup(props) {
    const emitter = inject('emitter');
    const state = reactive({
      notes: [],
      popOver: false,
    });

    const notesCount = computed(() => {
      if (
        (!props.isItem && props.rowData.currentNote?.text?.length)
        || (!props.isItem && props.rowData.manager?.notes.length)
        || (props.isShipping && props.rowData.delivery.notes?.length)
        || (props.isItem
          && props.rowData.simpleMemos.length
          && props.rowData.simpleMemos[0].text?.length)
      ) {
        return 1;
      }
      return 0;
    });

    const openNotesSlider = () => {
      const emitObj = {
        data: props.rowData,
        isItem: props.isItem,
        isActive: true,
        isShipping: props.isShipping,
      };
      emitter.emit('toggle:notesSlider', emitObj);
    };

    const getInstallLocs = computed({
      get: () => get(props.rowData, 'installLocs', []),
      set: (installLocs) => set(props.rowData, 'installLocs', installLocs),
    });

    const closePopover = (installLocs) => {
      getInstallLocs.value = installLocs;
      state.popOver = false;
    };

    return {
      ...toRefs(state),
      openNotesSlider,
      notesCount,
      getInstallLocs,
      closePopover,
    };
  },
};
</script>
<style scoped>
button.is-absolute {
  position: absolute !important;
  right: 0;
}
</style>
