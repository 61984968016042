<template>
  <footer v-if="showSave || !hideSaveAndMoveNext" class="card-footer" >
    <o-loading :active="isLoading"></o-loading>
    <div class="has-text-black" v-if="!showSave">
      <button
        class="button has-background-black-bis has-text-white mr-3"
        @click="saveAndMoveNext"
        v-if="!hideSaveAndMoveNext && !['manager-edit-qa', 'cut-edit-manufacturing'].includes($store.state.activeScreen)"
        :disabled="isDisabled"
      >
       {{ moveButtonName }}
      </button>
      <button
        class="button is-success has-text-white"
        @click="createShipmentModal(card)"
        v-if="isCreateShipping && $store.state.activeScreen !== 'cut-edit-manufacturing'"
        :disabled="isShippingDisabled"
      >
        Create Shipment
      </button>
      <button
        class="button is-success has-text-white"
        @click="moveToKit(card)"
        v-if="isAssembly"
      >
        Move to Kit Location
      </button>
      <button
        class="button is-invent-btn has-text-white"
        @click="moveItemsToInventory"
        v-if="card.isCutOrder && $store.state.activeScreen === 'cut-edit-manufacturing'"
        :disabled="!isItemsCompleted"
      >
        Move Completed Items To Inventory
      </button>
    </div>
    <div class="buttons" v-else>
      <button class="button is-outlined is-danger" @click="$emit('reloadCard')" v-permission="{permissionModule: permissionType,
            type: 'edit', project: card.project}">
        discard changes
      </button>
      <button class="button is-success" @click="save" :disabled="disableSave" v-permission="{permissionModule: permissionType,
            type: 'edit', project: card.project}" >
        save changes
      </button>
      <button
        class="button is-success"
        @click="saveAndMoveNext"
        v-if="!hideSaveAndMoveNext"
        :disabled="isDisabled"
      >
        save &amp; {{ $store.state.activeScreen === 'manager-edit-qa' ? 'create shipment' : 'move forward' }}
      </button>
    </div>
  </footer>
</template>
<script>
import {
  defineComponent,
  reactive,
  computed,
  toRefs,
  inject,
  onBeforeMount,
  onMounted,
  h
} from 'vue';
import _ from 'lodash';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import MoveOrders from '@/components/modals/MoveOrders.vue';
import CreateShipping from '@/components/modals/CreateShipping.vue';
import { useToast } from 'vue-toastification';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import PrefabsMixin from '@/components/mixins/PrefabsMixin';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import MaterialManager from '@/models/MaterialManager';
import ShippingLabel from '@/models/ShippingLabel';
import Validation from '@/components/utils/Validations';
// import screens from '@/constants';
import PermissionHelper from '@/components/mixins/PermissionHelper';
import Projects from '@/models/Projects';
import SupplyChain from '@/models/SupplyChain';
import Helper from '@/models/Helper';
import { DialogProgrammatic } from '@/components/Dialog';
import Util from '@/components/mixins/UtilityMixin';
import MoveCompletedItems from '@/components/modals/MoveCompletedItems.vue';


const { isDisabledOnSaveMove, getPermissionModule } = PermissionHelper();

export default defineComponent({
  name: 'card-footer',
  emits: ['changeCard'],
  props: {
    showSave: Boolean,
    card: Object,
    stage: String,
    projectId: String,
    cardId: String,
    type: String,
    itemQtyChanged: {
      type: Boolean,
      default: false,
    },
    matItemQtyChanged: {
      type: Boolean,
      default: false,
    },
    isNewCard: {
      type: Boolean,
      default: false,
    },
    saveCard: {
      type: Function,
      default: (card) => card,
    },
  },
  setup(props, { emit }) {
    let {
      card, type, stage, cardId, saveCard,
    } = props;
    const initialCard = _.cloneDeep(card);
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const emitter = inject('emitter');
    const { moveToKitLoc, generateShipPdf } = GeneralShippingMixin();
    const { getCardStatus } = CardEditMixin();
    const { createBomAndPR } = PrefabsMixin();
    const { conflictHtml } = Util();
    const shippingStages = ['not-started', 'in-transit', 'complete', 'delivery', 'released-to-inventory',
      'fulfilled', 'in-storage', 'mixed-shipping'];

    const state = reactive({
      nextStage: '',
      isMoveForward: true,
      moveFromSourcing: false,
      isSub: false,
      submitMaterial: false,
      selectedValue: true,
      orderForRelease: {},
      showInventoryLoc: false,
      currentLoc: '',
      projectId: '',
      notifyModal: false,
      user: {},
      reservationModalActive: false,
      shipModalActive: false,
      freezeFields: {},
      type: '',
      partialsManagerActive: false,
      masterShipment: null,
      reserveShipmentFor: null,
      isNewPrefab: true,
      obj: [],
      createBomModal: false,
      requiredCheck: [
        { module: 'production-manager', key: 'move' },
        { module: 'production-manager', key: 'data' },
      ],
      moduleMap: {
        'material-edit-ordering': 'material-manager',
        'manager-edit-qa': 'production-manager',
      },
      refreshOrder: false,
      refreshOnClose: false,
      isLoading: false,
      isShippingListVisible: false,
      templateProject: {},
      templateOrders: [],
    });
    const vendorMatchCheck = () => {
      if (_.isEmpty(card.baseDelivery.vendor)) {
        toast.error('Vendor cannot be Empty. Please select a vendor');
        return false;
      }
      // const msg = 'The Order vendor does not match one or more item vendors.
      // do you want to still continue ?';
      let itemVendors = _.flatMap(_.compact(_.map(card.items, 'vendors')));
      itemVendors = _.keyBy(itemVendors, '_id');
      if (
        !_.isEmpty(itemVendors)
        && !_.keys(itemVendors).includes(card.baseDelivery.vendor._id)
      ) {
        // this.$dialog.confirm({
        //   message: msg,
        //   type: 'is-warning',
        //   hasIcon: true,
        //   size: 'is-medium',
        //   icon: 'exclamation-circle',
        //   iconPack: 'fas',
        //   onCancel: () => {
        //     this.moveFromSourcing = false;
        //   },
        // });
        state.moveFromSourcing = false;
      }
      return true;
    };
    const filterItems = computed(() => _.filter(card.items, (i) => !i.archived.value));
    const planningStage = computed(() => ['coordination', 'detailing', 'manufacturing'].includes(card._customStage || card.stage));
    const permissionType = computed(() => getPermissionModule(card));
    const catItemSync = (cardToSync) => {
      // This fn is created to give user a
      // toast that items name and Id will be in sync with Master CatId Manager.
      if (cardToSync.__t === 'Materials') {
        // eslint-disable-next-line max-len
        const newItem = _.some(
          cardToSync.items,
          (item) => _.isUndefined(item._id) && !_.isEmpty(item.catId),
        );
        if (newItem) {
          toast.warning(
            'Item Name and Id will be in sync with Master Catalog!',
          );
        }
      }
    };
    const bomQtyCheck = async () => {
      let qtyChecked;
      try {
        qtyChecked = await card.BOMQuantityCheck();
      } catch (e) {
        console.log(e);
        return toast.warning(e?.message);
      }
      if (['sourcing', 'ordering'].includes(qtyChecked)) {
        return toast.warning('The BOM is in Sourcing/Ordering stage. The quantity changes done here will not be updated in BOM', {
          timeout: 8000,
        });
      }
      if (qtyChecked) {
        return toast.warning('The quantity changes done here will be updated in BOM', {
          timeout: 8000,
        });
      }
      return null;
    };

    const addDefVendor = (orderCard, prevItems) => {
      // add default vendors to the items
      orderCard.items.forEach((item) => {
        const filteredItem = prevItems.filter((prItem) => prItem.uuid === item.uuid);
        if (filteredItem.length) {
          item.defVendor = filteredItem[0].defVendor ? filteredItem[0].defVendor : {};
          item.vendors = filteredItem[0].vendors ? filteredItem[0].vendors : [];
        }
      });
      return orderCard;
    };

    const save = _.debounce(async () => {
      try {
        state.isLoading = true;

        const actualStage = card._customStage || route.params.stage;
        const prevItems = card.items;
        if (['manager-edit-detailing', 'manager-edit-manufacturing'].includes(store.state.activeScreen) && _.some(prevItems, (item) => item?.isNew && item?.runs?.length === 0)) {
          throw new Error('Atleast one run should be present in each item');
        }
        
        if (!card.isCutOrder && _.isEmpty(card.materials) && _.some(card.catQtyMaps,
            (dt) => parseFloat(dt.qtyToConsume) === 0
              && parseFloat(dt.qtyToShip) === 0
              && !dt.qtyConsumed)){
                throw new Error('Both Qty Consume and Qty ship cannot be 0.');

              }
              
        if (!_.isEmpty(card.materials) && planningStage.value
          && _.some(card.catQtyMaps,
            (dt) => parseFloat(dt.qtyToConsume) === 0
              && parseFloat(dt.qtyToShip) === 0
              && !dt.qtyConsumed)) {
          throw new Error('Both Qty Consume and Qty ship cannot be 0.');
        }

        if (!_.isEmpty(store.state.reOrderRunsName)) {
          const { runs } = card.manager;
          const reOrderRuns = [];
          _.forEach(store.state.reOrderRunsName, (uuid) => {
            reOrderRuns.push(runs.find((run) => run.uuid === uuid));
          });
          _.set(card, 'manager.runs', reOrderRuns);
        }

        card = await getCardStatus(card);
        emit('beforeSave');
        catItemSync(card);

        // if card is sourcing
        if (['sourcing'].includes(card.stage) && _.isEmpty(card.items)) {
          throw new Error('Item Validation failed. Please add an Item to save the Order!');
        }

        // if card is ordering
        if (card.stage === 'ordering') {
          if (_.isUndefined(card.baseDelivery.vendor)) {
            throw new Error('Vendor cannot be Empty. Please select a Vendor');
          }
          if (_.isUndefined(card.baseDelivery.recipient)) {
            throw new Error('Please add a Recipient before Shipping');
          }
          card.readyForShipment();
        }

        // if card is sourcing or ordering
        if (['sourcing', 'ordering'].includes(card.stage) && route.params.cardId === 'add') {
          _.set(card, 'stage', 'preparation');
          state.refreshOrder = true;
        }

        // if card is coordination
        if (card.stage === 'coordination' && !_.isEmpty(card.materials) && props.itemQtyChanged === true) {
          await bomQtyCheck();
        }

        if (!_.isEmpty(card.materials) && card._bomCreated === true && props.itemQtyChanged === true && !card.hasGuid) {
          await bomQtyCheck();
        }

        for (const item of card.items) {
          if (!_.isEmpty(item.measureUnits)
              && !_.isFinite(parseFloat(item.measure))
          ) {
            throw new Error(`Please select measure for item ${item.name}`);
          }
          if (!_.isFinite(parseFloat(item.leadTime))) {
            throw new Error(`Lead time cannot be empty for item ${item.name}`);
          }
          if (!_.isEmpty(item.simpleMemos)) {
            const memo = item.newFTD('memos');
            memo.text = item.simpleMemos[0].text;
          }
        }
        if (filterItems.value.length && card.isPrefab() && card.purpose === 'kit') {
          const defaultItem = _.find(filterItems.value, (item) => item.purpose === 'kit');
          _.set(defaultItem, 'name', card.name);
        }
        if (filterItems.value.length === 0 && card.__t === 'ProductionOrder') {
          await saveCard(cardId, stage, card);
          state.isLoading = false;
          return false;
        }
        if (card.isSourcing() && card.stage === 'ordering') {
          if (!vendorMatchCheck()) {
            state.isLoading = false;
            return false;
          }
        }
        card = await saveCard(cardId, stage, card);

        if (card.__t === 'Prefabs' && !card._id) state.isNewPrefab = false;
        if (
          ['sourcing', 'ordering'].includes(actualStage)
            && route.params.cardId === 'add'
        ) {
          state.isNewPrefab = false;
          const srcCard = (await MaterialManager.pullToSourcing([card._id], card))[0];
          if (actualStage === 'ordering') {
            srcCard.stage = 'ordering';
            let orderingCard = await MaterialManager.moveToNextStageFromCard(
              srcCard,
            );

            orderingCard = addDefVendor(orderingCard, prevItems);

            emit('changeCard', orderingCard);
            let { commonStockProject } = store.state.queryParams;
            commonStockProject = !_.isEmpty(commonStockProject)
              ? commonStockProject : await Projects.getCommonStockProject();
            const newShipment = await ShippingLabel.createShipment(
              orderingCard,
              commonStockProject,
            );
            if (newShipment) {
              await generateShipPdf(newShipment);
            }
          } else emit('changeCard', srcCard);
        } else card = addDefVendor(card, prevItems);

        if (
          route.params.cardId !== 'add'
            && card.isMM()
            && actualStage !== 'preparation'
            && _.some(card.items, { stage: 'preparation' })
        ) {
          router.push({
            name: 'material-edit',
            params: {
              projectId: card.project._id,
              cardId: card._id,
              stage: 'preparation',
            },
          });
          emit('reload-page');
        }
        if (
          card.stage === 'preparation'
            && card._customStage === 'rejected'
            && (card.items.length === 0 || _.every(card.items, { stage: 'preparation' }))
            && route.params.stage === 'rejected'
        ) {
          router.push({
            name: 'material-edit',
            params: {
              projectId: card.project._id,
              cardId: card._id,
              stage: 'preparation',
            },
          });
          emit('reload-page');
        }
        emit('reload-page');
        state.refreshOrder = false;
      } catch (e) {
        let errMsg = 'Something went wrong, please contact Manufacton Support';
        if (e.message) errMsg = e.message;
        else if (_.get(e, 'data.msg', '')) errMsg = e.data.msg;
        else if (_.get(e, 'msg', '')) errMsg = e.msg;
        const conflictMsg = conflictHtml(errMsg);
        // const conflictMsg = `<h3 class="is-size-3 is-italic has-text-weight-bold mb-3 has-text-danger" >Duplicate Order ID:</h3>
        //   <h3 class="is-size-3  mb-3 has-text-danger" > ${errMsg.OrderId}</h3>
        //   <h6 class="is-size-6 has-text-weight-bold has-text-black-bis" >(Order ID's must be unique and cannot be duplicated within a single project.) </h6>
        //   <div class="is-divider my-3"></div>
        //   <h3 class="is-size-3 mb-3 has-text-weight-bold is-italic has-text-black-bis" >Order Name:</h3>
        //   <h3 class="is-size-3 mb-3 has-text-black-bis" >${errMsg.OrderName} </h3>
        //    <h3 class="is-size-3 mb-3 has-text-weight-bold is-italic has-text-black-bis" >Project: </h3>
        //    <h3 class="is-size-3 has-text-black-bis" >${errMsg.Project} </h3>`;
        if (errMsg.OrderId || errMsg.OrderName || errMsg.Project) {
          const confirmParam = {
            title: 'CONFLICT: Order ID',
            okButton: 'Close',
            type: 'black-bis',
            conflictNote: conflictMsg,
            noteType: 'danger',
          };
          DialogProgrammatic.confirm(confirmParam);
        } else {
          toast.error(h('div', {innerHTML: errMsg}));
        }
      } finally {
        state.isLoading = false;
      }
      return null;
    },
    1000,
    { leading: true, trailing: false });

    const showListView = (goBack = false) => {
      let path = '/home';
      goBack = !!card._id;
      if (['sourcing', 'ordering', ...shippingStages].includes(card.stage)
        && ['Materials', 'Sourcing'].includes(card.__t)) {
        goBack = false;
      }
      if (window.history.length > 1 && goBack) {
        router.go(-1);
        return;
      }
      if (card.isPM()) {
        if ([...shippingStages, 'mixed'].includes(card._customStage)) {
          path = '/manager/detailing';
        } else path = `/manager/${card._customStage}`;
      } else if (card.isMM()) {
        if ([...shippingStages, 'mixed', 'rejected'].includes(card._customStage)) {
          path = '/materials/preparation';
        } else path = `/materials/${card._customStage}`;
      } else if (card.isPrefab()) {
        path = '/prefab/planning';
      } else if (card.isPO()) {
        path = '/prefab/coordination';
      } else if (card.isSourcing()) {
        if (shippingStages.includes(card.stage)) path = '/materials/ordering';
        else path = `/materials/${card.stage}`;
      }
      router.push({ path });
    };

    const moveCardToNextStage = (cardToMove) => {
      const cardData = _.castArray(cardToMove);
      const cardWithNoItems = cardData.filter((cD) => !cD.items.length);
      if (cardWithNoItems.length) {
        toast.error('Can not move selected orders, no items found');
      } else {
        // toggle modal
        ModalProgrammatic.open({
          component: MoveOrders,
          props: {
            type,
            cards: cardData,
            isActive: true,
            isMulti: false,
            kind: 'forward',
            templateProj: state.templateProject,
            templateOrders: state.templateOrders,
            initialCard,
            isCutOrder: cardToMove.isCutOrder,
            itemsCount: cardToMove.numberOfItems,
          },
          events: {
            close: (moved) => {
              if (moved) {
                showListView();
              } else {
                emit('reload-page');
              }
            },
          },
          canCancel: false,
          rootClass: store.state.activeScreen === 'material-edit-sourcing'
            ? 'modal-md' : 'modal-sm',
        });
      }
    };

    const checkOrderStatus = () => {
      if (card._customStage === 'manufacturing' && card.status === 'pause') {
        toast.error('Order is paused, cannot move to next stage');
        state.isLoading = false;
      }
      return !(card._customStage === 'manufacturing' && card.status === 'pause');
    };

    // save and move forward
    const saveAndMoveNext = _.debounce(async () => {
      state.isLoading = true;
      if (!card) emit('reload-page');
      catItemSync(card);
      if (['sourcing', 'ordering'].includes(card.stage) && route.params.cardId === 'add') {
        card = await saveCard(cardId, stage, card);
        const srcCard = (await MaterialManager.pullToSourcing([card._id]))[0];
        emit('changeCard', srcCard);
      }
      if (!_.isEmpty(card.materials) && card._bomCreated === true
       && props.itemQtyChanged === true && !card.hasGuid) {
        await bomQtyCheck();
      }
      if (card.stage === 'sourcing') {
        state.isLoading = false;
        if (_.some(card.items, (item) => !Validation.validateCatalogId(item.catId))) {
          toast.error('Catalog ID should be 4 to 32 character long');
          return;
        }
        if (!vendorMatchCheck()) {
          return;
        }
        if (_.isEmpty(card.baseDelivery.recipient)) {
          toast.error('Recipient is mandatory');
          return;
        }
        if (_.isEmpty(card.baseDelivery.location)) {
          toast.error('Delivery location cannot be Empty. Please select a delivery location');
          return;
        }
        if (card.items && card.items.length > 50) {
          toast.error(`Too many items to move this order.
           Please break it down into orders of 50 items or less, and then move.`);
          return;
        }
      }
      for (const item of card.items) {
        if (!_.isEmpty(item.measureUnits)
            && !_.isFinite(parseFloat(item.measure))
        ) {
          toast.error(`Please select measure for item ${item.name}`);
          state.isLoading = false;
          return;
        }
        if (!_.isFinite(parseFloat(item.leadTime))) {
          toast.error(
            `Lead time cannot be empty for item ${item.name}`,
          );
        }
        if (!_.isEmpty(item.simpleMemos)) {
          const memo = item.newFTD('memos');
          memo.text = item.simpleMemos[0].text;
        }
      }
      if (!checkOrderStatus()) return;
      if (card._customStage === 'manufacturing') {
        const shipMaterials = await card.materialsReadyToShip();
        if (!shipMaterials) {
          toast.error('Some of the materials are not reserved. Please reserve before moving.');
          state.isLoading = false;
          return;
        }
      }
      if (card._customStage === 'manufacturing' && _.get(card, 'purpose') === 'kit'
          && card.isLocked) {
        toast.error('Some Kit components are still in Manufacturing. Please advance them out of manufacturing and Try again');
        state.isLoading = false;
        return;
      }
      // if (card._customStage === 'manufacturing') {
      //   const shipMaterials = await card.materialsReadyToShip();
      //   if (!shipMaterials) {
      //     toast.error('Some of the materials are not reserved. Please reserve before moving.');
      //     state.isLoading = false;
      //     return;
      //   }
      // }
      if (['coordination', 'detailing'].includes(card.stage)) {
        if (_.some(card.catQtyMaps, (dt) => parseFloat(dt.qtyToConsume) === 0
        && parseFloat(dt.qtyToShip) === 0)) {
          toast.warning('Both Qty Consume and Qty ship cannot be 0.');
          state.isLoading = false;
          return;
        }
      }
      if (card.isPrefab() && card.purpose === 'kit') {
        const defaultItem = _.find(card.items, (item) => item.purpose === 'kit');
        _.set(defaultItem, 'name', card.name);
      }
      card = await getCardStatus(card);
      try {
        if (card.__t === 'Prefabs' && !card._id) state.isNewPrefab = false;
        if (card.isPrefab() || card.isMM()) {
          card = await saveCard(cardId, stage, card);
        } else if (card._customStage === 'qa') {
          card = await saveCard(cardId, stage, card);
          if (isCreateShipping) return createShipmentModal(card);
        } else {
          card = await saveCard(cardId, stage, card);
        }
        // handle bom and pick request creation
        const isDetail = _.some(card.items, (item) => ['detailing', 'coordination'].includes(item.stage));
        if (isDetail) card = await createBomAndPR(card);
        emit('change-qty-flag');
        moveCardToNextStage(card);
      } catch (e) {
        toast.error(e.data ? e.data.msg : null || e.message || e.msg || 'Something went wrong, please contact Manufacton Support');
      } finally {
        state.isLoading = false;
      }
    }, 1000, { leading: true, trailing: false });

    onBeforeMount(async () => {
      const stageMap = {
        materials: ['preparation', 'sourcing', 'ordering'],
        productionOrder: [
          'prefab',
          'po',
          'detailing',
          'manufacturing',
          'qa',
        ],
      };
      const stages = stageMap[
        ['Materials', 'Sourcing'].includes(card.__t)
          ? 'materials'
          : 'productionOrder'
      ];
      let curStage = type.split('-');
      curStage = curStage.length === 2 ? curStage[0] : curStage[curStage.length - 1];
      let curIdx = stages.indexOf(curStage);
      if (curIdx === stages.length - 1) {
        state.nextStage = 'shipping';
        return;
      }
      state.nextStage = stages[++curIdx];
      if (card.stage === state.nextStage) {
        state.nextStage = stages[++curIdx];
      }
      if (store.state.activeScreen === 'prefab-edit') {
        ({ templateProject: state.templateProject } = (await Projects.haveTemplateProject()));
        state.templateOrders = await SupplyChain.supplyChain({
          projectId: state.templateProject._id,
          module: 'ProdTemplates',
          filterNoItemOrders: false,
          limit: 9999,
          page: 1,
        });
      }
    });
    onMounted(async () => {
      await store.getters.userPromise;
      state.user = store.state.userData;
    });

    const createShipmentModal = async (cardData) => {
      state.isLoading = true;
      const inventoryItems = ['qa'].includes(cardData._customStage) ? await Helper.getMaterialItems(cardData) : [];
      if (_.some(inventoryItems?.data, (item) => _.isUndefined(item.reserved)) && ['qa'].includes(cardData._customStage)) {
        const catIds = cardData.reqForReserveQty(inventoryItems);
        await cardData.reserveCatalog(catIds, store.state.userData);
      }
      if (!checkOrderStatus()) return;
      if (cardData._customStage === 'manufacturing') {
        const shipMaterials = await cardData.materialsReadyToShip();
        if (!shipMaterials) {
          toast.error('Some of the materials are not reserved. Please reserve before moving.');
          state.isLoading = false;
          return;
        }
      }
      if (cardData._customStage === 'manufacturing' && _.get(cardData, 'purpose') === 'kit'
          && cardData.isLocked) {
        toast.error('Some Kit components are still in Manufacturing. Please advance them out of manufacturing and Try again');
        state.isLoading = false;
        return;
      }
      ModalProgrammatic.open({
        component: CreateShipping,
        props: {
          orders: [cardData],
        },
        events: {
          close: (moved) => {
            if (moved) {
              let currentURL = window.location.href;
              let redirect = "";
              if (currentURL.includes("manufacturing")) {
                redirect = "/manager/manufacturing";
              } else if(currentURL.includes("qa")) {
                redirect = "/manager/qa";
              }
              if (!_.isUndefined(redirect)) {
                router.push({ path: `${redirect}` });
              }
            }
          },
        },
        canCancel: false,
        rootClass: 'modal-sm',
      });
      state.isLoading = false;
    };

    const moveToKit = (async (kitCard) => {
      state.isLoading = true;
      await moveToKitLoc(kitCard, saveCard);
      showListView();
      state.isLoading = false;
    });

    const moveItemsToInventory = async () => {
      try {
        state.isLoading = true;
        if(!checkOrderStatus()) return;
        if (card._customStage === 'manufacturing' && card?.isCutOrder) {
          const someUnreserved = _.some(card.catQtyMaps, (item) => {
            const totalQtyToProcess = (item.qtyToConsume + item.qtyToShip + item.qtyToReturn);
            if (item.qtyReserved < totalQtyToProcess) {
              toast.error('Some of the materials are not reserved. Please reserve before moving.');
              return true;
            }
            return false;
          });

          if (someUnreserved) {
            return;
          }
        }


        ModalProgrammatic.open({
          component: MoveCompletedItems,
          props: {
            card: card,
          },
          events: {
            close: (moved) => {
              if (moved) {
                let currentURL = window.location.href;
                let redirect = "";

                if (currentURL.includes("manufacturing")) {
                  redirect = "/manager/manufacturing";
                } else if(currentURL.includes("qa")) {
                  redirect = "/manager/qa";
                }

                if (!_.isUndefined(redirect)) {
                  router.push({ path: `${redirect}` });
                }
              }
            },
          },
          canCancel: false,
          rootClass: 'modal-sm',
        })

      } catch (error) {
        console.error(error.message);
      } finally {
        state.isLoading = false;
      }
    };

    const hideSaveAndMoveNext = computed(() => {
      if (card.isCutOrder && ['manufacturing', 'mixed'].includes(card.stage) && card.isDirty) return true;
      if (card.__t === 'Prefabs' && card._customStage === 'planning') {
        return !_.some(card.items, { stage: 'planning' });
      }
      if (card.__t === 'Materials') return !_.some(card.items, { stage: 'preparation' });
      return ['ordering', ...shippingStages].includes(card._customStage);
    });

    const isCreateShipping = computed(() => {
      if (store.state.activeScreen === 'manager-edit-detailing') return false;
      if (card.__t === 'ProductionOrder' && _.some(card.items, (item) => ['manufacturing', 'qa'].includes(item.stage))
       && card.purpose !== 'assembly') {
        return true;
      }
      return false;
    });

    const isAssembly = computed(() => {
      if (card.__t === 'ProductionOrder' && ['manufacturing', 'qa'].includes(card.stage)
      && card.purpose === 'assembly') {
        return true;
      }
      return false;
    });

    const moveButtonName = computed(() => {
      const buttonTextObj = {
        planning: 'Create Orders In Coordination',
        sourcing: 'Create Purchase Order',
        manufacturing: 'Move To QA/QC',
      };
      if (buttonTextObj[card.stage]) return buttonTextObj[card.stage];
      if (state.nextStage === 'po') return 'Create Orders In Coordination';
      return `Move Order to ${state.nextStage}`;
    });

    const isShippingDisabled = computed(() => {
      if (props.card.status === 'pause') return true;
      if (stage === 'qa') return false;
      if (_.some(_.get(props.card, 'items', []), (item) => item.isCompleted)) return false;
      return (!_.some(_.get(props.card, 'manager.itemStatus', []), (item, key) => item.done && !_.find(_.get(props.card, 'items', []), (itm) => itm._id === key)?.markCompleteDisabled));
    });

    const isItemsCompleted = computed(() => {
      if (_.some(card.items, (i) => i.isCompleted === true)) return true;
      return _.some(card.items, (i) => i.isItemCompleted === true); // we have to change it back to isCompleted
    });

    // const getType = computed(() => {
    //   const res = _.get(screens[store.state.activeScreen], 'moduleKey', '');
    //   return res;
    // });

    const disableSave =computed(() => {
      if (card.owner.company._id !== store.state.userData.company) {
        return true;
      }
    })

    const disableSaveAndMove = computed(() => {
      const project = store.getters.findProject({ _id: card.project._id });
      if (project?.isTemplateProject
        && ['manufacturing', 'ordering'].includes(card._customStage)) return true;
      if (!filterItems.value.length) return true;
      if (card._customStage === 'planning') {
        return !_.some(card.items, { stage: 'planning' });
      }
      if (card._customStage === 'coordination') {
        return (card.owner.company._id !== state.user.company);
      }
      if (card.__t === 'Materials' && _.some(card.items, ['stage', 'rejected'])) {
        return false;
      }
      if (card.__t === 'Materials' && _.some(card.items, (item) => ['preparation', 'rejected'].includes(item.stage) && _.has(item, 'purchase') && item.purchase) && _.get(route, 'params.stage', '') === 'sourcing') return true;
      if (card.__t === 'Materials' && !_.some(card.items, (item) => ['preparation', 'rejected'].includes(item.stage) && (!_.has(item, 'purchase') || item?.purchase))) return true;
      if (card.stage === 'ordering') return false;
      if (card.__t === 'Materials' && card.stage !== 'preparation') return false;
      return shippingStages.includes(card._customStage);
    });

    const allowRejectedMove = computed(() => {
      if (store.state.activeScreen === 'material-edit-preparation' && stage === 'rejected') {
        return _.every(card.items, (item) => item.stage === stage);
      }
      if (store.state.activeScreen === 'material-edit-sourcing') {
        return _.some(card.items, (item) => item.stage === 'rejected');
      }
      return false;
    });

    const isDisabled = computed(() => {
      if (card.owner.company._id !== store.state.userData.company) {
        return true;
      }
      if (card.stage === 'manufacturing') {
        return isShippingDisabled.value;
      }
      if (props.card.status === 'pause') return true;
      return !card.items.length || allowRejectedMove.value
      || (!_.some(card.items, (item) => item.stage === stage))
      || disableSaveAndMove.value || isDisabledOnSaveMove(card);
    });

    onBeforeMount(async () => {
      if (_.isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
    });
    emitter.on('onFormSave', async (payload) => {
      await save();
      setTimeout(() => {
        router.go(0);
      }, 200);
    });

    return {
      ...toRefs(state),
      save,
      saveAndMoveNext,
      hideSaveAndMoveNext,
      isCreateShipping,
      createShipmentModal,
      isDisabled,
      isAssembly,
      moveToKit,
      moveButtonName,
      isShippingDisabled,
      permissionType,
      disableSave,
      moveItemsToInventory,
      isItemsCompleted,
    };
  },
});
</script>
