<template>
  <div
    class="
      has-background-grey-lighter has-text-weight-bold
      card-basic
      column
      p-0
    "
  >
    <div class="is-flex hero-header is-size-3 columns is-marginless mb-3">
      <div class="column is-6 is-flex is-align-items-flex-end">
        <i class="image has-text-white is-32x32" :class="getDynamicClasses">
        </i>
        <input class="input has-text-weight-bold" placeholder="Enter Shipping Label Name" v-model="sLabel.name" :disabled="$_.includes(['in-storage', 'fulfilled', 'zombie'], sLabel.status)
          && !disableFieldsForInstorage"/>
      </div>
    </div>
    <!-- FIRST ROW -->
    <div class="columns ">
      <div class="column pt-0 pb-0 is-5">
        <field-generic-input
          v-if="!isMultiDeliveries"
          :rowField="{isDisabled:$_.includes(['in-storage', 'fulfilled','zombie'], sLabel.status)
             && !disableFieldsForInstorage}"
          label="Tracking #"
          :isEditing="true"
          :value="sLabel.delivery?.trackingId"
          @update:value="(val) => $_.set(sLabel, 'delivery.trackingId', val)"
          placeHolder="Enter Tracking Number"
        ></field-generic-input>
        <div v-if="isMultiDeliveries">
          <label class="label has-text-black-bis is-italic is-size-3"> Tracking # </label>
          <div class="field-body line-height">
            (multiple)
          </div>
        </div>
      </div>
      <div class="column pt-0 pb-0 is-5 is-offset-2">
        <div class="columns">
          <div class="column">
            <field-stage
              v-show="!isCreateShipment"
              label="Status"
              :isEditing="true"
              :shipType="sLabel.shipType"
              :value="sLabel.status"
              :shippingItems="sLabel.items"
              :isStatus="true"
            ></field-stage>
          </div>
          <div class="column is-3">
            <field-date
              label="Earliest Onsite"
              :isEdit="false"
              :item="sLabel"
              :inputProps="getDateProps('minItemDelivery')"
            ></field-date>
          </div>
        </div>
      </div>
    </div>
    <!-- SENDER DETAILS -->
    <h4 class="title is-capitalized">Sender</h4>
    <div class="columns">
      <div class="column">
        <field-project-select
          titleLabel="Source Project"
          :value="sLabel.delivery?.currentProject"
          :options="currentProjectsList"
          :isEditing="true"
          :isDisabled="disableFieldsForInstorage || shipFromInventory || isExistingCard || isPartialShipment"
          @select="$_.set(sLabel, 'delivery.currentProject', $event)"
        >
        </field-project-select>
      </div>
      <div class="column">
        <field-location-select
          titleLabel="Current Location"
          :isEditing="true"
          :isDisabled="disableFieldsForInstorage || shipFromInventory || isExistingCard || isPartialShipment"
          :value="sLabel.delivery?.currentLocation"
          :options="projectLocations[sLabel.delivery?.currentProject._id] || []"
          @update:value="(val) => $_.set(sLabel, 'delivery.currentLocation', val)"
        ></field-location-select>
      </div>
      <div class="column is-5">
        <div class="columns">
          <div class="column">
            <field-user-select
              titleLabel="Owner"
              :isEditing="true"
              :disabled="($_.includes(['in-storage','fulfilled', 'zombie'], sLabel.status)
                && !disableFieldsForInstorage) || isPartialShipment"
              :key="allUsers.length"
              :options="allUsers || destUsers"
              :value="sLabel.delivery?.owner"
              @update:value="(val) => $_.set(sLabel, 'delivery.owner', val)"
            ></field-user-select>
          </div>
          <div class="column is-3">
            <field-date
              v-if="!isMultiDeliveries"
              label="Ship Date"
              :isEdit="!$_.includes(['in-storage', 'in-transit', 'zombie', 'fulfilled'],
                sLabel.status) || disableFieldsForInstorage || isPartialShipment"
              :item="sLabel"
              :inputProps="getDateProps('deliveryStart')"
            ></field-date>
            <div v-if="isMultiDeliveries">
              <label class="label has-text-black-bis is-italic is-size-3">Ship Date</label>
              <div class="field-body is-pulled-right mr-2">
                {{ mimShipDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- RECEIVER DETAILS -->
    <h4 class="title is-capitalized">Receiver</h4>
    <div class="columns">
      <div class="column">
        <field-project-select
          titleLabel="Destination Project"
          :value="getDeliveryProject()"
          :options="destProjList"
          :isEditing="true"
          @update:value="(v) => destinationProject(v)"
          @select="projectChanged($event)"
          :isDisabled="($_.includes(['in-storage', 'fulfilled', 'in-transit', 'zombie'],
            sLabel.status) && !disableFieldsForInstorage) || isPartialShipment"
        >
        </field-project-select>
      </div>
      <div class="column">
        <field-location-select
          :key="sLabel.delivery?.deliveryProject._id"
          titleLabel="Destination Location"
          :isEditing="true"
          :options="deliveryProjectLocationsList || []"
          :value="sLabel.delivery?.deliveryLocation"
          :projectId="sLabel.delivery?.deliveryProject._id"
          :currentProjectId="sLabel.delivery?.currentProject._id"
          :deliveryProjectId="sLabel.delivery?.deliveryProject._id"
          :companyId="user.company"
          :shipFromInventory="shipFromInventory"
          @update:value="(val) => $_.set(sLabel, 'delivery.deliveryLocation', val)"
          :isDisabled="($_.includes(['in-storage', 'fulfilled', 'zombie'], sLabel.status)
            && !disableFieldsForInstorage) || isPartialShipment"
        ></field-location-select>
      </div>
      <div class="column is-5">
        <div class="columns">
          <div class="column">
            <field-user-select
              titleLabel="Recipient"
              :isEditing="true"
              :key="allUsers.length"
              :options="allUsers || destUsers"
              :value="sLabel.delivery?.recipient"
              @update:value="(val) => $_.set(sLabel, 'delivery.recipient', val)"
              :disabled="($_.includes(['in-storage', 'fulfilled', 'zombie'], sLabel.status)
                && !disableFieldsForInstorage) || isPartialShipment"
            ></field-user-select>
          </div>
          <div class="column is-3">
            <field-date
              v-if="!isMultiDeliveries"
              label="Deliver Date"
              :isEdit="!(['fulfilled', 'in-storage', 'zombie'].includes(sLabel.status)
                && !disableFieldsForInstorage)"
              :item="sLabel"
              :inputProps="getDateProps('deliverBy')"
            ></field-date>
             <div v-if="isMultiDeliveries">
              <label class="label has-text-black-bis is-italic is-size-3">Deliver Date</label>
              <div class="field-body is-pulled-right mr-2">
                {{ latestDeliverDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="isCollapsed ? 'hide-column' : 'show-column'" class="mb-3">
      <div class="columns">
        <div class="column">
            <field-file-list :order="sLabel" prop="files" :isShipping="true" :refreshFunction="refreshFunction"></field-file-list>
        </div>
        <div class="column">
          <field-notes
          :value="sLabel.delivery?.notes"
          @update:value="(val) => $_.set(sLabel, 'delivery.notes', val)">
          </field-notes>
        </div>
        <div class="column">
          <also-notify :value="sLabel.alsoNotify"
            :options="projectUsers[sLabel.delivery?.deliveryProject._id]"
            @addNotifier="updateNotifiers"
            :title="title"></also-notify>
        </div>
      </div>
    </div>
    <div
      class="column card-collapse is-clickable"
      @click="isCollapsed = !isCollapsed"
    >
      <div class="is-size-5 has-text-weight-normal is-capitalized">
        <i
          class="icon-disclosearrow"
          :class="isCollapsed ? '' : 'rotate-180'"
        ></i>
        <span class="is-italic" v-if="isCollapsed">show details</span>
        <span class="is-italic" v-else>hide details</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  onMounted,
  onBeforeMount,
} from 'vue';
import constants from '@/constants';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import AlsoNotify from '@/components/card-edit/AlsoNotify.vue';
import _, { isUndefined, flatMap, cloneDeep, filter } from 'lodash';
import Projects from '@/models/Projects';
import ShippingLabel from '@/models/ShippingLabel';
import Users from '@/models/Users';
import Locations from '@/models/Locations';
import moment from 'moment';
import UtilityMixin from '../mixins/UtilityMixin';

export default {
  name: 'ShippingBasics',
  props: {
    shippingLabel: {
      type: Object,
      default: () => new ShippingLabel({
        name: '',
        purpose: 'general',
        customId: '',
        delivery: {},
        items: [],
        externalEmails: [],
      }),
    },
    type: String,
    projectUsers: Object,
    projectLocations: Object,
    shipFromInventory: Boolean,
    disableFieldsForInstorage: Boolean,
    refreshFunction: Function,
  },
  components: { AlsoNotify },
  setup(props) {
    const store = useStore();
    const { getAllLocations } = UtilityMixin();
    const state = reactive({
      config: {},
      user: {},
      isCollapsed: true,
      loadMoreDates: false,
      datesData: {},
      mfDateProps: {
        deliveryStart: {
          kind: '_delivery.deliveryStart',
          max: '_delivery.deliverBy',
        },
        deliverBy: {
          kind: '_delivery.deliverBy',
          min: '_delivery.deliveryStart',
        },
      },
      sLabel: props.shippingLabel,
      allProjects: [],
      currentProjectsList: [],
      deliveryProjectsList: [],
      isCreateShipment: false,
      title: 'Also Notify',
      deliveryProjectLocation: props.projectLocations,
      latestDeliverDate: '',
      mimShipDate: '',
      destinationProjects: [],
      allUsers: [],
      destUsers: [],
    });
    const router = useRouter();

    state.user = store.state.userData;
    state.config = constants[props.type].cardBasics;

    const getDynamicClasses = computed(() => {
      const routeMeta = router.currentRoute.value.meta;
      let classes = '';
      if (routeMeta.activeViewColor) {
        classes = `${routeMeta.activeViewColor} `;
      }
      if (routeMeta.cardEditIcon) {
        classes += `icon-${routeMeta.cardEditIcon}`;
      }
      return classes;
    });

    const getRecipient = computed(() => {
      let allUsers = props.projectUsers[state.sLabel.delivery?.currentProject._id]
              || props.projectUsers[state.sLabel.delivery?.deliveryProject._id];
      allUsers = filter(allUsers, (usr) => usr.company === store.state.userData.company)
      return Users.groupUsers(allUsers);
    });

    const getOwnerList = computed(() => {
      let allUsers;
      if (state.sLabel.delivery?.currentProject._id
        === store.state.commonStockProject) {
        allUsers = props.projectUsers[state.sLabel.delivery?.deliveryProject._id];
      } else {
        allUsers = props.projectUsers[state.sLabel.delivery?.currentProject._id];
      }
      allUsers = filter(allUsers, (usr) => usr.company === store.state.userData.company);
      return Users.groupUsers(allUsers);
    });

    const getDateProps = ((dateType) => {
      if (!isUndefined(state.mfDateProps[dateType])) return state.mfDateProps[dateType];
      return { kind: dateType };
    });
    const updateNotifiers = (notifyUsers) => {
      state.sLabel.alsoNotify = notifyUsers;
    };

    const fetchProjects = async () => {
      // fetching and setting of projects lists happens here
      state.allProjects = store.state.queryParams._projectsAndGI;
      if (!state.allProjects.length) {
        const projects = await Promise.all([
          Projects.allProjects(),
          Projects.allProjects({ isGI: true }),
        ]);
        state.allProjects = flatMap(projects);
      }
      if (props.shipFromInventory) {
        const commonStockProject = await Projects.getCommonStockProject();
        state.allProjects.unshift(commonStockProject);
      }
      state.currentProjectsList = cloneDeep(state.allProjects);
      state.deliveryProjectsList = cloneDeep(state.allProjects);
    };
    const fetchLocations = async (projectId, isGIProject) => {
      let locs = await getAllLocations(projectId, true);
      if (isGIProject) { _.remove(locs, (loc) => loc.type !== 'gi_location');}
      locs = _.partition(locs, (loc) => loc.nestedLocation)[+(projectId
              !== store.state.queryParams.commonStockProject._id)];
      return Locations.groupLocations(locs, store.state.companyData);
    };
    const deliveryProjectLocationsList = computed(() => {
      if (state.deliveryProjectLocation) {
        return state.deliveryProjectLocation[state.sLabel.delivery?.deliveryProject._id];
      } return [];
    });
    const projectChanged = async (project) => {
      if (props.shipFromInventory && project._id) {
        // set locations list
        if (_.isEmpty(state.deliveryProjectLocation[project._id])) {
          _.set(state.deliveryProjectLocation, project._id, []);
          const locs = await fetchLocations(project._id, project.isGI);
          if (locs.length) state.deliveryProjectLocation[project._id] = locs;
        }
      }
    };

    // check if multiple deliveries exist
    const isMultiDeliveries = computed(() => {
      const partialDeliveries = state.sLabel.partialShipments;
      if (partialDeliveries && partialDeliveries.length >= 2) {
        const maxDeliver = _.max(_.compact(_.map(partialDeliveries, '_delivery.deliverBy')));
        const mimShip = _.min(_.compact(_.map(partialDeliveries, '_delivery.deliveryStart')));
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.latestDeliverDate = maxDeliver ? moment(maxDeliver).format('MM/DD/YYYY') : '';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.mimShipDate = mimShip ? moment(mimShip).format('MM/DD/YYYY') : '';
        return true;
      }
      return false;
    });

    const isExistingCard = () => !_.isUndefined(_.get(props, 'shippingLabel._id', undefined));

    const isPartialShipment = computed(() => state.sLabel?.shipType === 'm');

    const getDeliveryProject = () => {
      if (_.isUndefined(state.sLabel._id)
        && props.shipFromInventory
        && _.isEmpty(state.sLabel.delivery?.deliveryProject) && !state.sLabel.delivery?.currentProject.isCommonStockProject) {
        _.set(state.sLabel, 'delivery.deliveryProject', state.sLabel.delivery?.currentProject);
        projectChanged(state.sLabel.delivery?.currentProject);
        return state.sLabel.delivery?.currentProject;
      }
      return state.sLabel.delivery?.deliveryProject;
    };
    const destProjList = computed(() => {
      if (state.sLabel.delivery.currentProject.isCommonStockProject) {
        return state.allProjects;
      }
      return state.destinationProjects;
    });
    const destinationProject = async (v) => {
      _.set(state.sLabel, 'delivery.deliveryProject', v);
      let users = await Projects.linkedProjectUsers([state.sLabel.delivery?.deliveryProject._id]);
      users = filter(users, (usr) =>
        usr.company === _.get(store.state.userData,'company._id', store.state.userData.company)
      )
      state.allUsers = await Users.groupUsers(users);
      return state.allUsers;
    };

    onMounted(async () => {
      fetchProjects();
      state.isCreateShipment = props.type === 'create-shipping';
      state.destUsers = await destinationProject(state.sLabel.delivery.deliveryProject);
    });

    onBeforeMount(async () => {
      if (!state.sLabel.delivery) {
        router.go(-1);
      }
      if (state.sLabel.delivery) {
        Object.keys(state.sLabel.delivery).map((key) => {
          if (state.sLabel.delivery[key] === '' && key !== 'trackingId') {
            state.sLabel.delivery[key] = {};
          } else {
            return state.sLabel.delivery[key];
          }
        });
      }
      state.destinationProjects.push(state.sLabel.delivery?.currentProject);
      let { commonStockProject } = store.state.queryParams;
      commonStockProject = !_.isEmpty(commonStockProject)
        ? commonStockProject : await Projects.getCommonStockProject();
      state.destinationProjects.push(commonStockProject);
    });

    return {
      ...toRefs(state),
      getDynamicClasses,
      getRecipient,
      getOwnerList,
      getDateProps,
      updateNotifiers,
      projectChanged,
      fetchLocations,
      deliveryProjectLocationsList,
      isExistingCard,
      isMultiDeliveries,
      isPartialShipment,
      getDeliveryProject,
      destProjList,
      destinationProject,

    };
  },
};
</script>

<style scoped>
.hide-column {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
.show-column {
  max-height: 650px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}
</style>
