<template>
  <o-modal
    :active="isActive"
    :canCancel="false"
    class="modal-sm hide-tab">
    <div class="modal-card">
      <section class="modal-card-body has-background-white" id="printJS-form">
        <div class="qc-form-container">
          <div class="column p-0">
            <div class="columns">
              <div class="column has-text-left" v-if="companyImageUrl">
                <img class="logo" :src="companyImageUrl"/>
              </div>
            </div>
            <div class="columns qc-header">
              <div class="column"><h3 class="is-size-3 has-text-weight-bold">{{form.name}}</h3></div>
              <div class="column"><h3 class="is-size-3 has-text-right">{{formStatus}}</h3></div>
            </div>
          </div>
          <div class="is-divider my-3"></div>
          
          <div class="qc-body">
            <div class="column px-0">
              <div class="columns mb-0">
                <div class="column">
                  <div class="pulled-left">
                    <span class="has-text-weight-bold is-size-3">Project: </span> 
                    <span class="is-size-3">{{projectName}}</span>
                  </div>
                  </div>
                <div class="columnt is-narrow justify-end">  <span class="has-text-weight-bold is-size-3">Company: </span>
                <span class="is-size-3 ">{{companyName}}</span>
                </div>
              </div>
               <div class="columns mb-0">
                <div class="column">
                  <div class="pulled-left"> 
                    <span class="has-text-weight-bold is-size-3">Production Order: </span>
                    <span class="is-size-3">{{orderName}}</span>
                  </div>
               </div>
                <div class="column is-narrow">
                  
                    <span class="has-text-weight-bold is-size-3">Production Start:  </span>
                    <span class="is-size-3 is-pulled-right">{{startDate ? getDate(startDate) : '__ /__ /____'}}</span>
              
                </div>
              </div>

               <div class="columns mb-0">
                <div class="column">
                  <div class="pulled-left">
                    <span class="has-text-weight-bold is-size-3">Work Step: </span>
                    <span class="is-size-3">{{runName}}</span>
                  </div>
               </div>
                <div class="column is-narrow ">  
                  <div class="is-pulled-right">
                    <span class="has-text-weight-bold is-size-3">Production End:  </span>
                    <span class="is-size-3">{{endDate ? getDate(endDate) : '__ /__ /____'}}</span>
                  </div>
               </div>
              </div>
            </div>
            
          </div>
          <div class="column px-0">
            <div class="columns">
              <div v-if="form?.note?.desc" class="qc-note column text-overflow clamp-10">
                Overview/Notes: {{form.note.desc}}
              </div>
            </div>
          </div>
          <div class="column px-0">
            <div class="columns">
              <div class="column">
                <h3 class="has-text-weight-bold is-size-5 is-italic mb-2"> Document and Photos</h3>
                <div v-for="doc of $_.orderBy(form.docs, ['name'], ['asc'])" :key="doc._id">
                  <div class="is-flex is-align-items-center mb-2" v-if="doc.type === 'url'">
                    <a :href="doc.url" target="_blank" download>{{ doc.name }}</a> 
                  </div>
                </div>
                <div v-for="doc of $_.orderBy(form.docs, ['name'], ['asc'])" :key="doc._id">
                  <div class="is-flex is-align-items-center mb-2" v-if="docFormatCheck(doc, true)">
                    <!-- <i class="fa fa-file mr-2 icon-size"></i> -->
                    <a :href="imageUrl(form.company, doc.url, true)" target="_blank" download>{{ doc.name }}</a> 
                  </div>
                </div>
                <div v-for="doc of $_.orderBy(form.docs, ['name'], ['asc'])" :key="doc._id">
                  <div  class="mb-2" v-if="docFormatCheck(doc, false)">
                    <div class="is-flex is-align-items-center">
                      <!-- <i class="fa fa-image mr-2 icon-size"></i> -->
                      <!-- <img src="attached.images.svg"> -->
                      <a :href="imageUrl(form.company, doc.url, true)" target="_blank" download>{{ doc.name }}</a> 
                    </div>
                    <div v-if="docFormatCheck(doc, false)">
                      <img class="form-image" :src="imageUrl(form.company, doc.url, true)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bt-line"></div>
          <div class="qc-checks">
            <div class="qc-header">
              QA/QC Checks ({{form.qaQcChecks.length}})
            </div>
            <div class="qc-check" v-for="(check, checkIdx) of form.qaQcChecks" :key="check._id">
              <div class="has-text-weight-bold mr-2">
                {{(checkIdx + 1).toString().padStart(3, '0')}}
              </div>
              <div class="check-description">
                <div class="qc-header">
                  {{check.desc}}
                </div>
                <div class="qc-task qc-unset"
                  v-for="(task, idx) in check.tasks.slice().reverse()"
                  :key="idx">
                  <div>
                    <span class="has-text-weight-bold mr-2">
                      {{(checkIdx + 1).toString().padStart(3, '0')}}
                      .{{(check.tasks.length - idx).toString().padStart(2, '0')}}:
                    </span>
                    <span class="has-text-weight-bold mr-2 is-capitalized">
                      <span v-if="!task.isRequired">Does Not Apply</span>
                      <span v-else-if="task.status === 'notStarted'">
                        <span class="mr-4">
                          <span class="qc-box"></span>
                          Pass
                        </span>
                        <span class="mr-4">
                          <span class="qc-box"></span>
                          Fail
                        </span>
                        <span>
                          <span class="qc-box"></span>
                          Does Not Apply
                        </span>
                      </span>
                      <span v-else>
                        {{task.status}}
                        <span class="is-pulled-right" v-if="!$_.isEmpty(task.lastModified)">
                          {{task.lastModified?.by?.user?.name}}
                          ({{$filters.fullDateTime(task.lastModified.at)}})
                        </span>
                      </span>
                    </span>
                  </div>
                  <div v-if="task.status === 'fail'" class="qc-column">
                    <div>
                      <div class="is-italic">Issue Type</div>
                      <div>{{task.errorType}}</div>
                    </div>
                    <div>
                      <div class="is-italic">Assign Task To</div>
                      <div>{{task.owner.name}}</div>
                    </div>
                  </div>
                  <div v-else-if="task.status === 'notStarted' && task.isRequired"
                    class="qc-column">
                    <div class="mr-3">
                      <div class="is-italic">Issue Type</div>
                      <div class="qc-underline">&nbsp;</div>
                    </div>
                    <div>
                      <div class="is-italic">Assign Task To</div>
                      <div class="qc-underline">&nbsp;</div>
                    </div>
                  </div>
                  <div v-if="check.componentType === 'qc'"  class="qc-column">
                    <div class="mr-3">
                      <div class="is-italic">Criteria</div>
                      <div v-if="check.criteria">{{check.criteria}}</div>
                      <div v-else class="qc-underline">&nbsp;</div>
                    </div>
                    <div class="mr-3">
                      <div class="is-italic">Target Value/Range</div>
                      <div v-if="check.targetVal">{{check.targetVal}}</div>
                      <div v-else class="qc-underline">&nbsp;</div>
                    </div>
                    <div>
                      <div class="is-italic">Measured Value</div>
                      <div v-if="task.meauredValue">{{task.meauredValue}}</div>
                      <div v-else class="qc-underline">&nbsp;</div>
                    </div>
                  </div>
                  <div v-if="task.isRequired">
                    <div class="is-italic">Notes</div>
                    <div v-if="!task.note" class="empty-task-note"></div>
                    <div v-else>{{task.note}}</div>
                    <div class="divider my-3"></div>
                  </div>
                  
                  <div class="column px-0">
                    <div class="columns">
                      <div class="column">
                        <h3 class="has-text-weight-bold is-size-5 is-italic mb-2"> Document and Photos</h3>
                        <div v-for="tdoc of $_.orderBy(task.docs, ['name'], ['asc'])" :key="tdoc._id">
                          <div class="is-flex is-align-items-center mb-2" v-if="tdoc.type === 'url'">
                            <a :href="tdoc.url" target="_blank" download>{{ tdoc.name }}</a> 
                          </div>
                        </div>
                        <div v-for="tdoc of $_.orderBy(task.docs, ['name'], ['asc'])" :key="tdoc._id">
                          <div class="is-flex is-align-items-center mb-2" v-if="docFormatCheck(tdoc, true)">
                            <!-- <i class="fa fa-file mr-2 icon-size"></i> -->
                            <a :href="imageUrl(form.project._id, tdoc.url, false)" target="_blank" download>{{ tdoc.name }}</a> 
                          </div>
                        </div>
                        <div v-for="tdoc of $_.orderBy(task.docs, ['name'], ['asc'])" :key="tdoc._id">
                        <div class="mb-2" v-if="docFormatCheck(tdoc, false)">
                          <div class="is-flex is-align-items-center mb-2">
                            <!-- <i class="fa fa-image mr-2 icon-size"></i> -->
                            <a :href="imageUrl(form.project._id, tdoc.url, false)" target="_blank" download>{{ tdoc.name }}</a> 
                          </div>
                          <div v-if="docFormatCheck(tdoc, false)">
                            <img class="form-image" :src="imageUrl(form.project._id, tdoc.url, false)"/>
                          </div>
                        </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
 
          <div v-if="form.signature.showComponent" class="qc-signature">
            <div class="is-size-3 has-text-black-bis">
              <span class="has-text-weight-bold ">Signature (required): </span>
              <span>(All QA/QC Checks should "PASS" before signing/submitting form.)</span>
            </div>
            <div class="qc-column is-size-3 has-text-black-bis">
              <div class="mr-3">
                <div class="qc-underline">&nbsp;</div>
                <div>Sign</div>
              </div>
              <div v-if="form?.signature?.owner?.name">
                <div>&nbsp;</div>
                <div class="has-text-centered">({{form?.signature?.owner?.name}})</div>
              </div>
                <div v-else class="mr-3">
                  <div class="qc-underline">&nbsp;</div>
                  <div>Print Name</div>
                </div>
              <div>
                <div v-if="form?.signature?.owner?.name">
                  {{getDate(form?.signature.submittedAt)}}
                </div>
                <div v-else class="qc-underline">&nbsp;</div>
                <div>Date</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </o-modal>
</template>

<script>
import { onMounted, reactive, toRefs, computed } from 'vue';
import _ from 'lodash';
import print from 'browser-print-js';
import moment from 'moment';
// eslint-disable-next-line import/no-webpack-loader-syntax
import css from '!!raw-loader!@/styles/form-print.no-parse.css';
import urls from '@/urls';
import { useStore } from 'vuex';

export default {
  name: 'QA/Qc print preview',
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    formStatus: {
      type: String,
      default: '',
    },
    projectName: {
      type: String,
      default: '',
    },
    orderName: {
      type: String,
      default: '',
    },
    runName: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      isActive: props.isActive,
    });

    onMounted(() => {
      print({
        type: 'html',
        printable: 'printJS-form',
        documentTitle: props.orderName, 
        style: css,
      });
      emit('close');
      state.isActive = false;
    });

    const getDate = (val) => moment(val).format('MM/DD/YYYY');

    const companyImageUrl = computed(() => {
      let url = '';
      const companyData = store.state.companyData;
      const fileGuid = _.get(companyData, 'logo.fileGuid', '');
      if (companyData._id && fileGuid !== '') {
        url = `${urls.s3}companies/${companyData._id}/${fileGuid}`;
      }
      return url;
    });

    const imageUrl = (id, docUrl, isCompany) => {
      let url = '';
      url = isCompany ? `${urls.s3}companies/${id}/${docUrl}` : `${urls.s3}projects/${id}/${docUrl}`;
      return url
    };

    const docFormatCheck = (doc, notImg) => {
      if (doc.type !== 'url') {
        const imgChck = doc.name.toLowerCase().endsWith('.jpg') || doc.name.toLowerCase().endsWith('.png') || doc.name.toLowerCase().endsWith('.jpeg');
        const docChck = !doc.name.toLowerCase().endsWith('.jpg') && !doc.name.toLowerCase().endsWith('.png') && !doc.name.toLowerCase().endsWith('.jpeg');
        const chck = notImg ? docChck : imgChck;
        return chck && doc.archived.value !== true;
      }
    };

    return {
      getDate,
      ...toRefs(state),
      companyImageUrl,
      imageUrl,
      docFormatCheck,
    };
  },
};
</script>

<style scoped>
  .hide-tab, .print-display {
    display: none !important;
  }
  .icon-size {
    width: 24px;
    height: 24px;
  }
</style>
