import JsPDF from 'jspdf';
import moment from 'moment';
import _ from 'lodash';
import { DialogProgrammatic } from '../Dialog';
import store from '../../store';
import Company from '../../models/Companies';

const defaultQRLabel = {
  shipping: [
    { label: 'Project', value: 'projectName' },
    { label: 'Proj ID', value: 'projectId' },
    { label: 'Order', value: 'orderName' },
    { label: 'Desc', value: 'orderId' },
    { label: 'Hide Field', value: 'hide' },
  ],
  item: [
    { label: 'Project', value: 'projectName' },
    { label: 'Proj ID', value: 'projectId' },
    { label: 'Item', value: 'itemName' },
    { label: 'Item ID', value: 'itemId' },
    { label: 'Cost Code', value: 'itemCostCode' },
  ],
  inventoryItem: [
    { label: 'Item', value: 'itemName' },
    { label: 'Item ID', value: 'customId' },
    { label: 'Catalog Id', value: 'catId' },
    { label: 'Cost Code', value: 'itemCostCode' },
  ],
  partial: [
    { label: 'Project', value: 'projectName' },
    { label: 'Proj ID', value: 'projectId' },
    { label: 'Shipment', value: 'name' },
    { label: 'Track ID', value: 'trackingId' },
  ],
  serial: [
    { label: 'Project', value: 'projectName' },
    { label: 'Proj ID', value: 'projectId' },
    { label: 'Order', value: 'orderName' },
    { label: 'Item', value: 'itemName' },
    { label: 'Item ID', value: 'itemId' },
  ],
  material: [
    { label: 'Project', value: 'projectName' },
    { label: 'Proj ID', value: 'projectId' },
    { label: 'Order', value: 'orderName' },
    { label: 'Submital', value: 'submittal' },
  ],
  materialItems: [
    { label: 'Project', value: 'projectName' },
    { label: 'Proj ID', value: 'projectId' },
    { label: 'Item', value: 'itemName' },
    { label: 'Item ID', value: 'itemId' },
    { label: 'Submittal', value: 'submittal' },
  ],
};

async function printQRPdf(qrCodes, User) {
  let customTemplate = await Company.qrLabel(User.company);
  if (_.isEmpty(customTemplate.shipping)) {
    customTemplate = defaultQRLabel;
  }
  const d = 5; // diff between two lines
  const br = 1; // extra line

  let pdfPageCount = 0;
  let pdf = new JsPDF('l', 'mm', [50.8, 101.6]);
  const pdfFontFamily = 'helvetica';
  pdf.setFont(pdfFontFamily);
  if (!_.isEmpty(qrCodes[0])) {
    _.forEach(qrCodes, (qrimg, k) => {
      if (pdfPageCount > 0) { pdf.addPage(); }
      let x = 4;
      let templateName = 'item';
      if (qrimg.type === 'basecard') {
        templateName = 'shipping';
      } else if (qrimg.type === 'baseMaterial') {
        templateName = 'material';
      } else if (qrimg.type === 'materialItem') {
        templateName = 'materialItems';
      } else if (_.isEqual(qrimg.type, 'shipping')) {
        templateName = 'partial';
      } else if (_.isEqual(qrimg.type, 'serial') || _.isEqual(qrimg.type, 'psItem')) {
        templateName = 'serial';
      } else if (_.isEqual(qrimg.type, 'inventoryItem')) {
        templateName = 'inventoryItem';
      }
      let chosenTemplate = {};
      if (_.compact(customTemplate[templateName]).length
                !== defaultQRLabel[templateName].length) {
        chosenTemplate = defaultQRLabel[templateName];
      } else {
        chosenTemplate = customTemplate[templateName];
      }
      // let count = 0;
      if (!_.isEmpty(chosenTemplate)) {
        // _.each(chosenTemplate, (val) => {
        //   if (val.value !== 'hide') {
        //     count += 1;
        //   }
        // });
        // if (count <= 2) {
        //   pdf.setFontSize(10);
        // } else if (count === 3) {
        //   pdf.setFontSize(8);
        // } else {
          pdf.setFontSize(7);
        // }
        _.each(chosenTemplate, (val) => {
          if (!_.isEmpty(qrimg) && !_.isEmpty(val.value) && val.value !== 'hide') {
            x = x + d + br;
            pdf.setFont(pdfFontFamily, 'bold');
            let value = val.label;
            if (value.length > 55) { value = `${value.substring(0, 75)}...`; }
            let itemTitle = pdf.splitTextToSize(`${value}:`, 40);
            if (val.value === 'orderId' && value.toLowerCase() === 'desc' && itemTitle[0].includes(':')) {
              itemTitle[0] = itemTitle[0].replace(':', '.:');
            }
            pdf.text(44, x, itemTitle);
            pdf.setFont(pdfFontFamily, 'normal');
            value = '';
            if (!_.isEmpty(qrimg) && val.value === 'itemId' && qrimg.type === 'inventoryItem' && qrimg.customId) {
              value = qrimg.customId.toString();
            }
            if (!_.isEmpty(qrimg) && !_.isEmpty(qrimg[val.value])) {
              value = qrimg[val.value].toString();
            }
            if (value.length > 55) {
              value = `${value.substring(0, 55)}...`;
            }
            itemTitle = pdf.splitTextToSize(value, 41);
            pdf.text(56, x, '');
            pdf.text(57, x, itemTitle);
          }
        });
      }
      if (!_.isEmpty(qrimg.imageData)) {
        pdf.addImage(qrimg.imageData, 'png', 1, 1, 42, 42);
      }
      if (!_.isEmpty(qrimg.companyLogo)) {
        pdf.addImage(qrimg.companyLogo, 'png', 5, 42, 35, 8);
      }
      if (!_.isEmpty(qrimg.appLogo)) {
        pdf.addImage(qrimg.appLogo, 'png', 89, 42, 6, 6);
      }
      if ((qrimg.type === 'psItem'
                || qrimg.type === 'serial'
                || qrimg.type === 'item'
                || qrimg.type === 'materialItem')
                && !_.isEmpty(qrimg.itemIcon)) {
        pdf.addImage(qrimg.itemIcon, 'png', 80, 42);
      } else if (!_.isEmpty(qrimg.typeIcon)) {
        pdf.addImage(qrimg.typeIcon, 'png', 80, 42);
      }
      if (_.isEqual(qrimg.type, 'serial')) {
        pdf.text(44, 38, 'Serial: ');
        pdf.text(55, 38, qrimg.itemSerial);
      }
      if (_.isEqual(qrimg.type, 'serial') || _.isEqual(qrimg.type, 'psItem')) {
        pdf.text(44, 42, 'QTY: ');
        pdf.text(55, 42, qrimg.itemQuantity.toString());
      }
      if (!_.isUndefined(qrimg.itemCount)) {
        pdf.text(59, 45, qrimg.itemCount);
      }
      if (!_.isUndefined(qrimg.itemMeasure)) {
        if (_.isEqual(qrimg.type, 'serial') || _.isEqual(qrimg.type, 'psItem')) {
          pdf.text(44, 46, 'MEAS');
          pdf.text(59, 46, qrimg.itemMeasure);
          pdf.text(73, 46, qrimg.itemMeasureUnits);
        } else {
          pdf.text(44, 49, 'MEAS');
          pdf.text(59, 49, qrimg.itemMeasure);
          pdf.text(73, 49, qrimg.itemMeasureUnits);
        }
      }
      pdfPageCount += 1;
      if (k > 1 && (k % 1000) === 0) {
        pdf.save(`ManufactOn-${moment().format()}.pdf`);
        pdf = new JsPDF('l', 'mm', [50.8, 101.6]);
        pdf.setFontSize(10);
        pdfPageCount = 0;
      }
    });
  }
  pdf.save(`ManufactOn-${moment().format()}.pdf`);
}

export default {
  async printQrLabel(qrCodes, User) {
    const { companyLogo } = qrCodes[0];
    if (store.state.activeScreen === 'shipping-order-view') qrCodes = _.sortBy(qrCodes, (code) => code.viewIndex);
    if (_.isEmpty(companyLogo)) {
      const confirmParam = {
        title: _.upperCase('company logo missing'),
        message: 'You haven\'t customized QR label in Manufacton Settings yet! Do you still want to proceed?',
        okButton: 'Print QR Now',
        type: 'black-bis',
        cancelButton: 'Add Logo',
        onConfirm: async () => {
          await printQRPdf(qrCodes, User);
        },
        onClose: () => {
          window.location.href = '#/settings/projects';
        },
      };
      DialogProgrammatic.confirm(confirmParam);
    } else {
      await printQRPdf(qrCodes, User);
    }
  },
};
