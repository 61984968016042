<template>
  <div>
    <o-modal :active="isActive" :canCancel="false" class="modal-sm">
      <div class="modal-card">
        <header class="modal-card-head">
          <h4 class="modal-card-title">Split Order: {{card.name}}</h4>
          <div class="is-divider-vertical"></div>
          <i class="icon-close is-clickable" @click="$emit('close')"></i>
        </header>
        <section class="modal-card-body is-size-2 is-marginless">
          <div class="columns">
            <div class="column">
              <div class="field">
                <div class="field-label">
                  <label class="label">Split selected items into new Order</label>
                </div>
                <div class="field-body">
                  <input v-model="splitObj.name" class="input" type="text"
                        placeholder="Enter split order name">
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <div class="field-label">
                  <label class="label">Project</label>
                </div>
                <div class="field-body">
                  <div class="line-height has-text-black-bis">
                    {{card.project.name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <search-bar
            :shouldEmit="true"
            @search="getSearchValue"
            placeholder="Search"
          >
          </search-bar>
          <div class="table-container">
            <mf-table
              :tableProps="tableProps"
              :apiMode="false"
              :loadData="loadData"
              :hideGutter="true"
              @checkbox-toggled="selectedRows"
              ref="itemTable"
            >
            </mf-table>
          </div>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
          <button class="button is-outlined" @click="cancel()">Cancel</button>
          <button
            class="button has-background-black-bis"
            @click="splitOrder()"
            :disabled="isEmpty(splitObj.name) || selectedItems.length <= 0"
          >Split</button>
        </footer>
      </div>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
    </o-modal>
  </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs, ref,
} from 'vue';
import { isEmpty, map } from 'lodash';
import MfTable from '@/components/table-fields/MfTable.vue';
import tableDefinition from '@/components/table-cols/splitOrderCols';
import { useToast } from 'vue-toastification';
import Order from '@/models/Orders';
import Material from '@/models/MaterialManager';
import ProductionManager from '@/models/ProductionManager';
import SearchBar from '@/components/SearchBar.vue';

export default defineComponent({
  name: 'SplitCard',
  components: {
    MfTable,
    SearchBar,
  },
  props: {
    card: Object,
    isActive: Boolean,
  },
  setup(props, { emit }) {
    const tableProps = tableDefinition;
    const toast = useToast();
    const itemTable = ref(null);
    const state = reactive({
      searchKey: '',
      splitObj: {
        name: `${props.card.name} - Split`,
      },
      selectedItems: [],
      isLoading: false,
    });
    const loadData = () => (state.searchKey === '' ? props.card.items : props.card.items.filter(
      (opt) => opt.name.toLowerCase().includes(state.searchKey.toLowerCase()),
    ));
    const cancel = () => {
      emit('close');
    };
    const selectedRows = (rows) => {
      state.selectedItems = rows;
    };
    const getSearchValue = (searchText) => {
      state.searchKey = searchText;
      itemTable.value.refreshTable();
    };
    const splitOrder = async () => {
      state.splitObj.name = state.splitObj.name.trim();
      const { card: orderData } = props;
      if (state.splitObj.name.length < 3) {
        // Todo add dialog box here
        toast.error('Card name must contain at least 3 characters!');
        return;
      }
      if (state.selectedItems.length === orderData.items.length) {
        // Todo add dialog box here
        toast.error('Uncheck at least one Order item for this card');
        return;
      }
      try {
        state.isLoading = true;
        const splitObj = {
          _id: orderData._id,
          name: state.splitObj.name,
          projectId: orderData.project._id,
          project: orderData.project,
          carryOverItems: map(state.selectedItems, '_id'),
        };
        if (orderData.isSourcing()) {
          await Material.split(splitObj);
        } else if (orderData.isPO()) {
          await Order.split(splitObj);
        } else if (orderData.isPM()) {
          await ProductionManager.split(splitObj);
        }
        cancel();
        state.isLoading = false;
        emit('refresh-table');
      } catch (e) {
        console.log('error', e);
      }
    };
    return {
      ...toRefs(state),
      tableProps,
      loadData,
      isEmpty,
      cancel,
      selectedRows,
      splitOrder,
      getSearchValue,
      itemTable,
    };
  },
});
</script>
<style lang="scss" scoped>
.o-table__th, .o-table th:first-child {
  width: 20px;
}
::v-deep(.o-table tbody) {
  min-height: 378px !important;
  max-height: 378px !important;
}
</style>
