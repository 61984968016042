<template>
    <div>
        <slot name="activity-icon"></slot>
         <transition name="slide">
            <div v-if="isActiveComponent" class="activity is-pulled-right">
                <aside>
                    <div class="card">
                        <header class="card-header has-background-grey-lighter">
                            <label class="card-header-title has-text-black-bis">Order Dates</label>
                            <div class="is-divider-vertical"></div>
                            <span class="card-header-icon" aria-label="more options"
                              @click="closeSildeOut">
                                <i class="icon-close"></i>
                            </span>
                        </header>
                        <div class="card-content">
                          <span class="has-text-black-bis is-size-3">{{card.name}}</span>
                          <div
                            :key="data.label">
                             <base-column :fields="data.fields"
                                :card="card"
                                kind="dates"
                              >
                              </base-column>
                          </div>
                        </div>
                    </div>
                </aside>
            </div>
         </transition>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { computed } from '@vue/reactivity';
import BaseCardColumn from '@/components/card-edit/BaseCardColumn.vue';

export default defineComponent({
  name: 'DateComponent',
  props: [
    'isActive',
    'data',
    'card',
  ],
  components: {
    'base-column': BaseCardColumn,
  },
  setup(props, { emit }) {
    const isActiveComponent = computed({
      get: () => props.isActive,
      set: (val) => emit('update:isActive', val),
    });
    const closeSildeOut = () => {
      isActiveComponent.value = false;
    };
    return {
      isActiveComponent,
      closeSildeOut,
    };
  },
});
</script>

<style scoped>
.activity {
  width: 320px;
  top: 122px;
  max-height: 328px !important;
}
.card {
  height: auto !important;
  min-height: 328px !important;
  max-height: 344px !important;
}
</style>
