<template>
 
  <div :class="activeIndex !== 2 ? 'modal-xs' : 'modal-md'">
  <div class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        <div class="columns">
          <div class="column">
            <a  v-if="activeIndex !== 0" class="is-pulled-left mr-2">
              <span class="icon is-left">
                <i class="fa fa-1x fa-arrow-left" @click="resetOrders()"></i>
              </span>
            </a>
            <span v-if="activeIndex !== 2">
             <h4 class="modal-card-title">{{ selectedKind.label ||
                (tab === 'msMassUpdate' ? 'Edit On-site Dates' : 'Edit Dates') }} </h4>
             </span>
             <h4 v-else  class="modal-card-title">
               Edit Dates: Conflicts
             </h4>
          </div>
        </div>
      </div>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="$emit('close')"
      :class="selectedKind.label || 'is-block'"
                ></i>
    </header>
    <div class="modal-card-body is-size-4" v-if="activeIndex === 0">

        <!-- options -->
        <div v-for="option in updateMethods" :key="option.shortHand">
          <a class="is-centered" @click="selectOption(option)">
            <div class="column gray-on-hover">
              <div class="columns">
                <!-- <div class="column is-1">
                  <span class="control has-icons-left icon">
                    <i class="fa-2x has-text-black" :class="option.icon"></i>
                  </span>
                </div> -->
                <div class="column">
                  <span class="is-size-3 has-text-black has-text-weight-semibold mb-2 is-block">
                    {{option.label}}
                  </span>
                  <p class="has-text-grey is-size-4">{{option.subtext}}</p>
                </div>
              </div>
            </div>
          </a>
        </div>
    </div>
    <div v-else class="modal-card-body is-visible">
      <o-loading :active-sync="isLoading" :full-page="false" :can-cancel="false"></o-loading>
      <!-- dates -->
      <dates-update
       v-if="activeIndex === 1"
       :selectedKind="selectedKind"
       :tab="tab"
       :cards="cards"
       :isLoading = 'showProgress'
       ref="datesUpdateRef"
       @toggleBtn="(val) => enableUpdate = val"
       @updated-lists="callInvalidListModal($event)"
      >
      </dates-update>

       <div v-else>
         <div class="is-flex is-align-items-center">
           <i class="icon-alert h2 has-text-danger"></i>
             <span class="is-size-4 has-text-weight-bold has-text-black-bis">
              Updating the following order will cause date conflicts.
            </span>
         </div>
        <invalid-list
            :data="invalid"
            :type="tab === 'msMassUpdate' ? 'ms-mass-update-dates' : type">
         </invalid-list>
        <span class="is-size-5 line-height has-text-weight-bold">
          Click 'Ok' to update the remaining
        <u style='color: green'>{{valid.length}}</u> order(s).</span>
      </div>

       <div v-if="showProgress">
          <span class="has-text-centered is-size-5 line-height has-text-info has-text-weight-bold">
              <progress class="progress is-info is-large mt-3" :value="doneCount"
                        :max="validCount"></progress>
                {{doneCount}}/{{validCount}} Saving {{labelToDisplay}}...
          </span>
       </div>
    </div>
    <footer class="modal-card-foot is-justify-content-flex-end" v-if="activeIndex !== 0">

     <span v-if="activeIndex !==2">
        <button class="button is-outlined"
          @click="restore()">Cancel</button>

        <button class="button has-background-black-bis"
          :disabled="showProgress || !enableUpdate"
          @click="update()"
          >Update ({{countToDisplay}}) Orders
        </button>
      </span>

      <span v-else>
        <button class="button button is-outlined"
          v-if="activeIndex === 2"
          @click="restore()">Cancel</button>

          <button class="button has-background-black-bis"
            @click="saveChanges()" :disabled="showProgress">
            Ok
          </button>
      </span>
    </footer>
  </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue';
import _ from 'lodash';
import DatesUpdate from '@/components/modals/DatesUpdate.vue';
import InvalidList from '@/components/modals/InvalidList.vue';
import Todo from '@/models/Todo';
import { useToast } from 'vue-toastification';

export default {
  props: {
    cards: Array,
    tab: String,
    type: String,
  },
  components: {
    'dates-update': DatesUpdate,
    'invalid-list': InvalidList,
  },
  setup({ cards, tab }, { emit }) {
    const state = reactive({
      isLoading: false,
      showProgress: false,
      activeIndex: 0,
      selectedKind: {},
      enableUpdate: false,
      countToDisplay: cards.length,
      invalid: [],
      valid: [],
      doneCount: 0,
      validCount: 0,
      failCount: 0,
      labelToDisplay: tab === 'clMassUpdate' ? 'Checklist(s)' : 'Order(s)',
      dateUpdateMethods: [
        {
          shortHand: 'byHand',
          label: 'Edit dates by hand',
          subtext: "Manually change the order's dates",
          desc: 'Enter new date(s) below. Leave blank to keep the current value.',
          icon: 'icon-edit',
          screensToShow: ['psMassUpdate', 'clMassUpdate', 'msMassUpdate'],
        },
        {
          shortHand: 'shiftDays',
          label: 'Add or subtract days',
          subtext: 'Move dates up or down a set number of days',
          desc: 'Selected order dates will move up or down these many days:',
          icon: 'fa fa-calendar-plus',
          screensToShow: ['psMassUpdate', 'clMassUpdate', 'msMassUpdate'],
        },
        {
          shortHand: 'offset',
          label: 'Use order offset dates',
          subtext: 'Enter one new date; other dates will shift according to the existing offsets',
          desc: 'Enter one new date; other dates will shift according to the existing offsets',
          icon: 'fa fa-arrow-alt-circle-right',
          screensToShow: ['psMassUpdate'],
        },
      ],
      updateMethods: [],
    });
    const toast = useToast();
    const datesUpdateRef = ref();

    state.updateMethods = _.filter(state.dateUpdateMethods,
      (mtd) => mtd.screensToShow.includes(tab));

    const selectOption = (opt) => {
      state.selectedKind = opt;
      state.activeIndex = 1;
    };

    const resetOrders = () => {
      state.enableUpdate = false;
      state.activeIndex = 0;
      state.selectedKind = {};
      _.forEach(cards, (card, index) => {
        if (cards[index] && cards[index]._beforeEdit) {
          _.set(cards, index, cards[index]._beforeEdit);
        }
      });
    };
    const restore = () => {
      emit('close');
      resetOrders();
      emit('restoreFromMassUpdate');
    };

     const updateOrders = async (order, type) => {
      try {
        if (type === 'clMassUpdate') {
          await Todo.updateTodo(order);
          state.doneCount += 1;
        } else {
          // we clear memos so that in mass update it wont be doubled
          order.memos = [];
          await order.saveNaked();
          // await order.slimSave(false);
          state.doneCount += 1;
        }
      } catch (e) {
        console.log('Error: ', e);
        state.failCount += 1;
      }
    };

    const saveChanges = async () => {
      if (state.valid.length > 0) {
        state.showProgress = true;
        state.validCount = state.valid.length;
        const chunkedOrders = _.chunk(state.valid, 25);
        for (const cOrders of chunkedOrders) {
          const promises = [];
          try {
            for (const order of cOrders) {
              if (tab === 'clMassUpdate') {
                promises.push(updateOrders(order, tab));
              } else if (['Sourcing', 'Materials'].includes(order.__t)) {
                const maxLeadTime = _.max(_.compact(_.map(order.items, 'leadTime'))) || 0;
                const orderByDate = order.getDateDiff(order.simpleDates.deliver.value, maxLeadTime);
                order.massUpdate = true;
                order.addOrUpdateDate('orderBy', orderByDate);
                for (const item of order.items) {
                  item.addOrUpdateDate('orderBy', orderByDate);
                }
                promises.push(updateOrders(order));
              } else {
                promises.push(updateOrders(order));
              }
            }
            await Promise.all(promises);
          } catch (e) {
            console.log('Error: ', e);
          }
        }
        if (state.failCount === 0) {
          toast.success(`${state.validCount} ${state.labelToDisplay} saved successfully!`);
        }
        if (state.doneCount !== state.validCount) {
          toast.error(`Only ${state.doneCount} ${state.labelToDisplay} saved out of ${state.validCount}`);
        }
      } else {
        toast.error(`No ${state.labelToDisplay} to save.`);
      }
      setTimeout(() => {
        state.showProgress = false;
        restore();
      }, 1000);
    };

    const callInvalidListModal = (data) => {
      state.invalid = data.invalid;
      state.valid = data.valid;
    };

    const update = async () => {
      if (datesUpdateRef.value) datesUpdateRef.value.validateOrders(cards);
      if (!state.invalid || state.invalid.length === 0) saveChanges();
      else state.activeIndex = 2;
    };

    return {
      ...toRefs(state),
      datesUpdateRef,
      selectOption,
      update,
      restore,
      resetOrders,
      callInvalidListModal,
      saveChanges,
    };
  },
};
</script>
<style scoped>

/* need to remove this color code after getting mock for hover color */
.gray-on-hover:hover{
  background-color: #DFDEDE;
}

.modal-xs .modal-card {
  width: 360px !important;
}

</style>
