export default {
  defaultSortField: 'todos.text',
  defaultSortOrder: 'asc',
  stickeyHeaders: true,
  rearrangeCols: false,
  detailRowComponent: 'mf-detail-row',
  customRowKey: 'uid',
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      tooltip: 'Customize Columns',
      isLine: true,
    }],
  },
  permissionModule: (screen) => (screen === 'planning' ? 'prefabs' : 'production-order'),
  toolBar: {
    // name: for drop down label, label: for action button label
    hideSearchBar: false, // by default let the search bar be available for the table
  },
  fields: [
    {
      name: 'field-generic-input',
      sortField: 'todos.text',
      width: '400px',
      sticky: true,
      title: 'Task',
      prop: 'todos.text',
      id: 'todos.text',
      // alwaysVisible: true,
      defaultVisible: true,
    },
    {
      name: 'field-generic-input',
      sortField: 'todos.type',
      width: '192px',
      title: 'Type',
      prop: 'todos.type',
      id: 'todoTypes',
      // alwaysVisible: true,
      defaultVisible: true,
      filterText: 'Types',
      searchable: true,
      isDisabled: true,
      inputProps: {
        disabled: true,
      },
    },
    {
      name: 'field-date',
      sortField: 'todos.dueDate',
      width: '152px',
      title: 'Due Date',
      prop: 'todos.dueDate',
      id: 'todos.dueDate',
      // alwaysVisible: true,
      defaultVisible: true,
      searchable: true,
      dateFilter: 'todo',
      inputProps: {
        kind: 'todos.dueDate',
        disableEdit: false,
      },
    },
    {
      name: 'priority',
      sortField: 'todos.priority',
      width: '152px',
      title: 'Priority',
      prop: 'todos.priority',
      id: 'todos.priority',
      // alwaysVisible: true,
      defaultVisible: false,
    },
    {
      name: 'todoStatus',
      sortField: 'todos.status',
      width: '152px',
      title: 'Status',
      prop: 'todos.status',
      id: 'todoStatus',
      // alwaysVisible: true,
      defaultVisible: true,
      isStatus: true,
      isStaticColor: true,
      filterText: 'Status',
      searchable: true,
    },
    {
      name: 'field-generic-input',
      sortField: 'project',
      width: '296px',
      title: 'Project',
      prop: 'project.name',
      id: 'project',
      // alwaysVisible: true,
      defaultVisible: true,
      filterText: 'Projects',
      searchable: true,
      isDisabled: true,
    },
    {
      name: 'field-user-select',
      sortField: 'todos.assignedTo.user',
      width: '296px',
      title: 'Assignee',
      prop: 'todos.assignedTo.user',
      id: 'assignee',
      filterText: 'Assignee',
      searchable: true,
      // alwaysVisible: true,
      defaultVisible: true,
      cardProps: {
        companyId: 'owner.company._id',
        projectId: 'project._id',
        isEditing: 'isEditing',
      },
    },
    {
      name: 'field-generic-input',
      sortField: 'name',
      width: '296px',
      title: 'Source',
      prop: 'name',
      id: 'name',
      defaultVisible: true,
      isDisabled: true,
      isClickable: true,
      eventType: 'openCard',
      alwaysVisible: true,
      searchable: false,
    },
    {
      name: 'field-cl-private-icon',
      width: '64px',
      title: '',
      prop: 'todos.private',
      id: 'todos.private',
      customHeader: true,
      // alwaysVisible: true,
      defaultVisible: true,
      inputProps: {
        icon: 'far fa-lock',
      },
    },
    {
      name: 'field-item-docs-icon',
      width: '64px',
      title: 'Docs',
      prop: 'todos.files',
      id: 'todos.files',
      // alwaysVisible: true,
      defaultVisible: true,
    },
    {
      name: 'field-notes-icon',
      sortField: 'todos.notes',
      width: '64px',
      title: 'Notes',
      prop: 'todos.notes',
      id: 'todos.notes',
      // alwaysVisible: true,
      defaultVisible: false,
    },
    {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      width: '112px',
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      permissionModule: 'checklist',
      noRequiredCheck: true,
      sticky: true,
      hideFromChooser: true,
      alwaysVisible: true,
      searchable: true,
      clearBtn: true,
      inputProps: {
        showEdit: true,
      },
    },
  ],
};
