<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <div class="list-view">
    <TableGutter
      :selectedAction="isBulkActionEnabled"
      :tools="tableProps.gutterOpts"
      @cc-modal-closed="triggerSetHeaderHeight"
      @re-render-table="rerenderMfTable += 1"
    >
      <template v-slot:showComplete>
        <div class="column is-narrow is-flex pr-0">
          <o-field>
            <o-switch class="is-size-3 has-text-black-bis is-italic"
              v-model="showComplete"
              @update:modelValue="refreshTable">
              Show Completed Tasks
            </o-switch>
          </o-field>
          <div class="is-divider-vertical"></div>
        </div>
      </template>
    </TableGutter>
    <mf-table
      ref="tasksTable"
      :tableProps="tableProps"
      :loadData="loadData"
      :screenTable="true"
      :hideGutter="true"
      :tableName="stageFromUrl"
      @cell-clicked="onCellClicked"
      :key="rerenderMfTable"
    >
      <template v-slot:todoStatus="{ rowData, rowField }">
        <mf-multi-select
          v-if="rowData.isEditing"
          :modelValue="$_.get(rowData, rowField.prop)"
          :options="getTodoStatuses"
          @update:modelValue="(val) => updateTodoStatus(val, rowData, rowField.prop)"
        />
        <field-stage v-else
          :value="$_.get(rowData, rowField.prop, '')"
          :isStaticColor="$_.get(rowField, 'isStaticColor', true)"
          :isStatus="$_.get(rowField, 'isStaticColor', true)"
        />
      </template>
      <template v-slot:type="{ rowData }">
        <span v-if="rowData.isEditing">
          <mf-multi-select v-model="rowData.selectType"
            :options="todoTypesCheck"
            :closeOnSelect="true"
            :show-labels="false"
            :disabled="todoType==='pause'"
            :multiple="false"
            label="title"
            :hideSelected="false"
            @update:modelValue="selectionDone('type', $event, rowData)">
          </mf-multi-select>
        </span>
        <span v-else>{{rowData.todos.type}}</span>
      </template>
    </mf-table>
  </div>
  <transition name="slide">
    <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedItem"
        @close="closeNotesSlider"
        @update:note="updateNotes($event)"
      >
    </notes-icon-slider>
  </transition>
  <transition name="slide">
    <item-document-slider
      v-if="isFileListVisible"
      :isActive="isFileListVisible"
      :rowData="selectedItem"
      :rowField="rowField"
      :isDisabled="true"
      @close="closeDocumentSlider"
      >
    </item-document-slider>
  </transition>
  <activity-log
    :isActive="isActive"
    :id="activityDetails.id"
    :projectId="activityDetails.projectId"
    :orderName="activityDetails.orderName"
    @update:isActive="isActive=false">
  </activity-log>
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  ref,
  inject,
  onBeforeUnmount,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import _ from 'lodash';
import tableDefinition from '@/components/table-cols/tasksCols';
import TableGutter from '@/components/TableGutter.vue';
import screens from '@/constants';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import ActivityLog from '@/components/ActivityLog.vue';
import SupplyChain from '@/models/SupplyChain';
import Prefab from '@/models/Prefabs';
import Todo from '@/models/Todo';
import uuid from 'uuid/v4';

export default {
  name: 'tasks',
  components: {
    MfTable,
    ActivityLog,
    TableGutter,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
  },
  setup(props) {
    const store = useStore();
    const emitter = inject('emitter');
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const tasksTable = ref(null);
    const state = reactive({
      isLoading: false,
      isActive: false,
      isTableReady: true,
      tableProps: tableDefinition,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      todoTypes: [
        { title: 'Planning', value: 'Planning', icon: 'book' },
        { title: 'Coordination', value: 'Coordination', icon: 'icon icon-production-order' },
        { title: 'Detailing', value: 'Detailing', icon: 'edit' },
        { title: 'Manufacturing', value: 'Manufacturing', icon: 'icon icon-contractor' },
        { title: 'Constraint', value: 'Constraint', icon: 'unlink' },
        { title: 'Purchase', value: 'Purchase', icon: 'shopping-cart' },
        { title: 'QA/QC', value: 'Quality Assurance', icon: 'check-square' },
        { title: 'Delivery', value: 'Delivery', icon: 'truck' },
        { title: 'Pause', value: 'Pause', icon: 'pause' },
        { title: 'Material', value: 'Material', icon: 'cubes' },
        { title: 'Complete', value: 'Complete', icon: 'trophy' }],
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedItem: {},
      rowField: {},
      todoData: {},
      totalDataCount: 0,
      showComplete: false,
      rerenderMfTable: 0,
    });

    const stageFromUrl = computed(() => route.path.split('/').pop());
    const permissionType = () => {
      const res = _.get(screens[store.state.activeScreen], 'permissionModule', '');
      if (res === 'shipping') return 'scm';
      return res;
    };
    const getAllProjects = computed(() => {
      const permModule = (permissionType() === 'scm')
        ? 'shipping' : permissionType();
      return store.getters.getProjectsOnPermission(permModule, store.state.queryParams._projects, 'data');
    });
    const groupedProjects = computed(() => {
      const projects = getAllProjects.value;
      return projects;
    });

    const refreshTable = () => {
      tasksTable.value.refreshTable();
    };

    const loadData = async (commonParam) => {
      const params = {
        showPrivate: true,
        projectId: store.getters.selectedIdsForKey('filteredProjects'),
        companyId: store.getters.selectedIdsForKey('companies', false),
        private: store.getters.selectedTodoPrivacy,
        selectedTypes: store.getters.selectedValuesForKey('todoTypes', false),
        status: store.getters.selectedValuesForKey('todoStatus'),
        userId: store.getters.selectedIdsForKey('assignee', false),
        ...commonParam,
        ...store.getters.selectedDatesForKeys([
          'todoStartDate',
          'todoEndDate',
        ]),
      };
      if (store.state.activeScreen === 'PMChecklist') {
        params.isManager = true;
      }

      if (state.showComplete) {
        params.status = ['Complete'];
      }
      state.todoData = await Todo.getTodo(params);
      for (const todo of state.todoData.data) {
        todo.uid = uuid();
        const todoValueObj = _.find(state.todoTypes, { value: todo.todos.type });
        _.set(todo, 'selectType', _.find(state.todoTypes, {
          title: todoValueObj.title, value: todoValueObj.value,
        }));
      }
      state.todoData.total = state.todoData.count;
      state.totalDataCount = state.todoData.total;
      return state.todoData;
    };

    const validateChecklist = (checklist) => {
      if (checklist.text === '') {
        toast.error('Please enter Checklist description');
        return false;
      }
      if (!checklist.status) {
        toast.error('Please enter Checklist status');
        return false;
      }
      if (!checklist.type) {
        toast.error('Please enter Checklist type');
        return false;
      }
      if (!checklist.dueDate) {
        toast.error('Please enter Checklist Due Date');
        return false;
      }
      return true;
    };

    const saveRow = async (checkList) => {
      if (!validateChecklist(checkList.todos)) { return; }
      try {
        await Todo.updateTodo(checkList);
        toast.success('Checklist updated');
        refreshTable();
      } catch (error) {
        toast.success('Error updating Checklist: please contact ManufactOn support');
      }
    };

    const onCellClicked = async (event) => {
      if (event.type === 'save') {
        _.set(event.data, 'isEditing', !event.data.isEditing);
        await saveRow(event.data);
      } else if (event.type === 'openCard') {
        let module = '';
        let stage = '';
        let order;
        const card = event.data;
        const projectId = _.get(card.project, '_id', '');
        const cardId = _.get(card, '_id', '');
        const moduleName = ['Sourcing', 'Materials', 'ProductionOrder', 'Prefabs'].includes(card.__t) ? 'baseOrder' : '';
        if (card.stage === 'planning') {
          const queryParams = {
            projectId,
            prefabId: cardId,
          };
          order = await Prefab.get(queryParams);
        } else if (moduleName === 'baseOrder' && card.stage !== 'planning') {
          const queryParams = {
            projectId,
            orderId: cardId,
            showCutOrders: 1
          };
          const orders = await SupplyChain.supplyChain(queryParams);
          order = orders.data[0];
        } else if (moduleName === 'shippingLabel' && card.stage !== 'planning') {
          module = 'shipping';
        }
        if (order.isPrefab()) module = 'prefab';
        else if (order.isPO()) module = 'order';
        else if (order.isPM()) module = order.isCutOrder ? 'cut' :'manager';
        else if (order.isMM() || card.__t === 'Sourcing') module = 'material';
        stage = order.getCardStage();
        const routeParams = { projectId, cardId };
        if (['manager', 'material', 'sourcing', 'cut'].includes(module)) routeParams.stage = stage;
        router.push({
          name: `${module}-edit`,
          params: routeParams,
        });
      }
    };

    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      state.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      state.isFileListVisible = false;
    };

    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      // eslint-disable-next-line no-unused-expressions
      tasksTable?.value?.closeColumnChooserModal();
      // eslint-disable-next-line no-unused-expressions
      tasksTable?.value?.refreshFilterOpts();
    };

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      state.selectedItem = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      state.selectedItem = payload.data;
      state.rowField = payload.rowField;
      state.isFileListVisible = payload.isActive;
    });

    const getTodoStatuses = computed(() => store.getters.selectedValuesForKey('todoStatus'));
    const todoTypesCheck = computed(() => {
      for (const key in state.todoTypes) {
        if (state.todoTypes[key].title === 'Material' && ['ProductionOrder', 'Prefabs'].includes(props.rowData?.__t)) delete state.todoTypes[key];
      }
      return _.filter(state.todoTypes);
    });
    const updateTodoStatus = (val, rowData, field) => {
      _.set(rowData, field, val);
    };

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });
    const selectionDone = (selectionType, opt, row) => {
      row.todos[selectionType] = opt.value;
    };
    return {
      ...toRefs(state),
      loadData,
      onCellClicked,
      tasksTable,
      stageFromUrl,
      groupedProjects,
      refreshTable,
      triggerSetHeaderHeight,
      closeNotesSlider,
      closeDocumentSlider,
      getTodoStatuses,
      updateTodoStatus,
      todoTypesCheck,
      selectionDone,
    };
  },
};
</script>

<style scoped lang="sass"></style>
