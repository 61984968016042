import { get } from 'lodash';
import GenerateRequest from './resources';

const req = GenerateRequest();
const defaultColorScheme = {
  prefab: '#375686',
  complete: '#489b64',
  coordination: '#f3b54a',
  detailing: '#e89d78',
  manufacturing: '#fe8137',
  qa: '#a64d00',
  sourcing: '#867437',
  delivery: '#6f3000',
  inTransit: '#43aac7',
  fulfilled: '#489b64',
  notStarted: '#4d93ff',
  inStorage: '#1470ff',
  preparation: '#fff000',
  rejected: '#fff000',
  ordering: '#c0ab42',
  fieldverify: '#867437',
  mixColor: '#5e467b',
  catalogue: '#818278',
  zombie: '#489b64',
  releasedToInventory: '#3e3edc',
  mixDelivery: '#5e467b',
  consumed: '#BD9BB5',
  noWorkflow: '#FF392E',
  pending: '#FDFD96',
  submitted: '#77DD77',
  approved: '#46b846',
  approvedAsNoted: '#20d020',
  forRecordOnly: '#059c05',
  reviseAndResubmit: '#cd5d57',
  void: '#7b3734',
};
let colorScheme = null;

export default {
  async getOne(id) {
    return (await req.get('companies/get', { params: { id } })).data;
  },
  defaultColorSchemes() {
    return defaultColorScheme;
  },
  async colorSchemes(id) {
    try {
      colorScheme = (await req.get('companies/getColorSchemes', { params: { company: id } })).data;
      return { ...defaultColorScheme, ...colorScheme };
    } catch (e) {
      console.log(e);
      return defaultColorScheme;
    }
  },
  async qrLabel(id) {
    return (await req.get('companies/getQrLabel', { params: { company: id } })).data;
  },
  async save(company) {
    return (await req.post('companies/update', { company })).data;
  },
  async updateMeasureUnit(company) {
    return (await req.post('companies/updateMeasureUnit', { company })).data;
  },
  async updateQaqcTypes(company) {
    return (await req.post('companies/updateQaqcTypes', { company })).data;
  },
  async updateSecurity(company) {
    return (await req.post('companies/updateSecurity', { company })).data;
  },
  async saveColor(colors) {
    return (await req.post('companies/saveColorScheme', colors)).data;
  },
  async saveQrLabel(data) {
    return (await req.post('companies/saveQrLabel', data)).data;
  },
  async updateCompanyUserRelation(data) {
    return (await req.post('companies/updateCompanyUserRelation', data)).data;
  },
  async sendActivationMail(params) {
    return (await req.post('companies/sendActivationMail', params)).data;
  },
  async getCompanyRuns(params) {
    return (await req.get('companies/getCompanyRuns', { params: { type: params } })).data;
  },
  async resetPassword(params = {}) {
    try {
      const user = await req.post('/accounts/resetPassword', params);
      return user.data;
    } catch (e) {
      throw e;
    }
  },
  async isRunUsed(params) {
    try {
      const { data } = await req.post('companies/isRunUsed', params);
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async usedLocations() {
    try {
      const { data } = await req.get('companies/usedLocations', {});
      return data.usedLocations;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async reNameRun(params) {
    return (await req.post('companies/reNameRun', params)).data;
  },
  async updateItemMeasureUnit(params) {
    return (await req.post('companies/updateItemMeasureUnit', params)).data;
  },
  async getAvailablePermissions() {
    return (await req.get('companies/getAvailablePermissions')).data;
  },
  async getPermissionGroups() {
    return (await req.get('companies/getPermissionGroups')).data;
  },
  async createUpdatePermissionGroups(params) {
    const res = await req.post(`companies/updatePermission/${params.id}`, params);
    return res;
  },
  async deletePermissionGroup(groupId) {
    const res = await req.delete(`companies/deletePermission/${groupId}`);
    return res;
  },
  async getPermissionGroupCount() {
    return (await req.get('companies/getPermissionGroupCount')).data;
  },
  async getTags(params) {
    try {
      const { data } = await req.get('tags', { params });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async addTags(params) {
    try {
      const { data: newTag } = await req.post(params.project ? `tags/add/${params.project}` : 'tags/add', params);
      return newTag;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async addQaQcTypes(params) {
    try {
      const { data: newTag } = await req.post(params.project ? `qaqcTypes/add/${params.project}` : 'qaqcTypes/add', params);
      return newTag;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async updateTag(params) {
    try {
      const pId = get(params, 'project._id');
      const updatedTag = await req.post(pId ? `tags/edit/${pId}` : 'tags/edit', params);
      return updatedTag;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async archiveTag(tagId, pId = null) {
    try {
      const { data: archivedTags } = await req.post(pId ? `tags/archive/${pId}/${tagId}` : `tags/archive/${tagId}`);
      return archivedTags;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async resetPermissionGroup(id) {
    try {
      const { data: updatedGroup } = await req.post(`companies/resetPermissionGroup/${id}`);
      return updatedGroup;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
