<template>
  <div class="login-tag">
    <div class="header is-vertical-center">
      <figure class="level-item has-text-centered is-centered">
        <img src="./../assets/logo-login.png" />
      </figure>
    </div>
    <div class="login-main is-flex is-justify-content-center is-align-items-center">
      <div class="card">
        <header class="card-header has-background-white-ter is-size-5">
          <p
            class="card-header-title is-uppercase has-text-dark is-justify-content-center"
          >
            {{ $t('set password') }}
          </p>
        </header>
        <div class="card-content">
          <div class="field">
            <p class="control">
              <input
                class="input"
                type="text"
                v-model.trim="user.firstName"
                placeholder="First Name"
              >
            </p>
          </div>
          <div class="field">
            <p class="control">
              <input
                class="input"
                type="text"
                v-model.trim="user.lastName"
                placeholder="Last Name"
              >
            </p>
          </div>
          <div class="field" v-if="!user.viewOnlyMember">
            <p class="control">
              <input
                class="input"
                type="text"
                :disabled="isOwner"
                v-model.trim="user.companyName"
                placeholder="Company Name"
              >
            </p>
          </div>
          <div class="field">
            <p class="control">
              <input
                class="input"
                type="password"
                v-model="user.newPassword"
                placeholder="Choose Password"
                minlength="8"
              >
            </p>
          </div>
          <div class="field">
            <p class="control">
              <input
                class="input"
                type="password"
                v-model="user.repeatPassword"
                placeholder="Verify Password"
              >
            </p>
          </div>
          <div class="is-offset-one-quarter">
            <p class="has-text-centered has-text-weight-semibold">
              <o-checkbox v-model="acceptTerms" required></o-checkbox>By logging in, you accept the
              <a
                href="https://www.manufacton.com/terms-of-use/"
                target="_blank" class="has-text-info"
              >Terms and Conditions</a> and
              <a
                href="https://www.manufacton.com/manufacton-privacy-policy/"
                target="_blank"
              >Privacy Policy</a>
            </p>
          </div>
        </div>
        <footer
          class="card-footer has-background-white-bis is-justify-content-flex-end -small"
        >
        <a @click="activate()" class="button login-button is-uppercase is-primary">Set</a>
        </footer>
      </div>
    </div>
  </div>
  <o-loading
    :full-page="true"
    :active="isLoading"
    :can-cancel="true"
  ></o-loading>
</template>

<script>
import {
  reactive, onMounted, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { pick, isEmpty } from 'lodash';
import User from '@/models/Users';
import { useToast } from 'vue-toastification';
import { DialogProgrammatic } from '@/components/Dialog';

export default {
  name: 'userActivation',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const state = reactive({
      isOwner: true,
      acceptTerms: false,
      isLoading: false,
      email: '',
      key: '',
      user: {
        firstName: '',
        lastName: '',
        companyName: '',
        key: '',
      },
    });
    const getUserData = (async () => {
      try {
        state.isLoading = true;
        state.email = route.query.email;
        state.key = route.query.key;
        const result = await User.getUserToActivate(state.email, state.key);
        if (!result.viewOnlyMember) {
          state.isOwner = result._id !== result.company.owner;
        }
        state.user = pick(result, ['firstName', 'lastName', 'companyName', 'viewOnlyMember']);
        state.user.key = state.key;
        state.user.email = state.email;
      } catch (err) {
        if (err.data.message) {
          const confirmParam = {
            title: 'Error',
            message: err.data.message,
            okButton: 'Ok',
            type: 'danger',
            onConfirm: async () => {
              router.push({ path: '/login' });
            },
            rootClass: 'danger-dialog is-header-danger',
          };
          DialogProgrammatic.confirm(confirmParam);
        }
        console.log(err);
      } finally {
        state.isLoading = false;
      }
    });
    const activate = (async () => {
      if (isEmpty(state.user.firstName)) { return toast.warning('First Name can not be empty!'); }

      if (isEmpty(state.user.lastName)) { return toast.warning('Last Name can not be empty!'); }

      if (!state.user.viewOnlyMember && isEmpty(state.user.companyName)) { return toast.warning('Company Name can not be empty!'); }

      if (isEmpty(state.user.newPassword) || state.user.newPassword.length < 8) { return toast.warning('Your password must be at least 8 characters.'); }

      if (state.user.newPassword !== state.user.repeatPassword) { return toast.warning('Please check the Passwords'); }

      if (state.acceptTerms === false) {
        return toast.error('Please read and accept the Terms & Conditions and Privacy Policy');
      }
      state.isLoading = true;

      try {
        await User.activateAccount(state.user);
        toast.success('User activated');
        router.push({ path: '/login' });
      } catch (err) {
        state.isLoading = false;
        if (err) {
          let errMessage = '';
          if (err.data && err.data.message) {
            errMessage = err.data.message;
          } else errMessage = 'Error in Update';
          return toast.error(errMessage);
        }
      }
      state.isLoading = false;
      return true;
    });
    onMounted(async () => {
      await getUserData();
    });
    return {
      ...toRefs(state),
      activate,
    };
  },
};
</script>

<style scoped>
</style>
