<template>
  <VDropdown :autoHide="false">
      <div v-if="!isDisabled" class="is-relative note-count is-clickable">
        <span> {{notifyUserCount}} </span>
        <i class="icon-subscribe is-size-3"></i>
      </div>
     <template #popper>
        <div class="card">
          <header class="card-header has-background-grey-lighter">
            <h4
              class="
                card-header-title
                is-size-3
                has-text-black-bis
                is-capitalized
                has-text-weight-bold
                is-paddingless
              "
            >
              {{ title }}
            </h4>
          </header>
          <div class="card-content is-marginless has-background-white">
            <div class="tags-body">
              <div v-for="(option, key) in userOptions" :key="key" class="mb-3">
                <h4
                  class="
                    title
                    is-size-4
                    has-text-weight-bold has-text-black-bis
                    line-height
                  "
                >
                  {{ key }}
                </h4>
                 <div class="control has-icons-left mb-3">
              <input v-model="searchToken" class="input" type="text" placeholder="Search" />
              <span class="icon is-small is-left">
                <i class="icon-search"></i>
              </span>
            </div>
                <button v-for="user in option" :key="user.id"
                  class="button is-fullwidth is-flex is-justify-content-space-between"
                  :class="
                    checkIfPresent(user.id)
                      ? 'has-background-black-bis has-text-white'
                      : 'is-outlined'
                  "
                  @click="updateValue(user)"
                >
                  <span class="is-size-3 text-clamp text-overflow">{{ user.name }}</span>
                  <span class="icon is-small">
                    <i :class="checkIfPresent(user.id) ? 'icon-removedelete' : 'icon-addnew'"></i>
                    </span>
                </button>
              </div>
            </div>
          </div>
          <footer
            class="
              card-footer
              is-justify-content-flex-end
              has-background-grey-lighter
            "
          >
            <div>
              <button @click="sendNotification"
                v-close-popper="true"
                class="button has-background-black-bis has-text-white is-pulled-right"
              >
                Done
              </button>
            </div>
          </footer>
        </div>
      </template>
    </VDropdown>
  </template>

<script>

import {
  reactive, toRefs, computed, inject, onBeforeMount,
} from 'vue';
import { groupBy, isEmpty, uniqBy } from 'lodash';

export default {
  name: 'NotifyIconField',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    title: String,
    rowData: Object,
    rowField: Object,
  },
  setup(props, { emit }) {
    const data = reactive({
      isOpen: true,
      notifyUsers: props.value,
      shippingLabel: props.rowData,
      searchToken: '',
    });
    const notifyUserCount = computed(() => {
      return uniqBy(data.notifyUsers, '_id')?.length;
    });
    const isDisabled = computed(() => {
      if (isEmpty(props.rowField?.isDisabled)) {
        if (typeof props.rowField?.isDisabled === 'function') {
          return props.rowField.isDisabled(props.rowData);
        }
        return props.rowField.isDisabled;
      }
      return false;
    });

    const userOptions = computed(() => {
      let opts = props.options;
      if (data.searchToken.length) {
        opts = props.options.filter((j) => j.name.toUpperCase()
          .includes(data.searchToken.toUpperCase())
            || j.email.toUpperCase().includes(data.searchToken.toUpperCase()));
      }

      opts = groupBy(opts, 'companyName');

      return opts;
    });

    const methods = {
      updateValue(user) {
        if (
          !data.notifyUsers
            .map((i) => i.id)
            .includes(user.id)
        ) {
          data.notifyUsers.push(user);
        } else {
          this.removeEmp(user);
        }
      },
      removeEmp(user) {
        data.notifyUsers = data.notifyUsers.filter((i) => i.id !== user.id);
        data.shippingLabel._delivery.notify = data.shippingLabel._delivery.notify.filter((i) => i.id !== user.id);
      },

      checkIfPresent(userId) {
        if (
          !data.notifyUsers
            .map((i) => i.id)
            .includes(userId)
        ) return false;
        return true;
      },

      sendNotification() {
        data.shippingLabel.alsoNotify = data.notifyUsers;
        emit('sendNotify', data.shippingLabel);
      },
    };

    return {
      ...toRefs(data),
      isDisabled,
      ...methods,
      userOptions,
      notifyUserCount,
    };
  },
};

</script>
