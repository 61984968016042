<template>
  <div>
    <VDropdown  placement="bottom" :shown="isModalVisible" :autoHide="false">
      <template #popper>
        <div class="card">
          <header class="card-header has-background-grey-lighter">
            <div
              class="card-header-title is-size-3 has-text-black-bis
                  is-capitalized has-text-weight-bold is-paddingless"
            >
                {{ title }}
            </div>
            <div class="is-divider-vertical"></div>
            <i class="icon-close is-clickable" @click="isModalVisible=false"></i>
          </header>
          <section class="card-content is-marginless has-background-white p-2">
            <div class="columns is-marginless">
              <div class="column">
                <search-bar
                  :shouldEmit="true"
                  @search="getSearchValue($event)"
                  placeholder="Search"
                >
                </search-bar>
              </div>
            </div>
            <div class="table-container" v-if="isModalVisible">
              <mf-table
                :tableProps="tableProps"
                :loadData="loadData"
                :hideGutter="true"
                @row-select="getSelectedRows($event)"
                @checkbox-toggled="getSelectedRows"
                ref="itemTable"
                :checkedRows="selectedItems"
                :key="isModalVisible"
                :selectedRow="selectedRow"
              />
            </div>
          </section>
          <footer
            class="card-footer is-justify-content-flex-end has-background-grey-lighter"
          >
            <button class="button is-outlined mr-2" @click="isModalVisible=false">Cancel</button>
            <button
              class="button has-background-black-bis has-text-white"
              @click="addItems()"
              :disabled="!selectDisable"
            >
              Select
            </button>
          </footer>
        </div>
        <o-loading
          :full-page="false"
          :active="isLoading"
          :can-cancel="false"
        ></o-loading>
      </template>
    </VDropdown>
 </div>
</template>
<script>
import {
  computed, defineAsyncComponent, reactive, ref, toRefs,
} from 'vue';
import tableDefinition from '@/components/table-cols/catalogListCols';
import subituteItem from '@/components/table-cols/subtituteItemCols';
import { cloneDeep, isEmpty } from 'lodash';
import Catalogs from '@/models/Catalogs';
import SearchBar from '@/components/SearchBar.vue';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default {
  name: 'AddFromCatalogPopover',
  components: {
    'mf-table': MfTable,
    SearchBar,
  },
  props: {
    module: String,
    projectId: String,
    companyId: String,
    maxAddable: Number,
    isSubstitute: Boolean,
    substituteItem: String,
    title: String,
    isVisible: Boolean,
  },
  setup(props, { emit }) {
    const itemTable = ref(null);
    const data = reactive({
      tableProps: props.isSubstitute ? subituteItem : tableDefinition,
      searchKey: '',
      maxCount: props.maxAddable ? props.maxAddable : 500,
      selectedItems: [],
      isLoading: false,
      tableData: [],
      loadTable: 0,
      selectedRow: {},
    });

    const isModalVisible = computed({
      get: () => props.isVisible,
      set: (val) => { emit('close', val); },
    });

    const close = () => {
      emit('close', false);
      data.tableData = [];
    };

    const msgToDisplay = computed(() => `You can select ${data.maxCount - data.selectedItems.length} more items`);
    const selectDisable = computed(() => !isEmpty(data.selectedRow)
     || !isEmpty(data.selectedItems));
    const getSelectedRows = (items) => {
      data.selectedItems = items;
    };
    const loadData = async () => {
      const queryParams = {
        search: data.searchKey,
        type: 'parts',
        limit: 9999,
      };
      const result = await Catalogs.getAssemblyParts(queryParams);
      return result;
    };
    const getSearchValue = async (searchText) => {
      data.searchKey = searchText;
      await itemTable.value.refreshTable();
    };
    const addItems = () => {
      const selectedItems = cloneDeep(data.selectedItems);
      emit('selected-rows', selectedItems);
      close();
      data.selectedItems = [];
    };

    return {
      ...toRefs(data),
      isModalVisible,
      itemTable,
      msgToDisplay,
      selectDisable,
      getSelectedRows,
      loadData,
      getSearchValue,
      addItems,
    };
  },
};
</script>
<style scoped>
.card {
  width: 760px !important;
}
::v-deep(.o-table tbody) {
  min-height: 200px !important;
  max-height: 200px !important;
}
</style>
