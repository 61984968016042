<template>
  <div class="modal-card qc-form-modal">
    <header>
      <div class="modal-card-head">
        <h4 class="modal-card-title">
          QA/QC - Work Step: <span v-if="preview" class="has-text-grey-dark">[autofill]</span>
          <span v-else>{{rowData?.name}}</span>
        </h4>
        <button class="button p-0 mx-3" v-if="!preview && !fromTask"
          v-tooltip="'Remove QA/QC Form'">
          <i class="icon-removedelete is-clickable" @click="deleteForm()"></i>
        </button>
        <div class="is-divider-vertical" v-if="!preview"></div>
        <button class="button p-0 mx-3" v-if="!preview">
          <i class="icon-notifyusers is-clickable" @click="notifyUsers"></i>
        </button>
        <button class="button p-0 mx-3" v-if="!preview">
          <i class="icon-print is-clickable" @click="printForm"></i>
        </button>
        <span v-if="preview" class="is-size-3 has-text-primary has-text-weight-bold">
          (PREVIEW)
        </span>
        <div class="is-divider-vertical"></div>
        <button class="button p-0">
          <i class="icon-close is-clickable" @click="cancel()"></i>
        </button>
      </div>
    </header>
    <section
      class="modal-card-body has-background-white p-0 is-visible qa-body">
      <div class="has-text-black-bis column">
        <div class="columns">
          <div class="column">
            <div class="line-height mb-2">
              <em class="has-text-weight-bold">Prod. Order: </em>
              <span v-if="preview" class="has-text-grey-dark">[autofill]</span>
              <span v-else>{{card.name}}</span>
            </div>
            <div class="line-height">
              <em class="has-text-weight-bold">Project: </em>
              <span v-if="preview" class="has-text-grey-dark">[autofill]</span>
              <span v-else>{{card.project.name}}</span>
            </div>
          </div>
          <div class="is-divider-vertical"></div>
          <div class="column is-2">
            <div class="line-height mb-2 is-flex is-justify-content-space-between">
              <em class="has-text-weight-bold">Start</em>
              <span v-if="preview" class="has-text-grey-dark">[autofill]</span>
              <field-date v-else
                :inputProps="{ kind: 'coord', disableEdit: true }"
                :isEdit="false"
                :isModal="true"
                :rowData="rowData"/>
            </div>
            <div class="line-height is-flex is-justify-content-space-between">
              <em class="has-text-weight-bold">End</em>
              <span v-if="preview" class="has-text-grey-dark">[autofill]</span>
              <field-date v-else
                :inputProps="{ kind: 'deliver', disableEdit: true }"
                :isEdit="false"
                :isModal="true"
                :rowData="rowData"/>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-border-top">
        <div class="columns">
          <div class="column">
            <span class="line-height is-flex is-align-items-center">
              <span class="has-text-weight-bold mr-3">Overall QA/QC Status: </span>
              <span v-if="preview" class="is-flex is-align-items-center">
                <i class="icon-qcnotstarted"></i>
                <span> QC Not Started</span></span>
              <span v-else class="is-flex is-align-items-center">
                <i :class="[getFormStatus?.color, getFormStatus?.icon]" class="mr-1"></i>
                <span>{{getFormStatus?.label}}</span>
              </span>
            </span>
          </div>
          <div class="is-divider-vertical"></div>
          <div class="column">
            <span class="line-height is-flex is-justify-content-space-between">
              <span class="has-text-weight-bold">QA/QC Support Documentation:</span>
              <div>
                <VDropdown placement="left" :shown="isOpen" :autoHide="false">
                  <div class="is-flex is-align-items-center form-docs-count is-clickable"
                    @click="isOpen = !isOpen">
                    <i class="icon-attacheddocumentsOL"></i>
                    <span class="is-size-5">{{getFormDocs.length}}</span>
                  </div>
                  <template #popper>
                    <div class="card has-text-black-bis is-visible">
                      <header class="card-header has-background-grey-lighter">
                        <h4
                          class="card-header-title is-size-3 has-text-black-bis
                            has-text-weight-bold is-paddingless"
                        >
                         Documents ({{getFormDocs.length}})
                        </h4>
                      </header>
                      <section class="card-content py-4 is-marginless has-background-white">
                        <div class="mb-3">
                          <search-bar
                            :shouldEmit="true"
                            @search="getSearchValue($event)"
                            placeholder="Search"
                          >
                          </search-bar>
                        </div>
                        <file-list
                          :order="formData"
                          :files="getFormDocs"
                          :closable="false"
                          @close="deleteFile"
                          :isPojectDoc="preview"
                          :isQaQc="true"
                        ></file-list>
                      </section>
                      <footer
                        class="card-footer is-justify-content-flex-end has-background-grey-lighter"
                      >
                        <button class="button has-background-black-bis has-text-white"
                          @click="isOpen = !isOpen">Done</button>
                      </footer>
                    </div>
                  </template>
                </VDropdown>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="column is-border-top" v-if="formData?.note?.desc">
        <span class="line-height is-sixe-5">
          {{formData.note.desc}}
        </span>
      </div>
      <div class="column is-border-top" v-if="form?.note?.desc">
        <span class="line-height is-sixe-5">
          {{form.note.desc}}
        </span>
      </div>
      <div class="column">
        <div class="columns qa-check">
          <div class="column">
            <span class="has-text-black-bis has-text-weight-bold line-height">
              QA/QC Checks
            </span>
          </div>
          <div class="column is-narrow" v-if="!preview">
            <o-switch class="has-text-black-bis"
              :disabled="checkIsHideDisabled"
              @update:modelValue="setToggle"
              >
                <em class="line-height has-text-grey-dark has-text-weight-bold">
                  Hide "Does Not Apply" ({{doesntApplyCount}})
                </em>
              </o-switch>
          </div>
          <div class="is-divider-vertical"></div>
          <div class="column is-narrow">
            <button class="button p-0"
              @click="accordian = !accordian">
              <i :class="!accordian ?
                'icon-expandall' : 'icon-collapseall'"
                class="has-text-black-bis">
              </i>
            </button>
          </div>
        </div>
      </div>
      <div class="column" v-if="!preview">
        <div class="columns filter-buttons">
          <div v-for="filter in filterOptions" :key="filter.key" class="column">
            <div class="btn is-fullwidth is-relative"
              :class="[filter.class, filter.key === selectedFilter.key ? 'is-active': '']"
              @click="selectedFilter = filter">
              <span class="is-italic is-size-3" v-if="filter.label">All</span>
              <i v-else :class="filter.icon"></i>
              <span class="is-size-1 has-text-weight-bold">
                {{filter.key === 'all' ? getVal(filter) : getVal(filter)}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="column qa-forms has-text-black"
        :class="visiblePauseComp ? 'pause-visible' : ''" :key="taskUpdated">
        <qc-task v-for="(qcCheck, idx) in qaQcChecks" :key="idx"
          :qcCheck="qcCheck"
          :checkIndex="idx"
          :assigneeList="assigneeList"
          @add-task="(val) => addTask(val, qcCheck)"
          @docs-added="docsAdded"
          :projectId="card?.project._id"
          :preview="preview"
          :hideTask="hideDoesntApply"
          v-show="!(hideDoesntApply &&
            qcCheck.tasks.length === 1 && !qcCheck.tasks[0].isRequired)"
          :accordian="accordian"
          :isSubmitted="formData?.signature?.showComponent && formData?.signature?.isSubmitted"
          class="mb-3"
          :runAssignee="rowData?.owner?.user"
          :fromTask="fromTask"
          :selectedFilter="selectedFilter"
          :formData="formData"
          :isWorkStepCompleted="rowData?.completed">
        </qc-task>
      </div>
      <div class="column is-flex is-align-items-center
        is-justify-content-space-between is-border-top"
        v-if="formData?.signature?.showComponent &&
        !visiblePauseComp && !preview && !formData?.signature?.isSubmitted">
        <o-checkbox class="mr-3 p-0" v-model="formData.submitForm"
          :disabled="!isFormComplete || preview || fromTask || isTaskReEnabled">
          <span class="has-text-weight-bold">Signature (required):</span>
          <span v-if="isFormComplete"> Click to sign Form</span>
          <span>
            (All active checks must be complete before submitting QA/QC Form.)
          </span>
        </o-checkbox>
        <button class="button has-background-black-bis has-text-weight-bold"
          :disabled="!formData.submitForm || fromTask"
          @click="submitForm">
          Submit Form
        </button>
      </div>
      <div class="column is-flex is-justify-content-space-between
        is-align-items-center" v-else-if="!preview && !visiblePauseComp">
        <div v-if="formData?.signature?.showComponent" class="is-flex is-align-items-center">
          <i class="icon-checkmark"></i>
          <span class="has-text-weight-bold line-height">
            Signature (required):</span>
        </div>
        <span v-if="formData?.signature?.showComponent">
          ({{formData.signature?.owner?.name?.trim()}})</span>
        <stage-field v-if="formData?.signature?.showComponent"
          value="submitted"
          :isStatus="true"
          :isStaticColor="true"
          >
        </stage-field>
      </div>
    </section>
    <footer class="modal-card-foot qc-pause-content p-0"
      v-if="!preview && card?.status === 'pause'">
      <div class="column is-flex is-align-items-center pause-header ">
        <i class="is-orange-text icon-pause is-size-2"></i>
        <span class="is-size-4">
          <span class="has-text-weight-bold">ORDER PAUSED </span> (Note: Order can be restarted from
          <a href="/#/scm/production-status/order-view" @click="$emit('close')"
            target="_blank">Supply Chain Manager</a>.)
        </span>
      </div>
    </footer>
    <footer class="modal-card-foot qc-modal-footer" v-else
      :class="visiblePauseComp ? 'qc-pause-content p-0' : ''">
      <div class="column p-0 is-flex is-align-items-center pause-header">
        <button @click="pauseOrder()" class="button p-0 pause-button"
          :disabled="visiblePauseComp || preview || isPauseDisabled || fromTask">
          <i class="icon-pause has-text-black-bis"></i></button>
        <span class="has-text-black-bis">
          <span class="has-text-weight-bold">
            PAUSE ORDER
          </span> (NOTE: This will PAUSE the entire PRODUCTION ORDER,
            not just specific Work Step.)
        </span>
      </div>
      <div v-if="visiblePauseComp" class="qa-pause-content">
        <div class="column">
          <div class="columns">
            <div class="column">
              <div class="has-text-weight-bold has-text-black-bis is-size-3">
              Order: {{rowData.order.name}}
              </div>
            </div>
            <div class="column is-narrow has-text-grey-darker">
              <em>* required</em>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Message*</label>
            <div class="control">
              <textarea
                v-model.trim="message"
                class="textarea"
                rows="3"
              >
              </textarea>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field" multiple="true">
            <div class="is-flex is-justify-content-space-between">
              <label class="label"><em>Recipients *</em></label>
              <div class="is-flex is-align-items-center">
                <em class="mr-2 has-text-grey-darker">(Project Admins added by default)</em>
                <button class="button p-0 is-pulled-right mr-0 has-text-black-bis"
                  @click="activateDropdown">
                  <i class="icon-addnew"></i>
                </button>
              </div>
            </div>
            <div class="control">
              <VueMultiselect
                class="recipient-field"
                v-model="usersList"
                :options="groupedListUsers"
                track-by="_id"
                label="name"
                :multiple="true"
                group-values="val"
                group-label="key"
                :searchable="true"
                :allow-empty="true"
                :tags="true"
                ref="recipientField"
                :close-on-select="false"
              ></VueMultiselect>
            </div>
          </div>
        </div>
        <div class="column qa-pause-footer is-flex is-justify-content-flex-end">
          <div class="buttons">
            <button class="button is-outlined" @click="resetPause">
              Cancel
            </button>
            <button class="button has-background-black-bis"
              :disabled="!isPauseButtonDisabled"
              @click="pauseCurrentOrder">
              Pause
            </button>
          </div>
        </div>
      </div>
    </footer>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
  <notify-users
    v-if="showNotify && !preview"
    :isActive="showNotify"
    :card="card"
    :from="'qaQc'"
    :run="rowData"
    @close="showNotify = false"
  >
  </notify-users>
  <print-preview
    v-if="isPrintModalActive"
    :isActive="isPrintModalActive"
    :form="formData"
    :formStatus="getFormStatus?.pdfLbl"
    :projectName="card?.project.name"
    :orderName="card?.name"
    :runName="rowData?.name"
    :companyName="card?.owner?.company?.name"
    :startDate="rowData?.simpleDates?.coord?.value"
    :endDate="rowData?.simpleDates?.deliver?.value"
    @close="isPrintModalActive=false"/>
</template>

<script>
import {
  toRefs, reactive, onBeforeMount, ref, computed,
} from 'vue';
import NotifyUsers from '@/components/modals/NotifyUsers.vue';
import QCTask from '@/components/fields/QCTask.vue';
import Form from '@/models/Form';
import moment from 'moment';
import {
  isEmpty, filter, compact, map, cloneDeep, set, find, every, assign, flatMap, some,
} from 'lodash';
import { useStore } from 'vuex';
import Users from '@/models/Users';
import Projects from '@/models/Projects';
import VueMultiselect from 'vue-multiselect';
import SearchBar from '@/components/SearchBar.vue';
import FileList from '@/components/card-edit/FileList.vue';
import QaQcPrintPreview from '@/components/modals/QaQcPrintPreview.vue';
import StageField from '@/components/fields/StageField.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import { useToast } from 'vue-toastification';

export default {
  name: 'qa/qc modal',
  components: {
    NotifyUsers,
    'qc-task': QCTask,
    VueMultiselect,
    SearchBar,
    FileList,
    'print-preview': QaQcPrintPreview,
    StageField,
  },
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    preview: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    fromTask: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const state = reactive({
      showNotify: false,
      isLoading: false,
      formData: {
        qaQcChecks: [],
        run: { _id: props.rowData?._id },
      },
      qaQcChecks: [],
      filterOptions: [
        {
          label: 'all',
          key: 'all',
          class: 'has-text-black-bis',
        },
        {
          icon: 'icon-qcpass',
          class: 'has-text-success',
          key: 'pass',
        },
        {
          icon: 'icon-qcfail',
          class: 'has-text-danger',
          key: 'fail',
        },
        {
          icon: 'icon-qcinspect',
          class: 'has-text-info',
          key: 'inspect',
        }, {
          icon: 'icon-qcnotstarted',
          class: 'has-text-grey-light',
          key: 'notStarted',
        },
      ],
      selectedFilter: {
        label: 'all',
        value: 0,
        key: 'all',
        class: 'has-text-black-bis',
      },
      assigneeList: [],
      taskUpdated: 0,
      visiblePauseComp: false,
      message: '',
      usersList: [],
      groupedListUsers: [],
      userOptions: [],
      isDocModalActive: false,
      isOpen: false,
      isPrintModalActive: false,
      hideDoesntApply: false,
      doesntApplyCount: 0,
      accordian: false,
      wasCardDirty: false,
    });
    const recipientField = ref(null);
    const closeModal = () => {
      if (props.rowData && (props.rowData.formStatus !== state.formData.status)
        && !isEmpty(state.formData.status) && !props.preview && !props.fromTask) {
        emit('close', {
          statusChanged: true,
          status: state.formData.status,
          refreshCard: state.wasCardDirty,
        });
      } else {
        emit('close', {
          refreshCard: state.wasCardDirty,
        });
      }
    };

    const deleteForm = async () => {
      try {
        state.isLoading = true;
        await Form.archive(state.formData._id);
        emit('close', { formRemoved: true });
      } catch (e) {
        console.log(e);
      } finally {
        state.isLoading = false;
      }
    };
    const pauseOrder = () => {
      state.visiblePauseComp = true;
    };
    const notifyUsers = () => {
      state.showNotify = true;
    };
    const printForm = () => {
      state.isPrintModalActive = true;
      // toast.warning('Please wait until the print preview loads')
    };
    const loadData = async () => {
      if (!props.preview) {
        [state.formData] = (await Form.get({
          formId: props.rowData.form.id || props.rowData.form._id,
        })).data;
        state.qaQcChecks = cloneDeep(state.formData?.qaQcChecks) || [];
        const linkedUsers = await Users.getCompanyProjectAllLinkedUsers({
          params: {
            companyIds: store.state.userData.company,
            projectId: props.card.project._id,
            ignoreCompany: true,
          },
        });
        state.assigneeList = Users.groupUsers(linkedUsers);
      } else {
        state.qaQcChecks = props.form?.qaQcChecks || [];
      }
    };

    const cancel = () => {
      const tasksList = flatMap(map(state.qaQcChecks, 'tasks'));
      if (some(tasksList, { showAction: true })) {
        const confirmParam = {
          title: 'UNSAVED CHANGES',
          message: `QA/QC form contains unsaved changes.<br/><br/>
            Do you want to discard changes or cancel and return to the QA/QC form?`,
          okButton: 'Discard',
          cancelButton: 'Cancel',
          type: 'danger',
          onConfirm: async () => {
            closeModal();
          },
        };
        DialogProgrammatic.confirm(confirmParam);
      } else {
        closeModal();
      }
    };

    const filterData = (params) => {
      if (isEmpty(params) || params.key === 'all') {
        state.qaQcChecks = cloneDeep(state.formData.qaQcChecks);
      } else {
        state.qaQcChecks = cloneDeep(compact(filter(state.formData.qaQcChecks, {
          status: params.key,
        })));
      }
    };
    const getVal = (params) => (compact(filter(state.formData.qaQcChecks, (check) => {
      const taskIdx = check.tasks.length - 1;
      const task = check.tasks[taskIdx];
      if ((check.status === params.key && (task.isRequired || task.showAction))
        || (params.key === 'all' && (task.isRequired || task.showAction))) {
        return true;
      }
      return false;
    }))).length;
    const updateForm = async () => {
      state.formData.run = {
        _id: props.rowData._id,
      };
      const { userData } = store.state;
      state.formData.company = {
        _id: userData.company,
      };
      await Form.updateForm(state.formData);
    };
    const isTaskReEnabled = computed(() => {
      for (const checks of state.qaQcChecks) {
        const { tasks } = checks;
        if (tasks?.length && tasks[tasks.length - 1].isReEnabled) {
          return true;
        }
      }
      return false;
    });

    onBeforeMount(async () => {
      state.isLoading = true;
      await loadData();
      state.isLoading = false;
      if (!props.preview) {
        // state.isLoading = true;
        const project = await Projects.getOne(props.card.project._id);
        const projectCompanies = map(project.projectCompanies, '_id');
        projectCompanies.push(props.card.owner.company._id);
        const params = {
          companyIds: projectCompanies,
          projectId: props.card.project._id,
          ignoreCompany: true,
        };
        state.userOptions = await Users.getCompanyProjectAllLinkedUsers({ params });
        state.usersList = state.userOptions;
        const users = Users.groupUsers(state.userOptions);
        state.groupedListUsers = users;
        // state.isLoading = false;
      }
    });

    const addChecklistTask = async (params, updateExisting = false) => {
      const {
        status, task, qcCheck, taskIdx, checkIdx,
      } = params;
      const existingTodo = find(props.card.todos, (t) => t.form?._id === state.formData?._id && t.qcCheckId && t.qcCheckId === qcCheck?._id
          && !t.archived.value);
      if (!isEmpty(existingTodo)) {
        if (updateExisting && task.status === 'pass') {
          existingTodo.status = 'Complete';
        } else {
          existingTodo.assignedTo = {
            user: {
              _id: task.isInspection ? task.reAssigneeTo._id : task.owner._id,
              name: task.isInspection ? task.reAssigneeTo.name : task.owner.name,
            },
            company: props.card.manager.owner.company,
          };
          existingTodo.status = task.isInspection ? 'QC Inspect' : status;
        }
        let taskId = '';
        if (props.card.financialId) {
          taskId += `${props.card.financialId}.`;
        }
        taskId += `${props.rowData.name}.${checkIdx}.${taskIdx}`;
        existingTodo.taskId = taskId;
      } else if (!updateExisting) {
        const newTodoIndex = props.card.newTodo();
        const newTodo = props.card.todos[newTodoIndex];
        newTodo.assignedTo = {
          user: {
            _id: task.owner._id,
            name: task.owner.name,
          },
          company: props.card.manager.owner.company,
        };
        // newTodo.assignedTo = task.owner;
        newTodo.dueDate = moment().format();
        let taskId = '';
        if (props.card.financialId) {
          taskId += `${props.card.financialId}.`;
        }
        taskId += `${props.rowData.name}.${checkIdx}.${taskIdx}`;
        const todoData = {
          status,
          type: 'Quality Assurance',
          text: checkIdx ? `Task.${checkIdx} ${qcCheck.desc}` : qcCheck.desc,
          createdVia: 'qaQcForm',
          taskId,
          qcCheckId: qcCheck._id,
          source: {
            name: state.formData.name,
            sourceId: state.formData._id,
          },
          form: { _id: state.formData._id, name: state.formData.name },
        };
        assign(newTodo, todoData);
      }
    };

    const addTask = async (val, qcTempCheck) => {
      const qcCheck = cloneDeep(qcTempCheck);
      let updateCard = false;
      if (val.task.createCheckList) {
        addChecklistTask({
          status: 'QC Fail', task: val.task, qcCheck, taskIdx: val.taskIdx, checkIdx: val.checkIdx,
        });
        updateCard = true;
      }
      if (val.task.isInspection) {
        addChecklistTask({
          status: 'QC Fail', task: val.task, qcCheck, taskIdx: val.taskIdx, checkIdx: val.checkIdx,
        });
        updateCard = true;
        qcCheck.tasks.push({
          note: '',
          status: 'inspect',
          errorType: null,
          isEditable: true,
          createCheckList: true,
          isModified: true,
          componentType: val.task.componentType,
        });
      }
      if (val.task.isReEnabled) {
        qcCheck.tasks.push({
          note: '',
          status: 'reEnabled',
          errorType: null,
          isEditable: true,
          componentType: val.task.componentType,
          isModified: true,
        });
      }
      if (updateCard && props.card.isDirty) state.wasCardDirty = true;
      if (val.task.updateCheckList) {
        addChecklistTask({
          status: 'QC Fail', task: val.task, qcCheck, taskIdx: val.taskIdx, checkIdx: val.checkIdx,
        }, val.task.updateCheckList);
        updateCard = true;
      }
      // state.formData.qaQcChecks = state.qaQcChecks;
      const qcCheckToSave = find(state.formData.qaQcChecks, { _id: qcCheck._id });
      if (qcCheckToSave) Object.assign(qcCheckToSave, qcCheck);
      state.isLoading = true;
      if (updateCard) {
        const updatedCard = await props.card.save();
        Object.assign(props.card.simpleTodos, updatedCard.todos);
        Object.assign(props.card.todos, updatedCard.todos);
      }
      const { data } = await Form.updateForm(state.formData);
      const qcResCheckToSave = find(state.qaQcChecks, { _id: qcCheck._id });
      if (qcResCheckToSave) {
        Object.assign(qcResCheckToSave, find(data.qaQcChecks, { _id: qcCheck._id }));
      }
      Object.assign(state.formData, cloneDeep(data));
      // once form is saved updating the run form status
      props.rowData.changeQaQcFormStatus(state.formData.status);
      state.isLoading = false;
    };

    const activateDropdown = () => {
      recipientField.value.activate();
    };

    const resetPause = () => {
      state.visiblePauseComp = false;
      state.message = '';
      state.usersList = cloneDeep(state.userOptions);
    };

    const isPauseButtonDisabled = computed(() => {
      const requiredFields = !isEmpty(state.message) && !isEmpty(state.usersList);
      return requiredFields;
    });

    const pauseCurrentOrder = async () => {
      set(props.card, 'notifyUsers', state.usersList);
      try {
        if (props.card.status !== 'pause') {
          set(props.card, 'status', 'pause');
          await props.card.save();
        }
      } catch (e) {
        console.log('Error pausing order', e);
      }
      emit('close', { isOrderPaused: true });
    };

    const getFormDocs = computed(() => {
      if (props.preview) {
        return filter(props.form.docs, (file) => !file.archived.value);
      }
      return state.formData.docs || [];
    });

    const docsAdded = (checkIdx, taskIdx, docs) => {
      docs.forEach((doc) => {
        doc.docType = doc.type;
        set(doc, 'archived.value', false);
        if (state.formData.qaQcChecks[checkIdx].tasks[taskIdx]) {
          state.formData.qaQcChecks[checkIdx].tasks[taskIdx].docs.push(doc);
        }
      });
      state.qaQcChecks[checkIdx].tasks[taskIdx].docs = state.formData.qaQcChecks[checkIdx]
        .tasks[taskIdx].docs;
    };

    const getFormStatus = computed(() => {
      const status = {
        notStarted: { label: 'QC Not Started', icon: 'icon-qcnotstarted', color: '', pdfLbl: 'Notstarted' },
        inspect: { label: 'QC Inspection', icon: 'icon-qcinspect', color: 'has-text-info', pdfLbl: 'Inspect' },
        inProgress: { label: 'QC In Progress', icon: 'icon-qcinprogress', color: 'has-text-link', pdfLbl: 'Inprogress' },
        pass: { label: 'QC Complete', icon: 'icon-qcpass', color: 'has-text-success', pdfLbl: 'Pass' },
        fail: { label: 'QC Fail', icon: 'icon-qcfail', color: 'has-text-danger', pdfLbl: 'Fail' },
        complete: { label: 'QC Complete', icon: 'icon-qcpass', color: 'has-text-success', pdfLbl: 'Complete' },
      };
      return status[state.formData.status];
    });

    const checkIsHideDisabled = computed(() => {
      const mapTasks = state.formData.qaQcChecks.map((check) => check.tasks);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      state.doesntApplyCount = 0;
      for (const tasks of mapTasks) {
        if (!(tasks[tasks.length - 1].isRequired) && !(tasks[tasks.length - 1].isEditable)) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          state.doesntApplyCount++;
        }
      }
      return !(state.doesntApplyCount > 0);
    });

    const setToggle = (val) => {
      state.hideDoesntApply = val;
    };

    const isFormComplete = computed(() => {
      let isAllTaskCompleted = true;
      for (const check of state.formData.qaQcChecks) {
        const task = check.tasks[check.tasks.length - 1];
        if ((task.status !== 'pass' && task.isRequired) || (task.status === 'pass' && task.isEditable)) {
          isAllTaskCompleted = false;
          break;
        }
      }
      return isAllTaskCompleted;
    });

    const submitForm = async () => {
      state.isLoading = true;
      const { data } = await Form.updateForm(state.formData);
      Object.assign(state.formData, data);
      state.isLoading = false;
    };

    const isPauseDisabled = computed(() => {
      const res = every(state.formData.qaQcChecks, { status: 'pass' });
      return res;
    });

    return {
      ...toRefs(state),
      cancel,
      deleteForm,
      pauseOrder,
      notifyUsers,
      printForm,
      loadData,
      filterData,
      getVal,
      addTask,
      updateForm,
      activateDropdown,
      recipientField,
      resetPause,
      isPauseButtonDisabled,
      pauseCurrentOrder,
      getFormDocs,
      docsAdded,
      addChecklistTask,
      getFormStatus,
      checkIsHideDisabled,
      setToggle,
      isFormComplete,
      submitForm,
      isPauseDisabled,
      isTaskReEnabled,
    };
  },
};
</script>

<style scoped>
  i.is-size-2 {
    font-size: 32px !important;
  }
  ::v-deep(.o-chk--disabled) {
  pointer-events: none;
}
.pause-header {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
