<template>
  <div class="card-edit" :key="refreshDetail" v-on="dirtyListeners">
    <card-edit-subheader v-if="cardLoaded" :type="type" :card="card"></card-edit-subheader>
    <div class="edit-body">
      <card-basics v-if="cardLoaded" :type="type" :card="card"
        @accordianChanged="setAccordionStatus"
        :accordionStatus="accordionStatus"
        :refreshCard="reloadPage"></card-basics>
      <div class="card-edit-item-table has-background-white">
        <mf-detail-row v-if="cardLoaded" :rowData="card" :materialsData="materialsData"
          :refreshCard="loadCard" :BOMOrders="BOMOrders" :key="refreshDetail"
          @tabName="setTabName" :tabLabel="selectedTabName"
          @saveCard="saveDetails"
          @itemQtyUpdate="setItemQtyChanged">
        </mf-detail-row>
      </div>
    </div>
  <transition name="slide">
    <notes-icon-slider
      v-if="isSlideNotesActive"
      :isActive="isSlideNotesActive"
      :rowData="selectedItem"
      @close="closeNotesSlider"
    >
  </notes-icon-slider>
  </transition>
  <transition name="slide">
    <item-document-slider
      v-if="isFileListVisible"
      :isActive="isFileListVisible"
      :rowData="selectedItem"
      :rowField="rowField"
      @close="closeDocumentSlider"
      :card="card"
      >
    </item-document-slider>
    </transition>
    <card-footer
      v-if="cardLoaded"
      :showSave="isDirty"
      :type="type"
      @reload-card="loadCard"
      @reload-page="reloadPage"
      @changeCard="changeCard"
      @beforeSave="beforeSave"
      :isNewCard="isNewCard"
      :card="card"
      :projectId="projectId"
      :stage="stage"
      :cardId="cardId"
      :saveCard="saveCard"
      :key="refreshFooter"
      :itemQtyChanged="itemQtyChanged"
      >
    </card-footer>
    <o-loading :active="isLoading"></o-loading>
  </div>
</template>

<script>
import {
  reactive, toRefs, onBeforeMount, onBeforeUnmount, inject,
} from 'vue';
import { useStore } from 'vuex';
// import { debounce, find } from 'lodash';
import { useRouter } from 'vue-router';
import Orders from '@/models/Orders';
import CardEditSubHeader from '@/components/card-edit/CardEditSubheader.vue';
import CardBasics from '@/components/card-edit/CardBasics.vue';
import MfDetailRow from '@/components/table-fields/MfDetailRow.vue';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import CardFooter from '@/components/card-edit/CardFooter.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';
import { cloneDeep } from 'lodash';

export default {
  name: 'po-edit',
  components: {
    'card-edit-subheader': CardEditSubHeader,
    'card-basics': CardBasics,
    'mf-detail-row': MfDetailRow,
    'card-footer': CardFooter,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    cardId: {
      type: String,
      required: true,
    },
    stage: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { cardId, projectId } = props;
    const router = useRouter();
    const emitter = inject('emitter');
    const store = useStore();
    const { onInitDirty, updateMaterialWishlist } = CardEditMixin();
    const { saveCard } = ProductionManagerMixin();
    // data
    const data = reactive({
      card: {},
      cardLoaded: false,
      type: '',
      isLoading: false,
      isNewCard: false,
      materialsData: [],
      refreshFooter: 0,
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedItem: {},
      isItem: false,
      refreshDetail: 0,
      rowField: {},
      selectedTabName: '',
      accordionStatus: false,
      itemQtyChanged: false,
    });
    onInitDirty(data);

    // TODO: need to call from mixin
    const loadCard = async () => {
      onInitDirty(data);
      if (data.tabName !== 'materials') data.isLoading = true;
      data.card = await Orders.get({ orderId: cardId, projectId });
      data.card._beforeEdit = cloneDeep(data.card);
      data.isLoading = false;
      data.tabName = '';
      data.refreshDetail++;
    };

    const reloadPage = async () => {
      await loadCard();
    };

    const beforeSave = () => {
      data.materialsData = updateMaterialWishlist();
    };

    const closeNotesSlider = (() => {
      data.isSlideNotesActive = false;
      data.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      data.isFileListVisible = false;
    };
    const saveDetails = _.debounce(async () => {
      await saveCard(data.card._id, data.card.stage, data.card);
    }, 500);

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      data.isFileListVisible = !payload.isActive;
      data.selectedItem = payload.data;
      data.isItem = payload.isItem;
      data.isSlideNotesActive = payload.isActive;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      data.isSlideNotesActive = !payload.isActive;
      data.selectedItem = payload.data;
      data.rowField = payload.rowField;
      data.isFileListVisible = payload.isActive;
    });

    onBeforeMount(async () => {
      data.type = store.state.activeScreen;
      await loadCard();
      data.cardLoaded = true;
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });

    const setTabName = (val) => {
      data.selectedTabName = val;
    };
    const setAccordionStatus = (val) => { data.accordionStatus = val; };
    const setItemQtyChanged = (val) => {
      data.itemQtyChanged = val;
    };

    return {
      ...toRefs(data),
      loadCard,
      reloadPage,
      saveCard,
      beforeSave,
      closeNotesSlider,
      closeDocumentSlider,
      setTabName,
      setAccordionStatus,
      setItemQtyChanged,
      saveDetails,
    };
  },
};
</script>

<style scoped></style>
