<template>
  <mf-table
      ref="shippingTable"
      :tableProps="tableProps"
      :hideGutter="true"
      :loadData="loadData"
      :screenTable="true"
      tableName="shipping"
      @cell-clicked="onCellClicked"
      class="shipping-item-table"
    ></mf-table>
</template>
<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import Shipping from '@/models/Shipping';
import itemviewCols from '@/components/table-cols/shippingItemViewCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import { ref, reactive, toRefs } from 'vue';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';
import ShippingReceive from '@/components/modals/ShippingReceive.vue';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import Print from '@/components/utils/Print';
import { get, set, find } from 'lodash';
import ExcelExportMixin from '@/components/mixins/ExcelExportMixin';

export default ({
  components: {
    MfTable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const shippingTable = ref(null);
    const toast = useToast();
    const { excelExport } = ExcelExportMixin();
    const {
      deleteOrder,
      markOrderFinal,
      releaseToInv,
      cancelShipment,
    } = GeneralShippingMixin();
    const state = reactive({
      tableProps: itemviewCols,
    });
    const refreshTable = async () => {
      await shippingTable.value.refreshTable();
    };
    const loadData = (commonParam) => {
      let showInvOrders = false;
      if (store.getters.selectedValuesForKey('shippingStatus', false).includes('released-to-inventory')) {
        showInvOrders = true;
      }
      return Shipping.getItemView({
        status: store.getters.selectedValuesForKey('shippingStatus', false),
        projectId: store.getters.selectedIdsForKey('filteredProjects'),
        showAllCompanyOrders: store.state.queryParams.showAllCompanyOrders,
        recipient: store.getters.selectedIdsForKey('recipients'),
        currentLocation: store.getters.selectedIdsForKey('currentLocations'),
        deliveryLocation: store.getters.selectedIdsForKey('deliveryLocation'),
        ...store.getters.selectedDatesForKeys([
          'deliverStartDate',
          'deliverEndDate',
          'shipStartDate',
          'shipEndDate',
          'minItemDeliveryStartDate',
          'minItemDeliveryEndDate',
        ]),
        showInvOrders,
        ...commonParam,
      });
    };

    const saveRow = async (shipment) => {
      try {
        const validate = shipment.validateLabel();
        if (validate.error) {
          toast.error(validate.errText);
          console.error('error---------', validate.errText);
          return;
        }
        shipment = await Shipping.update(shipment);
        // eslint-disable-next-line no-unused-expressions
        await refreshTable();
      } catch (e) {
        console.log(e);
      }
    };

    function openRow(card) {
      router.push({
        name: 'shipping-edit',
        params: { projectId: card.project._id, cardId: card._id },
      });
    }

    const onCellClicked = async (event) => {
      const { type, data } = event;
      if (type === 'save') {
        await saveRow(data);
      } else if (type === 'openCard') {
        openRow(data);
      } else if (type === 'receive') {
        ModalProgrammatic.open({
          component: ShippingReceive,
          props: {
            shippingLabel: data,
            isActive: true,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          events: {
            close: () => {
              refreshTable();
            },
          },
        });
      } else if (type === 'remove') {
        state.isLoading = true;
        await deleteOrder(data);
        state.isLoading = false;
      } else if (type === 'cancelDelivery') {
        state.isLoading = true;
        await cancelShipment(data, refreshTable);
        state.isLoading = false;
      } else if (type === 'markFinal') {
        state.isLoading = true;
        await markOrderFinal(data, refreshTable);
        state.isLoading = false;
      } else if (type === 'release') {
        const deliveryLoc = get(data.delivery, 'currentLocation', {});
        await releaseToInv(deliveryLoc, data, refreshTable);
      } else if (type === 'printQr') {
        const itemData = data.items;
        const params = {
          shippingId: data._id,
          projectId: data.project._id,
          tzinfo: new Date().getTimezoneOffset(),
          printSerial: false,
        };
        const qrLabel = await Shipping.getShippingLabel(params);
        const itemArray = qrLabel.filter((qr) => qr.type !== 'shipping');
        const compArray = qrLabel[0];
        set(compArray, 'viewIndex', 0);
        const sortedArr = itemArray;
        sortedArr.unshift(compArray);
        for (const item of itemData) {
          const obj = find(itemArray, (dt) => dt._id === item._id);
          set(obj, 'viewIndex', item.viewIndex);
        }
        const qrData = sortedArr;
        const user = store.state.userData;
        await Print.printQrLabel(qrData, user);
      } else if (type === 'excelExport') excelExport();
    };

    return {
      ...toRefs(state),
      loadData,
      refreshTable,
      onCellClicked,
    };
  },
});
</script>
