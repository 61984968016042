import { pullAllBy, cloneDeep, get } from 'lodash';

function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    isDetailed: false,
    rearrangeCols: false,
  };
}

const itemsInfo = [
  {
    name: 'field-generic-input',
    width: '15%',
    title: 'Items',
    prop: 'name',
    id: 'name',
  },
  {
    name: 'field-generic-input',
    width: '8%',
    title: 'Item ID',
    prop: 'customId',
    id: 'customId',
  },
  {
    name: 'table-field-icon',
    width: '5%',
    title: '',
    id: 'lmvIcon',
    customHeader: true,
    inputProps: {
      icon: 'icon-linked-model',
    },
  },
  {
    name: 'field-generic-input',
    width: '5%',
    title: 'QTY',
    prop: 'quantity',
    id: 'quantity',
    max: 9999,
    isEditing: 'isEditing',
    isDisabled: (rowData) => {
      if (rowData.stage === 'planning' && rowData.isEditing === true) {
        return false;
      }
      return true;
    },
  },
  {
    name: 'field-generic-input',
    width: '5%',
    title: 'Measure',
    prop: 'measure',
    id: 'measure',
  },
  {
    name: 'field-measure-unit-select',
    width: '10%',
    title: 'Measure Units',
    prop: 'measureUnits',
    id: 'measureUnits',
    cardProps: {
      isEditing: 'isEditing',
    },
  },
  {
    name: 'field-lvl-zone',
    width: '8%',
    title: 'Level',
    prop: 'level',
    id: 'level',
    inputProps: {
      options: 'levels',
      isDisabled: true,
    },
    sortField: 'level',
  },
  {
    name: 'field-lvl-zone',
    width: '8%',
    title: 'Zone',
    prop: 'zone',
    id: 'zone',
    inputProps: {
      options: 'zones',
      isDisabled: true,
    },
    sortField: 'zone',
  },
  {
    name: '',
    title: 'Install Location',
    width: '10%',
    id: 'installLocation',
    formatter(item) {
      if (Array.isArray(item.installLocs)) {
        return item.installLocs.join(', ');
      }
      return '';
    },
  },
  {
    name: 'field-stage',
    width: '8%',
    title: 'Stage',
    id: 'stage',
    prop: 'stage',
    inputProps: {
      stageColor: true,
    },
  },
  {
    name: 'field-item-docs-icon',
    width: '8%',
    title: 'Docs',
    id: 'simpleFiles',
    for: 'Item',
  },
  {
    name: 'field-notes-icon',
    title: 'Notes',
    prop: 'simpleMemos[0].text',
    defaultVisible: true,
    width: '10%',
    id: 'notes',
    inputProps: {
      isItem: true,
    },
  },
  {
    title: 'Actions',
    name: 'table-field-actions',
    id: 'action',
    width: '10%',
    permissionModule: 'prefabs',
    inputProps: {
      showEdit: true,
    },
  },
];

const itemNotes = [
  {
    name: 'field-generic-input',
    width: '25%',
    title: 'Items',
    prop: 'name',
    id: 'name',
  },
  {
    name: 'field-generic-input',
    width: '25%',
    title: 'Item ID',
    prop: 'customId',
    id: 'customId',
  },
  {
    name: 'field-generic-input',
    width: '50%',
    title: 'Notes',
    prop: 'simpleMemos[0].text',
    id: 'notes',
  },
];
export default {
  'item-info': {
    ...extraTableParams(),
    fields: pullAllBy(cloneDeep(itemsInfo), [{ id: 'installLocation' }], 'id'),
  },
  'order-item-info': {
    ...extraTableParams(),
    fields: pullAllBy(cloneDeep(itemsInfo), [{ id: 'action' }], 'id'),
  },
  management: {
    ...extraTableParams(),
    fields: [
      {
        name: 'field-generic-input',
        width: '25%',
        title: 'Items',
        prop: 'name',
        id: 'name',
      },
      {
        name: 'field-generic-input',
        width: '5%',
        title: 'Item ID',
        prop: 'customId',
        id: 'customId',
      },
      {
        name: 'field-stage',
        width: '10%',
        title: 'Stage',
        id: 'stage',
        prop: 'stage',
        inputProps: {
          stageColor: true,
        },
      },
      {
        name: 'field-generic-input',
        width: '10%',
        title: 'Cost Code',
        prop: 'costCode',
        id: 'costCode',
        inputProps: {
          type: 'text',
          disableEdit: true,
        },
      },
      {
        name: '',
        title: 'Install Location',
        width: '20%',
        id: 'installLocation',
        formatter(item) {
          if (Array.isArray(item.installLocs)) {
            return item.installLocs.join(', ');
          }
          return '';
        },
      },
      {
        name: 'field-date',
        width: '10%',
        title: 'Coordinate',
        prop: 'coord',
        id: 'coord-date',
        inputProps: {
          kind: 'coord',
          max: 'deliver',
          disableEdit: (rowData, params) => get(rowData, 'order.__t', '') === 'Prefabs'
            && get(rowData, 'order.purpose', '') === 'kit'
            && get(rowData, '_place', '') === 'item',
        },
      },
      {
        name: 'field-date',
        width: '10%',
        title: 'Onsite',
        prop: 'date',
        id: 'onsite-date',
        inputProps: {
          type: 'mf-date',
          kind: 'deliver',
          min: 'coord',
          disableEdit: (rowData, params) => get(rowData, 'order.__t', '') === 'Prefabs'
              && get(rowData, 'order.purpose', '') === 'kit'
              && get(rowData, '_place', '') === 'item',
        },
      },
      {
        title: 'Actions',
        name: 'table-field-actions',
        id: 'action',
        width: '10%',
        inputProps: {
          showEdit: true,
        },
      },
    ],
  },
  'item-notes': {
    ...extraTableParams(),
    fields: itemNotes,
  },
};
