<template>
  <div class="modal-card move-modal">
    <!-- header -->
    <header>
      <div class="modal-card-head">
        <h4 class="modal-card-title">
          {{ config.title }}
          <span class="required-label is-size-5 is-italic is-pulled-right mr-0 has-text-weight-normal">
            * required
          </span>
        </h4>
      </div>
    </header>
    <!-- section -->
    <section class="modal-card-body has-background-white py-0 is-relative ">
      <o-loading :full-page="false" :active="!isDataLoaded" :can-cancel="false"></o-loading>
      <h4 class="has-text-black-bis is-italic is-size-3 line-height">
        {{ config.cardTitle }}
      </h4>
      <div class="column p-0">
        <div class="columns">
          <div class="column" v-for="(col, idx) in cardConfig" :key="col.label">
            <div class="details-col">
              <div class="pb-2" v-if="(idx === 0)">
                <div class="field">
                  <div class="field-label">
                    <label class="has-text-black-bis is-italic is-size-3 label">
                      Number Selected
                    </label>
                  </div>
                  <div class="field-body">
                    <span class="has-text-black-bis">({{selectedOrders.length}}) </span>
                  </div>
                </div>
              </div>
              <base-column class="mt-2" :fields="columnFields(form, col)" :card="form">
              </base-column>
              <div class="pb-2" v-if="(idx === 0)">
                <div class="field">
                    <div class="field-label">
                      <label class="has-text-black-bis is-italic is-size-3 label">
                        Move Orders To Stage *
                      </label>
                    </div>
                    <div class="field-body" v-if="(destinationStageOpts.length === 1)">
                      <span>{{ form.destinationStage.name }}</span>
                    </div>
                    <div class="field-body" v-else>
                      <mf-multi-select
                        v-model="form.destinationStage"
                        track-by="id"
                        label="name"
                        :multiple="false"
                        :options="destinationStageOpts"
                        :allow-empty="false"
                        @update:modelValue="setSelectedDesOpt($event)">
                      </mf-multi-select>
                    </div>
                </div>
              </div>
              <div class="pb-2" v-if="(idx === 1) && type !== 'manager-edit-manufacturing'">
              <div class="field">
                <div class="field-label">
                    <label class="has-text-black-bis is-italic is-size-3 label">
                      Bills of Material
                    </label>
                  </div>
                <div class="field-body">
                  <div>
                    <o-checkbox class="p-0"
                      v-model="form.bomCreate"
                      :disabled="disableBomCreate">
                      <span class="has-text-black-bis">
                        Create Available BOMs
                      </span>
                    </o-checkbox>
                  </div>
                  <div>
                    <o-checkbox class="p-0"
                      v-model="form.bomSubmit"
                      @update:modelValue="(val) => updateBOMCheckBox(val)">
                      <span class="has-text-black-bis">
                        Submit Available BOMs
                      </span>
                    </o-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="pb-2" v-if="(idx === 1) && (form.bomCreate || form.bomSubmit)">
              <div class="field">
                  <div class="field-label">
                    <label class="has-text-black-bis is-italic is-size-3 label">
                      Deliver Materials
                    </label>
                  </div>
                  <div class="field-body">
                    <div class="is-flex is-align-items-center">
                      <span class="is-pulled-left">
                      <qty-input class="input-qty"
                        v-model.number="form.bomManufactureBy"
                        placeholder="0">
                      </qty-input>
                    </span>
                    <span class="ml-2 is-pulled-left has-text-black-bis">
                      days before manufacture
                    </span>
                    </div>
                  </div>
               </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="column p-0" v-if="kind === 'forward'">
        <div class="table-container" v-if="!!tableConfig">
          <div>
            <search-bar v-if="kind === 'forward'"
              placeholder="Search"
              :shouldEmit="shouldEmit"
              @search="getSearchValue">
            </search-bar>
          </div>
          <div>
            <mf-table
              v-if="isDataLoaded"
              ref="moveTable"
              :tableProps="tableConfig"
              :apiMode="false"
              :loadData="loadData"
            >
            <template #fieldSelectDateSlot="{ rowData, rowField, isHeader}">
              <div v-if="checkPickReqDate(rowData, rowField)">
                <select-date-popup-field
                :rowData="rowData"
                :rowField="rowField"
                :key="refreshCount"
                :isHeader="isHeader"
                :selectedDestStage="form.destinationStage"
                @selected-date="applyDateToAll"
                :isDateDisabled="dateDisable(rowField.prop)"
                @refreshMaterialBy="refreshCountMat"
                ></select-date-popup-field>
              </div>
              <div v-else>
                <select-date-popup-field
                :rowData="rowData"
                :rowField="rowField"
                :key="refreshCount"
                :isHeader="isHeader"
                :selectedDestStage="form.destinationStage"
                @selected-date="applyDateToAll"
                :isDateDisabled="dateDisable(rowField.prop)"
                ></select-date-popup-field>
              </div>
            </template>
            <template #fieldSelectTemplateSlot="{ rowData, rowField}">
              <select-template-popup-field
                :rowData="rowData"
                :key="rowData._id"
                :rowField="rowField"
                :templateOrders="templateOrders"
                @template-details="addTemplateDetails">
              </select-template-popup-field>
            </template>
            </mf-table>
          </div>
        </div>
      </div>
    </section>
    <!-- footer -->
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined" true type="button" @click="cancel()">
        Cancel
      </button>
      <button
        class="button has-background-black-bis"
        :disabled="isDisabled()"
        @click="saveAndMoveNext()"
      >
        {{ cardActionBtn }}
      </button>
    </footer>
    <o-modal :active="isProgressBar" v-if="isProgressBar" :canCancel="false" class="modal-xs">
      <progress-bar
        :totalCount="selectedOrders.length"
        :progressCount="progressCount"
        headerLabel="Move Orders Forward"
        @turn-off-progress="turnOffProgress"
        progressType="progressCount"
        progressLabel="orders moved"
      />
    </o-modal>
  </div>
</template>

<script>
import {
  reactive, toRefs, ref, defineAsyncComponent, onBeforeMount,
} from 'vue';
import { useToast } from 'vue-toastification';
import _, {
  forEach, find, filter, isFunction, isEmpty, cloneDeep, get, assign, findIndex, some, camelCase,
  uniqBy, keys, first,
} from 'lodash';
import { useStore } from 'vuex';
import constants from '@/components/table-cols/bulkMoveModalCols';
import BaseCardColumn from '@/components/card-edit/BaseCardColumn.vue';
import Projects from '@/models/Projects';
import ProductionManager from '@/models/ProductionManager';
import Company from '@/models/Companies';
import SupplyChain from '@/models/SupplyChain';
import Helper from '@/models/Helper';
import Tasks from '@/models/Task';
import { BaseOrder } from '@/models/BaseOrder';
import SelectTemplatePopupField from '@/components/fields/SelectTemplatePopupField';
import SelectDatePopupField from '@/components/fields/SelectDatePopupField';
import QtyInput from '@/components/fields/QtyInput.vue';
import PrefabsMixin from '@/components/mixins/PrefabsMixin';
import ProgressBar from '@/components/modals/ProgressBar';
import moment from 'moment';
import { DialogProgrammatic } from '@/components/Dialog';
import SearchBar from '@/components/SearchBar.vue';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));
export default {
  name: 'MoveOrdersForward',
  props: {
    type: String,
    cards: Array,
    kind: String,
  },
  emits: ['cancel', 'close'],
  components: {
    'base-column': BaseCardColumn,
    'search-bar': SearchBar,
    'mf-table': MfTable,
    'qty-input': QtyInput,
    'select-template-popup-field': SelectTemplatePopupField,
    'select-date-popup-field': SelectDatePopupField,
    'progress-bar': ProgressBar,
  },
  setup(props, { emit }) {
    const {
      createBOMForOrders, moveFromPlanningToCordination, moveProdOrder, attachFormsAndTodos,createBomAndPR
    } = PrefabsMixin();
    const store = useStore();
    const state = reactive({
      cardActionBtn: 'Move',
      config: {},
      cardConfig: {},
      tableConfig: {},
      rowData: {},
      user: {},
      form: {
        created: props.cards[0].created,
        project: props.cards[0].project,
        owner: '',
        location: '',
        destinationStage: '',
        _alsoNotify: '',
        bomCreate: false,
        bomSubmit: false,
        bomManufactureBy: 0,
      },
      errorMssg: [],
      refreshCount: 0,
      disableBomCreate: false,
      selectedOrders: props.cards,
      shouldEmit: true,
      searchText: '',
      destinationStageOpts: [],
      templateProject: {},
      templateOrders: {},
      isProgressBar: false,
      progressCount: 0,
      selectedTemplates: {},
      companyRuns: [],
      isDataLoaded: false,
    });
    const toast = useToast();
    const constKey = `${props.type}-${props.kind}`;
    const config = constants[constKey];
    state.config = config;
    state.cardConfig = config.moveBasics.multiDetails;
    state.tableConfig = config.orderTableBasics;
    const moveTable = ref(null);
    const { activeScreen } = store.state;
    const hasNestedInvLoc = function (project) {
      let cardProject = project || state.selectedOrders[0].project;
      cardProject = store.getters.findProject({ _id: cardProject._id });
      return cardProject.hasNesetedProjectInvLoc(store.state.userData);
    };

    const checkPickReqDate = (rowData, rowField) => {
      if (rowField?.prop !== 'pickReqOnSite') return true;
      if ((_.filter(rowData.items, { fromMasterCatalog: true })).length || rowData.materials?.length ) {
        return true;
      }
      return false;
    };

    if (hasNestedInvLoc() && (activeScreen === 'detailing') && (props.kind === 'forward')
      && !_.find(state.tableConfig.fields, { prop: 'pickReqOnSite' })) {
      state.tableConfig.fields.push({
        name: 'fieldSelectDateSlot',
        defaultVisible: false,
        kind: 'dates',
        width: '152px',
        prop: 'pickReqOnSite',
        title: 'Deliver Material By*',
        isEdit: true,
        id: 'deliverBy-date',
        inputProps: {
          kind: 'pickReqOnSite',
          max: ['qaBy', 'deliver'],
          min: 'detailBy',
        },
      });
    }

    const columnFields = (card, column) => {
      if (isFunction(column.fields)) {
        return column.fields(card);
      }
      return column.fields;
    };

    const updateRouteDetails = (stageVal) => {
      const setRoute = (route) => {
        if (!route) return;
        state.form._alsoNotify = [];
        if (route.notify) {
          state.form._alsoNotify = uniqBy(route.notify, '_id');
        }
        if (route.newOwner) {
          state.form.owner = { user: route.newOwner };
        }
        if (route.newLocation) {
          state.form.location = route.newLocation;
        }
      }
      const cardPurposeMap = {
        kit: 'KitProdOrders',
        assembly: 'AssemblyProdOrders',
      }
      const cardPurpose = get(props.cards[0], 'purpose', '')
      if (state.selectedOrders.length === 1 && ['kit', 'assembly'].includes(cardPurpose)) {
        const route = find(state.proj.orderRoute, (route) => (route.module === cardPurposeMap[cardPurpose] && route.stage === stageVal));
        setRoute(route);
      }
      const route = find(state.proj.orderRoute, (orderRoute) => (orderRoute.module === 'ProdOrders'
      && orderRoute.stage === stageVal && props.cards[0].purpose === 'general'));
      setRoute(route);
    };

    const getdestinationStageOpts = () => {
      if (props.type === 'prefab-edit') {
        state.destinationStageOpts = [
          {
            id: 'coordination', name: 'Coordination',
          }, {
            id: 'detailing', name: 'Detailing',
          }, {
            id: 'manufacturing', name: 'Manufacturing',
          },
        ];
      }
      if (props.type === 'po-edit') {
        state.destinationStageOpts = [
          {
            id: 'detailing', name: 'Detailing',
          }, {
            id: 'manufacturing', name: 'Manufacturing',
          },
        ];
      }
      if (props.type === 'manager-edit-detailing') {
        state.destinationStageOpts = [{ id: 'manufacturing', name: 'Manufacturing' }];
        state.form.destinationStage = { id: 'manufacturing', name: 'Manufacturing' };
      }
      if (props.type === 'manager-edit-manufacturing') {
        state.destinationStageOpts = [{ id: 'qaQc', name: 'QA/QC' }];
        state.form.destinationStage = { id: 'qaQc', name: 'QA/QC' };
      }
    };

    const setSelectedDesOpt = (opt) => {
      state.form.destinationStage = opt;
      if (isEmpty(state.form.destinationStage.id)) return;
      updateRouteDetails(state.form.destinationStage.id);
    };

    const updateBOMCheckBox = (val) => {
      if (val) {
        state.form.bomCreate = true;
        state.form.bomSubmit = true;
        state.disableBomCreate = true;
      } else {
        state.disableBomCreate = false;
      }
    };

    const getSearchValue = (searchValue) => {
      state.searchText = searchValue;
      moveTable.value.refreshTable();
    };

    const loadData = () => {
      let ordersData = state.selectedOrders;
      if (!isEmpty(state.searchText)) {
        ordersData = filter(state.selectedOrders,
          (order) => order.name.toLowerCase().includes(state.searchText.toLowerCase()));
      }
      return ordersData;
    };

    const dateDisable = (col) => {
      const screenMap = {
        coordination: ['coord'],
        detailing: ['coord','detailBy'],
        manufacturing: ['coord', 'detailBy', 'manufactureBy'],
      }; 
      if (screenMap[activeScreen] && screenMap[activeScreen].includes(col)) return true;
      return false;
    };

    const getRealOrderForBO = (kind, data) => {
      let place = '';
      const isManagerDate = ['detailBy', 'manufactureBy', 'qaBy'].includes(
        kind,
      );
      const itemIsManager = data._place === 'manager';
      const itemHasManager = !itemIsManager && data.manager;
      if (isManagerDate && !data.isManager && !itemIsManager) {
        // PO created from template
        kind = camelCase(`po ${kind}`);
      } else if (isManagerDate && itemHasManager) {
        place = 'manager.';
        data = data.manager;
      } else if (itemIsManager && !isManagerDate && data.order) {
        place = 'order.';
        data = data.order;
      }
      if (!data.simpleDates || !data.simpleDates[kind]) data.newDate(kind);
      return {
        data,
        kind: `${place}simpleDates.${kind}.value`,
        value: data.simpleDates[kind].value,
      };
    };

    const validDate = (date) => {
      if (!date) return undefined;
      date = moment(date);
      if (date.isValid()) return date.toDate();
      return undefined;
    };
    // eslint-disable-next-line consistent-return
    const getMinMaxDate = (key, row, inputProps) => {
      if (!_.isUndefined(inputProps)) {
        const minMax = inputProps[key];
        if (!minMax) return undefined;
        const dates = _(minMax)
          .castArray()
          .map((key1) => getRealOrderForBO(key1, row).value)
          .compact()
          .map((d) => moment(d).startOf('day'))
          .value();
        if (dates.length === 0) return undefined;
        return moment[key === 'max' ? 'min' : 'max'](dates);
      }
    };
    const minDate = (row, inputProps) => {
      const date = getMinMaxDate('min', row, inputProps);
      return validDate(date);
    };

    const maxDate = (row, inputProps) => {
      const date = getMinMaxDate('max', row, inputProps);
      return validDate(date);
    };

    const diffRowDates = (row, selectedDate, inputProps) => {
      const mindate = minDate(row, inputProps);
      const maxdate = maxDate(row, inputProps);
      if ((moment(selectedDate) >= mindate || !mindate)
      && (moment(selectedDate) <= maxdate || !maxdate)) {
        return true;
      }
      return false;
    };

    const applyDateToAll = (selectedDate, applyAll, kind, inputProps, alertTitle) => {
      const confirmParam = {
        title: 'ENTERED DATE IS OUT OF RANGE',
        message: `The Entered ${alertTitle} date is out of range for some Orders.
        Enter a new date, or set each Order's date individually.`,
        okButton: 'OK',
      };
      if (applyAll) {
        let invalidDate = false;
        for (const row of state.selectedOrders) {
          const formattedSelectedDate = moment(selectedDate);
          const dateKindDiff = diffRowDates(row, formattedSelectedDate, inputProps);
          const newKind = kind.value.split('.', 2);
          if (dateKindDiff || (!dateKindDiff && newKind[1] === 'coord')) {
            row.addOrUpdateDate(newKind[1], moment(selectedDate).toISOString());
          } else if (!invalidDate) {
            invalidDate = true;
          }
        }
        if (invalidDate) DialogProgrammatic.confirm(confirmParam);
        state.refreshCount += 1;
      }
    };

    const addTemplateDetails = (templateDetails, orderId) => {
      state.selectedTemplates[templateDetails.selectedTemplate._id] = templateDetails.selectedTemplate;
      if (!templateDetails.applyToAll) {
        let selectedOrder = find(
          state.selectedOrders,
          (order) => order._id.toString() === orderId.toString(),
        );
        selectedOrder = selectedOrder.fillBasicTemplateData(templateDetails.selectedTemplate);
        selectedOrder.fillDetailedTemplateData(templateDetails.selectedTemplate, 'deliver', true);
        selectedOrder.templateId = templateDetails.selectedTemplate._id;
        selectedOrder.templateName = templateDetails.selectedTemplate.name;
        state.refreshCount += 1;
      } else {
        forEach(state.selectedOrders, (selectedOrder) => {
          selectedOrder = selectedOrder.fillBasicTemplateData(templateDetails.selectedTemplate);
          selectedOrder.fillDetailedTemplateData(templateDetails.selectedTemplate, 'deliver', true);
          selectedOrder.templateId = templateDetails.selectedTemplate._id;
          selectedOrder.templateName = templateDetails.selectedTemplate.name;
          state.refreshCount += 1;
        });
      }
    };

    const moveBOM = async (params) => {
      let { materialSubmitted } = params;
      const { order } = params;
      if (state.form.bomSubmit && !materialSubmitted) {
        const materialsToSubmit = await order.getSubmitMaterials();
        const disableSubmit = _.some(materialsToSubmit, (ord) => ord.hasNotPurchasedItems);
        if (some(materialsToSubmit, (material) => ['preparation', 'mixed'].includes(material.stage)) && !disableSubmit) {
          await order.submitMaterials(materialsToSubmit, order);
          materialSubmitted = true;
        } else if (disableSubmit) {
          state.errorMssg.push(order);
        }
      }
      return materialSubmitted;
    };
    const moveCard = async (params) => {
      const { project } = params;
      let { card } = params;
      let order;
      let bomCreated = false;
      // eslint-disable-next-line no-unused-vars
      let materialSubmitted = false;
      if (activeScreen === 'planning') {
        order = {
          __t: 'ProductionOrder',
          name: card.name,
          oldName: card.name,
          stage: 'coordination',
          _customStage: '',
          _alsoNotify: null,
          notifyUsers: [],
          defaultRun: null,
          pTrackEnabled: false,
          owner: state.form.owner,
        };
        if (!isEmpty(card.templateId)) {
          card = new BaseOrder(card);
          // apply files here
          card = await card.save();
          card.templateId = card.templateOrder;
        }
        if (card.stage === 'mixed') {
          const items = cloneDeep(filter(card.items, { stage: 'planning', status: 'NotUsed' }));
          if (!items.length) {
            state.progressCount += 1;
            return;
          }
          order = Object.assign(card, order);
          order.items = items;
        } else {
          order = Object.assign(card, order);
        }
        if (order.templateId) {
          order = attachFormsAndTodos(order, state.selectedTemplates[order.templateId]);
        }
        order = await moveFromPlanningToCordination(order);
        const isCatIdItemExist = some(order.items, (item) => !isEmpty(item.catId));
        if (!order._bomCreated && state.form.bomCreate && isCatIdItemExist && !bomCreated) {
          order.dateOffset = state.form.bomManufactureBy;
          order = await createBOMForOrders(order);
          bomCreated = true;
        }
        materialSubmitted = await moveBOM({ materialSubmitted, order });
      } else {
        order = new BaseOrder(card);
      }
      order.location = {
        _id: state.form.location._id,
        name: state.form.location.name,
      };
      if (['planning', 'coordination'].includes(activeScreen)
          && ['detailing', 'manufacturing'].includes(state.form.destinationStage?.id)) {
        card = await createBomAndPR(card);
        order.owner.user = state.form.owner.user;
        order.notifyUsers = _.map(state.form._alsoNotify, '_id', null);
        if (card.stage === 'mixed') {
          let items = [];
          if (state.form.destinationStage?.id === 'detailing') {
            items = cloneDeep(filter(card.items, (item) => ['planning', 'coordination'].includes(item.stage) && item.status === 'NotUsed'));
          } else if (state.form.destinationStage?.id === 'manufacturing') {
            items = cloneDeep(filter(card.items, (item) => ['planning', 'coordination', 'detailing'].includes(item.stage) && item.status === 'NotUsed'));
          }
          if (!items.length) {
            state.progressCount += 1;
            return;
          }
          order = Object.assign(card, order);
          order.items = items;
        } else {
          order = Object.assign(card, order);
        }
        order.pTrackEnabled = true;
        order.manager.location = order.location;
        order.projectId = project._id;
        const isCatIdItemExist = some(order.items, (item) => !isEmpty(item.catId));
        if (!order._bomCreated && state.form.bomCreate && isCatIdItemExist && !bomCreated) {
          order.dateOffset = state.form.bomManufactureBy;
          order = await createBOMForOrders(order);
        }
        materialSubmitted = await moveBOM({ materialSubmitted, order });
        const dates = {};
        const dateMap = {
          poDetailBy: 'detailBy',
          poManufactureBy: 'manufactureBy',
          poQaBy: 'qaBy',
        };
        try {
          for (const type of keys(dateMap)) {
            const dateVal = get(order.simpleDates[type], 'value', null);
            if (dateVal) {
              dates[type] = dateVal;
            }
          }
          if (_.isEmpty(state.selectedTemplates) && !!order.templateOrder) {
            const selectedTemplate = _.find(state.templateOrders.data,
              (template) => template._id.toString() === order.templateOrder.toString());
            assign(order, {
              selectedCard: selectedTemplate,
            });
          } else {
            assign(order, {
              selectedCard: state.selectedTemplates[order.templateOrder],
            });
          }
          const { companyRuns } = state;
          order = await ProductionManager.createFromPO({ card: order, companyRuns });
        } catch (e) {
          console.log('e', e);
        }
      }
      if (['manufacturing', 'qaQc'].includes(state.form.destinationStage.id)) {
        order.manager.owner.user = state.form.owner.user;
        order.notifyUsers = _.map(state.form._alsoNotify, '_id', null);
      }
      if (state.form.destinationStage.id === 'manufacturing') {
        const isDetail = _.get(order, 'stage') === 'detailing';
        if (isDetail) card = await createBomAndPR(card);
        if (card.stage === 'mixed') {
          const items = cloneDeep(filter(card.items, (item) => ['planning', 'coordination', 'detailing'].includes(item.stage)));
          if (!items.length) {
            state.progressCount += 1;
            return;
          }
          order = Object.assign(card, order);
          order.items = items;
        } else {
          order = Object.assign(card, order);
        }
        order = new BaseOrder(order);
        const isCatIdItemExist = some(order.items, (item) => !isEmpty(item.catId));
        if (!order._bomCreated && state.form.bomCreate && isCatIdItemExist && !bomCreated) {
          order.dateOffset = state.form.bomManufactureBy;
          order = await createBOMForOrders(order);
          order._bomCreated = true;
        }
        materialSubmitted = await moveBOM({ materialSubmitted, order });
        let pickReqOnSite = order.getDate('pickReqOnSite');
        if (_.isEmpty(pickReqOnSite)) {
          pickReqOnSite = order.getDate('manufactureBy') || order.getDate('poManufactureBy');

        }
        order.dates.splice(findIndex(order.dates, (date) => date.kind === 'pickReqOnSite'), 0);
        order.stage = 'manufacturing';
        const newCard = await moveProdOrder(order);
        // Transfer request
        if (order._createTR && pickReqOnSite && hasNestedInvLoc(newCard.project)) {
          const task = {};
          task.type = 'pick';
          task.assignee = {
            user: { _id: state.user._id, name: state.user.name },
            company: { _id: state.user.company, name: state.user.companyName },
          };
          assign(task, {
            createdFor: { _id: order._id },
            dueDate: pickReqOnSite,
            project: order.project,
            destinationLocation: order.manager.location,
            taskUniqueId: card.pickReqUniqueId
          });
          await Tasks.create(task);
          toast.success('Transfer Request Created');
        } else {
          const list = await Helper.getMaterialItems(newCard);
          const { data } = list;
          const reqCatIds = order.reqForReserveQty(data, state.user);
          await order.reserveCatalog(reqCatIds);
        }
      }
      if (state.form.destinationStage.id === 'qaQc') {
        order.stage = 'qa';
        await moveProdOrder(order);
      }
      state.progressCount += 1;
    };
    const generateNextTaskId = (taskId) => {
      const splitTaskStr = taskId.split('-');
      const currentCount = splitTaskStr.pop();
      let count = _.parseInt(currentCount) + 1;
      count = _.toString(count);
      count = '0'.repeat(4 - count.length) + count;
      return splitTaskStr.join('-') + '-' + count;
    };
    const saveAndMoveNext = async () => {
      state.isProgressBar = true;
      const project = store.getters.findProject({ _id: state.form.project._id });
      const promises = [];
      state.errorMssg = [];

      const companyId = store.getters.state.userData.company;
      let taskId = undefined;
      const isDestManf = ['manufacturing'].includes(state.form.destinationStage?.id)
      if (isDestManf) {
        const initialTaskData = await Tasks.getUniqueTaskId('pick', companyId);
        taskId = initialTaskData.data.taskId;
      }

      for (const card of state.selectedOrders) {
        const hasNestedLoc = hasNestedInvLoc(card.project);
        if (hasNestedLoc && isDestManf) {
          taskId = generateNextTaskId(taskId);
          card.pickReqUniqueId = taskId;
        }
        promises.push(moveCard({ card, activeScreen, project }));
      }
      await Promise.all(promises);
      const confirmParam = {
        title: 'Move BOM',
        message: 'Few BOM could not moved to Sourcing as all items in them are not Purchased',
        okButton: 'OK',
      };
      if (state.errorMssg.length) DialogProgrammatic.confirm(confirmParam);
      emit('refresh-table');
      emit('close');
      state.isProgressBar = false;
    };

    const cancel = () => {
      emit('refresh-order');
      emit('close');
    };

    const validateDates = (destinationStage, orders) => {
      if (props.type === 'prefab-edit' || props.type === 'po-edit') {
        if (destinationStage.id === 'coordination') {
          for (const order of orders) {
            if (order.simpleDates?.coord?.value === '' || order.simpleDates?.deliver?.value === '') {
              return false;
            }
          }
        }
        if (destinationStage.id === 'detailing') {
          for (const order of orders) {
            if (order.simpleDates?.coord?.value === '' || order.simpleDates?.deliver?.value === ''
            || order.simpleDates?.poDetailBy?.value === '') {
              return false;
            }
          }
        }
        if (destinationStage.id === 'manufacturing') {
          for (const order of orders) {
            if (order.simpleDates?.coord?.value === '' || order.simpleDates?.deliver?.value === ''
            || order.simpleDates?.poDetailBy?.value === ''
            || order.simpleDates?.poManufactureBy?.value === '') {
              return false;
            }
          }
        }
      }
      if (props.type === 'manager-edit-detailing' || props.type === 'manager-edit-manufacturing') {
        if (destinationStage.id === 'manufacturing') {
          for (const order of orders) {
            if (order.manager.simpleDates?.manufactureBy?.value === '') {
              return false;
            }
          }
        }
        if (destinationStage.id === 'qaQc') {
          for (const order of orders) {
            if (order.manager.simpleDates?.qaBy?.value === '') {
              return false;
            }
          }
        }
      }
      return true;
    };

    const isDisabled = () => {
      // move disable pending
      if (!isEmpty(state.form.destinationStage)
      && state.form.location && state.form.owner
      && validateDates(state.form.destinationStage, state.selectedOrders)) {
        return false;
      }
      return true;
    };

    const setRouteDetails = () => {
      if (props.type === 'manager-edit-detailing') updateRouteDetails('manufacturing');
      if (props.type === 'manager-edit-manufacturing') updateRouteDetails('qa');
    };

    const refreshCountMat = () => {
      state.refreshCount += 1;
    };

    onBeforeMount(async () => {
      try {
        state.companyRuns = await Company.getCompanyRuns('companyRuns');
        state.rowData = props.cards;
        state.proj = await Projects.get(state.form.project._id);
        state.templateProject = (await Projects.haveTemplateProject())?.templateProject;
        state.templateOrders = await SupplyChain.supplyChain({
          projectId: state.templateProject._id,
          module: 'ProdTemplates',
          filterNoItemOrders: false,
          limit: 9999,
          page: 1,
        });
        const firstCard = first(props.cards);
        if (activeScreen === 'coordination' && firstCard) {
          let isSameOwner = true;
          let isSameTemplate = true;
          forEach(props.cards, (card) => {
            if (card.owner.user._id !== firstCard.owner.user._id) isSameOwner = false;
            if (card.templateOrder !== firstCard.templateOrder) isSameTemplate = false;
          });
          if (isSameOwner) state.form.owner = firstCard.owner;
          if (isSameTemplate) {
            const template = find(state.templateOrders.data, { _id: firstCard?.templateOrder });
            state.form.location = template?.manager?.location;
          }
        }
        await store.getters.userPromise;
        state.user = store.state.userData;
        getdestinationStageOpts();
        setRouteDetails();
        // state.disableBomCreate = some();
      } catch (e) {
        console.error(e);
      } finally {
        state.isDataLoaded = true;
      }
    });

    return {
      ...toRefs(state),
      saveAndMoveNext,
      cancel,
      isDisabled,
      columnFields,
      getSearchValue,
      moveTable,
      loadData,
      getdestinationStageOpts,
      setSelectedDesOpt,
      addTemplateDetails,
      applyDateToAll,
      updateBOMCheckBox,
      diffRowDates,
      validDate,
      minDate,
      maxDate,
      getRealOrderForBO,
      dateDisable,
      checkPickReqDate,
      refreshCountMat,
      // disableBomCheckbox,
    };
  },
};
</script>

<style scoped>
.details-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.details-col div {
  flex-basis: 432px;
}

::v-deep(.details-col div) {
  flex-basis: 432px;
}
</style>
