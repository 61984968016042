import { createApp } from 'vue';
import {
  Autocomplete,
  Checkbox,
  Collapse,
  Datepicker,
  Dropdown,
  Field,
  Icon,
  Input,
  Inputitems,
  Loading,
  Modal,
  Notification,
  Radio,
  Select,
  Skeleton,
  Slider,
  Steps,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Config,
  Upload,
} from '@oruga-ui/oruga-next';
import Toast from 'vue-toastification';
import VTooltip from 'v-tooltip';
import _ from 'lodash';
import translator from '@/languages/translator';
import GenericField from '@/components/fields/GenericField.vue';
import kitView from '@/components/fields/KitIdField.vue';
import Submittal from '@/components/fields/SubmittalField.vue';
import LinkedProductionOrder from '@/components/fields/LinkedProductionOrder.vue';
import LinkedShippingOrders from '@/components/fields/LinkedShippingOrders.vue';
import CardTags from '@/components/card-edit/CardTags.vue';
import MfFileList from '@/components/card-edit/CardFileList.vue';
import MfNotes from '@/components/card-edit/MfNotes.vue';
import MfDate from '@/components/abstract/MfDate.vue';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import CatId from '@/components/fields/CatIdField.vue';
import ItemDocs from '@/components/fields/ItemDocumentField.vue';
import LinkedBOMs from '@/components/fields/LinkedBOMs.vue';
import BomMoveModalField from '@/components/fields/BomMoveModalField.vue';
import DatesMoveModalField from '@/components/fields/DatesMoveModalField.vue';
import ShippingListField from '@/components/fields/SendShippingListField.vue';
import GenericUserSelect from '@/components/fields/UserSelect.vue';
import GenericLocationSelect from '@/components/fields/LocationSelect.vue';
import GenericVendorSelect from '@/components/fields/VendorSelect.vue';
import GenericProjectSelect from '@/components/fields/ProjectSelect.vue';
import GenericTemplateSelect from '@/components/fields/TemplateSelect.vue';
import StageField from '@/components/fields/StageField.vue';
import MeasureUnitSelect from '@/components/fields/MeasureUnitSelect.vue';
import CardEditActionField from '@/components/table-fields/CardEditActionField.vue';
import Dialog from '@/components/Dialog';
import RunFilterField from '@/components/table-fields/RunFilterField.vue';
import ClockDisplay from '@/components/table-fields/ClockDisplay.vue';
import QtyInput from '@/components/fields/QtyInput.vue';
import ItemSelect from '@/components/table-fields/ItemSelect.vue';
import ProductivityField from '@/components/table-fields/ProductivityField.vue';
import RunSelect from '@/components/table-fields/RunSelect.vue';
import PauseRestartField from '@/components/fields/PauseRestartField.vue';
import NotesIconField from '@/components/table-fields/NotesIconField.vue';
import ShippingItemsIconField from '@/components/table-fields/ShippingItemsIconField.vue';
import NotifyIconField from '@/components/table-fields/NotifyIconField.vue';
import ItemDocsIconField from '@/components/table-fields/ItemDocsIconField.vue';
import LvlOrZoneField from '@/components/fields/LvlOrZoneField.vue';
import InstallLocsIconField from '@/components/table-fields/InstallLocsIconField.vue';
import SelectStatus from '@/components/fields/selectStatus.vue';
import CLPrivateIconField from '@/components/table-fields/CLPrivateIconField.vue';
import ProgressCircle from '@/components/table-fields/ProgressCircle.vue';
import LmvCarousel from '@/components/table-fields/LmvCarousel.vue';
import ProgressBar from '@/components/modals/ProgressBar.vue';
import Weld from '@/components/fields/WeldField.vue';
import mitt from 'mitt';
import App from './App.vue';
import router from './router';
import store from './store';
import filters from './directives/filters';
import Projects from './models/Projects';
import RunProductivityField from '@/components/table-fields/RunProductivityField.vue';

// directives
import stageColor from './directives/stageColor';
import permissions from './directives/permissions';

VTooltip.enabled = window.innerWidth > 1024;
const app = createApp(App);

// global settings
app.config.globalProperties.$_ = _;

app.config.globalProperties.$filters = filters;

const { t } = translator;
app.config.globalProperties.$t = t;

// Provide
const emitter = mitt();
app.provide('emitter', emitter);

// field component registration.
app.component('field-generic-input', GenericField);
app.component('field-tags', CardTags);
app.component('field-file-list', MfFileList);
app.component('field-notes', MfNotes);
app.component('field-date', MfDate);
app.component('mf-multi-select', MfMultiSelect);
app.component('field-stage', StageField);
app.component('field-user-select', GenericUserSelect);
app.component('field-location-select', GenericLocationSelect);
app.component('field-vendor-select', GenericVendorSelect);
app.component('field-project-select', GenericProjectSelect);
app.component('field-template-select', GenericTemplateSelect);
app.component('field-kit-id', kitView);
app.component('field-submittal', Submittal);
app.component('field-linked-production-order', LinkedProductionOrder);
app.component('field-linked-shpping-orders', LinkedShippingOrders);
app.component('field-measure-unit-select', MeasureUnitSelect);
app.component('field-card-edit-action', CardEditActionField);
app.component('field-cat-id', CatId);
app.component('field-item-docs', ItemDocs);
app.component('field-linked-bom', LinkedBOMs);
app.component('field-bom-modal', BomMoveModalField);
app.component('field-dates-move-modal', DatesMoveModalField);
app.component('field-shipping-list', ShippingListField);
app.component('field-run-filter', RunFilterField);
app.component('field-clock-display', ClockDisplay);
app.component('field-qty-input', QtyInput);
app.component('field-item-select', ItemSelect);
app.component('field-productivity-display', ProductivityField);
app.component('field-run-select', RunSelect);
app.component('field-pause-restart', PauseRestartField);
app.component('field-notes-icon', NotesIconField);
app.component('field-ship-items-icon', ShippingItemsIconField);
app.component('field-notify-icon', NotifyIconField);
app.component('field-item-docs-icon', ItemDocsIconField);
app.component('field-lvl-zone', LvlOrZoneField);
app.component('field-install-locs-icon', InstallLocsIconField);
app.component('field-status', SelectStatus);
app.component('field-cl-private-icon', CLPrivateIconField);
app.component('field-progress-circle', ProgressCircle);
app.component('field-LmvCarousel', LmvCarousel);
app.component('progress-bar', ProgressBar);
app.component('field-weld', Weld);
app.component('field-run-productivity', RunProductivityField);

// import Dependencies.
app.use(store);
app.use(router);
app.use(Checkbox);
app.use(Collapse);
app.use(Datepicker);
app.use(Dropdown);
app.use(Field);
app.use(Icon);
app.use(Input);
app.use(Inputitems);
app.use(Loading);
app.use(Modal);
app.use(Notification);
app.use(Radio);
app.use(Select);
app.use(Skeleton);
app.use(Slider);
app.use(Steps);
app.use(Switch);
app.use(Table);
app.use(Tabs);
app.use(Tooltip);
app.use(VTooltip);
app.use(Upload);
app.use(Dialog);
app.use(Autocomplete);

// https://maronato.github.io/vue-toastification/
app.use(Toast, {
  position: 'top-center',
  timeout: 5000,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
  hideProgressBar: true,
  closeButton: false,
  icon: false,
});

// font-awesome icon pack configuration for Oruga
app.use(Config, {
  iconPack: 'fas',
  useHtml5Validation: true,
  statusIcon: true,
});

app.directive('stageColor', stageColor);
app.directive('permission', permissions);

// import Components

require('./styles/main.scss');
require('./styles/custom-icon.css');

app.mount('#app');

window.addEventListener('message', async (msg) => {
  const data = _.get(msg, 'data', '');
  if (_.isString(data) && data !== '') {
    const path = JSON.parse(data);
    const mappedRoute = {
      'forge-authorize': '/forge/authorize',
      'procore-authorize': '/procore/authorize',
      'redirect-scm': '/scm/production-status/order-view',
    };
    if (path && path.pathInfo) {
      if (path.pathInfo.name === 'login') router.push({ path: '/login' });
      if (['lmv-view'].includes(path.pathInfo.name)) {
        return router.push({ name: path.pathInfo.name, params: path.pathInfo.params });
      }
      if (path.pathInfo.name === 'set-project') {
        const project = path.pathInfo.proj;
        let allProjects = await Projects.allProjects();
        allProjects = _.filter(allProjects, { isTemplateProject: false });
        store.commit('setQueryParams', { _projects: allProjects });
        // removing archived projects from the filtered projects
        if (project.status === 'Archived') {
          store.commit('setQueryParams', { projects: allProjects });
          const tempFilterProjArr = store.state.queryParams.filteredProjects.filter((p) => {
            const proj = _.find(allProjects, { _id: p._id });
            if (!_.isEmpty(proj)) return true;
          });
          store.commit('setQueryParams', { filteredProjects: tempFilterProjArr });
        }
      }
      if (['calender'].includes(path.pathInfo.name)) {
        return router.push(path.pathInfo.params);
      }
      if (path.pathInfo.name === 'qcFormTemplate-edit') {
        const route = router.resolve({
          path: `/qcFormTemplate/${store.state.userData.company}/${path.pathInfo.formId ? path.pathInfo.formId : 'add'}/`,
        });
        window.open(route.href);
      }
      if (path.pathInfo.name === 'prodTemplateFormPreview') {
        const route = router.resolve({
          path: `/qcProdFormPreview/${path.pathInfo.formId}`,
        });
        window.open(route.href);
      }
      if (path.pathInfo.name === 'assembly-parts') {
        const route = router.resolve({
          path: '/settings/company-profile/assembly-parts/assemblies',
        });
        window.open(route.href);
      }
      if (path.pathInfo.name === 'location'
        && path.pathInfo.message === 'locationUpdated') {
        store.commit('setQueryParams', { allLocations: '' });
      }
      const redirect = mappedRoute[path.pathInfo.name];
      if (!_.isUndefined(redirect)) {
        router.push({ path: `${redirect}` });
      }
      if (path.pathInfo.name === 'update-company-data') {
        store.commit('setCompanyRuns', []);
        // setting store companyRuns as empty array in above line so the below code will
        // fetch latest company runs
        await store.dispatch('getCompanyRuns');
      }
      if (path.pathInfo.name === 'update-qaqc-issueType') {
        await store.dispatch('getCompanyData');
      }
      if (path.pathInfo.name === 'update-user') {
        await store.dispatch('getUserData');
      }
    }
  }
});
