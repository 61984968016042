<template>
  <div class="is-pulled-right">
    <VDropdown placement="left" :autoHide="false">
      <span class="is-pulled-right">
        <i class="icon-addnew"></i>
      </span>
      <template #popper>
        <div class="card">
          <header class="card-header has-background-grey-lighter">
            <h4
              class="
                card-header-title
                is-size-3
                has-text-black-bis
                is-capitalized
                has-text-weight-bold
                is-paddingless
              "
            >
              {{ title }}
            </h4>
          </header>
          <div class="card-content is-marginless has-background-white">
            <div class="control has-icons-left mb-3">
              <input v-model="searchToken" class="input" type="text" placeholder="Search" />
              <span class="icon is-small is-left">
                <i class="icon-search"></i>
              </span>
            </div>
            <div class="tags-body">
              <div v-for="(option, key) in userOptions" :key="key" class="mb-3">
                <h4
                  class="
                    title
                    is-size-4
                    has-text-weight-bold has-text-black-bis
                    line-height
                  "
                >
                  {{ key }}
                </h4>
                <button v-for="user in option" :key="user.id"
                  class="button is-fullwidth is-flex is-justify-content-space-between"
                  :class="
                    checkIfPresent(user.id)
                      ? 'has-background-black-bis has-text-white'
                      : 'is-outlined'
                  "
                  @click="updateValue(user)"
                >
                  <span class="is-size-3">{{ user.name }}</span>
                  <span class="icon is-small">
                    <i :class="checkIfPresent(user.id) ? 'icon-removedelete' : 'icon-addnew'"></i>
                    </span>
                </button>
              </div>
            </div>
          </div>
          <footer
            class="
              card-footer
              is-justify-content-flex-end
              has-background-grey-lighter
            "
          >
            <div>
              <button
                v-close-popper="true"
                class="button has-background-black-bis has-text-white is-pulled-right"
              >
                Done
              </button>
            </div>
          </footer>
        </div>
      </template>
    </VDropdown>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue';
import { groupBy } from 'lodash';

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    title: String,
  },
  setup(props, { emit }) {
    const data = reactive({
      isOpen: true,
      notifyUsers: props.value,
      searchToken: '',
    });

    const userOptions = computed(() => {
      let opts = props.options;
      if (data.searchToken.length) {
        opts = props.options.filter((j) => j.name.toUpperCase().includes(data.searchToken.toUpperCase())
            || j.email.toUpperCase().includes(data.searchToken.toUpperCase()));
      }

      opts = groupBy(opts, 'companyName');

      return opts;
    });

    const methods = {
      updateValue(user) {
        if (
          !data.notifyUsers
            .map((i) => i.id)
            .includes(user.id)
        ) {
          data.notifyUsers.push(user);
          emit('update:value', data.notifyUsers);
        } else {
          this.removeEmp(user);
        }
      },
      removeEmp(user) {
        data.notifyUsers = data.notifyUsers.filter((i) => i.id !== user.id);
        emit('update:value', data.notifyUsers);
      },

      checkIfPresent(userId) {
        if (
          !data.notifyUsers
            .map((i) => i.id)
            .includes(userId)
        ) return false;
        return true;
      },
    };

    return {
      ...toRefs(data),
      ...methods,
      userOptions,
    };
  },
};
</script>
<style scoped>
h4 {
  margin-bottom: 0 !important;
  padding: 4px 0 !important;
}
.card-content .button {
  margin-top: 4px;
}
.card-content {
  padding-top: 16px !important;
}
</style>
