<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        {{title}}
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="cancel()"></i>
    </header>
    <section class="modal-card-body is-size-4">
        <p class="has-text-black-bis mb-5">
          {{description}}
        </p>
        <mf-multi-select  v-model="selectedProject"
          :options="options"
          titleLabel="Project"
          :multiple="false"
          :searchable="true"
          openDirection="below"
          label="name"
          track-by="_id"
          :allow-empty="false">
        </mf-multi-select>
        <mf-multi-select v-if="editWorkStep" v-model="selectedWorkstep"
          :options="allWorkSteps"
          titleLabel="Work Step"
          :multiple="false"
          :searchable="true"
          openDirection="below"
          label="name"
          track-by="_id"
          :allow-empty="false">
        </mf-multi-select>
        <o-checkbox
          v-if="!invScreen && bulkQrPrint "
          v-model="orderLevel"
          @update:modelValue="getOnlyOrderQR($event)"
        >
          <span class="is-size-3 has-text-black-bis">
            Only print QR labels for Order (not their items)</span>
        </o-checkbox>
        <o-checkbox
          v-if="bulkQrPrint"
          v-model="separatePdf"
          @update:modelValue="getSeparatePdf($event)"
        >
          <span  v-if="!invScreen" class="is-size-3 has-text-black-bis">
            Generate a separate PDF for each order</span>
          <span v-else class="is-size-3 has-text-black-bis">
            Generate a seperate pdf for each item </span>
        </o-checkbox>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined" @click="cancel()">Cancel</button>
      <button v-if="!editWorkStep" class="button has-background-black-bis"
              @click="select()"
              :disabled="!selectedProject">Select</button>
      <button v-if="editWorkStep" class="button has-background-black-bis" :disabled="disableContinue" @click="selectWorkstep()" >Continue
           </button>
      </footer>
  </div>
</template>
<script>
import {
  reactive, toRefs, computed, onBeforeMount,
} from 'vue';
import { useStore } from 'vuex';
import { filter, isEmpty } from 'lodash';

export default {
  props: {
    title: String,
    description: String,
    bulkQrPrint: Boolean,
    editWorkStep: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      selectedProject: null,
      orderLevel: false,
      separatePdf: false,
      seperateForInvItem: false,
      selectedWorkstep: null,
      allWorkSteps: [],
    });
    const select = () => {
      emit('selected-project', state.selectedProject);
      emit('close');
    };
    const cancel = () => {
      emit('cancel');
      emit('close');
      store.commit('setSelectedWorkStep', '');
    };
    const selectWorkstep = () => {
      emit('select-workstep', state);
      store.commit('setSelectedWorkStep', state.selectedWorkstep);
      emit('close');
    };
    const options = computed(() => store.state.queryParams.projects);
    const workstepOptions = computed(() => store.state.companyRuns);

    const getOnlyOrderQR = (isChecked) => {
      state.orderLevel = isChecked;
      store.dispatch('qrScan/setOrderLvlQr', isChecked);
    };
    const disableContinue = computed(() => {
      if (!isEmpty(state.selectedProject) && state.selectedWorkstep) {
        return false;
      }
      return true;
    });
    const getSeparatePdf = (isChecked) => {
      state.separatePdf = isChecked;
      store.dispatch('qrScan/setSeperateQr', isChecked);
    };
    const invScreen = computed(() => {
      if (store.state.activeScreen === 'inventory' && props.bulkQrPrint) return true;
      if (props.bulkQrPrint) return false;
      return null;
    });
    onBeforeMount(async () => {
      store.dispatch('qrScan/qrsBasedOnUserPref', false);
      if (props.editWorkStep) {
        await store.dispatch('getCompanyRuns');
        state.allWorkSteps = store.state.companyRuns;
      }
    });
    return {
      ...toRefs(state),
      options,
      select,
      cancel,
      getOnlyOrderQR,
      getSeparatePdf,
      invScreen,
      workstepOptions,
      selectWorkstep,
      disableContinue,
    };
  },
};
</script>

<style scoped>
  .modal-card-body {
    overflow: visible;
  }
</style>
