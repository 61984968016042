export default {
  ID: {
    field: 'customId',
    multiple: false,
  },
  NAME: {
    field: 'name',
    multiple: false,
    required: true,
  },
  DESCRIPTION: {
    field: 'desc',
    multiple: false,
  },
  'CATALOG ID': {
    field: 'catId',
    multiple: false,
  },
  CATEGORY: {
    field: 'category',
    multiple: false,
  },
  'SUB CATEGORY': {
    field: 'subCategory',
    multiple: false,
  },
  'PART CATID': {
    field: 'parts:catId',
    multiple: false,
  },
  'PART QUANTITY': {
    field: 'parts:quantity',
    multiple: false,
  },
  'PART NOTES': {
    field: 'parts:notes',
    multiple: false,
  },
  'ASSEMBLY CATID': {
    field: 'assemblies:catId',
    multiple: false,
  },
  'ASSEMBLY QUANTITY': {
    field: 'assemblies:quantity',
    multiple: false,
  },
  'ASSEMBLY NOTES': {
    field: 'assemblies:notes',
    multiple: false,
  },
  'ATTRIBUTE NAME': {
    field: 'customAttributes:name',
    multiple: false,
  },
  'ATTRIBUTE VALUE': {
    field: 'customAttributes:value',
    multiple: false,
  },
};
