import * as _ from 'lodash';
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async getOne(params) {
    return (await req.get('location/getOne', { params })).data;
  },
  async allLocationForUser(params, largeDataSet = false) {
    let locData = {};
    if (largeDataSet) {
      locData = await req.post('location/getAllLocationsForUser', params);
    } else {
      locData = await req.get('location/getLocationsForUser', { params });
    }
    return locData.data;
  },
  async forProject(params) {
    params = { limit: -1, ...params };
    return (await req.get('location', { params })).data;
  },
  async update(location, isProjectAdmin) {
    const locationUpdte = await req.post('location/edit', { form_data: location, isProjectAdmin });
    return locationUpdte.data;
  },
  async importLocation(params) {
    return (await req.post('location/importCompanyLocationToProjectLocation', params)).data;
  },
  async deleteLocation(params) {
    return (await req.post('location/removeProjectLocation', params)).data;
  },
  async removeLocations(params) {
    const deletedLoc = await req.post('location/removeLocations', params);
    return deletedLoc;
  },
  async getChildNodes(params) {
    try {
      const { data } = await req.get('location/getNodes', { params });
      return data;
    } catch (e) {
      console.log('Error', e);
      throw e;
    }
  },
  groupLocations(locs, companyData) {
    const projectLocationsKey = `${companyData.name} [Project Delivery Locations]`;
    const pls = _.groupBy(locs, (loc) => {
      if (loc.type === 'company_location') {
        return `${loc.company.name} [Company Locations]`;
      } if (loc.type === 'gi_location') {
        return `${loc.company.name} [General Inventory Locations]`;
      }
      return projectLocationsKey;
    });
    let locations = _.map(pls, (val, key) => ({ key, val }));
    // Ref. Rio#12298.
    locations = _.partition(locations, l => l.key.includes(companyData.name));
    locations = [...locations[0], ..._.sortBy(locations[1], 'key')];
    locations.forEach((loc) => {
      loc.val = _.sortBy(loc.val, 'name');
      if (_.some(loc.val, location => location.nestedLocation === true)) {
        loc.val.unshift(..._.remove(loc.val, location => location.nestedLocation === true));
      }
      if (loc.key === projectLocationsKey) {
        loc.val = _.map(loc.val, l => ({ ...l, nameWithProject: `${l.name} [${l.project[0].name}]` }));
      }
    });
    return locations;
  },
  async allCompanyLocations(params) {
    try {
      const { data } = await req.get('location', { params });
      return {
        data: data.data,
        total: data.totalCount,
      };
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
};
