<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div
    v-if="!showSave && !showScheduleButton"
    class="is-flex footer-btns">
    <div
      v-for="(positionedButtons, idx) of getFooterButtons"
      :key="idx"
      class="buttons mb-0">
      <button
        v-for="(button, idx) in positionedButtons"
        :key="idx"
        class="button mb-0 has-text-white"
        :class="button.event === 'deleteOrder' ? 'is-danger is-outlined'
          : 'has-background-black-bis'"
        @click.prevent="fireEvent(button.event)"
        v-permission="{
          permissionModule: 'shipping',
          type: button.permissionType,
        }"
      >
        <span class="is-capitalized is-size-5 has-text-weight-bold">
          {{ button.title }}
        </span>
      </button>
    </div>
  </div>
  <div class="buttons" v-else-if="!showSave && showScheduleButton">
    <button class="button is-outlined is-danger">
      <span
        class="is-capitalized is-size-5 has-text-weight-bold"
        @click.prevent="fireEvent('cancelDelivery')"
      >
        Cancel delivery
      </span>
    </button>
    <button class="button is-success">
      <span
        class="is-capitalized is-size-5 has-text-weight-bold"
        @click.prevent="fireEvent('scheduleDelivery')"
      >
        Schedule delivery
      </span>
    </button>
  </div>
  <div class="buttons" v-else>
    <button class="button is-outlined is-danger">
      <span
        class="is-capitalized is-size-5 has-text-weight-bold"
        @click.prevent="fireEvent('reloadCard')"
      >
        discard changes
      </span>
    </button>
    <button class="button is-success">
      <span
        class="is-capitalized is-size-5 has-text-weight-bold"
        @click.prevent="fireEvent('updateShipment')"
      >
        Update shipment
      </span>
    </button>
  </div>
</template>
<script>
import {
  computed, defineComponent, reactive, toRefs,
} from 'vue';
import constants from '@/constants';
import { filter } from 'lodash';

export default defineComponent({
  name: 'shipping-buttons',
  emits: ['event', 'reloadCard'],
  props: {
    showSave: Boolean,
    isCreateShipping: Boolean,
    type: String,
    status: String,
    card: Object,
  },
  setup(props, { emit }) {
    const state = reactive({
      buttons: [],
      showScheduleButton: false,
    });
    state.buttons = constants[props.type].shippingOrderButtons;
    const methods = {
      fireEvent(eventHandler) {
        if (eventHandler === 'schedule') {
          state.showScheduleButton = true;
          emit('event', eventHandler);
        } else if (eventHandler === 'cancelDelivery') {
          state.showScheduleButton = false;
          emit('event', eventHandler);
        } else {
          emit('event', eventHandler);
        }
      },
    };

    const getFooterButtons = computed(() => {
      const buttonOnStatus = state.buttons.filter((btn) => {
        if (btn.event === 'sendShippingList' && btn.status.includes(props.card.status)) return true;
        if (btn.event === 'deleteOrder' && btn.status.includes(props.card.status)) return true;
        if (btn.event === 'receiveItems') {
          const validPartialShipments = filter(props.card.partialShipments, (ps) => ps.items.length);
          if (validPartialShipments.length > 1) return false;
        }
        return btn.status === props.card.status;
      });

      const buttonsOnPosition = {
        left: [],
        center: [],
        right: [],
      };

      buttonsOnPosition.left = buttonOnStatus.filter((btn) => btn.position === 'left');
      buttonsOnPosition.center = buttonOnStatus.filter((btn) => btn.position === 'center');
      buttonsOnPosition.right = buttonOnStatus.filter((btn) => btn.position === 'right');

      return buttonsOnPosition;
    });

    return {
      ...methods,
      ...toRefs(state),
      getFooterButtons,
    };
  },
});
</script>
<style scoped>
.footer-btns {
  width: 100%;
  padding: 0 16px;
  justify-content: space-between
}
</style>
