<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        Select Materials From Existing Material Orders
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="cancel()"></i>
    </header>
    <section class="modal-card-body is-size-2 is-marginless">
      <search-bar
        placeholder="Search"
        :shouldEmit="true"
        @search="getSearchValue"
      ></search-bar>
      <div class="table-container">
        <mf-table
          :tableProps="tableProps"
          :loadData="loadData"
          :hideGutter="true"
          ref="ordersTable"
          :apiMode="true"
        >
          <template v-slot:select="{ rowData: order }">
           <o-checkbox v-model="order.selected"
            @update:modelValue="toggleOrder(order)">
           </o-checkbox>
          </template>
        </mf-table>
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined" true type="button" @click="cancel()">
        Cancel
      </button>
      <button
        class="button has-background-black-bis"
        type="button"
        :disabled="isDisabled"
        @click.once="importOrders()"
      >
        Select
      </button>
    </footer>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs, ref, computed,defineAsyncComponent
} from 'vue';
import {
  isEmpty, filter, some, find, set, isUndefined, each, concat, map
} from 'lodash';
import SearchBar from '@/components/SearchBar.vue';
import tableDefinition from '@/components/table-cols/linkRequestingOrders';
import Order from '@/models/Orders';
import SupplyChain from '@/models/SupplyChain';
import { useStore } from 'vuex';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default defineComponent({
  name: 'AddFromInventory',
  components: {
    SearchBar,
    MfTable,
  },
  props: {
    card: Object,
  },
  setup(props, { emit }) {
    const store = useStore();
    const tableProps = tableDefinition;
    const ordersTable = ref(null);
    const state = reactive({
      card: props.card,
      selectedOrders: {},
      searchText: '',
      isLoading: false,
      orderList:[],
    });
    const isDisabled = computed(() => !some(Object.values(state.selectedOrders), 'value'));
    const loadData = async (commonParams) => {
      if (isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
      const params = {
        filterNonLinkedOrders: true,
        projectId: props.card.project._id,
        module: 'Materials',
        filterNoItemOrders: false,
        showAllCompanyOrders: false,
        stage: 'preparation',
        notFromRequisition: false,
        company: store.state.userData.company,
        ...commonParams,
        search: state.searchText,
      };
      state.isLoading = true;
      state.orderList = await SupplyChain.supplyChain(params);
      const dataCount = state.orderList.data.length;
      if (isEmpty(state.orderList)) {
        state.orderList.data = [];
      }
      state.orderList.data = filter(state.orderList.data, (order) => !some(order.items, ['stage', 'rejected']) && order.items && order.items.length > 0);
      state.orderList.total -= (dataCount - state.orderList.data.length);
      state.isLoading = false;
      return state.orderList;
    };
    const toggleOrder = (item) => {
      const itemId = item.catId;
      let val;
      if (isEmpty(state.selectedOrders[itemId])) {
        item.qtyNeeded = 1;
        val = { value: true, item };
      } else {
        val = { value: !state.selectedOrders[itemId].value, item };
      }
      set(state.selectedOrders, itemId, val);
    };

    const cancel = () => {
      emit('close');
    };
    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      ordersTable.value.refreshTable();
    };
    const getCatQTyMap = (item) => {
      const predicate = {};
      if (isEmpty(item.catId)) {
        predicate._id = item._id;
      } else {
        predicate.catId = item.catId;
      }
      const { catQtyMaps } = state.card;
      const catQtyMap = find(catQtyMaps, predicate);
      if (!isUndefined(catQtyMap)) return catQtyMap;
      catQtyMaps.push({
        ...predicate,
        qtyToConsume: 0,
        qtyToShip: 0,
      });
      return catQtyMaps[catQtyMaps.length - 1];
    };
    const importOrders = async () => {
      let ids = [];
      const selectedMats = [];
      each(state.selectedOrders, (val) => {
        if (val.value === true) {
          ids.push(val.item._id);
          selectedMats.push(val.item);
        }
      });
      for (const matOrder of selectedMats) {
        for (const matItem of matOrder.items) {
          const catQtyMap = getCatQTyMap(matItem);
          if (state.card.purpose === 'kit') {
            catQtyMap.qtyToShip += matItem.quantity;
          } else {
            catQtyMap.qtyToConsume += matItem.quantity;
          }
        }
      }
      await state.card.save();
      const orderIds = map(state.card.materials, '_id');
      ids = concat(ids, orderIds);
      await Order.addMaterials(state.card, ids);
      emit('close');
      emit('refresh-table');
    };
    return {
      ...toRefs(state),
      tableProps,
      loadData,
      cancel,
      ordersTable,
      toggleOrder,
      getSearchValue,
      isDisabled,
      importOrders,
    };
  },
});
</script>
<style scoped>
::v-deep(.o-table tbody) {
  min-height: 385px !important;
  max-height: 385px !important;
}
</style>
