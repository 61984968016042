<template>
  <div class="edit-action-btns">
    <button class="button "
      :disabled="isDisable || isRemoveDisabled"
      v-if="showDelete"
      @click="onCellClicked($event, 'delete')">
        <i class="icon-removedelete"></i>
    </button>
    <button class="button"
      v-if="showDetails"
      @click="onCellClicked($event, 'showDetails')">
        <i class="icon-more"></i>
    </button>
    <button v-if="showSubstitute"
      :disabled="disableSubstitute"
      class="button"
      @click="onCellClicked($event, 'substitute')">
        <span class="icon">
          <i class="icon-substitute has-text-link"></i>
        </span>
    </button>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { get } from 'lodash';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'CardEditActionField',
  props: {
    rowData: Object,
    rowField: Object,
    rowIndex: Number,
    rowCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const { rowData, rowField, rowIndex } = props;
    // computed
    const showDelete = computed(() => {
      if (typeof rowField?.showDelete === 'function') {
        return rowField?.showDelete(route.params);
      }
      return get(rowField, 'showDelete', true);
    });
    const showDetails = computed(() => {
      if (typeof rowField?.showDetails === 'function') {
        return rowField?.showDetails(rowData);
      }
      return get(rowField, 'showDetails', false);
    });
    const isDisable = computed(() => {
      if (['not-started', 'in-transit', 'complete', 'delivery', 'released-to-inventory', 'qa',
        'fulfilled', 'in-storage', 'mixed-shipping'].includes(rowData.stage)) { return true; }
      if (typeof rowField.isDisabled === 'function') return rowField.isDisabled(rowData, props.rowCount);
      if (get(rowData, 'purpose', null) === 'kit') return true;
      return false;
    });
    const isRemoveDisabled = computed(() => {
      if (typeof rowField.isRemoveDisabled === 'function') return rowField.isRemoveDisabled(rowData, props.rowCount);
      return false;
    });
    const showSubstitute = computed(() => {
      const { _id, stage, order } = rowData;
      return _id && order && (order.__t === 'Materials' || (order.__t === 'Sourcing' && stage === 'sourcing'))
        && !rowField.noButtons;
    });
    const disableSubstitute = computed(() => {
      const { _id, stage, order } = rowData;
      return !_id || (order && order.__t === 'Materials' && !['preparation', 'rejected'].includes(stage));
    });
    // methods
    const onCellClicked = (e, type) => {
      emit('cell-clicked', {
        data: rowData,
        index: rowIndex,
        field: rowField,
        event: e,
        type,
      });
      if (type !== 'substitute') {
        CardDirtyBus.emit('setCustomDirty');
      }
    };
    return {
      showDelete,
      isDisable,
      onCellClicked,
      showSubstitute,
      disableSubstitute,
      showDetails,
      isRemoveDisabled,
    };
  },
});
</script>
