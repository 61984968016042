export default {
  'ACTIVITY ID': {
    field: 'activityId',
    multiple: false,
    required: true,
  },
  'ACTIVITY NAME': {
    field: 'activityName',
    multiple: false,
    required: true,
  },
  'ONSITE ACTIVITY START': {
    field: 'deliver',
    multiple: false,
    required: true,
  },
};
