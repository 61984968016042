<template>
    <div class="modal-card progress-bar">
      <section class="modal-card-body pt-0 is-size-4 has-text-centered line-height">
          <h3 class="is-size-3 has-text-black-bis has-text-weight-bold">{{headerLabel}}</h3>
           <progress class="progress is-primary" :value="getProgressValue" max="100"></progress>
          <div class="is-size-3 has-text-black-bis">
            <span v-if=" progressType === 'percent'">
              {{getProgressValue}}% complete
            </span>
            <span v-else>
              {{progressCount}} of {{totalCount}} {{progressLabel}}
            </span>
          </div>
      </section>
    </div>
</template>
<script>
import { computed } from 'vue';

export default {
  name: 'progressBar',
  props: {
    headerLabel: {
      type: String,
      default: '',
    },
    progressLabel: {
      type: String,
      default: '',
    },
    totalCount: {
      default: 100,
    },
    progressCount: {
      default: 0,
    },
    progressType: {
      type: String,
      default: 'percent',
    },
  },
  setup(props, { emit }) {
    const getProgressValue = computed(() => {
      const val = (props.progressCount / props.totalCount) * 100;
      if (val === 100) {
        emit('turn-off-progress');
      }
      return Math.floor(val);
    });
    return {
      getProgressValue,
    };
  },
};
</script>
<style scoped>
  .modal-card {
    max-width: 432px !important;
  }
</style>
