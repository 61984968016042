<template>
  <div class="has-background-white lmv-col">
     <div class="lmv-header-title level">
        <div >
          <h4 class="has-text-black has-text-weight-bold is-size-3">
                  Large Model Viewer (LMV)
          </h4>
        </div>
        <div class="has-text-black level-right">
          <i class="icon-close is-clickable" @click="closeModal"></i>
          <div class="is-divider-vertical"></div>
          <figure class="image is-48x48">
          </figure>
        </div>
      </div>
      <div v-if="!isSheet" class="list-view">
        <mf-table
          :tableProps="tableProps"
          ref="LmvTable"
          :screenTable="true"
          :hideGutter="true"
          :loadData="loadData"
          :noDefaultLoad="true">
          </mf-table>
      </div>
  </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs,
} from 'vue';
import { useRouter } from 'vue-router';
import tableDefinition from '@/components/table-cols/LmvFilterCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import GlobalFilter from '@/components/GlobalFilter';

export default defineComponent({
  name: 'LmvFilter',
  components: {
    MfTable,
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
    isSheet: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { clearFilters } = GlobalFilter();
    const state = reactive({
      qparams: {
        filteredProjects: [],
        locations: [],
        owners: [],
        suppliers: [],
        recipients: [],
        lmvStage: [],
        submittals: [],
        orderStatus: [],
        allDates: {},
      },
      tableProps: tableDefinition,
      tableData: {},
    });
    const closeModal = () => {
      clearFilters(state.qparams);
      router.go(-1);
    };
    const loadData = () => {
      emit('reloadForge');
      return state.tableData;
    };
    return {
      ...toRefs(state),
      closeModal,
      loadData,
    };
  },
});
</script>
<style scoped>
::v-deep(.o-table tbody) {
  display: none;
}
/* setting the lmv filter table min-height to 0 so
so the modal is visible */
::v-deep(.o-table__wrapper) {
  min-height: 0 !important;
}
::v-deep(.o-table th) {
    background-color: #cccccc;
}
::v-deep(.o-table) {
    width: 100% !important;
}
.lmv-header-title {
  padding: 0 16px !important;
  margin-bottom: 0 !important;
}
.is-48x48 {
  width: 71px !important;
}
</style>
