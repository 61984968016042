<template>
  <div class="card-edit" v-on="dirtyListeners" :key="refreshKey">
    <card-edit-subheader v-if="cardLoaded" :type="type" :card="card"></card-edit-subheader>
    <div class="edit-body">
      <card-basics v-if="cardLoaded" :type="type" :card="card"
        @accordianChanged="setAccordionStatus"
        :accordionStatus="accordionStatus"></card-basics>
      <div class="card-edit-item-table has-background-white">
       <mf-detail-row v-if="cardLoaded" :rowData="card" :key="refreshKey"
        @tabName="setTabName" :tabLabel="selectedTabName"
        :catalogCollection="catalogCollection"> </mf-detail-row>
      </div>
    </div>
    <transition name="slide">
      <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedItem"
        @close="closeNotesSlider"
      >
      </notes-icon-slider>
    </transition>
    <transition name="slide">
      <item-document-slider
        v-if="isFileListVisible"
        :isActive="isFileListVisible"
        :rowData="selectedItem"
        :rowField="rowField"
        @close="closeDocumentSlider"
        :card="card"
      >
      </item-document-slider>
    </transition>
    <card-footer
      v-if="cardLoaded"
      :showSave="isDirty"
      :type="type"
      @reload-card="loadCard"
      @reload-page="reloadPage"
      @changeCard="changeCard"
      :isNewCard="isNewCard"
      :card="card"
      :projectId="projectId"
      :stage="stage"
      :cardId="cardId"
      :saveCard="saveCard"
    >
    </card-footer>
    <o-loading :active="isLoading"></o-loading>
  </div>
</template>

<script>
import {
  reactive, toRefs, onBeforeMount, nextTick, inject, onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
// import { debounce, find } from 'lodash';
import Prefabs from '@/models/Prefabs';
import CardEditSubHeader from '@/components/card-edit/CardEditSubheader.vue';
import CardBasics from '@/components/card-edit/CardBasics.vue';
import MfDetailRow from '@/components/table-fields/MfDetailRow.vue';
import { BaseOrder } from '@/models/BaseOrder';
import CardFooter from '@/components/card-edit/CardFooter.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import Catalogs from '@/models/Catalogs';

export default {
  name: 'prefab-edit',
  components: {
    'card-edit-subheader': CardEditSubHeader,
    'card-basics': CardBasics,
    'mf-detail-row': MfDetailRow,
    'card-footer': CardFooter,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    cardId: {
      type: String,
      required: true,
    },
    stage: {
      type: String,
      required: true,
    },
    orderJSON: {
      type: String,
    },
  },
  setup(props) {
    const { cardId, projectId, orderJSON } = props;
    const emitter = inject('emitter');
    const { onInitDirty, saveCard } = CardEditMixin();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    // data
    const data = reactive({
      card: {},
      cardLoaded: false,
      type: '',
      isLoading: false,
      isNewCard: false,
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedItem: {},
      isItem: false,
      refreshKey: 0,
      rowField: {},
      accordionStatus: false,
      selectedTabName: '',
      catalogCollection: [],
    });
    onInitDirty(data);

    // methods
    // TODO: need to call from mixin
    const loadCard = async () => {
      onInitDirty(data);
      data.isLoading = true;
      let order;
      if (cardId !== 'add') {
        order = await Prefabs.get({ prefabId: cardId, projectId });
        order._beforeEdit = _.cloneDeep(order);
        data.isNewCard = true;
        await nextTick();
      } else {
        order = new BaseOrder(JSON.parse(orderJSON));
      }
      data.isLoading = false;
      order._customStage = props.stage;
      data.card = order;
      ({ data: data.catalogCollection } = await Catalogs.getAssemblyParts({
        type: 'Assembly',
        search: '',
        page: 1,
        limit: 10000,
      }));
      data.refreshKey++;
    };

    const reloadPage = async () => {
      if (route.params.cardId === 'add') {
        router.go(-1);
      } else {
        await loadCard();
      }
    };

    const changeCard = (newCard) => {
      data.card = newCard;
    };

    const closeNotesSlider = (() => {
      data.isSlideNotesActive = false;
      data.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      data.isFileListVisible = false;
    };

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      data.isFileListVisible = false;
      data.selectedItem = payload.data;
      data.isItem = payload.isItem;
      data.isSlideNotesActive = payload.isActive;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      data.isSlideNotesActive = false;
      data.selectedItem = payload.data;
      data.rowField = payload.rowField;
      data.isFileListVisible = payload.isActive;
    });

    // mounted
    onBeforeMount(async () => {
      data.type = store.state.activeScreen;
      await loadCard();
      data.cardLoaded = true;
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });

    const setAccordionStatus = (val) => { data.accordionStatus = val; };

    const setTabName = (val) => {
      data.selectedTabName = val;
    };

    return {
      ...toRefs(data),
      loadCard,
      reloadPage,
      saveCard,
      changeCard,
      closeNotesSlider,
      closeDocumentSlider,
      setAccordionStatus,
      setTabName,
    };
  },
};
</script>

<style scoped></style>
