<template>
    <div>
      <div class="column has-text-white has-text-centered">
         <h2 class="is-size-2 has-text-white line-height">Thank You!</h2>
         <h5 class="is-size-4  line-height">
          We have sent you an email with instructions on how to activate your account.</h5>
      </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>
