<template>
    <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
    <TableGutter
            :selectedAction="isBulkActionEnabled"
            :tools="tableProps.gutterOpts"
            @cc-modal-closed="triggerSetHeaderHeight"
            @re-render-table="rerenderMfTable += 1"
    >
        <template v-slot:selectView>
            <div class="column is-2 is-4-desktop pl-0 pr-0">
                <div class="columns is-align-items-center">
                    <div class="column is-narrow">
                        <label class="label has-text-black-bis
                        is-size-3 is-italic has-text-weight-bold">
                            Event Type:
                        </label>
                    </div>
                    <div class="column pr-0">
                        <mf-multi-select
                                :disabled="isBulkActionEnabled !== null"
                                v-model="viewAs"
                                track-by="type"
                                label="label"
                                :multiple="false"
                                :options="viewOptions"
                                :allow-empty="false"
                                @update:modelValue="setView($event)"
                        ></mf-multi-select>
                    </div>
                    <div class="is-divider-vertical"></div>
                </div>
            </div>
        </template>
    </TableGutter>
    <stage-view ref="stageView" v-if="view === 'stage-view'" :moduleName="moduleName"
      @cell-clicked="onCellClicked" :key="rerenderMfTable"></stage-view>
    <owner-view ref="ownerView" v-if="view === 'owner-view'" :moduleName="moduleName"
      @cell-clicked="onCellClicked" :key="rerenderMfTable"></owner-view>
    <date-view ref="dateView" v-if="view === 'date-view'" :moduleName="moduleName"
      @cell-clicked="onCellClicked" :key="rerenderMfTable"></date-view>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import stageViewCols from '@/components/table-cols/stageChangeViewCols';
import StageView from '@/views/StageChangeListView.vue';
import OwnerView from '@/views/OwnerChangeListView.vue';
import DateView from '@/views/DateChangeListView.vue';
import TableGutter from '@/components/TableGutter.vue';
import { findIndex } from 'lodash';
import UtilityMixin from '@/components/mixins/UtilityMixin';

export default defineComponent({
  name: 'recent-events',
  components: {
    'stage-view': StageView,
    'date-view': DateView,
    'owner-view': OwnerView,
    TableGutter,
  },
  props: {
    view: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const stageView = ref(null);
    const ownerView = ref(null);
    const dateView = ref(null);
    const { openPreference } = UtilityMixin();
    const isBulkActionEnabled = computed(() => {
      if (props.view === 'stage-view') {
        return stageView?.value?.$refs.stageChangeTable?.selectedBulkAction;
      }
      if (props.view === 'owner-view') {
        return ownerView?.value?.$refs.ownerChangeTable?.selectedBulkAction;
      }
      if (props.view === 'date-view') {
        return dateView?.value?.$refs.dateChangeTable?.selectedBulkAction;
      }
      // eslint-disable-next-line implicit-arrow-linebreak
      return stageView?.value?.$refs.stageChangeTable?.selectedBulkAction;
    });
    const viewOptions = [{
      label: 'Stage Changed',
      type: 'stage-view',
    }, {
      label: 'Date Changed',
      type: 'date-view',
    }, {
      label: 'Owner Changed',
      type: 'owner-view',
    }];

    const state = reactive({
      isLoading: false,
      showEmpty: false,
      tableProps: stageViewCols,
      viewAs: viewOptions[findIndex(viewOptions, ['type', props.view])],
      viewModelOpen: false,
      moduleName: {
        PrefabPackage: {
          module: 'prefabPackage',
          __t: 'Prefabs',
        },
        ProductionOrder: {
          module: 'productionOrder',
          __t: 'ProductionOrder',
        },
        MaterialManager: {
          module: 'materialManager',
          __t: 'Materials',
        },
        Sourcing: {
          module: 'sourcing',
          __t: 'Sourcing',
        },
      },
      rerenderMfTable: 0,
    });

    const setView = async (view, newVal) => {
      state.viewAs = view;
      const key = 'selectedList';
      store.commit('setQueryParams', { [key]: view.type });
      if (newVal !== '' && newVal !== props.view) {
        router.push({ path: `/resources/recentEvents/${view.type}` });
      }
    };

    watch(
      () => store.state.queryParams.selectedList,
      (newVal, oldVal) => {
        store.commit('setQueryParams', {
          oldselectedList: oldVal,
        });
      },
    );
    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      if (props.view === 'stage-view') {
         // eslint-disable-next-line no-unused-expressions
         stageView?.value?.$refs.stageChangeTable?.closeColumnChooserModal();
          // eslint-disable-next-line no-unused-expressions
          stageView?.value?.$refs.stageChangeTable?.refreshFilterOpts();
      }
      if (props.view === 'owner-view') {
        // eslint-disable-next-line no-unused-expressions
        ownerView?.value?.$refs.ownerChangeTable?.closeColumnChooserModal();
          // eslint-disable-next-line no-unused-expressions
          stageView?.value?.$refs.ownerChangeTable?.refreshFilterOpts();
      }
      if (props.view === 'date-view') {
        // eslint-disable-next-line no-unused-expressions
        ownerView?.value?.$refs.dateChangeTable?.closeColumnChooserModal();
          // eslint-disable-next-line no-unused-expressions
          stageView?.value?.$refs.dateChangeTable?.refreshFilterOpts();
      }
        // eslint-disable-next-line no-unused-expressions
        stageView?.value?.$refs.stageChangeTable?.closeColumnChooserModal();
        // eslint-disable-next-line no-unused-expressions
        stageView?.value?.$refs.stageChangeTable?.refreshFilterOpts();
    };
    const openCard = (card) => {
      const module = {
        Prefabs: 'prefab',
        Sourcing: 'sourcing',
        ProductionOrder: 'order',
        Materials: 'material',
      };
      const shipStages = [
        'in-transit',
        'complete',
        'delivery',
        'fulfilled',
        'not-started',
        'in-storage',
        'released-to-inventory',
        'mixed-shipping',
        'ordering',
      ];
      let moduleName = '';
      moduleName = module[card.__t];
      let { stage } = card;
      if (shipStages.includes(card.stage)) {
        moduleName = 'sourcing';
        stage = 'ordering';
      }
      const routeParams = { projectId: card.project._id, cardId: card._id };
      if (['manufacturing', 'detailing', 'qa'].includes(stage)) {
        moduleName = 'manager';
        if(card.isCutOrder) moduleName = 'cut';
      }
      if (!['planning', 'coordination'].includes(card.stage)) {
        routeParams.stage = stage;
      }
      const routeObj = {
        name: `${moduleName}-edit`,
        params: routeParams,
      };
      window.open(router.resolve(routeObj)?.href, openPreference());
    };
    const onCellClicked = async (event) => {
      const { type, data } = event;
      if (type === 'openCard') {
        openCard(data);
      }
    };
    return {
      ...toRefs(state),
      viewOptions,
      setView,
      stageView,
      ownerView,
      dateView,
      isBulkActionEnabled,
      triggerSetHeaderHeight,
      onCellClicked,
    };
  },
});
</script>

<style scoped lang="sass"></style>
