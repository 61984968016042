/* global Autodesk:true */
/* eslint no-undef: "error" */
import _ from 'lodash';
import store from '@/store';
import Forge from '@/models/Forge';

let fv = null;
let buttonM = null;
let mButtonClicked = true;
let lButtonClicked = true;
// eslint-disable-next-line
let itemDBids = [];
let filterDBids = [];
let dbidsMap = {};
let cardsMap = {};
let fvParams = {};
let sheetColor = false;
const storeHelper = store.getters;
function clearLocal() {
  // Clear the Memory after use
  dbidsMap = {};
  cardsMap = {};
}
function focusItem() {
  let MfactDbIds = [];
  if (fvParams.itemId !== '' && _.isArray(dbidsMap[fvParams.itemId]) && dbidsMap[fvParams.itemId].length > 0) {
    MfactDbIds = dbidsMap[fvParams.itemId];
  } else if (fvParams.orderId !== '' && _.isArray(cardsMap[fvParams.orderId])) {
    _.each(cardsMap[fvParams.orderId], (itemId) => {
      if (_.isArray(dbidsMap[itemId]) && dbidsMap[itemId].length > 0) {
        MfactDbIds = _.concat(MfactDbIds, dbidsMap[itemId]);
      }
    });
  } else if (fvParams.mergedItems && !_.isEmpty(fvParams.itemIds)) {
    for (const itemId of fvParams.itemIds) {
      if (_.isArray(dbidsMap[itemId]) && dbidsMap[itemId].length > 0) {
        MfactDbIds.push(...dbidsMap[itemId]);
      }
    }
  }
  if (MfactDbIds.length > 0) {
    fv.select(MfactDbIds);
    const color = '#ff0000';
    fv.setColorMaterial(MfactDbIds, color, 'focus');
    fv.fitToView(MfactDbIds);
  }
}
function isolateElems(dbIds) {
  if (fvParams.sheetId === '') fv.isolate(dbIds);
}
function hideAllElements() {
  fv.hideAll();
}
async function propertiesResult(elements) {
  let revitItems = [];
  const childMap = {};
  for (let i = 0; i < elements.length; i += 1) {
    // todo:
    // compare the linked items GUID length === LMV(MFactLiniked).length
    // Eg
    // {
    // Mfact_
    // parent: 746
    // child: 748 [^]
    // dbid: 747
    // }
    // {
    // parent: 747
    // child: 749
    // child: 750
    // child: 751
    // dbid: 748 [^]
    // }
    let tmpItemId = '';
    const tmpItemIdx = _.findIndex(elements[i].properties, (pr) => pr.displayName == 'MFact_ItemID' && !_.isEmpty(pr.displayValue));
    let tmpParentId = '';
    const tmpParentIdx = _.findIndex(elements[i].properties, { displayName: 'parent' });
    if (tmpParentIdx > -1) {
      tmpParentId = elements[i].properties[tmpParentIdx].displayValue;
    }

    if (tmpItemIdx > -1) {
      revitItems.push(elements[i].properties[tmpItemIdx].displayValue);
      tmpItemId = elements[i].properties[tmpItemIdx].displayValue;
      // Todo: in the case of table & chairs the consigli fix breaking this scenario
      if (childMap[elements[i].dbId]) {
        const tItem = childMap[elements[i].dbId];
        dbidsMap[tItem] = _.pull(dbidsMap[tItem], elements[i].dbId);
      }
      childMap[elements[i].dbId] = tmpItemId; // Parent (eg: 747)
      if (tmpParentId !== '' && !_.isEmpty(tmpItemId)) {
        childMap[tmpParentId] = tmpItemId; // Parent (eg: 746)
      }
    } else if (!_.isEmpty(childMap[elements[i].dbId])) {
      // Map created below ref: 94, using previous properties object
      tmpItemId = childMap[elements[i].dbId]; // used for Non MFact_ properties child (eg: 748)
    }
    if (tmpItemId === '' && tmpParentId !== '' && !_.isEmpty(childMap[tmpParentId])) {
      // this is optional lookup, if the above one fails
      tmpItemId = childMap[tmpParentId]; // Non MFact_ properties lookup with parent (eg: 747)
    }
    for (let j = 1; j < elements[i].properties.length; j++) {
      // lookup all child and push this child dbids for coloring
      if (elements[i].properties[j].displayName === 'child' && !_.isEmpty(tmpItemId)) {
        let child = '';
        if (_.isNumber(elements[i].properties[j].displayValue)) {
          child = elements[i].properties[j].displayValue;
        }
        childMap[child] = tmpItemId; // Child (eg: 748) [ref: this is used in line 76]
        if (_.isEmpty(dbidsMap[tmpItemId])) {
          dbidsMap[tmpItemId] = [];
        }
        dbidsMap[tmpItemId].push(child);
      }
    }
    if (tmpItemId !== '') {
      if (_.isEmpty(dbidsMap[tmpItemId])) {
        dbidsMap[tmpItemId] = [];
      }
      dbidsMap[tmpItemId].push(elements[i].dbId);
    }
  }
  revitItems = _.uniq(revitItems);
  // dbidsMap used on the properties panel
  Autodesk.Viewing.Viewer3D.prototype.dbidsMap = dbidsMap;
  // available under color extension
  fv.restoreColorMaterial();
  const queryParams = {
    projectId: _.get(fvParams, 'projectId', '') || storeHelper.selectedIdsForKey('selectedLmvProject'),
    // module: 'Materials',
    company: storeHelper.selectedIdsForKey('companies', false),
    location: storeHelper.selectedIdsForKey('locations', false),
    owner: storeHelper.selectedOwners,
    submittals: storeHelper.selectedSubmittals,
    ...storeHelper.selectedDatesForKeys([
      'deliverStartDate',
      'deliverEndDate',
      'availableStartDate',
      'availableEndDate',
      'orderByStartDate',
      'orderByEndDate',
      'shipByStartDate',
      'shipByEndDate',
    ]),
    recipient: storeHelper.selectedIdsForKey('recipients'),
    supplier: storeHelper.selectedIdsForKey('suppliers'),
    search: store.state.queryParams.searchText,
    // stage: storeHelper.selectedValuesForKey('materialStage'),
    showPrivate: true,
    status: storeHelper.selectedValuesForKey('orderStatus', false),
    ...storeHelper.selectedDatesForKeys([
      'coordStartDate',
      'coordEndDate',
      'detailByStartDate',
      'detailByEndDate',
      'manufactreByStartDate',
      'manufactureByEndDate',
      'qaByStartDate',
      'qaByEndDate',
    ]),
  };
  const orderStages = _.map(store.state.queryParams.lmvStage, 'value');
  queryParams.filterStageStrict = true;
  // check revitItems stage
  queryParams.itemIds = revitItems;
  queryParams.lmv = true;
  queryParams.stage = _.chain(orderStages)
    .compact()
    .uniq()
    .value();
  queryParams.limit = 1000;
  // const tmp = _.get(store.state, 'itemsWithGuid', [])
  const tmpData = await Forge.getItemStatus(queryParams);
  filterDBids = [];

  let tmpDataItems = tmpData.data;
  const totalPages = _.ceil(tmpData.total / queryParams.limit);
  const pdataPromise = [];
  if (tmpData.total > queryParams.limit) {
    for (let i = 2; i <= totalPages; i++) {
      queryParams.page = i;
      const qp = _.clone(queryParams);
      pdataPromise.push(Forge.getItemStatus(qp));
    }
    const pdataRes = await Promise.all(pdataPromise);
    for (let i = 0; i < pdataRes.length; i++) {
      tmpDataItems = _.concat(tmpDataItems, pdataRes[i].data);
    }
  }
  _.each(tmpDataItems, (item) => {
    let { stage } = item;
    if (item.items[0].stageMap) {
      const stageMap = _.omit(item.items[0].stageMap, 'zombie');
      const stageCnt = _.filter(_.compact(_.values(stageMap)), (cnt) => cnt > 0);
      if (stageCnt.length > 1) {
        stage = 'mixDelivery';
      } else {
        for (const s in stageMap) {
          if (stageMap[s] === item.items[0].quantity) {
            stage = s;
          }
        }
      }
    }
    const colorMap = {
      planning: 'prefab',
      'not-started': 'notStarted',
      'in-transit': 'inTransit',
      'in-storage': 'inStorage',
      'released-to-inventory': 'releasedToInventory',
      'mixed-shipping': 'mixDelivery',
    };
    stage = _.get(colorMap, stage, stage);
    if (stage === 'qa' && item.__t === 'Materials') {
      stage = 'fieldverify';
    }
    if (!_.isEmpty(dbidsMap[item.id])) {
      if (fvParams.sheetId === '' || sheetColor) {
        fv.setColorMaterial(dbidsMap[item.id], tmpData.colorScheme[stage], `${item.id}_${stage}`);
      }
      filterDBids = _.concat(filterDBids, dbidsMap[item.id]);
    }
    const cardId = item.items[0].cards[0];
    if (_.isUndefined(cardsMap[cardId])) { cardsMap[cardId] = []; }
    if (_.isUndefined(cardsMap[item.cardId])) { cardsMap[item.cardId] = []; }
    cardsMap[item.cardId].push(item.id);
    cardsMap[cardId].push(item.id);
  });
  // Todo card focus with cardsMap ?
  if (mButtonClicked) {
    if (filterDBids.length === 0) {
      hideAllElements();
    } else {
      isolateElems(filterDBids);
    }
  }
  // not required for 2d view fv.fitToView(filterDBids);
  document.body.style.cursor = 'default';
  // fvParams.orderId = '5c593ab8072864472f759583';
  if (_.get(fvParams, 'itemId', '') !== '' || _.get(fvParams, 'orderId', '') !== '') {
    focusItem();
  }
}

function searchResult(dbIds) {
  // clear previous paint
  itemDBids = dbIds;
  fv.model.getBulkProperties(dbIds, ['MFact_ItemID', 'MFact_Linked', 'parent', 'child'], propertiesResult);
}

function filterFunction(pdb) {
  let attrIdlinked = -1;
  let attrIdchild = -1;
  pdb.enumAttributes((i, attrDef) => {
    if (attrDef.name === 'child' || attrDef.displayName === 'child') {
      attrIdchild = i;
    }
    if (attrDef.name === 'MFact_Linked' || attrDef.displayName === 'MFact_Linked') {
      attrIdlinked = i;
      // return true;
    }
    return false;
  });
  const hasLinked = [];
  if (attrIdlinked !== -1) {
    pdb.enumObjects((dbId) => {
      const childs = [];
      pdb.enumObjectProperties(dbId, (attrId, valId) => {
        if (attrId === attrIdchild) {
          const value = pdb.getAttrValue(attrId, valId);
          childs.push(value);
        }
      });
      // For each part, iterate over their properties.
      pdb.enumObjectProperties(dbId, (attrId, valId) => {
        // Only process 'MFact_Linked' property.
        // The word "Property" and "Attribute" are used interchangeably.
        // The below condition not works as expected with consigli Navisworks modal
        // if (attrId === attrIdlinked) {
        // Stop iterating over additional properties when "MFact_Linked" is found.
        // return true;
        // }
        //
        const value = pdb.getAttrValue(attrId, valId);
        // DisplayName/AttributeName/Name based filter failed to give the exptected output.
        // So we did just value search
        if (value === 'Yes' || value === 'ManufactOn') {
          hasLinked.push(dbId);
          if (childs.length > 0) {
            for (let i = 0; i < childs.length; i++) {
              hasLinked.push(childs[i]);
            }
          }
        }
        //
        return false;
      });
    });
  }
  return {
    dbids: hasLinked,
  };
}

async function refreshStatus(viewer) {
  document.body.style.cursor = 'progress';
  clearLocal();
  // GUID association removed from ManufactOn items,
  // and in Revit ele properties added MFact_Linked,MFact_ItemID
  // viewer.search('Yes', searchResult, searchError, ['MFact_Linked', attributeName]);
  const { dbids } = await viewer.model.getPropertyDb()
    .executeUserFunction(`var userFunction = ${filterFunction.toString()}`);
  searchResult(dbids);
}

// Tool bar ManufactOn button, for isolate view
function mButtonUI() {
  buttonM = new Autodesk.Viewing.UI.Button('manufacton-front-button');
  // M Button
  buttonM.onClick = () => {
    mButtonClicked = !mButtonClicked;
    sheetColor = mButtonClicked;
    refreshStatus(fv);
    if (mButtonClicked) {
      buttonM.removeClass('mf-disabled');
      // itemDBids can be used to show all linked elements
      // filterDBids to show only elements which are matched with filter
      isolateElems(filterDBids);
    } else {
      buttonM.addClass('mf-disabled');
      isolateElems([]);
    }
  };
  buttonM.addClass('manufacton-scm');
  // Commented below line to enable isolate view by default
  // buttonM.addClass('mf-disabled');
  buttonM.setToolTip('ManufactOn Items');

  // SubToolbar
  const mfactToolbar = new Autodesk.Viewing.UI.ControlGroup('manufacton-view-toolbar');

  const buttonL = new Autodesk.Viewing.UI.Button('manufacton-legend-button');
  // Legend Button
  buttonL.onClick = () => {
    lButtonClicked = !lButtonClicked;
    const legend = document.getElementById('mflegend');
    if (lButtonClicked) {
      // show
      legend.style.display = 'block';
      buttonL.removeClass('mf-disabled');
    } else {
      // hide
      legend.style.display = 'none';
      buttonL.addClass('mf-disabled');
    }
  };
  mfactToolbar.addControl(buttonM);
  const mflegend = document.getElementById('mflegend');
  if (fvParams.sheetId === '') {
    buttonL.addClass('manufacton-legend');
    buttonL.setToolTip('ManufactOn Legend');
    mfactToolbar.addControl(buttonL);
  } else {
    mflegend.style.display = 'none';
    mButtonClicked = false;
    buttonM.addClass('mf-disabled');
  }
  return mfactToolbar;
}

function ScmExtension(viewer, options) {
  Autodesk.Viewing.Extension.call(this, viewer, options);
  fv = viewer;
  this.load = () => {
    fv.prefs.set('progressiveRendering', false, true);
    // refreshStatus(fv);
    return true;
  };
  Autodesk.Viewing.Viewer3D.prototype.filterGuid = () => {
    // fv.restoreColorMaterial();
    refreshStatus(fv);
  };
  fv.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
    refreshStatus(fv);
  });
  fv.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, () => {
    const settingsTools = viewer.toolbar.getControl('settingsTools');
    settingsTools.removeControl('toolbar-modelStructureTool');
  });

  this.unload = () => true;
}

export default {
  init(params) {
    fvParams = params;
    const Ext = Autodesk.ADN.Viewing.Extension;
    Ext.Mscm = ScmExtension;
    Ext.Mscm.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
    Ext.Mscm.prototype.constructor = Ext.Mscm;
    Ext.Mscm.prototype.onToolbarCreated = (toolbar) => {
      const mBar = mButtonUI();
      toolbar.addControl(mBar);
    };
    Autodesk.Viewing.theExtensionManager.registerExtension('Autodesk.ADN.Viewing.Extension.Mscm', Ext.Mscm);
  },
  self: ScmExtension,
};
