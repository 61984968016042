import { last, filter } from 'lodash';
import GenerateRequest from './resources';

const req = GenerateRequest({ timeout: 180000 });

export default {
  async getViewerToken(params) {
    try {
      const { data: auth } = await req.get('lmv/viewerToken', {
        params,
      });
      return auth;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getAccessToken() {
    try {
      const { data: auth } = await req.get('lmv/oauthToken', {
        params: {},
      });
      return auth;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async setAccessToken(params) {
    try {
      const { data: auth } = await req.get('/lmv/callback', {
        params: { code: params.code },
      });
      return auth;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async getHubs() {
    try {
      const hubs = await req.get('lmv/hubs');
      return hubs.data.body;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getHubsProjects(id) {
    try {
      const projects = await req.get(
        'lmv/hubProjects',
        { params: { hubId: id } },
      );
      return projects.data.body;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getProjectFolders(params) {
    try {
      const folders = await req.get(
        'lmv/folders',
        { params },
      );
      return folders.data.body.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async getFolderContents(params) {
    try {
      const contents = await req.get(
        'lmv/contents',
        { params },
      );
      const data = filter(contents.data.body.data, (folder) => {
        const value = folder.attributes.displayName.split('.');
        if (folder.type === 'items' && !['rvt', 'dwg', 'nwd', 'nwc'].includes(last(value))) {
          return false;
        }
        return true;
      });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async getLMVStatus(params) {
    try {
      const { data: ele } = await req.get('/lmv/status', {
        params: { projectId: params.projectId },
      });
      return ele;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getLmvProperties(params) {
    try {
      const { data: ele } = await req.get(`/lmv/properties/${params.projectId}/${params.itemId}`, {
        params: { loggedUserCompany: params.company },
      });
      return ele;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getItemStatus(params) {
    try {
      const { data: ele } = await req.post('/lmv/getItemsView2', params);
      return ele;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async setForgeDirective(project) {
    try {
      const { data: urn } = await req.post(
        '/forge/setForgeDirective',
        { projectId: project._id },
      );
      return urn;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async deleteWebhook(project) {
    try {
      const { data: urn } = await req.post(
        '/forge/deleteWebhook',
        { projectId: project._id },
      );
      return urn;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async getItemUrn(params) {
    try {
      const { data: urn } = await req.get(
        '/forge/getItemUrn',
        { params },
      );
      return urn;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
};
