<template>
  <div class="modal-card">
    <!-- Modal Header -->
    <header class="modal-card-head">
      <h4 class="modal-card-title" >
        All Table Filters
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="$emit('close')"></i>
    </header>

    <!-- Modal Body -->

    <section class="modal-card-body has-background-white pb-0">
      <div v-if="$_.some(dateFilters, { visible: true })">
        <h3
          class="card-header-title is-size-3 has-text-black-bis
          is-capitalized has-text-weight-bold is-paddingless mb-2"
        >
          Date Filters
        </h3>
        <div class="column pl-0 pr-0">
          <div class="columns">
            <div class="column is-10">
              <div class="table-container filter-table">
                <o-table :data="[]">
                  <template>
                    <o-table-column
                      v-for="col in dateFilters"
                      :key="col.id"
                      :field="col.id"
                      :label="col.title"
                      :width="col.width"
                      v-bind="col"
                    >
                     <template #searchable>
                        <date-range
                            :title="col.title"
                            :key="refreshDate || col.dateFilter"
                            @filter="applyDateFilter($_.get(col, 'dateFilter'), $event)"
                            :dateValues="getFilteredDates($_.get(col, 'dateFilter'))"
                          >
                        </date-range>
                      </template>
                    </o-table-column>
                  </template>
                </o-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-filter">
        <h3
          class="is-size-3 has-text-black-bis
          is-capitalized has-text-weight-bold is-paddingless mb-2"
        >
          General Filters
        </h3>
        <div class="table-container">
          <table class="table is-bordered" :width="totalTableWidth()">
            <thead>
              <tr>
                <th v-for="filter of generalFilters" :key="filter.id" :width="filter.width">
                  {{filter.filterText}}
                  ({{totalInCategory(filter.id)}}/{{options[filter.id]?.length}})
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="filter of generalFilters" :key="filter.id">
                  <div class="all-filter-checkbox">
                    <o-checkbox v-model="allSelected[filter.id]"
                      @update:modelValue="selectOption('all',filter.id, $event)">
                      <span class="has-text-black-bis has-text-weight-bold">
                        All {{filter.filterText}}
                      </span>
                    </o-checkbox>
                    <o-checkbox v-for="opt of opts[filter.id]"
                      class="is-block"
                      v-model="opt.selected"
                      @update:modelValue="selectOption(opt, filter.id, val)"
                      :key="opt.id">
                      <span class="has-text-black-bis has-text-weight-bold">
                        {{opt.name}}
                      </span>
                    </o-checkbox>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <!-- Modal Footer -->
    <footer class="modal-card-foot is-justify-content-flex-end">
      <div class="column is-narrow">
        <button class="button is-outlined" @click="$emit('close')">
          Cancel
        </button>
        <button
          class="button has-background-black-bis"
          @click="apply()"
        >
          Done
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, watch, onBeforeMount, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import {
  includes, forEach, set, filter, find,
} from 'lodash';
import DateRange from '../DateRange.vue';
import Users from '../../models/Users';

export default defineComponent({
  name: 'AllFilters',
  props: {
    isActive: Boolean,
    generalFilters: Array,
    dateFilters: Array,
    options: Object,
    selectedFilters: Object,
  },
  components: {
    DateRange,
  },
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      opts: [],
      dates: [],
      allSelected: {},
      refreshDate: 0,
      allDates: {},
      activeScreen: store.state.activeScreen,
      linkedUsers: [],
    });

    // const state.activeScreen = () => store.state.state.activeScreen;
    const totalInCategory = (category) => state.opts[category]?.filter((opt) => opt.selected).length;

    watch(
      () => props.options,
      () => {
        state.opts = props.options;
        const filterCategories = Object.keys(state.opts);
        filterCategories.forEach((category) => {
          state.opts[category].forEach((filterData) => {
            if (category === 'types' && store.state.queryParams?.types) {
              const typeObject = find(store.state.queryParams.types, (i) => i.id === filterData.id);
              filterData.selected = typeObject ? typeObject.selected : false;
            } else if (category === 'status' && state.activeScreen === 'shipping-order-view') {
              filterData.selected = includes(props.selectedFilters.shippingStatus, filterData);
            } else {
              filterData.selected = includes(props.selectedFilters[category], filterData);
            }
          });
          state.allSelected[category] = props.options[category].length
                                      === totalInCategory(category);
        });
      },
      { immediate: true },
    );

    const selectOption = (type, category, val) => {
      if (type === 'all') {
        state.opts[category].forEach((opt) => {
          opt.selected = val;
        });
      } else {
        state.allSelected[category] = props.options[category].length === totalInCategory(category);
      }
    };

    const totalTableWidth = () => {
      let total = 0;
      props.generalFilters.forEach((filterData) => {
        const val = Number(filterData.width.replace('px', ''));
        total += val;
      });
      return total;
    };

    const apply = () => {
      emit('close');
      const params = {};
      const paramValues = {
        project: 'filteredProjects',
        nowAt: 'currentLocations',
      };
      forEach(state.opts, (value, key) => {
        key = paramValues[key] ? paramValues[key] : key;
        if (key === 'status' && state.activeScreen === 'shipping-order-view') {
          key = 'shippingStatus';
        }
        if (key === 'stage' && state.activeScreen === 'material-status-order-view') {
          key = 'materialStage';
        }
        set(params, key, filter(value, (val) => val.selected));
        if (['owner', 'recipient'].includes(key) && value?.length === state.linkedUsers.length) {
          set(params, key, null);
        }
      });
      store.commit('setQueryParams', params);
      emit('applyAllFilters');
    };

    const getFilteredDates = (dateKind) => {
      const val = {};
      val.startDate = state.allDates[`${dateKind}StartDate`];
      val.endDate = state.allDates[`${dateKind}EndDate`];
      return val;
    };

    const applyDateFilter = (dateKind, dateFilter) => {
      state.allDates[`${dateKind}StartDate`] = dateFilter.fromDate;
      state.allDates[`${dateKind}EndDate`] = dateFilter.toDate;
    };

    onMounted(async () => {
      for (const selectedOpt of props.generalFilters) {
        if (totalInCategory(selectedOpt.id) === state.opts[selectedOpt.id]?.length) {
          state.allSelected[selectedOpt.id] = true;
        }
      }
      state.linkedUsers = await Users.getCompanyProjectAllLinkedUsers({
        params: {
          companyIds: [store.state.companyData._id],
          projectId: store.getters.selectedIdsForKey('filteredProjects'),
          ignoreCompany: true,
        },
      });
    });
    onBeforeMount(() => {
      state.allDates = store.state.queryParams.allDates;
    });

    return {
      ...toRefs(state),
      selectOption,
      totalInCategory,
      totalTableWidth,
      apply,
      applyDateFilter,
      getFilteredDates,
    };
  },
});
</script>

<style>

</style>
