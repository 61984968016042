<template>
  <label class="has-text-black-bis is-italic is-size-3" v-if="label && getValue"
    :class="labelPosition === 'is-left' ? 'line-height' : 'label'">
    {{ label }}
  </label>
  <div class="is-size-3 pl-1 line-height is-flex is-justify-content-flex-end" v-if="getValue">
    <span class="has-text-weight-bold">
      {{getValue}}
    </span>
  </div>
</template>

<script>
import { computed } from 'vue';
import { find, isEmpty } from 'lodash';

export default {
  name: 'WeldField',
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  setup(props) {
    const getValue = computed(() => {
      if (!isEmpty(props.value)) return props.value.itemValue;
      return '';
    });
    return {
      getValue,
    };
  },
};
</script>

<style>

</style>
