<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <h4 class="modal-card-title" >
          Add a New Part to the Catalog
        </h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="cancel()"></i>
      </header>
      <section class="modal-card-body has-background-white pt-0 is-visible">
        <div class="line-height required-label is-size-5 is-italic mr-0">
          * required
        </div>
        <div class="columns">
          <div class="column pt-0">
            <h4 class="is-size-3">
              Details
            </h4>
            <div class="field">
              <label class="label">Name * </label>
              <div class="control  is-clearfix">
                <input class="input"
                  v-model.trim="part.name"
                  placeholder="Enter part name">
              </div>
            </div>
            <div class="field">
              <label class="label">Description
              </label>
              <div class="control  is-clearfix">
                <textarea class="textarea has-background-white"
                  placeholder="Enter a description"
                  rows="3"
                  v-model.trim="part.desc"></textarea>
              </div>
            </div>
            <div class="field">
              <label class="label">Measure Units</label>
              <div class="overflow-visible has-background-white">
                <mf-multi-select v-model="part.measureUnits"
                                 placeholder="Select a measured unit"
                                 :options="measureUnits"
                                 openDirection="below"
                                 :clear-on-select="false"
                                 class="pendo-measureUnit-select">
                </mf-multi-select>
              </div>
            </div>
          </div>
          <div class="column pt-0">
            <h4 class="is-size-3">
              Part Categorization
            </h4>
            <div class="field">
              <label class="label">Material Category</label>
              <div class="overflow-visible has-background-white">
                <mf-multi-select v-model="part.category"
                                 placeholder="Add categories to this part"
                                 :options="allCategories"
                                 label="name"
                                 track-by="_id"
                                 :clear-on-select="false"
                                 @update:modelValue="getSubCategories()"
                                 class="pendo-category-select">
                </mf-multi-select>
              </div>
            </div>
            <div class="field">
              <label class="label">
                Sub-Category
              </label>
              <div class="overflow-visible has-background-white" :key="refreshKey">
                <mf-multi-select v-model="part.subCategory"
                                 placeholder="Add sub-categories to this part"
                                 :options="allSubCategories"
                                 label="name"
                                 track-by="_id"
                                 :preselect-first="true"
                                 :allow-empty="false"
                                 class="pendo-subCategory-select">
                </mf-multi-select>
              </div>
            </div>
            <div class="field">
              <label class="label">Catalog ID * </label>
              <div class="control  is-clearfix cat-gen-btn">
                <input class="input has-background-white"
                       type="text" placeholder="Enter a Catalog ID"
                        @blur="catIdValidate($event)"
                       v-model.trim="part.catId">
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button class="button is-outlined" @click="cancel()">
          Cancel
        </button>
        <button class="button has-background-black-bis"
         :disabled="saveDisabled"
         @click="savePart"
        >
          Add
        </button>
      </footer>
    </div>
    <o-loading
      :full-page="true"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>

<script>
import {
  computed,
  defineComponent, onMounted, reactive, toRefs,
} from 'vue';
import Vendors from '@/models/Vendors';
import Catalogs from '@/models/Catalogs';
import {
  map, isEmpty, get, isUndefined, trim,
} from 'lodash';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import Validation from '@/utils/Validations';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';

export default defineComponent({
  name: 'PartsAddToCatalog',
  props: {
    addName: String,
  },
  components: {
    'mf-multi-select': MfMultiSelect,
  },
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const state = reactive({
      refreshKey: 0,
      part: {
        name: props.addName,
        desc: '',
        category: '',
        subCategory: '',
        measureUnits: '',
        catId: '',
      },
      allCategories: [],
      allSubCategories: [],
      measureUnits: [],
      isLoading: false,
    });
    onMounted(async () => {
      state.allCategories = (await Vendors.getCategories({ limit: 500 })).data;
      const { companyData } = store.state;
      state.measureUnits = map(companyData.measureUnits, (unit) => unit.name);
    });
    const saveDisabled = computed(() => isEmpty(state.part.name));

    const hasValidLength = (field, min) => trim(field).length < min;

    const validatePart = async (part, isAutoGeneratedCatId) => {
      const params = {
        generateId: false,
        catId: part.catId,
      };
      const data = await Catalogs.checkCatIds(params);
      if (data.result === 'error') {
        toast.error(data.message);
        return false;
      }
      if (!isAutoGeneratedCatId) {
        const catValidation = !Validation.validateCatalogId(part.catId);
        if (catValidation) {
          toast.error('Catalog ID should be 4 to 32 character long');
          return false;
        }
      }

      if (isEmpty(part.name) || hasValidLength(part.name, 3)) {
        toast.error('Part name cannot be Empty and name must be at least 3 characters');
        return false;
      }
      return true;
    };

    const savePart = async () => {
      let params;
      if (isEmpty(state.part.catId)) {
        params = {
          generateId: true,
          catId: '',
          category: state.part.category,
          subCategory: state.part.subCategory,
        };
        state.part.catId = (await Catalogs.checkCatIds(params)).data;
        if (!get(state.part, 'vendor')) state.part.vendor = [];
      }
      if ((await validatePart(state.part, get(params, 'generateId', false)))) {
        if (isUndefined(state.part._id)) {
          try {
            const newPart = await Catalogs.addAssemblyParts([state.part], 'items');
            emit('addedPart', newPart.data[0]);
          } catch (e) {
            const msg = typeof e === 'string' ? e : e.msg || 'Please contact ManufactOn Support';
            toast.error(`Couldn't add part. ${msg}`);
          }
        }
        emit('close');
      }
    };
    const cancel = () => {
      emit('close');
    };

    const getSubCategories = async () => {
      state.part.subCategory = '';
      if (isEmpty(state.part.category)) {
        state.allSubCategories = [];
      } else {
        state.allSubCategories = (await Vendors.getCategories({
          categoryId: state.part.category._id,
          isParent: false,
        })).data;
        state.refreshKey += 1;
      }
    };

    const catIdValidate = (event) => {
      const catId = event.target.value;
      if (!isEmpty(catId)) {
        state.part.catId = Validation.getValidCatId(catId);
        if (catId.toUpperCase() !== state.part.catId) {
          toast.success('Updated Catalog Id by removing unsupported characters');
        }
      }
    };

    return {
      ...toRefs(state),
      saveDisabled,
      savePart,
      cancel,
      getSubCategories,
      catIdValidate,
    };
  },
});
</script>
