<template>
  <div v-if="isAuthorized"
    class="mf-viewer-wrapper">
    <LmvFilter :projectId="projectId"  :isSheet="sheet" @reloadForge="reloadForge" />
    <div id="mflegend" style="display:none;"
         class="mf-legend column">
      <company-colors :legend="true" :edit="false"></company-colors>
    </div>
    <div id="forgeViewer" >

    </div>
    <div class="footer-fv" v-if="updatedTime !==''">
      Model Current as of {{updatedTime | fullDateTime}}</div>
  </div>
  <div v-else class="refresh-login is-size-4">
    <div class="has-text-weight-bold">
      Your BIM 360 login has expired
    </div>
    <div>
      <button
        @click="bim360Oauth">
        Refresh Login
      </button>
    </div>
  </div>
</template>
<script>
/* global Autodesk:true */
/* eslint no-undef: "error" */
import {
  defineComponent, computed, reactive, toRefs, watch, onUnmounted, onMounted, onBeforeMount,
} from 'vue';
import Forge from '@/models/Forge';
import ProjectsApi from '@/models/Projects';
import PropertyExtension from '@/views/lmv/extensions/extendPropertyPanel';
import ColorExtension from '@/views/lmv/extensions/stageColor';
import ScmExtension from '@/views/lmv/extensions/scm';
import MaterialManager from '@/models/MaterialManager';
import { useStore } from 'vuex';
import _ from 'lodash';
import { useRoute, useRouter } from 'vue-router';
import CompanyColors from '@/views/lmv/CompanyColors.vue';
import LmvFilter from '@/views/lmv/LmvFilter';
let viewerApp = null;

export default defineComponent({
  name: 'ForgeViewer',
  components: { 'company-colors': CompanyColors, LmvFilter },
  props: {
    urn: {
      type: String,
      default: '',
    },
    projectId: {
      type: String,
      default: '',
    },
    orderId: {
      type: String,
      default: '',
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { urn, projectId } = props;
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      viewerKey: 1,
      hasLoadedLibrary: false,
      projectSetting: '',
      token: '',
      viewerApp: null,
      isAuthorized: true,
      window: {
        width: 0,
        height: 0,
      },
      company: {
        colorScheme: store.state.colorScheme,
      },
      sheet: route?.query?.sheet,
    });
    const handleResize = () => {
      state.window.width = window.innerWidth;
      state.window.height = window.innerHeight;
    };
    const loadModel = () => {
      const config3d = {
        // loaderExtensions: { svf: 'Autodesk.MemoryLimited' }, // disable for ipad color issue
        extensions: [
          'Autodesk.ADN.Viewing.Extension.MetaPropertyPanel',
          'Autodesk.ADN.Viewing.Extension.Color',
          'Autodesk.ADN.Viewing.Extension.Mscm',
          'Autodesk.DocumentBrowser',
        ],
      };
      viewerApp = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'), config3d);
      const options = {
        // https://forge.autodesk.com/en/docs/model-derivative/v2/developers_guide/basics/preperation/
        env: 'AutodeskProduction2', // AutodeskProduction
        api: 'streamingV2', // 'derivativeV2', // for models uploaded to EMEA change this option to 'derivativeV2_EU'
        accessToken: state.token,
      };

      Autodesk.Viewing.Initializer(options, () => {
        try {
          const startedCode = viewerApp.start();
          if (startedCode > 0) {
            console.error('Failed to create a Viewer: WebGL not supported.');
            return '';
          }
        } catch (err) {
          return '';
        }

        Autodesk.Viewing.Document.load(
          `urn:${urn}`,
          (viewerDocument) => {
            viewerApp.setOptimizeNavigation(true);
            viewerApp.setProgressiveRendering(true);

            const sheetId = _.get(route.query, 'sheet', '');
            const search = { type: 'geometry' };
            if (sheetId !== '') {
              search.guid = sheetId;
            }
            const viewables = viewerDocument.getRoot().search(search);
            if (viewables.length === 0) {
              console.error('Document contains no viewables.');
              return;
            }
            viewerApp.loadDocumentNode(viewerDocument, viewables[0]);
          },
          () => { console.log('FAil'); },
        );
        return '';
      });
    };
    // onBeforeMount(() => {
    //   injectJS('https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js');
    // });

    onBeforeMount(async () => {
      const params = { urn, projectId };
      let obj;
      try {
        await router.isReady();
        obj = await Forge.getViewerToken(params);
        if (props.projectId) {
          let projects = store.state.queryParams._projects;
          if (_.isEmpty(projects)) {
            projects = await ProjectsApi.allProjects();
          }
          const selectedProject = _.find(projects, (p) => p.id === props.projectId );
          store.commit('setQueryParams', { selectedLmvProject: [selectedProject] });
        }
      } catch (e) {
        state.isAuthorized = false;
        return;
      }
      const myProject = await ProjectsApi.get(projectId);
      store.commit('setQueryParams', { project: myProject });
      const user = await store.getters.userPromise;
      const userCompany = _.get(user, 'company', '');
      state.projectSetting = _.find(
        myProject.projectSettings,
        (setting) => setting.companyId === userCompany,
      );
      state.token = obj.access_token;
      window.addEventListener('resize', handleResize);
      handleResize();
      // not required, In afterInjectJS we trigger the loadmodel
      // even after bim auth also we redirect to project tab only, so every time will hit the afterInjectJS
      // setTimeout(() => {
      //   if (state.hasLoadedLibrary) {
      //     loadModel();
      //   }
      // }, 5000);
    });
    onUnmounted(() => {
      store.commit('setQueryParams', { selectedLmvProject: [] });
      window.removeEventListener('resize', handleResize());
      viewerApp = null;
    });
    const loadExtensions = async () => {
      await router.isReady();
      const params = _.pick(props, ['urn', 'projectId', 'orderId', 'itemId']);
      if (store.state.queryParams?.selectedLmvProject[0]?.id) {
        params.projectId = _.map(store.state.queryParams?.selectedLmvProject, '_id');
      } else {
        params.projectId = props.projectId;
      }
      params.mergedItems = false;
      // for sourcing
      try {
        const order = await MaterialManager.getOne({
          cardId: params.orderId,
          projectId: params.projectId,
        });
        if (order && order.__t === 'Sourcing' && params.itemId) {
          const [item] = order.items.filter((i) => i._id === params.itemId);
          params.itemIds = item.materialItems.map((i) => i.itemId);
          params.mergedItems = true;
        }
      } catch (e) {
        console.error("Error: ", e);
      }
      params.sheetId = _.get(route.query, 'sheet', '');
      PropertyExtension.init(params);
      ColorExtension.init();
      ScmExtension.init(params);
    };
    const afterInjectJS = async () => {
      state.viewerKey += 1;
      await loadExtensions();
      state.hasLoadedLibrary = true;
      if (state.token !== '') {
        loadModel();
      }
    };
    const injectCSS = (link) => {
      const href = link;
      const cssList = document.getElementsByTagName('link');
      if (_.findIndex(cssList, { href }) === -1) {
        const tmp = document.createElement('link');
        tmp.setAttribute('rel', 'stylesheet');
        tmp.setAttribute('type', 'text/css');
        tmp.setAttribute('href', href);
        document.head.appendChild(tmp);
      }
    };
    const injectJS = (src) => {
      const jsList = document.getElementsByTagName('script');
      if (_.findIndex(jsList, { src }) === -1) {
        const tmp = document.createElement('script');
        tmp.src = src;
        tmp.onload = () => {
          afterInjectJS();
        };
        tmp.onerror = () => {
          console.log('dynamic load Error');
        };
        document.head.appendChild(tmp);
      } else {
        afterInjectJS();
      }
    };
    const bim360Oauth = () => {
      store.commit('setForgeRedirectUrl', window.location.href);
      router.push({ name: 'forge-authorize', params: { oauth: 'authorize' } });
    };
    const updatedTime = computed(() => _.get(state.projectSetting, 'forgeSync.updatedAt', ''));
    const qp = computed(() => store.state.queryParams);
    watch(
      () => state.token,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          if (state.hasLoadedLibrary) loadModel();
        }
      },
    );
    watch(
      () => store.state.queryParams,
      () => {
        if (viewerApp) {
          Autodesk.Viewing.Viewer3D.prototype.filterGuid();
        }
      },
    );
    watch(() => store.state.queryParams.initialized, (newVal) => {
      if (!newVal) return;
      if (newVal) {
        injectJS('https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js');
        injectCSS('https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css');
      }
    },
    { immediate: true });

    const reloadForge = () => {
      afterInjectJS();
    };
    return {
      ...toRefs(state),
      afterInjectJS,
      bim360Oauth,
      updatedTime,
      reloadForge,
    };
  },
});
</script>
<style scoped>
.mf-viewer-wrapper{
  height: 100vh;
}
</style>
