<template>
  <div class="card-edit" :key="refreshDetail" v-on="dirtyListeners">
    <card-edit-subheader
      v-if="cardLoaded"
      :type="type"
      :module="card.__t"
      :stage="stage"
      :card="card"
    >
    </card-edit-subheader>
    <div class="edit-body">
      <card-basics v-if="cardLoaded" :type="type" :card="card" @pause="pause" :disablePause="isDirty"
        @accordianChanged="setAccordionStatus"
        :accordionStatus="accordionStatus"
        :refreshCard="reloadPage"></card-basics>
      <div class="card-edit-item-table has-background-white">
        <mf-detail-row v-if="(cardLoaded && itemsLoaded)"
          :key="refreshDetail"
          :rowData="card"
          :stage="stage"
          :refreshCard="loadCard"
          @tabName="setTabName"
          @saveCard="saveDetails"
          @itemQtyUpdate="setItemQtyChanged"
          :tabLabel="selectedTabName"
        >
      </mf-detail-row>
      </div>
    </div>
    <transition name="slide">
      <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedItem"
        @close="closeNotesSlider"
        :isDisabled="isNotesDisabled"
      >
      </notes-icon-slider>
    </transition>
    <transition name="slide">
      <item-document-slider
        v-if="isFileListVisible"
        :isActive="isFileListVisible"
        :rowData="selectedItem"
        :rowField="rowField"
        @close="closeDocumentSlider"
        :card="card"
        :checkListOrder="checkListOrder"
        >
      </item-document-slider>
    </transition>
      <card-footer
        v-if="cardLoaded"
        :showSave="isDirty && !disableSave"
        :type="type"
        @reload-card="loadCard"
        @reload-page="reloadPage"
        :isNewCard="isNewCard"
        :card="card"
        :projectId="projectId"
        :stage="stage"
        :cardId="cardId"
        :saveCard="saveCard"
        :itemQtyChanged="itemQtyChanged"
        :key="refreshDetail"
      >
      </card-footer>
    <o-loading :active="isLoading"></o-loading>
     <excel-invalid-data
        :isActive="isInvalidModalActive"
        :loadData="loadInvalidData"
        :tableCols="getInvalidExcelCols"
        :excelFileName="excelFileName"
        @close="closeInvalidExcelModal"
      />
  </div>   
</template>

<script>
import {
  reactive, toRefs, onBeforeMount, onBeforeUnmount, nextTick, inject, computed, provide
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ProductionManager from '@/models/ProductionManager';
import CardEditSubHeader from '@/components/card-edit/CardEditSubheader.vue';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import CardBasics from '@/components/card-edit/CardBasics.vue';
import MfDetailRow from '@/components/table-fields/MfDetailRow.vue';
import { BaseOrder } from '@/models/BaseOrder';
import CardFooter from '@/components/card-edit/CardFooter.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import _ from 'lodash';
import moment from 'moment';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';
import Tasks from '@/models/Task';
import ExcelInvalidData from '@/components/modals/ExcelInvalidData.vue';
import { DialogProgrammatic } from '@/components/Dialog';

export default {
  name: 'pm-edit',
  components: {
    'card-edit-subheader': CardEditSubHeader,
    'card-basics': CardBasics,
    'mf-detail-row': MfDetailRow,
    'card-footer': CardFooter,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
    ExcelInvalidData
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    cardId: {
      type: String,
      required: true,
    },
    stage: {
      type: String,
      required: true,
    },
    orderJSON: {
      type: String,
    },
    isCutOrder: {
      type: Boolean
    }
  },
  setup(props) {
    const { cardId, projectId, orderJSON } = props;
    const emitter = inject('emitter');
    const { onInitDirty } = CardEditMixin();
    const router = useRouter();
    const store = useStore();
    // data
    const data = reactive({
      card: {},
      cardLoaded: false,
      itemsLoaded: false,
      type: '',
      isLoading: false,
      isNewCard: false,
      refreshDetail: 0,
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedItem: {},
      isItem: false,
      rowField: {},
      selectedTabName: '',
      accordionStatus: false,
      itemQtyChanged: false,
      isNotesDisabled: false,
      checkListOrder: {},
      disableSave: false,
      hideCompleted: 0,
      isInvalidModalActive: false,
      excelFileName: '',
      invalidDatas: [],
    });
    onInitDirty(data);

    const triggerLoading = (val = false) => {
      data.isLoading = val;
    }

    provide('showCardLoader', {triggerLoading});

    const { saveCard } = ProductionManagerMixin();

    const onCardLoaded = async () => {
      const stagesToDisable = ['detailing', 'qa', 'in-transit', 'complete',
        'delivery', 'released-to-inventory', 'fulfilled', 'in-storage', 'not-started'];
      data.card._customStage = props.stage;
      data.card.items.forEach((item) => {
        _.set(item, 'isEditing', true);
        if (item.stage !== props.stage || !['detailing', 'manufacturing', 'qa'].includes(item.stage)) {
          // This property is to disable editable fields
          item.$isDisabled = true;
          item.isEditing = false;
        }
        if ((props.stage === 'manufacturing' && item.stage !== props.stage)
          || stagesToDisable.includes(props.stage)) {
          // This property is to disable checkbox while tracking progress
          item.markCompleteDisabled = true;
        }
        if (!item.simpleMemos || item.simpleMemos.length === 0) {
          item.newFTD('memos');
        }
        // this is needed so that the multiselect in qa-select in mm/qa shows the previous val
        if (item.qa && item.qa.assignedTo) item.qa.assignedTo.name = item.qa.assignedTo._name;
      });
      const isPM = props.stage === 'manufacturing';
      data.card.manager.runs.forEach((t) => {
        t.isEditing = true;
        t.dirty = false;
        t.markCompleteDisabled = true;
        if (isPM) {
          /* This is to disable checkbox next to Track button. Since rowData there will be
           whole run object and not runItemCount, to access '_item.markCompleteDisabled'
           it needs looping through runItemCount, so added condition at run level  */
          t.markCompleteDisabled = false;
          /*
        By default start date should be today's date and finish date
        should take as manufacture by date(After Moving the order from
        detailing to Manufacturing if the detailing order doesn't have run dates)
        If Manufacture By is past date than today's date then both start
        and finish date should be Manufact By date.
        */
          const manufactureBy = data.card.getDate('manufactureBy');
          // if (!t.getDate('deliver')) {
          //   if (t.getDate('coord')) {
          //     const maxDate = moment.max(moment(manufactureBy), moment(t.getDate('coord')));
          //     t.addOrUpdateDate('deliver', maxDate);
          //   } else {
          //     t.addOrUpdateDate('deliver', manufactureBy);
          //   }
          // }
          // if (!t.getDate('coord')) {
          //   if (t.getDate('deliver')) {
          //     const maxDate = moment.min(moment(manufactureBy), moment(t.getDate('deliver')));
          //     t.addOrUpdateDate('coord', maxDate);
          //   } else {
          //     const minDate = moment.min(moment(manufactureBy), moment());
          //     t.addOrUpdateDate('coord', minDate.toDate());
          //   }
          // }
        }
      });
      data.card.manager.runItemsIndexMap();
      if (data.card.materials.length) {
        await data.card.getSubmitMaterials();
      }
      data.card._bomCreated = !!data.card.materials.length;
      data.refreshDetail += 1;
      if (isPM && ['manager-edit-manufacturing', 'cut-edit-manufacturing'].includes(data.type)) {
        const tasks = await Tasks.getTask({ createdFor: data.card._id });
        data.card.disableReserveBtn = tasks.length > 0;
      }
    };

    // methods
    // TODO: need to call from mixin
    const loadCard = async (opts) => {
      onInitDirty(data);
      if (data.tabName !== 'materials') data.isLoading = true;
      data.type = store.state.activeScreen;
      let order;
      if (props.cardId !== 'add') {
        const params = {
          orderId: props.cardId,
          projectId: props.projectId,
          stage: props.stage,
          isCutOrder: props.isCutOrder,
          page: opts?.page,
          search: opts?.search,
          sort: opts
        }
        if (opts?.hideCompleted === 0) {
          data.hideCompleted = 0;
        } else if (opts?.hideCompleted || data.hideCompleted) {
          params.hideCompleted = 1;
          data.hideCompleted = 1;
        }
        order = await ProductionManager.get(params);
        data.isNewCard = true;
        data.card = order;
        data.card._beforeEdit = _.cloneDeep(data.card);
        /* await nextTick();  required since props from route are not updated
        fast enough before cardInit is called */
      } else {
        order = new BaseOrder(JSON.parse(orderJSON));
      }
      // await cardInit();
      // refreshTable();
      order._customStage = props.stage;
      data.card = order;
      if (data.isNewCard) await onCardLoaded();
      data.isLoading = false;
      data.tabName = '';
    };

    const saveDetails = _.debounce(async () => {
      await saveCard(data.card._id, data.card.stage, data.card);
    }, 500);

    const saveCutCard = async (saveProps = {}) => {
      try {
        data.isLoading = true;
        data.itemsLoaded = false;
        if (saveProps && saveProps.invalidDatas?.length && saveProps.fileName) {
          data.invalidDatas = saveProps.invalidDatas
          data.excelFileName = saveProps.fileName
        }
        await saveCard(data.card._id, data.card.stage, data.card);
        await reloadPage(saveProps);
        if (data.invalidDatas.length) {
          data.isInvalidModalActive = true
        }

      } catch (error) {
        console.log(error)
      } finally {
        data.isLoading = false;
        data.itemsLoaded = true;
      }
    };

    emitter.on('itemsToolsEvent', async (opts) => {
      if (data.card.isDirty && opts.event !== 'saveCutCard') {
        const confirmParam = {
          title: 'UNSAVED CHANGES',
          message: `Current changes must be saved or
            discarded before viewing additional Items
            or changing the current view options`,
          okButton: 'Save',
          cancelButton: 'Cancel',
          type: 'success',
          additionalButton: 'Discard',
          confirmButtonStyle: 'isSolid',
          cancelBtnIsOutlined: true,
          additionalBtnStyles: {
            btnType: 'danger',
            isOutlined: true,
          },
          additionalMethod: async () => {
            await reloadPage({page: data.card.page, search: data.card.search, ...opts.params});
          },
          onConfirm: async () => {
            const { page, search, showCompletedItems } = data.card;
            await saveCutCard({page, search, showCompletedItems, ...opts.params})
          },
          onClose: () => {
            const { page, search, showCompletedItems } = data.card._beforeEdit;
            if (_.isFunction(opts.refreshToolbar)) {
              data.card.page = page;
              data.card.search = search;
              data.card.showCompletedItems = showCompletedItems;
              opts.refreshToolbar();
            }
          }
        };
        DialogProgrammatic.confirm(confirmParam);
      } else {
        if (opts.event === 'saveCutCard') {
          await saveCutCard(opts?.params ? {...opts.params} : {});
        } else if (['setPagination', 'showCompletedItems'].includes(opts.event)) {
          await reloadPage(opts.params);
        }
      }
    })

    const reloadPage = async (opts) => {   
      await loadCard(opts);
      store.commit('setreOrderRunsName', []);
    };

    const closeNotesSlider = (() => {
      data.isSlideNotesActive = false;
      data.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      data.isFileListVisible = false;
    };

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      data.isFileListVisible = !payload.isActive;
      data.selectedItem = payload.data;
      data.isItem = payload.isItem;
      data.isSlideNotesActive = payload.isActive;
      data.isNotesDisabled = payload.isDisabled;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      data.isSlideNotesActive = !payload.isActive;
      data.selectedItem = payload.data;
      data.rowField = payload.rowField;
      data.isFileListVisible = payload.isActive;
      data.checkListOrder = payload.checkListOrder || data.checkListOrder;
    });

    // mounted
    onBeforeMount(async () => {
      data.type = store.state.activeScreen;
      await loadCard();
      data.cardLoaded = true;
      data.itemsLoaded = true;
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
      emitter.off('itemsToolsEvent');
    });

    const setTabName = (val) => {
      data.selectedTabName = val;
    };
    const setItemQtyChanged = (val) => {
      data.itemQtyChanged = val;
    };

    const setAccordionStatus = (val) => { data.accordionStatus = val; };
    
    const pause = async(val) => {
      if (val.type === 'pause') {
        data.disableSave = val.s
      } else if (val.type === 'close') {
        if (val.val) {
          data.disableSave = val.s
          await loadCard();
        } else {
          data.disableSave = val.s
        }
      }
    };

    const loadInvalidData = () => data.invalidDatas;
    const getInvalidExcelCols = computed(() => store.getters.invalidExcelColumns('cut-item-import'));
    const closeInvalidExcelModal = () => {
      data.invalidDatas = [];
      data.isInvalidModalActive = false;
      data.excelFileName = ''
    };

    return {
      ...toRefs(data),
      loadCard,
      reloadPage,
      saveCard,
      saveDetails,
      closeNotesSlider,
      closeDocumentSlider,
      setTabName,
      setAccordionStatus,
      setItemQtyChanged,
      pause,
      loadInvalidData,
      getInvalidExcelCols,
      closeInvalidExcelModal
    };
  },
};
</script>

<style scoped></style>
