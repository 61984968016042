import {
  isFinite, clamp, isEmpty, get,
} from 'lodash';

export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: true,
  rearrangeCols: false,
  stickeyHeaders: true,
  detailRowComponent: 'mf-detail-row',
  customRowKey: 'uid',
  permissionModule: 'inventory',
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      tooltip: 'Customize Columns',
      isLine: true,
    }],
  },
  toolBar: {
    // name: for drop down label, label: for action button label
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: [{
      event: 'excelImport',
      icon: 'icon-importexcel',
      tooltip: 'Import From Excel',
      permissionType: 'import',
    }, {
      event: 'excelExport',
      icon: 'icon-exportexcel',
      tooltip: 'Export to Excel',
      permissionType: 'export',
    }],
    bulkActions: () => {
      const opts = [{
        name: 'Order More Inventory',
        event: 'ordermore',
        label: 'Order More Inventory',
      }, {
        name: 'Remove Inventory',
        event: 'archiveInventoryItem',
        label: 'Remove Inventory',
      }, {
        name: 'Local Inventory Transfer',
        event: 'localInventoryTransfer',
        label: 'Local Inventory Transfer',
      }, {
        name: 'Ship Inventory',
        event: 'shipInventory',
        label: 'Ship Inventory',
        refreshOnCancel: true,
      },
      {
        name: 'Print QR Labels',
        event: 'bulkQrPrint',
        label: 'Print QR',
        refreshOnCancel: true,
      }];
      return opts;
    },
  },
  fields: [
    {
      name: 'field-generic-input',
      sortField: 'name',
      width: '400px',
      sticky: true,
      title: 'Item',
      prop: 'name',
      id: 'name',
      alwaysVisible: true,
      defaultVisible: true,
      searchable: true,
      bulkActionButtonField: true,
      inputProps: {
        type: 'text',
        stageIcons: true,
        openItemView: true,
      },
    }, {
      name: 'field-generic-input',
      sortField: 'customId',
      prop: 'customId',
      width: '152px',
      title: 'Item ID',
      alwaysVisible: false,
      defaultVisible: true,
      searchable: true,
      id: 'customId',
    }, {
      name: 'field-cat-id',
      sortField: 'catId',
      width: '152px',
      title: 'Catalog ID',
      id: 'catId',
      prop: 'catId',
      alwaysVisible: false,
      defaultVisible: true,
      inputProps: {
        type: 'text',
        disableEdit(row) {
          if (!row.isEditing) return true;
          if ((row.project.projectSettings && row.project.projectSettings.isCatIdRequired)
                      || row.fromMasterCatalog) {
            return true;
          }
          return false;
        },
      },
    }, {
      name: 'activeSupplier',
      width: '296px',
      title: 'Preferred Vendor',
      sortField: 'activeSupplier',
      id: 'activeSupplier',
      defaultVisible: true,
    }, {
      name: 'measure',
      sortField: 'measure',
      width: '112px',
      title: 'Meas',
      id: 'measure',
      defaultVisible: false,
      iPadVisible: true,
    }, {
      name: 'measureUnits',
      sortField: 'measureUnits',
      width: '112px',
      title: 'Meas Units',
      id: 'measureUnits',
      defaultVisible: false,
      iPadVisible: true,
      formatter(row) {
        if (!isEmpty(row)) return row.toUpperCase();
        return '';
      },
    }, {
      name: 'available',
      sortField: 'available',
      width: '112px',
      title: 'In Stock',
      id: 'available', // do not change the id as it is relied upon for inventory adjust
      defaultVisible: true,
      iPadVisible: true,
    }, {
      name: 'minStock',
      sortField: 'minQuantity',
      width: '112px',
      title: 'Min Stock',
      id: 'minQuantity',
      prop: 'minQuantity',
      defaultVisible: true,
      restrict: (val) => {
        val = parseInt(val, 10);
        if (!isFinite(val)) {
          return null;
        }
        return clamp(val, 0, 9999);
      },
      inputProps: {
        type: 'number',
        min: 0,
        step: 1,
      },
    },
    {
      name: 'maxStock',
      sortField: 'maxQuantity',
      width: '112px',
      title: 'Max Stock',
      id: 'maxQuantity',
      prop: 'maxQuantity',
      alwaysVisible: false,
      defaultVisible: true,
      restrict: (val) => {
        val = parseInt(val, 10);
        if (!isFinite(val)) {
          return null;
        }
        return clamp(val, 0, 9999);
      },
      inputProps: {
        type: 'number',
        min: 1,
        step: 1,
      },
    }, {
      name: '',
      width: '112px',
      title: 'In Delivery',
      id: 'delivery',
      sortField: 'delivery',
      defaultVisible: true,
      showNegative: false,
      classRight: 'is-pulled-right',
      formatter(item) {
        return item.delivery < 0 ? 0 : item.delivery;
      },
    }, {
      name: 'reserved',
      sortField: 'reserved',
      width: '112px',
      title: 'Reserved',
      id: 'reserved',
      classRight: 'is-pulled-right',
      defaultVisible: true,
    }, {
      name: 'consumed',
      sortField: 'consumed',
      width: '112px',
      title: 'Consumed',
      id: 'consumed',
      defaultVisible: true,
      classRight: 'is-pulled-right',
    }, {
      title: 'Notes',
      name: 'inventory-notes',
      id: 'notes',
      width: '152px',
      defaultVisible: true,
    }, {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      width: '112px',
      sticky: true,
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      hideFromChooser: true,
      alwaysVisible: true,
      permissionModule: 'inventory',
      permissionKey: 'save',
      inputProps: {
        showExcelIcon: false,
        options(data, store) {
          const project = store.getters.findProject({ _id: data.project._id });
          const optionsToShow = [
            {
              label: 'Print QR',
              event: 'printQr',
              icon: 'fa fa-qrcode',
            },
          ];
          if (['Part'].includes(data.type) && !get(project, 'isGI', false)) {
            optionsToShow.push({
              label: 'Order More',
              event: 'orderMoreInv',
              icon: 'icon-box-with-plus is-size-3',
            });
          }
          return optionsToShow;
        },
        showEdit(rowData) {
          return isEmpty(rowData.catId);
        },
        addButtonSpace: true,
      },
    },
  ],
};
