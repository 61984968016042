<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <div class="list-view">
    <TableGutter
      :tools="tableProps.gutterOpts"
      :selectedAction="isBulkActionEnabled"
      @cc-modal-closed="triggerSetHeaderHeight"
      @re-render-table="rerenderMfTable += 1"
    >
      <template v-slot:selectStock>
        <div class="column">
          <div class="columns  is-flex is-align-items-center pr-0">
            <div class="column is-9 is-7-desktop is-flex is-align-items-center">
                <button class="button px-0 is-pulled-left mr-2"
                  :disabled="isBulkActionEnabled !== null"
                  @click="selectLocation">
                  <i class="icon-attachedlocationsOL"></i>
                </button>
                <span v-if="filteredLocationCount === totalLocationCount"
                  class="has-text-left has-text-black-bis
                  is-size-5 text-overflow text-clamp line-height">
                  All Locations
              </span>
              <span v-else
                class="has-text-left has-text-black-bis
                is-size-5 text-overflow text-clamp line-height">
                {{getFilteredLocationName}}
              </span>
            </div>
            <div class="column is-narrow">
               <button class="button pointer px-0 has-text-black-bis">
                 <i class="icon-activityhistory is-size-3" @click="showActivity"></i>
               </button>
            </div>
            <div class="is-divider-vertical mx-0"></div>
            <div class="column is-narrow">
              <label class="label has-text-black-bis is-size-3 is-italic has-text-weight-bold">
                Stock Status:
              </label>
            </div>
            <div class="column">
              <mf-multi-select
                :disabled="isBulkActionEnabled !== null"
                :modelValue="getSelectedVal()"
                track-by="value"
                label="label"
                :multiple="false"
                :options="viewOptions"
                :allow-empty="false"
                :closeOnSelect="true"
                @update:modelValue="setView($event)"
              ></mf-multi-select>
            </div>
          </div>
        </div>
      </template>
    </TableGutter>
    <mf-table
      ref="inventoryTable"
      :tableProps="tableProps"
      :loadData="loadData"
      :tableName="stageFromUrl"
      :screenTable="true"
      :hideGutter="true"
      :apiMode="true"
      @cell-clicked="onCellClicked"
      :key="rerenderMfTable"
    >
    <template v-slot:available="{ rowData: item }">
       <span class="is-pulled-right">{{item.available}}</span>
      <i v-if="showLowStock(item)"
        class="fa fa-exclamation-triangle has-text-danger is-pulled-right">
      </i>
    </template>
    <template v-slot:minStock="{ rowData: item }">
     <span class="is-pulled-right">{{item.minQuantity}}</span>
    </template>
    <template v-slot:maxStock="{ rowData: item }">
      <span class="is-pulled-right">{{item.maxQuantity}}</span>
    </template>
    <template v-slot:project="{ rowData }">
      {{rowData.project.name}}
    </template>
    <template v-slot:locName="{ rowData }">
      {{rowData.locName}}
    </template>
    <template v-slot:reserved="{ rowData }">
      <div class="has-text-right">
        {{ rowData.reserved.toFixed(4) }}
      </div>
    </template>
    </mf-table>
    <o-modal :active="exelTypeSelectModal" :canCancel="false" class="modal-xs">
      <div class="modal-card">
        <header class="modal-card-head is-borderless">
          <h3 class="card-header-title p-0 has-text-black-bis">
            Select Location Type
          </h3>
        </header>
        <section class="modal-card-body is-visible has-text-black-bis">
          <h3 class="mb-5">What type of location will you import?</h3>
          <div v-for="opt in radioOpts" :key="opt.key"
            class="mb-2 has-text-black-bis line-height">
            <input type="radio"
              :id="opt.key"
              :value="opt.key"
              v-model="excelType"
            />
            <label :for="opt.key" class="has-text-black-bis">{{opt.title}}</label>
          </div>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
          <button class="button is-outlined"
            @click="() => exelTypeSelectModal = false"
          >Cancel</button>
          <button
            class="button has-background-black-bis"
            :disabled="$_.isEmpty(excelType)"
            @click="importExcel()"
          >Select</button>
        </footer>
      </div>
    </o-modal>
    <excel-import
      ref="excel"
      :isExcelModalActive="excelImportModal"
      :saveExcelItem="saveExcelItem"
      :selectedProjectId="selectedProject"
      @close-modal="closeExcelModal"
      :validateExcelItem="validateRow"
      :batchSize="500"
      :importLimit="500"
      importModule="inventory-import"
      modalHeaderTitle="import inventory items from excel"
      :isNestedLocImport="isNestedLocImport"
      @refresh-table="inventoryTable.value?.refreshTable()">
      <template #project v-if="!isNestedLocImport">
        <td width="40%">Project*</td>
        <td width="60%">
          <mf-multi-select
            :options="groupedProjects"
            :multiple="false"
            v-model="selectedProject"
            group-values="projects"
            group-label="type"
            label="name"
            track-by="name"
            @update:modelValue="fetchLocations"
            class="pendo-import-project-select"
            @select="checkToDisableNext"
          ></mf-multi-select>
        </td>
      </template>
    </excel-import>
    <activity-log
      :isActive="isActive"
      :projectId="activityDetails.projectId"
      @update:isActive="isActive=false">
    </activity-log>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  ref, watch,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';
import { useToast } from 'vue-toastification';
import SupplyChain from '@/models/SupplyChain';
import Locations from '@/models/Locations';
import Print from '@/components/utils/Print';
import tableDefinition from '@/components/table-cols/inventoryCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import TableGutter from '@/components/TableGutter.vue';
import CreateMaterialFromInv from '@/components/modals/CreateMaterialFromInv.vue';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import ExcelImport from '@/components/ExcelImport.vue';
import Projects from '@/models/Projects';
import Catalogs from '@/models/Catalogs';
import Validation from '@/components/utils/Validations';
import ExcelExportMixin from '@/components/mixins/ExcelExportMixin';
import AddLocation from '@/components/modals/AddLocation.vue';
import ActivityLog from '../components/ActivityLog.vue';

export default defineComponent({
  name: 'InventoryManager',
  components: {
    MfTable,
    TableGutter,
    ExcelImport,
    ActivityLog,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const inventoryTable = ref(null);
    const excel = ref(null);
    const { excelExport } = ExcelExportMixin();
    const stageFromUrl = computed(() => route.path.split('/').pop());
    const isBulkActionEnabled = computed(() => inventoryTable?.value?.selectedBulkAction);
    const viewOptions = [
      { label: 'All Items', value: 'showAll' },
      { label: 'Overstocked', value: 'showOverStock' },
      { label: 'In Stock', value: 'isAvailable' },
      { label: 'Out of Stock', value: 'showOutofStock' },
      { label: 'Understocked', value: 'showUnderStock' },
    ];
    const state = reactive({
      selectedProject: null,
      openedRows: [],
      projectLocations: [],
      isLoading: false,
      isActive: false,
      tableProps: tableDefinition,
      tempInStockVal: '',
      multiCreateMaterialActive: computed(() => store.state.multiCreateMaterialActive),
      multiCreateTransferReqActive: computed(() => store.state.multiCreateTransferReqActive),
      projectLocns: computed(() => Locations.groupLocations(state.projectLocations,
        store.state.companyData)),
      radioOpts: [
        {
          title: 'Nested locations',
          key: 'nested',
          postSaveAction: 'nested',
        },
        {
          title: 'Classic (non-nested) locations',
          key: 'classic',
          postSaveAction: 'classic',
        },
      ],
      activityDetails: {
        projectId: '',
      },
      excelType: '',
      exelTypeSelectModal: false,
      excelImportModal: false,
      requiredCheck: [
        { module: 'inventory', key: 'quantity' },
        { module: 'material-manager', key: 'data' },
        { module: 'material-manager', key: 'move' },
        { module: 'shipping', key: 'data' },
        { module: 'shipping', key: 'receive' },
      ],
      transferReq: false,
      catIdsFetched: false,
      existingCatIds: [],
      importFailCount: 0,
      excelFileName: '',
      tableData: [],
      rerenderMfTable: 0,
      filteredLocationCount: 0,
      totalLocationCount: 0,
    });
    const qp = computed(() => store.state.queryParams);
    state.filteredLocationCount = qp.value.selectedCompanyLocations.length;
    state.totalLocationCount = qp.value.allLocationsLength;
    const isNestedLocImport = computed(() => state.excelType === 'nested');
    const allProjects = computed(() => store.state.queryParams.projects);
    const groupedProjects = computed(() => {
      let projects = store.getters
        .getProjectsOnPermission('shipping', allProjects.value, 'receive');
      if (!_.isEmpty(projects)) {
        state.requiredCheck.forEach((rc) => {
          if (!_.isEmpty(projects)) {
            projects = store.getters
              .getProjectsOnPermission(rc.module, projects, rc.key);
          }
        });
      }
      return Projects.groupProjects(projects);
    });
    const getFilteredLocationName = computed(() => {
      const { selectedCompanyLocations: locations } = store.state.queryParams;
      if (!_.isEmpty(locations)) {
        let allLocations = locations;
        if (locations.length > 12) {
          allLocations = locations.slice(0, 12);
        }
        return _.map(allLocations, 'name').toString().replaceAll(',', ', ');
      }
      return '';
    });
    const closeExcelModal = async () => {
      state.excelImportModal = false;
      state.excelType = '';
      state.catIdsFetched = false;
      await inventoryTable.value?.refreshTable();
    };
    const fetchLocations = async () => {
      const user = await store.getters.userPromise;
      if (state.transferReq) {
        const allLocs = (await Locations.allCompanyLocations({
          companyId: user.company,
          type: 'company_location',
        })).data;
        state.projectLocations = _.filter(allLocs, (loc) => loc.nestedLocation);
        return;
      }
      let fetchedLocations = await Locations.allLocationForUser({
        project: state.selectedProject._id || state.selectedProject[0]._id,
        hideRoot: true,
      });
      if (state.selectedProject.isGI) {
        fetchedLocations = _.filter(fetchedLocations, { type: 'gi_location' });
      } // This is the requirement, we have to only show GI locations but not company locations
      state.projectLocations = fetchedLocations;
    };
    const fetchNestedDetails = async () => {
      const user = await store.getters.userPromise;
      const [projects, locations] = await Promise.all([
        Projects.allProjects({ isCommonStockProject: true }),
        Locations.allCompanyLocations({
          companyId: user.company,
          type: 'company_location',
          limit: 9999,
          showNestedLocs: true,
        }),
      ]);
      _.set(state, 'selectedProject', _.find(projects, { isCommonStockProject: true }));
      state.projectLocations = _.filter(locations.data, { nestedLocation: true });
    };
    const checkToDisableNext = (project) => {
      excel.value.checkToDisableNext(project);
    };
    const excelModalActive = async () => {
      if (isNestedLocImport.value) {
        await fetchNestedDetails();
        return;
      }
      _.set(state, 'selectedProject', store.state.queryParams.projects[0]);
      checkToDisableNext(state.selectedProject);
      if (state.selectedProject) await fetchLocations();
    };
    const importExcel = () => {
      state.exelTypeSelectModal = false;
      excelModalActive();
      state.excelImportModal = true;
    };
    const trimString = (str) => str.replace(/\s{2,}/g, ' ').trim();
    const locationCheck = (item) => {
      if (_.includes(state.projectLocations.map((loc) => (
        isNestedLocImport.value ? loc.fullAddress.toLowerCase() : loc.name.toLowerCase())),
      isNestedLocImport.value ? (`${item.location}/`).toLowerCase() : item.location.toLowerCase())) {
        return item.location;
      }
      return null;
    };
    function isValidInteger(item, field) {
      return !(Number.isNaN(parseInt(item[field], 10)) || item[field] > 9999 || item[field] < 0);
    }
    function isValidFloat(item, field, max = 9999) {
      return !(Number.isNaN(parseFloat(item[field])) || item[field] > max || item[field] < 0.01);
    }
    const validateRow = async (item, obj) => {
      if (item.locName) item.location = item.locName;
      let errMsg = '';
      if (!state.catIdsFetched) {
        // fetch just once
        let excelCatIds = _.compact(_.map(obj, 'catId'));
        excelCatIds = excelCatIds.map(
          (catId) => (catId ? catId.toString().toUpperCase().trim() : null),
        );
        const existingCatalogs = await Catalogs.getAssemblyParts({
          catId: _.compact(excelCatIds),
          showAssmbsParts: true,
          limit: 9999,
          page: 1,
        });
        state.existingCatIds = _.map(existingCatalogs.data, 'catId');
        state.catIdsFetched = true;
      }
      item.catId = item.catId ? item.catId.toString().toUpperCase().trim() : item.catId;
      if (_.isEmpty(item.catId)) {
        errMsg = 'Catalog ID is missing';
      } else if (item.catId
       && !state.existingCatIds.includes(item.catId)) {
        errMsg = 'Catalog ID not recognized';
      } else if (!item.location) {
        errMsg = 'Location should not be empty';
      } else if (!locationCheck(item)) {
        errMsg = isNestedLocImport.value ? 'Full address does not match any existing nested locations.' : 'Location must be a part of project locations';
      } else if (!Validation.validateSupplier(item.activeSupplier, false, false)) {
        errMsg = 'Supplier name should have atleast 3 characters and cannot start with special characters';
      } else if (!_.isEmpty(item.minQuantity) && !isValidInteger(item, 'minQuantity')) {
        errMsg = 'Min Stock should be an integer in the range 0 - 9999';
      } else if (!_.isEmpty(item.maxQuantity) && !isValidInteger(item, 'maxQuantity')) {
        errMsg = 'Max Stock should be an integer in the range 0 - 9999';
      } else if (!_.isEmpty(item.minQuantity) && !_.isEmpty(item.maxQuantity)
        && parseInt(item.maxQuantity, 10) < parseInt(item.minQuantity, 10)) {
        errMsg = 'Minimum Quantity should not be greater than Maximum Quantity';
      } else if (!_.isEmpty(item.quantity) && !isValidInteger(item, 'quantity')) {
        errMsg = 'Quantity should be an integer in the range 0 - 9999';
      } else if (item.catId && !Validation.validateCatalogId(item.catId)) {
        errMsg = 'Catalog ID should be 4 to 32 character long';
      }
      if (!errMsg && item.measure) {
        if (!isValidFloat(item, 'measure')) {
          errMsg = 'Measure should be an integer in the range 0.01 - 9999';
        } else if (!item.measureUnits) {
          errMsg = 'If measure field has value, then measure units field becomes mandatory';
        }
      } if (!errMsg && item.measureUnits) {
        if (!item.measure) {
          errMsg = 'If measure units field has value, then measure field becomes mandatory';
        } else if (!Validation.measureUnitsCheck(item, store.state.companyData)) {
          errMsg = 'Measure units must be a part of company measure units';
        }
      }
      if (errMsg) state.importFailCount++;
      return errMsg;
    };
    const loadData = async (commonParam) => {
      if (_.isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
      let idReqPrms = {
        catId: store.state.queryParams.catId || undefined,
      };
      const idType = JSON.parse(_.get(route.params, 'isCatId', null));
      let openAllRows = false;
      if (_.isNumber(idType)) {
        const routeParamMap = {
          0: 'itemId',
          1: 'catId',
        };
        if (Object.keys(routeParamMap).map(Number).includes(idType)) {
          idReqPrms = { [routeParamMap[idType]]: route.params.itemQrId };
        }
        openAllRows = true;
      }
      let selectedLocations = store.state.queryParams.selectedCompanyLocations;
      // since the project and location select modal doesnt return the entore location object
      // below code is to get the entire location object for selected location from modal.
      if (!_.isEmpty(isBulkActionEnabled.value) && isBulkActionEnabled.value.event === 'shipInventory') {
        const prjId = store.getters.selectedIdsForKey('filteredProjects')
        if (!_.isEmpty(prjId)) {
          let fetchedLocations = await Locations.allLocationForUser({
            project: prjId[0],
            hideRoot: true,
          });
          if (!_.isEmpty(fetchedLocations)) {
            const newLocs = [];
            selectedLocations.forEach((l) => {
              const loc = _.find(fetchedLocations , { _id: l._id });
              if (!_.isEmpty(loc)) newLocs.push(loc);
            })
            selectedLocations = [...newLocs]
          }
        }
      }
      if (!_.isEmpty(isBulkActionEnabled.value) && (isBulkActionEnabled.value.event !== 'shipInventory')) {
        selectedLocations = selectedLocations.filter((loc) => loc.level === 1).map((loc) => loc._id);
      }
      const params = {
        ...idReqPrms,
        projectId: store.getters.selectedIdsForKey('filteredProjects'),
        locs: selectedLocations,
        company: store.state.userData.company,
        ...commonParam,
      };
      if (store.state.queryParams.getOnlyRoot) {
        params.projectId = store.state.queryParams.project._id;
        params.getOnlyRoot = true;
        state.toggleInvVal = false;
      }
      if(store.state.queryParams?.project?.isCommonStockProject && isBulkActionEnabled?.value?.event === 'shipInventory'){
        params.hasRootNonEmpty = true;
      }
      params.inStock = state.tempInStockVal || store.getters.selectedInStock;
      let qryCardType = store.getters.selectedValuesForKey('source', true);
      if (state.multiCreateMaterialActive || state.multiCreateTransferReqActive) {
        qryCardType = ['Part'];
        const selectedProjId = _.get(state.selectedProject, '_id', params.projectId);
        let selectedLocId;
        if (!_.isEmpty(state.projectLocns) && state.multiCreateTransferReqActive) {
          selectedLocId = state.projectLocns[0].val[0]._id;
        } else if (state.multiCreateTransferReqActive) {
          toast.warning('Please add a nested location in company profile to create stock request');
        }
        params.projectId = !_.isArray(selectedProjId)
          ? _.castArray(selectedProjId) : selectedProjId;
        if (!_.isEmpty(selectedLocId)) {
          params.locs = !_.isArray(selectedLocId)
            ? _.castArray(selectedLocId) : selectedLocId;
        }
      }
      if (qryCardType.length !== 0) {
        Object.assign(params, { baseCardType: qryCardType });
      }
      const response = await SupplyChain.inventory(params, false, true);
      // if (this.checkedList && page === 1) this.checkedList = [];
      if (openAllRows) {
        state.openedRows = _.map(response.data, '_id.uid');
      }
      // this.openRows();
      // this.transferReq = false;
      if (store.state.queryParams.getOnlyRoot) {
        const itemsData = [];
        for (const item of response.data) {
          if ((!item.available || item.available === 0) && params.inStock !== 'showAll') continue;
          item.isInventoryItem = true;
          item.initialAvailable = item.available;
          item.selectedQty = item.available;
          itemsData.push(item);
        }
        state.tableData = itemsData;
        response.data = itemsData;
      } else {
        state.tableData = response.data;
      }
      if (inventoryTable.value?.selectedBulkAction?.event === 'localInventoryTransfer') {
        response.data = _.filter(response.data, { nestedLocation: true });
      }
      return { data: response.data, total: response.total };
    };
    const setLocationForItems = (obj, addLocId = false) => {
      const locIdKeyedItems = {};
      let locationNameIdMap = state.projectLocations.map((loc) => ({
        _id: loc._id,
        name: isNestedLocImport.value ? loc.fullAddress.toLowerCase()
          : loc.name.toLowerCase(),
      }));
      locationNameIdMap = _.uniqBy(locationNameIdMap, 'name');
      const locationNameMap = _.map(locationNameIdMap, 'name');
      const newObj = _.filter(obj, (item) => {
        item.location = item.location
          ? item.location.toLowerCase().concat(isNestedLocImport.value ? '/' : '') : '';
        if (locationNameMap.includes(item.location) && !_.isEmpty(item.location)) {
          if (addLocId) {
            ({ _id: item.locationId } = locationNameIdMap.find((l) => l.name === item.location));
            item.projectId = state.selectedProject._id;
          }
          return true;
        }
        return false;
      });
      state.importFailCount += obj.length - newObj.length;
      return { locIdKeyedItems, locationNameIdMap, newObj };
    };
    const saveExcelItem = async (items) => {
      try {
        if (_.isEmpty(state.selectedProject)) {
          throw new Error('Project field is Mandatory');
        }
        items.forEach((item) => {
          item.inventoryNotes = _.get(item, 'inventoryNotes', '').substring(0, 32);
          item.catId = item.catId ? item.catId.toString().toUpperCase().trim() : '';
          item.location = item.location ? item.location.toString() : '';
          item.maxQuantity = item.maxQuantity ? item.maxQuantity.toString() : '';
          item.minQuantity = item.minQuantity ? item.minQuantity.toString() : '';
          item.quantity = item.quantity ? item.quantity.toString() : 0;
          item.measure = item.measure ? item.measure.toString() : '';
        });
        if (!isNestedLocImport.value) {
          toast.warning('Item Name and Id will be in sync with Master Catalog!');
        }
        if (!_.isEmpty(items)) {
          let itemsWithLocs = [];
          ({ newObj: itemsWithLocs } = setLocationForItems(items, true));
          await SupplyChain.importInventoryItems(itemsWithLocs);
        }
      } catch (e) {
        console.log('Error', e);
        toast.error(e.message || e.msg || e || 'Error while importing items');
      }
    };
    // eslint-disable-next-line consistent-return
    const saveRow = async (imItem) => {
      if (!imItem.minQuantity || (parseFloat(imItem.minQuantity) < 0)) {
        imItem.minQuantity = 0;
      }
      imItem.projectId = imItem.project._id;
      if (!_.isEmpty(imItem.catId)) {
        imItem.catId = Validation.getValidCatId(imItem.catId);
        const catValidation = !Validation.validateCatalogId(imItem.catId);
        if (catValidation) {
          toast.error('Catalog ID should be 4 to 32 character long');
          return false;
        }
      }
      try {
        const updatedItem = await SupplyChain.itemUpdate(imItem);
        _.assign(imItem, updatedItem.item);
        if (!_.isEmpty(imItem.catId)) {
          imItem._id = { catId: imItem.catId, uid: imItem.uid };
        }
        _.set(imItem, 'isEditing', false);
        toast.success('Item saved');
        const isMultipleEditOpen = _.some(state.tableData, { isEditing: true });
        if (!isMultipleEditOpen) await inventoryTable.value?.refreshTable();
      } catch (e) {
        console.log(e);
        toast.error(_.get(e, 'data.message', 'Update Failed'));
      }
    };
    const onCellClicked = async (event) => {
      const { type, data } = event;
      if (type === 'orderMoreInv') {
        ModalProgrammatic.open({
          component: CreateMaterialFromInv,
          props: {
            itemsData: [data],
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-md',
        });
      } else if (type === 'printQr') {
        if (data.available === 0) {
          toast.error('Cannot print the QR because the available quantity is zero');
          return;
        }
        const params = {
          uid: data.uid,
          projectId: data.project[0]._id,
          locId: data.rootLoc._id,
        };
        const invItem = await SupplyChain.inventoryQr(params);
        if (_.isEmpty(invItem)) {
          toast.error('Something went wrong');
        }
        const user = store.state.userData;
        await Print.printQrLabel(invItem, user);
      } else if (event.type === 'excelImport') {
        state.exelTypeSelectModal = true;
      } else if (type && type === 'excelExport') {
        excelExport();
      } else if (type === 'save') {
        await saveRow(data);
      }
    };
    const showLowStock = (item) => item.available < item.minQuantity;

    const setView = async (event) => {
      const storeKey = 'inStock';
      store.commit('setQueryParams', { [storeKey]: event });
      await inventoryTable.value.refreshTable();
    };

    const getSelectedVal = () => qp.value.inStock;
    const selectLocation = () => {
      ModalProgrammatic.open({
        component: AddLocation,
        props: {
          for: 'filter',
        },
        trapFocus: true,
        canCancel: false,
        events: {
          'refresh-table': async () => {
            inventoryTable.value.refreshTable();
          },
          'location-count': () => {
            const { selectedCompanyLocations: filteredLocations } = store.state.queryParams;
            const { allLocationsLength: allLocations } = store.state.queryParams;
            state.totalLocationCount = allLocations;
            state.filteredLocationCount = filteredLocations.length;
          },
        },
        rootClass: 'modal-sm',
      });
    };
    const resetCompanyLocation = async () => {
      if (!_.isEmpty(store.state.queryParams.selectedCompanyLocations)) return;
      let projects = [];
      if (!_.isEmpty(store.state.queryParams.projects)) {
        projects = _.castArray(store.state.queryParams.projects);
      }
      projects = _.compact(_.map(projects, '_id'));
      const allCompanies = (await Projects.getSubCompany({
        showUser: 'all',
        sendLinkedCompanies: true,
        projectId: projects,
      }, true)).items;
      const allLocns = await Locations.allLocationForUser({
        projectIds: projects,
        companyIds: _.compact(_.map(allCompanies, '_id')),
      }, true);
      const locGroups = _.filter(
        Locations.groupLocations(allLocns, store.state.companyData),
        (group) => group.val && group.val.length > 0,
      );
      const allLocs = _(locGroups).map('val').flatten().value();
      store.commit('setAllLocationsLength', allLocs.length);
      store.commit('setQueryParams', { selectedCompanyLocationGroup: locGroups[0].key });
      store.commit('setQueryParams', { selectedCompanyLocations: allLocs });
      store.commit('setQueryParams', { selectedOldCompanyLocations: allLocs });
    };

    onBeforeMount(async () => {
      store.commit('setMultiCreateMatActive', false);
      store.commit('setMultiRemoveActive', false);
      store.commit('setMultiShipInventoryActive', false);
      store.commit('setQueryParams', { getOnlyRoot: false });
      const { selectedOldCompanyLocations } = store.state.queryParams;
      if (!_.isEmpty(selectedOldCompanyLocations)) {
        store.commit('setQueryParams', { selectedCompanyLocations: _.castArray(selectedOldCompanyLocations) });
      }
      await resetCompanyLocation();
    });

    watch(
      () => store.state.multiCreateMaterialActive,
      () => {
        inventoryTable.value.refreshTable();
      },
    );

    watch(
      () => store.state.multiRemoveActive,
      (newVal) => {
        if (newVal !== '' && newVal === true) {
          state.tempInStockVal = 'showOutofStock';
        } else {
          state.tempInStockVal = '';
        }
        inventoryTable.value.refreshTable();
      },
    );

    watch(
      () => store.state.multiShipInventoryActive,
      () => {
        if (!store.state.multiShipInventoryActive) resetCompanyLocation();
        inventoryTable.value.refreshTable();
      },
    );
    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      inventoryTable?.value?.closeColumnChooserModal();
    };
    const showActivity = () => {
      state.isActive = true;
      state.activityDetails.projectId = store.getters.selectedIdsForKey('projects');
    };
    return {
      ...toRefs(state),
      loadData,
      stageFromUrl,
      inventoryTable,
      onCellClicked,
      showLowStock,
      getSelectedVal,
      viewOptions,
      setView,
      groupedProjects,
      isNestedLocImport,
      closeExcelModal,
      fetchLocations,
      validateRow,
      saveExcelItem,
      excel,
      checkToDisableNext,
      importExcel,
      selectLocation,
      resetCompanyLocation,
      getFilteredLocationName,
      isBulkActionEnabled,
      triggerSetHeaderHeight,
      showActivity,
    };
  },
});
</script>

<style scoped>
div >>> .o-table__detail td:first-child {
  padding-right: 0;
}
</style>
