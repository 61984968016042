<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
    <div class="list-view">
      <TableGutter
        :selectedAction="isBulkActionEnabled"
        :tools="tableProps.gutterOpts"
        @cc-modal-closed="triggerSetHeaderHeight"
        @re-render-table="rerenderMfTable += 1"
        @cell-clicked="onCellClicked"
      >
      </TableGutter>
      <mf-table
        ref="formsListTable"
        :tableProps="tableProps"
        :loadData="loadData"
        :screenTable="true"
        :hideGutter="true"
        tableName="forms"
        @cell-clicked="onCellClicked"
        :key="rerenderMfTable"
      ></mf-table>
      <o-modal
        :active="openForm"
        :canCancel="false"
        class="modal-sm"
        :class="[isHeadless ? 'hide-tab' : '']"
        >
        <div class="modal-card">
          <header>
            <div class="modal-card-head">
              <p class="modal-card-title">
                Form Preview
              </p>
            </div>
          </header>
          <section class="modal-card-body has-background-white" id="printJS-form">
             <div v-for="(templateForm, idx) of templateForms" :key="idx" class="newpage">
              <div v-html="getHeader(true, templateForm, idx)">
             </div>
              <form-builder
              :templateForm="templateForm.formData"
              :editForm="false"
              :showForm="!isHeadless"
              @loaded="printFormsView"
              ref="form"
              >
              </form-builder>
             </div>
          </section>
            <footer class="modal-card-foot is-justify-content-flex-end">
            <button class="button is-outlined" true type="button" @click="() => {openForm = false}">
              Cancel
            </button>
          </footer>
        </div>
    </o-modal>
    </div>
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  ref,
  watch,
} from 'vue';
import _ from 'lodash';
import Todo from '@/models/Todo';
import tableDefinition from '@/components/table-cols/formsListCols';
import TableGutter from '@/components/TableGutter.vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import FormPrintMixin from '@/components/mixins/FormPrintMixin';
import { useToast } from 'vue-toastification';
import FormBuilder from '@/components/FormBuilder';
import ExcelExport from '@/models/ExcelExport';
import { useStore } from 'vuex';

export default {
  name: 'FormsList',
  components: {
    TableGutter,
    MfTable,
    'form-builder': FormBuilder,
  },
  setup() {
    const formsListTable = ref(null);
    const store = useStore();
    const toast = useToast();
    const state = reactive({
      isLoading: false,
      isActive: false,
      tableProps: tableDefinition,
      openForm: false,
      isHeadless: true,
      templateForms: [],
      templateForm: null,
      orderData: null,
      formOrder: null,
      rerenderMfTable: 0,
    });
    const { getHeader, printPdf } = FormPrintMixin();
    const isBulkActionEnabled = computed(() => formsListTable?.value?.selectedBulkAction);

    const filteredProject = computed(() => store.state.queryParams.project);

    // trigger print form
    const triggerPrint = async (opts) => {
      state.openForm = true;
      const formId = opts._id;

      // fetch Form data
      state.templateForms = await Todo.getCompletedFormData({
        templateId: formId,
        projectId: filteredProject.value._id,
      });
    };

    const printFormsView = () => {
      printPdf({
        fromList: true,
      });
    };

    const getCompanyIds = computed(() => {
      const { userData } = store.state;
      let companyId = userData.company;
      if (store.getters.isViewer()) {
        companyId = store.getters.selectedIdsForKey('companies', false);
      }
      return companyId;
    });

    const exportExcel = async (opts) => {
      const card = await Todo.getFormBuilder({
        cardId: opts._id,
      });
      const excelHeaders = _.compact(_.map(card.formData, (data) => {
        if (data.label) {
          if (!['header', 'paragraph'].includes(data.type)) {
            return {
              field: data.name,
              title: data.label,
              show: true,
              disabled: false,
              sortAttr: data.label,
            };
          }
          if (data.type === 'paragraph' && data.className === 'time-saved') {
            return {
              field: 'Date/Time Saved',
              title: 'Date/Time Saved',
              show: true,
              disabled: false,
              sortAttr: 'Date/Time Saved',
            };
          }
        }
        return null;
      }));
      excelHeaders.unshift({
        field: 'Order Name',
        title: 'Order Name',
        show: true,
        disabled: false,
        sortAttr: 'name',
      });
      const screen = store.state.activeScreen;

      const res = await ExcelExport.export({
        company: store.getters.selectedIdsForKey('companies', false),
        owner: store.getters.selectedOwners,
        template: opts._id,
        search: store.state.queryParams.searchText,
        screen,
        limit: 1000,
        type: 'excel',
        name: 'forms',
        excelHeaders: { columns: excelHeaders },
        projectId: store.state.queryParams.project._id,
        userCompanyId: getCompanyIds.value,
      });

      if (res.done) {
        toast.success('Excel report emailed to you');
      }
    };

    const onCellClicked = async (event) => {
      if (event.type === 'excel-export') {
        await exportExcel(event.data);
      } else if (event.type === 'print-form') {
        await triggerPrint(event.data);
      } else if (event.type === 'setProject') {
        state.rerenderMfTable++;
      }
    };

    const loadData = async (commonParam) => {
      const params = {
        projectId: filteredProject.value._id,
        search: store.state.queryParams.searchText,
        companyId: getCompanyIds.value,
        ...commonParam,
      };
      const data = await Todo.getTemplateFormsStatus(params);
      return data;
    };

    const refreshTable = async () => {
      await formsListTable.value.refreshTable();
    };

    const cancel = () => {
      state.openForm = false;
    };

    // refresh on filter change
    watch(
      () => filteredProject.value,
      async (newVal, oldVal) => {
        if (oldVal.name !== newVal.name) {
          refreshTable();
        }
      },
    );

    // refresh on search
    watch(
      () => store.state.queryParams.searchText,
      _.debounce(async (newVal, oldVal) => {
        if (oldVal !== newVal) {
          refreshTable();
        }
      }, 500),
    );

    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      // eslint-disable-next-line no-unused-expressions
      formsListTable?.value?.closeColumnChooserModal();
    };

    return {
      ...toRefs(state),
      triggerSetHeaderHeight,
      formsListTable,
      isBulkActionEnabled,
      onCellClicked,
      loadData,
      refreshTable,
      cancel,
      getHeader,
      printFormsView,
    };
  },
};
</script>

<style scoped>
.hide-tab, .print-display {
  display: none !important;
}
</style>
