<template>
  <o-modal :active="isActive" :canCancel="false" class="modal-md">
    <div class="modal-card">
      <header class="modal-card-head is-borderless">
        <p class="card-header-title is-justify-content-left has-text-black-bis">
          Invalid Entries (The Following Items Were Not Imported Successfully)
        </p>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="close"></i>
      </header>
      <section class="modal-card-body has-text-black-bis no-validate">
        <mf-table
          :tableProps="tableCols"
          :loadData="loadData"
          :apiMode="false"
        />
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end
        is-align-items-center has-text-black-bis is-size-3">
          <o-checkbox
            v-model="needErrorLog"
          >
          Download invalid entries as a CSV file
          </o-checkbox>
          <button class="button has-background-black-bis"
            @click="downloadErrorLog">
              OK
          </button>
      </footer>
    </div>
  </o-modal>
</template>

<script>
import { defineAsyncComponent, reactive, toRefs } from 'vue';
import {
  map, split, get, keyBy, toUpper, join, set,
} from 'lodash';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default {
  components: {
    'mf-table': MfTable,
  },
  props: {
    isActive: Boolean,
    loadData: Function,
    tableCols: Object,
    excelFileName: String,
  },
  setup(props, { emit }) {
    const state = reactive({
      needErrorLog: false,
    });

    const close = () => {
      state.needErrorLog = false;
      emit('close');
    };

    // Used for swapping out header titles using the props in the invalid cols field, for example _errorMsg will be replaced by error/warning.
    const headerSwap = {
      _errorMsg: 'error/warning',
    };

    const downloadErrorLog = () => {
      if (!state.needErrorLog) return close();
      const invalidData = props.loadData();
      const colsByPropKey = keyBy(get(props, 'tableCols.fields', []), 'prop');
      const mapOrder = [];
      let content = '';
      const headers = map(get(props, 'tableCols.fields', []), (field) => {
        mapOrder.push(field.prop);
        return toUpper(headerSwap[field.prop] || get(colsByPropKey, field.prop, '').title);
      });
      content += join(headers, ', ');
      content += '\n';
      content += map(invalidData, (obj) => {
        if (!obj.customId) obj.customId = '';
        // for checklist
        if (!obj.name) obj.name = obj.text;
        const formattedObj = {};
        for (const field of mapOrder) {
          if (colsByPropKey[field]) set(formattedObj, field, get(obj, field));
        }
        return Object.values(formattedObj).join(',');
      }).join('\n');
      const contentType = 'text/csv';
      const csvFile = new Blob([content], { type: contentType });
      const a = document.createElement('a');
      a.download = `invalid-${get(split(props.excelFileName, '.'), '[0]', '')}.csv`;
      a.href = window.URL.createObjectURL(csvFile);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return close();
    };

    return {
      ...toRefs(state),
      downloadErrorLog,
      close,
    };
  },
};
</script>
