import { get } from 'lodash';

function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    hasCheckBox: true,
    isCheckable: true,
    isDetailed: false,
    rearrangeCols: false,
    toolBar: false,
    hideTableGutter: true,
    stickeyHeaders: true,
    customRowKey: 'uid',
  };
}

export default {
  modalBasics: [
    {
      label: 'col1',
      title: 'Shipment Details',
      fields: {
        genericFields: [
          {
            name: 'field-generic-input',
            label: 'Name',
            prop: 'name',
            placeHolder: 'Enter Name',
            isDisabled: true,
          },
          {
            name: 'field-generic-input',
            label: 'Project',
            prop: 'project.name',
            isDisabled: true,
          },
        ],
        dateFields: [
          {
            title: 'Ship Date',
            name: 'field-date',
            prop: 'delivery.deliveryStart',
            kind: 'dates',
            width: '15%',
            id: 'shipBy',
            inputProps: {
              kind: 'delivery.deliveryStart',
              max: 'delivery.deliverBy',
              disableEdit: true,
            },
          }, {
            title: 'Deliver Date',
            name: 'field-date',
            prop: 'delivery.deliverBy',
            kind: 'dates',
            width: '15%',
            id: 'deliverBy',
            inputProps: {
              kind: 'delivery.deliverBy',
              min: 'delivery.deliveryStart',
              disableEdit: true,
            },
          },
        ],
        multiGenericFields: [
          {
            name: 'field-generic-input',
            label: 'Name',
            prop: 'modalName',
            placeHolder: 'Enter Name',
            isDisabled: true,
            key: 'name'
          },
          {
            name: 'field-generic-input',
            label: 'Project',
            prop: 'project.name',
            isDisabled: true,
          },
        ],
      },
    },
  ],
  tableBasics: {
    ...extraTableParams(),
    fields: [
      {
        title: 'Item Name',
        name: 'field-generic-input',
        prop: 'name',
        width: '25%',
        id: 'name',
      },
      {
        title: 'Catalog',
        name: 'field-generic-input',
        prop: 'catId',
        width: '15%',
        id: 'catId',
      }, {
        title: 'Issues',
        name: 'selectError',
        visible: true,
        width: '33%',
        id: 'selectedError',

      }, {
        title: 'Expected Qty',
        name: 'available',
        prop: 'available',
        classRight: 'is-pulled-right',
        width: '15%',
        id: 'available',
      },
      {
        name: 'availableQty',
        title: 'Actual Qty',
        visible: true,
        width: '12%',
      },
    ],
  },
  receiveDeliveries: {
    ...extraTableParams(),
    fields: [
      {
        title: 'Delivery Name',
        name: 'field-generic-input',
        prop: 'name',
        width: '50%',
        id: 'name',
        visible: true,
      },
      {
        title: 'Tracking #',
        name: 'field-generic-input',
        prop: '_delivery.trackingId',
        width: '50%',
        visible: true,
        id: 'name',
      },
      {
        title: 'Ship By',
        name: 'field-date',
        prop: '_delivery.deliveryStart',
        kind: 'dates',
        width: '152px',
        id: 'shipBy',
        inputProps: {
          kind: '_delivery.deliveryStart',
          max: '_delivery.deliverBy',
          disableEdit: false,
        },
      }, {
        title: 'Deliver By',
        name: 'field-date',
        prop: '_delivery.deliverBy',
        kind: 'dates',
        width: '152px',
        id: 'deliverBy',
        inputProps: {
          kind: '_delivery.deliverBy',
          min: '_delivery.deliveryStart',
          disableEdit: false,
        },
      },
    ],
  },
};
