<template>
<div>
  <selected-orders  :isOpen="popOver"
    :cards="selectedCards" :run="selectedWorkStep" @closePopover="closePopover"
    > </selected-orders>
    <div class="modal-card">
      <!-- Modal Header -->
      <header class="modal-card-head">
        <h4 class="modal-card-title" >
         Edit Work Step: {{selectedWorkStep.name}}
        </h4>
        <button class="button has-background-black-bis has-text-weight-bold is-size-5"
             @click="openSelectedOrders">
            <span >Selected Orders ({{selectedOrders.length}})</span>
          </button>
      </header>
    <!-- Modal Body -->
    <div class="modal-card-body is-visible has-background-white p-0 work-step-modal">
      <div class="column pt-0">
            <div class="field">
              <div class="field-body">
                <div class="control">
                </div>
              </div>
            </div>

            <div class="field">
              <div class="field-label">
                <label class="label"> Work Step Owner </label>
              </div>
              <div class="field-body">
                <div class="field is-expanded">
                  <div class="control">
                    <mf-multi-select v-model="workStepOwner" track-by="_id" label="name" placeholder="Keep Currect"
                      :preselect-first="true" :options="allUsers" :searchable="true"
                      :allow-empty="false" group-values="val" group-label="key" class="pendo-owner-select">
                    </mf-multi-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="field">
              <div class="field-label">
                <label class="label"> Work Step Location </label>
              </div>
              <div class="field-body">
                <div class="field is-expanded">
                  <div class="control">
                    <mf-multi-select v-model="workStepLocation" track-by="_id" label="name" placeholder="Keep Currect"
                      :preselect-first="true" :options="allLocations"
                      :searchable="true" :allow-empty="false" group-values="val" group-label="key"
                      class="pendo-owner-select">
                    </mf-multi-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column is-5">
                <div class="field">
                  <div class="field-label">
                    <label class="label"> Production Start</label>
                  </div>
                  <div class="field-body">
                      <mf-date
                      :item="selectedWorkStep"
                      :input-props="{
                          kind: 'coord', fromWorkStep: true, max: 'deliver'
                        }"
                      :is-edit="true" @set-date="setDate"
                    ></mf-date>
                  </div>
                </div>
              </div>
              <div class="column is-5">

                <div class="field">
                  <div class="field-label">
                    <label class="label"> Production End</label>
                  </div>
                  <div class="field-body">
                    <mf-date
                    :item="selectedWorkStep"
                      :input-props="{
                          kind: 'deliver', fromWorkStep: true, min: 'coord'
                        }"
                      :is-edit="true" @set-date="setDate"
                    ></mf-date>
                  </div>
                </div>
              </div>
              <div class="column is-narrow">
                 <div class="field">
                  <div class="field-label">
                    <label class="label"> Done </label>
                    <o-checkbox id="isDone" v-model="isDone" class="pt-0 pl-0"> </o-checkbox>
                  </div>
                 </div>
              </div>
            </div>

            <div class="columns">
              <div class="column is-6">
                <div class="field">
                  <div class="field-label">
                    <label class="label"> Planned Hours/Item (h:m:s)</label>
                  </div>
                  <div class="field-body">
                    <field-clock-display
                          :rowField="{ prop: 'autoHours',
                            inputProps: {disableEdit: false, showNum: false, fromWorkStep: true, editHours:editHours, isLeft: true, isModal:true, max: 999 },}"
                          :rowData="tempCard.manager.runs[0]" @cellClicked="onTableCellClicked"/>
                  </div>
                </div>
              </div>
              <div class="column is-6 pl-0 pt-0">
                <div class="field">
                  <div class="field-label">
                    <label class="label is-invisible">Keep Current</label>
                  </div>
                 <o-checkbox v-model="keepCurrent" @update:modelValue="setEdit"> Keep Current </o-checkbox>
                </div>
              </div>
            </div>
           <div class="columns">
             <div class="column is-6">
              <div class="field">
                <div class="field-label">
                  <label class="label"> Actual Hours (h:m:s)</label>
                </div>
                <div class="field-body pt-2">
                  <field-clock-display
                          :rowField="{ prop: 'autoActualHours',
                            inputProps: {disableEdit: false, showNum: false, fromWorkStep: true, isLeft: true, isModal: true, max: 999 },}"
                          :rowData="tempCard.manager.runs[0]" @cellClicked="onTableCellClicked"/>
                </div>
              </div>
              <div class="field">
                <div class="field-label">
                  <label class="label"> Apply Actual Hours </label>
                </div>
                <div class="field-body" >
                  <fieldset class="p-2">
                    <input id="splitRadio" type="radio"
                     v-model="setActualHours"
                     :disabled="disableRadio"
                     value="splitActualHours"/>
                    <label for="splitRadio" :class="!enableCheckBox ? 'has-text-grey-light' : ' '">  Split Actual Hours between Work Steps across {{selectedOrders.length}} Orders </label>
                  </fieldset>
                  <fieldset class="p-2">
                    <input id="applyToEachActualHours"
                    type="radio"
                    v-model="setActualHours"
                    value="applyToEachActualHours"
                    :disabled="disableRadio"/>
                    <label for="applyToEachActualHours" :class="!enableCheckBox ? 'has-text-grey-light' : ''"> Apply Actual Hours to each Work Steps</label>
                  </fieldset>
                </div>
              </div>
            </div>
           </div>
          </div>
      </div>
      <!-- Modal Footer -->
      <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
        <div class="column is-narrow ">
          <div>
            <button class="button is-outlined" @click="onCancel">
              Cancel
            </button>
            <button class="button  has-background-black-bis" @click="saveWorkStepAndOrders(true, false)">
              Save
            </button>
         </div>
        </div>
      </footer>
    </div>
    <o-modal :active="isProgressBar" v-if="isProgressBar && orderCount>0" :canCancel="false" class="modal-xs">
              <progress-bar
              :totalCount="orderCount"
              :progressCount="progressCount"
              headerLabel="Updating Work Step:"
              @turn-off-progress="turnOffProgress"
              progressType="progressCount"
              progressLabel="orders created" />
            </o-modal>
     <o-loading
        :full-page="true"
        :active="isLoading"
        :can-cancel="true"
      ></o-loading>
</div>
</template>
<script>
import {
  reactive, toRefs, computed, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import Users from '@/models/Users';
import MfDate from '@/components/abstract/MfDate.vue';
import Locations from '@/models/Locations';
import SelectedOrdersPopover from '@/components/modals/SelectedOrdersPopover.vue';
import ProgressBar from '@/components/modals/ProgressBar.vue';
import BaseOrder from '../../models/BaseOrder';

export default {
  components: {
    'selected-orders': SelectedOrdersPopover,
    'mf-date': MfDate,
    ProgressBar,
  },
  props: {
    selectedCards: Array,
    selectedWorkStep: Object,
  },

  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      workStepOwner: '',
      workStepLocation: '',
      actualHours: 0,
      isDone: false,
      users: [],
      locations: [],
      selectedOrders: [],
      isActive: false,
      popOver: false,
      orders: [],
      allUsers: [],
      allLocations: [],
      isDisabled: true,
      isEnabled: true,
      radioCheck: true,
      split: false,
      each: false,
      isCorrect: '',
      runFinishDate: '',
      runStartDate: '',
      keepCurrent: false,
      isLoading: false,
      plannedHrs: 0,
      applyToAll: true,
      splitToEach: false,
      editHours: false,
      isHoursSelected: false,
      setActualHours: 'splitActualHours',
      progressCount: 0,
      isProgressBar: false,
      enableCheckBox: false,
      orderCount: 0,
      disableRadio: true,
      // below card is only need to use for actual and planned hours
      tempCard: new BaseOrder({
        name: '',
        _customStage: 'manufacturing',
        stage: 'manufacturing',
        __t: 'manufacturing',
        project: {},
        owner: { user: null },
        location: null,
        isTrackingEnabled: false,
        defaultRun: null,
        purpose: 'general',
      }),
    });
    // below code is only need to use for actual and planned hours
    state.tempCard.manager.owner = {};
    state.tempCard.manager.location = {};
    state.tempCard.manager.addRun();
    // end of actual and planned hours fix code

    const runLocations = computed(() => store.state.queryParams.allLocations);
    const updatedValues = computed(() => {
      return state;
    });
    const setEdit = (val) => {
      state.keepCurrent = val;
      state.editHours = val;
      if (val) {
        _.set(state.tempCard.manager.runs[0], 'autoHours', 0);
      }
    };
    const setDate = (val) => {
      if (val.kind._value === 'coord') {
        state.runStartDate = val.val;
      } else {
        state.runFinishDate = val.val;
      }
    };
    const onTableCellClicked = (params) => {
      if (params.prop === 'autoHours') {
        state.plannedHrs = params.value;
      } else {
        state.actualHours = params.value;
        if (state.actualHours) {
          state.enableCheckBox = true;
          state.disableRadio = false;
        } else {
          state.enableCheckBox = false;
          state.disableRadio = true;
        }
      }
    };

    const saveCard = async (card) => {
      await card.save();
      state.progressCount += 1;
    };
    const onCancel = () => {
      emit('close');
      emit('refresh-table');
      store.commit('setSelectedWorkStep', '');
    };

    const saveWorkStepAndOrders = async () => {
      if (state.setActualHours === 'splitActualHours') {
        state.actualHours = _.floor(state.actualHours/props.selectedCards.length);
      }
      try {
        state.isProgressBar = true;
        const promises = [];
        for (const card of props.selectedCards) {
          const run = _.find(card.manager.runs, { name: props.selectedWorkStep.name });
          if (_.isUndefined(card.isRemoved) && !_.isUndefined(run)) {
            state.orderCount++;
            if (state.runStartDate !== '') run.addOrUpdateDate('coord', state.runStartDate);
            if (state.runStartDate !== '') run.addOrUpdateDate('deliver', state.runFinishDate);
            if (!_.isEmpty(state.workStepOwner) && state.workStepOwner.name !== 'Keep Current') _.set(run, 'owner.user', state.workStepOwner);
            if (!_.isEmpty(state.workStepLocation) && state.workStepLocation.name !== 'Keep Current') _.set(run, 'location', state.workStepLocation);
            let runPerc = '';
            if (state.applyToAll) {
              const runItems = run?.runItemsCount;
              if (state.actualHours > 0) {
                _.set(run, 'stats.actualHrs', state.actualHours);
                // need to distribute run hours to items
                for (const rItem of runItems) {
                  _.set(rItem, 'actualHrs', state.actualHours / runItems.length);
                }
                _.set(card, 'status', 'in-progress');
              }
              if (state.plannedHrs > 0 && !state.keepCurrent) {
                _.set(run, 'hours', state.plannedHrs);
                // need to distribute run hours to items
                for (const rItem of runItems) {
                  _.set(rItem, 'plannedHrs', state.plannedHrs);
                }
              }
            }
            if (state.isDone) {
              // need to set items also done for the particular run
              runPerc = 100;
              const runItems = run.runItemsCount;
              const { simpleDates } = run;
              for (const rItem of runItems) {
                _.set(rItem, 'percComplete', 100);
                _.set(rItem, 'riCompleted', true);
              }
              _.set(run, 'completed', state.isDone);
              _.set(run, 'stats.percComplete', runPerc);
            }
            promises.push(saveCard(card));
          }
        }
        if (state.orderCount === 0) {
          state.isProgressBar = false;
          onCancel();
        }
        await Promise.all(promises);
        store.commit('setSelectedWorkStep', '');
      } catch (e) {
        console.log(e);
        state.isProgressBar = false;
      }
    };
    const openSelectedOrders = () => {
      state.popOver = true;
    };
    const selectedOrders = computed(() => props.selectedCards.filter((card) => !card.isRemoved));
    const closePopover = () => {
      state.popOver = false;
    };
    onMounted(async () => {
      try {
        state.isLoading = true;
        let locs = [];
        const compId = props.selectedCards[0].created.by.company._id;
        const projId = props.selectedCards[0].project._id;
        [state.allUsers, locs] = await Promise.all([
          Users.getGroupedProjectLinkedUsers(compId, projId),
          Locations.allLocationForUser({
            companyIds: compId,
            projectId: projId,
          }),
        ]);
        const keepCurrent = { key: 'Keep Current', val: [{ name: 'Keep Current' }] };
        state.allLocations = Locations.groupLocations(locs, store.state.companyData);
        state.allLocations.push(keepCurrent);
        state.allUsers.push(keepCurrent);
      } catch (e) {
        console.log(e);
      } finally {
        state.isLoading = false;
      }
      // get owner and location for same values across all the orders
      const runs = _.map(props.selectedCards, 'manager.runs', null);
      const actualRuns = _.flatMap(runs);
      const run = _.find(props.selectedCards[0].manager.runs, { name: props.selectedWorkStep.name });
      const runOwner = run?.owner?.user;
      const runLocation = run.location;
      const sameOwner = _.every(actualRuns, (r) => r.owner?.user?._id === runOwner._id);
      const sameLocation = _.every(actualRuns, (r) => r.location?._id === runLocation._id);
      if (sameOwner === true) state.workStepOwner = runOwner;
      if (sameLocation === true) state.workStepLocation = runLocation;
      _.set(props.selectedWorkStep, 'actualHrs', 0);
      _.set(props.selectedWorkStep, 'plannedHrs', 0);
      _.set(props.selectedWorkStep, 'coord', '');
      _.set(props.selectedWorkStep, 'deliver', '');
      state.iDone = true;
      state.isCorrect = true;
    });
    const turnOffProgress = () => {
      setTimeout(() => {
        state.isProgressBar = false;
        emit('close');
        emit('refresh-table');
      }, 2000);
    };
    return {
      ...toRefs(state),
      updatedValues,
      openSelectedOrders,
      closePopover,
      runLocations,
      saveWorkStepAndOrders,
      setDate,
      onTableCellClicked,
      setEdit,
      onCancel,
      turnOffProgress,
      selectedOrders,
    };
  },
};
</script>
<style scoped>
</style>
