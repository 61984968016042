<template>
<div class="login-tag">
   <div class="header is-vertical-center">
      <figure class="level-item has-text-centered is-centered">
        <img src="./../assets/logo-login.png" />
      </figure>
    </div>
  <div class="is-flex is-justify-content-center is-align-items-center login-main">
    <div class="card">
        <header class="card-header has-background-white-ter is-size-5">
          <p v-if='!showForgot'
            class="card-header-title is-uppercase has-text-dark is-justify-content-center"
          >
            {{ $t('log in').replace(/\s/g, '') }}
          </p>
          <p v-else
            class="card-header-title is-uppercase has-text-dark is-justify-content-center"
          >
            {{ $t('reset Password') }}
          </p>
        </header>
        <div class="card-content">
          <div class="field">
            <p class="control has-icons-left has-icons-right">
              <input
                class="input has-text-dark"
                v-model.trim="user.email"
                type="email"
                :placeholder="$t('Email Address')"
                @keyup.enter="login()"
              />
              <span class="icon is-small is-left">
                <i class="icon-email"></i>
              </span>
            </p>
          </div>
          <div class="field" v-if='!showForgot'>
            <p class="control has-icons-left has-icons-right">
              <input
                class="input has-text-dark"
                :type="showPassword ? 'text' : 'password'"
                v-model="user.password"
                :placeholder="$t('Password')"
                @keyup.enter="login()"
              />
              <span class="icon is-small is-left">
                <i class="icon-lock"></i>
              </span>
              <span
                class="icon is-small is-right is-clickable"
                @click="showPassword = !showPassword"
                @mouseover="
                  showPassword
                    ? (eyeIcon = 'icon-hide')
                    : (eyeIcon = 'icon-show')
                "
                @mouseleave="
                  showPassword
                    ? (eyeIcon = 'icon-show')
                    : (eyeIcon = 'icon-hide')
                "
              >
                <i :class="eyeIcon"></i>
              </span>
            </p>
          </div>
          <p  v-if='!showForgot'><a @click="showForgot=!showForgot">
            {{ $t('Forgot password?') }}</a>
          </p>
          <p v-else class="has-text-black-bis is-size-3 line-height">
            {{ $t('Enter your registered email address and we will email a link to reset your password.') }}</p>
        </div>
        <footer
          class="card-footer has-background-white-bis is-justify-content-flex-end -small"
        >
          <button v-if='!showForgot'
            class="button is-dark is-capitalized has-text-weight-bold"
            @click="login()"
          >
            {{ $t('log in') }}
          </button>
          <div v-else class="buttons">
            <button class="button is-outlined has-text-weight-bold"
              @click="showForgot=!showForgot">{{ $t('Cancel') }}</button>
            <button class="button is-dark is-capitalized has-text-weight-bold"
              @click="sendMail()">{{ $t('Email Reset Password Link') }}</button>
          </div>
        </footer>
      </div>
  </div>
  <div class="has-text-centered login-footer">
      <span class="">
      <p class="has-text-white">
        &copy; {{ $t('Copyright 2021 ViZZ Technologies, LLC. All Rights Reserved.') }}
      </p>
      <p class="has-text-centered has-text-white">
          {{ $t('By logging in, you accept the') }}
          <a
            href="https://www.manufacton.com/terms-of-use/"
            target="_blank"
          >{{ $t('Terms & Conditions') }}</a> {{ $t('and') }}
          <a
            href="https://www.manufacton.com/manufacton-privacy-policy/"
            target="_blank"
          >{{ $t('Privacy Policy') }}</a>.
        </p>
      </span>
  </div>
</div>
  <o-loading
    :full-page="true"
    :active="isLoading"
    :can-cancel="true"
  ></o-loading>
</template>

<script>
import {
  reactive, toRefs,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import Validation from '@/utils/Validations';
import User from '@/models/Users';
import { useToast } from 'vue-toastification';

export default {
  name: 'login',
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const state = reactive({
      showPassword: false,
      user: {
        email: '',
        password: '',
      },
      landingPage: {
        home: '/home',
        mm: '/materials/preparation',
        prefabs: '/prefabs-list',
        pm: '/manager/detailing',
        scm: '/scm/production-status/order-view',
        schedules: 'schedule-list',
      },
      showForgot: false,
      icons: ['times-circle', 'exclamation-circle'],
      currentInstance: '',
      isLoading: false,
      eyeIcon: 'icon-hide',
    });

    const login = async () => {
      if (Validation.validateEmail(state.user.email)) {
        state.isLoading = true;
        try {
          const result = await User.login(state.user);
          localStorage.setItem('auth-token', result.token);
          await store.dispatch('getUserData');
          store.commit('setLoggedIn', true);
          await store.getters.userPromise;
          const user = store.state.userData;
          await store.dispatch('updateProjects');
          ['projects', 'projectsAndGI'].forEach((key) => {
            if (user.rememberLastUsedProjects) {
              if (store.state.queryParams.isActiveSelected[key]) { store.commit('setIsActiveSelectedVal', key, false); }
              if (_.get(store.state.queryParams, 'projects.length', 0) === 0) store.commit('setQueryParams', { [key]: store.state.queryParams._projects });
            } else {
              store.dispatch('selectActiveProjects', key);
            }
          });

          // oruga.notification.open({
          //   message: 'You are now logged in',
          //   variant: 'success',
          // });
          // initialize pendo once login is success
          if (process.env.NODE_ENV === 'production') {
            const userData = await store.getters.userPromise;
            // eslint-disable-next-line
            pendo.initialize({
              visitor: {
                id: userData._id,
                email: userData.email,
                userName: userData.firstName,
                role: userData.role,
              },
              account: {
                id: userData.company,
                name: userData.companyName,
              },
            });
          }
          // navigating based on user settings default landing
          router.push({ path: state.landingPage[store.state.userData.defaultLanding] });
        } catch (e) {
          // alertMessage(e);
          toast.error('Cannot log in with this user name and password');
        }
        state.isLoading = false;
      } else {
        toast.error('Email Address is Not Valid');
      }
    };
    const alertMessage = (err) => {
      if (err.status === 404) {
        return toast.error('Looks like server is down !!!');
      }
      return toast.error(
        _.get(err, 'data.message', false)
          ? err.data.message
          : 'Something went wrong!',
      );
    };
    const callToast = (msg) => {
      // oruga.notification.open({
      //   message: `${msg}`,
      //   variant: 'danger',
      // });
    };
    const sendMail = async () => {
      if (Validation.validateEmail(state.user.email)) {
        try {
          await User.forgotPassword(state.user);
          return toast.success('We have sent you an email with instruction on how to reset your password!', 'success');
        } catch (e) {
          return alertMessage(e);
        }
      } else {
        return toast.error('Email Address is Not Valid');
      }
    };
    return {
      ...toRefs(state),
      login,
      alertMessage,
      callToast,
      sendMail,
    };
  },
};
</script>

<style scoped>
.card {
  width: 432px;
  border-radius: 8px;
}
::v-deep(.eye-button) {
  position: absolute;
  right: 7px;
  top: 7px;
}
.control.has-icons-left .icon.is-left,
.control.has-icons-right .icon.is-right {
  height: 100%;
}

</style>
