export default {
  ID: {
    field: 'customId',
    multiple: false,
  },
  NAME: {
    field: 'name',
    multiple: false,
    required: true,
  },
  'CATALOG ID': {
    field: 'catId',
    multiple: false,
  },
  DESCRIPTION: {
    field: 'desc',
    multiple: false,
  },
  CATEGORY: {
    field: 'category',
    multiple: false,
  },
  'MEASURE UNIT': {
    field: 'measureUnits',
    multiple: false,
  },
  'SUB CATEGORY': {
    field: 'subCategory',
    multiple: false,
  },
  VENDOR: {
    field: 'vendors:name',
    multiple: false,
  },
  'DEFAULT VENDOR': {
    field: 'vendors:isDefault',
    multiple: false,
  },
  'PREF VENDOR': {
    field: 'vendors:isPreferred',
    multiple: false,
  },
  'VENDOR SKU/PART #': {
    field: 'vendors:code',
    multiple: false,
  },
  'VENDOR DESCRIPTION': {
    field: 'vendors:desc',
    multiple: false,
  },
  'VENDOR ITEM COST': {
    field: 'vendors:unitCost',
    multiple: false,
  },
  'VENDOR LEAD TIME': {
    field: 'vendors:leadTime',
    multiple: false,
  },
};
