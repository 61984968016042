const commonFields = [
  {
    title: 'Item Location',
    name: 'field-generic-input',
    sortField: 'location.fullAddress',
    prop: 'location.fullAddress',
    width: '65%',
    id: 'location',
    inputProps: {
      type: 'text',
    },
  },
];

export default {
  pick: {
    fields: [
      ...commonFields,
      {
        title: 'Available',
        name: 'field-generic-input',
        prop: 'qtyAvailable',
        width: '10%',
        id: 'quantity',
      },
      {
        title: 'Qty Taken',
        name: 'qtyInput',
        width: '10%',
        id: 'inputVal',
      },
      {
        title: 'Actions',
        name: 'action',
        id: 'action',
        width: '15%',
      },
    ],
  },
  stock: {
    fields: [
      ...commonFields,
      {
        title: 'Qty Stocked',
        name: 'qtyInput',
        width: '20%',
        id: 'inputVal',
      },
      {
        title: 'Actions',
        name: 'action',
        id: 'action',
        width: '15%',
      },
    ],
  },
};
