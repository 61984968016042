<template>
  <div class="control">
    <button class="button is-primary" @click="showShippingList">{{ emails.length }}</button>
    <!-- <input
      v-model="emailInput"
      class="input"
      type="text"
      placeholder="Type email followed by enter"
      @keyup.enter="addExternalEmail()"
    />
    <div
      class="button has-background-black-bis has-text-white"
      v-for="(email, idx) in emails"
      :key="idx"
    >
      <span>{{ email }}</span>
      <span class="icon is-small">
        <i
          class="icon-close has-text-black"
          @click="removeExternalEmail(email)"
        ></i>
      </span>
    </div> -->
    <send-shipping-list
      :isVisible="isShippingListVisible"
      :offSetValue="[-180, -120]"
      :value="emails"
      @update:modal="(isShippingListVisible = false)"
      @update:value="updateExternalEmail"
    />
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue';
import SendShippingList from '@/components/modals/SendShippingList.vue';

export default {
  name: 'ShippingListField',
  components: {
    'send-shipping-list': SendShippingList,
  },
  props: {
    value: Array,
  },
  setup(props, { emit }) {
    const data = reactive({
      emails: props.value || [],
      emailInput: '',
      toastErrorMessage: '',
      isShippingListVisible: false,
    });

    const showShippingList = () => {
      data.isShippingListVisible = true;
    };

    const updateExternalEmail = (emails) => {
      data.emails = emails;
      emit('update:value', data.emails);
    };

    return {
      ...toRefs(data),
      showShippingList,
      updateExternalEmail,
    };
  },
};
</script>
