export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  isCheckable: true,
  fields: [{
    name: 'field-generic-input',
    prop: 'name',
    width: '50%',
    title: 'Item Name',
    id: 'name',
  }, {
    name: 'field-generic-input',
    prop: 'customId',
    width: '35%',
    title: 'Item ID',
    id: 'customId',
  },
  {
    name: 'field-generic-input',
    prop: 'quantity',
    width: '15%',
    title: 'Quantity',
    id: 'quantity',
  }],
};
