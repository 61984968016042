// Table cols for Assemblies and Parts Table
export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  stickeyHeaders: true,
  gutterOpts: {},
  toolBar: false,
  isCardView: true,
  fields: [
    {
      title: '',
      name: 'table-field-radio',
      prop: 'selected',
      width: '40px',
      id: 'selected',
    },
    {
      name: 'name',
      width: '400px',
      title: 'Name',
      sortField: 'name',
      sticky: true,
      id: 'name',
      defaultVisible: true,
    },
    {
      name: 'field-generic-input',
      width: '112px',
      title: 'Quantity',
      id: 'quantity',
      prop: 'quantity',
      type: 'number',
      defaultVisible: true,
      inputProps: {
        disableEdit: false,
      },
    },
    {
      name: 'catId',
      width: '112px',
      title: 'Catalog ID',
      sortField: 'catId',
      id: 'catId',
      defaultVisible: true,
    }, 
    {
      name: 'customId',
      width: '112px',
      title: 'Part ID',
      id: 'customId',
      defaultVisible: true,
    },
    {
      name: 'type',
      width: '112px',
      title: 'Type',
      id: 'type',
      defaultVisible: true,
    },
    {
      name: 'desc',
      width: '296px',
      title: 'Description',
      id: 'desc',
      defaultVisible: true,
    },
    // {
    //   name: 'category.name',
    //   width: '152px',
    //   title: 'Category',
    //   id: 'cat-name',
    //   defaultVisible: true,
    // },
    // {
    //   name: 'subCategory.name',
    //   width: '152px',
    //   title: 'Sub Category',
    //   id: 'subCat-name',
    //   defaultVisible: true,
    // },
    // {
    //   name: 'measureUnits',
    //   width: '152px',
    //   title: 'Measure Units',
    //   id: 'measure-units',
    //   defaultVisible: true,
    // },
    // {
    //   name: 'purchase',
    //   width: '124px',
    //   title: 'Purchase',
    //   id: 'purchase',
    //   defaultVisible: true,
    // },
    // {
    //   name: 'field-generic-input',
    //   width: '196px',
    //   title: 'Notes',
    //   id: 'notes',
    //   prop: 'notes',
    //   type: 'text',
    //   defaultVisible: true,
    //   inputProps: {
    //     disableEdit: false,
    //   },
    // },
  ],
};
