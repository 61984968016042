<template>
  <div v-if="isHeader" class="has-text-centered">
    <i class="icon-timer"></i>
  </div>
  <div v-else class="buttons is-justify-content-center">
    <button v-if="!isStopWatchOn(rowData)" :disabled="disableClockFields(rowData)" class="button has-background-white has-border-success run-timer" @click="startWatch(rowData)">
      <i class="icon-timerrecord has-text-success"></i>
    </button>
    <button v-else class="button is-danger run-timer" @click="fireEvent()">
      <i class="icon-timerstop has-text-white"></i>
    </button>
    <button class="button has-background-white has-border-success run-timer mr-0" :disabled="disableClockFields(rowData)" @click="fireEvent(true)">
      <i class="icon-timmeradd has-text-success"></i>
    </button>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
} from 'vue';
import _ from 'lodash';
import ProductionManager from '@/models/ProductionManager';
import moment from 'moment';
import { useToast } from 'vue-toastification';
import RunsTimer from '../modals/RunsTimer.vue';
import UtilityMixin from '../mixins/UtilityMixin';

export default defineComponent({
  name: 'RunProductivityField',
  props: {
    card: Object,
    rowData: Object,
    rowField: Object,
    isHeader: Boolean,
  },
  components: {
    RunsTimer,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const state = reactive({
      setHrs: 500,
      isModalActive: false,
    });
    const { getHoursMinutesSeconds } = UtilityMixin();

    const fireEvent = (isManualTimer = false) => {
      const params = { type: 'runsTimerModal', data: { rowData: props.rowData, isManualTimer } };
      emit('cellClicked', params);
    }

    const isStopWatchOn = ((run) => {
      const managerPT = _.get(
        props.card,
        `manager.pt.${run.uuid}`, {},
      );
      return _.get(managerPT, 'startTimer', false)
        && !_.get(managerPT, 'stopTimer', false);
    });

    const startWatch = async (run) => {
      let res = {};
      const params = {
        orderId: run.order._id,
        runUuid: run.uuid,
      };
      res = await ProductionManager.startTimer(params);
      _.set(props.card.manager, 'pt', res.pt);
      _.set(props.card, 'status', res.status);
      return res;
    }

    const disableClockFields = ((rowData, clock = false) => {
      if (clock) {
        return rowData.fieldsDirty;
      }
      return rowData?.completed || props.card.status === 'pause' || isStopWatchOn(rowData);
    });
    return {
      isStopWatchOn,
      disableClockFields,
      fireEvent,
      startWatch,
    };
  },
});
</script>

<style></style>
