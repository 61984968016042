export default {
  isCheckable: true,
  fields: [{
    name: 'workStep',
    width: '51%',
    title: 'Work Steps',
    prop: 'name',
    id: 'name',
    isDisabled: true,
    inputProps: {
      type: 'text',
      textArea: true,
    },
    dataClass: 'overflow-visible',
    customSlotHeader: true,
    customHeader: true,
  },
  {
    name: 'qa-qc',
    width: '7%',
    title: 'QA/QC',
  },
  {
    name: 'percCompleted',
    width: '15%',
    title: '% Done',
  },
  {
    name: 'isDone',
    width: '7%',
    title: 'Done'
  },
  {
    name: 'field-productivity-display',
    width: '20%',
    title: 'Productivity',
    inputProps: {
      type: 'text',
      disableEdit: true,
      kind: 'stats.pf',
    },
    dataClass: 'overflow-visible',
  },],
};