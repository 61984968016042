import screens from '@/constants';
import store from '@/store';

export default function () {
  const getPermissionModule = (card) => {
    if (store.state.activeScreen === 'untitled') {
      return store.getters.getStageWiseModule(card);
    }
    const res = _.get(screens[store.state.activeScreen], 'permissionModule', '');
    return res;
  };

  const isDisabledOnSaveMove = (card) => {
    const permKeyArr = ['save', 'move'];
    const permissionType = getPermissionModule(card);
    if (permissionType) {
      let res = true;
      permKeyArr.forEach((key) => {
        if (res) {
          res = store.getters.getPermissionVal({
            permissionModule: permissionType,
            rowData: card,
          }, key);
        }
      });
      const type = _.get(screens[store.state.activeScreen], 'moduleKey', '');
      if (res && type === 'shipping') {
        res = store.getters.getPermissionVal({
          permissionModule: type,
          rowData: card,
        });
      }
      return !res;
    }
    return false;
  };

  const isDisabledOnPermission = (card) => {
    const pModule = getPermissionModule();
    if (pModule) {
      let res = store.getters.getPermissionVal({
        permissionModule: pModule,
        rowData: card,
      }, 'save');
      if (res) {
        const checkMap = {
          submit: [{ module: 'material-manager', key: 'save' },
            { module: 'material-manager', key: 'move' }],
        };
        checkMap.submit.forEach((pm) => {
          if (res) {
            res = store.getters.getPermissionVal({
              permissionModule: pm.module,
            }, pm.key);
          }
        });
      }
      return !res;
    }
    return false;
  };
  return {
    isDisabledOnSaveMove,
    getPermissionModule,
    isDisabledOnPermission,
  };
}
