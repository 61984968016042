<template>
    <div class="is-flex is-align-items-center">
      <div class="pause-restart-btn">
        <span class="is-flex is-align-items-center">
          <button class="button has-text-white p-0" v-if="!showIcons"
          :class="rowData.status !== 'pause' ? 'has-background-danger' : 'is-success'"
          @click="emit('cell-clicked', {type: 'pauseRestart'})" :disabled="disablePause" >
            <i :class="rowData.status !== 'pause'?
              $_.get(rowField.inputProps, 'icon.pauseIcon')
              : $_.get(rowField.inputProps, 'icon.restartIcon')"
              @click="emit('cell-clicked', {type: 'pauseRestart'})"
              
              v-permission="{ permissionModule: 'scm', type: 'pauseRestart'}"
              ></i>
          </button>
          <i v-else class="image is-24x24"></i>
          </span>
      </div>
      <div class="column"  v-if="!isCardView">
        <field-generic-input
            :label="label"
            :value="value"
            :textValue="textValue"
            :type="type"
            :isEditing="isEditing"
            :labelPosition="labelPosition"
            :placeHolder="placeHolder"
            :isDisabled="isDisabled"
            :isClickable="isClickable"
            :eventType="eventType"
            :rowField="rowField"
            :rowData="rowData"
            @update:value="updateValue($event)"
            @cell-clicked="$emit('cellClicked', { type: eventType })"
        ></field-generic-input>
      </div>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue';
import { some } from 'lodash';
import { useStore } from 'vuex';
import { BaseOrder } from '@/models/BaseOrder';

export default {
  name: 'PauseRestartField',
  props: [
    'label',
    'value',
    'textValue',
    'type',
    'isEditing',
    'labelPosition',
    'placeHolder',
    'isDisabled',
    'isClickable',
    'eventType',
    'rowField',
    'rowData',
    'disablePause'
  ],
  setup(props, { emit }) {
    const { rowData } = props;
    const store = useStore();
    const state = reactive({
      isActive: false,
    });
    const updateValue = (val) => {
      emit('update:value', val);
    };
    const showIcons = computed(() => {
      const { delivery: deliveryStages } = BaseOrder.getShipStageMap();
      const logedUser = store.state.userData;
      if (rowData.owner.company._id !== logedUser.company) return true;
      if (
        (['detailing', 'manufacturing', 'qa', 'mixed'].includes(rowData.stage))
        && !((some(rowData.items, (item) => ['planning', 'coordination', 'qa', ...deliveryStages].includes(item.stage))))
      ) {
        return false;
      }
      return true;
    });

    const isCardView = computed(() => {
      return ['cut-edit-manufacturing', 'cut-edit-detailing','manager-edit-detailing', 'manager-edit-manufacturing'].includes(store.state.activeScreen);
    });



    

    return {
      ...toRefs(state),
      showIcons,
      updateValue,
      emit,
      isCardView
    };
  },
};
</script>
