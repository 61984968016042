<template>
  <o-dropdown scrollable
              position="bottom-left"
              aria-role="list"
              @active-change="emitOptions($event)"
              multiple
              class="filter-multiselect has-text-left"
              ref="filterDropdown"
              :disabled="isDisabled">
    <template v-slot:trigger="{ active }">
      <o-input
        class="input"
        type="text"
        v-model="searchText"
        :placeholder="getPlaceHolder(active)"
        :icon-right="active ? ' icon-disclosearrow rotate-180' : ' icon-disclosearrow'"
      ></o-input>
    </template>
    <o-dropdown-item v-model="allSelected" aria-role="listitem">
      <o-checkbox v-model="allSelected" @update:modelValue="() => itemsFiltered = true">
        <span class="has-text-black-bis has-text-weight-bold">
          All {{ getAttributes.filterText }} ({{ items.length }})
        </span>
      </o-checkbox>
    </o-dropdown-item>
    <o-dropdown-item v-for="opt, idx in opts" :key="idx" value="opt" aria-role="listitem">
     <o-checkbox v-model="opt.selected" @update:modelValue="() => itemsFiltered = true">
        <span class="has-text-black-bis">
          {{ $_.get(opt, label, '') }}
        </span>
      </o-checkbox>
    </o-dropdown-item>
  </o-dropdown>
</template>

<script>
import {
  computed,
  reactive,
  ref,
  toRefs,
  watch,
  onUpdated,
} from 'vue';
import {
  every,
  cloneDeep,
  get,
  filter,
  some,
  isEmpty,
} from 'lodash';
import { useStore } from 'vuex';
import defaultStatus from '@/components/DefaultFilterOpts';
import { useRoute } from 'vue-router';

export default {
  name: 'MfMutiSelectCheckbox',
  props: {
    trackBy: {
      type: String,
      default: 'id',
    },
    label: String,
    modelValue: [Number, String, Object, Array, Boolean],
    titleLabel: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: 'top',
    },
    disabled: [Boolean, Function],
    options: {
      type: [Array, Object, Function],
      required: false,
      default: () => [],
    },
    labelClass: String,
    groupSelect: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 120,
    },
    fieldId: {
      type: String,
      default: '',
    },
  },
  setup(props, { attrs, emit }) {
    // data
    const state = reactive({
      isShow: false,
      items: [],
      searchText: '',
      itemsFiltered: false,
      filterOpts: props.options,
    });
    const filterDropdown = ref(null);
    const store = useStore();
    const route = useRoute();

    const storeValues = computed(() => {
      const fieldName = {
        project: 'filteredProjects',
        'stageChange.oldValue': 'oldStage',
        'stageChange.newValue': 'newStage',
        nowAt: 'currentLocations',
        filteredProjects: 'selectedLmvProject',
      };
      if (props.fieldId === 'status' && store.state.activeScreen === 'shipping-order-view') {
        fieldName[props.fieldId] = 'shippingStatus';
      }
      if (props.fieldId === 'stage' && store.state.activeScreen === 'material-status-order-view') {
        fieldName[props.fieldId] = 'materialStage';
      }
      let key = '';
      key = fieldName[props.fieldId];
      if (isEmpty(key)) {
        key = props.fieldId;
      }
      if (Object.keys(defaultStatus).includes(key)) {
        return store.getters.selectedValuesForKey(key, false) || [];
      }
      return store.getters.selectedIdsForKey(key) || [];
    });
    const multipleSelectValues = computed(() => storeValues.value.length || 0);
    const getAttributes = computed(() => cloneDeep(attrs));
    const allSelected = computed({
      get: () => every(state.items, 'selected'),
      set: (val) => { state.items.forEach((i) => { i.selected = val; }); },
    });
    const fromDashBoard = computed(() => route.query.fromDashBoard === 'true');
    const getPlaceHolder = (active) => {
      const selected = filter(state.items, (item) => item.selected);
      if (!active && selected.length === 1) return get(selected[0], props.label, '');
      if (!active) {
        return allSelected.value ? `All ${getAttributes.value.filterText} (${selected.length})`
          : `${getAttributes.value.filterText} (${multipleSelectValues.value})`;
      }
      if (getAttributes.value.placeholder) return getAttributes.value.placeholder;
      return allSelected.value ? `All ${getAttributes.value.filterText} (${selected.length})`
        : 'Make Selection';
    };
    const opts = computed(() => (state.searchText === ''
      ? state.items : state.items.filter((opt) => get(opt, props.label, '').toLowerCase().includes(state.searchText.toLowerCase()))));
    const emitOptions = (dropDownOpen) => {
      if (['lmv-view', 'lmv-view-card', 'lmv-view-item'].includes(store.state.activeScreen)) {
        emit('isFilterDropdownActive', dropDownOpen);
      }
      if (!dropDownOpen && state.itemsFiltered) {
        state.searchText = '';
        const selectedOptions = state.items.filter((item) => item.selected);
        emit('update:modelValue', selectedOptions);
        emit('close', selectedOptions);
        state.itemsFiltered = false;
      }
    };

    onUpdated(() => {
      state.filterOpts = props.options;
      // by default level and zone filter dont select all
      if (['level', 'zone'].includes(props.fieldId)) {
        state.filterOpts.forEach((filterOpt) => {
          filterOpt.selected = false;
        });
      }

      state.items = state.filterOpts;
      state.items.forEach((item) => {
        item.selected = storeValues.value.includes(item._id ? item._id : item.id);
      });
      const isAllSelected = some(state.items, (i) => i.selected);
      if (!isAllSelected && !['level', 'zone'].includes(props.fieldId)) {
        state.items.forEach((i) => {
          i.selected = true;
        });
      }
    });

    const isDisabled = computed(() => props?.fieldId === 'subCategory' && state.filterOpts.length === 0);

    watch(
      () => store.state.hideFilterDropdown,
      (newVal, oldVal) => {
        if (!oldVal && newVal) {
          filterDropdown.value.isActive = false;
          store.dispatch('setHideFilterDropdown', false);
        }
      },
      { immediate: true },
    );

    return {
      ...toRefs(state),
      multipleSelectValues,
      getAttributes,
      getPlaceHolder,
      allSelected,
      opts,
      emitOptions,
      filterDropdown,
      storeValues,
      fromDashBoard,
      isDisabled,
    };
  },
};
</script>
