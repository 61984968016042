import ShippingLabel from './ShippingLabel';
import GenerateRequest from './resources';

const req = GenerateRequest();

export default {
  async importSchedules(params) {
    const { data: schedule } = await req.post('schedule/importSchedule', params);
    return schedule;
  },
  async updateSchedule(params) {
    const { data: schedule } = await req.post('schedule/updateSchedule', params);
    return schedule;
  },
  async multiRowUpdate(params) {
    const { data } = await req.post('schedule/multiRowUpdate', params);
    return data;
  },
  async get(params = {}) {
    params = {
      limit: 25,
      page: 1,
      ...params,
    };
    try {
      const { data: schedules } = await req.get('schedule/getSchdules', { params });
      return schedules;
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
  async create(ship) {
    try {
      const { data: shipment } = await req.post('/shipping/new', ship);
      return new ShippingLabel(shipment);
    } catch (e) {
      console.log(e);
      throw (e);
    }
  },
  async archive(id) {
    try {
      const { data } = await req.post('shipping/archiveLabel', { id });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async clone(card) {
    try {
      const { data: schedule } = await req.post('schedule/cloneSchedule', card, {
        params: { projectId: card.project._id },
      });
      return schedule;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw e;
    }
  },
  async resolveConflict(params) {
    try {
      const { data: schedules } = await req.get('schedule/resolveConflict', { params });
      return schedules;
    } catch (e) {
      console.log(e);
      throw e;
      // TODO: Throw an error dialog box?
    }
  },
};
