<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <h4 class="modal-card-title">Split Delivery: {{ card.name }}</h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="$emit('close')"></i>
      </header>
      <section class="modal-card-body is-size-2 is-marginless">
        <div class="columns">
          <div class="column">
            <h4 class="has-text-black-bis is-italic is-size-3 line-height">
              New Delivery Details
            </h4>
            <div class="field">
              <div class="field-label">
                <label class="label">Name *</label>
              </div>
              <div class="field-body">
                <input
                  v-model="newDelivery.name"
                  class="input"
                  type="text"
                  placeholder="Enter split order name"
                />
              </div>
            </div>
            <div class="field">
              <div class="field-label">
                <label class="label">Tracking #</label>
              </div>
              <div class="field-body">
                <input
                  v-model="newDelivery.trackingId"
                  class="input"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div class="column">
            <h4 class="has-text-black-bis is-italic is-size-3 line-height">
              Dates
            </h4>
            <div class="field">
              <div class="field-label">
                <label class="label">Ship By *</label>
              </div>
              <div class="field-body">
                <div class="line-height has-text-black-bis">
                  <mf-date
                    :item="newDelivery"
                    :input-props="{
                      kind: '_delivery.deliveryStart',
                      max: '_delivery.deliverBy',
                    }"
                    :is-edit="true"
                  ></mf-date>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-label">
                <label class="label">Deliver By *</label>
              </div>
              <div class="field-body">
                <mf-date
                  :item="newDelivery"
                  :input-props="{
                    kind: '_delivery.deliverBy',
                    min: '_delivery.deliveryStart',
                  }"
                  :is-edit="true"
                >
                </mf-date>
              </div>
            </div>
            <div class="field">
              <div class="field-label">
                <label class="has-text-black-bis is-italic is-size-3 label">
                  Delivery Arrangement
                </label>
              </div>
              <div class="field-body">
                <o-checkbox
                  class="p-0"
                  v-model="createDeliveries"
                  @change="splitItemDelivery"
                >
                  <span class="has-text-black-bis">
                    Split each item into its own Delivery
                  </span>
                </o-checkbox>
              </div>
            </div>
          </div>
        </div>
        <search-bar
          :shouldEmit="true"
          @search="getSearchValue"
          placeholder="Search"
        >
        </search-bar>
        <div class="table-container create-shipping-modal">
          <mf-table
            :tableProps="tableProps"
            :apiMode="false"
            :loadData="loadData"
            :hideGutter="true"
            ref="deliveryItemsTable"
            :isRowCheckable="isRowCheckable"
            :key="refreshKey"
            :checkedRows="checkedRow"
            @checkbox-toggled="getSelectedRow"
          >
            <template v-slot:qty-needed="{ rowData: item }">
              <qty-input
                v-show="!createDeliveries"
                :value="item.selectedQty || 0"
                @update:value="itemQtyChanged($event, item)"
                :min="isItemChecked(item) ? 1 : 0"
                :roundTo="4"
                :max="maxQtyAllowed(item)"
                :selectedItems="selectedItems"
                :rowData="item"
                :key="item.uid"
                :setMaxVal="createDeliveries"
              >
              </qty-input>
              <div class="is-pulled-right" v-if="createDeliveries">
                {{ item.selectedQty }}
              </div>
            </template>
            <template v-slot:qty-available="{ rowData: item }">
              <span class="is-pulled-right" :key="item.selectedQty">
                {{ item.quantity - (item.selectedQty ? item.selectedQty : 0) }}
              </span>
            </template>
          </mf-table>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button class="button is-outlined" @click="cancel()">Cancel</button>
        <button
          class="button has-background-black-bis"
          @click="splitDelivery"
          :disabled="isDisabled"
        >
          Create
        </button>
      </footer>
    </div>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>
<script>
import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  toRefs,
  ref,
  computed,
  onBeforeMount,
} from "vue";
import {
  isEmpty,
  set,
  filter,
  forEach,
  isNumber,
  cloneDeep,
  find,
} from "lodash";
import MfDate from "@/components/abstract/MfDate.vue";
import tableDefinition from "@/components/table-cols/splitShippingDeliveryCols";
import { useToast } from "vue-toastification";
import QtyInput from "@/components/fields/QtyInput.vue";
import SearchBar from "@/components/SearchBar.vue";
import Shipping from "@/models/Shipping";
import ShippingLabel from "@/models/ShippingLabel";
import AddRow from "@/components/utils/AddRow";
import uuid from "uuid/v4";

const MfTable = defineAsyncComponent(() =>
  import("@/components/table-fields/MfTable.vue")
);

export default defineComponent({
  name: "SplitDeliveries",
  components: {
    MfTable,
    QtyInput,
    SearchBar,
    MfDate,
  },
  props: {
    card: Object,
    masterShipment: Object,
    isActive: Boolean,
  },
  setup(props, { emit }) {
    const tableProps = tableDefinition;
    const toast = useToast();
    const deliveryItemsTable = ref(null);
    const state = reactive({
      searchText: "",
      selectedItems: [],
      createDeliveries: false,
      checkedRow: [],
      deliveryName: `${props.card.name} - split`,
      deliveryTrackingId: props.card._delivery.trackingId,
      isLoading: false,
      masterShipment: {},
      newDelivery: Object,
      refreshKey: 0,
    });

    // set masterShipment
    const { masterShipment } = props;
    state.masterShipment = masterShipment;

    // add a key for rows to unselect
    props.card.items.forEach((item) => {
      if (item.actualQty === 0 && item.quantity < 1) {
        item.isCheckable = false;
      } else {
        item.isCheckable = true;
      }
    });

    onBeforeMount(() => {
      const emptyRow = AddRow.createEmptyRow(tableDefinition);
      const deliveryObj = Object.assign(props.masterShipment.delivery, {
        deliverBy: props.card._delivery.deliverBy,
        deliveryStart: props.card._delivery.deliveryStart,
        trackingId: props.card._delivery.trackingId,
      });
      state.newDelivery = new ShippingLabel(
        Object.assign(emptyRow, {
          name: `${props.card.name} - split`, // Todo: Random Shipment Label
          delivery: deliveryObj,
          status: "not-started",
          project: state.masterShipment.project,
          trackingId: state.deliveryTrackingId,
          files: [],
        })
      );
    });

    const loadData = () => {
      let fetchedData;
      fetchedData = props.card.items;
      // search
      if (fetchedData.length > 0 && state.searchText) {
        fetchedData = filter(fetchedData, (item) => {
          set(item, "uid", uuid);
          return item.name
            .toLowerCase()
            .includes(state.searchText.toLowerCase());
        });
      }
      return fetchedData;
    };

    const cancel = () => {
      emit("close");
    };

    const getSelectedRow = (selectedItems) => {
      state.selectedItems = selectedItems;
    };

    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      deliveryItemsTable.value.refreshTable();
    };

    const itemQtyChanged = (newQty, item) => {
      item.selectedQty = newQty;
      if (newQty === 0) {
        item.actualQty = item.quantity;
      } else {
        item.actualQty -= newQty;
      }
      const key = item.catId !== "" ? "catId" : "_id";
      const itemObj = find(
        state.selectedItems,
        (i) => i[key]?.toString() === item[key]?.toString()
      );
      if (!itemObj && newQty) {
        item.isCheckable = true;
        state.checkedRow.push(item);
        deliveryItemsTable.value.selectedCheckBoxRows(state.selectedItems);
        state.selectedItems.push(item);
        // state.refreshKey++;
      }
    };

    const maxQtyAllowed = (item) => item.quantity;
    // import items from master
    const addItemsFromMaster = () => {
      const itemsToAdd = [];
      for (let i = 0; i < state.selectedItems.length; i++) {
        // get the masterShipmentItem
        const selectedItem = find(state.masterShipment.items, (item) => {
          if (item._id === "000000000000000000000000") {
            return item.catId === state.selectedItems[i].catId;
          }
          return item._id === state.selectedItems[i]._id;
        });
        if (isEmpty(selectedItem) || selectedItem.selectedQty < 1) {
          continue;
        }
        const newSItem = cloneDeep(selectedItem);
        newSItem.quantity = state.selectedItems[i].selectedQty;
        newSItem.isUsed = true;
        Object.assign(newSItem, {
          id: newSItem._id + state.masterShipment._id,
          isMasterItem: true,
          selected: true,
          showInput: true,
          selectedQty: newSItem.quantity,
          isEditing: true,
          isTemporaryAddition: true,
        });
        itemsToAdd.push(newSItem);
      }
      return itemsToAdd;
    };
    const splitDelivery = async () => {
      try {
        state.isLoading = true;
        // delivery name
        if (state.deliveryName.length < 3) {
          throw new Error("Delivery name must contain at least 3 characters!");
        }
        /* Note: split partial shipment steps
         * p1 => partialShipment1
         * p2 => partialShipment2
         * step 1 : p1.removeItem // remove items and save in unscheduled
         * step 2 : MS.update // update masterShipment
         * step 3 : MS.split() -> // split the mastershipment gives p2
         * step 4 : p2.add() -> // Add the items to the split p2
         * p2.save()
         * push p2 to ms.partialShipments
         * ms.save()
         */
        const msLabel = state.masterShipment;
        let p1;
        let updatedMS;
        // if s-m type
        // if (state.createDeliveries) {
        //     console.log('state selected items', state.selectedItems);
        //     updatedMS = await createDeliveryParItem({ msLabel: msLabel, items: state.selectedItems });
        // }
        if (msLabel.shipType === "s-m") {
          // make a call to break it into m and s
          try {
            state.masterShipment = await Shipping.breakShipmentIntoPartials({
              shippingLabelId: msLabel._id,
            });
          } catch (e) {
            throw new Error(
              "Error occured while breaking shipment in to partials"
            );
          }
          [p1] = state.masterShipment.partialShipments;
        } else {
          p1 = props.card;
        }
        forEach(state.selectedItems, (item) => {
          if (isNumber(item.selectedQty) && item.selectedQty > 0) {
            item.quantity -= item.selectedQty;
          }
        });
        // remove items from p1
        let updatedShipment;
        try {
          updatedShipment = await Shipping.removeItemsFromPartial({
            shippingLabelId: p1._id,
            items: state.selectedItems,
          });
        } catch (e) {
          toast.error("Failed to remove items from partial");
        }
        // fetch latest master shipment
        const updatedM1Id = updatedShipment.mLabel._id;
        // update mastershipment
        try {
          state.masterShipment = await Shipping.getPartialShipments({
            shippingLabelId: updatedM1Id,
          });
        } catch (e) {
          throw new Error("Failed to get partial shipment");
        }
        // split() -> split the partial shipment p1
        // update newShipment
        // step 4: p2.add() add removedItems of the previous partialShipment from Master
        // this will update mastershipment's partial(p2) with the new items
        const itemsToBeAdded = addItemsFromMaster();
        if (state.createDeliveries) {
          for (const item of itemsToBeAdded) {
            const emptyRow = AddRow.createEmptyRow(tableDefinition);
            const deliveryObj = Object.assign(props.masterShipment.delivery, {
              deliverBy: props.card._delivery.deliverBy,
              deliveryStart: props.card._delivery.deliveryStart,
              trackingId: props.card._delivery.trackingId,
            });
            state.newDelivery = new ShippingLabel(
              Object.assign(emptyRow, {
                name: `${props.card.name} - ${item.name}`, // Todo: Random Shipment Label
                delivery: deliveryObj,
                status: "not-started",
                project: state.masterShipment.project,
                trackingId: state.deliveryTrackingId,
                files: [],
              })
            );
            if (item.selected && item.isMasterItem) {
              if (state.newDelivery._newItems)
                state.newDelivery._newItems.fromShipments = [];
              state.newDelivery.items = [];
              state.newDelivery.addItemFromShipment(
                item,
                state.masterShipment,
                item.selectedQty
              );
            }
            try {
              const updatedP2 = await state.newDelivery.save();
              set(updatedP2, "isEditing", false);
              state.masterShipment.partialShipments.push(updatedP2);
            } catch (e) {
              throw new Error("Failed to save shipment");
            }
          }
          // save the shipment
        } else {
          for (const item of itemsToBeAdded) {
            if (item.selected && item.isMasterItem) {
              state.newDelivery.addItemFromShipment(
                item,
                state.masterShipment,
                item.selectedQty
              );
            }
          }
          try {
            const updatedP2 = await state.newDelivery.save();
            set(updatedP2, "isEditing", false);
            state.masterShipment.partialShipments.push(updatedP2);
          } catch (e) {
            throw new Error("Failed to save shipment");
          }
        }
        updatedMS = await state.masterShipment.save();
        toast.success("Delivery was split successfully !");
        state.isLoading = false;
        cancel();
        emit("refreshTab", updatedMS);
      } catch (e) {
        console.log("earor", e);
        toast.error("Error occured while spliting!");
      }
    };

    const isDisabled = computed(() => {
      if (isEmpty(state.selectedItems)) {
        return true;
      }
      if (!state.newDelivery.name) {
        return true;
      }
      let itemsWithNoQty = [];
      itemsWithNoQty = state.selectedItems.filter(
        (item) => !item.selectedQty || item.selectedQty <= 0
      );
      if (itemsWithNoQty.length) return true;

      if (isEmpty(state.newDelivery._delivery.deliverBy)) {
        return true;
      }
      if (isEmpty(state.newDelivery._delivery.deliveryStart)) {
        return true;
      }
      return false;
    });

    const isItemChecked = (itemData) => {
      const item = find(state.selectedItems, { uid: itemData.uid });
      return !isEmpty(item);
    };

    const splitItemDelivery = () => {
      state.checkedRow = _.cloneDeep(props.card.items);
      state.selectedItems = state.checkedRow;
      deliveryItemsTable.value.selectedRows = state.selectedItems;
    };

    const isRowCheckable = () => {
      return !state.createDeliveries;
    };

    return {
      ...toRefs(state),
      tableProps,
      loadData,
      isEmpty,
      cancel,
      splitDelivery,
      splitItemDelivery,
      getSearchValue,
      deliveryItemsTable,
      itemQtyChanged,
      maxQtyAllowed,
      getSelectedRow,
      isDisabled,
      isItemChecked,
      isRowCheckable,
    };
  },
});
</script>
