/* global THREE:true */
/* global Autodesk:true */
/* eslint no-undef: "error" */
import _ from 'lodash';

function forVector4(hexColor) {
  let v4Color = [0, 0, 0.5];
  if (!_.isEmpty(hexColor)) {
    const tmp = hexColor.match(/^#?([A-Fa-f\d]{2})([A-Fa-f\d]{2})([A-Fa-f\d]{2})$/);
    const r = (parseInt(tmp[1], 16) / 255).toFixed(2);
    const g = (parseInt(tmp[2], 16) / 255).toFixed(2);
    const b = (parseInt(tmp[3], 16) / 255).toFixed(2);
    v4Color = [r, g, b];
  }
  return v4Color;
}

function StageColor(viewer, options) {
  Autodesk.Viewing.Extension.call(this, viewer, options);
  this.load = () => true;

  this.unload = () => true;

  // Set color for nodes
  // objectIds should be an array of dbId
  Autodesk.Viewing.Viewer3D.prototype.setColorMaterial = (objectIds, color) => {
    const rgba = forVector4(color, objectIds);
    for (let i = 0; i < objectIds.length; i += 1) {
      const dbid = objectIds[i];
      // from dbid to node, to fragid
      const vColor = new THREE.Vector4(rgba[0], rgba[1], rgba[2], 1);
      viewer.setThemingColor(dbid, vColor);
    }
  };

  Autodesk.Viewing.Viewer3D.prototype.restoreColorMaterial = () => {
    // remove all overlays with same name
    viewer.clearThemingColors();
  };
}

export default {
  init() {
    Autodesk.ADN.Viewing.Extension.Color = StageColor;
    Autodesk.ADN.Viewing.Extension.Color.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
    Autodesk.ADN.Viewing.Extension.Color.prototype.constructor = Autodesk.ADN.Viewing.Extension.Color;
    Autodesk.Viewing.theExtensionManager.registerExtension(
      'Autodesk.ADN.Viewing.Extension.Color',
      Autodesk.ADN.Viewing.Extension.Color,
    );
  },
  self: StageColor,
};
