export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  stickeyHeaders: true,
  detailRowComponent: 'mf-detail-row',
  gutterOpts: {
    buttons: [
      {
        event: 'columnChooser',
        icon: 'icon-tablecolumns',
        isLine: true,
        tooltip: 'Customise Columns',
      },
    ],
  },
  toolBar: {
    // name: for drop down label, label: for action button label
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: () => [{
      event: 'excelExport',
      icon: 'icon-exportexcel',
      tooltip: 'Export to Excel',
    }],
  },
  fields: [
    {
      name: 'field-generic-input',
      width: '400px',
      title: 'Item',
      prop: 'name',
      sticky: true,
      id: 'NAME',
      alwaysVisible: true,
      inputProps: {
        type: 'text',
        stageIcons: true,
        view: 'item',
      },
    }, {
      name: 'field-generic-input',
      width: '296px',
      title: 'Source Order',
      prop: 'cardName',
      defaultVisible: true,
      alwaysVisible: true,
      isClickable: true,
      id: 'cardName',
      eventType: 'openCard',
      inputProps: {
        type: 'text',
        view: 'item',
      },
    }, {
      name: 'financialId',
      default: false,
      width: '152px',
      title: 'Item ID',
      id: 'financialId',
      defaultVisible: false,
    }, {
      name: 'field-cat-id',
      id: 'catId',
      title: 'Catalog ID',
      width: '112px',
      show: true,
      defaultVisible: true,
    }, {
      name: 'project',
      id: 'project',
      title: 'Project',
      width: '296px',
      searchable: true,
      filterText: 'Projects',
      show: true,
      defaultVisible: true,
    }, {
      title: 'Stage',
      name: 'field-stage',
      prop: 'stage',
      searchable: true,
      filterText: 'Stages',
      id: 'stage',
      width: '152px',
      defaultVisible: true,
      alwaysVisible: false,
      cardProps: {
        cardType: '__t',
      },
    }, {
      name: 'field-user-select',
      id: 'owner',
      title: 'Owner',
      prop: 'owner.user',
      defaultVisible: true,
      show: false,
      searchable: true,
      filterText: 'Owners',
      inputProps: {
        type: 'manager',
      },
      width: '296px',
    }, {
      name: 'field-generic-input',
      id: 'companies',
      title: 'Company',
      prop: 'owner.company.name',
      width: '296px',
      show: false,
      inputProps: {
        type: 'manager',
      },
    }, {
      name: 'field-generic-input',
      isDisabled: true,
      width: '296px',
      title: 'Level',
      id: 'level',
      prop: 'level',
      alwaysVisible: false,
    },
    {
      title: 'Zone',
      name: 'field-generic-input',
      id: 'zone',
      prop: 'zone',
      width: '296px',
      alwaysVisible: false,
    }, {
      name: 'quantity',
      width: '112px',
      title: 'Quantity',
      prop: 'quantity',
      show: true,
      classRight: 'is-pulled-right',
      defaultVisible: true,
      id: 'quantity',
    },
    {
      name: 'measure',
      width: '112px',
      title: 'Measure',
      id: 'measure',
    }, {
      name: 'measureUnits',
      width: '152px',
      title: 'Measure Units',
      id: 'measureUnits',
    }, {
      title: 'Order By',
      name: 'field-date',
      defaultVisible: false,
      alwaysVisible: false,
      prop: 'orderBy',
      kind: 'dates',
      id: 'orderBy',
      searchable: true,
      dateFilter: 'orderBy',
      width: '112px',
      inputProps: {
        kind: 'orderBy',
        max: ['available', 'shipBy', 'deliver'],
        disableEdit: false,
      },
    }, {
      title: 'Ship By',
      id: 'shipBy',
      name: 'field-date',
      prop: 'shipBy',
      defaultVisible: false,
      kind: 'dates',
      width: '152px',
      searchable: true,
      dateFilter: 'shipBy',
      itemDate: true,
      inputProps: {
        kind: 'shipBy',
        isBO: true,
        disableEdit: false,
      },
    }, {
      name: 'field-date',
      width: '112px',
      title: 'Onsite',
      prop: 'date',
      id: 'onSiteBy',
      searchable: true,
      dateFilter: 'deliver',
      defaultVisible: true,
      inputProps: {
        type: 'mf-date',
        kind: 'deliver',
      },
    }, {
      name: 'lastModified',
      width: '112px',
      title: 'Modified',
      defaultVisible: true,
      prop: 'date',
      id: 'modified',
      searchable: true,
      dateFilter: 'lastModified',
      // formatter: value => (value === '' ? '' : moment(value).format('DD/MM/YYYY')),
    },
    {
      title: 'Actions',
      name: 'action',
      id: 'action',
      clearBtn: true,
      searchable: true,
      sticky: true,
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      width: '112px',
      hideFromChooser: true,
      alwaysVisible: true,
    },

  ],
};
