import GenerateRequest from './resources';

const req = GenerateRequest();
export default {
  async updateQcTask(params) {
    const { data: task } = await req.post('form/updateQcTask', params);
    return task;
  },
  async updateForm(formObj) {
    try {
      const { data: form } = await req.post('form/update', formObj);
      return form;
    } catch (e) {
      throw e;
    }
  },
  async get(params = {}) {
    params = {
      limit: 25,
      page: 1,
      ...params,
    };
    try {
      const { data: form } = await req.get('form/getForm', { params });
      return form;
    } catch (e) {
      throw e;
    }
  },
  async archive(id) {
    try {
      const { data } = await req.delete(`form/archiveForm/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async cloneForm(param) {
    try {
      const { data } = await req.post('form/cloneForm', param);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async updateFormTemplate(params) {
    try {
      const { data } = await req.post('form/updateFormTemplate', { formData: params });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async getFormTemplate(params) {
    try {
      const { data } = await req.get(`form/getFormTemplate/${params.formId}`, { params });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async attachForm(params) {
    try {
      const { data } = await req.post('form/attachForm', { params });
      return data;
    } catch (e) {
      throw (e);
    }
  },
};
