<template>
  <div class="toolbar column">
      <div class="columns">
        <div class="column" :class="blukActionsDropdown()">
          <mf-multi-select v-if="actions?.length"
            :disabled="selectedAction?.name || fromDashBoard || permissionDisabled()"
            v-model="selectedAction"
            track-by="event"
            label="name"
            placeholder="Bulk Actions"
            :multiple="false"
            :options="actions"
            :allow-empty="false"
            @select="fireEvent($event, true)"
            :selectedWorkstep="selectedWorkstep"
          ></mf-multi-select>
        </div>
        <div class="column is-offset-5 is-offset-2-desktop">
          <div class="columns">
            <div class="column">
              <div class="is-pulled-left" v-if="tab === 'items' && rowData.isCutOrder">
                <Pagination :total="Math.ceil(rowData.totalCutItems/50)"
                  :initialValue="rowData.page"
                  @pagination="(val) =>fireEvent('itemPagination', false, val)"/>
              </div>
              <div class="is-flex is-justify-content-flex-end is-align-items-center">
              <div v-for="(button, idx) in toolButtons" :key="idx" class="toolbar-icon">
                <div class="py-0 mr-2">
                  <template v-if="button.type === 'switch'">
                      <o-switch v-if="button.event === 'showCompletedItems'" v-model="completedItems"
                        name="showCompletedItems"
                        @change="fireEvent(button.event, false, showEmpty);">
                        <span class="has-text-black-bis is-size-3 is-italic has-text-weight-bold">
                          {{ button.label}}
                        </span>
                      </o-switch>
                      <o-switch v-else-if="button.event === 'showArchive'" v-model="showEmpty"
                        @change="fireEvent(button.event, false, showEmpty);">
                        <span class="has-text-black-bis is-size-3 is-italic has-text-weight-bold">
                          {{ button.label}}
                        </span>
                      </o-switch>
                  </template>
                  <template v-else-if="button.type === 'search'">
                    <search-bar v-bind:placeholder="placeholder" :shouldEmit="true" :searchValue="rowData.search" @search="(val) => fireEvent(button.event, false, val)"/>
                  </template>
                <o-dropdown
                    v-else-if="button.options && routeParams.stage === button.showOptionsForStage"
                    :disabled="isButtonDisabled(button)"
                    position="bottom-left" aria-role="list">
                    <template #trigger>
                      <button class="button">
                        <i :class="button.icon"></i>
                      </button>
                    </template>
                    <o-dropdown-item
                      v-for="(opt, idx) in button.options"
                      :key="idx"
                      @click="fireEvent(button.event, false, opt.type)"
                      aria-role="listitem"
                    >
                      <span>{{ opt.label }}</span>
                    </o-dropdown-item>
                  </o-dropdown>
                  <button
                    v-else-if="showActionButtons"
                    @click="fireEvent(button.event, false)"
                    v-show="!showToolbarButtons(button)"
                    :disabled="disabledOnPermission(button) ||
                      (button.event === 'createBOMS' ? createBOMS : isButtonDisabled(button)
                    )"
                    v-tooltip="button.tooltip"
                    class="button"
                    :class="button.title ? 'has-background-black-bis has-text-white mr-3 has-text-weight-bold' : 'p-0'">
                    <i :class="button.icon" v-if="button.icon"></i>
                    <span v-else>{{ button.title }}</span>
                  </button>
                </div>
              </div>
            <div class="is-divider-vertical" v-if="toolButtons.length"></div>
            <search-bar v-bind:placeholder="placeholder"
                        v-if="!tools.hideSearchBar">
            </search-bar>
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import {
  computed, reactive, toRefs, onMounted, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  isFunction, isEmpty, isObject, some, get, set,
} from 'lodash';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import SearchBar from '@/components/SearchBar.vue';
import Pagination from '@/components/Pagination.vue';
import PermissionMixin from '@/components/mixins/PermissionMixin';
import PrefabsMixin from '@/components/mixins/PrefabsMixin';
import screens from '@/constants';

export default {
  name: 'MfToolbar',
  components: {
    MfMultiSelect,
    SearchBar,
    Pagination
  },
  props: {
    tools: Object,
    purpose: {
      type: String,
      default: () => '',
    },
    poMaterials: {
      type: Array,
      default: () => [],
    },
    showArchive: {
      type: Boolean,
      default: () => false,
    },
    orderStage: {
      type: String,
      default: () => '',
    },
    isKitOrder: Boolean,
    rowData: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: String,
    },
    isPartQtyUpdated: Boolean,
    selectedWorkstep: {},
    notesUpdated: Boolean,
    vendorUpdated: Boolean,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore();
    const { getPermissionValues } = PermissionMixin();
    const { createBomAndPR } = PrefabsMixin();
    const { activeScreen } = store.state;
    const state = reactive({
      selectedAction: null,
      columnChooserModal: false,
      showEmpty: props.showArchive,
      routeParams: !isEmpty(route.params)
        ? route.params : { stage: route.path.split('/').pop() },
      createBOMS: true,
      showActionButtons: false,
      orderLevel: false,
    });
    const clearAction = () => {
      state.selectedAction = null;
    };
    const actions = computed(() => {
      if (isFunction(props.tools.bulkActions)) {
        return props.tools.bulkActions(
          state.routeParams.stage,
          props.orderStage,
          route.params,
          route.path,
          props.rowData,
        );
      }
      return props.tools.bulkActions || [];
    });
    const placeholder = computed(() => {
      const placeholderVal = {
        schedule: 'Search Activities',
        forms: 'Search Forms',
        default: 'Search',
        inventory: 'Search Items',
      };
      return placeholderVal[activeScreen] || placeholderVal.default;
    });

    const toolButtons = computed(() => {
      if (isFunction(props.tools.buttons)) {
        let isPropDisabled = false;
        if (['manager-edit-manufacturing', 'cut-edit-manufacturing'].includes(activeScreen)) {
          isPropDisabled = props.rowData.disableReserveBtn || false;
        }
        return props.tools.buttons(state.routeParams.stage, props.poMaterials, isPropDisabled, props.rowData?.isCutOrder);
      }
      return props.tools.buttons || [];
    });
    const fromDashBoard = computed(() => route.query.fromDashBoard === 'true');
    const showToolbarButtons = (button) => {
      if (isFunction(props.tools.showButtons)) {
        return props.tools.showButtons(route.params, button);
      }
      return props.isKitOrder || false;
    };
    const fireEvent = (eventHandler, setBulkAction, optType) => {
      clearAction();
      state.selectedAction = setBulkAction ? eventHandler : null;
      if (eventHandler?.event === 'bulkQrPrint') {
        if (!state.orderLevel && activeScreen !== 'inventory') {
          state.selectedAction.name = 'Print QR Labels (Orders And Items)';
        }
      }
      const handler = setBulkAction ? eventHandler : {
        event: eventHandler,
        type: eventHandler,
        optType,
        nonBulkAction: true,
      };
      if (handler.event === 'itemPagination' && props.rowData.search) {
        handler.optType.search = props.rowData.search;
      }
      emit('event', handler);
    };

    const isButtonDisabled = (button) => {
      if (state.selectedAction !== null) return true;
      if (isFunction(button.isDisabled)) {
        // in list view, if qty is updated - enable the button
        if (['assemblies', 'cutParts'].includes(activeScreen)) {
          return button.isDisabled(props.isPartQtyUpdated, props.notesUpdated);
        }
        return button.isDisabled(state.routeParams, props, props.isPartQtyUpdated || props.vendorUpdated);
      }
      let permissionRes = false;
      if (isObject(button.acl)) {
        permissionRes = getPermissionValues({ ...button.acl, project: { _id: route.path.split('/')[2] } }, false);
      }
      return button.isDisabled || permissionRes || false;
    };

    const getPermissionModule = computed(() => {
      const moduleMap = {
        inventory: 'inventory',
        'shipping-order-view': 'shipping',
        assemblies: 'app&projects',
        parts: 'app&projects',
      };
      if (moduleMap[activeScreen]) return moduleMap[activeScreen];
      return activeScreen;
    });

    const disabledOnPermission = ((button) => {
      if (getPermissionModule.value && button.permissionType) {
        const value = getPermissionValues({
          permissionModule: getPermissionModule.value,
          type: button.permissionType,
        });
        if (value) return true;
      }
      return false;
    });

    const isBOMDisabled = async () => {
      const card = await createBomAndPR(props.rowData);
      state.createBOMS = card?._bomCreated;
    };

    onMounted(async () => {
      // using a flag to enable the toolbar action buttons bcoz the
      // flyout was taking too much time to showup on clicking the icon
      // due to the v-tooltip
      state.showActionButtons = true;
      if (some(toolButtons.value, (button) => button.event === 'createBOMS')) isBOMDisabled();
    });
    const blukActionsDropdown = () => (activeScreen === 'mm-sourcing'
      ? 'is-4 bulk-action-width is-4-desktop' : 'is-3 is-3-fullhd is-4-desktop');

    const permissionModule = () => {
      const res = get(screens[store.state.activeScreen], 'permissionModule', '');
      return res;
    };

    const permissionDisabled = () => getPermissionValues({
      permissionModule: permissionModule(),
      type: 'bulkActions',
    });

    watch(
      () => store.state.qrScan.orderLevel,
      (newVal) => {
        state.orderLevel = newVal;
        if (state.orderLevel && state.selectedAction) {
          state.selectedAction.name = 'Print QR Labels (Orders Only)';
        } else if (!state.orderLevel && state.selectedAction) {
          state.selectedAction.name = 'Print QR Labels (Orders And Items)';
        }
      },
    );

    const completedItems = computed({
      get: () => {
        return !!props?.rowData?.showCompletedItems;
      },
      set: (val) => {
        set(props.rowData, 'showCompletedItems', val ? 1 : 0)
      }
    })

    return {
      ...toRefs(state),
      fireEvent,
      clearAction,
      actions,
      showToolbarButtons,
      isButtonDisabled,
      toolButtons,
      getPermissionModule,
      disabledOnPermission,
      placeholder,
      blukActionsDropdown,
      fromDashBoard,
      permissionDisabled,
      permissionModule,
      completedItems,
    };
  },
};
</script>
<style scoped>
::v-deep(.searchbar) {
  flex: 1;
}
/* added below class to make the bulk action dropdown non-scaling for different resolution */
.bulk-action-width {
  width: 400px !important;
}
</style>
