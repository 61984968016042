<template>
     <div class="card-edit" :key="refreshDetail" v-on="dirtyListeners">
       <card-edit-subheader v-if="isCardLoaded"
        :type="type">
       </card-edit-subheader>
       <div class="edit-body" v-if="isCardLoaded">
          <div class="is-flex hero-header is-size-3 columns is-marginless mb-2">
            <div class="column is-6 is-flex is-align-items-flex-end is-align-items-center">
              <i
                class="has-text-white image is-32x32"
                :class="getDynamicClasses"
              >
              </i>
             <span>{{customId}}</span>
            </div>
            <div v-if="project" class="column is-6 is-flex is-align-items-flex-end
              is-align-items-center">
              <label class="is-italic">Project </label>
              {{ project.name }}
            </div>
          </div>
          <div class="column">
            <div class="columns mb-1">
              <div class="column">
                <div class="columns">
                    <div class="column">
                        <label class="has-text-black-bis is-italic is-size-3">
                          Planned Hours
                        </label>
                        <div class="has-text-black text-overflow line-height text-clamp">
                          <field-clock-display
                          :rowField="{ prop: 'plannedHrs',
                            inputProps: {disableEdit: true, showNum: true, isLeft: true,},}"
                          :rowData="productivityDetails"/>
                        </div>
                    </div>
                    <div class="column">
                        <label class="has-text-black-bis is-italic is-size-3">
                          Actual Hours
                        </label>
                        <div class="has-text-black text-overflow line-height text-clamp">
                          <field-clock-display
                          :rowField="{ prop: 'actualHrs',
                            inputProps: {disableEdit: true, showNum: true, isLeft: true},}"
                          :rowData="productivityDetails"/>
                        </div>
                    </div>
                    <div class="column">
                        <label class="has-text-black-bis is-italic is-size-3">
                          Total Orders
                        </label>
                        <div class='has-text-black text-overflow line-height text-clamp'>
                          <span>
                            {{kitDetails.orders.length}}
                          </span>
                        </div>
                    </div>
                    <div class="column">
                        <label class="has-text-black-bis is-italic is-size-3">
                          Total Items
                        </label>
                        <div class='has-text-black text-overflow line-height text-clamp'>
                          <span>
                            {{kitDetails.items.length}}
                          </span>
                        </div>
                    </div>
                    <div class="column">
                        <label class="has-text-black-bis is-italic is-size-3">
                          % Done
                        </label>
                        <div class="has-text-black text-overflow line-height text-clamp">
                          <span>
                            {{productivityDetails.percComplete}}
                          </span>
                        </div>
                    </div>
                    <div class="column">
                        <h3 class="has-text-black-bis is-italic is-size-3
                          has-text-weight-bold mb-1">
                        Productivity
                        </h3>
                        <div class="has-text-black line-height is-pulled-left">
                          <field-productivity-display :rowField="{ inputProps: {
                                      type: 'text',
                                      disableEdit: true,
                                      kind: 'pf',
                                    },}"
                          :rowData="productivityDetails">
                          </field-productivity-display>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card-edit-item-table has-background-white kit-card-basics">
            <mf-detail-row v-if="isCardLoaded"
              :rowData="kitDetails"
              @tabName="setTabName"
              :tabLabel="selectedTabName"
              @cell-clicked="onCellClicked"
              :key="refreshDetail">
            </mf-detail-row>
          </div>
       </div>
        <pause-restart-modal
            v-if="isPauseRestartActive"
            :isActive="isPauseRestartActive"
            :rowData="selectedOrder"
            @close="closePauseRestartModal">
        </pause-restart-modal>
        <transition name="slide">
            <notes-icon-slider
              v-if="isSlideNotesActive"
              :isActive="isSlideNotesActive"
              :rowData="selectedItem"
              :isDisabled="notesDisable"
              @close="closeNotesSlider"
            >
            </notes-icon-slider>
        </transition>
        <transition name="slide">
          <item-document-slider
            v-if="isFileListVisible"
            :isActive="isFileListVisible"
            :rowData="selectedItem"
            :rowField="rowField"
            @close="closeDocumentSlider"
            :card="card"
            :isDisabled="docsDisabled"
            >
          </item-document-slider>
        </transition>
        <o-loading :active="isLoading"></o-loading>
     </div>
</template>

<script>
import {
  reactive, onBeforeMount, onBeforeUnmount, computed, toRefs, inject,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import CardEditSubHeader from '@/components/card-edit/CardEditSubheader.vue';
import SupplyChain from '@/models/SupplyChain';
import Productivity from '@/models/Productivity';
import MfDetailRow from '@/components/table-fields/MfDetailRow.vue';
import PauseRestartmodal from '@/components/modals/PauseRestartmodal.vue';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import _ from 'lodash';
import ExcelExportMixin from '@/components/mixins/ExcelExportMixin';
import CardEditMixin from '@/components/mixins/CardEditMixin';

export default {
  name: 'KitView',
  components: {
    'card-edit-subheader': CardEditSubHeader,
    'mf-detail-row': MfDetailRow,
    'pause-restart-modal': PauseRestartmodal,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    customId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const emitter = inject('emitter');
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const { excelExport } = ExcelExportMixin();
    const { onInitDirty, printQr, openLmvModel, getRestartPermission } = CardEditMixin();
    const data = reactive({
      type: '',
      kitDetails: {
        orders: {},
        items: {},
      },
      isLoading: false,
      orderStages: [],
      showStageSelect: false,
      card: {},
      config: {},
      productivityDetails: {},
      isCardLoaded: false,
      project: {
        name: '',
      },
      selectedTabName: '',
      isSlideNotesActive: false,
      isFileListVisible: false,
      isPauseRestartActive: false,
      selectedOrder: {},
      selectedItem: {},
      rowField: {},
      notesDisable: false,
      refreshDetail: 0,
      isActive: false,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      docsDisabled: false,
    });
    onInitDirty(data);
    const getProductivitySummarydata = (async () => {
      const params = {
        customId: route.params.customId,
        projectId: route.params.projectId,
        userCompanyId: store.state.userData.company,
        stage: ['coordination', 'detailing'],
      };
      const prodDetail = await Productivity.getProjectView(params);
      if (!prodDetail[0]) {
        data.productivityDetails = {
          percComplete: 0,
        };
      } else {
        [data.productivityDetails] = prodDetail;
      }
    });

    // mention module as materials or production order
    const loadCard = (async (orderModule) => {
      const params = {
        limit: 20,
        customId: route.params.customId,
        projectId: route.params.projectId,
        module: orderModule,
      };
      const kitData = await SupplyChain.supplyChain(params);
      if (orderModule === 'Materials') {
        data.kitDetails.items = _.flattenDeep(_.map(kitData.data, 'items'));
      } else if (orderModule === 'ProductionOrder') {
        data.kitDetails.orders = kitData.data;
        data.kitDetails.orders.forEach((card) => {
          card._customStage = card.stage;
        });
      }
      data.project = store.getters.findProject({ id: props.projectId });
    });
    const loadKitDetails = async () => {
      onInitDirty(data);
      data.isLoading = true;
      await Promise.all([loadCard('Materials'), loadCard('ProductionOrder'), getProductivitySummarydata()]);
      data.refreshDetail++;
      data.isLoading = false;
    };
    onBeforeMount(async () => {
      data.type = store.state.activeScreen;
      await loadKitDetails();
      data.isCardLoaded = true;
    });
    const router = useRouter();
    const getDynamicClasses = computed(() => {
      const routeMeta = router.currentRoute.value.meta;
      let classes = '';
      if (routeMeta.activeViewColor) {
        classes = `${routeMeta.activeViewColor} `;
      }
      if (routeMeta.cardEditIcon) {
        classes += `icon-${routeMeta.cardEditIcon}`;
      }
      return classes;
    });
    const setTabName = (val) => {
      data.selectedTabName = val;
    };
    const closeNotesSlider = (() => {
      data.isSlideNotesActive = false;
      data.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      data.isFileListVisible = false;
    };

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      data.isFileListVisible = !payload.isActive;
      data.selectedItem = payload.data;
      data.isItem = payload.isItem;
      data.isSlideNotesActive = payload.isActive;
      data.notesDisable = payload.isDisabled;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      data.isSlideNotesActive = !payload.isActive;
      data.selectedItem = payload.data;
      data.rowField = payload.rowField;
      data.isFileListVisible = payload.isActive;
      data.docsDisabled = payload.isDisabled;
    });

    const saveRow = async (rowData) => {
      let order = _.cloneDeep(rowData);
      _.set(rowData, 'isEditing', !rowData.isEditing);
      try {
        store.commit('setLoading', true);
        order = await order.save();
        Object.assign(rowData, order);
        await loadKitDetails();
      } catch (e) {
        const msg = _.get(e.data, 'message', '')
          || _.get(e.data, 'msg', '')
          || 'Error saving: please contact ManufactOn support';
        return toast.error(msg);
      } finally {
        store.commit('setLoading', false);
      }
    };

    function openRow(card) {
      let id = '';
      let customStage = card.stage;
      let key = 'prefab';
      if (card.baseCardType === 'Materials') {
        if (card.order.leastStage === 'preparation') key = 'material';
        else if (card.order.leastStage === 'sourcing') key = 'sourcing';
        else {
          key = 'sourcing';
          customStage = 'ordering';
        }
        id = card.order.id;
      } else if (card.isPM()) {
        key = 'manager';
        id = card._id;
      } else if (card.isPO()) {
        key = 'order';
        id = card._id;
        customStage = '';
      }
      router.push({
        name: `${key}-edit`,
        params: {
          projectId: card.project._id,
          cardId: id,
          stage: customStage,
        },
      });
    }
    const onCellClicked = async (event) => {
      const { type } = event;
      if (type === 'openCard') {
        openRow(event.data);
      } else if (type === 'pauseRestart') {
        data.selectedOrder = event.data;
        const cannotRestart = await getRestartPermission(event.data)
        if (event.data.status === 'pause' && cannotRestart) {
          return toast.error('Only Project admins can restart the order');
        }
        data.isPauseRestartActive = true;
      } else if (type === 'save') {
        await saveRow(event.data);
      } else if (type === 'excelExport') {
        excelExport();
      } if (event.type === 'activity') {
        data.isActive = true;
        data.activityDetails.id = event.data._id;
        data.activityDetails.projectId = event.data.project._id;
        data.activityDetails.orderName = event.data.name;
      } else if (event.type === 'printQr') {
        printQr(event.data);
      } else if (event.type === 'refresh-table') {
        await loadKitDetails();
      } else if (event.type === 'lmv') {
        openLmvModel(event.data);
      }
    };
    const closePauseRestartModal = (() => {
      data.isPauseRestartActive = false;
      data.selectedOrder = {};
    });

    return {
      ...toRefs(data),
      getDynamicClasses,
      setTabName,
      closeDocumentSlider,
      closeNotesSlider,
      loadKitDetails,
      onCellClicked,
      closePauseRestartModal,
    };
  },
};
</script>
<style scoped>
::v-deep(.o-table__th.o-table__th-checkbox .o-chk) {
  margin-left: 10px !important;
  display: flex;
  justify-content: center;
}
::v-deep(.card-edit-item-table .o-table__wrapper){
  min-height: 60vh !important;
  max-height: 60vh !important;
}
::v-deep(.text-clamp > div) {
  float: left !important;
  padding-left: 4px;
}
</style>
