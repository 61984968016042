<template>
  <div class="searchbar">
    <div class="columns mr-0">
      <div class="column pr-0">
        <div class="field">
          <p class="control has-icons-right">
            <input
              class="input"
              type="text"
              :placeholder="placeholder"
              v-model="searchText"
              :disabled="disableSearch"
              name="searchText"
              @keypress.enter="handleKeyPress"
            />
            <span class="icon is-small is-right" v-if="searchText.length > 0" @click="clearSearch">
              <i class="icon-close is-clickable"></i>
            </span>
          </p>
        </div>
      </div>
      <div class="column is-narrow">
        <button class="button has-background-black-bis has-text-white p-0" @click="searchedValue(searchText)">
          <i class="icon-search"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onUnmounted } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'searchBar',
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    shouldEmit: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
    }
  },
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      searchText: props.searchValue || '',
    });

    const clearSearch = () => {
      state.searchText = ''; // Reset searchText to clear the search field
      searchedValue(''); // Optionally, emit an empty string or perform any other necessary action
    };

    const setQueryParams = (key, val) => {
      const qryObj = { [key]: val };
      store.commit('setQueryParams', qryObj);
    };

    const searchedValue = (searchQuery) => {
      if (props.shouldEmit) {
        // emit the search back
        emit('search', searchQuery);
      } else {
        // set searchText in store
        setQueryParams('searchText', searchQuery);
      }
    };

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        searchedValue(state.searchText);
        event.target.blur();
      }
    };

    onUnmounted(() => {
      if (!props.shouldEmit) setQueryParams('searchText', '');
    });

    return {
      ...toRefs(state),
      searchedValue,
      clearSearch,
      handleKeyPress,
    };
  },
});
</script>

<style scoped></style>