<template>
  <div>
    <VDropdown placement="right" :shown="isOpen" :autoHide="false">
      <template #popper>
        <div class="card">
          <header class="card-header  has-background-grey-lighter">
            <h4
              class="card-header-title is-size-3 has-text-black-bis pl-0
                  is-capitalized has-text-weight-bold"
            >
              Install Locations
            </h4>
          </header>
          <section class="card-content mt-2 is-marginless has-background-white">
            <div class="is-flex has-icons-left mb-3 is-justify-content-space-between">
              <input
                class="input mr-3"
                type="text"
                placeholder="Enter location, then click Plus"
                v-model="installLocInput"
              />
              <button class="px-0 button" @click="addInstallLoc()"
              :disabled="!installLocInput || installLocInput.length < 4  || rowData.isItemCompleted">
                <i class="icon-addnew"></i>
              </button>
            </div>
            <div>
              <div
                id="tag-field"
                class="field is-grouped is-grouped-multiline"
              ></div>
              <div class="tags-body">
                <div v-if="installLocsList">
                  <div class="mb-3">
                    <button v-for="(loc, idx) in installLocsList" :key="idx"
                      class="button is-primary is-fullwidth is-flex is-justify-content-space-between"
                      :class="loc.isRemoved ? 'is-outlined' : 'has-background-black-bis has-text-white'"
                      :disabled="rowData.isItemCompleted"
                    >
                      <span class="is-size-3">{{ loc.val }}</span>
                      <span class="icon is-small">
                        <i
                          :class="loc.isRemoved ? 'icon-addnew' : 'icon-removedelete'"
                          @click="loc.isRemoved ? addInstallLoc(idx) : removeInstallloc(idx)"></i>
                      </span>
                    </button>
                  </div>
                </div>
                <span
                  v-if="!installLocsList"
                  class="has-text-black-bis has-text-weight-bold"
                >
                  {{ $_.startCase('no install locations added') }}
                </span>
              </div>
            </div>
          </section>
          <footer
            class="card-footer is-justify-content-flex-end has-background-grey-lighter"
          >
            <div>
              <button
                class="button has-background-black-bis has-text-white is-pulled-right"
                @click="close"
              >
                Done
              </button>
            </div>
          </footer>
        </div>
      </template>
    </VDropdown>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>
<script>
// import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import {
  defineComponent, onMounted, reactive, toRefs,
} from 'vue';
import { useToast } from 'vue-toastification';
import { inRange, isUndefined, set } from 'lodash';
import { CardDirtyBus } from '@/utils/CardDirtyBus';

const MyComponent = defineComponent({
  name: 'InstallLocsPopover',
  props: {
    isOpen: Boolean,
    installLocs: Array,
    rowData: Object
  },
  setup(props, { emit }) {
    const toast = useToast();
    const state = reactive({
      searchTag: '',
      tagsToUpdate: [],
      currentSearchText: '',
      existingTags: [],
      existingColors: [],
      installLocsList: [],
      installLocInput: '',
    });

    const checkAlreadyExists = (installLoc) => state.installLocsList.map(
      (loc) => loc.val.toUpperCase(),
    ).includes(installLoc.toUpperCase());

    const installLocValidation = (installLoc) => {
      let msg = '';
      const regex = '^[A-Za-z0-9][A-Za-z0-9-._ ]*?[A-Za-z0-9]$';
      if (state.installLocsList.length >= 16) {
        msg = 'Not More than 16 Install locations can be added';
      } else if (!inRange(installLoc.length, 4, 25) || !RegExp(regex).test(installLoc)) {
        msg = 'Install location should be 4 to 24 character long and cannot start with special characters';
      } else if (checkAlreadyExists(installLoc)) {
        msg = 'Install location already exists';
      }
      return msg;
    };

    const addInstallLoc = (idx) => {
      if (!isUndefined(idx)) {
        set(state.installLocsList, `[${idx}].isRemoved`, false);
        return;
      }
      const msg = installLocValidation(state.installLocInput);
      if (msg) {
        toast.error(msg);
      } else {
        state.installLocsList.push({ val: state.installLocInput, isRemoved: false });
        CardDirtyBus.emit('setCustomDirty');
      }
      state.installLocInput = '';
    };

    const removeInstallloc = (idx) => {
      set(state.installLocsList, `[${idx}].isRemoved`, true);
      CardDirtyBus.emit('setCustomDirty');
    };

    const close = () => {
      emit('closePopover', state.installLocsList.reduce((acc, it) => {
        if (!it.isRemoved) acc.push(it.val);
        return acc;
      }, []));
    };

    onMounted(() => {
      props.installLocs.forEach((val) => {
        state.installLocsList.push({ val, isRemoved: false });
      });
    });

    return {
      ...toRefs(state),
      addInstallLoc,
      removeInstallloc,
      close,
    };
  },
});

export default MyComponent;
</script>

<style scoped>
.card {
  width: 432px !important;
}
</style>
